import React, { useMemo } from "react";
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import { Channel4, Neutral300, Brand100, Success700 } from "../utils/colors";
import * as Dfns from "date-fns/fp";
import { currencyFormatter } from "../MMM/MMMUtils";
import { useDynamicYAxisWidth } from "../Components/Charts/ChartUtils";
import { calculateCumulativeData } from "./pacingUtils";

interface ModalCumulativeAndDailyGraphProps {
  data: any[];
  showCumulative: boolean;
  today: string;
  budget: number;
}

const ModalCumulativeAndDailyGraph: React.FC<ModalCumulativeAndDailyGraphProps> = ({
  data,
  showCumulative,
  today,
  budget,
}) => {
  const { xAxisHeight, yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    xAxisHeightModifier: x => x + 5,
    yAxisWidthModifier: x => x + 3,
  });

  const sumSpendBeforeDate = useMemo(() => {
    return data
      .filter(item => new Date(item.date) < new Date())
      .reduce((acc, item) => acc + item.spend, 0);
  }, [data]);

  const todayFormatted = new Date();
  const formattedDate = todayFormatted.toISOString().split("T")[0];

  const cumulativeGraphDataAdjusted = useMemo(() => {
    const formattedDate = new Date().toISOString().split("T")[0];
    const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return calculateCumulativeData(sortedData).map(item => {
      const formattedCurrent = new Date(item.date).toISOString().split("T")[0];

      if (formattedCurrent < formattedDate) {
        return {
          ...item,
          cumulativeProjectedSimpleSpend: null,
          cumulativeProjectedAdjustedSpend: null,
        };
      } else if (formattedCurrent === formattedDate) {
        return { ...item };
      } else {
        return {
          ...item,
          cumulativeClearedSimpleSpend: null,
          cumulativeClearedSimpleIdealSpend: null,
        };
      }
    });
  }, [data]);

  const barData = useMemo(() => {
    const formattedDate = new Date().toISOString().split("T")[0];
    const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const unnestedData = sortedData.map((row: any) => ({
      date: row.date,
      clearedSimpleSpend: row.cleared.simple.spend,
      projectedSimpleSpend: row.projected.simple.spend,
      clearedSimpleIdealSpend: row.cleared.simple.idealSpend,
      projectedAdjustedSpend: row.projected.adjusted.spend,
    }));

    return unnestedData.map(item => {
      const formattedCurrent = new Date(item.date).toISOString().split("T")[0];

      if (formattedCurrent < formattedDate) {
        return {
          ...item,
          projectedSimpleSpend: null,
          projectedAdjustedSpend: null,
        };
      } else if (formattedCurrent === formattedDate) {
        return { ...item };
      } else {
        return {
          ...item,
          clearedSimpleSpend: null,
          clearedSimpleIdealSpend: null,
        };
      }
    });
  }, [data]);

  const tickSize = useMemo(() => {
    const lastObject = cumulativeGraphDataAdjusted[cumulativeGraphDataAdjusted.length - 1] || {};
    const values = [
      lastObject.cumulativeClearedSimpleSpend,
      lastObject.cumulativeProjectedSimpleSpend,
      lastObject.cumulativeClearedSimpleIdealSpend,
      lastObject.cumulativeProjectedAdjustedSpend,
    ];
    const largestValue = Math.max(...values);
    const tickSize = largestValue / 5;
    return tickSize;
  }, [cumulativeGraphDataAdjusted]);

  const formatYAxis = (value, budgetValue) => {
    if (Math.abs(value - budgetValue) < tickSize / 2) {
      return "";
    }
    return `${currencyFormatter.format(value, 0)}`;
  };

  const HorizontalCustomLabelBudget = ({ viewBox, value }) => {
    const { x, y } = viewBox;
    return (
      <text x={x} y={y} fill="#007F8E" fontSize={10} fontWeight="400">
        <tspan x={x - 36} dy="-.2em">
          {currencyFormatter.format(value, 1)}
        </tspan>
        <tspan x={x - 36} dy="1em">
          Budget
        </tspan>
      </text>
    );
  };

  const VerticalCustomLabelSpend = props => {
    const { viewBox, budget } = props;
    const { x, y } = viewBox;

    const percentage = (Number(sumSpendBeforeDate / budget) * 100).toFixed(0);

    return (
      <text x={x + 10} y={y + 20} fill={Brand100} fontWeight="600" fontSize={16}>
        <tspan x={x + 10} dy="-.2em">
          Today
        </tspan>
        <tspan x={x + 10} dy="1.2em">
          {`${percentage}% through the time period`}
        </tspan>
      </text>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) =>
        showCumulative ? (
          <LineChart
            data={cumulativeGraphDataAdjusted}
            height={height}
            width={width}
            ref={setChartRef}
          >
            <CartesianGrid
              stroke={Neutral300}
              strokeDasharray="0"
              horizontal={true}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={val => Dfns.format("M/dd", Dfns.parseISO(val))}
              height={xAxisHeight}
              tick={{ fontSize: 12, fontWeight: 400 }}
              axisLine={false}
            />
            <YAxis
              tickFormatter={value => formatYAxis(value, budget)}
              width={yAxisWidth}
              tick={{ fontSize: 12, fontWeight: 400 }}
              tickLine={false}
              axisLine={false}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeClearedSimpleSpend"
              stroke={Channel4}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeProjectedSimpleSpend"
              stroke={Channel4}
              strokeDasharray="5 5"
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeClearedSimpleIdealSpend"
              stroke={Success700}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeProjectedAdjustedSpend"
              stroke={Success700}
              strokeDasharray="5 5"
            />
            <ReferenceLine
              x={formattedDate}
              label={<VerticalCustomLabelSpend budget={budget} />}
              stroke="#5F6C84"
            />
            <ReferenceLine
              y={budget}
              label={props => <HorizontalCustomLabelBudget {...props} value={budget} />}
              stroke="#007F8E"
              strokeWidth={3}
            />
          </LineChart>
        ) : (
          <BarChart data={barData} height={height} width={width}>
            <YAxis
              tickFormatter={val => `${currencyFormatter.format(val, 0)}`}
              tick={{ fontSize: 12, fontWeight: 400 }}
              tickLine={false}
              axisLine={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={val => Dfns.format("M/dd", Dfns.parseISO(val))}
              tick={{ fontSize: 12, fontWeight: 400 }}
              axisLine={false}
            />
            <Bar dataKey="clearedSimpleSpend" fill={Channel4} />
            <Bar dataKey="clearedSimpleIdealSpend" fill={Success700} />
            <Bar dataKey="projectedSimpleSpend" fill="#B3F5FB" />
            <Bar dataKey="projectedAdjustedSpend" fill="#D5F0B6" />
            <ReferenceLine
              x={formattedDate}
              label={<VerticalCustomLabelSpend data={data} today={today} budget={budget} />}
              stroke="#5F6C84"
            />
          </BarChart>
        )
      }
    </AutoSizer>
  );
};

export default ModalCumulativeAndDailyGraph;
