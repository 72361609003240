import { VerbId } from '@tableau/api-internal-contract-js';
import { ServiceImplBase, ServiceNames } from '@tableau/api-shared-js';
import { AuthService } from '../AuthService';

export class AuthServiceImpl extends ServiceImplBase implements AuthService {
  public get serviceName(): string {
    return ServiceNames.Authentication;
  }

  public setAuthToken(authToken: string): Promise<void> {
    const parameters = {
      authToken,
    };

    return this.execute(VerbId.Authenticate, parameters).then<void>((response) => {
      return;
    });
  }
}
