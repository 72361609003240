import React from "react";
import { MdOutlineTableRows, MdShowChart } from "react-icons/md";
import { IconToggleButton } from "../IconToggleButton";
import { Mixpanel, MxE } from "../../utils/mixpanelWrapper";
import { StateSetter } from "../../utils/types";

interface GraphTableToggleButtonProps {
  graphView: boolean;
  setGraphView: StateSetter<boolean>;
  className?: string;
  widgetName: string;
}

export const GraphTableToggleButton: React.FC<GraphTableToggleButtonProps> = ({
  graphView,
  setGraphView,
  widgetName,
  className,
}) => {
  return (
    <IconToggleButton
      size="sm"
      options={[
        { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
        { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
      ]}
      selectedOption={graphView ? "showGraph" : "showTable"}
      onChange={() =>
        setGraphView(prev => {
          Mixpanel.track(MxE.GRAPH_OR_TABLE_CHANGE, {
            widget: widgetName || "unknown",
            option_selected: !prev ? "graph" : "table",
          });
          return !prev;
        })
      }
      className={className}
    />
  );
};
