import * as R from "ramda";
import * as XLSX from "xlsx";
import { DOMParser } from "@xmldom/xmldom";
import * as Dfns from "date-fns/fp";

import {
  PlanRow,
  TYPES,
  makeKey,
  ISCI,
  isFakeCompany,
  AVAILS,
  LENGTHS,
  LinearImpressionOverrides,
  TYPES_TO_NAMES,
} from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { convert12hrTo24hr, convert24hrTo12hr } from "../utils/time-utils";
import { DATE_FORMAT, DAYS_OF_WEEK, EXPORT_TO_EXCEL_COLUMN_NAMES } from "./linearBuyingConstants";
import { CreativeMap } from "../redux/creative";
import {
  calculateAudienceInfo,
  convertDaysOfWeekStringToArray,
  EditsMap,
  isCreativeLive,
  makeSummaryData,
  mergeAllRows,
  NielsenEstimates,
  RotationsAndPricing,
} from "./linearBuyingUtils";

import { isOnlyDigits, excelNumberToDate } from "../BudgetIntakeTool/excelUtils";

interface ExcelExportRow {
  network: string;
  avail: typeof AVAILS[number];
  rotationName: string;
  daypart12hr: string;
  dow: string;
  type: typeof TYPES[number];
  length: typeof LENGTHS[number];
  count: number;
  cost: number;
  notes: string | null;
}

/**
 * Check if an imported rotation adds up to 100% and that no ISCIs are undefined (meaning we didn't find them in the creative map)
 */
const isValidImportRotation = (rotation: Record<string, number>, row) => {
  const { network, avail, rotationName, daypart12hr, dow, type, length, notes } = row;
  const key = `Network: ${network} \n Avail: ${avail} \n Rotation: ${rotationName} \n Daypart: ${daypart12hr} \n Days: ${dow} \n Type: ${type} \n Length: ${length} \n Notes: ${notes}`;

  let total = 0;
  for (let isci of R.keys(rotation)) {
    if (isci === "undefined") {
      throw new Error(
        `Couldn't match one of the creative names to an ISCI for at least one row.\n This could mean it's not in the creative map, not live on linear for that week, or there isn't a matching length and/or avail for that name.\n${key}`
      );
    }
    total += rotation[isci];
  }
  // If the count is 0, ignore this because we can assume that when zeroing-out rows we don't care that the rotation % no longer makes sense.
  if (R.keys(rotation).length > row.count && row.count !== 0) {
    throw new Error(
      `The creative rotation doesn't make sense with the current unit count for ${key}`
    );
  }
  if (total !== 100) {
    throw new Error(`The creative rotation does not add up to 100% for ${key}`);
  }
  return true;
};

/**
 * Check if an import changes rotation is different than the existing rotation.
 */
const isDifferentRotation = (newRotation: Record<string, number>, existingRow: PlanRow) => {
  const { creatives } = existingRow;

  // If there wasn't an existing rotation, and there is a new one.
  if (!creatives) {
    return true;
  }

  let existingRotation = {};
  for (let row of creatives) {
    existingRotation[row.isci] = row.percent;
  }

  if (R.equals(existingRotation, newRotation)) {
    return false;
  } else {
    return true;
  }
};

/**
 * Convert days of week from v2 to v1 format
 */
export const convertDaysOfWeekToExcelFormat = (dow: string[]): string => {
  let convertedDow = "";

  for (let day of DAYS_OF_WEEK) {
    if (dow.includes(day)) {
      convertedDow += day;
    } else if (!dow.includes(day) && (day === "M" || day === "W" || day === "F")) {
      convertedDow += "-";
    } else {
      convertedDow += "--";
    }
  }
  return convertedDow;
};

/**
 * Convert daypart from v2 to v1 format
 * In v1, daypart start and end times are one string, and in 12hr time format.
 */
export const convertDaypartToExcelFormat = (daypart_start: string, daypart_end: string): string => {
  return `${convert24hrTo12hr(daypart_start)}-${convert24hrTo12hr(daypart_end)}`;
};

const getCreativeMetadataToIsciMap = (creativeMap: CreativeMap, week: string) => {
  return R.values(creativeMap)
    .filter(creative =>
      isCreativeLive(creative, week, Dfns.format(DATE_FORMAT, Dfns.addDays(6, new Date(week))))
    )
    .reduce((prev, current) => {
      const { name, length, avail, isci, modelEntry } = current;
      const key = `${name}_${length}_${avail}_ad${modelEntry}`;
      return { ...prev, [key]: isci };
    }, {});
};

const isUpfront = (type: number) => {
  return TYPES_TO_NAMES[type] === "Upfront";
};

/**
 * Figure out which ISCI to use for a creative based on its name, length, and avail.
 */
const getIsciToUse = (
  creative: string,
  length: typeof LENGTHS[number],
  avail: typeof AVAILS[number],
  nameToIsciMap: Record<string, string>
) => {
  // Creative columns in optimizer file start with creative[' and end with ']
  const creativeInfo = creative.split("['")[1].split("']")[0]; // creative['ad123;My Creative Name'] -> ad123;My Creative Name
  const adId = creativeInfo.split(";")[0]; // ad123;My Creative Name -> ad123
  const creativeName = creativeInfo.split(";")[1]; // ad123;My Creative Name -> My Creative Name
  const creativeKey = `${creativeName}_${length}_${avail}_${adId}`; // My Creative Name_30_N_ad123
  const isciToUse = nameToIsciMap[creativeKey];
  return { isciToUse, creativeName, adId };
};

/**
 * Parses an Excel file that is generated from the optimizer, and processes the data to be
 * added as new rows for the currently selected week.
 */
export const processBulkImport = (
  file: ArrayBuffer,
  mondayDates: string[],
  company: string,
  existingRows: PlanRow[],
  creativeMap: CreativeMap | undefined,
  rotationsAndPricing: RotationsAndPricing,
  campaigns: Record<string, number>,
  enableAllLinearBuyingChanges: boolean | string
): Record<string, PlanRow> => {
  if (!creativeMap) {
    return {};
  }
  // Create a map of existing keys for the selected weeks.
  let existingRowKeys = {};
  for (let row of existingRows) {
    existingRowKeys[row.key] = true;
  }

  // Create a workbook using the imported file.
  const workbook = XLSX.read(file, { type: "buffer" });
  // Get first sheet of workbook
  const worksheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[worksheetName];

  // Convert worksheet to JSON
  const rows: Record<string, any>[] = XLSX.utils.sheet_to_json(worksheet);

  // For each live creative in creative map, map each name/length/avail to it's ISCI
  let creativeMetadataToIsciMap = {};
  for (const week of mondayDates) {
    creativeMetadataToIsciMap = {
      creativeMetadataToIsciMap,
      ...getCreativeMetadataToIsciMap(creativeMap, week),
    };
  }
  let newRows: Record<string, PlanRow> = {};
  for (let row of rows) {
    const {
      network,
      avail,
      rotationName,
      market,
      daypart12hr,
      dow,
      type,
      length,
      count,
      cost,
      notes,
      campaign,
      impressionOverride,
      secondaryImpressionOverride,
      secondaryImpressionsDemo,
      secondaryImpressionsMeasurement,
      orderedImpressions,
      orderedImpressionsMeasurement,
      orderedImpressionsDemo,
      week,
    } = row;

    // if (!rotationsAndPricing[network]) {
    //   throw new Error(
    //     `The network, ${network}, does not currently exist. If it should, add it to both the linear rates and contacts pages.`
    //   );
    // }
    let formattedDate = "";
    if (mondayDates.length > 1) {
      formattedDate = isOnlyDigits(week) ? excelNumberToDate(week) : week;
    } else {
      formattedDate = mondayDates[0];
    }
    // Day of week in optimizer file is a string. Convert to string array.
    let daysOfWeek = convertDaysOfWeekStringToArray(dow);
    // Daypart start and end time in optimizer file is one concatenated string. Split them and convert to 24hr time.
    let startTime = convert12hrTo24hr(daypart12hr.split("-")[0]);
    let endTime = convert12hrTo24hr(daypart12hr.split("-")[1]);
    let override: LinearImpressionOverrides = {};
    if (isUpfront(type)) {
      if (
        secondaryImpressionsDemo &&
        secondaryImpressionsMeasurement &&
        secondaryImpressionOverride
      ) {
        override = {
          [secondaryImpressionsDemo]: {
            [secondaryImpressionsMeasurement]: secondaryImpressionOverride,
          },
        };
      } else if (
        (secondaryImpressionsDemo && R.isNil(secondaryImpressionsMeasurement)) ||
        (R.isNil(secondaryImpressionsDemo) && secondaryImpressionsMeasurement)
      ) {
        throw new Error(
          "Must fill out all fields: secondaryImpressionsDemo, secondaryImpressionsMeasurement, secondaryImpressionOverride to add an Impression Override. Otherwise please leave all three fields blank."
        );
      }
    }
    // Type in the optimizer is blank for Regular type. If the cell is blank convert to 0, otherwise use the number.
    let convertedType: typeof TYPES[number] = type ? type : 0;

    let newRow: PlanRow = {
      plan_id: null,
      week: formattedDate,
      network,
      avail,
      rotation: rotationName,
      market,
      daypart_start: startTime,
      daypart_end: endTime,
      dow: daysOfWeek,
      type: convertedType,
      length,
      count,
      cost: Math.round(cost),
      notes: notes || null,
      company,
      key: "",
      order_id: null,
      traffic_id: null,
      isNewNetwork: true,
      isNewRow: true,
      is_plan_pending: true,
      campaign_id: campaigns[campaign] || null,
      impressions: enableAllLinearBuyingChanges
        ? secondaryImpressionOverride
        : impressionOverride || null,
    };
    if (isUpfront(type) && enableAllLinearBuyingChanges) {
      const demoAndMeasurementUpdates = {
        orderedImpressionsDemo: orderedImpressionsDemo || null,
        orderedImpressionsMeasurement: orderedImpressionsMeasurement || null,
        orderedImpressions: orderedImpressions || null,
        impressionOverrides: override || null,
      };
      newRow = { ...newRow, ...demoAndMeasurementUpdates };
    }
    let key = makeKey(newRow);
    // If the key already exists, skip this from the import so we don't duplicate rows.
    if (existingRowKeys[key]) {
      continue;
    }

    let creativeRotation: Record<string, number> = {};

    /*
    If this is a fake company, ignore the creative columns. This allows one to upload another company's
    media plan without it erroring when looking for the creatives (e.g. uploading into a scratchpad)

    TODO: Have a more systematic way of determining "fake" companies, because we do want to
    include creatives for instacart_scratchpad specifically, but not the other scratchpads.
     */
    if (!isFakeCompany(company) || company === "instacart_scratchpad") {
      for (let column of R.keys(row)) {
        if (column.startsWith("creative[") && Number.isInteger(row[column]) && row[column] !== 0) {
          const { isciToUse, creativeName, adId } = getIsciToUse(
            column,
            length,
            avail,
            creativeMetadataToIsciMap
          );
          if (!isciToUse) {
            const key = `Network: ${network} \n Avail: ${avail} \n Rotation: ${rotationName} \n Market: ${market} \n Daypart: ${daypart12hr} \n Days: ${dow} \n Type: ${type} \n Length: ${length} \n Notes: ${notes}`;
            throw new Error(
              `Couldn't find an ISCI for creative name "${creativeName}" where length = ${length} and avail = ${avail} and model entry = ${adId} \n This could mean it's not in the creative map, not live on linear for that week, or there isn't a matching length and/or avail for that name. Happened at row: \n ${key}`
            );
          }
          creativeRotation[isciToUse] = row[column];
        }
      }
    }

    // If there's a valid rotation, build creatives field
    let creatives: ISCI[] = [];
    if (!R.isEmpty(creativeRotation) && isValidImportRotation(creativeRotation, row)) {
      for (let isci of R.keys(creativeRotation)) {
        let data: ISCI = {
          plan_id: null,
          isci,
          percent: creativeRotation[isci],
          isPending: true,
        };
        creatives.push(data);
      }
    }

    if (creatives.length) {
      newRow = { ...newRow, creatives };
    }

    newRow = { ...newRow, key };
    newRows[key] = newRow;
  }

  return newRows;
};

/**
 * Parses an Excel file that is generated from the optimizer, and processes the data to be
 * added as new rows for the currently selected week.
 */

export const processXMLImport = (file: Buffer, company: string): void => {
  let processedRows: ExcelExportRow[] = [];

  //parse file
  const decoder = new TextDecoder("utf-8");
  const xmlString = decoder.decode(file);
  const xmlDocument = new DOMParser().parseFromString(xmlString, "text/xml");
  const rows = xmlDocument.getElementsByTagName("AvailLineWithDetailedPeriods");

  const outletIDToNetworkMap: { [key: string]: string } = {};
  const outletIDToForListID: { [key: string]: string } = {};
  //radio
  const radioStations =
    xmlDocument.getElementsByTagName("RadioStation").length > 0
      ? xmlDocument.getElementsByTagName("RadioStation")
      : null;
  //tv
  const tvStations =
    xmlDocument.getElementsByTagName("CableStation").length > 0
      ? xmlDocument.getElementsByTagName("CableStation")
      : xmlDocument.getElementsByTagName("TelevisionStation").length > 0
      ? xmlDocument.getElementsByTagName("TelevisionStation")
      : null;

  if (radioStations) {
    for (let i = 0; i < radioStations.length; i++) {
      const outlet = radioStations[i];
      const callLetters = outlet.getAttribute("callLetters");
      const band = outlet.getAttribute("band");
      const outletId = outlet.getAttribute("outletId");
      const networkName = `${callLetters}-${band}`;
      if (outletId) {
        outletIDToNetworkMap[outletId] = networkName;
      }
    }
  } else if (tvStations) {
    for (let i = 0; i < tvStations.length; i++) {
      const outlet = tvStations[i];
      const network = outlet.getAttribute("network")
        ? outlet.getAttribute("network")
        : outlet.getAttribute("callLetters");
      const outletId = outlet.getAttribute("outletId");
      if (outletId && network) {
        outletIDToNetworkMap[outletId] = network;
      }
    }
  }

  const referenceNumbers = xmlDocument.getElementsByTagName("OutletReference");
  for (let i = 0; i < referenceNumbers.length; i++) {
    const outletRef = referenceNumbers[i];
    const outletFromProposalRef = outletRef.getAttribute("outletFromProposalRef");
    const outletForListId = outletRef.getAttribute("outletForListId");
    if (outletFromProposalRef && outletForListId) {
      outletIDToForListID[outletForListId] = outletFromProposalRef;
    }
  }

  //tv

  const avilListRow = xmlDocument.getElementsByTagName("AvailList");
  const name = avilListRow[0].getElementsByTagName("Name")[0].textContent;

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    //find network based on maps
    const outletRefId = row.getElementsByTagName("OutletReference")[0];
    const outletFromListRef = outletRefId.getAttribute("outletFromListRef");
    const outletIdFromMap = outletFromListRef ? outletIDToForListID[outletFromListRef] : null;
    const network = outletIdFromMap ? outletIDToNetworkMap[outletIdFromMap] : "";

    //dow
    const days = row.getElementsByTagName("Days");
    const dayContent = days[0].textContent;
    const yOrNArray = dayContent?.match(/[YN]/g);
    const dayArray = yOrNArray
      ?.map((value, index) => ({ day: DAYS_OF_WEEK[index] as string, status: value }))
      .filter(item => item.status === "Y")
      .map(item => item.day) as string[];

    const excelDow = convertDaysOfWeekToExcelFormat(dayArray);

    //daypart and time
    const dayTimes = row.getElementsByTagName("DayTimes");
    const startTime = dayTimes[0].getElementsByTagName("StartTime")[0].textContent || "";
    const endTime = dayTimes[0].getElementsByTagName("EndTime")[0].textContent || "";
    const [hours] = endTime.split(":").map(Number);

    // Subtract one minute
    const newHours = hours - 1;
    const newMinutes = 59;
    const newEndTime = `${newHours.toString().padStart(2, "0")}:${newMinutes
      .toString()
      .padStart(2, "0")}`;

    const excelDaypart = convertDaypartToExcelFormat(startTime, newEndTime);

    //rotation
    const daypartName = row.getElementsByTagName("DaypartName")[0].textContent || "";
    const availName = row.getElementsByTagName("AvailName")[0].textContent || "";
    const rotationName = radioStations
      ? `${daypartName} ${availName}`
      : `${daypartName} ${availName} ${startTime}-${newEndTime}`;

    //length
    const timeLength = row.getElementsByTagName("SpotLength")[0].textContent || "";
    const length = timeLength?.split(":").reduce((acc, time) => acc * 60 + Number(time), 0);
    const newLength = length as typeof LENGTHS[number];

    //per week data
    const periods = row.getElementsByTagName("Periods");
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      const detailedPeriods = period.getElementsByTagName("DetailedPeriod");
      for (let i = 0; i < detailedPeriods.length; i++) {
        const detailedPeriod = detailedPeriods[i];
        const startWeek = detailedPeriod.getAttribute("startDate");
        const endWeek = detailedPeriod.getAttribute("endDate");
        let parsedStartWeek = Dfns.parseISO(startWeek as string);
        let startOfWeek = Dfns.startOfWeek(parsedStartWeek);
        let firstMonday = Dfns.addDays(1, startOfWeek);
        const lastDay = Dfns.parseISO(endWeek as string);
        const weeks: string[] = [];

        //get all mondays between start and end week
        while (firstMonday <= lastDay) {
          weeks.push(Dfns.format(DATE_FORMAT, firstMonday));
          firstMonday = Dfns.addDays(7, firstMonday);
        }

        //get rate and spots
        const rate = detailedPeriod.getElementsByTagName("Rate")[0]?.textContent;
        const rateNum = rate ? parseInt(rate, 10) : 0;
        const spots = detailedPeriod.getElementsByTagName("SpotsPerWeek")[0]?.textContent || null;
        const spotsNum = spots ? parseInt(spots) : 0;
        for (let week of weeks) {
          let newRow: ExcelExportRow = {
            network: network,
            avail: "L",
            rotationName: rotationName,
            daypart12hr: excelDaypart,
            dow: excelDow,
            type: 0,
            length: newLength,
            count: spotsNum,
            cost: rateNum,
            notes: name,
          };

          const processedRow = {
            ...newRow,
            campaign: "",
            media_classification: "",
            market: "",
            week: week,
          };

          processedRows.push(processedRow);
        }
      }
    }
  }

  let fileName = `${company}_XMLDownload.xlsx`;

  // The sheet name can't exceed 31 characters.
  if (fileName.length > 31) {
    let diff = fileName.length - 31;
    fileName = fileName.substring(diff);
  }
  let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
  let worksheet = XLSX.utils.json_to_sheet(processedRows);

  workbook.SheetNames.push(fileName);
  workbook.Sheets[fileName] = worksheet;

  XLSX.writeFile(workbook, fileName);
};

/**
 * Parses an Excel file that is generated from the optimizer, and processes the data to bulk change
 * multiple rows. This only affects count, cost, notes, and creative rotations.
 */
export const processImportChanges = (
  file: ArrayBuffer,
  existingRows: PlanRow[],
  creativeMap: CreativeMap | undefined,
  campaigns: Record<string, string>,
  company: string,
  mondayDates: string[],
  enableAllLinearBuyingChanges: boolean | string
): EditsMap => {
  if (!creativeMap) {
    return {};
  }
  //change the dates for this set of rows and then change the dates for the map meta to get the range
  // Create a map of existing rows for the selected week based on values that won't change with this import.
  let existingRowKeys = {};
  for (let row of existingRows) {
    let campaign = "";
    if (row.campaign_id) {
      campaign = campaigns[row.campaign_id];
    }
    const existingKey = `${row.week}_${row.network}_${row.avail}_${row.rotation}_${row.daypart_start}_${row.daypart_end}_${row.dow}_${row.type}_${row.length}_${campaign}`;
    existingRowKeys[existingKey] = row;
  }
  // Create a workbook using the imported file.
  const workbook = XLSX.read(file, { type: "buffer" });

  // Get first sheet of workbook
  const worksheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[worksheetName];

  // Convert worksheet to JSON
  const rows: Record<string, any>[] = XLSX.utils.sheet_to_json(worksheet);
  // For each live creative in creative map, map each name/length/avail to it's ISCI
  let creativeMetadataToIsciMap = {};
  for (const week of mondayDates) {
    creativeMetadataToIsciMap = {
      creativeMetadataToIsciMap,
      ...getCreativeMetadataToIsciMap(creativeMap, week),
    };
  }
  let editedRows: EditsMap = {};
  for (let row of rows) {
    const {
      network,
      avail,
      rotationName,
      daypart12hr,
      dow,
      type,
      length,
      count,
      cost,
      notes,
      campaign,
      media_classification,
      impressionOverride,
      secondaryImpressionOverride,
      secondaryImpressionsMeasurement,
      secondaryImpressionsDemo,
      market,
      orderedImpressionsDemo,
      orderedImpressionsMeasurement,
      orderedImpressions,
    } = row;

    //impression overrides must have a demo, measurement and impressions
    let override: LinearImpressionOverrides = {};
    if (isUpfront(type) && enableAllLinearBuyingChanges) {
      if (
        !R.isNil(secondaryImpressionsDemo) &&
        !R.isNil(secondaryImpressionsMeasurement) &&
        secondaryImpressionOverride !== 0
      ) {
        override = {
          [secondaryImpressionsDemo]: {
            [secondaryImpressionsMeasurement]: secondaryImpressionOverride,
          },
        };
      } else if (
        (secondaryImpressionsDemo && R.isNil(secondaryImpressionsMeasurement)) ||
        (R.isNil(secondaryImpressionsDemo) && secondaryImpressionsMeasurement)
      ) {
        throw new Error(
          "Must fill out all fields: secondaryImpressionsDemo, secondaryImpressionsMeasurement, secondaryImpressionOverride to add an Impression Override. Otherwise please leave impressionsDemo and impressionsMeasurement blank."
        );
      }
    }

    // Day of week in optimizer file is a string. Convert to string array.
    const daysOfWeek = convertDaysOfWeekStringToArray(dow);

    let formattedDate = "";
    if (mondayDates.length > 1) {
      formattedDate = isOnlyDigits(row.week) ? excelNumberToDate(row.week) : row.week;
    } else {
      formattedDate = mondayDates[0];
    }
    // Daypart start and end time in optimizer file is one concatenated string. Split them and convert to 24hr time.
    const startTime = convert12hrTo24hr(daypart12hr.split("-")[0]);
    const endTime = convert12hrTo24hr(daypart12hr.split("-")[1]);
    row.week = formattedDate;
    // Type in the optimizer is blank for Regular type. If the cell is blank convert to 0, otherwise use the number.
    const convertedType: typeof TYPES[number] = type ? type : 0;

    const changedRowKey = `${
      row.week
    }_${network}_${avail}_${rotationName}_${startTime}_${endTime}_${daysOfWeek}_${convertedType}_${length}_${
      campaign || ""
    }`;

    const existingRow = existingRowKeys[changedRowKey];

    // If there's an existing row that matches, and there are changes, add to edits.
    if (existingRow) {
      let edits = {};

      if (count !== existingRow.count) {
        edits = { ...edits, count };
      }
      if (cost !== existingRow.cost) {
        edits = { ...edits, cost: Math.round(cost) };
      }
      if (notes !== existingRow.notes && !R.isNil(notes) && !R.isNil(existingRow.notes)) {
        edits = { ...edits, notes };
      }
      if (
        media_classification !== existingRow.media_classification &&
        !R.isNil(media_classification)
      ) {
        edits = { ...edits, media_classification };
      }

      if (enableAllLinearBuyingChanges && isUpfront(type)) {
        if (
          override !== existingRow.impressionOverrides &&
          JSON.stringify(override) !== JSON.stringify(existingRow.impressionOverrides) &&
          !R.isNil(override) &&
          !R.isEmpty(override) &&
          override !== undefined
        ) {
          edits = { ...edits, impressionOverrides: override };
        }
        if (
          orderedImpressionsDemo !== existingRow.orderedImpressionsDemo &&
          !R.isEmpty(orderedImpressionsDemo)
        ) {
          edits = { ...edits, orderedImpressionsDemo };
        }
        if (
          orderedImpressionsMeasurement !== existingRow.orderedImpressionsMeasurement &&
          !R.isEmpty(orderedImpressionsMeasurement)
        ) {
          edits = { ...edits, orderedImpressionsMeasurement };
        }
        if (
          orderedImpressions !== existingRow.orderedImpressions &&
          orderedImpressions !== 0 &&
          !R.isNil(orderedImpressions)
        ) {
          edits = { ...edits, orderedImpressions };
        }
      }
      if (
        impressionOverride !== existingRow.impressions &&
        impressionOverride !== 0 &&
        !R.isNil(impressionOverride)
      ) {
        edits = { ...edits, impressions: impressionOverride };
      }
      if (!R.isNil(market) && market !== existingRow.market) {
        edits = { ...edits, market };
      }
      let newRotation: Record<string, number> = {};
      // If this is a fake company, ignore the creative columns so that you can upload another company's
      // media plan without it erroring (e.g. uploading into a scratchpad)
      if (!isFakeCompany(company)) {
        for (let column of R.keys(row)) {
          if (
            column.startsWith("creative[") &&
            Number.isInteger(row[column]) &&
            row[column] !== 0
          ) {
            const { isciToUse, creativeName, adId } = getIsciToUse(
              column,
              length,
              avail,
              creativeMetadataToIsciMap
            );
            if (!isciToUse) {
              const key = `Network: ${network} \n Avail: ${avail} \n Rotation: ${rotationName} \n Daypart: ${daypart12hr} \n Days: ${dow} \n Type: ${type} \n Length: ${length} \n Notes: ${notes}`;
              throw new Error(
                `Couldn't find an ISCI for creative name "${creativeName}" where length = ${length} and avail = ${avail} and model entry = ${adId}\n This could mean it's not in the creative map, not live on linear for that week, or there isn't a matching length and/or avail for that name. Occurred at row: \n ${key}`
              );
            }
            newRotation[isciToUse] = row[column];
          }
        }
      }

      let existingRotation: Record<string, number> = {};
      if (existingRow.creatives) {
        for (let creative of existingRow.creatives) {
          existingRotation[creative.isci] = creative.percent;
        }
      }

      let mergedRotation = { ...existingRotation, ...newRotation };

      // If there's a valid rotation, build creatives field
      let creatives: ISCI[] = [];
      if (
        !R.isEmpty(newRotation) &&
        isValidImportRotation(newRotation, row) &&
        isDifferentRotation(newRotation, existingRow)
      ) {
        for (let isci of R.keys(mergedRotation)) {
          let planId = existingRow.plan_id;
          // If an ISCI is in the merged rotation, but not in the new rotation, this means it was
          // removed in the import changes. Set it to 0% in the edits.
          let pct = newRotation[isci] || 0;
          let creative: ISCI = {
            plan_id: planId,
            isci,
            percent: pct,
            isPending: true,
          };
          creatives.push(creative);
        }

        edits = { ...edits, creatives };
      }

      if (!R.isEmpty(edits)) {
        editedRows[existingRow.key] = { ...existingRow, edits };
      }
    }
  }
  return editedRows;
};

/**
 * Parses a plan that was exported by the buying page (which added a plan ID column), and processes
 * the data to bulk change multiple rows. Since it matches on ID, it can change all fields in a
 * row except Network, Avail, Type, and Campaign (because we don't want people changing these
 * because it would change how PDFs are created).
 */
export const processImportChangesWithID = (
  file: ArrayBuffer,
  existingRows: PlanRow[],
  creativeMap: CreativeMap | undefined,
  company: string,
  mondayDates: string[],
  enableAllLinearBuyingChanges: boolean | string
): EditsMap => {
  if (!creativeMap) {
    return {};
  }
  // Create a map of existing rows for the selected week based on values that won't change with this import.
  let existingRowsMap: Record<string, PlanRow> = {};
  for (let row of existingRows) {
    if (row.plan_id) {
      existingRowsMap[row.plan_id] = row;
    }
  }

  // Create a workbook using the imported file.
  const workbook = XLSX.read(file, { type: "buffer" });

  // Get first sheet of workbook
  const worksheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[worksheetName];

  // Convert worksheet to JSON
  const rows: Record<string, any>[] = XLSX.utils.sheet_to_json(worksheet);

  // For each live creative in creative map, map each name/length/avail to it's ISCI
  let creativeMetadataToIsciMap = {};
  for (const week of mondayDates) {
    creativeMetadataToIsciMap = {
      creativeMetadataToIsciMap,
      ...getCreativeMetadataToIsciMap(creativeMap, week),
    };
  }
  let editedRows: EditsMap = {};
  for (let row of rows) {
    const {
      id,
      network,
      avail,
      rotationName,
      daypart12hr,
      dow,
      type,
      length,
      count,
      cost,
      notes,
      media_classification,
      market,
      impressionOverride,
      secondaryImpressionOverride,
      secondaryImpressionsMeasurement,
      secondaryImpressionsDemo,
      orderedImpressionsDemo,
      orderedImpressions,
      orderedImpressionsMeasurement,
    } = row;

    //impression overrides must have a demo, measurement and impressions
    let override = {};
    if (isUpfront(type) && enableAllLinearBuyingChanges) {
      if (
        !R.isNil(secondaryImpressionsDemo) &&
        !R.isNil(secondaryImpressionsMeasurement) &&
        secondaryImpressionOverride !== 0
      ) {
        override = {
          [secondaryImpressionsDemo]: { [secondaryImpressionsMeasurement]: impressionOverride },
        };
      } else if (
        (secondaryImpressionsDemo && R.isNil(secondaryImpressionsMeasurement)) ||
        (R.isNil(secondaryImpressionsDemo) && secondaryImpressionsMeasurement)
      ) {
        throw new Error(
          "Must fill out all fields: secondaryImpressionsDemo, secondaryImpressionsMeasurement, secondaryImpressionOverride to add an Impression Override. Otherwise please leave secondaryImpressionsDemo and secondaryImpressionsMeasurement blank."
        );
      }
    }
    // Day of week in optimizer file is a string. Convert to string array.
    const daysOfWeek = convertDaysOfWeekStringToArray(dow);

    // Daypart start and end time in optimizer file is one concatenated string. Split them and convert to 24hr time.
    const startTime = convert12hrTo24hr(daypart12hr.split("-")[0]);
    const endTime = convert12hrTo24hr(daypart12hr.split("-")[1]);

    const existingRow = existingRowsMap[id];

    // If there's an existing row that matches, and there are changes, add to edits.
    if (existingRow) {
      let edits = {};

      if (rotationName !== existingRow.rotation) {
        edits = { ...edits, rotation: rotationName };
      }
      if (startTime !== existingRow.daypart_start) {
        edits = { ...edits, daypart_start: startTime };
      }
      if (endTime !== existingRow.daypart_end) {
        edits = { ...edits, daypart_end: endTime };
      }
      if (!R.equals(daysOfWeek, existingRow.dow)) {
        edits = { ...edits, dow: daysOfWeek };
      }
      if (length !== existingRow.length) {
        edits = { ...edits, length: length };
      }
      if (count !== existingRow.count) {
        edits = { ...edits, count };
      }
      if (cost !== existingRow.cost) {
        edits = { ...edits, cost: Math.round(cost) };
      }
      if (notes !== existingRow.notes && !R.isNil(notes) && !R.isNil(existingRow.notes)) {
        edits = { ...edits, notes };
      }
      if (
        media_classification !== existingRow.media_classification &&
        !R.isNil(media_classification)
      ) {
        edits = { ...edits, media_classification };
      }
      if (!R.isNil(market) && market !== existingRow.market) {
        edits = { ...edits, market };
      }
      if (enableAllLinearBuyingChanges && isUpfront(type)) {
        if (
          override !== existingRow.impressionOverrides &&
          JSON.stringify(override) !== JSON.stringify(existingRow.impressionOverrides) &&
          !R.isNil(override) &&
          !R.isEmpty(override) &&
          override !== undefined
        ) {
          edits = { ...edits, impressionOverrides: override };
        }
        if (
          orderedImpressionsDemo !== existingRow.orderedImpressionsDemo &&
          !R.isEmpty(orderedImpressionsDemo)
        ) {
          edits = { ...edits, orderedImpressionsDemo };
        }
        if (
          orderedImpressionsMeasurement !== existingRow.orderedImpressionsMeasurement &&
          !R.isEmpty(orderedImpressionsMeasurement)
        ) {
          edits = { ...edits, orderedImpressionsMeasurement };
        }
        if (
          orderedImpressions !== existingRow.orderedImpressions &&
          orderedImpressions !== 0 &&
          !R.isNil(orderedImpressions)
        ) {
          edits = { ...edits, orderedImpressions };
        }
      }
      if (
        impressionOverride !== existingRow.impressions &&
        impressionOverride !== 0 &&
        !R.isNil(impressionOverride)
      ) {
        edits = { ...edits, impressions: impressionOverride };
      }
      let newRotation: Record<string, number> = {};
      // If this is a fake company, ignore the creative columns so that you can upload another company's
      // media plan without it erroring (e.g. uploading into a scratchpad)
      if (!isFakeCompany(company)) {
        for (let column of R.keys(row)) {
          if (
            column.startsWith("creative[") &&
            Number.isInteger(row[column]) &&
            row[column] !== 0
          ) {
            const { isciToUse, creativeName } = getIsciToUse(
              column,
              length,
              avail,
              creativeMetadataToIsciMap
            );
            if (!isciToUse) {
              const key = `Network: ${network} \n Avail: ${avail} \n Rotation: ${rotationName} \n Daypart: ${daypart12hr} \n Days: ${dow} \n Type: ${type} \n Length: ${length} \n Notes: ${notes}`;
              throw new Error(
                `Couldn't find an ISCI for creative name "${creativeName}" where length = ${length} and avail = ${avail}\n This could mean it's not in the creative map, not live on linear for that week, or there isn't a matching length and/or avail for that name. Occurred at row: \n ${key}`
              );
            }
            newRotation[isciToUse] = row[column];
          }
        }
      }

      let existingRotation: Record<string, number> = {};
      if (existingRow.creatives) {
        for (let creative of existingRow.creatives) {
          existingRotation[creative.isci] = creative.percent;
        }
      }

      let mergedRotation = { ...existingRotation, ...newRotation };

      // If there's a valid rotation, build creatives field
      let creatives: ISCI[] = [];
      if (
        !R.isEmpty(newRotation) &&
        isValidImportRotation(newRotation, row) &&
        isDifferentRotation(newRotation, existingRow)
      ) {
        for (let isci of R.keys(mergedRotation)) {
          let planId = existingRow.plan_id;
          // If an ISCI is in the merged rotation, but not in the new rotation, this means it was
          // removed in the import changes. Set it to 0% in the edits.
          let pct = newRotation[isci] || 0;
          let creative: ISCI = {
            plan_id: planId,
            isci,
            percent: pct,
            isPending: true,
          };
          creatives.push(creative);
        }

        edits = { ...edits, creatives };
      }

      if (!R.isEmpty(edits)) {
        editedRows[existingRow.key] = { ...existingRow, edits };
      }
    }
  }
  return editedRows;
};

/**
 * Exports the selected week's plan rows to an Excel where the format matches the optimizer Excel.
 */
export const exportToExcel = (
  rows: PlanRow[],
  creativeMap: CreativeMap | undefined,
  nielsenEstimates: NielsenEstimates,
  universeEstimate: number | undefined,
  idToNames: Record<string, string>,
  company: string,
  week: string,
  enableAllLinearBuyingChanges: boolean | string
): void => {
  if (!creativeMap) {
    throw new Error("Couldn't find creative map.");
  }
  const makeNameModelEntryKey = (name: string, id: number | undefined) => `ad${id};${name}`;

  let uniqueCreativeNames = {};
  // Get names of live creatives
  for (let creative of R.values(creativeMap)) {
    const { name, length, modelEntry } = creative;
    const key = makeNameModelEntryKey(name, modelEntry);
    if (isCreativeLive(creative, week, Dfns.format(DATE_FORMAT, Dfns.addDays(6, new Date(week))))) {
      uniqueCreativeNames[key] = {
        ...(uniqueCreativeNames[key] || {}),
        [length]: true,
      };
    }
  }

  // Get names of creatives in rotations (regardless of if creative is live or not)
  for (let row of rows) {
    if (row.creatives) {
      for (let creative of row.creatives) {
        const { name, length, modelEntry } = creativeMap[creative.isci];
        const key = makeNameModelEntryKey(name, modelEntry);
        uniqueCreativeNames[key] = {
          ...(uniqueCreativeNames[key] || {}),
          [length]: true,
        };
      }
    }
  }

  let processedRows: ExcelExportRow[] = [];
  for (let row of rows) {
    const convertedDaypart = convertDaypartToExcelFormat(row.daypart_start, row.daypart_end);
    const convertedDow = convertDaysOfWeekToExcelFormat(row.dow);
    const { impressions, cpm, trp } = calculateAudienceInfo({
      row,
      nielsenEstimates,
      universeEstimate,
    });
    const spend = row.cost * row.count;
    let convertedRow = { ...row, daypart: convertedDaypart, dow: convertedDow };
    // Filter out keys that we won't use as columns in Excel export.
    let withFilteredColumns = R.pick(R.keys(EXPORT_TO_EXCEL_COLUMN_NAMES), convertedRow);
    // Rename keys to match the column naming from optimizer files.
    let withRenamedColumns = R.keys(withFilteredColumns).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [EXPORT_TO_EXCEL_COLUMN_NAMES[key]]: withFilteredColumns[key] },
      }),
      {}
    ) as ExcelExportRow;
    let withExtraColumns = {
      ...withRenamedColumns,
      spend,
      cpm,
      trp,
    };
    let withCampaignName = {
      ...withExtraColumns,
      campaign: row.campaign_id ? idToNames[row.campaign_id] : null,
    };
    let addingImpressionsTables = {};
    const extractDemo = R.pipe(R.keys, R.head)(row.impressionOverrides || {}) as string;

    const extractMeasurement = R.pipe(
      R.head,
      R.keys,
      R.head
    )(R.values(row.impressionOverrides || {})) as string;

    const extractSecondaryImpressions =
      row.impressionOverrides && extractDemo && extractMeasurement
        ? row.impressionOverrides[extractDemo][extractMeasurement]
        : null;

    if (enableAllLinearBuyingChanges) {
      //with impressions columns
      let withImpressionsExtraColumns = {
        secondaryImpressionsMeasurement: extractMeasurement || null,
        secondaryImpressionsDemo: extractDemo || null,
        secondaryImpressionOverride: extractSecondaryImpressions || null,
        impressionOverride: null,
        orderedImpressionsMeasurement: row.orderedImpressionsMeasurement || "",
        orderedImpressionsDemo: row.orderedImpressionsDemo || "",
        orderedImpressions: row.orderedImpressions || null,
      };
      addingImpressionsTables = {
        ...withCampaignName,
        ...withImpressionsExtraColumns,
        nielsenEstimates: impressions || null,
      };
    }
    // Convert the ISCIs to their creative name and map them to the percent allocation.
    let rowCreatives = {};
    if (row.creatives) {
      for (let creative of row.creatives) {
        const { name, modelEntry } = creativeMap[creative.isci];
        const key = makeNameModelEntryKey(name, modelEntry);
        rowCreatives[key] = creative.percent;
      }
    }

    // Add creative columns. Need to convert names to match the format of the optimizer file.
    // This adds columns for creatives that are either live, or in an existing rotation.
    let creativeColumns = R.keys(uniqueCreativeNames).reduce((prev, current) => {
      // Adding the lengths for each creative name to the end of the column name so you know what lengths it has when assigning traffic.
      const lengths = R.keys(uniqueCreativeNames[current])
        .map(length => `${length}s`)
        .join(", ");
      const nameWithFormatting = `creative['${current}']_${lengths}`;
      const percent = rowCreatives[current] || "";
      return { ...prev, [nameWithFormatting]: percent };
    }, {});
    let withCreatives = enableAllLinearBuyingChanges
      ? {
          ...withCampaignName,
          ...addingImpressionsTables,
          ...creativeColumns,
        }
      : {
          ...withCampaignName,
          impressionOverride: row.edits?.impressions
            ? row.edits?.impressions
            : row.impressions
            ? row.impressions
            : null,
          nielsenEstimates: impressions || null,
          ...creativeColumns,
        };
    processedRows.push(withCreatives);
  }
  let fileName = `${company}_${week}.xlsx`;

  // The sheet name can't exceed 31 characters.
  if (fileName.length > 31) {
    let diff = fileName.length - 31;
    fileName = fileName.substring(diff);
  }
  //help tab
  let columnDefinitions = {
    "*": "Headers with * can be changed via process changes or process changes with ID",
    id:
      "Buying plan id **If you have this in your spreadsheet you should use process changes with ID to update the buying page**",
    network: "Network code",
    avail: "N or L (national or local)",
    rotationName: "Name of the rotation",
    daypart12hr: "Start and end time rotation",
    dow: "Days of the week of rotation",
    type: "Type of Buy:  Regular = 0,  Secured = 1, General = 2, Remnant = 3, Upfront = 4",
    length: "Length of spot 15, 30, 60 unless billboard",
    "*count": "Number of spots",
    "*cost": "Cost per spot",
    "*notes": "Notes",
    campaign: "Campaign name",
    "*media_classification": "Media classification",
    "*market": "Market",
    week:
      "Week of air date -- to extend to future weeks then copy the row and change the week to the appropriate week. Repeat for each week you want to extend",
    spend: "cost x count",
    cpm: "cost per million",
    trp: "TRP",
    "*impressionOverride":
      "Edit to override impressions (nielsen estimates) that are displayed on buying page. Only use for non-upfront buy types",
    nielsenEstimates:
      "Impressions download from buying page based on trailing 4 week average of nielsen numbers",
  };
  if (enableAllLinearBuyingChanges) {
    let withImpressionsExtraColumns = {
      "*Upfront buy type columns*": "All columns below are only for upfront buy type (4)",
      "*secondaryImpressionsMeasurement":
        "Secondary impressions measurement to track an additional measurement than the booked. Must fill out secondary demo, measurement and impressions for it to save",
      "*secondaryImpressionsDemo":
        "Secondary impressions demo to track an additional measurement than the booked. Must fill out secondary demo, measurement and impressions for it to save",
      "*secondaryImpressionOverride":
        "Secondary impression override to track an additional impressions than the booked. Must fill out secondary demo, measurement and impressions for it to save",
      "*orderedImpressionsMeasurement": "Ordered impressions measurement booked",
      "*orderedImpressionsDemo": "Ordered impressions demo booked",
      "*orderedImpressions": "Ordered impressions booked",
    };
    columnDefinitions = { ...columnDefinitions, ...withImpressionsExtraColumns };
  }
  let definitionTabName = "column_definitions_tab";

  const helpTab = Object.keys(columnDefinitions).map(key => ({
    Column: key,
    Description: columnDefinitions[key],
  }));

  let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
  let worksheet = XLSX.utils.json_to_sheet(processedRows);
  let helpTabWorksheet = XLSX.utils.json_to_sheet(helpTab);

  workbook.SheetNames.push(fileName);
  workbook.SheetNames.push(definitionTabName);
  workbook.Sheets[fileName] = worksheet;
  workbook.Sheets[definitionTabName] = helpTabWorksheet;

  XLSX.writeFile(workbook, fileName);
};

/**
 * Exports the summary view to Excel.
 */
export const exportSummaryView = (
  rows: PlanRow[],
  editsMap: EditsMap,
  newRows: Record<string, PlanRow>,
  creativeMap: CreativeMap | undefined,
  company: string,
  week: string,
  nielsenEstimates: NielsenEstimates,
  assumedClearanceRate: number | undefined
): void => {
  if (!creativeMap) {
    throw new Error("Couldn't find creative map.");
  }

  let uniqueCreativeNames = {};
  // Get names of live creatives
  for (let creative of R.values(creativeMap)) {
    if (isCreativeLive(creative, week, Dfns.format(DATE_FORMAT, Dfns.addDays(6, new Date(week))))) {
      uniqueCreativeNames[creative.name] = true;
    }
  }

  // Get names of creatives in rotations (regardless of if creative is live or not)
  for (let row of rows) {
    if (row.creatives) {
      for (let creative of row.creatives) {
        const { name } = creativeMap[creative.isci];
        uniqueCreativeNames[name] = true;
      }
    }
  }

  let fileName = `${company}_${week}_Summary.xlsx`;

  const newRowsForCurrentWeek: Record<string, PlanRow> = R.pickBy(
    val => val.week === week,
    newRows
  );

  const editsForCurrentWeek: EditsMap = R.pickBy(val => val.week === week, editsMap);

  const allRows = mergeAllRows(rows, editsForCurrentWeek, newRowsForCurrentWeek);

  const { byLength, byNetwork, byRotation, byCreative, byAvailLength } = makeSummaryData({
    rows: allRows,
    nielsenEstimates,
    creativeMap,
  });

  const {
    byLength: byLengthWithClearance,
    byNetwork: byNetworkWithClearance,
    byRotation: byRotationWithClearance,
    byCreative: byCreativeWithClearance,
    byAvailLength: byAvailLengthWithClearance,
  } = makeSummaryData({
    rows: allRows,
    nielsenEstimates,
    creativeMap,
    assumedClearanceRate,
  });

  // Initialize workbook
  const workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };

  // Convert table arrays to sheets
  const networkWorksheet = XLSX.utils.json_to_sheet(byNetwork);
  const rotationWorksheet = XLSX.utils.json_to_sheet(byRotation);
  const creativeWorksheet = XLSX.utils.json_to_sheet(byCreative);
  const lengthWorksheet = XLSX.utils.json_to_sheet(byLength);
  const availLengthWorksheet = XLSX.utils.json_to_sheet(byAvailLength);

  const networkWorksheetWithClearance = XLSX.utils.json_to_sheet(byNetworkWithClearance);
  const rotationWorksheetWithClearance = XLSX.utils.json_to_sheet(byRotationWithClearance);
  const creativeWorksheetWithClearance = XLSX.utils.json_to_sheet(byCreativeWithClearance);
  const lengthWorksheetWithClearance = XLSX.utils.json_to_sheet(byLengthWithClearance);
  const availLengthWorksheetWithClearance = XLSX.utils.json_to_sheet(byAvailLengthWithClearance);

  // Convert sheets to JSON
  const rotationJSON: any[][] = XLSX.utils.sheet_to_json(rotationWorksheet, {
    header: 1,
  });
  const creativeJSON: any[][] = XLSX.utils.sheet_to_json(creativeWorksheet, {
    header: 1,
  });
  const lengthJSON: any[][] = XLSX.utils.sheet_to_json(lengthWorksheet, {
    header: 1,
  });
  const availLengthJSON: any[][] = XLSX.utils.sheet_to_json(availLengthWorksheet, { header: 1 });

  const rotationJSONWithClearance: any[][] = XLSX.utils.sheet_to_json(
    rotationWorksheetWithClearance,
    { header: 1 }
  );
  const creativeJSONWithClearance: any[][] = XLSX.utils.sheet_to_json(
    creativeWorksheetWithClearance,
    { header: 1 }
  );
  const lengthJSONWithClearance: any[][] = XLSX.utils.sheet_to_json(lengthWorksheetWithClearance, {
    header: 1,
  });
  const availLengthJSONWithClearance: any[][] = XLSX.utils.sheet_to_json(
    availLengthWorksheetWithClearance,
    { header: 1 }
  );

  // Add all the JSONs to the same sheet
  XLSX.utils.sheet_add_aoa(networkWorksheet, rotationJSON, { origin: "G1" });
  XLSX.utils.sheet_add_aoa(networkWorksheet, creativeJSON, { origin: "M1" });
  XLSX.utils.sheet_add_aoa(networkWorksheet, lengthJSON, { origin: "S1" });
  XLSX.utils.sheet_add_aoa(networkWorksheet, availLengthJSON, { origin: "Y1" });

  XLSX.utils.sheet_add_aoa(networkWorksheetWithClearance, rotationJSONWithClearance, {
    origin: "G1",
  });
  XLSX.utils.sheet_add_aoa(networkWorksheetWithClearance, creativeJSONWithClearance, {
    origin: "M1",
  });
  XLSX.utils.sheet_add_aoa(networkWorksheetWithClearance, lengthJSONWithClearance, {
    origin: "S1",
  });
  XLSX.utils.sheet_add_aoa(networkWorksheetWithClearance, availLengthJSONWithClearance, {
    origin: "Y1",
  });

  // Add sheet to workbook
  XLSX.utils.book_append_sheet(workbook, networkWorksheet, "Summary View");
  XLSX.utils.book_append_sheet(
    workbook,
    networkWorksheetWithClearance,
    `Summary View with ${(assumedClearanceRate || 0.75) * 100}% Clearance`
  );

  XLSX.writeFile(workbook, fileName);
};
