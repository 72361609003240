import "./CustomSegments.scss";
import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import cn from "classnames";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { Button, ButtonType, DropdownMenu, InfoTooltip, OverlayTrigger } from "../Components";
import {
  CommittedValue,
  NewValue,
  PRESET_VALUES_MAP,
  PresetSegmentNames,
  RequiredSegmentNames,
  SystemGeneratedSegmentNames,
} from "./CustomSegments";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { SellerCountries } from "../RetailOpsChannels/FormDropDownOptions";

export enum SegmentType {
  SYSTEM_GENERATED = "system-generated",
  REQUIRED = "required",
  PRESET = "preset",
  CUSTOM = "custom",
}

interface CustomSegmentCardProps {
  type: SegmentType;
  id: number | string;
  name: string;
  values: (CommittedValue | NewValue)[];
  dataGranularity: "ad" | "ad_group" | "campaign";
  isInEditMode: boolean;
  isAdmin: boolean;
  hasEdits: boolean;
  setIsInEditMode?: (newVal: boolean) => void;
  onEditSegmentName: (newVal: string) => void;
  onEditSegmentValueName: (newVal: string, valueId: string) => void;
  addNewSegmentValue: (valueName: string, segmentId: number | string) => void;
  deleteSegment: () => void;
  deleteSegmentValue: (segmentId: string, valueId: number | string) => void;
}

export const CustomSegmentCard = ({
  type,
  id,
  name,
  values,
  dataGranularity,
  isInEditMode,
  isAdmin,
  hasEdits,
  setIsInEditMode = () => {},
  onEditSegmentName,
  onEditSegmentValueName,
  addNewSegmentValue,
  deleteSegment,
  deleteSegmentValue,
}: CustomSegmentCardProps): JSX.Element => {
  const addBlankSegmentValue = () => addNewSegmentValue("", id);

  const segmentTypeLabel = useMemo(() => {
    switch (type) {
      case SegmentType.SYSTEM_GENERATED:
        return "System Generated";
      case SegmentType.REQUIRED:
        return "Required";
      case SegmentType.PRESET:
        return "Pre-set Segment";
      case SegmentType.CUSTOM:
      default:
        return "Custom Segment";
    }
  }, [type]);

  const segmentNameTooltip = useMemo(() => {
    const tooltipCopyEnding = {
      [SegmentType.SYSTEM_GENERATED]: ` is a required label and will be automatically labeled for each of your ${dataGranularity}s but can be re-labeled if needed.`,
      [SegmentType.REQUIRED]: ` is a required label for each of your ${dataGranularity}s.`,
      [SegmentType.PRESET]: ` is optional for any ${dataGranularity} and includes standardized values approved by Client Reporting. These values are not editable.`,
    };

    switch (name) {
      case SystemGeneratedSegmentNames.PLATFORM:
        return `${name} is the system through which the ad impression is delivered (e.g. Meta). ${name}${
          tooltipCopyEnding[SegmentType.SYSTEM_GENERATED]
        }`;
      case SystemGeneratedSegmentNames.CHANNEL:
        return `${name} is a type of marketing effort (e.g. Paid Social). ${name}${
          tooltipCopyEnding[SegmentType.SYSTEM_GENERATED]
        }`;
      case RequiredSegmentNames.CAMPAIGN_OWNERSHIP:
        return `${name} tracks whether a campaign is managed by Tinuiti or not. ${name}${
          tooltipCopyEnding[SegmentType.REQUIRED]
        }`;
      case RequiredSegmentNames.INCLUDE_IN_FEE_CALC:
        return `${name} tracks whether a given campaign is eligible for Tinuiti to charge a fee. ${name}${
          tooltipCopyEnding[SegmentType.REQUIRED]
        }`;
      case PresetSegmentNames.TACTIC:
        return `${name} is the primary strategy or audience for your spend. ${name}${
          tooltipCopyEnding[SegmentType.PRESET]
        }`;
      case PresetSegmentNames.FUNNEL_TIER:
        return `${name} is the type of response a campaign is trying to generate (awareness, traffic or direct response). ${name}${
          tooltipCopyEnding[SegmentType.PRESET]
        }`;
      case PresetSegmentNames.PROMO:
        return `${name} is sales and evergreen periods. ${name}${
          tooltipCopyEnding[SegmentType.PRESET]
        }`;
      case PresetSegmentNames.OBJECTIVE:
        return `${name} is the primary goal of a campaign. ${name}${
          tooltipCopyEnding[SegmentType.PRESET]
        }`;
      case PresetSegmentNames.BUDGET_TYPE:
        return `${name} is the part of the client's business that has to account for this campaign's media spend in its budget. ${name}${
          tooltipCopyEnding[SegmentType.PRESET]
        }`;
      case PresetSegmentNames.GEO:
        return `${name} is country-level campaign tracking. ${name}${
          tooltipCopyEnding[SegmentType.PRESET]
        }`;
      default:
        return "Custom Segments are optional for any campaign, the values are editable and you can select from any of the values you populate here or N/A on the labeling page.";
    }
  }, [dataGranularity, name]);

  const editableValues = useMemo(() => {
    if (type === SegmentType.CUSTOM && isInEditMode) {
      return values;
    }

    if (type === SegmentType.PRESET && isAdmin) {
      const presetValues =
        name === PresetSegmentNames.GEO
          ? SellerCountries.map(country => country.label)
          : PRESET_VALUES_MAP[name] ?? [];
      const approvedValues = [...presetValues, "N/A"];

      return values.filter(({ valueName }) => !approvedValues.includes(valueName));
    }

    return [];
  }, [isAdmin, isInEditMode, name, type, values]);

  const nonEditableValues = useMemo(() => {
    return values.filter(val => !editableValues.includes(val));
  }, [editableValues, values]);

  return (
    <div
      className={cn("segmentContainer", {
        edit: isInEditMode,
        default: [SegmentType.SYSTEM_GENERATED, SegmentType.REQUIRED].includes(type),
      })}
      key={id}
    >
      <div className="segmentHeader">
        <div className="segmentTitle">
          <div className={cn("segmentTypeLabel")}>
            {segmentTypeLabel}
            {type === SegmentType.CUSTOM && (
              <InfoTooltip>
                <p className="customSegmentsTooltip">{segmentNameTooltip}</p>
              </InfoTooltip>
            )}
          </div>
          {[SegmentType.PRESET, SegmentType.CUSTOM].includes(type) && (
            <div className="segmentControls">
              {type === SegmentType.PRESET ? (
                <Button
                  type={ButtonType.EMPTY}
                  variant={ButtonFrameworkVariant.ICON_ONLY}
                  icon={<MdDelete />}
                  onClick={deleteSegment}
                />
              ) : (
                <Button
                  type={ButtonType.EMPTY}
                  variant={ButtonFrameworkVariant.ICON_ONLY}
                  icon={isInEditMode ? <MdDelete /> : <MdEdit />}
                  onClick={
                    isInEditMode
                      ? () => {
                          deleteSegment();
                          setIsInEditMode(false);
                        }
                      : () => {
                          setIsInEditMode(true);
                        }
                  }
                />
              )}
            </div>
          )}
        </div>
        {type === SegmentType.CUSTOM && isInEditMode ? (
          <Form.Control
            className="segmentNameInput"
            size="sm"
            placeholder="New Segment"
            type="text"
            value={name}
            key={id}
            onChange={e => onEditSegmentName(e.currentTarget.value)}
            onBlur={e => onEditSegmentName(e.currentTarget.value.trim())}
          />
        ) : (
          <div className="segmentNameContainer">
            <div className="segmentName">{name}</div>
            {type !== SegmentType.CUSTOM && segmentNameTooltip && (
              <InfoTooltip>
                <p className="customSegmentsTooltip">{segmentNameTooltip}</p>
              </InfoTooltip>
            )}
          </div>
        )}
      </div>
      <div className="segmentBody">
        <div className="segmentValues">
          {editableValues.map(({ valueId, valueName }) => (
            <div className="editableValue" key={valueId}>
              <Form.Control
                className="segmentValueInput"
                size="sm"
                placeholder="New Value"
                type="text"
                value={valueName}
                key={valueId}
                onChange={e => {
                  onEditSegmentValueName(e.currentTarget.value, String(valueId));
                }}
                onBlur={e => {
                  onEditSegmentValueName(e.currentTarget.value, String(valueId).trim());
                }}
              />
              <Button
                type={ButtonType.EMPTY}
                variant={ButtonFrameworkVariant.ICON_ONLY}
                icon={<MdDelete />}
                onClick={() => {
                  deleteSegmentValue(String(id), valueId);
                }}
              />
            </div>
          ))}
          {nonEditableValues.map(({ valueId, valueName }) => (
            <div className="option" key={valueName}>
              {valueName}
            </div>
          ))}
          {(type === SegmentType.CUSTOM
            ? isInEditMode
            : isAdmin && name !== PresetSegmentNames.GEO) && (
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdAdd />}
              onClick={addBlankSegmentValue}
            />
          )}
        </div>
      </div>
      {hasEdits && (
        <div className="unsavedChangesContainer">
          <div className="unsavedChanges">Unsaved changes</div>
        </div>
      )}
      {name === PresetSegmentNames.GEO && (
        <GeoSelector
          onAddValue={(value: string) => {
            if (!values.map(val => val.valueName).includes(value)) {
              addNewSegmentValue(value, id);
            }
          }}
        />
      )}
    </div>
  );
};

export const GeoSelector = ({
  onAddValue,
}: {
  onAddValue: (value: string) => void;
}): JSX.Element => {
  return (
    <OverlayTrigger
      placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
      trigger="click"
      exitOnBackgroundClick
      loseFocusOnScroll={false}
      overlay={
        <DropdownMenu
          className="geoSelectorMenu"
          title="Geo(s)"
          applyOnClick={null}
          cancelOnClick={null}
          menuItems={SellerCountries}
          itemOnClick={({ label, selected }) => {
            if (label && selected) {
              onAddValue(label);
            }
          }}
          hasFilterBar
          multiSelect
          standalone
        />
      }
    >
      <Button
        type={ButtonType.FILLED}
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdAdd />}
      />
    </OverlayTrigger>
  );
};

interface NewSegmentCardProps {
  unaddedPresets: string[];
  addNewSegment: (key: string) => void;
}

export const NewSegmentCard = ({
  unaddedPresets,
  addNewSegment,
}: NewSegmentCardProps): JSX.Element => {
  const [newSegmentSelection, setNewSegmentSelection] = useState("");

  return (
    <div className="segmentContainer">
      <div className="segmentHeader">
        <div className="segmentName">Choose New Segment</div>
      </div>
      <div className="segmentBody">
        <div className="segmentValues">
          {unaddedPresets.map(presetKey => {
            const presetName = PresetSegmentNames[presetKey];

            return (
              <Form.Check
                key={presetKey}
                id={presetKey}
                checked={newSegmentSelection === presetKey}
                onChange={() => {
                  setNewSegmentSelection(presetKey);
                }}
                label={presetName}
                type="radio"
              />
            );
          })}
          {unaddedPresets.length > 0 && <div className="segmentOptionDivider" />}
          <Form.Check
            id="custom"
            checked={newSegmentSelection === "custom"}
            onChange={() => {
              setNewSegmentSelection("custom");
            }}
            label="Custom"
            type="radio"
          />
        </div>
      </div>
      <div className="segmentFooter">
        <Button
          type={ButtonType.FILLED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdAdd />}
          onClick={() => {
            addNewSegment(newSegmentSelection === "custom" ? "" : newSegmentSelection);
            setNewSegmentSelection("");
          }}
          disabled={!newSegmentSelection}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default CustomSegmentCard;
