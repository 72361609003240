import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { getGroupingValue } from "../../TVADCrossChannel/homePageUtils";
import { currencyFormatter, capitalizeWords, numberFormatter } from "../MMMUtils";
import { MdOutlineWarningAmber } from "react-icons/md";
import { formatMoney, formatNumber } from "../../utils/format-utils";

//Change date to M/dd/yy format
export const dateToMddyy = (date: string): string => {
  return Dfns.format("M/dd/yy", new Date(date.length < 11 ? `${date}T00:00:00` : date));
};

interface DataItem {
  channel: string;
  platform: string;
  tactic: string | null;
  brand_nonbrand: string | null;
}

type DateGroupingKey = "Day" | "Week" | "Month" | "Quarter" | "Year";

const getTotalsByDate = (dateGrouping, groupedByDate) => {
  let totalsByDate = {};
  Object.keys(groupedByDate).forEach(key => {
    const dateToUse = getGroupingValue(key, dateGrouping as DateGroupingKey);

    const merged = R.mergeAll(groupedByDate[key]);
    Object.keys(merged).forEach(kpi => {
      if (kpi !== "date") {
        totalsByDate = R.mergeDeepRight(totalsByDate, {
          [dateToUse]: {
            [kpi]: R.pathOr(0, [dateToUse, kpi], totalsByDate) + merged[kpi],
          },
        });
      }
    });
  });
  return totalsByDate;
};

interface getNewDProps {
  totalsByDate: Record<string, Record<string, number>>;
  relative: boolean;
}
const getNewD = ({ totalsByDate, relative }: getNewDProps) => {
  let newD = [] as any[];
  Object.keys(totalsByDate).forEach((key, index) => {
    // if relative view for chart; divide each value by the total sum of the date
    if (relative) {
      const totalSum = R.sum(R.values(totalsByDate[key]));
      R.keys(totalsByDate[key]).forEach(item => {
        totalsByDate[key][item] = R.divide(totalsByDate[key][item], totalSum) * 100;
      });
    }
    newD[index] = {};
    newD[index].date = key;

    newD[index] = { ...newD[index], ...totalsByDate[key] };
  });
  return newD;
};

const TEST_COMPANY_INCREMENTALITY = [
  "Direct Mail",
  "Paid Search",
  "Paid Search, Brand",
  "Paid Search, Non Brand",
  "Paid Search, Non-brand",
];

const getIncrementality = (itemName, itemIncrementality, disabledPlatform, companyEqualsTest) => {
  return companyEqualsTest
    ? Boolean(TEST_COMPANY_INCREMENTALITY.includes(capitalizeWords(itemName, disabledPlatform)))
    : itemIncrementality;
};

// Change cptb data into a format that can be used by the chart
// {data: string, name of type: raw_spend}
// also returns areas list from name attribute
// when y axis needs to be calculated from two data points, data1Key/data2Key is used
export const cptbAreaData = ({
  data,
  data1Key,
  groupBy,
  dateGrouping,
  data2Key,
  disabledPlatform,
  relative = false,
  companyEqualsTest = false,
}: {
  data: any[];
  data1Key: string;
  groupBy: string;
  dateGrouping: string;
  data2Key?: string;
  disabledPlatform?: boolean;
  relative?: boolean;
  companyEqualsTest?: boolean;
}): { newD: any[]; areas: any; groupedAreas: any } => {
  let groupedData;
  let grouping;
  let channels;
  let newD = [] as any[];
  let totalsByDate: Record<string, Record<string, number>> = {};

  switch (groupBy) {
    case "channel": {
      const groupByChannel = R.groupBy(item => R.prop(groupBy, item), data);
      channels = Object.keys(groupByChannel);
      groupedData = Object.keys(groupByChannel).map(key => {
        return R.groupBy(item => R.prop("date", item), groupByChannel[key]);
      });
      let channelsData = {} as any;
      groupedData.forEach((item, index) => {
        channelsData[channels[index]] = [] as any[];
        Object.keys(item).forEach(key => {
          const dKeyTotal = item[key].reduce((acc, obj) => {
            // treat raw_spend < 10 as 0
            if (data1Key === "raw_spend" && obj[data1Key] < 10) {
              return acc;
            }
            return acc + obj[data1Key];
          }, 0);

          if (data2Key) {
            const d2KeyTotal = item[key].reduce((acc, obj) => {
              if (data2Key === "raw_spend" && obj[data2Key] < 10) {
                return acc;
              }
              return acc + obj[data2Key];
            }, 0);
            channelsData[channels[index]].push({
              date: key,
              [channels[index]]:
                dKeyTotal / d2KeyTotal === Infinity ? null : dKeyTotal / d2KeyTotal,
            });
          } else {
            channelsData[channels[index]].push({ date: key, [channels[index]]: dKeyTotal });
          }
        });
      });

      const e = Object.keys(channelsData).map(key => {
        return channelsData[key];
      });

      const groupedByDate = R.groupBy(item => R.prop("date", item), e.flat());
      totalsByDate = getTotalsByDate(dateGrouping, groupedByDate);

      newD = getNewD({ totalsByDate, relative });

      break;
    }
    case "platform": {
      // group by channel and then platform
      const d = R.groupBy(item => R.prop("channel", item), data);
      let chanPlat = {} as any;
      Object.keys(d).forEach(key => {
        chanPlat[key] = R.groupBy(item => R.prop("platform", item), d[key]);
      });

      interface TransformedChanPlatData {
        data: any[];
        grouping: {
          channel: string;
          platform: string | null;
        };
      }
      const transformChanPlat = (
        originalData: Record<string, Record<string, Record<string, Record<string, any[]>>>>
      ): Record<string, TransformedChanPlatData> => {
        const transformedData: Record<string, TransformedChanPlatData> = {};

        Object.keys(originalData).forEach(channel => {
          Object.keys(originalData[channel]).forEach(platform => {
            const key = `${channel},${platform}`;
            const data = originalData[channel][platform];
            transformedData[key] = {
              data: [data],
              grouping: {
                channel: capitalizeWords(channel, disabledPlatform),
                platform:
                  platform === "null"
                    ? `All ${capitalizeWords(channel, disabledPlatform)}`
                    : capitalizeWords(platform, disabledPlatform),
              },
            };
          });
        });

        return transformedData;
      };

      const transformedChanPlat = transformChanPlat(chanPlat);

      // format grouped data to have key as [channel, platform]: data instead of channel: {platform: data}
      let formattedChanPlat = {} as any;
      Object.keys(chanPlat).forEach(key => {
        Object.keys(chanPlat[key]).forEach(platform => {
          formattedChanPlat[`${key},${platform}`] = chanPlat[key][platform];
        });
      });

      channels = Object.keys(formattedChanPlat);
      groupedData = Object.keys(formattedChanPlat).map(key => {
        //@ts-ignore
        return R.groupBy(item => R.prop("date", item), formattedChanPlat[key]);
      });
      let nameData = {} as any;
      // sums data1Key values from each object in the array
      // take date grouped data and and turn in into groupedName: {date, groupedName: data}

      groupedData.forEach((item, index) => {
        nameData[channels[index]] = [] as any[];
        Object.keys(item).forEach(key => {
          const dKeyTotal = item[key].reduce((acc, obj) => {
            if (data1Key === "raw_spend" && obj[data1Key] < 10) {
              return acc;
            }
            return acc + obj[data1Key];
          }, 0);
          if (data2Key) {
            const d2KeyTotal = item[key].reduce((acc, obj) => {
              if (data2Key === "raw_spend" && obj[data2Key] < 10) {
                return acc;
              }
              return acc + obj[data2Key];
            }, 0);
            nameData[channels[index]].push({
              date: key,
              [channels[index]]:
                dKeyTotal / d2KeyTotal === Infinity ? null : dKeyTotal / d2KeyTotal,
            });
          } else {
            nameData[channels[index]].push({ date: key, [channels[index]]: dKeyTotal });
          }
        });
      });

      const arr = Object.keys(nameData).map(key => {
        return nameData[key];
      });
      // flatten array of arrays and group by date. Gives {date: [{date, [channel, platform]: value}]}
      const groupByDat = R.groupBy(item => R.prop("date", item), arr.flat());

      grouping = Object.fromEntries(
        Object.keys(transformedChanPlat).map(key => {
          return [key.replace(" ", ""), transformedChanPlat[key].grouping];
        })
      );
      totalsByDate = getTotalsByDate(dateGrouping, groupByDat);

      newD = getNewD({ totalsByDate, relative });

      break;
    }
    default: {
      const groupByChannelPlatformTacticBrand = (
        data: DataItem[]
      ): Record<string, Record<string, Record<string, Record<string, DataItem[]>>>> => {
        const groupedData: Record<
          string,
          Record<string, Record<string, Record<string, DataItem[]>>>
        > = {};

        data.forEach(item => {
          const { channel, platform } = item;
          const tactic = item.tactic ?? "null";
          const brand = item.brand_nonbrand ?? "null";

          if (!groupedData[channel]) {
            groupedData[channel] = {};
          }
          if (!groupedData[channel][platform]) {
            groupedData[channel][platform] = {};
          }
          if (!groupedData[channel][platform][tactic]) {
            groupedData[channel][platform][tactic] = {};
          }
          if (!groupedData[channel][platform][tactic][brand]) {
            groupedData[channel][platform][tactic][brand] = [];
          }
          groupedData[channel][platform][tactic][brand].push(item);
        });

        return groupedData;
      };

      const gd = groupByChannelPlatformTacticBrand(data);

      interface TransformedData {
        data: any[];
        grouping: {
          channel: string;
          platform: string | null;
          tactic: string | null;
          brand_nonbrand: string | null;
        };
      }
      const transformData = (
        originalData: Record<string, Record<string, Record<string, Record<string, any[]>>>>
      ): Record<string, TransformedData> => {
        const transformedData: Record<string, TransformedData> = {};

        Object.keys(originalData).forEach(channel => {
          Object.keys(originalData[channel]).forEach(platform => {
            Object.keys(originalData[channel][platform]).forEach(tactic => {
              Object.keys(originalData[channel][platform][tactic]).forEach(brand => {
                const key = `${channel},${platform},${tactic},${brand}`;
                const data = originalData[channel][platform][tactic][brand];

                if (
                  (platform === "null" || disabledPlatform) &&
                  tactic === "null" &&
                  brand === "null"
                ) {
                  transformedData[key] = {
                    data: data,
                    grouping: {
                      channel: capitalizeWords(channel, disabledPlatform),
                      platform: `All ${capitalizeWords(channel, disabledPlatform)}`,
                      tactic: null,
                      brand_nonbrand: null,
                    },
                  };
                } else {
                  transformedData[key] = {
                    data: data,
                    grouping: {
                      channel: capitalizeWords(channel, disabledPlatform),
                      platform:
                        platform === "null" ? null : capitalizeWords(platform, disabledPlatform),
                      tactic: tactic === "null" ? null : capitalizeWords(tactic, disabledPlatform),
                      brand_nonbrand:
                        brand === "null" ? null : capitalizeWords(brand, disabledPlatform),
                    },
                  };
                }
              });
            });
          });
        });

        return transformedData;
      };

      const transformedData = transformData(gd);

      groupedData = Object.keys(transformedData).map(key => {
        //@ts-ignore
        return R.groupBy(item => R.prop("date", item), transformedData[key].data);
      });

      channels = Object.keys(transformedData);

      let namesData = {} as any;
      groupedData.forEach((item, index) => {
        namesData[channels[index]] = [] as any[];
        Object.keys(item).forEach(key => {
          const dKeyTotal = item[key].reduce((acc, obj) => {
            if (data1Key === "raw_spend" && obj[data1Key] < 10) {
              return acc;
            }
            return acc + obj[data1Key];
          }, 0);
          if (data2Key) {
            const d2KeyTotal = item[key].reduce((acc, obj) => {
              if (data2Key === "raw_spend" && obj[data2Key] < 10) {
                return acc;
              }
              return acc + obj[data2Key];
            }, 0);
            namesData[channels[index]].push({
              date: key,
              [channels[index]]:
                dKeyTotal / d2KeyTotal === Infinity ? null : dKeyTotal / d2KeyTotal,
            });
          } else {
            namesData[channels[index]].push({ date: key, [channels[index]]: dKeyTotal });
          }
        });
      });

      const n = Object.keys(namesData).map(key => {
        return namesData[key];
      });

      const groupByDate = R.groupBy(item => R.prop("date", item), n.flat());

      totalsByDate = getTotalsByDate(dateGrouping, groupByDate);

      grouping = Object.fromEntries(
        Object.keys(transformedData).map(key => {
          return [key, transformedData[key].grouping];
        })
      );

      newD = getNewD({ totalsByDate, relative });

      break;
    }
  }

  const keys = Object.keys(newD[0]).filter(key => key !== "date");

  let areas: {
    name: string;
    dataKey: string;
    grouping: { channel: string; platform: string; tactic: string; brand: string };
    incrementality: boolean;
  }[] = [];
  keys.forEach((key, index) => {
    const incrementalityFind = data.find(item => {
      if (groupBy === "platform") {
        return `${item.channel},${item.platform}` === key;
      }
      if (groupBy === "name") {
        return `${item.channel},${item.platform},${item.tactic},${item.brand_nonbrand}` === key;
      }
      return item[groupBy] === key;
    });

    key = groupBy === "platform" ? key.replace(" ", "") : key;

    const incrementality = companyEqualsTest
      ? Boolean(TEST_COMPANY_INCREMENTALITY.includes(capitalizeWords(key, disabledPlatform)))
      : !incrementalityFind?.incrementality_test;

    areas[index] = {
      dataKey: key,
      grouping: grouping ? grouping[key] : null,
      incrementality,
      name: capitalizeWords(key, disabledPlatform),
    };
  });

  let groupedAreas = [] as any[];
  if (Array.isArray(areas) && areas.length > 0 && areas[0].grouping) {
    groupedAreas = areas.reduce((acc: { channel: string; areas: any[] }[], obj) => {
      const { channel } = obj.grouping || { channel: "unknown" }; // Default to 'unknown' if channel is not present
      const existingGroup = acc.find(item => item.channel === channel);

      if (existingGroup) {
        existingGroup.areas.push(obj);
      } else {
        acc.push({ channel, areas: [obj] });
      }

      return acc;
    }, []);
  }

  areas = R.sortBy(R.compose(R.toLower, R.prop("name")))(areas);
  groupedAreas = R.sortBy(R.compose(R.toLower, R.prop("channel")))(groupedAreas);

  return { newD, areas, groupedAreas };
};

export const cptbSnapShotData = ({
  companyEqualsTest,
  data,
  disabledPlatform,
  groupBy,
  conversionTypeString,
  type,
  kpiType,
}: {
  companyEqualsTest?: boolean;
  data: any[];
  disabledPlatform: boolean | undefined;
  groupBy: string;
  conversionTypeString: string;
  type: string;
  kpiType: string;
  twoStageData?: any[];
}): { barData; titleData } => {
  let groupedData;
  switch (groupBy) {
    case "platform": {
      // Group the data by channel and then by platform
      const chanPlatGrouped = R.pipe(
        //@ts-ignore
        R.groupBy(R.prop("channel")),
        //@ts-ignore
        R.map(R.groupBy(R.prop("platform")))
        //@ts-ignore
      )(data);

      // format grouped data to have key as [channel, platform]: data instead of channel: {platform: data}
      let formattedChanPlat = {} as Record<string, any>;
      Object.keys(chanPlatGrouped as Record<string, any>).forEach((key: string) => {
        Object.keys((chanPlatGrouped as Record<string, any>)[key]).forEach((platform: string) => {
          formattedChanPlat[`${key}, ${platform}`] = [
            (chanPlatGrouped as Record<string, any>)[key][platform],
          ];
        });
      });

      Object.keys(formattedChanPlat).forEach(key => {
        formattedChanPlat[key] = formattedChanPlat[key][0];
      });

      groupedData = formattedChanPlat;
      break;
    }
    default:
      groupedData = R.pipe(
        //@ts-ignore
        R.groupBy(R.prop(groupBy)),
        R.map(R.map(R.omit(["covariates"])))
        //@ts-ignore
      )(data);
  }

  // Sum of predicted_outcome values by grouping
  const sumOfOutcome = Object.keys(groupedData as any[]).map((item: any) => {
    // Sum of predicted_outcome values
    const predictedOutcomeSum = R.pipe(
      //@ts-ignore
      R.pluck("predicted_outcome"),
      R.sum
      //@ts-ignore
    )(groupedData[item]);
    return {
      name: capitalizeWords(item, disabledPlatform),
      value: predictedOutcomeSum,
      incrementality: groupedData[item][0].incrementality_test,
    };
  });
  // Sum of predicted_outcome values by grouping
  const sumOfSpend = Object.keys(groupedData as any[]).map((item: any) => {
    // Sum of predicted_outcome values
    const spendSum = R.pipe(
      //@ts-ignore
      R.pluck("raw_spend"),
      R.sum
      //@ts-ignore
    )(groupedData[item]);

    return {
      name: capitalizeWords(item, disabledPlatform),
      value: spendSum,
    };
  });

  //@ts-ignore
  const sumOfOutcomeAll = R.pipe(R.pluck("value"), R.sum)(sumOfOutcome);
  //@ts-ignore
  const sumOfSpendAll = R.pipe(R.pluck("value"), R.sum)(sumOfSpend);

  const overallCPA = sumOfSpendAll / sumOfOutcomeAll;
  const overallROAS = sumOfOutcomeAll / sumOfSpendAll;

  const calcCpaRoasSpend = Object.keys(groupedData as any[]).map((item: any) => {
    const rawSpendSum = R.pipe(
      //@ts-ignore
      R.pluck("raw_spend"),
      R.sum
      //@ts-ignore
    )(groupedData[item]);

    // Sum of predicted_outcome values
    const predictedOutcomeSum = R.pipe(
      //@ts-ignore
      R.pluck("predicted_outcome"),
      R.sum
      //@ts-ignore
    )(groupedData[item]);

    let spendOverOutcome;
    let outcomeOverSpend;
    const avg = rawSpendSum / predictedOutcomeSum;
    spendOverOutcome = avg === Infinity || isNaN(avg) ? null : avg;
    const outcomeOverSpendAvg = predictedOutcomeSum / rawSpendSum;
    outcomeOverSpend =
      outcomeOverSpendAvg === Infinity || isNaN(outcomeOverSpendAvg) ? null : outcomeOverSpendAvg;

    const spendAndEffectShare = {
      name: capitalizeWords(item, disabledPlatform),
      value: (rawSpendSum / sumOfSpendAll) * 100,
      value2: (predictedOutcomeSum / sumOfOutcomeAll) * 100,
      label: `Spend ${currencyFormatter.format(rawSpendSum)}`,
      label2: {
        top: conversionTypeString,
        bottom:
          kpiType === "revenue"
            ? currencyFormatter.format(predictedOutcomeSum)
            : `${numberFormatter.format(predictedOutcomeSum)}`,
      },
      spendOverOutcome,
      incrementality: getIncrementality(
        item,
        !groupedData[item][0].incrementality_test,
        disabledPlatform,
        companyEqualsTest
      ),
      spend_volume: rawSpendSum,
      effect_volume: predictedOutcomeSum,
    };

    if (type === "spendAndEffectShare") {
      return spendAndEffectShare;
    }

    return {
      name: capitalizeWords(item, disabledPlatform),
      value: type === "roas" ? outcomeOverSpend : spendOverOutcome,
      label:
        type === "roas"
          ? `${(outcomeOverSpend / overallROAS).toFixed(2)} x overall`
          : `${(spendOverOutcome / overallCPA).toFixed(2)} x overall`,
      incrementality: getIncrementality(
        item,
        !groupedData[item][0].incrementality_test,
        disabledPlatform,
        companyEqualsTest
      ),
      labelRawNumber:
        type === "roas" ? outcomeOverSpend / overallROAS : spendOverOutcome / overallCPA,
    };
  });

  const revenueBarData = sumOfOutcome.map((item: any) => {
    return {
      name: item.name,
      value: (item.value / sumOfOutcomeAll) * 100,
      label:
        kpiType === "revenue"
          ? currencyFormatter.format(item.value)
          : `${numberFormatter.format(item.value)}`,
      incrementality: getIncrementality(
        item.name,
        !item.incrementality,
        disabledPlatform,
        companyEqualsTest
      ),
      labelRawNumber: item.value,
    };
  });

  switch (type) {
    case "revenue":
      return { barData: revenueBarData, titleData: null };
    case "costPerAcquisition":
      return {
        barData: calcCpaRoasSpend,
        titleData: currencyFormatter.format(overallCPA),
      };
    case "roas":
      return { barData: calcCpaRoasSpend, titleData: overallROAS.toFixed(2) };
    default:
      return {
        barData: calcCpaRoasSpend,
        titleData: `Total spend to effect ratio ${sumOfSpendAll / sumOfSpendAll}:${
          sumOfOutcomeAll / sumOfSpendAll
        }`,
      };
  }
};
interface StageTwoFileData {
  date: string;
  primary_channel: string;
  secondary_channel: string;
  value: number;
  cat: "Added" | "Reduced" | "Direct";
  type: "Spend" | "Effect";
  primary_channel_incrementality_test: boolean;
}

interface StageTwoGroupedData {
  name: string;
  incrementality: boolean;
  added: null | boolean;
  contributers: Set<string>;
  contributorsSpendSum: number; // a total sum of values nest in obj for convience
  contributorsEffectSum: number;
  totalSpendForChannel: number;
  totalEffectForChannel: number;
  value: number;
  spendRawValue: number;
  effectRawValue: number;
  contributorsSpendSumRawValue: number;
  contributorsEffectSumRawValue: number;
  totalActualizedSpendRawValue: number;
  totalActualizedEffectRawValue: number;
  totalActualizedSpendRawValueLabel: string;
  totalActualizedEffectRawValueLabel: string;
  totalSpendForChannelLabel: string;
  totalEffectForChannelLabel: String;
  value2: number;
  label: string;
  label2: {
    top: string;
    bottom: number | string;
  };
  spendOverOutcome: null | number;
  totalActualizedSpend: number;
  totalActualizedEffect: number;
}

export const cptbTwoStageSnapData = (
  data: StageTwoFileData[],
  disabledPlatform: boolean | undefined,
  conversionTypeString: string,
  kpiType: string,
  covariates: boolean
): any => {
  let totalSpendAllChannels = 0;
  let totalEffectAllChannels = 0;

  const result: { [key: string]: StageTwoGroupedData } = data.reduce((acc, row) => {
    if (!covariates && row.primary_channel === "Covariates") {
      return acc;
    }

    const primaryChannel = capitalizeWords(row.primary_channel, disabledPlatform);
    const secondaryChannel = capitalizeWords(row.secondary_channel, disabledPlatform);

    if (!acc[primaryChannel]) {
      acc[primaryChannel] = {
        name: primaryChannel,
        incrementality: !row.primary_channel_incrementality_test,
        added: null,
        contributers: new Set(),
        contributorsSpendSum: 0,
        contributorsEffectSum: 0,
        totalSpendForChannel: 0,
        totalEffectForChannel: 0,
        value: 0,
        value2: 0,
        label: "",
        label2: {
          top: conversionTypeString,
          bottom: "",
        },
        spendOverOutcome: null,
      };
    }

    if (acc[primaryChannel].added === null && row.cat !== "Direct") {
      acc[primaryChannel].added = row.cat === "Added";
    }
    if (row.value === 0) {
      return acc;
    }

    if (row.cat === "Direct" || row.cat === "Reduced") {
      if (row.type === "Spend") {
        if (row.cat === "Direct") {
          acc[primaryChannel].totalSpendForChannel += row.value;
          totalSpendAllChannels += row.value;
        }
      } else {
        acc[primaryChannel].totalEffectForChannel += row.value;
        totalEffectAllChannels += row.value;
      }
    }

    if (row.cat === "Added" || row.cat === "Reduced") {
      row.type === "Spend"
        ? (acc[primaryChannel].contributorsSpendSum += row.value)
        : (acc[primaryChannel].contributorsEffectSum += row.value);

      acc[primaryChannel].contributers.add(secondaryChannel);
      acc[primaryChannel][`${secondaryChannel}:${row.type}`] =
        (acc[primaryChannel][`${secondaryChannel}:${row.type}`] || 0) + row.value;
    }

    return acc;
  }, {});

  const resultsToArray = Object.values(result).map(channel => {
    channel.spendRawValue =
      channel.totalSpendForChannel - (channel.added ? 0 : channel.contributorsSpendSum);
    if (channel.spendRawValue < 0.0005) {
      channel.spendRawValue = 0;
    }
    channel.value = (channel.spendRawValue / totalSpendAllChannels) * 100;
    channel.effectRawValue =
      channel.totalEffectForChannel - (channel.added ? 0 : channel.contributorsEffectSum);
    channel.value2 = (channel.effectRawValue / totalEffectAllChannels) * 100;

    channel.contributorsSpendSumRawValue = channel.contributorsSpendSum;
    channel.contributorsSpendSum = (channel.contributorsSpendSum / totalSpendAllChannels) * 100;
    channel.contributorsEffectSumRawValue = channel.contributorsEffectSum;
    channel.contributorsEffectSum = (channel.contributorsEffectSum / totalEffectAllChannels) * 100;
    channel.label = currencyFormatter.format(
      channel.totalSpendForChannel + channel.contributorsSpendSum
    );
    channel.label2.bottom =
      kpiType === "revenue"
        ? currencyFormatter.format(channel.totalEffectForChannel + channel.contributorsEffectSum)
        : `${numberFormatter.format(
            channel.totalEffectForChannel + channel.contributorsEffectSum
          )}`;
    channel.spendOverOutcome =
      channel.totalEffectForChannel === 0
        ? 0
        : channel.totalSpendForChannel / channel.totalEffectForChannel;
    channel.totalSpendForChannelLabel = currencyFormatter.format(channel.totalSpendForChannel);
    channel.totalEffectForChannelLabel =
      kpiType === "revenue"
        ? currencyFormatter.format(channel.totalEffectForChannel)
        : `${numberFormatter.format(channel.totalEffectForChannel)}`;

    channel.contributers.forEach(contributor => {
      if (channel[`${contributor}:Spend`]) {
        const spendContribution = (channel[`${contributor}:Spend`] / totalSpendAllChannels) * 100;
        channel[`${contributor}:SpendRawValue`] = channel[`${contributor}:Spend`];
        channel[`${contributor}:Spend`] = spendContribution;
      }
      if (channel[`${contributor}:Effect`]) {
        const effectContribution =
          (channel[`${contributor}:Effect`] / totalEffectAllChannels) * 100;
        channel[`${contributor}:EffectRawValue`] = channel[`${contributor}:Effect`];
        channel[`${contributor}:Effect`] = effectContribution;
      }
    });
    if (channel.added === null) {
      channel.added = false;
    }
    channel.totalActualizedSpend = channel.added
      ? channel.value + channel.contributorsSpendSum
      : channel.value;
    channel.totalActualizedSpendRawValue = channel.added
      ? channel.spendRawValue + channel.contributorsSpendSumRawValue
      : channel.spendRawValue;
    channel.totalActualizedSpendRawValueLabel = currencyFormatter.format(
      channel.totalActualizedSpendRawValue
    );
    channel.totalActualizedEffect = channel.added
      ? channel.value2 + channel.contributorsEffectSum
      : channel.value2;
    channel.totalActualizedEffectRawValue = channel.added
      ? channel.effectRawValue + channel.contributorsEffectSumRawValue
      : channel.effectRawValue;
    channel.totalActualizedEffectRawValueLabel =
      kpiType === "revenue"
        ? currencyFormatter.format(channel.totalActualizedEffectRawValue)
        : `${numberFormatter.format(channel.totalActualizedEffectRawValue)}`;

    return channel;
  });

  const finishedobj = {
    barData: resultsToArray,
    titleData: `Total spend to effect ratio 1:${(
      totalEffectAllChannels / totalSpendAllChannels
    ).toFixed(2)}`,
  };
  return finishedobj;
};

export const decayDataFormatter = (
  data: any[],
  yAxisDataKey: string,
  disabledPlatform: boolean,
  companyEqualsTest?: boolean
): { newD; lines; groupedLines } => {
  const d = R.groupBy(item => R.prop("period", item), data);
  let newD = [] as any[];
  let grouping = [] as any[];

  Object.keys(d).forEach((key, index) => {
    newD[index] = {};
    newD[index].date = key;

    d[key].forEach((item: any) => {
      newD[index][item.name] = item[yAxisDataKey] * 100;
      grouping.push({});

      if (
        (item.platform === null || disabledPlatform) &&
        item.tactic === null &&
        item.brand_nonbrand === null
      ) {
        grouping[index][item.name] = {
          channel: capitalizeWords(item.channel, disabledPlatform),
          platform: `All ${capitalizeWords(item.channel, disabledPlatform)}`,
          tactic: null,
          brand_nonbrand: null,
        };
      } else {
        grouping[index][item.name] = {
          channel: capitalizeWords(item.channel, disabledPlatform),
          platform: capitalizeWords(item.platform, disabledPlatform),
          tactic: capitalizeWords(item.tactic, disabledPlatform),
          brand_nonbrand: capitalizeWords(item.brand_nonbrand, disabledPlatform),
        };
      }
    });
  });

  const keys = Object.keys(newD[0]).filter(key => key !== "date");
  let lines: {
    dataKey: string;
    grouping?: { channel: string; platform: string; tactic: string; brand: string };
    incrementality: boolean;
    name: string;
  }[] = [];
  keys.forEach((key, index) => {
    const incrementalityFind = data.find(item => {
      return item.name === key;
    });

    const incrementality = getIncrementality(
      key,
      !incrementalityFind?.incrementality_test,
      disabledPlatform,
      companyEqualsTest
    );

    lines[index] = {
      name: capitalizeWords(key, disabledPlatform),
      dataKey: key,
      grouping: grouping ? grouping[0][key] : null,
      incrementality,
    };
  });

  let groupedLines = [] as any[];
  if (Array.isArray(lines) && lines.length > 0 && lines[0].grouping) {
    groupedLines = lines.reduce((acc: { channel: string; areas: any[] }[], obj) => {
      const { channel } = obj.grouping || { channel: "unknown" }; // Default to 'unknown' if channel is not present
      const existingGroup = acc.find(item => item.channel === channel);

      if (existingGroup) {
        existingGroup.areas.push(obj);
      } else {
        acc.push({ channel, areas: [obj] });
      }

      return acc;
    }, []);
  }

  return { newD, lines, groupedLines };
};

export const saturationDataFormatter = (
  data: any[],
  disabledPlatform: boolean,
  companyEqualsTest?: boolean
): { newD; medians; groupedLines } => {
  const d = R.groupBy(item => R.prop("name", item), data);

  const medians = {};
  for (const [key, value] of Object.entries(d)) {
    const medianRow = value.find(row => row.median === 1);
    medians[key] = medianRow
      ? { median: medianRow.adstock_spend, dataPoint: medianRow.adstock_spend }
      : { median: null, dataPoint: null };
  }

  const transformedData: {
    name: string;
    data: any[];
    grouping: { channel: string; platform: string; tactic: string; brand_nonbrand: string };
    incrementality: boolean;
  }[] = R.pipe(
    R.toPairs,
    R.map(([name, data]: [string, any[]]) => {
      const channel = capitalizeWords(data[0].channel, disabledPlatform);
      let platform = capitalizeWords(data[0].platform, disabledPlatform);
      const tactic = capitalizeWords(data[0].tactic, disabledPlatform);
      const brand_nonbrand = capitalizeWords(data[0].brand_nonbrand, disabledPlatform);
      if ((!platform || disabledPlatform) && !brand_nonbrand && !tactic) {
        platform = `All ${channel}`;
      }

      const incrementality = getIncrementality(
        name,
        !data[0].incrementality_test,
        disabledPlatform,
        companyEqualsTest
      );

      return {
        name: capitalizeWords(name, disabledPlatform),
        data: data as any[],
        grouping: { channel, platform, tactic, brand_nonbrand },
        incrementality,
      };
    })
  )(d);

  let groupedLines = [] as any[];
  if (Array.isArray(transformedData) && transformedData.length > 0 && transformedData[0].grouping) {
    groupedLines = transformedData.reduce((acc: { channel: string; areas: any[] }[], obj) => {
      const { channel } = obj.grouping || { channel: "unknown" }; // Default to 'unknown' if channel is not present
      const existingGroup = acc.find(item => item.channel === channel);

      if (existingGroup) {
        existingGroup.areas.push(obj);
      } else {
        acc.push({ channel, areas: [obj] });
      }

      return acc;
    }, []);
  }

  return { newD: transformedData, medians, groupedLines };
};

// Turns array of objects, where each object is a row in the table,
// into an array of arrays, where each inner array is a row in the table
// each inner array is an array of objects, where each object is a cell in the table
export const roasDataFormatter = ({
  data,
  tableHeaders,
  disabledPlatform,
  companyEqualsTest,
  kpiType,
}: {
  data: any[];
  tableHeaders: any[];
  disabledPlatform: boolean;
  companyEqualsTest?: boolean;
  kpiType?: string;
}): any[] => {
  let newD = [] as any[];
  let newDimension = [] as any[];

  data.forEach((item: any, i) => {
    newD[i] = [];
    newDimension[i] = [];
    tableHeaders.forEach(header => {
      let label =
        typeof item[header.id] === "number" || item[header.id] === null
          ? item[header.id]
          : capitalizeWords(item[header.id]);
      let formattedLabel;

      switch (header.id) {
        case "next_dollar_effectiveness":
          if (item[header.id] === 0 && kpiType) {
            formattedLabel = kpiType === "revenue" ? "$0" : "0";
          } else if (item[header.id] < 0.01 && kpiType) {
            formattedLabel = kpiType === "revenue" ? "<$0.01" : "<0.01";
          }
          if (item[header.id] >= 0.01 && kpiType) {
            formattedLabel =
              kpiType === "revenue"
                ? formatMoney(Math.abs(item[header.id]), 2)
                : formatNumber(Math.abs(item[header.id]), 2);
          }
          break;
        case "response_previous_qtr_median_spend":
        case "previous_qtr_median_spend":
          if (kpiType === "revenue") {
            if (item[header.id] === 0 && kpiType) {
              formattedLabel = "$0";
            } else if (item[header.id] < 0.5 && kpiType) {
              formattedLabel = "<$1";
            } else if (item[header.id] < 1 && kpiType) {
              formattedLabel = "$1";
            } else if (item[header.id] > 1 && kpiType) {
              formattedLabel = formatMoney(item[header.id], 0);
            }
            break;
          } else {
            if (item[header.id] === 0 && kpiType) {
              formattedLabel = "0";
            } else if (item[header.id] < 0.5 && kpiType) {
              formattedLabel = "<1";
            } else if (item[header.id] < 1 && kpiType) {
              formattedLabel = "1";
            } else if (item[header.id] > 1 && kpiType) {
              formattedLabel = formatNumber(item[header.id], 0);
            }
            break;
          }
        case "name":
          formattedLabel = capitalizeWords(item[header.id], disabledPlatform).replace(/,/g, " –");
          break;
        default:
          if (item[header.id] === 0 && kpiType) {
            formattedLabel = kpiType === "revenue" ? "0" : "$0";
          } else if (item[header.id] < 0.01 && kpiType) {
            formattedLabel = kpiType === "revenue" ? "<0.01" : "<$0.01";
          }
          if (item[header.id] >= 0.01 && kpiType) {
            const convDecimal = item[header.id] > 15 ? 0 : 2;
            formattedLabel =
              kpiType === "revenue"
                ? formatNumber(item[header.id], convDecimal)
                : formatMoney(item[header.id], convDecimal);
          }
          break;
      }

      const incrementality = getIncrementality(
        item.name,
        !item.incrementality_test,
        disabledPlatform,
        companyEqualsTest
      );

      if (header.id === "name") {
        const contentLabel = formattedLabel || formattedLabel === "0" ? `${formattedLabel}` : "--";
        newDimension[i].push({
          [header.dimensionTypeName]: {
            value: label,
            label: contentLabel,
            content: incrementality ? (
              <div className="labelDimensionIconWrapper">
                {contentLabel}
                <div>
                  <MdOutlineWarningAmber
                    style={{
                      color: "orange",
                      marginLeft: "5px",
                      height: "22px",
                      width: "22px",
                    }}
                  />
                </div>
              </div>
            ) : undefined,
          },
        });
      }

      // Label is the value of the cell; value: header.label is the column name
      const contentLabel = formattedLabel || formattedLabel === 0 ? formattedLabel : "--";
      newD[i].push({
        value: label,
        label: contentLabel,
      });
    });
  });

  if (!R.isEmpty(newDimension[0])) {
    return newDimension.flat();
  }

  return newD;
};

export const roasSnapshotData = (
  data: any[],
  disabledPlatform: boolean,
  companyEqualsTest?: boolean
): any[] => {
  return data.map((item: any) => {
    const incrementality = getIncrementality(
      item.name,
      !item.incrementality_test,
      disabledPlatform,
      companyEqualsTest
    );
    return {
      name: capitalizeWords(item.name, disabledPlatform).replace(/,/g, " –"),
      value: item.next_dollar_effectiveness,
      incrementality,
    };
  });
};

// Currently only filtering for platform
export const findGroupByOptions = (data: any[]): { label: string; value: string }[] => {
  const filteredData = data.filter((item: any) => {
    return item.platform !== "total" && item.platform !== "" && item.platform !== null;
  });

  const optionsArray = R.isEmpty(filteredData)
    ? [
        { label: "Channel", value: "channel" },
        { label: "Channel, Tactic, Brand/Nonbrand", value: "name" },
      ]
    : [
        { label: "Channel", value: "channel" },
        { label: "Channel, Platform", value: "platform" },
        { label: "Channel, Platform, Tactic, Brand/Nonbrand", value: "name" },
      ];

  return optionsArray;
};

export const calculateTTMOverallRoasCpa = (data: any[], kpiType: string): number => {
  let totalSpendSum = 0;
  let totalOutcomeSum = 0;
  data.forEach(row => {
    if (row.name !== "Covariates") {
      totalSpendSum += row.raw_spend;
      totalOutcomeSum += row.predicted_outcome;
    }
  });

  if (totalOutcomeSum === 0 || totalOutcomeSum === 0) {
    return 0;
  }

  return kpiType === "conversion"
    ? totalSpendSum / totalOutcomeSum
    : totalOutcomeSum / totalSpendSum;
};
