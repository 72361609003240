import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { NestedOptionConfig, NestedValues } from "./QuestionCard";

interface QuestionCardRadioProps {
  questionNumber: string;
  options: string[];
  nestedOptionsConfig?: { [option: string]: NestedOptionConfig[] };
  value?: string;
  nestedValues?: NestedValues;
  readOnly?: boolean;
  onChange?: (updatedValues: { parentValue: string; nestedValues: NestedValues }) => void;
}

const QuestionCardRadio: React.FC<QuestionCardRadioProps> = ({
  questionNumber,
  options,
  nestedOptionsConfig = {},
  value,
  nestedValues,
  readOnly = false,
  onChange,
}) => {
  const [parentValue, setParentValue] = useState<string>(value || "");
  const [nestedValuesState, setNestedValues] = useState<NestedValues>(nestedValues || {});

  const handleParentChange = (label: string) => {
    setParentValue(label);
    setNestedValues({});
    onChange?.({
      parentValue: label,
      nestedValues: {},
    });
  };

  const renderNestedInput = (parentLabel: string, parentChecked: boolean) => {
    const config = nestedOptionsConfig[parentLabel];
    if (!config) {
      return null;
    }

    return (
      <div className="nestedFields">
        {config.map((nestedConfig, index) => {
          const effectiveNestedValues = nestedValuesState[parentLabel] || {};

          if (nestedConfig.inputType === "textarea") {
            const nestedTextValue = effectiveNestedValues[nestedConfig.label || parentLabel] || "";

            return (
              <Form.Group key={index}>
                {nestedConfig.label && <Form.Label>{nestedConfig.label}</Form.Label>}
                <Form.Control
                  type="text"
                  placeholder={nestedConfig.placeholder || "Enter text..."}
                  value={nestedTextValue === true ? "" : nestedTextValue}
                  onChange={e => {
                    const updatedNestedValues = {
                      ...nestedValuesState,
                      [parentLabel]: {
                        ...nestedValuesState[parentLabel],
                        [nestedConfig.label || parentLabel]: e.target.value,
                      },
                    };
                    setNestedValues(updatedNestedValues);
                    onChange?.({
                      parentValue,
                      nestedValues: updatedNestedValues,
                    });
                  }}
                  disabled={readOnly || !parentChecked}
                />
              </Form.Group>
            );
          }

          if (nestedConfig.inputType === "checkbox") {
            return (
              <Form.Group key={index}>
                {nestedConfig.options?.map((option, idx) => {
                  const isChecked = Boolean(effectiveNestedValues[option]);

                  return (
                    <Form.Check
                      key={idx}
                      type="checkbox"
                      label={option}
                      checked={parentChecked && isChecked}
                      onChange={e => {
                        const updatedNestedValues = {
                          ...nestedValuesState,
                          [parentLabel]: {
                            ...nestedValuesState[parentLabel],
                            [option]: e.target.checked,
                          },
                        };
                        setNestedValues(updatedNestedValues);
                        onChange?.({
                          parentValue,
                          nestedValues: updatedNestedValues,
                        });
                      }}
                      disabled={readOnly || !parentChecked}
                    />
                  );
                })}
              </Form.Group>
            );
          }

          return null;
        })}
      </div>
    );
  };

  return (
    <div className="radioInput fields">
      {options.map((option, index) => {
        const isChecked = parentValue === option;
        return (
          <div key={index} className="radioOption">
            <Form.Check
              type="radio"
              name={questionNumber}
              id={`${questionNumber}-${option}-radio`}
              label={option}
              checked={isChecked}
              onChange={() => handleParentChange(option)}
              disabled={readOnly}
            />
            {renderNestedInput(option, isChecked)}
          </div>
        );
      })}
    </div>
  );
};

export default QuestionCardRadio;
