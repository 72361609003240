import React, { useMemo, useCallback, useContext, useRef } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { MdDelete, MdArrowForward, MdAdd, MdWarning, MdContentCopy } from "react-icons/md";

import { Button, ListGroup, Popover, Tooltip } from "react-bootstrap";

import { useStateFunction } from "../utils/hooks/useData";
import { useScrollbarSizes } from "../utils/hooks/useDOMHelpers";
import { useSetAreYouSure } from "../redux/modals";

import { OldFilterBar, OverlayTrigger } from "../Components";

import {
  OrderViewContext,
  toPrettyDate,
  subFlightsToPropertyList,
  toPrettySpend,
  toPrettyImpressions,
} from "./OrderView";

import "./OrderView.scss";
import { getSeriesColor, computeTextColor } from "../utils/colors";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

const DATE_FORMAT = "yyyy-MM-dd";

export const NEW_VERSION_SEND = "new version";
export const RESEND = "resend";

const FILTER_BAR_OPTIONS = [
  {
    name: "id",
    label: "ID",
  },
  {
    name: "startDate",
    label: "Start Date",
  },
  {
    name: "endDate",
    label: "End Date",
  },
  {
    name: "platform",
    label: "Platform",
  },
  {
    name: "description",
    label: "Description",
  },
  {
    name: "spend",
    label: "Spend",
  },
  {
    name: "cpm",
    label: "CPM",
  },
  {
    name: "impressions",
    label: "Impressions",
  },
  {
    name: "dsp",
    label: "DSP",
  },
  {
    name: "isNew",
    label: "Is New",
  },
  {
    name: "canceled",
    label: "Canceled",
  },
  {
    name: "changed",
    label: "Changed",
  },
];

const TODAY = Dfns.format(DATE_FORMAT, new Date());

const FlightDetails = ({ order, flight, derivedIDColor, enableStreamingBuyingUpdates }) => {
  const setAreYouSure = useSetAreYouSure(true);

  const enableBeeswaxIntegration = useExperimentFlag("enableBeeswaxIntegration");
  const beeswaxIntegrationFlipDate = useExperimentFlag("beeswaxIntegrationFlipDate");
  const placementSpecsExperiment = useExperimentFlag("enablePlacementSpecs");
  const enablePlacementSpecs = useMemo(() => {
    if (R.prop("placementSpecsExperiment", order) === true) {
      return true;
    } else if (R.prop("placementSpecsExperiment", order) === false) {
      return false;
    } else {
      return placementSpecsExperiment;
    }
  }, [order, placementSpecsExperiment]);

  const {
    id,
    isNew,
    platform,
    description,
    content,
    additional,
    startDate,
    endDate,
    dsp,
    spotPosition,
    showTitle,
    created,
    useDescription,
  } = flight;
  const {
    flightChangeMap,
    selectedFlight,
    setSelectedFlight,
    isMultiProperty,
    saveFlightChange,
    setCopiedFlight,
    setSameTab,
    setShowCopiedToast,
  } = useContext(OrderViewContext);
  const flightChanges = useMemo(() => flightChangeMap[id] || {}, [flightChangeMap, id]);
  const flightHasChanges = useMemo(() => Boolean(R.keys(flightChanges).length), [flightChanges]);

  const canceled = useMemo(
    () => (R.isNil(flightChanges.canceled) ? flight.canceled : flightChanges.canceled),
    [flight, flightChanges]
  );

  let variant;
  if (endDate < TODAY) {
    variant = "dark";
  } else if (startDate <= TODAY) {
    variant = "primary";
  }

  const makeEditedItem = useCallback(
    (key, transform, className) => {
      const hasChange = !(isNew || R.isNil(flightChanges[key]));
      let content = transform(flight[key]);
      let changeContent;
      if (hasChange) {
        changeContent = transform(flightChanges[key]);
      }

      return (
        <div key={key} className={className}>
          <div className={hasChange ? "strikethrough" : ""} title={content}>
            {content}
          </div>
          {hasChange && (
            <div className="change" title={changeContent}>
              {changeContent}
            </div>
          )}
        </div>
      );
    },
    [isNew, flightChanges, flight]
  );

  const isSelected = selectedFlight === id;
  const className = useMemo(() => {
    let classes = ["flightRow"];
    if (isNew) {
      classes.push("isNew");
    }
    if (canceled) {
      classes.push("canceled");
    }
    if (variant) {
      classes.push(variant);
    }
    return classes.join(" ");
  }, [isNew, canceled, variant]);

  const details = useMemo(() => {
    let concatenatedDescription = "";
    if (enableStreamingBuyingUpdates && !useDescription) {
      concatenatedDescription = content
        ? `${content}${additional ? ` - ${additional}` : ""}`
        : `${showTitle} | ${spotPosition}${additional ? ` | ${additional}` : ""}`;
    } else {
      concatenatedDescription = showTitle
        ? `${showTitle} | ${spotPosition}${description ? ` | ${description}` : ""}`
        : `${description}`;
    }
    return `${platform}${dsp ? ` | ${dsp}` : ""}${
      concatenatedDescription ? ` | ${concatenatedDescription}` : ""
    }`;
  }, [
    enableStreamingBuyingUpdates,
    useDescription,
    platform,
    content,
    showTitle,
    additional,
    spotPosition,
    dsp,
    description,
  ]);

  const onDiscard = useCallback(
    async e => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await setAreYouSure({
          title: "Discard Changes?",
          message: "Are you sure you want to discard all changes to this flight?",
          cancelText: "Never mind",
          okayText: "Yes, I'm sure",
        });
      } catch (e) {
        return;
      }
      saveFlightChange(flight.id);
    },
    [setAreYouSure, saveFlightChange, flight]
  );

  const derivedPill = useMemo(() => {
    let textColor = "";
    if (derivedIDColor === "new") {
      textColor = "#000000";
    } else {
      textColor = computeTextColor(derivedIDColor);
      if (textColor !== "#000000") {
        textColor = "#FFFFFF";
      }
    }

    let pill = (
      <small
        className="derivedID"
        style={{
          backgroundColor: derivedIDColor === "new" ? "#bbbbbb" : derivedIDColor,
          color: textColor,
        }}
      >
        {flight.derivedNetwork || "NEW FLIGHT"}
      </small>
    );

    if (derivedIDColor === "new") {
      return (
        <OverlayTrigger
          overlay={
            <Popover style={{ pointerEvents: "none" }}>
              <Popover.Content>
                This is a new flight. It won't get a derived ID until the order is saved.
              </Popover.Content>
            </Popover>
          }
          placement="top center"
        >
          {pill}
        </OverlayTrigger>
      );
    }

    return pill;
  }, [derivedIDColor, flight]);

  const copyFlight = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      let copiedFlight = {
        copiedPlatform: flight.platform,
        copiedDescription: flight.description,
        copiedDSP: flight.dsp,
        copiedSpotPosition: flight.spotPosition,
        copiedShowTitle: flight.showTitle,
        copiedCPM: flight.cpm,
        copiedImpressions: flight.impressions,
        copiedAudioBuyType: flight.audioBuyType,
        copiedSpend: flight.spend,
        copiedNumOfSpots: flight.numOfSpots,
        copiedSpotRate: flight.spotRate,
        copiedGeo: flight.geo,
      };

      setSameTab(true);
      setCopiedFlight(copiedFlight);
      setShowCopiedToast(true);
    },
    [flight, setCopiedFlight, setSameTab, setShowCopiedToast]
  );

  return (
    <ListGroup.Item
      as="div"
      action
      active={isSelected}
      className={className}
      onClick={() => setSelectedFlight(isSelected ? null : id)}
      variant={variant}
    >
      <div className="id">
        {id.toString().length === 6 ? id : "New"}
        {enableBeeswaxIntegration && dsp === "Beeswax" && beeswaxIntegrationFlipDate > created && (
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
            overlay={
              <Tooltip>
                <>
                  This flight was created before Beeswax automation was turned on. You will still
                  need to maintain changes manually in Beeswax.
                </>
              </Tooltip>
            }
          >
            <MdWarning color="orange" className="alertIcon" />
          </OverlayTrigger>
        )}
      </div>
      <div className="description" title={details}>
        {details}
        {derivedPill}
      </div>
      {isMultiProperty && makeEditedItem("subFlights", subFlightsToPropertyList, "properties")}
      <div className="dateBox">
        {makeEditedItem("startDate", toPrettyDate, "start")}
        <MdArrowForward className="arrow" />
        {makeEditedItem("endDate", toPrettyDate, "end")}
      </div>
      {makeEditedItem("spend", toPrettySpend, "spend")}
      {makeEditedItem("impressions", toPrettyImpressions, "impressions")}
      {makeEditedItem("cpm", toPrettySpend, "cpm")}
      <div className="actions">
        {!isNew && enablePlacementSpecs && (
          <OverlayTrigger
            overlay={<Tooltip>Copy Flight</Tooltip>}
            placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
          >
            <Button size="sm" variant="dark" onClick={copyFlight}>
              <MdContentCopy />
            </Button>
          </OverlayTrigger>
        )}
        {flightHasChanges && (
          <OverlayTrigger
            overlay={<Tooltip>Discard Changes</Tooltip>}
            placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
          >
            <Button size="sm" variant="danger" onClick={onDiscard}>
              <MdDelete />
            </Button>
          </OverlayTrigger>
        )}
      </div>
    </ListGroup.Item>
  );
};

const FlightList = () => {
  const {
    flightChangeMap,
    order,
    isMultiProperty,
    selectedFlight,
    setSelectedFlight,
    networkInfo,
  } = useContext(OrderViewContext);

  const listRef = useRef();
  const { width: scrollbarWidth } = useScrollbarSizes(listRef);
  const [filter, setFilter] = useStateFunction(() => true);
  const enableStreamingBuyingUpdates = networkInfo?.enableTaxonomyUpdates;

  const orderedFlights = useMemo(() => {
    const { flights } = order;
    const newFlights = R.pipe(R.values, R.filter(R.prop("isNew")))(flightChangeMap);
    return R.sortWith(
      [
        R.descend(R.prop("startDate")),
        R.descend(R.prop("endDate")),
        R.ascend(R.prop("description")),
        R.ascend(R.prop("platform")),
        R.descend(R.prop("id")),
      ],
      [...newFlights, ...flights]
    );
  }, [order, flightChangeMap]);

  const filterBarLines = useMemo(
    () =>
      R.map(
        ({ id, isNew, canceled, subFlights, ...rest }) => ({
          ...rest,
          isNew: Boolean(isNew),
          id: isNew ? "new" : id,
          canceled: canceled || R.path([id, "canceled"], flightChangeMap) ? "canceled" : "false",
          changed: flightChangeMap[id] ? "changed" : "false",
          properties: subFlightsToPropertyList(subFlights),
        }),
        orderedFlights
      ),
    [orderedFlights, flightChangeMap]
  );

  const filteredFlights = useMemo(
    () =>
      R.filter(
        flight =>
          filter({
            ...flight,
            isNew: Boolean(flight.isNew),
            id: flight.isNew ? "new" : flight.id,
            canceled:
              flight.canceled || R.path([flight.id, "canceled"], flightChangeMap)
                ? "canceled"
                : "false",
            changed: flightChangeMap[flight.id] ? "changed" : "false",
            properties: subFlightsToPropertyList(flight.subFlights),
          }) || filter(flightChangeMap[flight.id] || {}),
        orderedFlights
      ),
    [filter, orderedFlights, flightChangeMap]
  );

  let filterBarOptions = [...FILTER_BAR_OPTIONS];
  if (isMultiProperty) {
    filterBarOptions.push({
      name: "properties",
      label: "Properties",
    });
  }

  const totals = useMemo(() => {
    let minStart, maxEnd;
    let spend = 0;
    let impressions = 0;
    for (let flight of filteredFlights) {
      let changes = flightChangeMap[flight.id] || {};
      let start = changes.startDate || flight.startDate;
      if (!minStart || start < minStart) {
        minStart = start;
      }
      let end = changes.endDate || flight.endDate;
      if (!maxEnd || end > maxEnd) {
        maxEnd = end;
      }
      spend += R.isNil(changes.spend) ? flight.spend : changes.spend;
      impressions += R.isNil(changes.impressions) ? flight.impressions : changes.impressions;
    }
    return { start: minStart, end: maxEnd, spend, impressions };
  }, [filteredFlights, flightChangeMap]);

  const derivedColorMap = useMemo(() => {
    const derivedIDs = R.pipe(
      R.pluck("derivedNetwork"),
      R.uniq,
      R.reject(R.isNil),
      R.sortBy(R.identity)
    )(orderedFlights);

    const map = {};
    for (let i = 0; i < derivedIDs.length; ++i) {
      map[derivedIDs[i]] = getSeriesColor(i);
    }
    return map;
  }, [orderedFlights]);

  return (
    <>
      <div className="flightListFilterBar">
        <OldFilterBar onFilter={setFilter} lines={filterBarLines} options={filterBarOptions} />
      </div>
      <div className="flightListScrollBox" ref={listRef}>
        <div className="flightListBox">
          <ListGroup variant="flush" className="listHeader">
            <ListGroup.Item className="flightRow" style={{ marginRight: scrollbarWidth }}>
              <div className="id">Flight ID</div>
              <div className="description">Details</div>
              {isMultiProperty && <div className="properties">Properties</div>}
              <div className="dateBox">Dates</div>
              <div className="spend">Spend</div>
              <div className="impressions">Impressions</div>
              <div className="cpm">CPM</div>
              <div className="actions">
                <Button
                  size="sm"
                  disabled={Boolean(selectedFlight)}
                  onClick={() => setSelectedFlight("new")}
                >
                  <MdAdd />
                </Button>
              </div>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup variant="flush" className="flightList">
            {filteredFlights.map(flight => (
              <FlightDetails
                key={flight.id}
                order={order}
                flight={flight}
                derivedIDColor={derivedColorMap[flight.derivedNetwork] || "new"}
                enableStreamingBuyingUpdates={enableStreamingBuyingUpdates}
              />
            ))}
          </ListGroup>
          <ListGroup variant="flush" className="listTotals">
            <ListGroup.Item className="flightRow" style={{ marginRight: scrollbarWidth }}>
              <div className="id">Totals</div>
              <div className="description" />
              {isMultiProperty && <div className="properties" />}
              <div className="dateBox">
                <div className="start">{totals.start && toPrettyDate(totals.start)}</div>
                <MdArrowForward className="arrow" />
                <div className="end">{totals.end && toPrettyDate(totals.end)}</div>
              </div>
              <div className="spend">{toPrettySpend(totals.spend)}</div>
              <div className="impressions">{toPrettyImpressions(totals.impressions)}</div>
              <div className="cpm" />
              <div className="actions" />
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default FlightList;
