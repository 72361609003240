import "../Performance.scss";
import { computeResolvedDate, TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { DateRange, typedReactMemo } from "../../utils/types";
import { Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { mediaTypesSelector, useCompanyInfo } from "../../redux/company";
import { mergeNumberObjects } from "../../utils/data";
import { navigate, RouteComponentProps } from "@reach/router";
import { overrideDateRange } from "../../utils/test-account-utils";
import { OverviewMetrics, OverviewData } from "../OverviewMetrics";
import { ReactComponent as Save } from "../icons/Save.svg";
import { useDerivedNetworkMap } from "../../redux/networks";
import { useMap } from "../../utils/hooks/useData";
import { useSelector } from "react-redux";
import { useSetError } from "../../redux/modals";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import * as UserRedux from "../../redux/user";
import * as uuid from "uuid";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import ConfigToggle from "../Config/ConfigToggle";
import DateConfig from "../Config/DateConfig";
import OptionsDropdown from "../Config/OptionsDropdown";
import OverviewMetricsConfig from "../Config/OverviewMetricsConfig";
import PerformanceGrid from "../PerformanceGrid";
import PerformanceGridConfig from "../Config/PerformanceGridConfig";
import PerformanceGridSkeleton from "../PerformanceGridSkeleton";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useLocation from "../../utils/hooks/useLocation";
import {
  BPMButton,
  BPMDateRange,
  FilterPane,
  FullPageSpinner,
  KpiPicker,
  OldDropdown,
  Page,
  SuggestionInput,
  useFilterPaneState,
} from "../../Components";
import {
  awaitJSON,
  StreamingPerformanceLambdaFetch,
  YoutubePerformanceLambdaFetch,
} from "../../utils/fetch-utils";
import {
  ALL_GROUP_NAME,
  decodePrettyUrl,
  encodePrettyUrl,
  getBaseURL,
  getGroupOptions,
  getPresetOptions,
  PerformanceContext,
  STANDARD_GROUP_NAME,
} from "../performanceUtils";
import {
  IndexTotalsMap,
  makeFetchKey,
  performanceRowToTableRow,
  RowWithTabularData,
} from "./youtubePerformanceUtils";

interface YoutubePerformanceProps extends RouteComponentProps {
  urlPresetName: string;
}

const YouTubePerformance = typedReactMemo<React.FC<YoutubePerformanceProps>>(
  ({ urlPresetName, location }) => {
    const [editMode, setEditModeRaw] = useState<true | false | "new" | "quick">(false);
    const [groupName, setGroupName] = useState<string>("");
    const [kpi, setKpi] = useState<string>("");
    const [newGroupName, setNewGroupName] = useState<string>("");
    const [newName, setNewName] = useState("");
    const [presetName, setPresetNameRaw] = useState<string>("");
    const [selectGroupOptions, setSelectGroupOptions] = useState<string[]>([]);
    const { company } = useLocation();
    const companyInfo = useCompanyInfo();
    const derivedNetworkMap = useDerivedNetworkMap(company);
    const isInternal = useSelector(UserRedux.isInternalSelector);
    const mediaTypes = useSelector(mediaTypesSelector(company));
    const mediaTypeEnabled = useMemo(() => mediaTypes.includes("youtube"), [mediaTypes]);
    const setError = useSetError();

    const [presetChanges, setPresetChanges] = useState<P.PresetChanges<Y.Preset>>({
      columns: [],
      performanceDimensionColumns: [],
      headers: [],
      adminOnly: false,
    });

    const pickerSetKpi = useCallback(
      (kpi: string) => {
        if (editMode) {
          setPresetChanges(preset => ({ ...preset, globalKpi: kpi }));
        } else {
          setKpi(kpi);
        }
      },
      [editMode]
    );

    const baseUrl = useMemo(() => {
      return getBaseURL(urlPresetName, location);
    }, [urlPresetName, location]);

    const setPresetName = useCallback(
      (name: string) => {
        setPresetNameRaw(name);
        navigate(`${location?.origin}${baseUrl}/${encodePrettyUrl(name)}`);
      },
      [location, baseUrl]
    );

    useEffect(() => {
      setPresetNameRaw(decodePrettyUrl(urlPresetName));
    }, [urlPresetName]);
    const [presets, setPresets] = useState<P.PresetIDGroups[]>();

    const applyGroupSelection = useCallback((groupName: string) => {
      setGroupName(groupName);
    }, []);

    const applyPresetSelection = useCallback(
      (name: string) => {
        // Remove cached dates and use default dates of selected preset.
        localStorage.removeItem(`${company}_youtubePerfDateRange`);
        setPresetName(name);
      },
      [company, setPresetName]
    );

    const presetID = useMemo(() => {
      if (presets) {
        return R.find((preset: P.PresetIDGroups) => preset.name === presetName, presets);
      }
    }, [presets, presetName]);

    const [presetMap, setPresetMap] = useMap<string, Y.Preset | undefined>();

    const preset = presetMap[`${presetID?.id}`];

    const defaultDateState = useMemo(
      () => ({
        start:
          presetChanges.defaultDateState?.start ||
          preset?.defaultDateState?.start ||
          Y.DEFAULT_DATE_RANGE_STATE.start,
        end:
          presetChanges.defaultDateState?.end ||
          preset?.defaultDateState?.end ||
          Y.DEFAULT_DATE_RANGE_STATE.end,
      }),
      [presetChanges, preset]
    );

    const startingDateRange = useMemo(() => {
      const localDateRangeString = localStorage.getItem(`${company}_youtubePerfDateRange`);
      const localDateRange = localDateRangeString ? JSON.parse(localDateRangeString) : null;

      return {
        start: localDateRange?.start || computeResolvedDate(defaultDateState.start),
        end: localDateRange?.end || computeResolvedDate(defaultDateState.end),
      };
    }, [company, defaultDateState]);

    useEffect(() => {
      if (preset) {
        setKpi(preset?.globalKpi || companyInfo.streaming_performance_default_kpi);
        setDates(startingDateRange);
      }
    }, [preset, companyInfo, startingDateRange]);

    const [resolvedKpi, fetchKpi] = useMemo(() => {
      let defaultKpi = companyInfo.streaming_performance_default_kpi;
      let fetchKpi;
      if (preset && !editMode) {
        if (preset.globalKpi) {
          defaultKpi = preset.globalKpi;
        }
        fetchKpi = kpi;
      }

      let resolvedKpi = kpi || defaultKpi;

      if (editMode) {
        resolvedKpi = presetChanges.globalKpi || defaultKpi;
      }

      return [resolvedKpi, fetchKpi];
    }, [preset, companyInfo, kpi, editMode, presetChanges]);

    useEffect(() => {
      if (company && !presets && mediaTypeEnabled) {
        (async () => {
          try {
            let presets: P.PresetIDGroups[] = [];
            let res = await YoutubePerformanceLambdaFetch<Y.GetPresetsParams>("/presets", {
              params: { company },
            });
            let parsedPresets = await awaitJSON(res);
            if (!parsedPresets.errorMessage) {
              presets = parsedPresets as P.PresetIDGroups[];
              setPresets(presets);
            }
            let urlPreset = R.find(
              preset => encodePrettyUrl(preset.name) === urlPresetName,
              presets
            );
            if (urlPreset) {
              setPresetName(urlPreset.name);
              if (urlPreset.groups.length > 0) {
                setGroupName(urlPreset.groups[0]);
              } else {
                setGroupName(ALL_GROUP_NAME);
              }
            } else {
              setPresetName("Ad");
              setGroupName(STANDARD_GROUP_NAME);
            }
          } catch (e) {
            let error = e as Error;
            setError({
              reportError: error,
              message: `Failed to fetch presets. Error: ${error.message}`,
            });
          }
        })();
      }
    }, [company, setError, urlPresetName, presets, setPresetName, mediaTypeEnabled]);

    useEffect(() => {
      if (!preset && presetID) {
        (async () => {
          try {
            let res = await YoutubePerformanceLambdaFetch<Y.GetPresetParams>("/preset", {
              params: {
                id: presetID.id,
                company,
              },
            });
            const row = await awaitJSON<Y.PresetRow>(res);
            setKpi(row.preset.globalKpi || companyInfo.streaming_performance_default_kpi);
            setPresetMap(`${presetID.id}`, row.preset as Y.Preset);
          } catch (e) {
            let error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          }
        })();
      }
    }, [preset, presetID, setError, company, setPresetMap, companyInfo, startingDateRange]);

    const [dates, setDates] = useState<DateRange>();
    const [otherDates, setOtherDates] = useState<DateRange>();

    const onDatePickerChange = useCallback(
      (dates: DateRange) => {
        localStorage.setItem(`${company}_youtubePerfDateRange`, JSON.stringify(dates));
        setDates(dates);
      },
      [company]
    );

    const resetDefaultDates = useCallback(() => {
      localStorage.removeItem(`${company}_youtubePerfDateRange`);
      setDates({
        start: computeResolvedDate(defaultDateState.start),
        end: computeResolvedDate(defaultDateState.end),
      });
    }, [company, defaultDateState]);

    const fetchKey = useMemo(() => {
      if (preset && dates && presetID && fetchKpi) {
        return makeFetchKey(presetID?.id, dates, fetchKpi);
      }
      return "";
    }, [preset, dates, presetID, fetchKpi]);

    const otherDatesFetchKey = useMemo(() => {
      if (preset && otherDates && presetID && fetchKpi) {
        return makeFetchKey(presetID.id, otherDates, fetchKpi);
      }
      return "";
    }, [preset, presetID, fetchKpi, otherDates]);

    const [dataStatusMap, setDataStatusMap] = useMap<string, string | undefined>();
    const [dataMap, setDataMap] = useMap<string, Y.GetPageDataResponse | undefined>();
    const data = dataMap[fetchKey];
    const otherData = dataMap[otherDatesFetchKey];

    const fetchData = useCallback(
      (key, dates) => {
        (async () => {
          try {
            const dateRangeToUse = overrideDateRange(company, dates);
            let params: Y.GetPageDataParams & { agency: string } = {
              ...(dateRangeToUse || {}),
              agency: companyInfo.agency,
              company,
              id: R.defaultTo({ id: 0 }, presetID).id,
            };
            if (fetchKpi) {
              params.kpi = fetchKpi;
            }
            const res = await YoutubePerformanceLambdaFetch<Y.GetPageDataParams>("/", {
              params,
            });
            const data = await awaitJSON<Y.GetPageDataResponse>(res);
            setDataMap(key, data);
          } catch (e) {
            setDataStatusMap(key, "readyToFetch");
            let error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          }
        })();
      },
      [company, companyInfo.agency, fetchKpi, presetID, setDataMap, setDataStatusMap, setError]
    );

    useEffect(() => {
      if (fetchKey && dataStatusMap[fetchKey] === "readyToFetch") {
        if (R.isNil(dataMap[fetchKey])) {
          try {
            fetchData(fetchKey, dates);
          } catch (e) {
            console.error(
              `Failed to fetch performance data for ${company}, ${fetchKey}, ${dates}. Refresh to retry`
            );
            setDataStatusMap(fetchKey, "failed");
          }
        }
        setDataStatusMap(fetchKey, "fetched");
      }
      if (
        fetchKey &&
        otherDatesFetchKey &&
        fetchKey !== otherDatesFetchKey &&
        dataStatusMap[otherDatesFetchKey] === "readyToFetch"
      ) {
        if (R.isNil(dataMap[otherDatesFetchKey])) {
          try {
            fetchData(otherDatesFetchKey, otherDates);
          } catch (e) {
            console.error(
              `Failed to fetch performance data for ${company}, ${otherDatesFetchKey}, ${otherDates}. Refresh to retry`
            );
            setDataStatusMap(otherDatesFetchKey, "failed");
          }
        }
        setDataStatusMap(otherDatesFetchKey, "fetched");
      }
    }, [
      company,
      dataMap,
      dataStatusMap,
      dates,
      fetchData,
      fetchKey,
      otherDates,
      otherDatesFetchKey,
      setDataStatusMap,
    ]);

    useEffect(() => {
      if (fetchKey && R.isNil(dataStatusMap[fetchKey]) && !data) {
        setDataStatusMap(fetchKey, "readyToFetch");
      }
      if (
        fetchKey !== otherDatesFetchKey &&
        otherDates &&
        otherDatesFetchKey &&
        R.isNil(dataStatusMap[otherDatesFetchKey]) &&
        !otherData
      ) {
        setDataStatusMap(otherDatesFetchKey, "readyToFetch");
      }
    }, [
      company,
      data,
      dataStatusMap,
      dates,
      editMode,
      fetchData,
      fetchKey,
      otherData,
      otherDates,
      otherDatesFetchKey,
      presetID,
      setDataMap,
      setDataStatusMap,
      setError,
    ]);

    const [filterState, setFilterState, resetFilterState] = useFilterPaneState();
    const [filterID, setFilterID] = useState<number | undefined>();

    useEffect(() => {
      if (preset) {
        if (preset.filterID) {
          resetFilterState();
          setFilterID(preset.filterID);
        } else {
          setFilterID(undefined);
          if (preset.filterState) {
            setFilterState(preset.filterState);
          }
        }
      } else {
        resetFilterState();
      }
    }, [preset, resetFilterState, setFilterID, setFilterState]);

    const [filterTerm, setFilterTerm] = useState("");

    const [tabularData, otherTabularData] = useMemo(() => {
      if (!(data && preset)) {
        return [undefined, undefined];
      }
      let datas = [data];
      if (otherData) {
        datas.push(otherData);
      }
      return datas.map(data => {
        let indexTotalsMap = {} as IndexTotalsMap;
        let rows: RowWithTabularData[] = [];
        for (let row of data.data) {
          rows.push({
            ...row,
            tabularRow: performanceRowToTableRow(
              row,
              preset.columns,
              indexTotalsMap,
              company,
              resolvedKpi,
              isInternal
            ),
          });
        }
        return rows;
      });
    }, [data, resolvedKpi, preset, company, isInternal, otherData]);

    const otherDataRowMap = useMemo<Record<string, RowWithTabularData | undefined>>(() => {
      if (!otherTabularData) {
        return {};
      }
      let map: Record<string, RowWithTabularData | undefined> = {};
      for (let row of otherTabularData) {
        map[JSON.stringify(row.dimensions)] = row;
      }
      return map;
    }, [otherTabularData]);

    const filteredRows = useMemo(() => {
      if (!(tabularData && filterTerm && preset && derivedNetworkMap)) {
        return tabularData;
      }

      let lcFilterTerm = filterTerm.toLowerCase();
      let rows: RowWithTabularData[] = [];
      for (let row of tabularData) {
        for (let dimCol of preset.performanceDimensionColumns) {
          let check: (row: RowWithTabularData) => boolean;
          check = row =>
            `${row.dimensions[dimCol.dimension] || ""}`.toLowerCase().includes(lcFilterTerm);
          if (check(row)) {
            rows.push(row);
            break;
          }
        }
      }

      return rows;
    }, [filterTerm, tabularData, derivedNetworkMap, preset]);

    const overviewData = useMemo(() => {
      if (!(filteredRows && preset)) {
        return;
      }

      const overviewFetchKey = resolvedKpi;
      const weightedData: any = R.map((row: any) => {
        let weightedRow = row;
        for (let key of R.keys(row.fetches)) {
          // Youtube weighting
          weightedRow.fetches[key].liftProbabilityWeighted =
            row.fetches[key].liftProbability && row.fetches[key].impressions
              ? row.fetches[key].liftProbability * row.fetches[key].impressions
              : 0;
          weightedRow.fetches[key].relativeLiftWeighted =
            row.fetches[key].relativeLift && row.fetches[key].impressions
              ? row.fetches[key].relativeLift * row.fetches[key].impressions
              : 0;
        }
        return weightedRow;
      }, filteredRows);
      let aggregatedData = weightedData.reduce((agg, row) => {
        let data = row.fetches[overviewFetchKey];
        return !data ? agg : mergeNumberObjects(agg, data);
      }, {} as Y.ColumnDataRow);

      aggregatedData.liftProbability =
        aggregatedData.liftProbabilityWeighted / aggregatedData.impressions;
      aggregatedData.relativeLift =
        aggregatedData.relativeLiftWeighted / aggregatedData.impressions;
      const overviewData: OverviewData = {
        imps: aggregatedData.impressions || 0,
        spend: aggregatedData.cost || 0,
        lastUpdated: data?.lastModified || "Unknown",
        ecpm: 0,
        youtube: {
          conversions: aggregatedData.conversions,
          cpa: aggregatedData.cost / aggregatedData.conversions,
          cpm: (aggregatedData.cost * 1000.0) / aggregatedData.impressions,
          revenue: aggregatedData.revenue,
          roas: aggregatedData.revenue / aggregatedData.cost,
        },
      };
      return overviewData;
    }, [filteredRows, preset, resolvedKpi, data?.lastModified]);

    const groupOptions = useMemo(() => {
      let allGroups = getGroupOptions(presets);
      allGroups.push(ALL_GROUP_NAME);
      return allGroups;
    }, [presets]);

    const createViewGroupOptions = useMemo(() => {
      let viewGroupOptions = groupOptions.filter(
        groupName => groupName !== STANDARD_GROUP_NAME && groupName !== ALL_GROUP_NAME
      );
      return viewGroupOptions;
    }, [groupOptions]);

    const presetOptions = useMemo(() => {
      const allPresets = getPresetOptions(presets, groupName);
      return allPresets;
    }, [presets, groupName]);

    const allPresetsNames = useMemo(() => {
      const opts = presets ? presets.map(preset => preset.name) : [];
      return opts;
    }, [presets]);

    const setEditMode = useCallback(
      (val: typeof editMode) => {
        if (preset) {
          setEditModeRaw(val);
          if (presetID && presetID.groups.includes(STANDARD_GROUP_NAME)) {
            setSelectGroupOptions([STANDARD_GROUP_NAME]);
            setNewGroupName(STANDARD_GROUP_NAME);
          } else {
            let selectOptions = groupOptions.filter(
              groupName => groupName !== STANDARD_GROUP_NAME && groupName !== ALL_GROUP_NAME
            );
            setSelectGroupOptions(selectOptions);
            let currentGroupName = presetID && presetID.groups.length > 0 ? presetID.groups[0] : "";
            setNewGroupName(currentGroupName);
          }
        }
        if (!val) {
          setNewName("");
          setNewGroupName("");
          setSelectGroupOptions([]);
          // TODO: what else needs to be reset if they hit cancel?
        }
      },
      [preset, groupOptions, presetID]
    );

    // This effect should only happen when the preset changes
    useEffect(() => {
      if (preset) {
        setPresetChanges(R.omit(["filterState", "filterTokens"], preset));
      }
    }, [preset, editMode]);

    const [saving, setSaving] = useState(false);

    const savePresetToDBAndReload = useCallback(
      async (body: Y.SavePresetParams) => {
        try {
          const res = await YoutubePerformanceLambdaFetch<Y.SavePresetParams>("/preset", {
            method: "POST",
            body,
          });
          const resp = await awaitJSON<P.SavePresetResponse>(res);
          const { name } = resp;
          navigate && navigate(`${location?.origin}${baseUrl}/${encodePrettyUrl(name)}`);
          window.location.reload();
        } catch (e) {
          let error = e as Error;
          setError({
            title: "Couldn't save view",
            message: error.message,
            reportError: error,
          });
          setSaving(false);
        }
      },
      [baseUrl, location, setError]
    );

    const saveView = useCallback(async () => {
      if (preset && presetID) {
        if (editMode !== "quick" && newName === "") {
          setError({
            title: "Blank Name",
            message: "Your view must have a name.",
          });
          return;
        }
        if (
          (newGroupName === STANDARD_GROUP_NAME) !==
          presetID.groups.includes(STANDARD_GROUP_NAME)
        ) {
          setError({
            title: "Protected Group",
            message: "Your view cannot change the default group.",
          });
          return;
        }
        setSaving(true);
        const newPreset: Y.Preset = {
          ...preset,
          ...presetChanges,

          filterState,
          filterID: editMode === "new" ? undefined : filterID,
        };
        let body: Y.SavePresetParams = {
          company,
          companyToUseInDB: company,
          id: editMode === "new" ? "new" : presetID.id,
          preset: newPreset,
        };

        if (editMode === "quick") {
          body.temporary = true;
        } else {
          body.name = newName;
          body.groups = newGroupName ? [newGroupName] : [];
        }
        await savePresetToDBAndReload(body);
      }
    }, [
      company,
      editMode,
      filterID,
      filterState,

      newGroupName,
      newName,
      preset,
      presetChanges,
      presetID,
      savePresetToDBAndReload,
      setError,
    ]);

    const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();

    useEffect(() => {
      if (company && !filterData) {
        (async () => {
          try {
            const res = await YoutubePerformanceLambdaFetch<Y.GetFilterOptionsParams>(
              "/filter_options",
              {
                params: {
                  company,
                } as any,
              }
            );
            const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
            setFilterData(options);
          } catch (e) {
            let error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, filterData, setError]);

    const [kpiMetaData, setKpiMetaData] = useState<P.GetKpiMetaDataResponse>();

    useEffect(() => {
      if (company && !kpiMetaData) {
        (async () => {
          try {
            const res = await StreamingPerformanceLambdaFetch<P.GetKpiMetaDataParams>("/kpis", {
              params: {
                company,
              },
            });
            const kpiMap = await awaitJSON<P.GetKpiMetaDataResponse>(res);
            setKpiMetaData(kpiMap);
          } catch (e) {
            let error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [kpiMetaData, company, setError]);

    const saveFilter = useCallback(
      async newFilterState => {
        if (editMode) {
          setFilterState(newFilterState);
        } else if (preset) {
          setSaving(true);
          let body: Y.SavePresetParams = {
            company,
            companyToUseInDB: company,
            id: "new",
            temporary: true,
            preset: {
              ...preset,
              filterState:
                typeof newFilterState === "function" ? newFilterState(filterState) : newFilterState,
            },
          };
          await savePresetToDBAndReload(body);
        }
      },
      [editMode, preset, setFilterState, company, filterState, savePresetToDBAndReload]
    );

    const setAdminOnly = useCallback(
      newVal => setPresetChanges(current => ({ ...current, adminOnly: newVal })),
      []
    );

    const blankCreativeMap = useMemo(() => {
      return {};
    }, []);

    return (
      <PerformanceContext.Provider
        value={{
          globalBrand: "",
          globalKpi: resolvedKpi,
          kpiMetaData: kpiMetaData || {},
          prefix: "youtube",
          // We don't actually use the below context values for Youtube, we just initialize them
          // to avoid annoying typescript errors down the road because we have a shared context
          // for streaming, linear, and youtube.
          impsBetween: false,
          globalLag: "7d",
        }}
      >
        <Page
          minWidth="500px"
          title={
            <div className="performanceTitle">
              <div>YouTube Performance</div>
              {presets && (
                <div className="viewControls">
                  {editMode !== "quick" &&
                    (editMode ? (
                      <div className="viewGroupControls">
                        <SuggestionInput
                          isDisabled={newGroupName === STANDARD_GROUP_NAME}
                          placeholder="Select Group..."
                          value={newGroupName}
                          options={selectGroupOptions.map(groupOption => ({
                            value: groupOption,
                            label: groupOption,
                          }))}
                          formatCreateLabel={value => `New group: ${value}`}
                          nonEmpty
                          className="groupInput"
                          classNamePrefix="groupInput"
                          maxMenuHeight={250}
                          onChange={option => {
                            if (option !== STANDARD_GROUP_NAME && option !== ALL_GROUP_NAME) {
                              setNewGroupName(option);
                            }
                          }}
                        />
                        <Form.Control
                          size="sm"
                          className="viewNameInput"
                          value={newName}
                          disabled={newGroupName === STANDARD_GROUP_NAME}
                          placeholder="New view name"
                          onChange={e => setNewName(e.currentTarget.value)}
                        />
                      </div>
                    ) : (
                      <div className="multipleDropdowns">
                        <OldDropdown
                          key={uuid.v4()}
                          searchable
                          label="Group"
                          size="sm"
                          className="presetPicker"
                          placeholder="Select Group..."
                          value={groupName}
                          options={groupOptions}
                          onChange={applyGroupSelection}
                        />
                        <OldDropdown
                          key={uuid.v4()}
                          searchable
                          label="View"
                          size="sm"
                          className="presetPicker"
                          placeholder="Select View..."
                          value={presetName}
                          options={presetOptions}
                          onChange={applyPresetSelection}
                        />
                      </div>
                    ))}
                  {preset &&
                    (editMode ? (
                      <div className="editControls">
                        <BPMButton size="sm" icon={<Save />} disabled={saving} onClick={saveView}>
                          {editMode === "quick" ? "Go" : "Save"}
                        </BPMButton>
                        <BPMButton
                          variant="outline-primary"
                          size="sm"
                          icon={<MdClose />}
                          disabled={saving}
                          onClick={() => {
                            setEditMode(false);
                          }}
                        >
                          Cancel
                        </BPMButton>
                      </div>
                    ) : (
                      <OptionsDropdown
                        baseUrl={baseUrl}
                        datePickerDates={dates || startingDateRange}
                        exportCSV={() => {}}
                        groupOptions={createViewGroupOptions}
                        hasExportCSVData={false}
                        isInternal={isInternal}
                        isYoutube={true}
                        location={location}
                        preset={preset}
                        presetID={presetID}
                        presetName={presetName}
                        presetOptions={allPresetsNames}
                        setEditMode={setEditMode}
                        setError={setError}
                        setNewName={setNewName}
                        setPresetChanges={setPresetChanges}
                        setSaving={setSaving}
                      />
                    ))}

                  {filterData && preset && (
                    <FilterPane
                      categories={filterData}
                      company={company}
                      filterID={filterID}
                      highlightWhenFiltered
                      platform={"youtube"}
                      save={saveFilter}
                      setFilterID={setFilterID}
                      setState={setFilterState}
                      state={filterState}
                    />
                  )}
                </div>
              )}
            </div>
          }
          pageType="YouTube Performance"
          actions={
            !editMode &&
            !R.isNil(dates) && (
              <div className="performanceActions">
                {localStorage.getItem(`${company}_youtubePerfDateRange`) && (
                  <BPMButton size="sm" onClick={resetDefaultDates}>
                    Reset Default Dates
                  </BPMButton>
                )}
                <BPMDateRange
                  range={dates}
                  onChange={onDatePickerChange}
                  isDayBlocked={date => date >= TODAY}
                />
              </div>
            )
          }
        >
          <div className="performance">
            {editMode && (
              <div className="miscControlContainer">
                <div
                  className="kpiPickerConfigContainer"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <div className="label">Global KPI and Lag:</div>
                  <KpiPicker
                    bold
                    disableTypeSelector
                    kpi={resolvedKpi}
                    mediaType="youtube"
                    normalTitle
                    onChange={pickerSetKpi}
                  />
                </div>
                <div className="editControls">
                  <ConfigToggle
                    toggledProperty={Boolean(presetChanges.adminOnly)}
                    setToggledProperty={setAdminOnly}
                    toggleText={"Admin Only"}
                    tooltipText={"Clients will not be able to see this preset listed."}
                  />
                </div>
                <DateConfig
                  defaultDateState={defaultDateState}
                  setPresetChanges={setPresetChanges}
                />
                <OverviewMetricsConfig
                  presetChanges={presetChanges}
                  setPresetChanges={setPresetChanges}
                  isYoutube={true}
                />
              </div>
            )}
            {mediaTypeEnabled && !editMode && dates && (
              <OverviewMetrics
                data={overviewData}
                end={dates.end}
                filterTokens={preset?.filterTokens}
                isYoutube
                preset={preset}
                presetID={presetID?.id}
                start={dates.start}
              >
                <div
                  className="overviewPickers"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {preset && kpiMetaData && (
                    <div
                      className="kpiPickerContainer"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <KpiPicker
                        bold
                        disableTypeSelector
                        kpi={resolvedKpi}
                        mediaType="youtube"
                        normalTitle
                        onChange={pickerSetKpi}
                      />
                    </div>
                  )}
                </div>
              </OverviewMetrics>
            )}

            {mediaTypeEnabled ? (
              editMode ? (
                preset && companyInfo.streaming_performance_default_kpi ? (
                  <PerformanceGridConfig
                    preset={preset}
                    presetChanges={presetChanges}
                    setPresetChanges={setPresetChanges}
                    isYoutube={true}
                    isInternal={isInternal}
                  />
                ) : (
                  <PerformanceGridSkeleton />
                )
              ) : preset &&
                filteredRows &&
                companyInfo.streaming_performance_default_kpi &&
                derivedNetworkMap ? (
                <PerformanceGrid
                  creativeMap={blankCreativeMap}
                  data={filteredRows}
                  filterTerm={filterTerm}
                  isYoutube
                  otherDataRowMap={otherDataRowMap}
                  otherDates={otherDates}
                  preset={preset}
                  setFilterTerm={setFilterTerm}
                  setOtherDates={setOtherDates}
                />
              ) : (
                <PerformanceGridSkeleton />
              )
            ) : (
              <div className="notAllowed">This company does not support this media type.</div>
            )}
          </div>
          {saving && <FullPageSpinner transparent fixed />}
        </Page>
      </PerformanceContext.Provider>
    );
  }
);

export default YouTubePerformance;
