import { SheetSize as EmbeddingSheetSize, Size as ExtensionSheetSize, SheetType } from '@tableau/api-external-contract-js';
import { SheetPath } from '@tableau/api-internal-contract-js';

export class SheetInfoImpl {
  public constructor(
    private _name: string,
    private _sheetType: SheetType,
    private _sheetSize: ExtensionSheetSize | EmbeddingSheetSize,
    private _index?: number,
    private _isActive?: boolean,
    private _isHidden?: boolean,
    private _url?: string,
  ) {}

  public get name(): string {
    return this._name;
  }

  public set name(sheetName: string) {
    this._name = sheetName;
  }

  public get sheetSize(): ExtensionSheetSize | EmbeddingSheetSize {
    return this._sheetSize;
  }

  public set sheetSize(value: ExtensionSheetSize | EmbeddingSheetSize) {
    this._sheetSize = value;
  }

  public get sheetType(): SheetType {
    return this._sheetType;
  }

  public get sheetPath(): SheetPath {
    return {
      sheetName: this.name,
      isDashboard: this.sheetType === SheetType.Dashboard,
      // TODO - Stories
    };
  }

  public get index(): number | undefined {
    return this._index;
  }

  public get isActive(): boolean | undefined {
    return this._isActive;
  }

  public set active(active: boolean) {
    this._isActive = active;
  }

  public get isHidden(): boolean | undefined {
    return this._isHidden;
  }

  public get url(): string | undefined {
    return this._url;
  }
}
