import * as Contract from '@tableau/api-external-contract-js';
import { WorksheetImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Dashboard } from './Dashboard';
import { Sheet } from './Sheet';
import { StoryPoint } from './StoryPoint';

export class Worksheet extends Sheet implements Contract.EmbeddingWorksheet {
  public constructor(private _worksheetImpl: WorksheetImpl, _workbookImpl: EmbeddingWorkbookImpl) {
    super(_worksheetImpl, _workbookImpl);
  }

  public get parentDashboard(): Contract.EmbeddingDashboard | null {
    if (this._worksheetImpl.parentDashboard == null) {
      // Worksheet doesn't belong to a dashboard. Return null.
      return null;
    }
    return new Dashboard(this._worksheetImpl.parentDashboard, this._workbookImpl);
  }

  public get parentStoryPoint(): Contract.StoryPoint | null {
    if (this._worksheetImpl.parentStoryPoint == null) {
      // Worksheet doesn't belong to a story point. Return null.
      return null;
    }

    return new StoryPoint(this._worksheetImpl.parentStoryPoint, this._workbookImpl);
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    return this._worksheetImpl.getDataSourcesAsync();
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getSummaryDataAsync(options);
  }

  public getSummaryDataReaderAsync(pageRowCount: number, options: Contract.GetSummaryDataOptions): Promise<Contract.DataTableReader> {
    return this._worksheetImpl.getSummaryDataReaderAsync(pageRowCount, options);
  }

  public getSummaryColumnsInfoAsync(): Promise<Array<Contract.Column>> {
    return this._worksheetImpl.getSummaryColumnsInfoAsync();
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    console.warn(
      'Worksheet.getUnderlyingDataAsync is deprecated. Please use ' +
        'Worksheet.getUnderlyingTablesAsync and Worksheet.getUnderlyingTableDataAsync',
    );
    return this._worksheetImpl.getUnderlyingDataAsync(options);
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    return this._worksheetImpl.getUnderlyingTablesAsync();
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getUnderlyingTableDataAsync(logicalTableId, options);
  }

  public getUnderlyingTableDataReaderAsync(
    logicalTableId: string,
    pageRowCount: number,
    options: Contract.GetUnderlyingDataOptions,
  ): Promise<Contract.DataTableReader> {
    return this._worksheetImpl.getUnderlyingTableDataReaderAsync(logicalTableId, pageRowCount, options);
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    return this._worksheetImpl.getFiltersAsync();
  }

  public applyFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    return this._worksheetImpl.applyFilterAsync(fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    return this._worksheetImpl.applyRangeFilterAsync(fieldName, filterOptions);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    return this._worksheetImpl.clearFilterAsync(fieldName);
  }

  public applyRelativeDateFilterAsync(fieldName: string, options: Contract.RelativeDateFilterOptions): Promise<string> {
    return this._worksheetImpl.applyRelativeDateFilterAsync(fieldName, options);
  }

  public applyHierarchicalFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    return this._worksheetImpl.applyHierarchicalFilterAsync(fieldName, values, updateType, options);
  }

  public clearSelectedMarksAsync(): Promise<void> {
    return this._worksheetImpl.clearSelectedMarksAsync();
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getSelectedMarksAsync();
  }

  public annotateMarkAsync(mark: Contract.MarkInfo, annotationText: string): Promise<void> {
    return this._worksheetImpl.annotateMarkAsync(mark, annotationText);
  }

  public getAnnotationsAsync(): Promise<Array<Contract.Annotation>> {
    return this._worksheetImpl.getAnnotationsAsync();
  }

  public removeAnnotationAsync(annotation: Contract.Annotation): Promise<void> {
    return this._worksheetImpl.removeAnnotationAsync(annotation);
  }

  public selectMarksByValueAsync(
    selections: Array<Contract.SelectionCriteria>,
    selectionUpdateType: Contract.SelectionUpdateType,
  ): Promise<void> {
    return this._worksheetImpl.selectMarksByValueAsync(selections, selectionUpdateType);
  }

  public appendContextMenuAsync(targetMenu: Contract.ApiMenuType, config: Contract.ContextMenuOptions): Promise<string> {
    return this._worksheetImpl.appendContextMenuAsync(targetMenu, config);
  }

  public removeContextMenuAsync(targetMenu: Contract.ApiMenuType, menuItemId: string): Promise<void> {
    return this._worksheetImpl.removeContextMenuAsync(targetMenu, menuItemId);
  }

  public executeContextMenuAsync(targetMenu: Contract.ApiMenuType, menuItemId: string): Promise<void> {
    return this._worksheetImpl.executeContextMenuAsync(targetMenu, menuItemId);
  }

  public renameContextMenuAsync(targetMenu: Contract.ApiMenuType, menuHeader: string, menuDescription: string): Promise<void> {
    return this._worksheetImpl.renameContextMenuAsync(targetMenu, menuHeader, menuDescription);
  }

  public getVisualSpecificationAsync(): Promise<Contract.VisualSpecification> {
    return this._worksheetImpl.getVisualSpecificationAsync();
  }
}
