import "./Dropdown.scss";
import { Dropdown as BSDropdown } from "react-bootstrap";
import { DropdownMenu, DropdownMenuProps } from "../DropdownMenu";
import { DropdownToggle } from "./DropdownToggle";
import { Setter, typedReactMemo } from "../../utils/types";
import * as R from "ramda";
import React, { useMemo, useState } from "react";

export enum DropdownToggleType {
  FILLED = "filled",
  OUTLINED = "outlined",
  EMPTY = "empty",
  WIDGET_TITLE = "widget-title",
}

export interface MenuItem<T> {
  className?: string;
  itemIndex?: number;
  itemOptions?: JSX.Element; // If the page opens a submenu, pass it in here
  label?: string;
  link?: string;
  menuIndex?: number;
  selected?: boolean;
  value: T;
  section?: string;
  customContent?: JSX.Element;
  simpleTooltip?: string;
}

export type DropdownOption<T extends string = string> = T | MenuItem<T>;

interface DropdownProps<T extends string>
  extends Omit<
    DropdownMenuProps<T>,
    "className" | "itemOnClick" | "menuItems" | "size" | "storybook"
  > {
  type?: DropdownToggleType;
  design?: "primary" | "secondary";
  background?: "light" | "dark";
  label?: string | JSX.Element;
  value?: T;
  options: readonly DropdownOption<T>[];
  onChange: Setter<T>;
  leadingIcon?: React.ReactElement;
  size?: "sm" | "lg";
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  show?: boolean;
  setShow?: Setter<boolean>;
  dropdownMenuClassName?: string;
  onToggle?:
    | ((
        isOpen: boolean,
        event: React.SyntheticEvent<BSDropdown, Event>,
        metadata: {
          source: "select" | "click" | "rootClose" | "keydown";
        }
      ) => void)
    | undefined;
  menuRenderOverride?: Record<string, "stringList">; // The key is the menu index. The value is the type of render override. Currently only "stringList" is supported.
}

export const Dropdown = typedReactMemo(
  <T extends string>({
    type = DropdownToggleType.FILLED,
    design = "primary",
    background = "light",
    label,
    value,
    options,
    onChange,
    leadingIcon,
    size = "lg",
    disabled,
    placeholder,
    className,
    show: showInput,
    setShow: setShowInput,
    onToggle: onToggleInput,
    // DropdownMenu props
    applyContent,
    applyOnClick,
    cancelContent,
    cancelOnClick,
    filterBarPlaceholder,
    footer,
    section,
    customContent,
    simpleTooltip,
    footerOnClick,
    hasFilterBar,
    hasTicks,
    id,
    itemOnClick,
    menuItems,
    multiSelect,
    subTitles,
    title,
    dropdownMenuClassName,
    menuRenderOverride,
    ...passthrough
  }: DropdownProps<T>): JSX.Element => {
    const items = useMemo(
      () =>
        options.map(option => {
          if (typeof option === "string") {
            return { label: option, value: option };
          } else {
            return { ...option, label: option.label || option.value };
          }
        }),
      [options]
    );

    const menu = useMemo(() => {
      return (
        <DropdownMenu
          className={`cl-dropdown-menu ${dropdownMenuClassName ? dropdownMenuClassName : null}`}
          menuItems={menuItems ? menuItems : items}
          itemOnClick={(item: MenuItem<T>) => {
            if (onChange) {
              onChange(item.value);
            }
          }}
          applyContent={applyContent}
          applyOnClick={applyOnClick}
          cancelContent={cancelContent}
          cancelOnClick={cancelOnClick}
          filterBarPlaceholder={filterBarPlaceholder}
          footer={footer}
          footerOnClick={footerOnClick}
          hasFilterBar={hasFilterBar}
          hasTicks={hasTicks}
          id={id}
          multiSelect={multiSelect}
          section={section}
          size={size}
          storybook={false}
          subTitles={subTitles}
          title={title}
          menuRenderOverride={menuRenderOverride}
          {...passthrough}
        />
      );
    }, [
      applyContent,
      applyOnClick,
      cancelContent,
      cancelOnClick,
      filterBarPlaceholder,
      footer,
      footerOnClick,
      hasFilterBar,
      hasTicks,
      id,
      items,
      menuItems,
      multiSelect,
      onChange,
      passthrough,
      size,
      subTitles,
      title,
      section,
      dropdownMenuClassName,
      menuRenderOverride,
    ]);

    const [show, setShow] = useState<boolean>(false);

    const onToggle = onToggleInput
      ? onToggleInput
      : (
          isOpen: boolean,
          event: React.SyntheticEvent<BSDropdown, Event>,
          metadata: { source: "select" | "click" | "rootClose" | "keydown" }
        ) => {
          if (!R.isNil(setShowInput)) {
            setShowInput(isOpen);
          } else {
            setShow(isOpen);
          }
        };

    return (
      <BSDropdown className="cl-dropdown" show={R.defaultTo(show, showInput)} onToggle={onToggle}>
        <DropdownToggle
          type={type}
          design={design}
          background={background}
          label={label}
          value={value}
          options={options}
          leadingIcon={leadingIcon}
          size={size}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
        />
        {menu}
      </BSDropdown>
    );
  }
);
