import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Ghost Bidding";

const GhostBidding = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              This process overall is very similar to PSA Testing. The lead time is the same as
              running a PSA test on other streaming networks. It usually takes a couple of days for
              the CS team to set up the calculator and for the Econometrics team to review it. The
              media team then needs 3-4 days to set up a tremor placement. So 2 weeks of lead time
              should be sufficient.
            </div>
            <br />
            <div className="basicText">
              The same sample size calculator used for PSA testing will also work for speccing out a
              ghost bidding test. For more info on this calculator, refer to the following doc:{" "}
              <a href="https://tinuiti.atlassian.net/wiki/spaces/MARKETING/pages/2459467808/Sample+Size+Calculator+PSA+Testing">
                Sample Size Calculator (PSA Testing)
              </a>
              .
            </div>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              ["Incrementality Testing Approaches", "Nexxen Ghost Bidding Analysis Query"]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Nexxen Ghost Bidding",
                  "How It Works": (
                    <ul className="basicList">
                      <li>IP’s are hashed and split into treatment and control groups.</li>
                      <li>
                        After all placement targeting (geo, demo, device, psycho-graphic etc.), the
                        bid optimization is run in the same manner for both groups.
                      </li>
                      <li>
                        In the control group, bids that would have won an auction (had the highest
                        bid price) have their impressions withheld, and the 2nd highest bid
                        impression is served instead.
                      </li>
                      <li>
                        The bid that is withheld has its metadata logged through the Nexxen
                        platform, and these are treated as control “impressions.” These control
                        impressions are treated as we would treat PSA’s in a PSA lift test.
                      </li>
                      <li>
                        This methodology ensures that users in the control group are comparable to
                        users in the treatment group, allowing for a reliable evaluation of the
                        incremental impact of the Nexxen campaign.
                      </li>
                    </ul>
                  ),
                  "Networks Available": (
                    <ul className="basicList">
                      <li>
                        Video
                        <ul>
                          <li>
                            Nexxen Ghost Bidding will enable seamless lift testing on ALL open
                            market and private auction Online Video inventory accessed via the
                            Nexxen platform across all devices and operating systems. This includes
                            campaigns with advanced targeting tactics such as Third-Party and
                            First-Party Audience Targeting/Suppression, Oracle Grapeshot Keyword
                            Targeting, and TV intelligence Technology.
                          </li>
                          <li>
                            We will be able to serve on Samsung TV Plus inventory as well if a
                            client wishes to solely serve within FEP content. Nexxen’s Samsung
                            offering consists of 100% CTV content sourced from the OEM.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Audio
                        <ul>
                          <li>
                            Streaming Audio and Podcast networks that we buy via the Nexxen platform
                            will also be made available for Ghost Bidding.
                          </li>
                          <li>
                            The following networks are able to be activated at this time: ACast,
                            iHeart, TuneIn, Pandora, Spotify, Radio.com (Audacy).
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ),
                  "Levers We Can Pull": (
                    <ul className="basicList">
                      <li>
                        Ghost bidding will mirror whatever settings the Nexxen Placement has. The
                        main lever that we can pull is what percent of users fall into the ghost
                        bidding segment. Otherwise, all settings will match the placement.
                      </li>
                      <li>
                        Once an IP is placed in the control group, it cannot be served real
                        impressions from the same placement. Since this is a new tool, we recommend
                        placing a maximum of 20% of traffic into the control group and only running
                        the test for up to 4 weeks. If this is not a large enough sample size when
                        running the sample size calculator, please reach out to the econometric team
                        so we can work with the ad ops team to figure out the implications of
                        increasing either of these parameters.
                      </li>
                    </ul>
                  ),
                },
              ],
              [
                {
                  flex: 2,
                  label: "Method",
                  name: "Method",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return (
                      //Reuse the channel class from the Playbook.scss
                      <div className="channelNames">
                        <div className="channelName single">
                          <div className="label">{row.Method}</div>
                        </div>
                      </div>
                    );
                  },
                },
                ...R.map(
                  section => ({
                    flex: 3,
                    label: section,
                    name: section,
                    nonInteractive: true,
                    renderer: (row: Record<string, any>): JSX.Element => {
                      return <div className="bulletedList">{row[section]}</div>;
                    },
                  }),
                  ["How It Works", "Networks Available", "Levers We Can Pull"]
                ),
              ],
              650
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />

      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              The standard query that will be used to analyze Nexxen (previously known as Tremor)
              ghost bidding campaigns (available through{" "}
              <a href="https://github.com/TinuitiAnalytics/bigsix-incrementality/blob/main/tremor_ghost_query.sql">
                Github
              </a>
              ). The inputs and outputs are similar to the standard PSA lift test query. This query
              does not include graph matches, a new version of this query to include graph matches
              is currently in progress.
            </div>
            <br />
            <div className="basicHeader">Inputs</div>
            <div className="basicText">timezone: the timezone that the client’s kpi data is in</div>
            <br />
            <div className="basicText">
              derived_id: the BPM network derived_id. In bpm.impressions, this is the network column
              and in streaming_derived_networks, this is the derived_id column. This can also be
              displayed in the streaming performance page if your view is configured to display it.
              The query can be run with multiple derived_id’s like in the standard PSA query, but
              the randomization is done at the derived_id/placement level. So if you are running
              multiple ghost bidding placements on Tremor, then users in the control group of one
              placement may be in the treatment group of another placement.
            </div>
            <br />
            <div className="basicText"> start: start date of lift test</div>
            <br />
            <div className="basicText"> end: end date of lift test</div>
            <br />
            <div className="basicText">
              contamination_start: the start date of the pre-test period that you would like to
              account exposure to a client’s ad. For example, if a test begins on 1/18/21 but you
              would like to remove ip addresses from the control group who were exposed to a
              client’s ad in the week leading up to the test, you would set this to ‘2021-01-11’
            </div>
            <br />
            <div className="basicText"> company: name of client that is running the lift test</div>
            <br />
            <div className="basicText">
              impression_year_months: the year-months that impressions should be queried, inclusive
              of all scrub windows. For example, if a test runs from 2022-11-14 through 2022-11-27
              with a contamination_start date of 2022-10-31 and a post scrub length of 7, then you
              would want to input: ‘2022-10’, ‘2022-11’, ‘2022-12’. Please note: the appropriate
              formatting for this input is yyyy-mm, without any dates. Please ensure that the months
              you select include the first day of the contamination start period through the last
              day of the test.
            </div>
            <br />
            <div className="basicText">
              supress_psa_exposures: this allows ip addresses that saw both a client ad and a psa ad
              to either be included or scrubbed from the exposed group. To scrub IP’s that saw both
              a psa and real ad from the exposed group, make this field: TRUE
            </div>
            <br />
            <div className="basicText">
              To include IP’s that saw both types of ads in the exposed group, make this field:
              FALSE
            </div>
            <br />
            <div className="basicText">
              post_exposure_scrub: this allows the option of removing users from the control group
              who were exposed to real client ads after the test ends. The length of this window is
              determined by the post_test_scrub_window_length input
            </div>
            <br />
            <div className="basicText">
              post_test_scrub_window_length: the length of the window to be used to look for
              exposure to real ads in control group users. For example, if you want to remove users
              who saw an ad until 7 days after the test ended, this value will be 7.
            </div>
            <br />
            <div className="basicText">
              This input will usually be the length of the lag for the test but may also be set to
              30 (the max lag we use) when comparing results across lags. If results are compared
              across different lags, and the post_test_scrub_window_length also changes across lags,
              it can lead to unintuitive incrementality results due to more users being removed from
              the control group as the lag increases.
            </div>
            <br />
            <div className="basicText">
              kpi_table: bpm.kpis_[company] canonical table for client
            </div>
            <br />
            <div className="basicText">
              kpi_name: name of kpi(s) in kpi_table that is being tested
            </div>
            <br />
            <div className="basicText"> lag: lag window for test/client</div>
            <br />
            <div className="basicText"> cpm: cpm of test</div>
            <br />
            <div className="basicHeader">Outputs</div>
            <div className="basicText">
              suppress_psa_exposures: true/false indicating if IP’s exposed to both real and psa ads
              are scrubbed from exposed groups
            </div>
            <br />
            <div className="basicText">
              post_scrub_control_group: true/false indicating if IP’s exposed to real ads from the
              end of the test + post_test_scrub_window_length
            </div>
            <br />
            <div className="basicText">
              matched_exposed_direct: number of filtered matched residential IP’s in the exposed
              group
            </div>
            <br />
            <div className="basicText">
              matched_control_direct: number of filtered matched residential IP’s in the control
              group
            </div>
            <br />
            <div className="basicText">
              matched_exposed_halo: number of matched residential IP’s in the exposed group
              (includes filtered and non filtered)
            </div>
            <br />
            <div className="basicText">
              matched_control_halo: number of matched residential IP’s in the control group
              (includes filtered and non filtered)
            </div>
            <br />
            <div className="basicText">
              n_exposed: number of residential IP’s in the exposed group
            </div>
            <br />
            <div className="basicText">
              n_control: number of residential IP’s in the control group
            </div>
            <br />
            <div className="basicText">
              unmatched_exposed_direct: number of unmatched residential IP’s in the exposed group
              using filtered criteria
            </div>
            <br />
            <div className="basicText">
              unmatched_control_direct: number of unmatched residential IP’s in the control group
              using filtered criteria
            </div>
            <br />
            <div className="basicText">
              unmatched_exposed_halo: number of unmatched residential IP’s in the exposed group
              using +halo criteria
            </div>
            <br />
            <div className="basicText">
              unmatched_control_halo: number of unmatched residential IP’s in the control group
              using +halo criteria
            </div>
            <br />
            <div className="basicText">
              z_direct: calculated z score for direct lift test results
            </div>
            <br />
            <div className="basicText">
              fisher_pvalue_direct: calculated p-value of Fisher’s Exact Test for filtered lift test
              results.
            </div>
            <br />
            <div className="basicText">
              avg_freq: calculated average frequency of exposed group.{" "}
            </div>
            <br />
            <div className="basicText">
              implied_cpa_direct: calculated cost per acquisition using cpm and filtered matched
              responses
            </div>
            <br />
            <div className="basicText">z_halo: calculated z score for halo lift test results</div>
            <br />
            <div className="basicText">
              fisher_pvalue_halo: calculated p-value of Fisher’s Exact Test for halo lift test
              results.
            </div>
            <br />
            <div className="basicText">
              implied_cpa_halo: calculated cost per acquisition using cpm and halo matched responses
            </div>
            <br />
            <div className="basicText">
              direct_exposed_rr_SE: Standard error of the direct exposed response rate
            </div>
            <br />
            <div className="basicText">
              halo_exposed_rr_SE: Standard error of the halo exposed response rate
            </div>
            <br />
            <div className="basicText">
              direct_control_rr_SE: Standard error of the direct control response rate
            </div>
            <br />
            <div className="basicText">
              halo_control_rr_SE: Standard error of the halo control response rate
            </div>
            <br />
            <div className="basicHeader">Example Inputs</div>
            <Img src={`${INC_CDN}/GhostBidding_Params.png`} />
          </div>
        }
        id={"nexxen-ghost-bidding-analysis-query"}
        pageTitle={PAGE_TITLE}
        title={"Nexxen Ghost Bidding Analysis Query"}
      />
    </div>
  );
});

export default GhostBidding;
