import "./BPMTable.scss";
import { Button } from "react-bootstrap";
import { OldFilterBar, OldFilterBar2 } from "../";
import { MdSave } from "react-icons/md";
import { useScrollbarSizes } from "../../utils/hooks/useDOMHelpers";
import { useStateFunction } from "../../utils/hooks/useData";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import { download } from "../../utils/download-utils";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import StickyTable, { basicRenderer, CellRenderer, SuperTopItemType } from "./StickyTable";
import useSortHeader from "./useSortHeader";
import Pagination from "./Pagination";

interface Dimensions {
  height: number;
  width: number;
}

interface FilterOption {
  name: string;
  label: string;
  type?: "string" | "boolean";
}

interface ScrollTo {
  (row: number, col: number): void;
}

interface FilteredData {
  content: boolean | Date | number | string;
  originalRowIndex: number;
}

export interface Header {
  contentGetter?: (data: any) => boolean | Date | number | string;
  default?: boolean | Date | number | string;
  field?: string;
  flex?: boolean | number;
  infoTextParagraphs?: string[];
  isInvalid?: (value) => boolean;
  isMulti?: boolean;
  label?: string;
  minFlexWidth?: number;
  modalFlex?: number;
  modalRow?: R.Ord;
  modalWidth?: number;
  name?: string;
  nonFilterable?: boolean;
  nonInteractive?: boolean;
  optionsGetter?: (data: any) => boolean | Date | number | string;
  renderer?: (value?, index?) => boolean | Date | null | number | string | JSX.Element;
  sortAscending?: boolean;
  sortPriority?: number;
  type?: string;
  width?: number;
  uneditable?: boolean;
  restriction?: (value) => boolean;
}

interface HoverProps {
  onMouseOut?: (args) => {};
  onMouseOver?: (args) => {};
}

interface InnerTableProps<TableDataType, TopDataType, BottomDataType, SuperTopDataType> {
  alternateColors?: boolean;
  bottomRowHeight?: number;
  bottomData?: BottomDataType[];
  bottomRenderer: CellRenderer<BottomDataType> | CellRenderer<React.ReactNode>;
  cellRenderer: CellRenderer<TableDataType>;
  data: TableDataType[][];
  headerHeight?: number;
  headers: Header[];
  height: number;
  minColumnWidth: number;
  overscan?: number;
  rowHeight?: number | ((index) => number);
  rowHoverClass?: string;
  scrollbarSizes: Dimensions;
  scrollToRef?: React.Ref<ScrollTo>;
  superHeaderHeight?: number;
  superHeaders?: SuperTopItemType<SuperTopDataType>[];
  superHeadersRenderer: CellRenderer<SuperTopDataType>;
  topData: TopDataType[];
  topRenderer: CellRenderer<TopDataType>;
  width: number;
}

const InnerTable = <TData, TPData, BDData, STData>(
  props: InnerTableProps<TData, TPData, BDData, STData> & {
    ref?: React.RefObject<HTMLDivElement | HTMLInputElement>;
  }
): JSX.Element => {
  const {
    alternateColors,
    bottomRowHeight,
    bottomData,
    bottomRenderer,
    cellRenderer,
    data,
    headerHeight,
    headers,
    height,
    minColumnWidth,
    overscan,
    ref,
    rowHeight,
    rowHoverClass,
    scrollbarSizes,
    scrollToRef,
    superHeaderHeight,
    superHeaders,
    superHeadersRenderer,
    topData,
    topRenderer,
    width,
  } = props;

  const columnWidth: (index: number) => number = useMemo(() => {
    let nonFlexWidth = scrollbarSizes.width;
    let flexSum = 0;

    let headerSizes: {
      flex?: number;
      minFlexWidth?: number;
      width?: number;
    }[] = [];

    for (let { width, minFlexWidth, flex: flexGeneric } of headers) {
      let flex: number | undefined =
        typeof flexGeneric === "boolean" ? (flexGeneric ? 1 : 0) : flexGeneric;
      let ourWidth = R.isNil(width) ? minColumnWidth : width;
      if (flex) {
        flexSum += flex;
      } else {
        nonFlexWidth += R.isNil(width) ? minColumnWidth : width;
      }
      headerSizes.push({
        flex,
        width: ourWidth,
        minFlexWidth,
      });
    }

    let flexElementTooSmall;
    let flexScraps;
    do {
      flexElementTooSmall = false;
      flexScraps = 0;
      let perFlexUnitWidthRaw = (width - nonFlexWidth) / flexSum;
      let perFlexUnitWidth = Math.floor(perFlexUnitWidthRaw);
      let perFlexUnitScrap = perFlexUnitWidthRaw - perFlexUnitWidth;
      for (let i = 0; i < headerSizes.length; ++i) {
        const { flex, minFlexWidth } = headerSizes[i];
        if (flex) {
          let actualMin = Math.max(minFlexWidth || 0, minColumnWidth || 0);
          let ourWidth = perFlexUnitWidth * flex;
          if (ourWidth < actualMin) {
            flexElementTooSmall = true;
            headerSizes[i] = {
              width: actualMin,
            };
            nonFlexWidth += actualMin;
            flexSum -= flex;
          } else {
            flexScraps += perFlexUnitScrap * flex;
            headerSizes[i] = {
              width: ourWidth,
              minFlexWidth,
              flex,
            };
          }
        }
      }
    } while (flexElementTooSmall);

    let headerWidths = R.pluck("width", headerSizes) as number[];
    let scrapDistribution = Math.floor(flexScraps / headerWidths.length);
    let scrapBonuses = flexScraps % headerWidths.length;
    for (let i = 0; i < headerSizes.length; ++i) {
      headerWidths[i] += scrapDistribution;
      if (scrapBonuses > 1) {
        headerWidths[i] += 1;
        scrapBonuses--;
      }
    }

    return i => headerWidths[i];
  }, [minColumnWidth, width, headers, scrollbarSizes]);
  return (
    <StickyTable
      alternateColors={alternateColors}
      bottomData={bottomData}
      bottomHeight={bottomRowHeight}
      bottomRenderer={bottomRenderer}
      cellRenderer={cellRenderer}
      columnWidth={columnWidth}
      data={data}
      height={height}
      overscan={overscan}
      ref={ref}
      rowHeight={rowHeight}
      rowHoverClass={rowHoverClass}
      scrollToRef={scrollToRef}
      superTopData={superHeaders}
      superTopHeight={superHeaderHeight}
      superTopRenderer={superHeadersRenderer}
      topData={topData}
      topHeight={headerHeight}
      topRenderer={topRenderer}
      width={width}
    />
  );
};

const superHeadersDefaultRenderer = ({ data, style, classes }) => {
  classes.push("BPMSuperHeader");
  // If it's an empty super header, don't add the underline
  if (data) {
    classes.push("BPMSuperHeaderWithData");
  }
  return (
    <div style={style} className={classes.join(" ")}>
      {data}
    </div>
  );
};

interface DefaultTokens {
  advanced?: string[];
  basic?: string[];
}

interface BPMTableProps<TableDataType, SuperTopDataType> {
  additionalControls?: boolean | number | JSX.Element | string;
  additionalFilterOptions?: FilterOption[];
  alternateColors?: boolean;
  bottomRowHeight?: number;
  className?: string;
  cellClassName?: string;
  cellRenderer?: CellRenderer<TableDataType>;
  csvDownload?: string;
  data?: TableDataType[];
  defaultAdvancedFilter?: boolean;
  defaultTokens?: DefaultTokens;
  dynamicTokens?: string[];
  filterBar?: boolean;
  filterBar2?: boolean;
  headerHeight?: number;
  headers?: Header[];
  headersRenderer?: CellRenderer<Header>;
  limit?: number;
  minColumnWidth?: number;
  noRowsRenderer?: (args?) => JSX.Element;
  onCsvChange?: (downloadCsvContents: string) => {} | void;
  onFilteredDataChange?: (value?) => {} | void;
  onUpdateSortParamList?: (any) => {} | void;
  overrideSortParamList?: Record<string, boolean | number | string>[];
  overscan?: number;
  pagination?: boolean;
  rowHeight?: number | ((index) => number);
  rowHoverClass?: string;
  scrollToRef?: React.Ref<ScrollTo>;
  setDynamicTokens?: (() => {} | void) | Dispatch<SetStateAction<any>>;
  superHeaderHeight?: number;
  superHeaders?: SuperTopItemType<SuperTopDataType>[];
  superHeadersRenderer?: CellRenderer<SuperTopDataType>;
  totals?: Partial<TableDataType>;
  totalsRenderer?: CellRenderer<Element | number | string | undefined>;
}

// /*
//    Note for usage: the wrapping elements of a BPMTable need to have height defined,
//    or the Autosizer will malfunction and give the table 0 height.  If your table
//    is not rendering, this is a likely cause!
// */
export const BPMTable = <TData extends {} = {}, STData extends {} = {}>(
  props: BPMTableProps<TData, STData> & {
    ref?: React.RefObject<HTMLDivElement | HTMLInputElement>;
  }
): JSX.Element => {
  const {
    additionalControls,
    additionalFilterOptions = [],
    alternateColors,
    bottomRowHeight,
    className = "",
    cellClassName = "",
    cellRenderer = basicRenderer,
    csvDownload,
    data = [],
    defaultAdvancedFilter = false,
    defaultTokens,
    dynamicTokens = [],
    filterBar = true,
    filterBar2 = false,
    headerHeight,
    headers = [],
    headersRenderer,
    limit = 10,
    minColumnWidth = 100,
    noRowsRenderer,
    onCsvChange = () => {},
    onFilteredDataChange,
    onUpdateSortParamList = () => {},
    overrideSortParamList,
    overscan,
    pagination = false,
    ref,
    rowHeight,
    rowHoverClass,
    scrollToRef,
    setDynamicTokens,
    superHeaderHeight,
    superHeaders,
    superHeadersRenderer = superHeadersDefaultRenderer,
    totals,
    totalsRenderer = basicRenderer,
  } = props;
  const originalData = R.filter(elem => !R.isNil(elem), data);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollbarSizes = useScrollbarSizes(containerRef);
  const [offset, updateOffset] = useState(0);

  const [filter, setFilterRaw] = useStateFunction<(value: any) => boolean>(() => true);

  const setFilter = useCallback(
    (filter: (value: any) => boolean) => {
      if (onFilteredDataChange) {
        onFilteredDataChange(R.filter(filter, originalData));
      }
      setFilterRaw(filter);
    },
    [onFilteredDataChange, originalData, setFilterRaw]
  );

  // Data comes in as an array of objects. The keys on the objects are targets for the filter. We should take an array
  // of headers that include a label and a name. We can map over our data and convert it to a 2d array and filter along
  // the way.
  const filteredData = useMemo(() => {
    let filteredData: FilteredData[][] = [];
    for (let i = 0; i < originalData.length; ++i) {
      let obj = originalData[i];
      if (filter(obj)) {
        let row: FilteredData[] = [];
        for (let { name, contentGetter } of headers) {
          let content = contentGetter ? contentGetter(obj) : obj[R.defaultTo("undefined", name)];
          row.push({
            originalRowIndex: i,
            content: R.isNil(content) ? "" : content,
          });
        }
        filteredData.push(row);
      }
    }
    return filteredData;
  }, [originalData, headers, filter]);

  const topData = useMemo(
    () => R.defaultTo([] as string[], R.pluck("label", headers)) as string[],
    [headers]
  );
  const bottomData = useMemo(
    () => totals && R.map(header => (header && header.name ? totals[header.name] : 0), headers),
    [totals, headers]
  );

  const initialSortParamList = useMemo(() => {
    let sorts: Record<string, boolean | number | string>[] = [];
    for (let i = 0; i < headers.length; ++i) {
      if (!R.isNil(headers[i].sortPriority)) {
        sorts.push({
          index: i,
          ascending: R.defaultTo(false, headers[i].sortAscending),
          priority: headers[i].sortPriority || 0,
        });
      }
    }
    return R.project(
      ["index", "ascending"],
      R.sortBy(elem => R.prop("sortPriority", elem), sorts)
    );
  }, [headers]);

  const [sortedData, topRenderer] = useSortHeader({
    data: filteredData,
    disableMap: R.pluck("nonInteractive", headers),
    getter: (elem: any) => R.prop("content", elem),
    initialSortParamList: R.defaultTo(initialSortParamList, overrideSortParamList),
    onUpdateSortParamList,
    renderer: headersRenderer as any,
    updateOffset,
  }) as [any[], () => JSX.Element];

  const ourCellRenderer = useCallback(
    ({ data, style, classes, columnIndex, rowIndex, onMouseOver, onMouseOut }) => {
      classes = [...classes, cellClassName];
      let { renderer } = headers[columnIndex];
      if (!renderer) {
        return cellRenderer({
          classes,
          columnIndex,
          data: data.content,
          onMouseOut,
          onMouseOver,
          rowData: originalData[data.originalRowIndex],
          rowIndex,
          style,
        });
      } else {
        let hoverProps: HoverProps = {};
        if (onMouseOut) {
          hoverProps.onMouseOut = onMouseOut;
        }
        if (onMouseOver) {
          hoverProps.onMouseOver = onMouseOver;
        }
        return (
          <div className={classes.join(" ")} style={style} {...hoverProps}>
            {renderer(originalData[data.originalRowIndex], rowIndex)}
          </div>
        );
      }
    },
    [headers, originalData, cellRenderer, cellClassName]
  );

  const downloadCsvContents: string = useMemo(() => {
    const headerString = `"${R.join('","', R.pluck("label", headers))}"`;
    const contentArr: string[] = R.map((elem: any[]) => {
      return `"${R.join('","', R.pluck("content", elem))}"`;
    }, sortedData as any[]);
    return R.join("\n", R.prepend(headerString, contentArr));
  }, [sortedData, headers]);

  useEffect(() => {
    onCsvChange(downloadCsvContents);
  }, [downloadCsvContents, onCsvChange]);

  const downloadCSV = useCallback(() => {
    download(downloadCsvContents, `${csvDownload}.csv`, "text/csv");
  }, [downloadCsvContents, csvDownload]);

  return (
    <div ref={containerRef} className={`BPMTable ${className}`}>
      {(filterBar || filterBar2 || csvDownload || additionalControls) && (
        <div className="BPMFilterBarContainer">
          {filterBar && !filterBar2 && (
            <OldFilterBar
              options={
                R.concat(
                  R.filter(
                    (el: Header) => !el.nonInteractive && !el.nonFilterable,
                    R.defaultTo([], headers)
                  ),
                  additionalFilterOptions
                ) as FilterOption[]
              }
              lines={originalData}
              onFilter={setFilter}
              defaultTokens={defaultTokens}
              defaultAdvanced={defaultAdvancedFilter}
              dynamicTokens={dynamicTokens}
              setDynamicTokens={setDynamicTokens}
            />
          )}
          {filterBar2 && (
            <OldFilterBar2
              options={
                R.concat(
                  R.filter(
                    (el: Header) => !el.nonInteractive && !el.nonFilterable,
                    R.defaultTo([], headers)
                  ),
                  additionalFilterOptions
                ) as FilterOption[]
              }
              lines={originalData}
              onFilter={setFilter}
              defaultTokens={defaultTokens}
              defaultAdvanced={defaultAdvancedFilter}
              dynamicTokens={dynamicTokens}
              setDynamicTokens={setDynamicTokens}
            />
          )}
          {csvDownload && (
            <Button variant="outline-primary" onClick={downloadCSV}>
              <MdSave />
            </Button>
          )}
          {additionalControls}
        </div>
      )}
      <div className="BPMTableContainer">
        {sortedData.length || !noRowsRenderer ? (
          <AutoSizer>
            {({ width, height }) => (
              <InnerTable
                alternateColors={alternateColors}
                bottomRowHeight={bottomRowHeight}
                bottomData={bottomData}
                bottomRenderer={totalsRenderer}
                cellRenderer={ourCellRenderer}
                data={pagination ? sortedData.slice(offset, offset + limit) : sortedData}
                headerHeight={headerHeight}
                headers={headers}
                height={height}
                minColumnWidth={minColumnWidth}
                overscan={overscan}
                ref={ref}
                rowHeight={rowHeight}
                rowHoverClass={rowHoverClass}
                scrollbarSizes={scrollbarSizes}
                scrollToRef={scrollToRef}
                superHeaderHeight={superHeaderHeight}
                superHeaders={superHeaders}
                superHeadersRenderer={superHeadersRenderer}
                topData={topData}
                topRenderer={topRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        ) : (
          noRowsRenderer()
        )}
      </div>
      {pagination && updateOffset && (
        <Pagination offset={offset} totalData={data?.length} updateOffset={updateOffset} />
      )}
    </div>
  );
};
