import React, { useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import "./MetaBuyingPublished.scss";
import MetaTableWidget from "./Components/MetaTableWidget";
import { Button, ButtonType, CheckBox, Header, FilterBarTokens } from "../../Components";
import { MdMoreVert, MdOutlineFileDownload, MdRemoveRedEye } from "react-icons/md";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import {
  AdAccountInfo,
  MetaBuyingTableRow,
  CampaignRow,
  AdSetRow,
  AdRow,
  AdsManagerStatus,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";
import {
  UseTableData,
  useFilterBar,
  useGetButtonPosition,
  useMetaBuyingTable,
  UseReviewModal,
  useReviewModal,
  useSelectRow,
  useTableData,
  useTableHeadersRenderer,
  DESELECT_ROW,
  SELECT_ROW,
} from "../MetaBuyingUtils";
import MoreActionsMenu from "./Components/MoreActionsMenu";
import { createPortal } from "react-dom";
import { ReviewModalTab, ReviewModalVariant } from "./Components/ReviewModal";

interface MetaBuyingPublishedProps {
  isInternal: boolean;
  selectedAdAccount: AdAccountInfo;
  setSelectedAdAccount: React.Dispatch<React.SetStateAction<AdAccountInfo>>;
  adAccountOptions: AdAccountInfo[];
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}

const MetaBuyingPublished = ({
  isInternal,
  selectedAdAccount,
  setSelectedAdAccount,
  adAccountOptions,
  campaignRows,
  adSetRows,
  adRows,
}: MetaBuyingPublishedProps & RouteComponentProps): JSX.Element => {
  // Used to manage table data
  const {
    setFilter,
    selectedLevel,
    setSelectedLevel,
    selectedRows,
    setSelectedRows,
    selectAll,
    setSelectAll,
    tableData,
    filteredTableData,
  }: UseTableData = useTableData({
    isInternal,
    campaignRows,
    adSetRows,
    adRows,
  });

  const selectRow: (row: MetaBuyingTableRow, remove?: boolean) => void = useSelectRow({
    setSelectedRows,
    selectedLevel,
  });

  const tableHeadersRenderer: ({ data, columnIndex }: any) => JSX.Element = useTableHeadersRenderer(
    {
      filteredTableData,
      selectAll,
      setSelectAll,
      selectedRows,
      setSelectedRows,
      selectedLevel,
    }
  );

  const buttonRefs = useRef({}); // Object to hold refs for each row
  const getButtonPosition: (
    rowId: string
  ) => {
    top: number;
    left: number;
  } = useGetButtonPosition({ buttonRefs, selectedLevel });
  const menuRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const [activeActionMenuRow, setActiveActionMenuRow] = useState<MetaBuyingTableRow>();
  // Close menu if clicking outside of it
  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        menuRef &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRefs.current[activeActionMenuRow?.id || ""]?.contains(event.target)
      ) {
        const filteredTableRows = Object.values(
          selectedRows[selectedLevel] as Record<string, MetaBuyingTableRow>
        ).filter(row => row.id !== activeActionMenuRow?.id);
        // Filter out current active row from selected rows
        setSelectedRows(current => {
          let newSelectedRows = {};
          for (let row of filteredTableRows) {
            newSelectedRows[row.id] = row;
          }

          return {
            ...current,
            [selectedLevel]: newSelectedRows,
          };
        });
        setActiveActionMenuRow(undefined);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [activeActionMenuRow, selectedLevel, selectedRows, setSelectedRows]);

  // Used to manage review modal
  const {
    setReviewModalActivatedRow,
    reviewModalSetSelectedTab,
    reviewModalComponent,
  }: UseReviewModal = useReviewModal({
    reviewModalVariant: ReviewModalVariant.PUBLISHED,
    selectedAdAccount,
    selectedLevel,
    isInternal,
    selectRow,
  });

  const tableColumns: Header[] = useMemo(() => {
    let headers: Header[] = [
      {
        label: "",
        name: "id",
        width: 40,
        nonInteractive: true,
        renderer: (row: MetaBuyingTableRow) => {
          return (
            <CheckBox
              className="checkboxCell"
              checked={R.has(row.id, selectedRows[selectedLevel])}
              onCheck={() => selectRow(row)}
            />
          );
        },
      },
    ];

    if (!isInternal) {
      headers.push(
        ...[
          {
            label: "Ad",
            name: "ad_name",
            flex: 3,
            renderer: (row: AdRow) => {
              return <div className="adNameCell highlighted">{row.ad_name}</div>;
            },
          },
          {
            label: "Ad Set",
            name: "adset_name",
            flex: 3,
            renderer: (row: AdSetRow) => {
              return <div className="adSetNameCell">{row.adset_name}</div>;
            },
          },
          {
            label: "Campaign",
            name: "campaign_name",
            flex: 3,
            renderer: (row: CampaignRow) => {
              return <div className="campaignNameCell">{row.campaign_name}</div>;
            },
          },
          {
            label: "Created On",
            name: "created",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="createdCell">{row.created}</div>;
            },
          },
          {
            label: "Status",
            name: "adsmanager_status",
            width: 112,
            renderer: (row: MetaBuyingTableRow) => {
              return row.adsmanager_status === AdsManagerStatus.LIVE ? (
                <div className="statusCell">
                  <div className="statusPill live">Live</div>
                </div>
              ) : (
                <div className="statusCell">
                  <div className="statusPill paused">Paused</div>
                </div>
              );
            },
          },
          {
            width: 239,
            nonInteractive: true,
            renderer: (row: AdRow) => {
              return (
                <div className="actionsCell">
                  <Button
                    className="viewAdPreviewButton"
                    variant={ButtonFrameworkVariant.TRAILING_ICON}
                    icon={<MdRemoveRedEye />}
                    type={ButtonType.OUTLINED}
                    size="sm"
                    onClick={() => {
                      setSelectedRows(current => {
                        return { ...current, ad: { [row.id]: row } };
                      });
                      setReviewModalActivatedRow(row);
                      reviewModalSetSelectedTab(ReviewModalTab.AD_PREVIEW);
                    }}
                  >
                    View Ad Preview
                  </Button>
                  <Button
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    icon={<MdOutlineFileDownload />}
                    size="sm"
                    type={ButtonType.FILLED}
                  />
                </div>
              );
            },
          },
        ]
      );
    } else {
      if (selectedLevel === "ad") {
        headers.push(
          ...[
            {
              label: "Ad",
              name: "ad_name",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adNameCell highlighted">{row.ad_name}</div>;
              },
            },
            {
              label: "Ad Set",
              name: "adset_name",
              flex: 4,
              renderer: (row: AdSetRow) => {
                return <div className="adSetNameCell">{row.adset_name}</div>;
              },
            },
            {
              label: "Campaign",
              name: "campaign_name",
              flex: 4,
              renderer: (row: CampaignRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
          ]
        );
      } else if (selectedLevel === "adset") {
        headers.push(
          ...[
            {
              label: "Ad Set",
              name: "adset_name",
              flex: 4,
              renderer: (row: AdSetRow) => {
                return <div className="adSetNameCell highlighted">{row.adset_name}</div>;
              },
            },
            {
              label: "Campaign",
              name: "campaign_name",
              flex: 4,
              renderer: (row: CampaignRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
          ]
        );
      } else {
        headers.push({
          label: "Campaign",
          name: "campaign_name",
          flex: 4,
          renderer: (row: CampaignRow) => {
            return <div className="campaignNameCell highlighted">{row.campaign_name}</div>;
          },
        });
      }

      headers.push(
        ...[
          {
            label: "Created On",
            name: "created",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="createdCell">{row.created}</div>;
            },
          },
          {
            label: "Creator",
            name: "lastuser",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="creatorCell">{row.lastuser}</div>;
            },
          },
        ]
      );

      headers.push(
        {
          label: "Status",
          name: "adsmanager_status",
          width: 112,
          renderer: (row: MetaBuyingTableRow) => {
            return row.adsmanager_status === AdsManagerStatus.LIVE ? (
              <div className="statusCell">
                <div className="statusPill live">Live</div>
              </div>
            ) : (
              <div className="statusCell">
                <div className="statusPill paused">Paused</div>
              </div>
            );
          },
        },
        {
          width: 66,
          nonInteractive: true,
          renderer: (row: MetaBuyingTableRow) => {
            const buttonPosition = getButtonPosition(row.id);
            return (
              <div className="actionsCell">
                <Button
                  ref={el => {
                    buttonRefs.current[row.id] = el;
                  }} // Assign ref for each button
                  variant={ButtonFrameworkVariant.ICON_ONLY}
                  type={ButtonType.FILLED}
                  icon={<MdMoreVert />}
                  size="sm"
                  onClick={() => {
                    if (selectedRows[selectedLevel][row.id] && activeActionMenuRow?.id === row.id) {
                      selectRow(row, DESELECT_ROW);
                    } else {
                      selectRow(row, SELECT_ROW);
                    }
                    setActiveActionMenuRow(activeActionMenuRow?.id === row.id ? undefined : row);
                  }}
                />
                {activeActionMenuRow?.id === row.id &&
                  createPortal(
                    <div
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "white",
                        position: "fixed",
                        top: buttonPosition.top,
                        left: buttonPosition.left,
                        display: "flex",
                        zIndex: 1000,
                      }}
                      ref={menuRef}
                    >
                      <MoreActionsMenu
                        selectedRows={selectedRows}
                        selectedLevel={selectedLevel}
                        tab="published"
                        viewDetailsAction={() => {
                          setSelectedRows(current => {
                            return { ...current, [selectedLevel]: { [row.id]: row } };
                          });
                          reviewModalSetSelectedTab(ReviewModalTab.DETAILS);
                          setActiveActionMenuRow(undefined);
                          setReviewModalActivatedRow(row);
                        }}
                        viewAdPreviewAction={() => {
                          setSelectedRows(current => {
                            return { ...current, [selectedLevel]: { [row.id]: row } };
                          });
                          reviewModalSetSelectedTab(ReviewModalTab.AD_PREVIEW);
                          setActiveActionMenuRow(undefined);
                          setReviewModalActivatedRow(row);
                        }}
                      />
                    </div>,
                    document.body
                  )}
              </div>
            );
          },
        }
      );
    }

    return headers;
  }, [
    activeActionMenuRow,
    getButtonPosition,
    isInternal,
    reviewModalSetSelectedTab,
    selectRow,
    selectedLevel,
    selectedRows,
    setReviewModalActivatedRow,
    setSelectedRows,
  ]);

  const tableComponent: JSX.Element = useMetaBuyingTable({
    className: "publishedTable",
    filteredTableData,
    columns: tableColumns,
    headersRenderer: tableHeadersRenderer,
  });

  const [tokens, setTokens] = useState<FilterBarTokens>({
    basic: [],
    advanced: [],
  });
  const filterBar: JSX.Element = useFilterBar({
    pageTab: "published",
    tableData,
    isInternal,
    selectedLevel,
    setFilter,
    tokens,
    setTokens,
  });

  return (
    <div className="metaBuyingPublished">
      {reviewModalComponent}
      {
        <MetaTableWidget
          isInternal={isInternal}
          title="Published"
          selectedAdAccount={selectedAdAccount}
          setSelectedAdAccount={setSelectedAdAccount}
          adAccountOptions={adAccountOptions}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          filterBar={filterBar}
          setFilter={setFilter}
          tableComponent={tableComponent}
          selectedRows={selectedRows}
          setTokens={setTokens}
        />
      }
    </div>
  );
};

export default MetaBuyingPublished;
