import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Page, Button, CheckBox, ButtonType } from "../Components";
import "./BrandEquity.scss";
import useLocation from "../utils/hooks/useLocation";
import BrandImpact from "./BrandImpact/BrandImpact";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import BusinessImpact from "./BusinessImpact/BusinessImpact";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { useSetAreYouSure, useSetError } from "../redux/modals";
import {
  fetchAndParseData,
  getMostRecentDateAndKPI,
  hasClientFacingOptions,
  hasClientFacingItem,
  removeDashPFromEndOfMetric,
  returnModelRunDates,
  isBrandImpactDataComplete,
  isBusinessImpactDataComplete,
  createUrlForFetch,
  transformTakeawaysImpactRevenue,
} from "./BrandEquityUtils";
import {
  BrandImpactData,
  BusinessImpactData,
  WeeklyRevenueDecomposition,
  TotalRevenueDecomposition,
  NextBestDollar,
  ImplicationData,
  PaidMediaDataRow,
  PaidMediaImpactObject,
  AreaChartDataRow,
  IncrementalRoasObject,
  WeeklyIncrementalRoas,
  IncrementalRoasTakeaways,
  GroupByOptionsBrandEquityMetric,
  RevenueImpactTakeaways,
  SaturationDataUnformatted,
} from "@blisspointmedia/bpm-types/dist/BrandEquity";
import { awaitJSON, MetricsLambdaFetch } from "../utils/fetch-utils";

const enum TabKey {
  BRAND_IMPACT = "brand-impact",
  BUSINESS_IMPACT = "business-impact",
  METRICS = "metrics",
  OFFLINE_INPUTS = "offline-inputs",
  FAQ = "FAQ",
}

const NAVS = [
  { label: "Brand Impact", key: TabKey.BRAND_IMPACT },
  { label: "Business Impact", key: TabKey.BUSINESS_IMPACT },
];

export const GROUP_BY_OPTIONS = [
  { label: "Channel", value: "channel" },
  { label: "Channel, Tactic, Brand/Nonbrand", value: "name" },
];

interface GroupByOptions {
  label: string;
  value: string;
  s3Path: string;
  branch: string;
}

const BrandEquity: React.FC = ({ navigate }: RouteComponentProps) => {
  const setAreYouSure = useSetAreYouSure(true);
  const { company } = useLocation();
  const [groupByLevel, setGroupByLevel] = useState("channel");
  const [groupByMetric, setGroupByMetric] = useState("");
  const isInternalSelector = useSelector(UserRedux.isInternalSelector);

  const [modelRunDate, setModelRunDate] = useState<string>("");
  const [groupByOptionsBrandImpact, setGroupByOptionsBrandImpact] = useState<
    GroupByOptionsBrandEquityMetric[]
  >([]);
  const [groupByOptionsBusinessImpact, setGroupByOptionsBusinessImpact] = useState<
    GroupByOptionsBrandEquityMetric[]
  >([]);
  const [groupByOptionsBrandImpactAll, setGroupByOptionsBrandImpactAll] = useState<
    Record<string, GroupByOptionsBrandEquityMetric[]>
  >({});
  const [groupByOptionsBusinessImpactAll, setGroupByOptionsBusinessImpactAll] = useState<
    Record<string, GroupByOptionsBrandEquityMetric[]>
  >({});
  const setError = useSetError();

  const [brandImpactData, setBrandImpactData] = useState<BrandImpactData>({
    weeklyBrandHealthTimeSeries: [],
    modelOverview: {},
    weeklyBrandHealthAreaChartSpend: {},
    implicationsData: {},
    paidMediaAreaChart: {},
    paidMediaBarChart: {},
    spendAndEffectBarChart: {},
    nextBestDollarBarChart: {},
    saturationData: {},
    decayData: {},
  });

  const [businessImpactData, setBusinessImpactData] = useState<BusinessImpactData>({
    weeklyBrandHealthTimeSeries: [],
    modelOverview: {
      client: "",
      kpi: "",
      kpi_type: "",
      date_granularity: "",
      refresh_date: "",
      refresh_timestamp: "",
      train_start_date: "",
      train_end_date: "",
      test_start_date: "",
      test_end_date: "",
      train_size: 0,
      test_size: 0,
      train_rsquared: 0,
      test_rsquared: 0,
      test_mape: 0,
      test_mae: 0,
      n_observations: 0,
      mean_spend: 0,
      median_spend: 0,
    },
    weeklyRevenueTimeSeries: [],
    totalRevenueDecomposition: [],
    weeklyRevenueDecomposition: [],
    revenueImpactTakeaways: {
      name: "",
      MediaDrivenBrandEquity: 0,
      BrandEquityRevenue: 0,
      Total: 0,
      BrandEquityRevenuePercent: 0,
    },
    weeklyIncrementalRoas: [],
    incrementalRoasTakeaways: { paidMediaSpend: 0, spendInducedBrandRevenue: 0, iRoasTtm: 0 },
    saturationData: {},
    decayData: {},
  });

  const toggleBrandEquityRun = useCallback(
    async (modelDate: string, checked: boolean, kpi?: string) => {
      try {
        await MetricsLambdaFetch("/toggleBrandEquityRun", {
          method: "POST",
          body: {
            company,
            date: modelDate,
            client_facing: checked,
            kpi: kpi ? kpi : "",
          },
        });
        setGroupByOptionsBrandImpact((prevOptions: any) =>
          prevOptions.map((item: any) => ({
            ...item,
            client_facing: !checked,
          }))
        );
        setGroupByOptionsBusinessImpact((prevOptions: any) =>
          prevOptions.map((item: any) => ({
            ...item,
            client_facing: !checked,
          }))
        );
        setGroupByOptionsBrandImpactAll((prevOptions: any) => {
          const updatedOptions = { ...prevOptions };

          if (updatedOptions[modelDate]) {
            updatedOptions[modelDate] = updatedOptions[modelDate].map((item: any) => ({
              ...item,
              client_facing: !checked,
            }));
          }
          return updatedOptions;
        });
        setGroupByOptionsBusinessImpactAll((prevOptions: any) => {
          const updatedOptions = { ...prevOptions };

          if (updatedOptions[modelDate]) {
            updatedOptions[modelDate] = updatedOptions[modelDate].map((item: any) => ({
              ...item,
              client_facing: !checked,
            }));
          }
          return updatedOptions;
        });
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      }
    },
    [company, setError]
  );

  const fetchBrandImpactData = useCallback(
    async (company: string, groupByOptionsBrandImpact: GroupByOptions[]) => {
      try {
        const fetchWeeklyBrandHealthTimeSeries = async () => {
          const weeklyBrandHealthTimeSeriesDataPromises = groupByOptionsBrandImpact.map(
            currentMetric =>
              fetchAndParseData(
                createUrlForFetch(
                  currentMetric.branch,
                  company,
                  modelRunDate,
                  currentMetric.s3Path,
                  "b_actual_vs_predicted.csv.gz"
                ),
                parsedData =>
                  parsedData.map(row => ({
                    date: row.date,
                    [currentMetric.label]: row.actual_kpi,
                  })),
                currentMetric.label
              )
          );
          const weeklyBrandHealthTimeSeriesDataSetsByMetric = await Promise.all(
            weeklyBrandHealthTimeSeriesDataPromises
          );
          const finalWeeklyBrandHealthTimeSeries = weeklyBrandHealthTimeSeriesDataSetsByMetric.reduce(
            (total, current) => {
              current.forEach(row => {
                const existingRow = total.find(r => r.date === row.date);
                if (existingRow) {
                  Object.assign(existingRow, row);
                } else {
                  total.push(row);
                }
              });
              return total;
            },
            [] as any[]
          );
          return finalWeeklyBrandHealthTimeSeries;
        };

        const fetchModelOverview = async () => {
          const modelOverviewDataPromises = groupByOptionsBrandImpact.map(currentMetric =>
            fetchAndParseData(
              createUrlForFetch(
                currentMetric.branch,
                company,
                modelRunDate,
                currentMetric.s3Path,
                "a_model_info.csv.gz"
              ),
              parsedData => {
                const modelOverviewMapped = parsedData.map(row => ({
                  [row.parameter]: row.value,
                }));
                const modelOverviewDestructured = Object.assign({}, ...modelOverviewMapped);
                return {
                  [currentMetric.label]: modelOverviewDestructured,
                };
              },
              currentMetric.label
            )
          );

          const allModelOverviewObjects = await Promise.all(modelOverviewDataPromises);
          const mergedModelOverviewObject = Object.assign({}, ...allModelOverviewObjects);
          return mergedModelOverviewObject;
        };

        const fetchweeklyBrandHealthAreaChart = async () => {
          const weeklyBrandHealthAreaChartDataPromises = groupByOptionsBrandImpact.map(
            currentMetric =>
              fetchAndParseData(
                createUrlForFetch(
                  currentMetric.branch,
                  company,
                  modelRunDate,
                  currentMetric.s3Path,
                  "g_cptb_over_time_direct.csv.gz"
                ),
                parsedData => ({
                  [currentMetric.label]: parsedData.map(row => ({
                    date: row.date,
                    channel: row.channel,
                    platform: row.platform,
                    "brand/nonbrand": row.brand_nonbrand,
                    tactic: row.tactic,
                    spend: row.raw_spend,
                  })),
                }),
                currentMetric.label
              )
          );
          const weeklyBrandHealthAreaChartDataDataSetsByMetric = await Promise.all(
            weeklyBrandHealthAreaChartDataPromises
          );
          const weeklyBrandHealthAreaChartData = weeklyBrandHealthAreaChartDataDataSetsByMetric.reduce(
            (acc, curr) => {
              return { ...acc, ...curr };
            },
            {}
          );
          return weeklyBrandHealthAreaChartData;
        };

        const fetchImplicationsData = async () => {
          const implicationCalculationDataPromises = groupByOptionsBrandImpact.map(currentMetric =>
            fetchAndParseData(
              createUrlForFetch(
                currentMetric.branch,
                company,
                modelRunDate,
                currentMetric.s3Path,
                "k_implications_calculations.csv.gz"
              ),
              parsedData => {
                const implicationsDataMapped = parsedData.map(row => ({
                  spendToIncreaseOnePercent:
                    row.best_incremental_portfolio_spend_total_for_additional_unit,
                }));

                return {
                  [currentMetric.label]: implicationsDataMapped[0],
                };
              },
              currentMetric.label
            )
          );

          const spendPerResponseAndResponsePerSpendObjects = await Promise.all(
            implicationCalculationDataPromises
          );
          const finalImplicationsData: Record<string, ImplicationData> = Object.assign(
            {},
            ...spendPerResponseAndResponsePerSpendObjects
          );

          return finalImplicationsData;
        };

        const fetchPaidMediaAreaObject = async () => {
          const dataPromises = groupByOptionsBrandImpact.map(currentMetric =>
            fetchAndParseData(
              createUrlForFetch(
                currentMetric.branch,
                company,
                modelRunDate,
                currentMetric.s3Path,
                "c_cptb_over_time.csv.gz"
              ),
              parsedData => {
                const series = parsedData.map(row => ({
                  date: row.date,
                  totalPositiveResponse: row.effective_predicted_response_normalized_to_observed,
                  effectivePredictedResponse: row.effective_predicted_response,
                  spendShare: row.effective_spend,
                  channel: row.channel,
                  tactic: row.tactic,
                  "brand/nonbrand": row.brand_nonbrand,
                  name: row.name,
                }));
                return { [currentMetric.label]: series };
              },
              currentMetric.label
            )
          );

          const allPaidMediaImpactObjects = await Promise.all(dataPromises);
          const allPaidMediaImpactObject: PaidMediaImpactObject = Object.assign(
            {},
            ...allPaidMediaImpactObjects
          );

          let finalAreaChartData = {};
          let finalBarChartData = {};
          let finalSpendAndEffect = {};

          Object.entries(allPaidMediaImpactObject).forEach(([groupByMetric, series]) => {
            const seriesArray = series as PaidMediaDataRow[];
            const result = seriesArray.reduce((acc, curr) => {
              const { date, totalPositiveResponse, name } = curr;
              if (!acc[date]) {
                acc[date] = {
                  date,
                  PaidMedia: 0,
                  OtherFactors: 0,
                  Total: 0,
                } as AreaChartDataRow;
              }
              if (name === "Covariates") {
                acc[date].OtherFactors += totalPositiveResponse || 0;
              } else {
                acc[date].PaidMedia += totalPositiveResponse || 0;
              }
              acc[date].Total += totalPositiveResponse || 0;
              return acc;
            }, {} as Record<string, AreaChartDataRow>);

            const areaChartData = Object.values(result);
            const barChartUnformatted = areaChartData.reduce(
              (acc, row) => {
                acc.PaidMediaSum += row.PaidMedia;
                acc.OtherFactorsSum += row.OtherFactors;
                acc.TotalSum += row.Total;
                return acc;
              },
              { PaidMediaSum: 0, OtherFactorsSum: 0, TotalSum: 0 }
            );

            const barChartFinal = {
              PaidMedia: barChartUnformatted.PaidMediaSum / barChartUnformatted.TotalSum,
              OtherFactors: barChartUnformatted.OtherFactorsSum / barChartUnformatted.TotalSum,
              key: groupByMetric,
            };

            finalAreaChartData[groupByMetric] = areaChartData;
            finalBarChartData[groupByMetric] = barChartFinal;
            finalSpendAndEffect[groupByMetric] = series;
          });
          return {
            finalAreaChartData,
            finalBarChartData,
            finalSpendAndEffect,
          };
        };

        const fetchFinalNextBestDollar = async () => {
          const dataPromises = groupByOptionsBrandImpact.map(currentMetric =>
            fetchAndParseData(
              createUrlForFetch(
                currentMetric.branch,
                company,
                modelRunDate,
                currentMetric.s3Path,
                "k_implications_calculations.csv.gz"
              ),
              parsedData => {
                const series = parsedData.map(row => ({
                  label: "Spend",
                  channel: row.channel,
                  tactic: row.tactic,
                  "brand/nonbrand": row.brand_nonbrand,
                  value: row.best_incremental_portfolio_allocation_for_additional_unit,
                }));
                return { [currentMetric.label]: series };
              },
              currentMetric.label
            )
          );

          const allNextBestDollarObjects = await Promise.all(dataPromises);
          const allNextBestDollarObject: Record<string, NextBestDollar[]> = Object.assign(
            {},
            ...allNextBestDollarObjects
          );

          let finalNextBestDollar = {};

          Object.entries(allNextBestDollarObject).forEach(([groupByMetric, series]) => {
            finalNextBestDollar[groupByMetric] = series;
          });

          return finalNextBestDollar;
        };

        const fetchSaturationData = async () => {
          const saturationDataPromises = groupByOptionsBrandImpact.map(currentMetric =>
            fetchAndParseData(
              createUrlForFetch(
                currentMetric.branch,
                company,
                modelRunDate,
                currentMetric.s3Path,
                "e_saturation_curves.csv.gz"
              ),
              parsedData => {
                const saturationDataMapped: SaturationDataUnformatted[] = parsedData.map(row => ({
                  channel: row.channel,
                  tactic: row.tactic,
                  "brand/nonbrand": row.brand_nonbrand,
                  spend: row.effective_adstock_spend,
                  response: row.effective_response,
                }));
                return {
                  [currentMetric.label]: saturationDataMapped,
                };
              },
              currentMetric.label
            )
          );

          const allSaturationObjects = await Promise.all(saturationDataPromises);
          const mergedSaturationData = Object.assign({}, ...allSaturationObjects);
          return mergedSaturationData;
        };

        const fetchDecayData = async () => {
          const decayDataPromises = groupByOptionsBrandImpact.map(currentMetric =>
            fetchAndParseData(
              createUrlForFetch(
                currentMetric.branch,
                company,
                modelRunDate,
                currentMetric.s3Path,
                "f_spend_decay_curves.csv.gz"
              ),
              parsedData => {
                return {
                  [currentMetric.label]: parsedData,
                };
              },
              currentMetric.label
            )
          );

          const allDecayObjects = await Promise.all(decayDataPromises);
          const mergedDecayData = Object.assign({}, ...allDecayObjects);
          return mergedDecayData;
        };

        const [
          weeklyBrandHealthTimeSeries,
          modelOverview,
          weeklyBrandHealthAreaChartData,
          implicationsData,
          paidMediaAreaObjectTwo,
          finalNextBestDollarTwo,
          saturationData,
          decayData,
        ] = await Promise.all([
          fetchWeeklyBrandHealthTimeSeries(),
          fetchModelOverview(),
          fetchweeklyBrandHealthAreaChart(),
          fetchImplicationsData(),
          fetchPaidMediaAreaObject(),
          fetchFinalNextBestDollar(),
          fetchSaturationData(),
          fetchDecayData(),
        ]);
        setBrandImpactData({
          weeklyBrandHealthTimeSeries: weeklyBrandHealthTimeSeries,
          modelOverview: modelOverview,
          weeklyBrandHealthAreaChartSpend: weeklyBrandHealthAreaChartData,
          implicationsData,
          paidMediaAreaChart: paidMediaAreaObjectTwo.finalAreaChartData,
          paidMediaBarChart: paidMediaAreaObjectTwo.finalBarChartData,
          spendAndEffectBarChart: paidMediaAreaObjectTwo.finalSpendAndEffect,
          nextBestDollarBarChart: finalNextBestDollarTwo,
          saturationData,
          decayData,
        });
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    },
    [modelRunDate]
  );

  const fetchBusinessImpactData = useCallback(
    async (
      company: string,
      groupByOptionsBusinessImpact: GroupByOptions[],
      groupByOptionsBrandImpact: GroupByOptions[]
    ) => {
      try {
        const fetchWeeklyBrandHealthTimeSeries = async () => {
          const weeklyBrandHealthTimeSeriesDataPromises = groupByOptionsBrandImpact.map(
            currentMetric =>
              fetchAndParseData(
                createUrlForFetch(
                  currentMetric.branch,
                  company,
                  modelRunDate,
                  currentMetric.s3Path,
                  "b_actual_vs_predicted.csv.gz"
                ),
                parsedData =>
                  parsedData.map(row => ({
                    date: row.date,
                    [currentMetric.label]: row.actual_kpi,
                  })),
                currentMetric.label
              )
          );
          const weeklyBrandHealthTimeSeriesDataSetsByMetric = await Promise.all(
            weeklyBrandHealthTimeSeriesDataPromises
          );
          const finalWeeklyBrandHealthTimeSeries = weeklyBrandHealthTimeSeriesDataSetsByMetric.reduce(
            (total, current) => {
              current.forEach(row => {
                const existingRow = total.find(r => r.date === row.date);
                if (existingRow) {
                  Object.assign(existingRow, row);
                } else {
                  total.push(row);
                }
              });
              return total;
            },
            [] as any[]
          );
          return finalWeeklyBrandHealthTimeSeries;
        };

        const fetchModelOverviewTwo = async () => {
          const businessImpactModelOverview = await fetchAndParseData(
            createUrlForFetch(
              company === "tinuititest" ? "v2" : "main",
              company,
              modelRunDate,
              groupByOptionsBusinessImpact[0].s3Path,
              "a_model_info.csv.gz"
            ),
            parsedData =>
              parsedData.map(row => ({
                [row.parameter]: row.value,
              })),
            groupByOptionsBusinessImpact[0].value
          );
          const mergedDecayData = Object.assign({}, ...businessImpactModelOverview);
          return mergedDecayData;
        };

        const fetchWeeklyRevenueTimeSeries = async () => {
          const weeklyRevenueTimeSeriesData = await fetchAndParseData(
            createUrlForFetch(
              company === "tinuititest" ? "v2" : "main",
              company,
              modelRunDate,
              groupByOptionsBusinessImpact[0].s3Path,
              "b_actual_vs_predicted.csv.gz"
            ),
            parsedData =>
              parsedData.map(row => ({
                date: row.date,
                revenue: row.actual_kpi,
              })),
            groupByOptionsBusinessImpact[0].value
          );
          return weeklyRevenueTimeSeriesData;
        };

        const fetchTotalRevenueDecomposition = async () => {
          const totalRevenueDecompositionData: TotalRevenueDecomposition[] = await fetchAndParseData(
            `tinuiti-mmm-results/brand_equity/${groupByOptionsBusinessImpact[0].branch}/${company}/${modelRunDate}/trailing_est.csv.gz`,
            parsedData =>
              parsedData.map(row => ({
                name: "revenue",
                MediaDrivenBrandEquity: row.spend_induced_brand_rev,
                NonMediaDrivenBrandEquity: row.brand_rev,
                OtherFactors: row.response - (row.brand_rev + row.spend_induced_brand_rev),
                MediaDrivenBrandEquityPercent:
                  row.spend_induced_brand_rev /
                  (row.brand_rev +
                    row.spend_induced_brand_rev +
                    (row.response - (row.brand_rev + row.spend_induced_brand_rev))),
                NonMediaDrivenBrandEquityPercent:
                  row.brand_rev /
                  (row.brand_rev +
                    row.spend_induced_brand_rev +
                    (row.response - (row.brand_rev + row.spend_induced_brand_rev))),
                OtherFactorsPercent:
                  (row.response - (row.brand_rev + row.spend_induced_brand_rev)) /
                  (row.brand_rev +
                    row.spend_induced_brand_rev +
                    (row.response - (row.brand_rev + row.spend_induced_brand_rev))),
              })),
            groupByOptionsBusinessImpact[0].value
          );
          return totalRevenueDecompositionData;
        };

        const fetchWeeklyRevenueDecomposition = async () => {
          const weeklyRevenueDecompositionData: WeeklyRevenueDecomposition[] = await fetchAndParseData(
            createUrlForFetch(
              groupByOptionsBusinessImpact[0].branch,
              company,
              modelRunDate,
              groupByOptionsBusinessImpact[0].s3Path,
              "brand_time_series_decomp.csv.gz"
            ),
            parsedData => {
              return parsedData.reduce((acc: any[], row: any) => {
                const { Date: unformattedDate, response, channel_group, value } = row;

                let existingRow = acc.find(item => item.date === unformattedDate);

                if (!existingRow) {
                  existingRow = {
                    date: unformattedDate,
                    OtherFactors: response,
                    total: response,
                    MediaDrivenBrandEquity: 0,
                    NonMediaDrivenBrandEquity: 0,
                  };
                  acc.push(existingRow);
                }

                if (channel_group === "Spend Induced Brand Effect") {
                  existingRow.OtherFactors -= value;
                  existingRow.MediaDrivenBrandEquity = value;
                } else if (channel_group === "Brand Effect") {
                  existingRow.Other -= value;
                  existingRow.NonMediaDrivenBrandEquity = value;
                }

                return acc;
              }, []);
            },
            groupByOptionsBusinessImpact[0].value
          );
          return weeklyRevenueDecompositionData;
        };

        const fetchRevenueImpactTakeaways = async () => {
          const revenueImpactTakeawaysData: RevenueImpactTakeaways = await fetchAndParseData(
            `tinuiti-mmm-results/brand_equity/${groupByOptionsBusinessImpact[0].branch}/${company}/${modelRunDate}/${groupByOptionsBusinessImpact[0].s3Path}/brand_time_series_decomp.csv.gz`,
            parsedData => {
              const revenueImpactTakeawaysParsed = parsedData.map(row => ({
                date: row.Date,
                channelGroup: row.channel_group,
                response: row.response,
                value: row.value,
              }));
              const finalRevenueImpactTakeAways = transformTakeawaysImpactRevenue(
                revenueImpactTakeawaysParsed
              );
              return finalRevenueImpactTakeAways;
            },
            groupByOptionsBusinessImpact[0].value
          );
          return revenueImpactTakeawaysData;
        };

        const fetchWeeklyIncrementalRoas = async () => {
          const weeklyIncrementalRoasData: WeeklyIncrementalRoas[] = await fetchAndParseData(
            `tinuiti-mmm-results/brand_equity/${groupByOptionsBusinessImpact[0].branch}/${company}/${modelRunDate}/incremental_roas.csv.gz`,
            parsedData =>
              parsedData.map(row => ({
                date: row.date,
                RevenueFromMediaDrivenBrandEquity: row.media_driven_brand_effect,
                Spend: row.total_spend,
                IncrementalRoas: row.iroas,
              })),
            groupByOptionsBusinessImpact[0].value
          );

          const incrementalRowsTakeawaysData: IncrementalRoasTakeaways = await fetchAndParseData(
            `tinuiti-mmm-results/brand_equity/${groupByOptionsBusinessImpact[0].branch}/${company}/${modelRunDate}/trailing_est.csv.gz`,
            parsedData => {
              const incrementalRoasTakeawaysParsed = parsedData.map(row => ({
                paidMediaSpend: row.paid_media_spend,
                spendInducedBrandRevenue: row.spend_induced_brand_rev,
                iRoasTtm: row.iroas_ttm,
              }));
              return incrementalRoasTakeawaysParsed[0];
            },
            groupByOptionsBusinessImpact[0].value
          );

          const incrementalRoasObject: IncrementalRoasObject = {
            finalWeeklyIncrementalRoas: weeklyIncrementalRoasData,
            finalIncrementalRoasTakeaways: incrementalRowsTakeawaysData,
          };
          return incrementalRoasObject;
        };

        const channelMapping = {
          awareness_p: "Awareness",
          buzz_p: "Buzz",
          consideration_p: "Consideration",
          intent_p: "Intent",
          gqv: "GQV",
          TotalIndexedQueryVolume: "TotalIndexedQueryVolume",
        };

        const fetchDecayData = async () => {
          const decayData: Record<string, any[]> = await fetchAndParseData(
            createUrlForFetch(
              company === "tinuititest" ? "v2" : "main",
              company,
              modelRunDate,
              groupByOptionsBusinessImpact[0].s3Path,
              "f_spend_decay_curves.csv.gz"
            ),
            parsedData => {
              const filteredData = parsedData.filter(
                row =>
                  row.name === "awareness_p" ||
                  row.name === "buzz_p" ||
                  row.name === "consideration_p" ||
                  row.name === "intent_p" ||
                  row.name === "Gqv" ||
                  row.name === "Totalindexedqueryvolume"
              );

              return filteredData;
            },
            groupByOptionsBusinessImpact[0].value
          );
          return decayData;
        };

        const fetchSaturationData = async () => {
          const saturationData: Record<string, any[]> = await fetchAndParseData(
            createUrlForFetch(
              company === "tinuititest" ? "v2" : "main",
              company,
              modelRunDate,
              groupByOptionsBusinessImpact[0].s3Path,
              "e_saturation_curves.csv.gz"
            ),
            parsedData => {
              const saturationDataMapped = parsedData
                .filter(
                  row =>
                    row.channel === "awareness_p" ||
                    row.channel === "buzz_p" ||
                    row.channel === "consideration_p" ||
                    row.channel === "intent_p" ||
                    row.channel === "gqv" ||
                    row.name === "Totalindexedqueryvolume"
                )
                .map(row => ({
                  channel: channelMapping[row.channel],
                  tactic: row.tactic,
                  "brand/nonbrand": row.brand_nonbrand,
                  spend: row.effective_adstock_spend,
                  response: row.effective_response,
                }));

              return saturationDataMapped;
            },
            groupByOptionsBusinessImpact[0].value
          );
          return saturationData;
        };

        const [
          weeklyBrandHealthTimeSeries,
          modelOverview,
          finalWeeklyRevenueTimeSeries,
          finalTotalRevenueDecomposition,
          finalWeeklyRevenueDecomposition,
          finalRevenueImpactTakeways,
          finalIncrementalRoasObject,
          finalDecayData,
          finalSaturationData,
        ] = await Promise.all([
          fetchWeeklyBrandHealthTimeSeries(),
          fetchModelOverviewTwo(),
          fetchWeeklyRevenueTimeSeries(),
          fetchTotalRevenueDecomposition(),
          fetchWeeklyRevenueDecomposition(),
          fetchRevenueImpactTakeaways(),
          fetchWeeklyIncrementalRoas(),
          fetchDecayData(),
          fetchSaturationData(),
        ]);
        setBusinessImpactData({
          weeklyBrandHealthTimeSeries: weeklyBrandHealthTimeSeries,
          modelOverview: modelOverview,
          weeklyRevenueTimeSeries: finalWeeklyRevenueTimeSeries,
          totalRevenueDecomposition: finalTotalRevenueDecomposition,
          weeklyRevenueDecomposition: finalWeeklyRevenueDecomposition,
          revenueImpactTakeaways: finalRevenueImpactTakeways,
          weeklyIncrementalRoas: finalIncrementalRoasObject.finalWeeklyIncrementalRoas,
          incrementalRoasTakeaways: finalIncrementalRoasObject.finalIncrementalRoasTakeaways,
          saturationData: finalSaturationData,
          decayData: finalDecayData,
        });
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    },
    [modelRunDate]
  );

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "brand-equity",
    defaultKey: TabKey.BRAND_IMPACT,
  });

  useEffect(() => {
    (async () => {
      try {
        const companyToFetch = company;
        let allRuns = await MetricsLambdaFetch("/getBrandEquityJobRuns", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            company: companyToFetch,
          },
        });
        let parsedAllRuns = await awaitJSON(allRuns);

        const { brandImpactOptions, businessImpactOptions } = parsedAllRuns.reduce(
          (curr, row) => {
            const { date: currentDate, kpi, client_facing, branch, stage_one } = row;

            if (!curr.brandImpactOptions[currentDate]) {
              curr.brandImpactOptions[currentDate] = [];
            }
            if (!curr.businessImpactOptions[currentDate]) {
              curr.businessImpactOptions[currentDate] = [];
            }

            if (stage_one) {
              curr.brandImpactOptions[currentDate].push({
                label: removeDashPFromEndOfMetric(kpi),
                value: removeDashPFromEndOfMetric(kpi),
                s3Path: kpi,
                kpi,
                client_facing,
                branch,
                stage_one,
              });
            } else {
              curr.businessImpactOptions[currentDate].push({
                label: removeDashPFromEndOfMetric(kpi),
                value: removeDashPFromEndOfMetric(kpi),
                s3Path: kpi,
                kpi,
                client_facing,
                branch,
                stage_one,
              });
            }

            return curr;
          },
          { brandImpactOptions: {}, businessImpactOptions: {} }
        );

        if (
          Object.keys(brandImpactOptions).length === 0 &&
          Object.keys(businessImpactOptions).length === 0
        ) {
          setError({
            message: "There are no MMM runs for this client",
          });
          return;
        } else if (
          !hasClientFacingOptions(brandImpactOptions) &&
          !hasClientFacingOptions(businessImpactOptions) &&
          !isInternalSelector
        ) {
          setError({
            message: "There are no released runs for this client",
          });
          return;
        }

        setGroupByOptionsBrandImpactAll(brandImpactOptions);
        setGroupByOptionsBusinessImpactAll(businessImpactOptions);

        const { date: mostRecentDate, kpi } = getMostRecentDateAndKPI(brandImpactOptions);
        setModelRunDate(mostRecentDate);
        setGroupByMetric(kpi);
      } catch (e) {
        setError({ message: "Failed to fetch incrementality job statuses", reportError: e });
      }
    })();
  }, [company, isInternalSelector, setError]);

  useEffect(() => {
    if (!modelRunDate) {
      return;
    }
    const brandImpact = groupByOptionsBrandImpactAll[modelRunDate] || [];
    const businessImpact = groupByOptionsBusinessImpactAll[modelRunDate] || [];
    setGroupByOptionsBrandImpact(brandImpact);
    setGroupByOptionsBusinessImpact(businessImpact);

    if (brandImpact.length > 0 && businessImpact.length > 0) {
      fetchBrandImpactData(company, brandImpact);
      fetchBusinessImpactData(company, businessImpact, brandImpact);
    }
  }, [
    modelRunDate,
    company,
    groupByOptionsBrandImpactAll,
    groupByOptionsBusinessImpactAll,
    fetchBrandImpactData,
    fetchBusinessImpactData,
  ]);

  return (
    <Page
      app2Redesign
      title="Brand Equity"
      pageType="Brand Equity"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
      actions={
        <div className="brandEquityRightActions">
          <div className="brandEquityArchiveActions">
            <Dropdown
              section={isInternalSelector ? ["Client-Facing", "Admin-Only"] : ["Client Facing"]}
              background="dark"
              className={
                isInternalSelector
                  ? "brandEquityArchiveDropdown internal"
                  : "brandEquityArchiveDropdown"
              }
              dropdownMenuClassName="align-right"
              onChange={value => {
                setModelRunDate(value);
              }}
              options={returnModelRunDates(groupByOptionsBrandImpactAll)}
              value={modelRunDate}
            />
            {isInternalSelector &&
              groupByOptionsBrandImpactAll &&
              groupByOptionsBusinessImpactAll &&
              modelRunDate && (
                <Button
                  className="archiveToggleButton"
                  background="dark"
                  type={ButtonType.OUTLINED}
                  variant={ButtonFrameworkVariant.TRAILING_ICON}
                  onClick={() => {
                    const visibility = hasClientFacingItem(
                      groupByOptionsBrandImpactAll[modelRunDate]
                    );
                    setAreYouSure({
                      title: visibility ? "Hide run from clients?" : "Show run to clients?",
                      message: `You're about to make this run ${
                        visibility ? "hidden from" : "visible to"
                      } clients. Are you sure you want to continue?`,
                      cancelText: "Cancel",
                      okayText: "Continue",
                      onOkay: () => {
                        toggleBrandEquityRun(modelRunDate, visibility);
                      },
                    });
                  }}
                  icon={
                    <CheckBox
                      checked={hasClientFacingItem(groupByOptionsBrandImpactAll[modelRunDate])}
                      id="secondaryDesign"
                    />
                  }
                >
                  Client-Facing
                </Button>
              )}
          </div>
        </div>
      }
      subHeader={
        <div className="subHeaderItemAtEnd">
          <Dropdown
            design="primary"
            className="brandEquityLevelDropdown"
            onChange={setGroupByLevel}
            options={GROUP_BY_OPTIONS}
            value={groupByLevel}
          />
        </div>
      }
    >
      <Router className="fullPageRouter brandEquityPage">
        {isBrandImpactDataComplete(brandImpactData) &&
          businessImpactData.incrementalRoasTakeaways !== undefined &&
          businessImpactData.incrementalRoasTakeaways !== null && (
            <BrandImpact
              company={company === "tinuititest" ? "instacart" : company}
              groupByMetric={groupByMetric}
              groupByMetricOptions={groupByOptionsBrandImpact}
              groupByLevel={groupByLevel}
              path={"/"}
              brandImpactData={brandImpactData}
              incrementalRoasTakeAways={businessImpactData.incrementalRoasTakeaways}
            ></BrandImpact>
          )}
        <Redirect noThrow from="/brandimpact" to={`../${TabKey.BRAND_IMPACT}`} />
        <Redirect noThrow from="/brand_impact" to={`../${TabKey.BRAND_IMPACT}`} />
        {isBusinessImpactDataComplete(businessImpactData) && (
          <BusinessImpact
            company={company === "tinuititest" ? "instacart" : company}
            groupByMetric={groupByMetric}
            groupByMetricOptions={groupByOptionsBusinessImpact}
            path={TabKey.BUSINESS_IMPACT}
            businessImpactData={businessImpactData}
            groupByLevel={groupByLevel}
          />
        )}
        <Redirect noThrow from="/businessimpact" to={`../${TabKey.BUSINESS_IMPACT}`} />
        <Redirect noThrow from="/business_impact" to={`../${TabKey.BUSINESS_IMPACT}`} />
      </Router>
    </Page>
  );
};

export default BrandEquity;
