import { useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  BarChart,
  Bar,
  AreaChart,
  Area,
} from "recharts";
import * as XLSX from "xlsx";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import {
  STREAMING_STROKE_COLOR,
  AUDIO_STROKE_COLOR,
  LINEAR_STROKE_COLOR,
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  CHART_MARGIN_WITH_LEGEND,
  LINE_STROKE_WIDTH,
  TICK_STYLE,
  X_AXIS_PADDING,
  DateGroupingKey,
  DATE_GROUPING_OPTIONS,
  DISPLAY_STROKE_COLOR,
  MEDIA_TYPE_TO_STROKE_COLOR,
  MEDIA_TYPE_TO_PRETTY_NAME,
  DateGroupingOption,
} from "./homePageConstants";
import {
  formatDateLabel,
  getGroupingValue,
  RELATIVE_FORMATTER,
  METRIC_TO_FORMATTER,
} from "./homePageUtils";
import {
  Skeleton,
  PathSkeleton,
  BPMTable,
  DownloadDropdown,
  DropdownToggleType,
  Dropdown,
  InfoTooltip,
  GraphTableToggleButton,
} from "../Components";
import { formatMoney, formatNumberAsInt } from "../utils/format-utils";
import { abbreviateNumber } from "../utils/data";
import ChartContainer from "../Components/ChartContainer";
import BreakdownLegend from "./BreakdownLegend";
import LinkSelector from "./LinkSelector";
import ChartTooltip from "./ChartTooltip";
import "./SpendBreakdown.scss";

interface SpendData {
  spend: number;
  cpm: number;
  imps: number;
}

interface SpendBreakdownData {
  date: any;
  [mediaType: string]: SpendData;
}

interface RelativeSpendBreakdownData {
  date: any;
  [channel: string]: number;
}

interface SpendBreakdownProps {
  data: SpendBreakdownData[];
  company: string;
  toggledMediaTypes: Record<string, boolean>;
  dateGroupingOptions: DateGroupingOption[];
}

const CHART_VIEW_OPTIONS = [{ value: "Absolute" }, { value: "Relative" }];
const DIMENSION_OPTIONS = [{ value: "Spend" }, { value: "CPM" }, { value: "Impressions" }];
const PRETTY_DIMENSION_TO_VALUE = {
  Spend: "spend",
  CPM: "cpm",
  Impressions: "imps",
};
const CHART_TYPE_OPTIONS = [
  { value: "Area Graph" },
  { value: "Line Graph" },
  { value: "Bar Graph" },
  { value: "Stacked Bar Graph" },
];

const makeTooltipSubLabel = (dimension: string) => {
  return `${dimension === "CPM" ? "Avg" : "Total"} ${dimension}`;
};

const groupDataByDate = (data: SpendBreakdownData[], groupingKey: DateGroupingKey): any => {
  if (!data) {
    return { groupedData: [], averageCpmByDate: {} };
  }

  const channels = Object.keys(data[0]).filter(key => key !== "date");

  let grouped: Record<string, Record<string, SpendData>> = {};
  let spendByChannelDateGrouping = {};
  let impressionsByChannelDateGrouping = {};
  let totalsByDateGrouping: Record<string, { spend: number; imps: number }> = {};

  for (const item of data) {
    const dateToUse = getGroupingValue(item.date, groupingKey);

    let prev = { ...(grouped[dateToUse] || {}) };

    let incremented: Record<string, any> = {};

    for (let channel of channels) {
      const spendKeyToUseForCpm = channel === "tv" ? "ratedSpend" : "spend"; // For Linear TV, calculate CPMs based on rated spend.
      totalsByDateGrouping = R.mergeDeepRight(totalsByDateGrouping, {
        [dateToUse]: {
          spend:
            R.pathOr(0, [dateToUse, "spend"], totalsByDateGrouping) +
            R.pathOr(0, [channel, spendKeyToUseForCpm], item),
          imps:
            R.pathOr(0, [dateToUse, "imps"], totalsByDateGrouping) +
            R.pathOr(0, [channel, "imps"], item),
        },
      });

      spendByChannelDateGrouping = R.mergeDeepRight(spendByChannelDateGrouping, {
        [dateToUse]: {
          [channel]:
            R.pathOr(0, [dateToUse, channel], spendByChannelDateGrouping) +
            R.pathOr(0, [channel, spendKeyToUseForCpm], item),
        },
      });
      impressionsByChannelDateGrouping = R.mergeDeepRight(impressionsByChannelDateGrouping, {
        [dateToUse]: {
          [channel]:
            R.pathOr(0, [dateToUse, channel], impressionsByChannelDateGrouping) +
            R.pathOr(0, [channel, "imps"], item),
        },
      });

      const cpm =
        (R.pathOr(0, [dateToUse, channel], spendByChannelDateGrouping) /
          R.pathOr(0, [dateToUse, channel], impressionsByChannelDateGrouping)) *
        1000;

      if (prev.hasOwnProperty(channel)) {
        incremented[channel] = {
          spend: (prev[channel].spend || 0) + (item[channel].spend || 0),
          imps: (prev[channel].imps || 0) + (item[channel].imps || 0),
          cpm: isFinite(cpm) ? cpm : 0,
        };
      } else {
        incremented[channel] = item[channel];
      }
    }

    grouped[dateToUse] = incremented;
  }

  const averageCpmByDate = Object.entries(totalsByDateGrouping).reduce((prev, curr) => {
    const [date, totals] = curr;
    return { ...prev, [date]: (totals.spend / totals.imps) * 1000 };
  }, {});

  const groupedData = Object.entries(grouped).map(([date, totals]) => ({
    date,
    ...totals,
  })) as any;

  return { groupedData, averageCpmByDate };
};

const processGraphData = (
  data: SpendBreakdownData[],
  chartView: string,
  dimensionType: string,
  groupingKey: DateGroupingKey
) => {
  if (R.isNil(data) || R.isEmpty(data)) {
    return { processedGraphData: [], averageCpmByDate: {} };
  }

  // Group data by selected date interval (Day, Week, Month, etc.)
  const { groupedData, averageCpmByDate } = groupDataByDate(data, groupingKey);

  // If applicable, convert data to relative percentages.
  // But only if looking at Spend/Impressions view because it doesn't make sense with CPM.
  if (chartView === "Relative" && ["Spend", "Impressions"].includes(dimensionType)) {
    let relativeSpend: RelativeSpendBreakdownData[] = [];
    for (let item of groupedData) {
      const { date, ...rest } = item;
      let totals = {};
      for (let channel of R.keys(rest)) {
        for (let metric of R.keys(rest[channel])) {
          totals[metric] = (totals[metric] || 0) + rest[channel][metric];
        }
      }
      let converted = {};
      for (let channel of R.keys(rest)) {
        for (let metric of R.keys(rest[channel])) {
          converted = {
            ...converted,
            [channel]: {
              ...(converted[channel] || {}),
              [metric]: Math.round((1000 * item[channel][metric]) / totals[metric]) / 10,
            },
          };
        }
      }
      relativeSpend.push({ date, ...converted });
    }
    return { processedGraphData: relativeSpend, averageCpmByDate };
  }

  return { processedGraphData: groupedData, averageCpmByDate };
};

const processTableData = (data: any[], groupingKey: DateGroupingKey): any[] => {
  let totalSpendByDateChannel = {};
  let totalImpressionsByDateChannel = {};
  let groupedDataByDate: Record<string, Record<string, number>> = {};
  for (let row of data) {
    const channels = R.keys(row).filter(key => key !== "date");
    const dateToUse = getGroupingValue(row.date, groupingKey);
    for (let channel of channels) {
      const data = row[channel];

      totalSpendByDateChannel = R.mergeDeepRight(totalSpendByDateChannel, {
        [dateToUse]: {
          [channel]: R.pathOr(0, [dateToUse, channel], totalSpendByDateChannel) + data.spend,
        },
      });

      totalImpressionsByDateChannel = R.mergeDeepRight(totalImpressionsByDateChannel, {
        [dateToUse]: {
          [channel]: R.pathOr(0, [dateToUse, channel], totalImpressionsByDateChannel) + data.imps,
        },
      });

      groupedDataByDate = R.mergeDeepRight(groupedDataByDate, {
        [dateToUse]: {
          [`${channel}_spend`]:
            R.pathOr(0, [dateToUse, `${channel}_spend`], groupedDataByDate) +
            R.pathOr(0, ["spend"], data),
          [`${channel}_imps`]:
            R.pathOr(0, [dateToUse, `${channel}_imps`], groupedDataByDate) +
            R.pathOr(0, ["imps"], data),
          [`${channel}_cpm`]:
            (R.pathOr(0, [dateToUse, channel], totalSpendByDateChannel) /
              R.pathOr(0, [dateToUse, channel], totalImpressionsByDateChannel)) *
            1000,
        },
      });
    }
  }

  return Object.entries(groupedDataByDate).map(([date, totals]) => ({
    date,
    ...totals,
  }));
};

const SpendBreakdown: React.FC<SpendBreakdownProps> = ({
  data,
  company,
  toggledMediaTypes,
  dateGroupingOptions,
}) => {
  const [dimensionType, setDimensionType] = useState(DIMENSION_OPTIONS[0].value);
  const [chartType, setChartType] = useState(CHART_TYPE_OPTIONS[0].value);
  const [chartView, setChartView] = useState(CHART_VIEW_OPTIONS[0].value);
  const [graphView, setGraphView] = useState(true);
  const [groupingKey, setGroupingKey] = useState(DATE_GROUPING_OPTIONS[1].value);

  const linkMetadata = {
    title: "delivery page",
    links: [
      { name: "Streaming", href: `/${company}/streaming/delivery` },
      { name: "Audio", href: `/${company}/audio/delivery` },
      { name: "Linear", href: `/${company}/linear/delivery` },
      { name: "Display", href: `/${company}/display/delivery` },
    ],
  };

  const { processedGraphData, averageCpmByDate } = useMemo(
    () => processGraphData(data, chartView, dimensionType, groupingKey),
    [data, dimensionType, chartView, groupingKey]
  );

  const processedTableData = useMemo(() => {
    if (!R.isNil(data) && !R.isEmpty(data)) {
      return processTableData(data, groupingKey);
    } else {
      return [];
    }
  }, [data, groupingKey]);

  const [headers, superHeaders] = useMemo(() => {
    let headers: any[] = [
      {
        label: "Date",
        name: "date",
        nonInteractive: true,
        renderer: item => formatDateLabel(item.date, groupingKey),
      },
    ];
    let superHeaders: any[] = [{ span: 1 }];
    if (data) {
      for (let key of R.keys(data[0])) {
        if (key !== "date") {
          // For each media type, add a super header plus a header for Spend, CPM, and Impressions.
          headers.push(
            ...[
              {
                label: "Spend",
                name: `${key}_spend`,
                nonInteractive: true,
                flex: 1,
                renderer: item => formatMoney(item[`${key}_spend`], 0),
              },
              {
                label: "CPM",
                name: `${key}_cpm`,
                nonInteractive: true,
                flex: 1,
                renderer: item => formatMoney(item[`${key}_cpm`], 2),
              },
              {
                label: "Impressions",
                name: `${key}_imps`,
                nonInteractive: true,
                flex: 1,
                renderer: item => (
                  <div className="farRightColumn">{formatNumberAsInt(item[`${key}_imps`])}</div>
                ),
              },
            ]
          );
          superHeaders.push({ span: 3, data: MEDIA_TYPE_TO_PRETTY_NAME[key] });
        }
      }
    }

    return [headers, superHeaders];
  }, [data, groupingKey]);

  const totals = useMemo(() => {
    let totals = processedTableData.reduce((acc, row) => {
      for (let key of R.keys(row)) {
        if (key !== "date") {
          acc[key] = (acc[key] || 0) + row[key];
        }
      }
      return acc;
    }, {}) as Record<string, number>;

    // Calculate average CPMs based on spend and impression totals.
    for (let key of R.keys(totals)) {
      if (key.includes("cpm")) {
        const channel = key.split("_")[0];
        totals[key] = (totals[`${channel}_spend`] / totals[`${channel}_imps`]) * 1000;
      }
    }

    let formattedTotals: Record<string, string> = {};
    // Format numbers based on metric.
    for (let key of R.keys(totals)) {
      if (key.includes("cpm")) {
        formattedTotals[key] = METRIC_TO_FORMATTER.cpm(totals[key]);
      } else if (key.includes("spend")) {
        formattedTotals[key] = METRIC_TO_FORMATTER.spend(totals[key]);
      } else if (key.includes("imps")) {
        formattedTotals[key] = METRIC_TO_FORMATTER.imps(totals[key]);
      }
    }

    return {
      date: "Totals",
      ...formattedTotals,
    };
  }, [processedTableData]);

  const totalsRenderer = ({ data, style = {}, classes = [] as string[] }) => {
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  const exportToExcel = useCallback(() => {
    let fileName = `${company}_SpendBreakdown.xlsx`;

    let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    let worksheet = XLSX.utils.json_to_sheet(processedTableData);

    let sheetName = `${company}`;

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;

    XLSX.writeFile(workbook, fileName);
  }, [company, processedTableData]);

  const downloadPNG = useCallback(async () => {
    const contents = document.querySelector<HTMLElement>(".spendBreakdown");
    if (!contents) {
      return;
    }
    const canvas = await html2canvas(contents);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${company}_SpendBreakdown.png`, "image/png");
  }, [company]);

  return (
    <ChartContainer
      title="Spend Breakdown"
      leftActions={<LinkSelector title={linkMetadata.title} links={linkMetadata.links} />}
      rightActions={
        <>
          {graphView && (
            <>
              <Dropdown
                type={DropdownToggleType.FILLED}
                design="secondary"
                size="sm"
                value={chartType}
                options={CHART_TYPE_OPTIONS}
                onChange={option => setChartType(option)}
              />
              {["Spend", "Impressions"].includes(dimensionType) && (
                <Dropdown
                  type={DropdownToggleType.FILLED}
                  design="secondary"
                  size="sm"
                  value={chartView}
                  options={CHART_VIEW_OPTIONS}
                  onChange={option => setChartView(option)}
                />
              )}
            </>
          )}
          <GraphTableToggleButton
            graphView={graphView}
            setGraphView={setGraphView}
            widgetName="spend_breakdown"
          />
          <DownloadDropdown
            size="sm"
            onClickOptions={[exportToExcel, downloadPNG]}
            disabledMenuOptions={
              graphView ? { XLSX: false, PNG: false } : { XLSX: false, PNG: true }
            }
            disabled={!data}
          />
        </>
      }
    >
      {data ? (
        !graphView ? (
          <div className="spendBreakdownTable">
            <BPMTable
              data={processedTableData}
              headers={headers}
              superHeaders={superHeaders}
              filterBar={false}
              totals={totals}
              totalsRenderer={totalsRenderer}
            />
          </div>
        ) : (
          <div className="spendBreakdown">
            <div className="aboveChart">
              <Dropdown
                type={DropdownToggleType.OUTLINED}
                design="secondary"
                size="sm"
                value={dimensionType}
                options={DIMENSION_OPTIONS}
                onChange={option => {
                  if (option === "CPM") {
                    setChartView("Absolute");
                  }
                  setDimensionType(option);
                }}
              />
              <InfoTooltip>
                <div>
                  Linear spend is captured for a typical calendar day of 12:00am through 11:59pm and
                  may differ marginally from pages in the app captured in broadcast days.
                </div>
              </InfoTooltip>
            </div>
            <div className="chartContents">
              <div id="spendBreakdownChart" className="spendBreakdownChart">
                <AutoSizer>
                  {({ width, height }) =>
                    chartType === "Line Graph" ? (
                      <LineChart
                        data={processedGraphData}
                        width={width}
                        height={height}
                        margin={CHART_MARGIN_WITH_LEGEND}
                        stackOffset={chartView === "Relative" ? "expand" : "none"}
                      >
                        <CartesianGrid
                          vertical={false}
                          stroke={CARTESIAN_GRID_STROKE}
                          strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                        />
                        <Tooltip
                          content={({ label, payload }) => {
                            const formattedHeaderLabel = formatDateLabel(label, groupingKey, true);
                            let items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload?.map(item => ({
                                label: item.name as string,
                                value: item.value as number,
                                color: item.stroke,
                              })) || []
                            );
                            const subHeaderValue =
                              dimensionType === "CPM" ? averageCpmByDate[label] : undefined;
                            return (
                              <ChartTooltip
                                headerLabel={formattedHeaderLabel}
                                subHeaderLabel={makeTooltipSubLabel(dimensionType)}
                                subHeaderValue={subHeaderValue}
                                subHeaderValueFormatter={
                                  chartView === "Relative"
                                    ? RELATIVE_FORMATTER
                                    : METRIC_TO_FORMATTER[PRETTY_DIMENSION_TO_VALUE[dimensionType]]
                                }
                                items={items}
                                itemFormatter={
                                  chartView === "Relative"
                                    ? RELATIVE_FORMATTER
                                    : METRIC_TO_FORMATTER[PRETTY_DIMENSION_TO_VALUE[dimensionType]]
                                }
                              />
                            );
                          }}
                          isAnimationActive={false}
                        />
                        <XAxis
                          dataKey="date"
                          padding={X_AXIS_PADDING}
                          tick={TICK_STYLE}
                          tickFormatter={date => formatDateLabel(date, groupingKey)}
                        />
                        <YAxis
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={number =>
                            chartView === "Relative"
                              ? `${abbreviateNumber(number)}%`
                              : dimensionType === "Impressions"
                              ? `${abbreviateNumber(number)}`
                              : `$${abbreviateNumber(number)}`
                          }
                        />
                        {toggledMediaTypes.streaming && (
                          <Line
                            dataKey={`streaming.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Streaming"
                            type="monotone"
                            strokeWidth={LINE_STROKE_WIDTH}
                            dot={false}
                            isAnimationActive={false}
                            stroke={STREAMING_STROKE_COLOR}
                          />
                        )}
                        {toggledMediaTypes.audio && (
                          <Line
                            dataKey={`audio.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Audio"
                            type="monotone"
                            strokeWidth={LINE_STROKE_WIDTH}
                            dot={false}
                            isAnimationActive={false}
                            stroke={AUDIO_STROKE_COLOR}
                          />
                        )}
                        {toggledMediaTypes.tv && (
                          <Line
                            dataKey={`tv.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Linear TV"
                            type="monotone"
                            strokeWidth={LINE_STROKE_WIDTH}
                            dot={false}
                            isAnimationActive={false}
                            stroke={LINEAR_STROKE_COLOR}
                          />
                        )}
                        {toggledMediaTypes.display && (
                          <Line
                            dataKey={`display.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Display"
                            type="monotone"
                            strokeWidth={LINE_STROKE_WIDTH}
                            dot={false}
                            isAnimationActive={false}
                            stroke={DISPLAY_STROKE_COLOR}
                          />
                        )}
                      </LineChart>
                    ) : chartType === "Area Graph" ? (
                      <AreaChart
                        width={width}
                        height={height}
                        data={processedGraphData}
                        margin={CHART_MARGIN_WITH_LEGEND}
                        stackOffset={chartView === "Relative" ? "expand" : "none"}
                      >
                        <CartesianGrid
                          vertical={false}
                          stroke={CARTESIAN_GRID_STROKE}
                          strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                        />
                        {R.keys(R.omit(["date"], processedGraphData[0])).map((channel, i) => {
                          if (toggledMediaTypes[channel]) {
                            return (
                              <Area
                                type="monotone"
                                name={MEDIA_TYPE_TO_PRETTY_NAME[channel]}
                                dataKey={`${channel}.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                                stackId="1"
                                key={i}
                                activeDot={false}
                                fillOpacity={1}
                                strokeWidth={0}
                                fill={MEDIA_TYPE_TO_STROKE_COLOR[channel]}
                                stroke={MEDIA_TYPE_TO_STROKE_COLOR[channel]}
                                isAnimationActive={false}
                              />
                            );
                          } else {
                            return <></>;
                          }
                        })}
                        <XAxis
                          dataKey="date"
                          padding={X_AXIS_PADDING}
                          tick={TICK_STYLE}
                          tickFormatter={date => formatDateLabel(date, groupingKey)}
                        />
                        <YAxis
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={number =>
                            chartView === "Relative"
                              ? `${abbreviateNumber(number * 100)}%`
                              : dimensionType === "Impressions"
                              ? `${abbreviateNumber(number)}`
                              : `$${abbreviateNumber(number)}`
                          }
                        />
                        <Tooltip
                          content={({ label, payload }) => {
                            const formattedHeaderLabel = formatDateLabel(label, groupingKey, true);
                            let items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload?.map(item => ({
                                label: item.name as string,
                                value: item.value as number,
                                color: item.stroke,
                              })) || []
                            );
                            const subHeaderValue =
                              dimensionType === "CPM" ? averageCpmByDate[label] : undefined;
                            return (
                              <ChartTooltip
                                headerLabel={formattedHeaderLabel}
                                subHeaderLabel={makeTooltipSubLabel(dimensionType)}
                                subHeaderValue={subHeaderValue}
                                subHeaderValueFormatter={
                                  chartView === "Relative"
                                    ? RELATIVE_FORMATTER
                                    : METRIC_TO_FORMATTER[PRETTY_DIMENSION_TO_VALUE[dimensionType]]
                                }
                                items={items}
                                itemFormatter={
                                  chartView === "Relative"
                                    ? RELATIVE_FORMATTER
                                    : METRIC_TO_FORMATTER[PRETTY_DIMENSION_TO_VALUE[dimensionType]]
                                }
                              />
                            );
                          }}
                          isAnimationActive={false}
                        />
                      </AreaChart>
                    ) : (
                      <BarChart
                        width={width}
                        height={height}
                        data={processedGraphData}
                        margin={CHART_MARGIN_WITH_LEGEND}
                        stackOffset={chartView === "Relative" ? "expand" : "none"}
                      >
                        <CartesianGrid
                          vertical={false}
                          stroke={CARTESIAN_GRID_STROKE}
                          strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                        />
                        <Tooltip
                          content={({ label, payload }) => {
                            const formattedHeaderLabel = formatDateLabel(label, groupingKey, true);
                            let items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload?.map(item => ({
                                label: item.name as string,
                                value: item.value as number,
                                color: item.stroke,
                              })) || []
                            );
                            const subHeaderValue =
                              dimensionType === "CPM" ? averageCpmByDate[label] : undefined;
                            return (
                              <ChartTooltip
                                headerLabel={formattedHeaderLabel}
                                subHeaderLabel={makeTooltipSubLabel(dimensionType)}
                                subHeaderValue={subHeaderValue}
                                subHeaderValueFormatter={
                                  chartView === "Relative"
                                    ? RELATIVE_FORMATTER
                                    : METRIC_TO_FORMATTER[PRETTY_DIMENSION_TO_VALUE[dimensionType]]
                                }
                                items={items}
                                itemFormatter={
                                  chartView === "Relative"
                                    ? RELATIVE_FORMATTER
                                    : METRIC_TO_FORMATTER[PRETTY_DIMENSION_TO_VALUE[dimensionType]]
                                }
                              />
                            );
                          }}
                          isAnimationActive={false}
                        />
                        <XAxis
                          dataKey="date"
                          padding={X_AXIS_PADDING}
                          tick={TICK_STYLE}
                          tickFormatter={date => formatDateLabel(date, groupingKey)}
                        />
                        <YAxis
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={number =>
                            chartView === "Relative"
                              ? `${abbreviateNumber(number)}%`
                              : `$${abbreviateNumber(number)}`
                          }
                        />
                        {toggledMediaTypes.streaming && (
                          <Bar
                            dataKey={`streaming.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Streaming"
                            fill={STREAMING_STROKE_COLOR}
                            stackId={chartType === "Stacked Bar Graph" ? "a" : undefined}
                            stroke={STREAMING_STROKE_COLOR}
                          />
                        )}
                        {toggledMediaTypes.audio && (
                          <Bar
                            dataKey={`audio.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Audio"
                            fill={AUDIO_STROKE_COLOR}
                            stackId={chartType === "Stacked Bar Graph" ? "a" : undefined}
                            stroke={AUDIO_STROKE_COLOR}
                          />
                        )}
                        {toggledMediaTypes.tv && (
                          <Bar
                            dataKey={`tv.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Linear TV"
                            fill={LINEAR_STROKE_COLOR}
                            stackId={chartType === "Stacked Bar Graph" ? "a" : undefined}
                            stroke={LINEAR_STROKE_COLOR}
                          />
                        )}
                        {toggledMediaTypes.display && (
                          <Bar
                            dataKey={`display.${PRETTY_DIMENSION_TO_VALUE[dimensionType]}`}
                            name="Display"
                            fill={DISPLAY_STROKE_COLOR}
                            stackId={chartType === "Stacked Bar Graph" ? "a" : undefined}
                            stroke={DISPLAY_STROKE_COLOR}
                          />
                        )}
                      </BarChart>
                    )
                  }
                </AutoSizer>
              </div>
              <div className="rightOfChart">
                <BreakdownLegend
                  data={data}
                  dimensionType={dimensionType}
                  mediaTypes={R.keys(R.pickBy(v => v, toggledMediaTypes))}
                />
                <Dropdown
                  type={DropdownToggleType.OUTLINED}
                  design="secondary"
                  size="sm"
                  value={groupingKey}
                  options={dateGroupingOptions}
                  onChange={option => setGroupingKey(option as DateGroupingKey)}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <Skeleton>
          <PathSkeleton
            points={[
              [0, 0.3],
              [0.25, 0.7],
              [0.5, 0.2],
              [0.75, 0.9],
              [1, 0.5],
            ]}
          />
        </Skeleton>
      )}
    </ChartContainer>
  );
};

export default SpendBreakdown;
