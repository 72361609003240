import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { AdminLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import * as Dfns from "date-fns/fp";

import {
  Page,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  SelectorOption,
  PendingChangesPanel,
  PendingChangesControls,
} from "../Components";
import "./Commissions.scss";

import {
  AddCommissionRatesBody,
  CommissionRow,
} from "@blisspointmedia/bpm-types/dist/pgTables/Commission";

const Commissions: React.FC = () => {
  const setError = useSetError();
  const [saving, setSaving] = useState(false);
  const [tableData, setTableData] = useState<CommissionRow[]>();
  const [newCommissionRows, setNewCommissionRows] = useState<CommissionRow[]>([]);
  const [showPendingChanges, setShowPendingChanges] = useState<boolean>(false);
  const [commissionModalInputErrorMessage, setCommissionModalInputErrorMessage] = useState<string>(
    ""
  );

  useEffect(() => {
    if (!tableData) {
      (async () => {
        await getCommissionsData();
      })();
    }
  });

  const validateUserInput: (commission) => boolean = commission => {
    let newCommission = { ...commission };

    if (!newCommission.start_date) {
      setCommissionModalInputErrorMessage(`Invalid input:
      Please enter a valid start date.`);
    }

    if (!newCommission.company) {
      setCommissionModalInputErrorMessage(`Invalid input: 
      Please enter a valid company name.`);
    }

    let validRate = newCommission.rate;
    if (R.isNil(validRate)) {
      setCommissionModalInputErrorMessage(`Invalid input:
      Please enter a valid rate.`);
    } else if (validRate < 0 || validRate > 12) {
      validRate = false;
      setCommissionModalInputErrorMessage(`Invalid input:
      Rates must be between 0 and 12 inclusive.`);
    } else {
      validRate = true;
    }

    return newCommission.start_date && validRate && newCommission.company;
  };

  const getCommissionsData = useCallback(async () => {
    try {
      let res = await AdminLambdaFetch("/getCommissionTable");
      let commissionDataLambdaResult = await awaitJSON<CommissionRow[]>(res);
      setTableData(commissionDataLambdaResult);
    } catch (e) {
      let error: Error = e as Error;
      setError({
        message: error.message,
        reportError: error,
      });
    }
  }, [setError]);

  useEffect(() => {
    if (tableData) {
      let newCommissionRowsHolder = R.filter(row => {
        if (row.id) {
          return false;
        }
        return true;
      }, tableData);
      setNewCommissionRows(newCommissionRowsHolder);
    }
  }, [tableData]);

  const clearAllChanges = useCallback(async () => {
    setNewCommissionRows([]);
    await getCommissionsData();
  }, [setNewCommissionRows, getCommissionsData]);

  const hasPendingChanges: boolean = useMemo(() => {
    const hasPendingChangesResult = Boolean(newCommissionRows.length);
    if (!hasPendingChangesResult) {
      setShowPendingChanges(false);
    }

    return hasPendingChangesResult;
  }, [newCommissionRows]);

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      let error: Error = {
        name: "500",
        message: "You need to have cookies enabled to use this page!",
      };
      setError({
        message: error.message,
        reportError: error,
      });
    }

    if (!tableData) {
      (async () => {
        await getCommissionsData();
      })();
    }
  }, [setError, tableData, getCommissionsData]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await AdminLambdaFetch<AddCommissionRatesBody>("/addCommissionRates", {
        method: "POST",
        body: {
          commissions: newCommissionRows,
        },
      });
      await getCommissionsData();
      setSaving(false);
    } catch (e) {
      setSaving(false);
      let error: Error = e as Error;
      setError({
        message: `Failed to insert commission data: ${error.message}`,
        reportError: error,
      });
    }
  }, [newCommissionRows, getCommissionsData, setError]);

  const companyLabel = {
    label: "Company",
    field: "company",
    type: "select",
    options: "company",
    flex: 3,
    modalRow: 0,
    modalFlex: 1,
  };

  const startDateLabel = {
    label: "Start Date",
    field: "start_date",
    type: "day",
    flex: 1,
    modalRow: 0,
    modalFlex: 1,
    restriction: (date: string) => {
      return !R.pipe(Dfns.parseISO, Dfns.isFirstDayOfMonth)(date);
    },
  };

  const endDateLabel = {
    label: "End Date",
    field: "end_date",
    type: "day",
    flex: 1,
    modalRow: 0,
    modalFlex: 1,
    uneditable: true,
  };

  const rateLabel = {
    label: "Rate (%)",
    field: "rate",
    type: "decimal",
    flex: 2,
    modalRow: 0,
    modalFlex: 1,
  };

  const headers = [companyLabel, startDateLabel, endDateLabel, rateLabel];

  let selectorOptions: Record<string, SelectorOption[]> = useMemo(() => {
    if (tableData) {
      let companies = new Set<string>();
      for (let i = 0; i < tableData.length; i++) {
        companies.add(tableData[i].company);
      }
      return {
        company: R.map(e => ({ label: e, value: e }), Array.from(companies.values())),
        start_date: [],
        rate: [],
      } as Record<string, SelectorOption[]>;
    } else {
      return {
        company: [],
        start_date: [],
        rate: [],
      };
    }
  }, [tableData]);

  return (
    <Page
      title="Commissions"
      pageType="Commissions"
      minHeight="600px"
      actions={
        <PendingChangesControls
          hasPendingChanges={hasPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          saveChanges={save}
          isSaving={saving}
          clearAllChanges={clearAllChanges}
        />
      }
    >
      <div className="commissionsPageContainer">
        {tableData ? (
          <ModalEditTable<CommissionRow>
            className="commissionsTable"
            headers={headers}
            tableData={tableData}
            setTableData={setTableData}
            selectorOptions={selectorOptions}
            filterBar
            showModalOnAdd={true}
            checkIsValid={validateUserInput}
            invalidText={commissionModalInputErrorMessage}
            enableDelete={false}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && (
          <PendingChangesPanel
            pendingChanges={{
              newRows: newCommissionRows,
            }}
            showPendingChanges={showPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            headers={headers}
          />
        )}
      </div>
    </Page>
  );
};

export default Commissions;
