import React, { useContext, useMemo, useState, useCallback } from "react";

import * as R from "ramda";

import { ListGroup, Button } from "react-bootstrap";

import { useCreativeMap } from "../redux/creative";

import useLocation from "../utils/hooks/useLocation";

import { Card, PieChart, PieSetter, Spinner } from "../Components";

import {
  StreamingCreativesContext,
  makeNetPlatKey,
  WORKSPACE_KEY,
  MENU_KEY,
} from "./StreamingCreatives";

import { StaticCard } from "./Cards";

import { ApprovalStages } from "@blisspointmedia/bpm-types/dist/CreativeApprovals";

import { useSetAreYouSure } from "../redux/modals";

const Cart = () => {
  const { company } = useLocation();
  const { creativeMap, colorMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });
  const {
    setSelectedMap,
    goToTab,
    cart,
    setWorkspaceMap,
    committing,
    commit,
    setCart,
    shopData,
    approvalStageData,
  } = useContext(StreamingCreativesContext);

  const setAreYouSure = useSetAreYouSure();

  const [selectedId, setSelectedId] = useState(cart.length && cart[0].id);
  const [expandedNetwork, setExpandedNetwork] = useState();

  const [selectedItem, items] = useMemo(() => {
    let selectedItem = {};

    let items = [];
    for (let item of cart) {
      const { id, placements, rotation } = item;
      if (selectedId === id) {
        selectedItem = item;
      }
      items.push({
        id,
        placements,
        rotation,
        label: R.pipe(
          R.map(id => shopData.placementMap[id] || {}),
          R.pluck("network"),
          R.uniq,
          R.sortBy(R.identity),
          R.join(", ")
        )(placements),
      });
    }
    return [selectedItem, items];
  }, [cart, selectedId, shopData]);

  const [networkGroups, subPlacements] = useMemo(() => {
    let networkGroups = {};
    let subPlacements = [];

    for (let id of selectedItem.placements || []) {
      let placement = shopData.placementMap[id] || {};
      let key = makeNetPlatKey(placement);
      if (key === expandedNetwork) {
        subPlacements.push(placement.name);
      }
      networkGroups[key] = {
        key,
        network: placement.network,
        platform: placement.platform,
      };
    }

    return [
      R.pipe(R.values, R.sortBy(R.prop("key")))(networkGroups),
      R.sortBy(R.identity, subPlacements),
    ];
  }, [selectedItem, expandedNetwork, shopData]);

  const removeFromCart = useCallback(() => {
    let newCart = [];
    for (let item of cart) {
      if (item.id !== selectedId) {
        newCart.push(item);
      }
    }
    setCart(newCart);
  }, [selectedId, cart, setCart]);

  const handleAreYouSureModal = useCallback(() => {
    if (approvalStageData.stage === ApprovalStages.APPROVED) {
      setAreYouSure({
        title: "Committing New Changes Before Uploading Approved Changes.",
        message:
          "There are creative allocation changes that have been approved, but not yet uploaded. If you commit new changes, this approval will be canceled. Continue anyway?",
        okayText: "Yes, Commit",
        cancelText: "No, Don't Commit",
        onOkay: commit,
      });
      return;
    }
    if (approvalStageData.stage === ApprovalStages.IN_REVIEW) {
      setAreYouSure({
        title: "Committing New Changes Before Finishing Current Approvals Process.",
        message:
          "There are already creative allocation changes in review. If you commit new changes, the current approval process will be canceled. Continue anyway?",
        okayText: "Yes, Commit",
        cancelText: "No, Don't Commit",
        onOkay: commit,
      });
      return;
    }

    commit();
  }, [approvalStageData, setAreYouSure, commit]);

  return (
    <div className="streamingCreativesCardContainer">
      <Card className="streamingCreativesCard">
        <div className="cartList">
          {cart.length ? (
            <>
              <div className="itemList">
                <ListGroup>
                  {items.map(({ id, rotation, label }) => (
                    <ListGroup.Item
                      key={id}
                      active={selectedId === id}
                      onClick={() => setSelectedId(id)}
                    >
                      <div className="mainItem">
                        <PieChart
                          data={R.map(
                            ({ isci, value }) => ({
                              name: isci,
                              value,
                              color: colorMap[isci] || "white",
                            }),
                            rotation
                          )}
                          size={40}
                        />
                        <div className="itemLabel">{label}</div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
              <div className="preview">
                <div className="actions">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() => {
                      let newSelectedMap = {};
                      let { rotation, placements } = selectedItem;
                      for (let id of placements) {
                        newSelectedMap[id] = true;
                      }
                      let newWorkspaceMap = {};
                      for (let { isci, value } of rotation) {
                        newWorkspaceMap[isci] = value;
                      }
                      setSelectedMap(newSelectedMap);
                      setWorkspaceMap(newWorkspaceMap);
                      removeFromCart(selectedId);
                      goToTab(WORKSPACE_KEY);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="lg"
                    onClick={() => {
                      if (items.length === 1) {
                        goToTab(MENU_KEY);
                      }
                      setSelectedId(cart[0].id);
                      removeFromCart(selectedId);
                    }}
                  >
                    Delete
                  </Button>
                </div>
                <div className="pieBox">
                  <PieSetter
                    pieFlex={0.5}
                    percentage
                    data={R.pipe(
                      R.prop("rotation"),
                      R.defaultTo([]),
                      R.map(({ isci, value }) => {
                        let { name, length } = creativeMap[isci] || {
                          name: "unnamed",
                          length: 0,
                        };
                        return {
                          isci,
                          name: isci,
                          key: isci,
                          color: colorMap[isci] || "white",
                          value,
                          creative: name,
                          length,
                          isLive: true,
                        };
                      })
                    )(selectedItem)}
                    renderItem={elem => <StaticCard {...elem} />}
                  />
                </div>
                <div className="placementList">
                  <div className="networkGroups">
                    <ListGroup>
                      {networkGroups.map(({ key, network, platform }) => (
                        <ListGroup.Item
                          key={key}
                          className="placementItem"
                          active={expandedNetwork === key}
                          onClick={() => setExpandedNetwork(key)}
                        >
                          {network} {platform}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                  <div className="subPlacements">
                    <ListGroup>
                      {subPlacements.map(item => (
                        <ListGroup.Item key={item}>{item}</ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="cartEmpty">Cart is Empty</div>
          )}
        </div>
        <div className="buttonsContainer">
          <Button variant="outline-dark" size="lg" onClick={() => goToTab(WORKSPACE_KEY)}>
            Back
          </Button>
          {Boolean(cart.length) && (
            <Button
              disabled={!cart.length || committing}
              variant="primary"
              size="lg"
              onClick={handleAreYouSureModal}
            >
              {committing ? <Spinner size={19} color="white" /> : "Commit"}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Cart;
