import { Card, Img, OldDropdown } from "../Components";
import { Form } from "react-bootstrap";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";
import React from "react";
import * as R from "ramda";

const XR_LABELS = ["xr", "extreme reach", "er"];
const FT_LABELS = ["ft", "flashtalking"];

export const EditableCard = ({
  name,
  isci,
  creative,
  length,
  value,
  color,
  onChange,
  notLive,
  company,
  audioCompanion,
  audioCompanionOptions = [],
  clickthroughURL,
  onMetadataChange,
  adServer,
}) => {
  const CARD_WIDTH = 190;
  const STRIPE_WIDTH = 5;
  const TEXT_HEIGHT = 60;
  const FORM_HEIGHT = 55;
  const companionOptions = [];
  for (const option of audioCompanionOptions) {
    const { adServer: compAdServer, adid, title } = R.defaultTo({}, option);
    if (
      (FT_LABELS.includes(adServer) && FT_LABELS.includes(compAdServer)) ||
      (XR_LABELS.includes(adServer) && XR_LABELS.includes(compAdServer))
    ) {
      companionOptions.push({
        label: `${adid} (${title})`,
        value: adid,
      });
    }
  }
  const available = company === "homechef" || company === "dsw";
  const hasCompanions = companionOptions.length > 0;
  const thumbHeight = ((CARD_WIDTH - STRIPE_WIDTH) * 9) / 16;
  return (
    <Card
      key={name}
      className={`creativeCardContainer ${notLive ? "notLive" : ""}`}
      style={{
        height:
          thumbHeight +
          TEXT_HEIGHT +
          FORM_HEIGHT * (FT_LABELS.includes(adServer) && available ? (hasCompanions ? 3 : 2) : 1),
        width: CARD_WIDTH,
        opacity: value === 0 ? 0.5 : 1,
      }}
    >
      <div className="stripe" style={{ width: STRIPE_WIDTH, backgroundColor: color }} />
      <div className="creativeCard">
        <div className="creativeContent">
          <div
            className="creativeImgBox"
            style={{
              height: thumbHeight,
              minHeight: thumbHeight,
              maxHeight: thumbHeight,
            }}
          >
            <Img
              src={getCreativeThumbnail(company, isci)}
              className="creativeImg"
              unloader={<div className="noImg">Thumbnail not found</div>}
            />
          </div>
          <div className="isci">{name}</div>
          <div className="subtitle" title={`${creative} (${length}s)`}>
            {creative} <span className="length">({length}s)</span>
          </div>
        </div>
        <div className="allocInput">
          <Form.Control
            type="number"
            value={`${value}`}
            onChange={e => onChange(name, parseInt(e.target.value))}
          />
        </div>
        {FT_LABELS.includes(adServer) && available && (
          <div className="clickthroughURLInput">
            <div className="label">Click-Through URL:</div>
            <Form.Control
              type="text"
              value={clickthroughURL || ""}
              onChange={e =>
                onMetadataChange(name, {
                  audioCompanion,
                  clickthroughURL: e.target.value,
                })
              }
            />
          </div>
        )}
        {hasCompanions && FT_LABELS.includes(adServer) && available && (
          <div className="companionBannerInput">
            <div className="label">Audio Companion Banner:</div>
            <OldDropdown
              searchable
              className="companionDropdown"
              size="sm"
              placeholder="Select Companion..."
              value={audioCompanion || ""}
              options={companionOptions}
              onChange={newVal =>
                onMetadataChange(name, {
                  audioCompanion: newVal,
                  clickthroughURL,
                })
              }
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export const StaticCard = ({ name, isci, creative, length, value, color, isLive, company }) => {
  const CARD_WIDTH = 170;
  const STRIPE_WIDTH = 5;
  const TEXT_HEIGHT = 70;
  const FORM_HEIGHT = 40;
  const thumbHeight = ((CARD_WIDTH - STRIPE_WIDTH) * 9) / 16;
  return (
    <Card
      key={name}
      className="staticCardContainer"
      style={{
        height: thumbHeight + TEXT_HEIGHT + FORM_HEIGHT,
        width: CARD_WIDTH,
        opacity: isLive ? 1 : 0.5,
      }}
    >
      <div className="stripe" style={{ width: STRIPE_WIDTH, backgroundColor: color }} />
      <div className="creativeCard">
        <div className="creativeContent">
          <div
            className="creativeImgBox"
            style={{
              height: thumbHeight,
              minHeight: thumbHeight,
              maxHeight: thumbHeight,
            }}
          >
            <Img
              src={getCreativeThumbnail(company, isci)}
              className="creativeImg"
              unloader={<div className="noImg">Thumbnail not found</div>}
            />
          </div>
          <div className="isci">{name}</div>
          <div className="subtitle" title={`${creative} (${length}s)`}>
            {creative} <span className="length">({length}s)</span>
          </div>
        </div>
        <div className="alloc">{value}%</div>
      </div>
    </Card>
  );
};
