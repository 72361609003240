import "./CustomerInsightsChart.scss";
import { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import { formatDecimal, formatPercent } from "../utils/format-utils";
import {
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
} from "./customerInsightsConstants";
import ChartContainer from "../Components/ChartContainer";
import { getChannelSeriesColor, Neutral300 } from "../utils/colors";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CustomerInsightsLegend } from "./CustomerInsightsGroup";
import { buildColorMap } from "./customerInsightsUtils";

const DEFAULT_BAR_CONTAINER_HEIGHT = 48;

interface DoubleBarProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const DoubleBar: React.FC<DoubleBarProps> = ({ data, fieldPrettyName, setSelectedValue }) => {
  const [hoveredValue, setHoveredValue] = useState<string | undefined>();
  const barMax = Math.max(
    ...data.map(val => val.clientPopulationFraction),
    ...data.map(val => val.tuPopulationFraction)
  );
  const colorMap = useMemo(() => buildColorMap(data), [data]);

  const barFormattedData = useMemo(() => {
    return data.map(({ clientPopulationFraction, tuPopulationFraction, levels }) => ({
      name: levels,
      val1: clientPopulationFraction,
      val2: tuPopulationFraction,
    }));
  }, [data]);

  const legendFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulationFraction, tuPopulationFraction }) => ({
      name: levels,
      val1: formatPercent(clientPopulationFraction, 1),
      val2: formatPercent(tuPopulationFraction, 1),
      computedValue: formatDecimal(clientPopulationFraction / tuPopulationFraction, 2),
    }));
  }, [data]);

  return (
    <div className="customerInsightsBarChart indexBarChart">
      <ChartContainer title={fieldPrettyName}>
        {barFormattedData.map((entry, index) => {
          const { name } = entry;
          const fill = colorMap[name];
          return (
            <div
              className={cn("indexBarContainer", {
                hoveredValue: name === hoveredValue,
                unhoveredValue: Boolean(hoveredValue) && name !== hoveredValue,
              })}
              style={{ height: DEFAULT_BAR_CONTAINER_HEIGHT }}
              key={name}
              onMouseEnter={() => {
                setHoveredValue(name);
              }}
              onMouseLeave={() => {
                setHoveredValue(undefined);
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={[entry]} layout="vertical" onClick={() => setSelectedValue(name)}>
                  <XAxis type="number" domain={[0, barMax]} hide />
                  <YAxis dataKey="name" type="category" hide />
                  <Legend
                    content={() => (
                      <CustomerInsightsLegend
                        data={[legendFormattedData[index]]}
                        hoveredValue={hoveredValue}
                        setHoveredValue={setHoveredValue}
                        setSelectedValue={setSelectedValue}
                      />
                    )}
                    layout="vertical"
                    align="left"
                  />
                  <Bar
                    dataKey="val1"
                    fill={fill}
                    isAnimationActive={false}
                    onClick={() => {
                      setSelectedValue(name);
                    }}
                  />
                  <Bar
                    dataKey="val2"
                    fill={fill}
                    isAnimationActive={false}
                    style={{ opacity: 0.25 }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          );
        })}
      </ChartContainer>
    </div>
  );
};

interface SelectedPieProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const SelectedPie: React.FC<SelectedPieProps> = ({
  data,
  fieldPrettyName,
  selectedValue,
  setSelectedValue,
}) => {
  const pieRef: RefObject<HTMLDivElement> = useRef(null);

  const colorMap = useMemo(() => {
    const map = {};
    for (let i = 0; i < data.length; i++) {
      const color = getChannelSeriesColor(i, true);
      map[data[i].levels] = color;
    }
    return map;
  }, [data]);

  const innerPieFormattedData = useMemo(() => {
    const formattedData: { name; value }[] = [];

    const selectedData = data.find(({ levels }) => levels === selectedValue);
    if (selectedData) {
      formattedData.push({
        name: selectedData.levels,
        value: Math.round(selectedData.clientPopulation),
      });
      formattedData.push({
        name: "Non-index",
        value: Math.round(selectedData.clientPopulation / selectedData.clientPopulationFraction),
      });
    }

    return formattedData;
  }, [data, selectedValue]);

  const outerPieFormattedData = useMemo(() => {
    const formattedData: { name; value }[] = [];

    const selectedData = data.find(({ levels }) => levels === selectedValue);
    if (selectedData) {
      formattedData.push({
        name: selectedData.levels,
        value: Math.round(selectedData.tuPopulation),
      });
      formattedData.push({
        name: "Non-index",
        value: Math.round(selectedData.tuPopulation / selectedData.tuPopulationFraction),
      });
    }

    return formattedData;
  }, [data, selectedValue]);

  const legendFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulationFraction, tuPopulationFraction }) => {
      return {
        name: levels,
        val1: formatPercent(clientPopulationFraction, 1),
        val2: formatPercent(tuPopulationFraction, 1),
        computedValue: formatDecimal(clientPopulationFraction / tuPopulationFraction, 2),
      };
    });
  }, [data]);

  useEffect(() => {
    const handleOutsideClick = event => {
      if (!(pieRef && pieRef.current)) {
        return;
      }

      const legend = pieRef.current.querySelector(".recharts-legend-wrapper .legend");
      const innerPie = pieRef.current.querySelector(".recharts-pie.indexInnerPie");
      const outerPie = pieRef.current.querySelector(".recharts-pie.indexOuterPie");

      const withinLegend = legend?.contains(event.target);
      const withinInnerPie = innerPie?.contains(event.target);
      const withinOuterPie = outerPie?.contains(event.target);

      if (!(withinLegend || withinInnerPie || withinOuterPie)) {
        setSelectedValue(undefined);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setSelectedValue]);

  return (
    <div className="customerInsightsPieChart indexSelectedPieChart" ref={pieRef}>
      <ChartContainer title={fieldPrettyName}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Legend
              content={() => (
                <CustomerInsightsLegend
                  data={legendFormattedData}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                />
              )}
              layout="vertical"
              align="left"
              verticalAlign="top"
            />
            <Pie
              className="indexInnerPie"
              data={innerPieFormattedData}
              isAnimationActive={false}
              startAngle={90}
              endAngle={-270}
              cx="50%"
              cy="50%"
              innerRadius="15%"
              outerRadius="45%"
              dataKey="value"
              onClick={() => setSelectedValue(undefined)}
            >
              {innerPieFormattedData.map(({ name }) => (
                <Cell
                  className="pieCell"
                  key={`cell-${name}`}
                  fill={colorMap[name] ?? Neutral300}
                />
              ))}
            </Pie>
            <Pie
              className="indexOuterPie"
              data={outerPieFormattedData}
              isAnimationActive={false}
              startAngle={90}
              endAngle={-270}
              cx="50%"
              cy="50%"
              innerRadius="46%"
              outerRadius="76%"
              dataKey="value"
              onClick={() => setSelectedValue(undefined)}
            >
              {outerPieFormattedData.map(({ name }) => (
                <Cell
                  className="pieCell"
                  key={`cell-${name}`}
                  fill={colorMap[name] ?? Neutral300}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

interface IndexChartProps {
  fieldName: CustomerInsightsFields;
  data: KnowYourCustomerData[];
}

const IndexChart: React.FC<IndexChartProps> = ({ fieldName, data }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[fieldName];

  if (selectedValue) {
    return (
      <SelectedPie
        data={data}
        fieldPrettyName={fieldPrettyName}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
    );
  }

  return (
    <DoubleBar data={data} fieldPrettyName={fieldPrettyName} setSelectedValue={setSelectedValue} />
  );
};

export default IndexChart;
