import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  SyntheticEvent,
} from "react";

import * as R from "ramda";

import { useScrollbarSizes } from "../../utils/hooks/useDOMHelpers";
import useVirtualize, { Virtual } from "./useVirtual";

import "./StickyTable.scss";
import { typedReactMemo } from "../../utils/types";

const DEFAULT_WIDTH = 100;
const DEFAULT_HEIGHT = 50;

interface MouseEvent {
  (e: SyntheticEvent): void;
}

export interface CellRenderer<T> {
  ({
    data,
    rowData,
    classes,
    style,
    rowIndex,
    virtual,
    onMouseOut,
    onMouseOver,
  }: {
    data: T;
    rowData: Object;
    classes: string[];
    style: object;
    rowIndex: number;
    columnIndex: number;
    virtual?: Virtual;
    onMouseOut?: MouseEvent;
    onMouseOver?: MouseEvent;
  }): JSX.Element;
}

interface AllButDataAndVirtualEqualObj {
  data: any;
  virtual?: Virtual;
  [key: string]: any;
}
// Virtual and Data are values that get passed to our various sub-elements. They are large objects.
// I don't want to deeply compare each object when it's time to check if we should re-render. So
// deep equal everything but those two. We'll do a shallow check on data. Virtual is a little
// particular (vertical headers only care if the row sub-object changes; no need to re-render if
// column virtual data changes) so we leave that comparison to the parent.
const allButDataAndVirtualEqual = (
  prev: AllButDataAndVirtualEqualObj,
  next: AllButDataAndVirtualEqualObj
) => {
  if (prev.data !== next.data) {
    return false;
  }
  let getAllButData = (obj: AllButDataAndVirtualEqualObj) =>
    R.props(R.pipe(R.keys, R.without(["data", "virtual"]))(obj), obj);
  return R.equals(getAllButData(prev), getAllButData(next));
};

interface TableCellProps<T> {
  data: T;
  rowData: Object;
  classes?: string[];
  style?: object;
  rowIndex: number;
  columnIndex: number;
  columnWidth: number;
  rowHeight: number;
  renderer: CellRenderer<T>;
  hovered?: boolean;
  rowHoverClass?: string;
  setHoveredRow?: (i?: number) => void;
  virtual?: Virtual;
}

// Don't do much but memoize and run the render function.
const TableCell = typedReactMemo(
  <T,>({
    data,
    rowData,
    classes = [],
    style = {},
    rowIndex,
    columnIndex,
    columnWidth,
    rowHeight,
    renderer,
    hovered,
    rowHoverClass,
    setHoveredRow = () => {},
    virtual,
  }: TableCellProps<T>): JSX.Element => {
    let onMouseOver = useCallback(() => setHoveredRow(rowIndex), [setHoveredRow, rowIndex]);
    let onMouseOut = useCallback(() => setHoveredRow(), [setHoveredRow]);
    const hoverProps = useMemo(() => {
      let props: {
        onMouseOver?: MouseEvent;
        onMouseOut?: MouseEvent;
      } = {};
      if (rowHoverClass) {
        props.onMouseOver = onMouseOver;
        props.onMouseOut = onMouseOut;
      }
      return props;
    }, [rowHoverClass, onMouseOver, onMouseOut]);

    const resolvedClasses = useMemo(() => {
      let ourClasses = ["stickyTableCell", ...classes];
      if (hovered && rowHoverClass) {
        ourClasses.push(rowHoverClass);
      }
      return ourClasses;
    }, [classes, hovered, rowHoverClass]);

    const resolvedStyle = useMemo(
      () => ({
        ...style,
        width: columnWidth,
        height: rowHeight,
      }),
      [style, columnWidth, rowHeight]
    );

    return renderer({
      data,
      rowData,
      style: resolvedStyle,
      classes: resolvedClasses,
      rowIndex,
      columnIndex,
      virtual,
      ...hoverProps,
    });
  },
  allButDataAndVirtualEqual
);

interface TableGridProps<T> {
  data: T[][];
  alternateColors: boolean;
  virtual: Virtual;
  renderer: CellRenderer<T>;
  rowHoverClass?: string;
  hoveredRow?: number;
  setHoveredRow: (i?: number) => void;
}

// This is the actual grid. It needs to do a 2d for loop
const TableGrid = typedReactMemo(
  <T,>({
    data,
    alternateColors,
    virtual,
    renderer,
    rowHoverClass,
    hoveredRow,
    setHoveredRow,
  }: TableGridProps<T>) => {
    const { cols, rows } = virtual;
    let items: React.ReactNode[] = [];
    // Only render items that our virtualization says we should
    for (let i = rows.visible.min; i <= rows.visible.max; ++i) {
      for (let j = cols.visible.min; j <= cols.visible.max; ++j) {
        const classes: string[] = [];
        if (alternateColors && i % 2) {
          classes.push("STOdd");
        }

        if (i === rows.visible.max) {
          classes.push("STlastRow");
        }

        items.push(
          <TableCell
            key={`${i}_${j}`}
            classes={classes}
            renderer={renderer}
            data={data[i][j]}
            rowData={data[i]}
            rowIndex={i}
            columnIndex={j}
            columnWidth={cols.sizes[j]}
            rowHeight={rows.sizes[i]}
            style={{
              top: rows.positions[i],
              left: cols.positions[j],
            }}
            rowHoverClass={rowHoverClass}
            hovered={hoveredRow === i}
            setHoveredRow={setHoveredRow}
            virtual={virtual}
          />
        );
      }
    }
    return <div className="STActualGrid">{items}</div>;
  },
  (prev, next) => {
    // We depend on the row and col sub-objects, so if they aren't shallow-equal that's good enough
    // to know we have to re-render.
    if (prev.virtual !== next.virtual) {
      return false;
    }
    return allButDataAndVirtualEqual(prev, next);
  }
);

interface TableHeaderProps<T, U = T> {
  data: T[];
  virtual: Virtual;
  renderer: CellRenderer<U>;
}
interface TableHeaderRowProps<T, U = T> extends TableHeaderProps<T, U> {
  className?: "STTopHeader" | "STBottomHeader";
  leftWidth?: number;
  top?: number;
  height: number;
}

// Horizontal header bars. These take a 1d data prop and some offsets for positioning
const TableHeaderRow = typedReactMemo(
  <T,>({
    data,
    className,
    leftWidth = 0,
    top = 0,
    height,
    virtual,
    renderer,
  }: TableHeaderRowProps<T>) => {
    const { cols } = virtual;
    const { visible, sizes, positions } = cols;

    let items: JSX.Element[] = [];
    for (let i = visible.min; i <= visible.max; ++i) {
      items.push(
        <TableCell
          key={i}
          renderer={renderer}
          data={data[i]}
          rowData={data}
          // Only one row!
          rowIndex={0}
          columnIndex={i}
          columnWidth={sizes[i]}
          rowHeight={height}
          style={{
            left: leftWidth + positions[i],
          }}
        />
      );
    }
    return (
      <div
        className={["stickyHeader", className].join(" ")}
        style={{
          height,
          top,
        }}
      >
        {items}
      </div>
    );
  },
  (prev, next) => {
    // If virtual.rows changes, we don't care, so we can still return true. If the cols change, we need to re-render
    if (prev.virtual.cols !== next.virtual.cols) {
      return false;
    }
    return allButDataAndVirtualEqual(prev, next);
  }
);

export interface SuperTopItemType<T> {
  span: number;
  data: T;
}

interface SuperTopInternalType<T> extends SuperTopItemType<T> {
  min: number;
  max: number;
  dataIndex: number;
}

type TableSuperHeaderRowProps<T> = TableHeaderRowProps<SuperTopInternalType<T>, T>;

const TableSuperHeaderRow = typedReactMemo(
  <T,>({
    data,
    className,
    leftWidth = 0,
    top = 0,
    height,
    virtual,
    renderer,
  }: TableSuperHeaderRowProps<T>) => {
    const { cols } = virtual;
    const { visible, sizes, positions } = cols;

    let seen = {};
    let items: JSX.Element[] = [];
    for (let i = visible.min; i <= visible.max; ++i) {
      let datum = data[i];
      // Keep track of the unique super header cells we've seen. If we have something with span 2,
      // we don't want to render it twice.
      if (seen[datum.dataIndex]) {
        continue;
      }
      seen[datum.dataIndex] = true;
      let width = 0;
      for (let colIndex = datum.min; colIndex <= datum.max; ++colIndex) {
        width += sizes[colIndex];
      }
      items.push(
        <TableCell<T>
          key={i}
          renderer={renderer}
          data={datum.data}
          rowData={datum}
          // Only one row!
          rowIndex={0}
          columnIndex={i}
          columnWidth={width}
          rowHeight={height}
          style={{
            left: leftWidth + positions[datum.min],
          }}
        />
      );
    }
    return (
      <div
        className={["stickyHeader", className].join(" ")}
        style={{
          height,
          top,
        }}
      >
        {items}
      </div>
    );
  },
  (prev, next) => {
    // If virtual.rows changes, we don't care, so we can still return true. If the cols change, we need to re-render
    if (prev.virtual.cols !== next.virtual.cols) {
      return false;
    }
    return allButDataAndVirtualEqual(prev, next);
  }
);

interface TableHeaderColProps<T> extends TableHeaderProps<T> {
  className: "STLeftHeader" | "STRightHeader";
  left?: number;
  width: number;
  alternateColors?: boolean;
  rowHoverClass?: string;
  hoveredRow?: number;
  setHoveredRow?: (i?: number) => void;
}

// The vertical headers. These also only take 1d arrays for data
const TableHeaderCol = typedReactMemo(
  <T,>({
    data,
    alternateColors,
    className,
    left = 0,
    width,
    virtual,
    renderer,
    rowHoverClass,
    hoveredRow,
    setHoveredRow,
  }: TableHeaderColProps<T>) => {
    const { rows } = virtual;
    const { visible, sizes, positions } = rows;

    let items: JSX.Element[] = [];
    for (let i = visible.min; i <= visible.max; ++i) {
      const classes: string[] = [];
      if (alternateColors && i % 2) {
        classes.push("STOdd");
      }
      items.push(
        <TableCell
          key={i}
          classes={classes}
          renderer={renderer}
          data={data[i]}
          rowData={data}
          rowIndex={i}
          columnIndex={0}
          columnWidth={width}
          rowHeight={sizes[i]}
          style={{
            top: positions[i],
          }}
          rowHoverClass={rowHoverClass}
          hovered={hoveredRow === i}
          setHoveredRow={setHoveredRow}
          virtual={virtual}
        />
      );
    }
    return (
      <div
        className={["stickyHeader", className].join(" ")}
        style={{
          width,
          left,
        }}
      >
        {items}
      </div>
    );
  },
  (prev, next) => {
    if (prev.virtual.rows !== next.virtual.rows) {
      return false;
    }
    return allButDataAndVirtualEqual(prev, next);
  }
);

export type CornerLocation = "nw" | "ne" | "sw" | "se";

interface CornerProps {
  className: "STnw" | "STne" | "STsw" | "STse";
  width: number;
  height: number;
  top: number;
  left: number;
  outerWidth?: boolean;
}

// These corners have z-index: 2 and sit above the headers. To get properly synchronized
// positioning, they also need "position: sticky". However, if they have a width or height, they'll
// offset the headers, kind of defeating the purpose. We can get around this by actually giving them
// a height/width of 0, giving them "overflow: visible", and sizing the sub-divs. The exception is
// some hackery for the right-side corners: they need to actually have a width to appear properly,
// so we take a flag to determine if the outer div should have the same width.
const Corner = typedReactMemo<React.FC<CornerProps>>(
  ({ outerWidth = false, className, width, height, top, left, children }) => (
    <div className="STCorner" style={{ top, left, width: outerWidth ? width : 0 }}>
      <div
        className={`STCornerInner ${className}`}
        style={{
          width,
          height,
        }}
      >
        {children}
      </div>
    </div>
  )
);

// Basic cell renderer. If someone wants to provide a custom function but only wants to change it
// slightly from the default, they can use a wrapper around this.
export const basicRenderer: CellRenderer<React.ReactNode> = ({
  data,
  style = {},
  classes = [],
  onMouseOut,
  onMouseOver,
}) => {
  let hoverProps: { onMouseOut?: MouseEvent; onMouseOver?: MouseEvent } = {};
  if (onMouseOut) {
    hoverProps.onMouseOut = onMouseOut;
  }
  if (onMouseOver) {
    hoverProps.onMouseOver = onMouseOver;
  }
  return (
    <div style={style} className={classes.join(" ")} {...hoverProps}>
      {data}
    </div>
  );
};

export const textCellRenderer: CellRenderer<string> = ({ classes = [], ...rest }) =>
  basicRenderer({ classes: ["stickyTableTextCell", ...classes], ...rest });

interface ScrollTo {
  (row: number, col: number): void;
}
interface StickyTableProps<
  TableDataType,
  TopDataType,
  LeftDataType,
  BottomDataType,
  RightDataType,
  SuperTopDataType
> {
  data: TableDataType[][];
  width: number;
  height: number;
  topData?: TopDataType[];
  leftData?: LeftDataType[];
  bottomData?: BottomDataType[];
  rightData?: RightDataType[];
  superTopData?: SuperTopItemType<SuperTopDataType>[];
  alternateColors?: boolean;
  columnWidth?: number | ((index: number) => number);
  rowHeight?: number | ((index: number) => number);
  topHeight?: number;
  rightWidth?: number;
  bottomHeight?: number;
  leftWidth?: number;
  superTopHeight?: number;
  cellRenderer?: CellRenderer<TableDataType>;
  topRenderer?: CellRenderer<TopDataType>;
  rightRenderer?: CellRenderer<RightDataType>;
  bottomRenderer?: CellRenderer<BottomDataType>;
  leftRenderer?: CellRenderer<LeftDataType>;
  superTopRenderer?: CellRenderer<SuperTopDataType>;
  cornerRenderer?: (location: CornerLocation) => JSX.Element;
  overscan?: number;
  rowHoverClass?: string;
  scrollToRef?: React.Ref<ScrollTo>;
  fillContainer?: boolean;
}

// This is where the magic happens!
export const StickyTable = <
  TableDataType,
  TopDataType,
  LeftDataType,
  BottomDataType,
  RightDataType,
  SuperTopDataType
>({
  topData,
  rightData,
  bottomData,
  leftData,
  // TODO: we need to add this for left/right/bottom
  superTopData,
  data,
  width,
  height,
  alternateColors = true,
  columnWidth = DEFAULT_WIDTH,
  rowHeight = DEFAULT_HEIGHT,
  topHeight: topHeightProp,
  rightWidth: rightWidthProp,
  bottomHeight: bottomHeightProp,
  leftWidth: leftWidthProp,
  superTopHeight: superTopHeightProp,
  cellRenderer = basicRenderer,
  topRenderer = basicRenderer,
  rightRenderer = basicRenderer,
  bottomRenderer = basicRenderer,
  leftRenderer = basicRenderer,
  superTopRenderer = basicRenderer,
  cornerRenderer,
  overscan = 10,
  // If set, this className will be applied all cells in a row that's hovered over
  rowHoverClass,
  scrollToRef,
  fillContainer,
}: StickyTableProps<
  TableDataType,
  TopDataType,
  LeftDataType,
  BottomDataType,
  RightDataType,
  SuperTopDataType
> & { ref?: React.RefObject<HTMLDivElement | HTMLInputElement> }): JSX.Element => {
  // This goes on the outermost div. We just use it for the scrollbar hook.
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollbarSizes = useScrollbarSizes(containerRef);
  const columnCount = data[0]?.length || topData?.length || bottomData?.length || 0;
  const rowCount = R.prop("length", data) || 0;

  const [topHeight, rightWidth, bottomHeight, leftWidth, superTopHeight] = useMemo(() => {
    let topHeight = 0;
    let rightWidth = 0;
    let bottomHeight = 0;
    let leftWidth = 0;
    let superTopHeight = 0;
    if (topData) {
      if (topHeightProp) {
        topHeight = topHeightProp;
      } else if (typeof rowHeight !== "function") {
        topHeight = rowHeight;
      } else {
        topHeight = DEFAULT_HEIGHT;
      }
    }
    if (rightData) {
      if (rightWidthProp) {
        rightWidth = rightWidthProp;
      } else if (typeof columnWidth !== "function") {
        rightWidth = columnWidth;
      } else {
        rightWidth = DEFAULT_WIDTH;
      }
    }

    if (bottomData) {
      if (bottomHeightProp) {
        bottomHeight = bottomHeightProp;
      } else if (typeof rowHeight !== "function") {
        bottomHeight = rowHeight;
      } else {
        bottomHeight = DEFAULT_HEIGHT;
      }
    }

    if (leftData) {
      if (leftWidthProp) {
        leftWidth = leftWidthProp;
      } else if (typeof columnWidth !== "function") {
        leftWidth = columnWidth;
      } else {
        leftWidth = DEFAULT_WIDTH;
      }
    }

    if (superTopData) {
      if (superTopHeightProp) {
        superTopHeight = superTopHeightProp;
      } else if (typeof rowHeight !== "function") {
        superTopHeight = rowHeight;
      } else {
        superTopHeight = DEFAULT_HEIGHT;
      }
    }

    return [topHeight, rightWidth, bottomHeight, leftWidth, superTopHeight];
  }, [
    topData,
    topHeightProp,
    rightData,
    rightWidthProp,
    bottomData,
    bottomHeightProp,
    leftData,
    leftWidthProp,
    superTopData,
    superTopHeightProp,
    rowHeight,
    columnWidth,
  ]);

  const superTopList = useMemo(() => {
    if (!superTopData) {
      return null;
    }
    let colIndex = 0;
    let superList: SuperTopInternalType<SuperTopDataType>[] = new Array(columnCount);
    for (let superIndex = 0; superIndex < superTopData.length; ++superIndex) {
      let datum = superTopData[superIndex];
      let { span } = datum;
      for (let i = colIndex; i < colIndex + span; ++i) {
        superList[i] = {
          ...datum,
          min: colIndex,
          max: colIndex + span - 1,
          dataIndex: superIndex,
        };
      }
      colIndex += span;
    }
    return superList;
  }, [superTopData, columnCount]);

  // Size of the actual grid. The grid table will need to have these dimensions. Because we have
  // custom size functions, we have to iterate over the entire data object and aggregate the
  // dimensions. For large data sets this can be expensive. This only needs to be re-computed when
  // the sizes or data change, so memoize!
  const [contentWidth, contentHeight] = useMemo(() => {
    let contentWidth = 0;
    let contentHeight = 0;
    if (typeof columnWidth === "function") {
      for (let i = 0; i < columnCount; ++i) {
        contentWidth += columnWidth(i);
      }
    } else {
      contentWidth = columnWidth * columnCount;
    }

    if (typeof rowHeight === "function") {
      for (let i = 0; i < rowCount; ++i) {
        contentHeight += rowHeight(i);
      }
    } else {
      contentHeight = rowHeight * rowCount;
    }
    const remainingHeight = height - topHeight - bottomHeight - superTopHeight;
    if (fillContainer && contentHeight < remainingHeight) {
      contentHeight = remainingHeight - scrollbarSizes.height;
    }
    const remainingWidth = width - leftWidth;
    if (fillContainer || contentWidth < remainingWidth) {
      contentWidth = remainingWidth - scrollbarSizes.width;
    }
    return [contentWidth, contentHeight];
  }, [
    columnWidth,
    rowHeight,
    height,
    topHeight,
    bottomHeight,
    superTopHeight,
    fillContainer,
    width,
    leftWidth,
    columnCount,
    rowCount,
    scrollbarSizes.height,
    scrollbarSizes.width,
  ]);

  let [viewWidth, viewHeight, rightLeftPos, bottomTopPos] = useMemo(() => {
    // Size of the grid plus all the headers. The containing div will need to have these
    // dimensions
    let totalWidth = leftWidth + rightWidth + contentWidth;
    let totalHeight = topHeight + bottomHeight + superTopHeight + contentHeight;

    let hasHorizScroll = false;
    let viewWidth = totalWidth;
    // If the size of the interior table is greater than its view, there will be scroll bars.
    // Additionally, the box will be the full size of the view. If the interior is smaller than the
    // view, there are no scroll bars and we want the view to only be as big as the interior. If the
    // interior plus scroll bars is greater than the outside, just include them.
    if (totalWidth + scrollbarSizes.width > width) {
      hasHorizScroll = true;
      viewWidth = width;
    }

    let hasVertScroll = false;
    let viewHeight = totalHeight;

    if (totalHeight + scrollbarSizes.height > height) {
      hasVertScroll = true;
      viewHeight = height;
    } else {
      hasVertScroll = false;
      viewHeight = totalHeight;
    }

    // The position from the left things like the right table/border/corners will be. This will be
    // the size of the view minus the size of the right header.
    let rightLeftPos = viewWidth - rightWidth;
    let bottomTopPos = viewHeight - bottomHeight;

    // If there are scroll bars, we're going to need to tweak some sizing and positioning.
    if (hasVertScroll || hasHorizScroll) {
      // If there are scroll bars on both sides, we need to move all our headers, corners, and
      // fake borders over the size of the scroll bars. If there is only one scroll bar, the side
      // that doesn't have one is smaller than the box. Thus the view was shrunk to only be the
      // full size of the content. However, that content is now being pushed a little by the
      // scroll bar in the other direction, requiring us to scroll that tiny amount. So expand
      // that width
      if (hasVertScroll && hasHorizScroll) {
        rightLeftPos -= Math.max(scrollbarSizes.width, width - viewWidth);
        bottomTopPos -= scrollbarSizes.height;
      } else if (hasVertScroll) {
        viewWidth += scrollbarSizes.width;
      } else {
        viewHeight += scrollbarSizes.height;
      }
    }
    if (fillContainer) {
      bottomTopPos = height - bottomHeight;
      viewHeight = height + (hasHorizScroll ? scrollbarSizes.height : 0);
      viewWidth = width + (hasVertScroll ? scrollbarSizes.width : 0);
    }
    return [viewWidth, viewHeight, rightLeftPos, bottomTopPos];
  }, [
    leftWidth,
    rightWidth,
    contentWidth,
    topHeight,
    bottomHeight,
    superTopHeight,
    contentHeight,
    height,
    width,
    scrollbarSizes,
    fillContainer,
  ]);

  // Given all relevant data, get virtual information. This includes
  // - Minimum and maximum rows/columns that must be rendered to populate the viewable box (the
  //   ranges that, if you're looking at an element outside that range, you don't have to render
  //   it).
  // - Dimensions of elements
  // - Absolute positions of elements
  // In order to get the virtualization (min/max) information, we have to compute the positions,
  // and in order to compute the positions, we need the sizes. Thus, we just handle and return all
  // this information so we don't have to repeat the work. This takes overscanning into account.
  // Since this is the only thing that is hooking into the scroll events on the div, we provide
  // our own setters and keep the state internally.
  let [virtual, setLeft, setTop] = useVirtualize({
    overscan,
    columnCount,
    rowCount,
    columnWidth,
    rowHeight,
    width: viewWidth - leftWidth - rightWidth,
    height: viewHeight - topHeight - bottomHeight - superTopHeight,
  });

  useImperativeHandle(
    scrollToRef,
    () => (row: number, col: number) => {
      let xPos = virtual.cols.positions[col] || 0;
      let yPos = virtual.rows.positions[row] || 0;
      containerRef.current?.scrollTo(xPos, yPos);
    },
    [virtual]
  );

  const [hoveredRow, setHoveredRow] = useState<number>();

  return (
    <div
      className="stickyTableOuter"
      style={{
        width,
        height,
      }}
    >
      <div
        ref={containerRef}
        className="stickyTable"
        style={{
          width: width,
          height: height,
        }}
        onScroll={e => {
          setLeft(e.currentTarget.scrollLeft);
          setTop(e.currentTarget.scrollTop);
        }}
      >
        {(topData || superTopData) && leftData && (
          <Corner
            className="STnw"
            width={leftWidth}
            height={topHeight + superTopHeight}
            top={0}
            left={0}
          >
            {cornerRenderer ? cornerRenderer("nw") : null}
          </Corner>
        )}
        {bottomData && leftData && (
          <Corner
            className="STsw"
            width={leftWidth}
            height={bottomHeight}
            top={bottomTopPos}
            left={0}
          >
            {cornerRenderer ? cornerRenderer("sw") : null}
          </Corner>
        )}
        {(topData || superTopData) && rightData && (
          <Corner
            className="STne"
            outerWidth
            width={rightWidth}
            height={topHeight + superTopHeight}
            top={0}
            left={rightLeftPos}
          >
            {cornerRenderer ? cornerRenderer("ne") : null}
          </Corner>
        )}
        {bottomData && rightData && (
          <Corner
            className="STse"
            outerWidth
            width={rightWidth}
            height={bottomHeight}
            top={bottomTopPos}
            left={rightLeftPos}
          >
            {cornerRenderer ? cornerRenderer("se") : null}
          </Corner>
        )}
        {superTopList && (
          <TableSuperHeaderRow
            data={superTopList}
            leftWidth={leftWidth}
            height={superTopHeight}
            virtual={virtual}
            renderer={superTopRenderer}
          />
        )}
        {topData && (
          <TableHeaderRow<TopDataType>
            data={topData}
            className="STTopHeader"
            leftWidth={leftWidth}
            top={superTopHeight}
            height={topHeight}
            virtual={virtual}
            renderer={topRenderer}
          />
        )}
        {leftData && (
          <TableHeaderCol<LeftDataType>
            data={leftData}
            className="STLeftHeader"
            width={leftWidth}
            virtual={virtual}
            renderer={leftRenderer}
            rowHoverClass={rowHoverClass}
            hoveredRow={hoveredRow}
            setHoveredRow={setHoveredRow}
            alternateColors={alternateColors}
          />
        )}
        {rightData && (
          <TableHeaderCol<RightDataType>
            data={rightData}
            className="STRightHeader"
            left={rightLeftPos}
            width={rightWidth}
            virtual={virtual}
            renderer={rightRenderer}
            rowHoverClass={rowHoverClass}
            hoveredRow={hoveredRow}
            setHoveredRow={setHoveredRow}
            alternateColors={alternateColors}
          />
        )}
        {bottomData && (
          <TableHeaderRow<BottomDataType>
            data={bottomData}
            className="STBottomHeader"
            top={bottomTopPos}
            leftWidth={leftWidth}
            height={bottomHeight}
            virtual={virtual}
            renderer={bottomRenderer}
          />
        )}
        <div
          className="STInnerGrid"
          style={{
            width: contentWidth + rightWidth,
            maxWidth: contentWidth + rightWidth,
            height: contentHeight,
            maxHeight: contentHeight,
            top: -bottomHeight,
            left: leftWidth,
          }}
        >
          <TableGrid<TableDataType>
            data={data}
            alternateColors={alternateColors}
            virtual={virtual}
            renderer={cellRenderer}
            rowHoverClass={rowHoverClass}
            hoveredRow={hoveredRow}
            setHoveredRow={setHoveredRow}
          />
        </div>
      </div>
    </div>
  );
};

export default StickyTable;
