import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import {
  Metric,
  Metrics,
  CrossChannelMetrics,
  MetricTotalsByDate,
  KpiData,
} from "@blisspointmedia/bpm-types/dist/CrossChannel";
import * as CC from "@blisspointmedia/bpm-types/dist/CrossChannelPerformance";
import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  toPretty1000sInteger,
  toPrettyPercent,
} from "../SingleChannel/MetricsTable/metricsTableUtils";
import { METRIC_TO_PRETTY_NAME } from "./crossChannelConstants";
import * as Dfns from "date-fns/fp";
import * as DfnsTZ from "date-fns-tz/fp";
import * as R from "ramda";
import { metricFormatter } from "./crossChannelFormatters";

const {
  CLICKS,
  CLICKS_CONVERSION_RATE,
  CPC,
  CPM,
  CPX,
  CTR,
  IMPS_CONVERSION_RATE,
  IMPRESSIONS,
  REVENUE,
  ROAS,
  SPEND,
  VOLUME,
} = Metric;

const DAY_DATE_LABEL = "M/dd/yy";
const formatDateLabel = (date: string): string => {
  if (!date) {
    return "";
  }

  const toUTC = DfnsTZ.utcToZonedTime("UTC", new Date(date));
  const isValid = Dfns.isValid(toUTC);

  if (!isValid) {
    return "";
  }

  const formattedDate = Dfns.format(DAY_DATE_LABEL, toUTC);

  return formattedDate;
};

export const makePrettyDateRange = (start: string, end: string): string => {
  return `${formatDateLabel(start)}–${formatDateLabel(end)}`;
};

/**
 * Get default date ranges for date picker.
 */
export const getDefaultDates = (): {
  start: string;
  end: string;
  otherStart: string;
  otherEnd: string;
} => {
  // TODO: Remove after demos.
  const start = "2024-04-01";
  const end = "2024-04-30";
  const otherStart = "2024-03-01";
  const otherEnd = "2024-03-30";
  // const start = R.pipe(Dfns.subDays(31), Dfns.format(DATE_FORMAT))(new Date());
  // const end = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());
  // const otherStart = R.pipe(Dfns.subDays(61), Dfns.format(DATE_FORMAT))(new Date());
  // const otherEnd = R.pipe(Dfns.subDays(32), Dfns.format(DATE_FORMAT))(new Date());
  return { start, end, otherStart, otherEnd };
};

/**
 * Aggregate platform delivery metrics by day for the Overview tab. If addPerformanceMetrics is true,
 * KPI Volume and Revenue (from the Source of Truth) will be included in the metrics.
 */
export const calculateOverviewMetricsByDay = ({
  metricsData,
  kpiData,
  selectedKpi,
  addPerformanceMetrics = false,
}: {
  metricsData: CrossChannelMetrics[];
  kpiData: KpiData[];
  selectedKpi: string | undefined;
  addPerformanceMetrics?: boolean;
}): MetricTotalsByDate[] => {
  if (R.isNil(metricsData) || R.isEmpty(metricsData)) {
    return [];
  }

  // Filter out performance metrics
  const deliveryMetricsData = metricsData.map(({ date, metrics }) => {
    return {
      date,
      metrics: {
        [CLICKS]: metrics[CLICKS],
        [CPC]: metrics[CPC],
        [CPM]: metrics[CPM],
        [CTR]: metrics[CTR],
        [IMPRESSIONS]: metrics[IMPRESSIONS],
        [SPEND]: metrics[SPEND],
      },
    };
  });

  // Sum metric totals by day
  let metricsByDay: Record<string, Metrics> = {};
  for (let item of deliveryMetricsData) {
    const { date, metrics } = item;
    metricsByDay[date] = R.mergeWith(R.add, metricsByDay[date] || {}, metrics);
  }

  // If KPI data is provided, sum KPI counts and revenue by day
  let kpisByDay: Record<string, Metrics> = {};

  if (addPerformanceMetrics) {
    // Sum KPI counts and revenue by day
    for (let item of kpiData) {
      const { date, count, revenue, kpi } = item;
      if (kpi !== selectedKpi) {
        continue;
      }
      let kpiMetrics = { [VOLUME]: count, [REVENUE]: revenue };
      kpisByDay[date] = R.mergeWith(R.add, kpisByDay[date] || {}, kpiMetrics);
    }

    // Replace Volume and Revenue metrics with KPIs
    for (let date of R.keys(kpisByDay)) {
      if (kpisByDay[date] && metricsByDay[date]) {
        metricsByDay[date] = { ...metricsByDay[date], ...kpisByDay[date] };
      }
    }
  }

  // Calculate derived metrics by day
  const allMetricsByDay = Object.entries(metricsByDay).map(([date, metrics]) => {
    const spend = R.pathOr(0, [date, SPEND], metricsByDay);
    const clicks = R.pathOr(0, [date, CLICKS], metricsByDay);
    const impressions = R.pathOr(0, [date, IMPRESSIONS], metricsByDay);
    const volume = R.pathOr(0, [date, VOLUME], metricsByDay);
    const revenue = R.pathOr(0, [date, REVENUE], metricsByDay);

    let computedMetrics = {};
    computedMetrics[CPC] = calculateCPC(spend, clicks);
    computedMetrics[CPM] = calculateCPM(spend, impressions);
    computedMetrics[CPX] = calculateCPX(spend, volume);
    computedMetrics[ROAS] = calculateROAS(revenue, spend);
    computedMetrics[CTR] = calculateCTR(clicks, impressions);
    computedMetrics[CLICKS_CONVERSION_RATE] = calculateCCR(volume, clicks);
    computedMetrics[IMPS_CONVERSION_RATE] = calculateICR(volume, impressions);

    return { date, metrics: { ...metrics, ...computedMetrics } };
  });

  return allMetricsByDay.sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Calculate Cost-Per-Click (CPC).
 */
export const calculateCPC = (spend: number, clicks: number): number => {
  return clicks ? spend / clicks : 0;
};

/**
 * Calculate Cost-Per-Thousand Impressions (CPM).
 */
export const calculateCPM = (spend: number, impressions: number): number => {
  return impressions ? (spend / impressions) * 1000 : 0;
};

/**
 * Calculate Cost-Per-X. X is the selected KPI (CPX)
 */
export const calculateCPX = (spend: number, volume: number): number => {
  return volume ? spend / volume : 0;
};

/**
 * Calculate Return on Ad Spend (ROAS)
 */
export const calculateROAS = (revenue: number, spend: number): number => {
  return spend ? revenue / spend : 0;
};

/**
 * Calculate Click Through Rate (CTR)
 */
export const calculateCTR = (clicks: number, impressions: number): number => {
  return impressions ? clicks / impressions : 0;
};

/**
 * Calculate Clicks Conversion Rate (CR)
 */
export const calculateCCR = (volume: number, clicks: number): number => {
  return clicks ? volume / clicks : 0;
};

/**
 * Calculate Impressions Conversion Rate (CR)
 */
export const calculateICR = (volume: number, impressions: number): number => {
  return impressions ? volume / impressions : 0;
};

export const getPrettyMetricName = (metric: string): string => {
  return METRIC_TO_PRETTY_NAME[metric] || metric;
};

export const makeColumnMetaData = (
  metric: CC.DimensionColumnType,
  iconStyle?: "logo" | "thumbnail" | undefined
): ColumnMetaData => {
  return {
    contentType: "text",
    dimensionVarName: metric,
    displayName: metric,
    iconStyle: iconStyle || undefined,
  };
};

export const dimensionColumnMetaDataMap: Partial<Record<CC.DimensionColumnType, ColumnMetaData>> = {
  Platform: makeColumnMetaData("Platform", "logo"),
  Channel: makeColumnMetaData("Channel", "logo"),
  "Account Name": makeColumnMetaData("Account Name"),
  "Account ID": makeColumnMetaData("Account ID"),
};

export const columnMetaDataMap: Partial<Record<CC.ColumnType, ColumnMetaData>> = {
  [CLICKS]: {
    displayName: METRIC_TO_PRETTY_NAME[CLICKS],
    formatValue: metricFormatter(CLICKS),
  },
  [SPEND]: {
    displayName: METRIC_TO_PRETTY_NAME[SPEND],
    formatValue: metricFormatter(SPEND),
  },
  [IMPRESSIONS]: {
    displayName: "Impressions (000s)",
    formatValue: toPretty1000sInteger,
  },
  [VOLUME]: {
    displayName: METRIC_TO_PRETTY_NAME[VOLUME],
    formatValue: metricFormatter(VOLUME),
  },
  [REVENUE]: {
    displayName: METRIC_TO_PRETTY_NAME[REVENUE],
    formatValue: metricFormatter(REVENUE),
  },
  [CPC]: {
    displayName: METRIC_TO_PRETTY_NAME[CPC],
    formatValue: metricFormatter(CPC),
    aggregator: agg =>
      agg[CLICKS] && agg[SPEND] ? (agg[SPEND] as number) / (agg[CLICKS] as number) : "--",
    fetchGetter: fetch =>
      fetch[CLICKS] && fetch[SPEND] ? (fetch[SPEND] as number) / (fetch[CLICKS] as number) : "--",
    requiredTotalsColumns: [CLICKS, SPEND],
  },
  [CPM]: {
    displayName: METRIC_TO_PRETTY_NAME[CPM],
    formatValue: metricFormatter(CPM),
    aggregator: agg =>
      agg[IMPRESSIONS] && agg[SPEND]
        ? ((agg[SPEND] as number) * 1000) / (agg[IMPRESSIONS] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[IMPRESSIONS] && fetch[SPEND]
        ? ((fetch[SPEND] as number) * 1000) / (fetch[IMPRESSIONS] as number)
        : "--",
    requiredTotalsColumns: [IMPRESSIONS, SPEND],
  },
  [ROAS]: {
    displayName: METRIC_TO_PRETTY_NAME[ROAS],
    formatValue: metricFormatter(ROAS),
    aggregator: agg =>
      agg[REVENUE] && agg[SPEND] ? (agg[REVENUE] as number) / (agg[SPEND] as number) : "--",
    fetchGetter: fetch =>
      fetch[REVENUE] && fetch[SPEND] ? (fetch[REVENUE] as number) / (fetch[SPEND] as number) : 0,
    requiredTotalsColumns: [REVENUE, SPEND],
  },
  [CPX]: {
    displayName: METRIC_TO_PRETTY_NAME[CPX],
    formatValue: metricFormatter(CPX),
    aggregator: agg =>
      agg[VOLUME] && agg[SPEND] ? (agg[SPEND] as number) / (agg[VOLUME] as number) : "--",
    fetchGetter: fetch =>
      fetch[VOLUME] && fetch[SPEND] ? (fetch[SPEND] as number) / (fetch[VOLUME] as number) : "--",
    requiredTotalsColumns: [VOLUME, SPEND],
  },
  [CTR]: {
    displayName: METRIC_TO_PRETTY_NAME[CTR],
    formatValue: toPrettyPercent,
    aggregator: agg =>
      agg[CLICKS] && agg[IMPRESSIONS]
        ? (agg[CLICKS] as number) / (agg[IMPRESSIONS] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[CLICKS] && fetch[IMPRESSIONS]
        ? (fetch[CLICKS] as number) / (fetch[IMPRESSIONS] as number)
        : "--",
    requiredTotalsColumns: [CLICKS, IMPRESSIONS],
  },
  [CLICKS_CONVERSION_RATE]: {
    displayName: METRIC_TO_PRETTY_NAME[CLICKS_CONVERSION_RATE],
    formatValue: toPrettyPercent,
    aggregator: agg =>
      agg[VOLUME] && agg[CLICKS] ? (agg[VOLUME] as number) / (agg[CLICKS] as number) : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch[VOLUME] && fetch[CLICKS] ? (fetch[VOLUME] as number) / (fetch[CLICKS] as number) : "--",
    requiredTotalsColumns: [VOLUME, CLICKS],
  },
  [IMPS_CONVERSION_RATE]: {
    displayName: METRIC_TO_PRETTY_NAME[IMPS_CONVERSION_RATE],
    formatValue: toPrettyPercent,
    aggregator: agg =>
      agg[VOLUME] && agg[IMPRESSIONS]
        ? (agg[VOLUME] as number) / (agg[IMPRESSIONS] as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch[VOLUME] && fetch[IMPRESSIONS]
        ? (fetch[VOLUME] as number) / (fetch[IMPRESSIONS] as number)
        : "--",
    requiredTotalsColumns: [VOLUME, IMPRESSIONS],
  },
};

export const getCrossChannelDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn
): CellData => {
  const { dimensionVarName, dimensionTypeName, icon } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<string, string>;
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    label: dimensionValue,
    value: dimensionValue,
  };
  if (dimensionTypeName === "Channel") {
    const parsedDimensionValue = dimensionValue.replace(/\s+/g, "");
    const url = !R.isNil(icon)
      ? `${CDN}/assets/img/channels/${parsedDimensionValue}.png`
      : undefined;
    return {
      ...defaultCell,
      url,
    };
  } else if (dimensionTypeName === "Platform") {
    const channelMap = {
      audio: "Streaming Audio",
      linear: "Linear TV",
      streaming: "Streaming Video",
    };
    const prettyNameMap = {
      audio: "Streaming Audio",
      facebook: "Meta",
      google_ads: "Google Ads",
      linear: "Linear TV",
      streaming: "Streaming Video",
      tiktok: "TikTok",
      twitter: "X",
    };
    const prettyName = prettyNameMap[dimensionValue.toLowerCase()]
      ? prettyNameMap[dimensionValue.toLowerCase()]
      : `${dimensionValue.replace(/_/g, " ").charAt(0).toUpperCase()}${dimensionValue
          .replace(/_/g, " ")
          .slice(1)}`;
    const url = !R.isNil(icon)
      ? `${CDN}/assets/img/${
          channelMap[dimensionValue.toLowerCase()] ? "channels" : "platforms"
        }/${prettyName.replace(/ /g, "")}.png`
      : undefined;
    return {
      label: prettyName,
      value: prettyName,
      url,
    };
  }

  return defaultCell;
};

/**
 * SPARC is a portfolio of companies, and there are cases when they need custom behavior.
 */
export const isSparcCompany = (company: string): boolean =>
  ["reebok", "nautica", "eddiebauer", "lucky", "aeropostale", "brooksbrothers"].includes(company);
