import React, { useCallback, useContext, useMemo, useRef } from "react";

import * as R from "ramda";

import cn from "classnames";

import { Popover, Button } from "react-bootstrap";

import { useDrag, useDrop } from "react-dnd";

import Select from "react-select";

import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";

import {
  Column,
  Preset,
  PresetChanges,
  StreamingColumnCategory,
} from "@blisspointmedia/bpm-types/dist/Performance";

import { OldDropdown, OverlayTrigger, CheckBox, KpiPicker, OnBlurInput } from "../../Components";
import { getColumnMetadataMap as getStreamingColumnMetadataMap } from "../StreamingPerformance/streamingPerformanceUtils";
import { COLUMN_METADATA_MAP as LINEAR_COLUMN_METADATA_MAP } from "../LinearPerformance/linearPerformanceUtils";
import { COLUMN_METADATA_MAP as YOUTUBE_COLUMN_METADATA_MAP } from "../YouTubePerformance/youtubePerformanceUtils";
import {
  MAX_DECIMALS,
  resolveDecimals,
  PerformanceContext,
  ColumnMetaData,
  PercentageDisplayMode,
} from "../performanceUtils";
import { MoveColumn, DeleteColumn, COLUMN_DND_TYPE } from "./configUtils";
import { StateSetter } from "../../utils/types";
import ColumnTop from "./ColumnTop";

import "./ConfigColumn.scss";

interface OnColumnChange {
  <T extends keyof Column>(i: number, key: T, value: Column[T]): void;
}

interface ConfigColumnProps<P extends Preset> {
  i: number;
  column: Column;
  setPresetChanges: StateSetter<PresetChanges<P>>;
  moveColumn: MoveColumn;
  deleteColumn: DeleteColumn;
  isLast: boolean;
  addColumn: (i: number) => void;
  onSuperHeaderAdd: () => void;
  hasSuperHeader: boolean;
  color?: string;
  hoverItem?: string;
  isLinear?: boolean;
  isYoutube?: boolean;
}

const ConfigColumn = <P extends Preset>({
  column,
  i,
  setPresetChanges,
  moveColumn,
  deleteColumn,
  isLast,
  color,
  onSuperHeaderAdd,
  hasSuperHeader,
  addColumn,
  hoverItem,
  isLinear,
  isYoutube,
}: ConfigColumnProps<P>): JSX.Element => {
  const { kpiMetaData, globalKpi, isGraph } = useContext(PerformanceContext);

  const company = useMemo(() => {
    return globalKpi ? globalKpi.split("_")[0] : "tinuititest";
  }, [globalKpi]);
  const columnMetaDataMap: Record<string, ColumnMetaData | undefined> = useMemo(() => {
    return isYoutube
      ? YOUTUBE_COLUMN_METADATA_MAP
      : isLinear
      ? LINEAR_COLUMN_METADATA_MAP
      : getStreamingColumnMetadataMap(isGraph, company);
  }, [isYoutube, isLinear, isGraph, company]);

  const {
    id,
    label,
    minWidth,
    kpi,
    type,
    lag,
    audience,
    pct,
    decimals,
    heatMapping,
    divider,
    adminOnly,
  } = column;
  const category = useMemo(
    () =>
      isLinear || isYoutube
        ? null
        : getStreamingColumnMetadataMap(isGraph, company)[type]?.category,
    [isLinear, isYoutube, isGraph, type, company]
  );

  const [isSpecial, metadata] = useMemo(() => {
    let metadata = { ...columnMetaDataMap[type] };

    let basicColumnTypes = isYoutube
      ? Y.BASIC_COLUMN_TYPES
      : isLinear
      ? L.BASIC_COLUMN_TYPES
      : S.BASIC_COLUMN_TYPES;
    // https://github.com/microsoft/TypeScript/issues/26255
    const isSpecial = !(basicColumnTypes as readonly string[]).includes(type);
    if (isSpecial) {
      const kpiInfo = kpiMetaData[kpi || globalKpi];

      if (kpiInfo) {
        metadata.decimals = kpiInfo.decimals;
        metadata.defaultLabel = (metadata.defaultLabel || metadata.prettyName || "").replace(
          "CPX",
          kpiInfo.cpxName
        );
      }
    }
    return [isSpecial, metadata];
  }, [columnMetaDataMap, type, isYoutube, isLinear, kpiMetaData, kpi, globalKpi]);

  const columnRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: COLUMN_DND_TYPE, id, i },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const [{ isOver, dropI }, drop] = useDrop({
    accept: COLUMN_DND_TYPE,
    canDrop: item => item.id !== id,
    collect: monitor => ({
      isOver: monitor.isOver() && monitor.canDrop(),
      dropI: monitor.getItem()?.i,
    }),
    drop: (item: { type: typeof COLUMN_DND_TYPE; id: string }) => {
      moveColumn(item.id, column.id);
    },
  });

  drop(preview(columnRef));
  drag(handleRef);

  const defaultLabel = useMemo(() => {
    if (type.startsWith("avgRevenue")) {
      return `${kpiMetaData[kpi || globalKpi]?.revenueHeader || "Avg. Rev."} (default)`;
    }
    return `${metadata.defaultLabel || metadata.prettyName} (default)`;
  }, [metadata, kpiMetaData, kpi, type, globalKpi]);

  const onChange = useCallback<OnColumnChange>(
    (i, key, value) => {
      setPresetChanges(changes => ({
        ...changes,
        columns: R.adjust(
          i,
          column => ({
            ...column,
            [key]: value,
          }),
          changes.columns
        ),
      }));
    },
    [setPresetChanges]
  );

  const [categorySelectVal, categorySelectOptions] = useMemo(() => {
    const CATEGORY_OPTIONS = R.map(
      category => ({
        value: category,
        label: category,
      }),
      Object.values(StreamingColumnCategory)
    );

    return [
      category ? { value: category, label: category } : null,
      R.pipe(
        R.values,
        R.sortBy(item => item.label.toLowerCase())
      )(CATEGORY_OPTIONS),
    ];
  }, [category]);

  const getValidTypeOptions = useCallback(
    (category = null) => {
      let columnMetaDataMapFiltered: Record<string, ColumnMetaData> = {};
      for (let key of R.keys(columnMetaDataMap)) {
        let col = columnMetaDataMap[key];
        if (col) {
          const categoryToFilterBy = category || categorySelectVal?.value;
          if (!categoryToFilterBy || col.category === categoryToFilterBy) {
            columnMetaDataMapFiltered[key] = col;
          }
        }
      }
      const TYPE_OPTIONS = R.mapObjIndexed(
        (val, type) => ({
          value: type,
          label: val.prettyName || type,
        }),
        columnMetaDataMapFiltered
      );
      return TYPE_OPTIONS;
    },
    [categorySelectVal, columnMetaDataMap]
  );

  const [typeSelectVal, typeSelectOptions] = useMemo(() => {
    const typeOptions = getValidTypeOptions();
    return [
      { value: type, label: R.defaultTo("Unknown", typeOptions[type]?.label) },
      R.pipe(
        R.values,
        R.sortBy(item => item.label.toLowerCase())
      )(typeOptions),
    ];
  }, [getValidTypeOptions, type]);

  // TODO: what if they delete the last column and try to save?
  return (
    <div className="columnConfigOuter" ref={columnRef}>
      <div
        className={cn("columnConfig", {
          hasDivider: divider,
          isOver,
          left: isOver && dropI > i,
          right: isOver && dropI <= i,
          dragging: isDragging,
          isHoverItem: hoverItem === id,
        })}
        key={i}
      >
        <ColumnTop
          color={color}
          onSuperHeaderAdd={onSuperHeaderAdd}
          hasHeader={hasSuperHeader}
          handleRef={handleRef}
          addColumn={addColumn}
          i={i}
        />
        {category && (
          <div className="formSection">
            <div className="label">Category</div>
            <Select
              className="typeSelect"
              value={categorySelectVal}
              options={categorySelectOptions}
              onChange={item => {
                const newTypeOptions = getValidTypeOptions(item.value);
                // If the type is no longer a valid option for the category,
                // just default to the first type within the new category.
                if (!Object.keys(newTypeOptions).includes(type)) {
                  onChange(
                    i,
                    "type",
                    Object.keys(newTypeOptions)[0] as S.ColumnType | L.ColumnType | Y.ColumnType
                  );
                }
                onChange(i, "category", item.value as StreamingColumnCategory);
              }}
            />
          </div>
        )}
        <div className="formSection">
          <div className="label">Type</div>
          <Select
            className="typeSelect"
            value={typeSelectVal}
            options={R.sortBy(R.prop("label"), typeSelectOptions)}
            onChange={(item: typeof typeSelectOptions[number]) =>
              onChange(i, "type", item.value as S.ColumnType | L.ColumnType | Y.ColumnType)
            }
          />
        </div>
        <div className="formSection">
          <div className="label">Label</div>
          <OnBlurInput
            size="sm"
            value={label || ""}
            placeholder={defaultLabel}
            onChange={val => onChange(i, "label", val || undefined)}
          />
        </div>
        {isSpecial && (
          <>
            <div className="basicSection kpiSection">
              <div className="label">
                KPI:{" "}
                {kpi && (
                  <small className="resetToGlobal" onClick={() => onChange(i, "kpi", undefined)}>
                    (Reset to global)
                  </small>
                )}
              </div>
              <OverlayTrigger
                exitOnBackgroundClick
                trigger="click"
                overlay={
                  <Popover id={`${id}_kpi_picker`} className="performanceKpiPickerPopover">
                    <Popover.Content>
                      <KpiPicker
                        kpi={kpi}
                        mediaType="streaming"
                        onChange={kpi => onChange(i, "kpi", kpi)}
                        disableTypeSelector
                      />
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="value">{kpi || "Global"}</div>
              </OverlayTrigger>
            </div>
            <div className="basicSection">
              <OldDropdown
                size="sm"
                label={isLinear ? "Audience" : "Lag"}
                value={(isLinear ? audience : lag) || "Global"}
                options={
                  isYoutube
                    ? ["Global"]
                    : isLinear
                    ? (["Global", ...L.AUDIENCES] as const)
                    : (["Global", ...S.LAGS] as const)
                }
                onChange={val =>
                  onChange(i, isLinear ? "audience" : "lag", val === "Global" ? undefined : val)
                }
              />
            </div>
          </>
        )}
        <div className="formSection">
          <div className="label">Min width:</div>
          <OnBlurInput
            size="sm"
            type="number"
            value={minWidth || metadata.minWidth}
            onChange={minWidth => onChange(i, "minWidth", parseFloat(minWidth) || undefined)}
            placeholder={`${metadata.minWidth} (default)`}
          />
        </div>
        {columnMetaDataMap[column.type]?.percentageDisplayMode !==
          PercentageDisplayMode.DISABLE && (
          <div className="basicSection checkboxSection">
            <div className="label">Show %?:</div>
            <CheckBox checked={Boolean(pct)} size="sm" onCheck={newVal => onChange(i, "pct", newVal)} />
          </div>
        )}

        <div className="formSection">
          <div className="label">Decimals:</div>
          <OnBlurInput
            size="sm"
            type="number"
            min="0"
            max={`${MAX_DECIMALS}`}
            value={R.isNil(decimals) ? metadata.decimals || 0 : decimals}
            onChange={decimals => {
              let parsed = parseInt(decimals);
              onChange(
                i,
                "decimals",
                isNaN(parsed) || parsed < 0 ? undefined : resolveDecimals(parsed)
              );
            }}
            placeholder={`${metadata.decimals || 0} (default)`}
          />
        </div>
        <div className="basicSection checkboxSection">
          <div className="label">Divider?:</div>
          <CheckBox
            checked={Boolean(divider)}
            size="sm"
            onCheck={newVal => onChange(i, "divider", newVal)}
          />
        </div>
        <div className="basicSection checkboxSection">
          <div className="label">Admin Only?:</div>
          <CheckBox
            checked={Boolean(adminOnly)}
            size="sm"
            onCheck={newVal => onChange(i, "adminOnly", newVal)}
          />
        </div>
        <div className="basicSection checkboxSection">
          <div className="label">Heat Mapping?:</div>
          <CheckBox
            checked={Boolean(heatMapping)}
            size="sm"
            onCheck={newVal => onChange(i, "heatMapping", newVal ? {} : undefined)}
          />
        </div>
        {heatMapping && (
          <div className="heatmappingSection">
            <div className="headerSection">
              <div className="label">Heat Mapping</div>
              <OverlayTrigger
                overlay={
                  <Popover
                    id={`${id}_heatmap_glossary_popover`}
                    className="performanceHeatmapGlossaryPopover"
                  >
                    <Popover.Title>Glossary</Popover.Title>
                    <Popover.Content>
                      <div className="glossaryItem">
                        <span>Exclude Zeros:</span>
                        <span>
                          Ignores zeros when calculating. They aren't factored into means, medians,
                          or std. dev. calculations, nor are they considered minimum or maximum
                          values.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Pivot:</span>
                        <span>
                          Point at which colors change. Does not apply to "Green" color scheme.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Mid Point:</span>
                        <span>
                          Half way between the maximum and minimum values (respects Std. Dev.
                          Clipping).
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Color:</span>
                        <span>
                          Color scheme. "Standard" goes from red to yellow, then yellow to green
                          (yellow being at the pivot). "Green" goes from white to green.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Std. Dev. Clipping:</span>
                        <span>
                          Numbers that are greater or less than this number of standard deviations
                          from the mean (not pivot) will not be considered when heat mapping. This
                          is to prevent outliers from impacting the heat mapping.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Minimum/Maximum:</span>
                        <span>
                          Like "Std. Dev. Clipping", but hard-coded. Numbers below the minimum and
                          above the maximum are treated as if they are those numbers. If you put a
                          maximum of 100, a value of 500 is treated as 100 for heat mapping
                          calculations.
                        </span>
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="glossary">(Glossary)</div>
              </OverlayTrigger>
            </div>
            {!(metadata.contentReplacement?.threshold === 0) && (
              <div className="basicSection checkboxSection">
                <div className="label">Exclude Zeros</div>
                <CheckBox
                  checked={Boolean(heatMapping.excludeZeros)}
                  size="sm"
                  onCheck={newVal =>
                    onChange(i, "heatMapping", { ...heatMapping, excludeZeros: newVal })
                  }
                />
              </div>
            )}
            <div className="basicSection">
              <OldDropdown
                size="sm"
                label="Pivot"
                value={heatMapping.midpoint || "MIDPOINT"}
                options={["MEDIAN", "MIDPOINT", "MEAN", "CUSTOM"]}
                onChange={val => onChange(i, "heatMapping", { ...heatMapping, midpoint: val })}
              />
            </div>
            {heatMapping.midpoint === "CUSTOM" && (
              <div className="formSection">
                <div className="label">Custom Pivot:</div>
                <OnBlurInput
                  size="sm"
                  type="number"
                  value={heatMapping.customMidpoint || ""}
                  onChange={val => {
                    onChange(i, "heatMapping", {
                      ...R.omit(["customMidpoint"], heatMapping),
                      customMidpoint: parseFloat(val) || undefined,
                    });
                  }}
                  placeholder="Custom pivot"
                />
              </div>
            )}
            <div className="basicSection">
              <OldDropdown
                size="sm"
                label="Color"
                value={heatMapping.colorScheme || "standard"}
                options={[
                  { label: "Standard", value: "standard" },
                  { label: "Green", value: "green" },
                ]}
                onChange={val => onChange(i, "heatMapping", { ...heatMapping, colorScheme: val })}
              />
            </div>
            <div className="basicSection checkboxSection">
              <div className="label">Min Is Best?:</div>
              <CheckBox
                checked={
                  R.isNil((heatMapping as any).minIsBest)
                    ? columnMetaDataMap[column.type]?.minIsBest
                    : (heatMapping as any).minIsBest
                }
                size="sm"
                onCheck={newVal =>
                  onChange(i, "heatMapping", {
                    ...R.omit(["minIsBest"], heatMapping),
                    minIsBest: newVal,
                  } as any)
                }
              />
            </div>
            <div className="formSection">
              <div className="label">Std. Dev. Clipping:</div>
              <OnBlurInput
                size="sm"
                type="number"
                value={heatMapping.stdevClipping || ""}
                onChange={val => {
                  onChange(i, "heatMapping", {
                    ...R.omit(["stdevClipping"], heatMapping),
                    stdevClipping: parseFloat(val) || undefined,
                  });
                }}
                placeholder="None (default)"
              />
            </div>
            <div className="formSection">
              <div className="label">Minimum:</div>
              <OnBlurInput
                size="sm"
                type="number"
                value={heatMapping.min || ""}
                onChange={val => {
                  onChange(i, "heatMapping", {
                    ...R.omit(["min"], heatMapping),
                    min: parseFloat(val) || undefined,
                  });
                }}
                placeholder="None (default)"
              />
            </div>
            <div className="formSection">
              <div className="label">Maximum:</div>
              <OnBlurInput
                size="sm"
                type="number"
                value={heatMapping.max || ""}
                onChange={val => {
                  onChange(i, "heatMapping", {
                    ...R.omit(["max"], heatMapping),
                    max: parseFloat(val) || undefined,
                  });
                }}
                placeholder="None (default)"
              />
            </div>
          </div>
        )}
        {!isLast && (
          <Button
            size="sm"
            variant="outline-danger"
            className="deleteButton"
            onClick={() => deleteColumn(i)}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConfigColumn;
