import React, { useEffect, useMemo, useState } from "react";
import Papa from "papaparse";
import { RouteComponentProps } from "@reach/router";
import useLocation from "../../utils/hooks/useLocation";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import ModelOverview from "./ModelOverview";
import CPTB from "./ChannelPlatformTacticBrand";
import ReturnOnAdSpend from "./ReturnOnAdSpend";
import SatAndDecay from "./SaturationAndDecay";
import { findGroupByOptions } from "./DataFormatters";
import { FullPageSpinner } from "../../Components";
import { TEST_COMPANIES } from "@blisspointmedia/bpm-types/dist/TestCompanies";
import { useSetError } from "../../redux/modals";
import {
  ActualVsPredictedDataInitial,
  RoasData,
  ModelOverviewData,
  CptbData,
  SatData,
  DecayData,
} from "@blisspointmedia/bpm-types/dist/MMM";
import { TINUITI_TEST_HOME_SERVICES, TINUITI_TEST_HOME_SERVICES_PROXY } from "../MMMUtils";

interface ModelResultsProps {
  showActualizedData: boolean;
  disabledPlatform: boolean | undefined;
  groupBy: string;
  setGroupByOptions: (options: any) => void;
  kpi: string;
  date: string;
  branch: string;
}

const ModelResults = ({
  disabledPlatform,
  groupBy,
  setGroupByOptions,
  kpi,
  date,
  branch,
  showActualizedData,
}: ModelResultsProps & RouteComponentProps): JSX.Element => {
  let { company } = useLocation();
  if (TEST_COMPANIES.includes(company)) {
    company = "test";
  } else if (company === TINUITI_TEST_HOME_SERVICES) {
    company = TINUITI_TEST_HOME_SERVICES_PROXY;
  }
  const [cptbData, setCptbData] = useState<CptbData[]>([]);
  const [modelOverviewData, setModelOverviewData] = useState<ModelOverviewData[]>([]);
  const [overallRoasCpa, setOverallRoasCpa] = useState<number>();
  const [cptbDataDirect, setCptbDataDirect] = useState<CptbData[]>([]);
  const [roasData, setRoasData] = useState<RoasData[]>([]);
  const [roasDataDirect, setRoasDataDirect] = useState<RoasData[]>([]);
  const [actualVsPredictedData, setActualVsPredictedData] = useState<
    ActualVsPredictedDataInitial[]
  >([]);
  const [satData, setSatData] = useState<SatData[]>([]);
  const [satDataDirect, setSatDataDirect] = useState<SatData[]>([]);
  const [decayData, setDecayData] = useState<DecayData[]>([]);
  const [twoStageData, setTwoStageData] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const setError = useSetError();

  useEffect(() => {
    setLoading(true);
    const expandedCPTBsetterfunc = value => {
      const groupByOptions = findGroupByOptions(value);
      setGroupByOptions(groupByOptions);
      setCptbData(value);
    };

    const baseUrl = `tinuiti-mmm-results/mmm/${branch}/${company}/${kpi}/${date}/`;

    const files: string[] = [
      "a_model_info.csv.gz",
      "b_actual_vs_predicted.csv.gz",
      "c_cptb_over_time.csv.gz",
      "d_roas_cpa_nextbestdollar.csv.gz",
      "e_saturation_curves.csv.gz",
      "f_spend_decay_curves.csv.gz",
      "g_cptb_over_time_direct.csv.gz",
      "j_spend_effect_share.csv.gz",
      "h_roas_cpa_nextbestdollar_direct.csv.gz",
      "i_saturation_curves_direct.csv.gz",
    ];
    const setterFuncArray = [
      setModelOverviewData,
      setActualVsPredictedData,
      expandedCPTBsetterfunc,
      setRoasData,
      setSatData,
      setDecayData,
      setCptbDataDirect,
      setTwoStageData,
      setRoasDataDirect,
      setSatDataDirect,
    ];

    const s3Promises = files.map(file => S3SignedUrlFetch(`${baseUrl}${file}`));

    Promise.all(s3Promises)
      .then(resArray =>
        Promise.all(
          resArray.map(res => {
            if (res.status !== 200) {
              throw new Error("Could not resolve mmm files for this run, reach out to engineering");
            }
            return res.text();
          })
        )
      )
      .then(textResArray =>
        textResArray.forEach((file, i) => {
          const { data }: Papa.ParseResult<any> = Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          });
          setterFuncArray[i](data);
        })
      )
      .catch(err => {
        const reportError = err as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [company, kpi, date, branch, setGroupByOptions, setError]);

  const kpiType = useMemo(() => {
    const obj = modelOverviewData.find(obj => obj.parameter === "kpi_type");
    return obj ? obj.value : "";
  }, [modelOverviewData]);

  return (
    <>
      {!loading ? (
        <div>
          <ModelOverview
            company={company}
            cptbDataDirect={cptbDataDirect}
            cptbData={cptbData}
            disabledPlatform={disabledPlatform}
            groupBy={groupBy}
            modelOverviewData={modelOverviewData}
            kpi={kpi}
            kpiType={kpiType}
            overallRoasCpa={overallRoasCpa}
            actualVsPredictedData={actualVsPredictedData}
            showActualizedData={showActualizedData}
          />
          <CPTB
            kpi={kpi}
            cptbData={showActualizedData ? cptbData : cptbDataDirect}
            disabledPlatform={disabledPlatform}
            groupBy={groupBy}
            kpiType={kpiType}
            setOverallRoasCpa={setOverallRoasCpa}
            showActualizedData={showActualizedData}
            twoStageData={twoStageData}
          />
          <ReturnOnAdSpend
            disabledPlatform={disabledPlatform}
            kpiType={kpiType}
            data={showActualizedData ? roasData : roasDataDirect}
          />
          <SatAndDecay
            disabledPlatform={disabledPlatform}
            kpiType={kpiType}
            satData={showActualizedData ? satData : satDataDirect}
            decayData={decayData}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </>
  );
};

export default ModelResults;
