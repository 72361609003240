import { useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { useSetError } from "../redux/modals";
import { Page, Button, ButtonType, ToggleSwitch, FullPageSpinner } from "../Components";
import { CroOnboardLambdaFetch } from "../utils/fetch-utils";
import "./CroOnboard.scss";

const CroOnboard = (): JSX.Element => {
  const setError = useSetError();
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState<string | null>(null);
  const [prodEnabled, setProdEnabled] = useState<boolean>(false);
  const [subdomainSubmitted, setSubdomainSubmitted] = useState("");

  const ischeckBucketNameUrlRegex = new RegExp("^[\\w-]+\\.[\\w-]+\\.[\\w-]{2,4}$");

  const checkBucketName = (getUrl: string) => {
    // expected format "get.partnersite.com"
    const isUrlFormatValid = ischeckBucketNameUrlRegex.test(getUrl);
    if (getUrl) {
      return isUrlFormatValid;
    } else {
      return false;
    }
  };

  const clearAllFields = () => {
    setInputText(null);
    setProdEnabled(false);
    setLoading(false);
  };

  const OnboardingModal = () => {
    return (
      <Modal
        size="lg"
        keyboard={false}
        show={subdomainSubmitted.length > 0}
        onHide={() => setSubdomainSubmitted("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Onboarding In Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          CRO onboarding for <b>{subdomainSubmitted}</b> is in progress; please check channel{" "}
          <a
            href="https://tinuiti.enterprise.slack.com/archives/C05SXUN30FN"
            target="_blank"
            rel="noopener noreferrer"
          >
            #amp-cyb-testing
          </a>{" "}
          for status.
        </Modal.Body>
        <Modal.Footer>
          <Button type={ButtonType.FILLED} onClick={() => setSubdomainSubmitted("")}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleCroOnboardSubmit = () => {
    try {
      if (inputText) {
        const validation = checkBucketName(inputText);
        if (validation) {
          setLoading(true);
          let queryStringParams: {
            s3_bucket_name: string;
            prod_env: string;
          } = { s3_bucket_name: inputText, prod_env: `${prodEnabled}` };
          CroOnboardLambdaFetch("/add", {
            params: queryStringParams,
            retries: 0,
          });
          setSubdomainSubmitted(inputText);
        } else {
          setError({
            message: "Incorrect bucket name",
          });
        }
      }
    } catch (e) {
      const error = e as Error;
      setError({
        message: `Failed to onboard partner: ${error.message}`,
        reportError: error,
      });
    }
    clearAllFields();
  };

  const onClearClick = () => {
    clearAllFields();
  };

  return (
    <Page title={"CRO Onboard"} pageType="Admin Functions">
      <div className="cro-onboard-container">
        <div className="cro-onboard-text-wrapper">
          <p>
            This tool will onboard client subdomain into AMP AWS account by creating and configuring
            AWS S3 buckets and CloudFront Distributions. The tool will also request SSL Certificate
            from our client.
          </p>
          <p>
            To complete the setup, both Client actions and AMP Engineering manual actions are
            needed.
          </p>
          <p>
            Example: please enter the subdomain name in this format -{" "}
            <span className="blue-text">get.insiderenvy.com</span>
          </p>
        </div>

        <Form className="">
          <Form.Row className="">
            <Form.Group as={Col}>
              <Form.Label>Production Subdomain Name*</Form.Label>
              <Form.Control
                value={inputText || ""}
                placeholder="eg. get.test-partner.com"
                onChange={e => setInputText(e.target.value)}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <div className="all-btn-wrapper">
          <div className="toggle-wrapper">
            <ToggleSwitch
              className="toggle"
              label="Production Only"
              checked={prodEnabled}
              disabled={loading}
              onChange={() => setProdEnabled(!prodEnabled)}
            />
          </div>
          {loading ? (
            <FullPageSpinner />
          ) : (
            <div className="button-wrapper">
              <Button type={ButtonType.FILLED} onClick={onClearClick}>
                Clear
              </Button>
              <Button
                type={ButtonType.FILLED}
                disabled={!inputText}
                onClick={() => handleCroOnboardSubmit()}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
        <OnboardingModal />
      </div>
    </Page>
  );
};

export default CroOnboard;
