// Radio Buying Experiment

// If opted into this experiment, you'll see the "Market" column on the Linear Rates Page.
// and the "Channel" column in the Linear Networks Page
const marketChannelColumnsUsers: string[] = [
  "berto.garciacarrillo@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "michelle.ricciuti@tinuiti.com",
  "alicia.jewell@tinuiti.com",
  "justin.manus@tinuiti.com",
  "matthew.bilyak@tinuiti.com",
  "sarah.butts@tinuiti.com",
  "cassidy.childs@tinuiti.com",
  "grace.langmuir@tinuiti.com",
  // Add users here!
];

export const shouldEnableMarketChannelColumns = (user: string): boolean => {
  return marketChannelColumnsUsers.includes(user);
};
