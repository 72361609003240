import React, { useCallback, useContext, useMemo, useState } from "react";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";

import { navigate } from "@reach/router";
import { encodePrettyUrl, PerformanceContext } from "../performanceUtils";
import {
  LinearPerformanceLambdaFetch,
  StreamingPerformanceLambdaFetch,
  YoutubePerformanceLambdaFetch,
} from "../../utils/fetch-utils";
import { useSetError } from "../../redux/modals";

import useLocation from "../../utils/hooks/useLocation";
import { GridPickerModal, GridPickerOption } from "../../Components";

interface PresetShopProps {
  onClose: () => void;
  currentID: number;
  currentName: string;
  presetNames: string[];
  groupOptions: string[];
  baseUrl: string;
  isLinear?: boolean;
  isYoutube?: boolean;
}

const PresetShop: React.FC<PresetShopProps> = ({
  onClose,
  currentID,
  currentName,
  presetNames,
  groupOptions,
  baseUrl,
  isLinear,
  isYoutube,
}) => {
  const setError = useSetError();
  const { company } = useLocation();
  const { prefix, isGraph } = useContext(PerformanceContext);

  const [name, setName] = useState("");
  const [groups, setGroups] = useState<string[]>([]);
  const [option, setOptionRaw] = useState<S.TemplateKey | L.TemplateKey | "">("");

  const DEFAULT_PRESETS = isLinear ? L.DEFAULT_PRESETS : S.DEFAULT_PERFORMANCE_PRESETS;

  const setOption = useCallback(
    (optionKey: S.TemplateKey | L.TemplateKey) => {
      if (optionKey === `${currentID}`) {
        setName(`${currentName} (Copy)`);
      } else if (optionKey === "minimal") {
        setName("");
      } else {
        setName(DEFAULT_PRESETS[optionKey]?.dimension || "");
      }
      setOptionRaw(optionKey);
    },
    [currentID, currentName, DEFAULT_PRESETS]
  );

  const options = useMemo(() => {
    let options: GridPickerOption<S.TemplateKey | L.TemplateKey>[] = [
      {
        key: "minimal",
        thumbnail: "Minimal",
        caption: isYoutube
          ? "Start from scratch with just a network logo and an impression count."
          : "Start from scratch with just ads and an impression count.",
      },
    ];

    let currentThumbnail = "Copy Current";
    for (let id of R.keys(DEFAULT_PRESETS)) {
      let preset = DEFAULT_PRESETS[id];
      if (preset.prefix === prefix) {
        if (id === `${currentID}`) {
          currentThumbnail = `Copy Current (${preset.dimension})`;
        } else {
          options.push({
            key: id,
            thumbnail: preset.dimension,
          });
        }
      }
    }

    options.unshift({
      // TypeScript sees `${currentID}` as a string not a `${number}`. I think an upcoming version
      // of TypeScript improves these template string types to address this.
      key: `${currentID}` as `${number}`,
      thumbnail: currentThumbnail,
      caption: "Copy the preset you currently have open.",
    });
    return options;
  }, [isYoutube, currentID, DEFAULT_PRESETS, prefix]);

  const nameTaken = useMemo(() => {
    if (isLinear) {
      return Boolean(L.DIMENSIONS.includes(name as L.Dimension) ||
        presetNames.find(thisName => thisName.toLowerCase() === name.toLowerCase()));
    }
    return Boolean(S.PERFORMANCE_DIMENSIONS.includes(name as S.PerformanceDimension) ||
      presetNames.find(thisName => thisName.toLowerCase() === name.toLowerCase()));
  }, [presetNames, name, isLinear]);

  const [saving, setSaving] = useState(false);

  const onSubmit = useCallback(async () => {
    if (option) {
      try {
        setSaving(true);
        if (isYoutube) {
          await YoutubePerformanceLambdaFetch<Y.MakePresetFromTemplateParams>("/new_preset", {
            method: "POST",
            body: {
              key: option,
              company,
              name,
              groups: groups,
            },
          });
        } else if (isLinear) {
          await LinearPerformanceLambdaFetch<L.MakePresetFromTemplateParams>(
            "/makePresetFromTemplate",
            {
              method: "POST",
              body: {
                key: option,
                company,
                name,
                groups: groups,
              },
            }
          );
        } else {
          await StreamingPerformanceLambdaFetch<S.MakePresetFromTemplateParams>("/new_preset", {
            method: "POST",
            body: {
              key: option,
              company,
              name,
              prefix: prefix as S.Prefix,
              isGraph,
              groups: groups,
            },
          });
        }

        navigate && navigate(`${window.location?.origin}${baseUrl}/${encodePrettyUrl(name)}`);
        window.location.reload();
      } catch (e) {
        let error = e as Error;
        setError({ reportError: error, message: error.message });
        setSaving(false);
      }
    }
  }, [option, isYoutube, isLinear, baseUrl, name, company, groups, prefix, isGraph, setError]);

  return (
    <GridPickerModal
      name={name}
      setName={setName}
      groups={groups}
      setGroups={setGroups}
      options={options}
      selectedOption={option}
      selectOption={setOption}
      onClose={onClose}
      onSubmit={onSubmit}
      saving={saving}
      invalid={nameTaken}
      title="New View"
      submitButtonText="Create View"
      nameFieldLabel={nameTaken ? "Name (this one is already taken)" : "Name"}
      bodyText="Create a new view starting with one of these presets."
      groupOptions={groupOptions}
      hasGroups
    />
  );
};

export default PresetShop;
