import * as R from "ramda";
import { YouTubePlanData } from "./YouTubePlanning";
import ToplineNumber from "./ToplineNumber";
import { abbreviateNumber, formatMoney, formatNumber } from "../utils/format-utils";
import {
  BPMTable,
  Button,
  ButtonFrameworkVariant,
  ButtonType,
  FullPageSpinner,
  Header,
  Input,
} from "../Components";
import { MdClose, MdEdit, MdOutlineFileDownload, MdRefresh } from "react-icons/md";
import { useCallback, useMemo, useState } from "react";
import "./Plan.scss";
import SpendVsChart from "./SpendVsChart";
import { formatDateLabel } from "../CrossChannel/crossChannelFormatters";
import { useMap } from "../utils/hooks/useData";

interface PlanProps {
  planData?: YouTubePlanData;
  onClose: () => any;
  onRegenerate: (editedBudget, editedBudgetShare) => any;
}

export const Plan: React.FC<PlanProps> = ({ planData, onClose, onRegenerate }) => {
  const [regenerating, setRegenerating] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editedBudget, setEditedBudget] = useState<number>(planData?.suggestedBudget || 0);
  const [editedBudgetShare, setEditedBudgetShareValue, setEditedBudgetShare] = useMap<
    string,
    number
  >(undefined);

  // Assumes only two rows max (2 types of inventory) and just adjusts both lines to add to 100%.
  // Ex: If we start with 65/35 and the second budget share is edited to 40, it will adjust to 60/40.
  const balanceBudgetShares = useCallback(
    (rowNumber, newBudgetShare) => {
      setEditedBudgetShareValue(rowNumber, newBudgetShare);
      const otherRow = 3 - parseInt(rowNumber); // 2 -> 1, 1 -> 2
      setEditedBudgetShareValue(otherRow.toString(), 100 - newBudgetShare);
    },
    [setEditedBudgetShareValue]
  );

  const regeneratePlan = useCallback(async () => {
    setEditMode(false);
    setRegenerating(true);
    await onRegenerate(editedBudget, editedBudgetShare);
    setRegenerating(false);
  }, [editedBudget, editedBudgetShare, onRegenerate]);

  const downloadPlan = useCallback(() => {
    // TODO: functionality to export a plan
  }, []);

  const onCancel = useCallback(() => {
    setEditMode(false);
    setEditedBudget(planData?.suggestedBudget || 0);
    setEditedBudgetShare({});
  }, [planData?.suggestedBudget, setEditedBudgetShare]);

  const planTableHeaders: Header[] = useMemo(
    () => [
      {
        label: "Format",
        name: "format",
        width: 120,
        renderer: value => {
          return <div className="formatCell">{value.format}</div>;
        },
      },
      {
        label: "Targeting Type",
        name: "targetingType",
        width: 200,
        renderer: value => {
          return (
            <div className="targetingTypeCell">
              {value.targetingType.map(targetingType => (
                <div className="targetingType" key={targetingType}>
                  {targetingType}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        label: "Segments",
        name: "segments",
        width: 320,
        renderer: value => {
          return (
            <div className="segmentsCell">
              <div className="segmentsCellSection">
                {value.segments.inMarkets?.map(segment => (
                  <div className="segment" key={segment}>
                    {segment}
                  </div>
                ))}
              </div>
              <div className="segmentsCellSection">
                {value.segments.affinities?.map(segment => (
                  <div className="segment" key={segment}>
                    {segment}
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
      {
        label: "Budget",
        name: "budget",
        flex: 1,
        renderer:
          editMode && planData && planData.tableData.length > 1 // Don't need to change based on edited budgetShare if there is only one row (100%)
            ? value => {
                const editedBudgetVal = editedBudgetShare
                  ? ((editedBudgetShare[value.id] || 0) * editedBudget) / 100
                  : undefined;
                return formatMoney(editedBudgetVal || value.budget);
              }
            : value => formatMoney(parseFloat(value.budget.replace(/\$/g, "").replace(/,/g, ""))),
      },
      {
        label: "Budget Share",
        name: "budgetShare",
        flex: 1,
        renderer:
          editMode && planData && planData.tableData.length > 1 // Don't need to allow editing of budgetShare if there is only one row (100%)
            ? value => {
                return (
                  <Input
                    type="number"
                    className="budgetShareInput"
                    value={
                      R.isNil(editedBudgetShare[value.id])
                        ? value.budgetShare.replace("%", "")
                        : editedBudgetShare[value.id]
                    }
                    onChange={e => balanceBudgetShares(value.id, e.target.valueAsNumber)}
                    onWheel={e => e.target.blur()}
                  />
                );
              }
            : value => value.budgetShare,
      },
      {
        label: "Reach",
        name: "reach",
        flex: 1,
      },
      {
        label: "Avg. Freq.",
        name: "avgFreq",
        flex: 1,
      },
      {
        label: "Cost Per Reach",
        name: "costPerReach",
        flex: 1,
      },
      {
        label: "Imps",
        name: "imps",
        flex: 1,
      },
      {
        label: "CPM",
        name: "cpm",
        flex: 1,
      },
      {
        label: "Views",
        name: "views",
        flex: 1,
      },
      {
        label: "CPV",
        name: "cpv",
        flex: 1,
      },
    ],
    [editMode, planData, editedBudget, editedBudgetShare, balanceBudgetShares]
  );

  return (
    <div className="youTubePlan">
      {regenerating ? (
        <FullPageSpinner />
      ) : (
        <div>
          <div className="youTubePlanHeader">
            <div className="youTubePlanHeaderTextContainer">
              <div className="youTubePlanHeaderText">Recommendations and Estimates</div>
              <div className="youTubePlanHeaderSubtext">
                {`${planData?.goal}, ${formatMoney(
                  planData?.inputBudget || 0,
                  0
                )} Budget, Query: "${planData?.queryString}", ${formatDateLabel(
                  planData?.dates.start || ""
                )} - ${formatDateLabel(planData?.dates.end || "")}`}
              </div>
            </div>
            <div className="youTubePlanHeaderControls">
              {editMode && (
                <Button
                  className="youTubePlanEditButton"
                  type={ButtonType.EMPTY}
                  onClick={onCancel}
                  variant={ButtonFrameworkVariant.NO_ICON}
                >
                  Cancel
                </Button>
              )}
              {editMode && (
                <Button
                  className="youTubePlanEditButton"
                  type={ButtonType.OUTLINED}
                  onClick={regeneratePlan}
                  icon={<MdRefresh />}
                  variant={ButtonFrameworkVariant.TRAILING_ICON}
                >
                  Regenerate
                </Button>
              )}
              {!editMode && (
                <Button
                  className="youTubePlanEditButton"
                  type={ButtonType.OUTLINED}
                  onClick={() => setEditMode(true)}
                  icon={<MdEdit />}
                  variant={ButtonFrameworkVariant.TRAILING_ICON}
                >
                  Edit Inputs
                </Button>
              )}
              <Button
                className="youTubePlanDownloadButton"
                type={ButtonType.FILLED}
                onClick={downloadPlan}
                icon={<MdOutlineFileDownload />}
              />
              <Button
                className="youTubePlanCloseButton"
                type={ButtonType.EMPTY}
                onClick={onClose}
                icon={<MdClose />}
              />
            </div>
          </div>
          <div className="youTubePlanToplineNumbersContainer">
            <ToplineNumber
              label="Budget"
              value={planData?.suggestedBudget}
              formatter={formatMoney}
              editable={editMode}
              onEdit={setEditedBudget}
            />
            <ToplineNumber
              label="On Target Reach"
              value={planData?.onTargetReach}
              formatter={formatNumber}
            />
            <ToplineNumber
              label="Avg. Frequency"
              value={planData?.averageFreq}
              formatter={formatNumber}
            />
            <ToplineNumber label="CPM" value={planData?.cpm} formatter={formatMoney} />
            <ToplineNumber
              label="YouTube Population"
              value={planData?.youTubePopulation}
              formatter={abbreviateNumber}
            />
          </div>
          <div className="youTubePlanSpendChartsContainer">
            <SpendVsChart
              variableName="Reach"
              data={planData?.spendVsReach || []}
              suggestedBudget={planData?.suggestedBudget}
            />
            <SpendVsChart
              variableName="Views"
              data={planData?.spendVsViews || []}
              suggestedBudget={planData?.suggestedBudget}
            />
          </div>
          <div className="youTubePlanTableContainer">
            <BPMTable
              className="youTubePlanTable"
              alternateColors={false}
              data={planData?.tableData}
              headers={planTableHeaders}
              filterBar={false}
              noRowsRenderer={() => <div>No rows to show.</div>}
              rowHeight={260}
              headerHeight={50}
            ></BPMTable>
          </div>
        </div>
      )}
    </div>
  );
};

export default Plan;
