import "./MMM.scss";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import { Dropdown, Page, Button, CheckBox, ButtonType, TextToggleButton } from "../Components";
import { MetricsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import * as R from "ramda";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import useLocation from "../utils/hooks/useLocation";

import ModelResults from "./ModelResults/ModelResults";
import OfflineInputs from "./OfflineInputs/OfflineInputs";
import FAQ from "./FAQ/FAQ";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { useSelector } from "react-redux";
import { isInternalSelector } from "../redux/user";
import { TINUITI_TEST_HOME_SERVICES, TINUITI_TEST_HOME_SERVICES_PROXY } from "./MMMUtils";

const enum TabKey {
  MODEL_RESULTS = "model-results",
  BUDGET_OPTIMIZATION = "budget-optimization",
  SCENARIO_PLANNING = "scenario-planning",
  FAQ = "FAQ",
  OFFLINE_INPUTS = "offline-inputs",
}

const NAVS = [
  { label: "Model Results", key: TabKey.MODEL_RESULTS },
  { label: "FAQ", key: TabKey.FAQ },
];

interface DateOption {
  date: string;
  client_facing: boolean;
  kpi: string;
  branch: string;
}

interface FormattedModelRunOptions {
  date: string;
  branch: string;
  client_facing: boolean;
  extraLabel: number;
  newest: boolean;
  prettyDate: string;
  kpis: { value: string; label: string; client_facing: boolean }[];
}

const MMM: React.FC = ({ navigate }: RouteComponentProps) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  let { company } = useLocation();
  if (company === TINUITI_TEST_HOME_SERVICES) {
    company = TINUITI_TEST_HOME_SERVICES_PROXY;
  }
  const [kpi, setKpi] = useState<string>("");
  const [groupBy, setGroupBy] = useState("channel");
  const [groupByOptions, setGroupByOptions] = useState<Array<{ label: string; value: string }>>([]);
  const disabledPlatform = useMemo(() => {
    if (!R.isEmpty(groupByOptions)) {
      return groupByOptions.filter(option => option.value === "platform").length === 0;
    }
  }, [groupByOptions]);
  const [pageActions, setPageActions] = useState<JSX.Element | undefined>(<></>);
  const [pageArchiveActions, setPageArchiveActions] = useState<JSX.Element | undefined>(<></>);
  const [currentModelRun, setCurrentModelRun] = useState<FormattedModelRunOptions>();
  const isInternal = useSelector(isInternalSelector);
  const [modelRunOptions, setModelRunOptions] = useState<FormattedModelRunOptions[]>([]);
  const [showActualizedData, setShowActualizedData] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [runVisible, setRunVisible] = useState(false);
  const selectedDate = useMemo(() => (currentModelRun ? currentModelRun.date : ""), [
    currentModelRun,
  ]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "mmm",
    defaultKey: TabKey.MODEL_RESULTS,
  });

  const toggleMMMRun = useCallback(
    async (modelRun: FormattedModelRunOptions, currKpi: string, checked: boolean) => {
      try {
        setModelRunOptions(m =>
          m.map(row => {
            if (row.date === modelRun.date) {
              const newRow = { ...row };
              const otherKpisOn = row.kpis.some(kpi => kpi.value !== currKpi && kpi.client_facing);
              newRow.client_facing = checked || otherKpisOn;
              newRow.kpis = row.kpis.map(kpi =>
                kpi.value === currKpi ? { ...kpi, client_facing: checked } : { ...kpi }
              );
              setCurrentModelRun({ ...newRow });
              return { ...newRow };
            }
            return { ...row };
          })
        );
        setRunVisible(checked);
        await MetricsLambdaFetch("/toggleMMMRun", {
          method: "POST",
          body: {
            company,
            date: modelRun.date,
            client_facing: checked,
            branch: modelRun.branch,
            kpi: currKpi,
          },
        });
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      }
    },
    [company, setError]
  );

  useEffect(() => {
    const getDateOptions = async () => {
      const filterDateRuns = (options: DateOption[]) => {
        const filterByRelease = isInternal ? options : options.filter(obj => obj.client_facing);
        const formattedRuns = filterByRelease
          .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
          .reduce((acc: FormattedModelRunOptions[], cv, i) => {
            if (i > 0 && acc[acc.length - 1].date === cv.date) {
              const lastInd = acc.length - 1;
              acc[lastInd].client_facing = acc[lastInd].client_facing || cv.client_facing;

              if (!acc[lastInd].kpis.find(kpi => kpi.value === cv.kpi)) {
                acc[lastInd].kpis.push({
                  value: cv.kpi,
                  label: cv.kpi.replace(/_/g, " "),
                  client_facing: cv.client_facing,
                });
              }
            } else {
              const currPrettyDate = `${new Date(cv.date as string).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}`;
              let currExtraLabel = 0;
              if (i > 0 && acc[acc.length - 1].prettyDate === currPrettyDate) {
                if (!acc[acc.length - 1].extraLabel) {
                  acc[acc.length - 1].extraLabel = 1;
                }
                currExtraLabel = acc[acc.length - 1].extraLabel + 1;
              }
              acc.push({
                branch: cv.branch,
                date: cv.date,
                prettyDate: currPrettyDate,
                newest: i === 0,
                client_facing: cv.client_facing,
                extraLabel: currExtraLabel,
                kpis: [
                  {
                    value: cv.kpi,
                    label: cv.kpi.replace(/_/g, " "),
                    client_facing: cv.client_facing,
                  },
                ],
              });
            }
            return acc;
          }, []);
        return formattedRuns;
      };
      try {
        setLoading(true);
        const res = await MetricsLambdaFetch("/dateOptions", {
          params: {
            company,
          },
        });
        const dateOptions = await awaitJSON<DateOption[]>(res);
        if (dateOptions.length < 1) {
          if (![TabKey.OFFLINE_INPUTS, TabKey.FAQ].includes(tab)) {
            setError({
              message: "There are no MMM runs for this client",
            });
          }
          return;
        }
        const dateOptionsFormated = filterDateRuns(dateOptions);
        if (R.isEmpty(dateOptionsFormated)) {
          setError({
            message: "There are no released MMM runs for this client",
          });
          return;
        }
        const initialRun =
          dateOptionsFormated.find(run => run.client_facing === true) ||
          dateOptionsFormated[dateOptionsFormated.length - 1];
        setModelRunOptions(dateOptionsFormated);
        setCurrentModelRun(initialRun);
        const initialKpi = initialRun.kpis.find(kpi => kpi.client_facing) || initialRun.kpis[0];
        setKpi(initialKpi.value);
        setRunVisible(initialKpi.client_facing);
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      } finally {
        setLoading(false);
      }
    };
    getDateOptions();
  }, [company, setError, isInternal, tab]);

  useEffect(() => {
    if (isInternal) {
      NAVS.push(
        { label: "Budget Optimization", key: TabKey.BUDGET_OPTIMIZATION },
        { label: "Scenario Planning", key: TabKey.SCENARIO_PLANNING },
        { label: "Offline Inputs", key: TabKey.OFFLINE_INPUTS }
      );
    }
  }, [isInternal]);

  useEffect(() => {
    switch (tab) {
      case TabKey.MODEL_RESULTS:
        setPageActions(
          <div id="mmmHeaderSubActions">
            <div id="mmmArchiveKpis">
              {currentModelRun && (
                <Dropdown
                  design="primary"
                  className={`mmmKpiDropdown ${isInternal ? "internal" : ""}`}
                  section={isInternal ? ["Client-Facing", "Admin-Only"] : ["Client Facing"]}
                  dropdownMenuClassName="align-right"
                  onChange={val => {
                    const kpiObj = currentModelRun.kpis.find(k => k.value === val);
                    setRunVisible(kpiObj ? kpiObj.client_facing : false);
                    setKpi(val);
                  }}
                  options={currentModelRun.kpis.map(kpi => ({
                    ...kpi,
                    section: kpi.client_facing ? "Client-Facing" : "Admin-Only",
                  }))}
                  value={kpi || ""}
                />
              )}
              {isInternal && currentModelRun && (
                <Button
                  className="archiveToggleButton"
                  background="light"
                  type={ButtonType.OUTLINED}
                  variant={ButtonFrameworkVariant.TRAILING_ICON}
                  onClick={() => {
                    setAreYouSure({
                      title: runVisible ? "Hide run from clients?" : "Show run to clients?",
                      message: `You're about to make this run ${
                        runVisible ? "hidden from" : "visible to"
                      } clients. Are you sure you want to continue?`,
                      cancelText: "Cancel",
                      okayText: "Continue",
                      onOkay: () => {
                        toggleMMMRun(currentModelRun, kpi, !runVisible);
                      },
                    });
                  }}
                  icon={<CheckBox checked={runVisible} id="secondaryDesign" />}
                >
                  Client-Facing
                </Button>
              )}
            </div>
            <Dropdown
              design="primary"
              className="mmmGroupByDropdown"
              onChange={setGroupBy}
              options={groupByOptions}
              value={groupBy}
            />
          </div>
        );
        setPageArchiveActions(
          <div className="primaryActionsContainerMMM">
            <TextToggleButton
              options={["Actualized", "Raw"]}
              selectedOption={showActualizedData ? "Actualized" : "Raw"}
              onChange={() => setShowActualizedData(e => !e)}
              className="mmmEffectiveRawToggle"
              design="primary-dark"
            />
            <div id="mmmArchiveActions" className={`${isInternal ? "archiveOptionsInternal" : ""}`}>
              <Dropdown
                section={isInternal ? ["Client-Facing", "Admin-Only"] : ["Client Facing"]}
                background="dark"
                className={"mmmArchiveDropdown"}
                onChange={value => {
                  const newRun = modelRunOptions.find(run => run.date === value);
                  if (newRun) {
                    const newKpi =
                      newRun.kpis.find(k => k.value === kpi) ||
                      newRun.kpis.find(k => k.client_facing) ||
                      newRun.kpis[0];
                    setCurrentModelRun(newRun);
                    setKpi(newKpi.value);
                    setRunVisible(newKpi.client_facing);
                  }
                }}
                options={modelRunOptions.map(run => ({
                  label: `${run.prettyDate} ${
                    run.extraLabel ? `(${String.fromCharCode(96 + run.extraLabel)})` : ""
                  }`,
                  value: run.date,
                  section: run.client_facing ? "Client-Facing" : "Admin-Only",
                  className: "archiveOptions",
                  simpleTooltip: isInternal && run.extraLabel ? run.date : undefined,
                  customContent: (
                    <div className={"customDropdownMenuContent"}>
                      <div className="statusPillContainer">
                        <div className={`dropdownPill status${run.newest ? "Newest" : "Archived"}`}>
                          {run.newest ? "Newest" : "Archived"}
                        </div>
                      </div>
                      {isInternal && (
                        <div className="statusPillContainer branch">
                          <div
                            className={`dropdownPill status${
                              run.branch === "main" ? "Main" : "Other"
                            }`}
                          >
                            {run.branch}
                          </div>
                        </div>
                      )}
                    </div>
                  ),
                }))}
                value={selectedDate}
              />
            </div>
          </div>
        );
        break;
      default:
        setPageActions(undefined);
        setPageArchiveActions(undefined);
    }
  }, [
    groupBy,
    groupByOptions,
    kpi,
    tab,
    isInternal,
    currentModelRun,
    modelRunOptions,
    toggleMMMRun,
    setAreYouSure,
    selectedDate,
    runVisible,
    showActualizedData,
  ]);

  return (
    <Page
      actions={pageArchiveActions}
      app2Redesign
      navs={NAVS}
      onNav={goToTab}
      pageType="MMM"
      selectedNav={tab}
      title="MMM"
      subHeader={pageActions}
    >
      <Router className="fullPageRouter mmmPage">
        {!loading && currentModelRun && kpi && (
          <ModelResults
            showActualizedData={showActualizedData}
            disabledPlatform={disabledPlatform}
            groupBy={groupBy}
            setGroupByOptions={setGroupByOptions}
            kpi={kpi}
            path={"/"}
            date={selectedDate}
            branch={currentModelRun.branch}
          />
        )}
        <FAQ path={TabKey.FAQ} />
        <OfflineInputs path={TabKey.OFFLINE_INPUTS} />
        <Redirect noThrow from="/offlineinputs" to={`../${TabKey.OFFLINE_INPUTS}`} />
        <Redirect noThrow from="/offline_inputs" to={`../${TabKey.OFFLINE_INPUTS}`} />
      </Router>
    </Page>
  );
};

export default MMM;
