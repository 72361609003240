import { useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import { Line, XAxis, YAxis, Tooltip, CartesianGrid, ComposedChart, Bar } from "recharts";
import * as XLSX from "xlsx";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import {
  Skeleton,
  PathSkeleton,
  BPMTable,
  ToggleSwitch,
  Header,
  InfoTooltip,
  Dropdown,
  DropdownToggleType,
  GraphTableToggleButton,
  DownloadDropdown,
} from "../Components";
import { formatMoney, formatNumber, formatNumberAsInt } from "../utils/format-utils";
import { abbreviateNumber } from "../utils/data";
import ChartContainer from "../Components/ChartContainer";
import BreakdownLegend from "./BreakdownLegend";
import LinkSelector from "./LinkSelector";
import {
  formatDateLabel,
  getGroupingValue,
  RELATIVE_FORMATTER,
  SPEND_FORMATTER,
  METRIC_TO_FORMATTER,
} from "./homePageUtils";
import {
  STREAMING_STROKE_COLOR,
  AUDIO_STROKE_COLOR,
  DISPLAY_STROKE_COLOR,
  LINEAR_STROKE_COLOR,
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  CHART_MARGIN_WITH_LEGEND,
  LINE_STROKE_WIDTH,
  TICK_STYLE,
  X_AXIS_PADDING,
  DateGroupingKey,
  DATE_GROUPING_OPTIONS,
  MEDIA_TYPE_TO_PRETTY_NAME,
  DateGroupingOption,
} from "./homePageConstants";
import ChartTooltip from "./ChartTooltip";
import "./PerformanceBreakdown.scss";

interface PerformanceData {
  volume: number;
  spend: number;
  cpx: number | null;
  roas: number | null;
  revenue: number | null;
}

interface PerformanceBreakdownData {
  date: any;
  [mediaType: string]: PerformanceData;
}

interface RelativePerformanceBreakdownData {
  date: any;
  [channel: string]: number;
}

interface PerformanceBreakdownProps {
  data: PerformanceBreakdownData[];
  company: string;
  toggledMediaTypes: Record<string, boolean>;
  dateGroupingOptions: DateGroupingOption[];
}

const CHART_VIEW_OPTIONS = [{ value: "Absolute" }, { value: "Relative" }];
const METRIC_OPTIONS_MAP = {
  volume: { prettyName: "Volume", tooltipFormatter: METRIC_TO_FORMATTER.volume },
  roas: { prettyName: "ROAS", tooltipFormatter: METRIC_TO_FORMATTER.roas },
  cpx: { prettyName: "CPX", tooltipFormatter: METRIC_TO_FORMATTER.cpx },
} as const;
const METRIC_DROPDOWN_OPTIONS = Object.entries(METRIC_OPTIONS_MAP).map(([key, value]) => ({
  label: value.prettyName,
  value: key,
}));

const groupDataByDate = (data: any, groupingKey: DateGroupingKey): any => {
  if (!data) {
    return { groupedData: [], averageCpxAndRoasByDate: {} };
  }

  const channels = Object.keys(data[0]).filter(key => key !== "date");

  let grouped: Record<string, Record<string, PerformanceData>> = {};

  let spendByChannelDateGrouping = {};
  let volumeByChannelDateGrouping = {};
  let revenueByChannelDateGrouping = {};
  let totalsByDateGrouping: Record<string, { spend: number; volume: number; revenue: number }> = {};

  for (const item of data) {
    const dateToUse = getGroupingValue(item.date, groupingKey);

    let prev = { ...(grouped[dateToUse] || {}) };

    let incremented: Record<string, any> = {};

    for (let channel of channels) {
      totalsByDateGrouping = R.mergeDeepRight(totalsByDateGrouping, {
        [dateToUse]: {
          spend:
            R.pathOr(0, [dateToUse, "spend"], totalsByDateGrouping) +
            R.pathOr(0, [channel, "spend"], item),
          volume:
            R.pathOr(0, [dateToUse, "volume"], totalsByDateGrouping) +
            R.pathOr(0, [channel, "volume"], item),
          revenue:
            R.pathOr(0, [dateToUse, "revenue"], totalsByDateGrouping) +
            R.pathOr(0, [channel, "revenue"], item),
        },
      });

      spendByChannelDateGrouping = R.mergeDeepRight(spendByChannelDateGrouping, {
        [dateToUse]: {
          [channel]:
            R.pathOr(0, [dateToUse, channel], spendByChannelDateGrouping) +
            R.pathOr(0, [channel, "spend"], item),
        },
      });

      volumeByChannelDateGrouping = R.mergeDeepRight(volumeByChannelDateGrouping, {
        [dateToUse]: {
          [channel]:
            R.pathOr(0, [dateToUse, channel], volumeByChannelDateGrouping) +
            R.pathOr(0, [channel, "volume"], item),
        },
      });

      revenueByChannelDateGrouping = R.mergeDeepRight(revenueByChannelDateGrouping, {
        [dateToUse]: {
          [channel]:
            R.pathOr(0, [dateToUse, channel], revenueByChannelDateGrouping) +
            R.pathOr(0, [channel, "revenue"], item),
        },
      });

      const cpx =
        R.pathOr(0, [dateToUse, channel], spendByChannelDateGrouping) /
        R.pathOr(0, [dateToUse, channel], volumeByChannelDateGrouping);
      const roas =
        R.pathOr(0, [dateToUse, channel], revenueByChannelDateGrouping) /
        R.pathOr(0, [dateToUse, channel], spendByChannelDateGrouping);

      if (prev.hasOwnProperty(channel)) {
        incremented[channel] = {
          spend: (prev[channel].spend || 0) + (item[channel].spend || 0),
          volume: (prev[channel].volume || 0) + (item[channel].volume || 0),
          cpx: isFinite(cpx) ? cpx : 0,
          roas: isFinite(roas) ? roas : 0,
        };
      } else {
        incremented[channel] = item[channel];
      }
    }

    grouped[dateToUse] = incremented;
  }

  const averageCpxAndRoasByDate = Object.entries(totalsByDateGrouping).reduce((prev, curr) => {
    const [date, totals] = curr;
    return {
      ...prev,
      [date]: { cpx: totals.spend / totals.volume, roas: totals.revenue / totals.spend },
    };
  }, {});

  const groupedData = Object.entries(grouped).map(([date, totals]) => ({
    date,
    ...totals,
  })) as any[];

  return { groupedData, averageCpxAndRoasByDate };
};

const processGraphData = (
  data: PerformanceBreakdownData[],
  chartView: string,
  metricType: string,
  groupingKey: DateGroupingKey
) => {
  if (R.isNil(data) || R.isEmpty(data)) {
    return { processedGraphData: [], averageCpxAndRoasByDate: {} };
  }

  // Group data by selected date interval (Day, Week, Month, etc.)
  const { groupedData, averageCpxAndRoasByDate } = groupDataByDate(data, groupingKey);

  // If applicable, convert data to relative percentages.
  // But only if looking at Volume view because it doesn't make sense with ROAS/CPX.
  if (chartView === "Relative" && metricType === "volume") {
    let relativeSpend: RelativePerformanceBreakdownData[] = [];
    for (let item of groupedData) {
      const { date, ...rest } = item;
      let totals = {};
      for (let channel of R.keys(rest)) {
        for (let metric of R.keys(rest[channel])) {
          totals[metric] = (totals[metric] || 0) + rest[channel][metric];
        }
      }
      let converted = {};
      for (let channel of R.keys(rest)) {
        for (let metric of R.keys(rest[channel])) {
          converted = {
            ...converted,
            [channel]: {
              ...(converted[channel] || {}),
              [metric]: Math.round((1000 * item[channel][metric]) / totals[metric]) / 10,
            },
          };
        }
      }
      relativeSpend.push({ date, ...converted });
    }
    return { processedGraphData: relativeSpend, averageCpxAndRoasByDate };
  }

  return { processedGraphData: groupedData, averageCpxAndRoasByDate };
};

const processTableData = (data: PerformanceBreakdownData[], groupingKey: DateGroupingKey) => {
  let totalSpendByDateChannel = {};
  let totalVolumeByDateChannel = {};
  let totalRevenueByDateChannel = {};
  let groupedDataByDate: Record<string, Record<string, number>> = {};
  for (let row of data) {
    const channels = R.keys(row).filter(key => key !== "date");
    const dateToUse = getGroupingValue(row.date, groupingKey);
    for (let channel of channels) {
      const data = row[channel];

      totalSpendByDateChannel = R.mergeDeepRight(totalSpendByDateChannel, {
        [dateToUse]: {
          [channel]: R.pathOr(0, [dateToUse, channel], totalSpendByDateChannel) + data.spend,
        },
      });

      totalVolumeByDateChannel = R.mergeDeepRight(totalVolumeByDateChannel, {
        [dateToUse]: {
          [channel]: R.pathOr(0, [dateToUse, channel], totalVolumeByDateChannel) + data.volume,
        },
      });

      totalRevenueByDateChannel = R.mergeDeepRight(totalRevenueByDateChannel, {
        [dateToUse]: {
          [channel]:
            R.pathOr(0, [dateToUse, channel], totalRevenueByDateChannel) + (data.revenue || 0),
        },
      });

      groupedDataByDate = R.mergeDeepRight(groupedDataByDate, {
        [dateToUse]: {
          [`${channel}_volume`]:
            R.pathOr(0, [dateToUse, `${channel}_volume`], groupedDataByDate) +
            R.pathOr(0, ["volume"], data),
          [`${channel}_revenue`]:
            R.pathOr(0, [dateToUse, `${channel}_revenue`], groupedDataByDate) +
            R.pathOr(0, ["revenue"], data),
          [`${channel}_spend`]:
            R.pathOr(0, [dateToUse, `${channel}_spend`], groupedDataByDate) +
            R.pathOr(0, ["spend"], data),
          [`${channel}_roas`]:
            R.pathOr(0, [dateToUse, channel], totalRevenueByDateChannel) /
            R.pathOr(0, [dateToUse, channel], totalSpendByDateChannel),
          [`${channel}_cpx`]:
            R.pathOr(0, [dateToUse, channel], totalSpendByDateChannel) /
            R.pathOr(0, [dateToUse, channel], totalVolumeByDateChannel),
        },
      });
    }
  }

  return Object.entries(groupedDataByDate).map(([date, totals]) => ({ date, ...totals }));
};

const PerformanceBreakdown: React.FC<PerformanceBreakdownProps> = ({
  data,
  company,
  toggledMediaTypes,
  dateGroupingOptions,
}) => {
  const [metricType, setMetricType] = useState(METRIC_DROPDOWN_OPTIONS[0].value);
  const [chartView, setChartView] = useState(CHART_VIEW_OPTIONS[0].value);
  const [graphView, setGraphView] = useState(true);
  const [groupingKey, setGroupingKey] = useState(DATE_GROUPING_OPTIONS[1].value);
  const [showSpend, setShowSpend] = useState(false);
  const [logScale, setLogScale] = useState(false);

  const linkMetadata = {
    title: "performance page",
    links: [
      { name: "Streaming", href: `/${company}/streaming/performance` },
      { name: "Audio", href: `/${company}/audio/performance` },
      { name: "Linear", href: `/${company}/linear/performance` },
      { name: "Display", href: `/${company}/display/performance` },
    ],
  };

  const { processedGraphData, averageCpxAndRoasByDate } = useMemo(
    () => processGraphData(data, chartView, metricType, groupingKey),
    [data, metricType, chartView, groupingKey]
  );

  const processedTableData = useMemo(() => {
    if (!R.isNil(data) && !R.isEmpty(data)) {
      return processTableData(data, groupingKey);
    } else {
      return [];
    }
  }, [data, groupingKey]);

  const [headers, superHeaders] = useMemo(() => {
    let headers: Header[] = [
      {
        label: "Date",
        name: "date",
        nonInteractive: true,
        renderer: item => formatDateLabel(item.date, groupingKey),
      },
    ];
    let superHeaders: any[] = [{ span: 1 }];
    if (data) {
      for (let key of R.keys(data[0])) {
        if (key !== "date") {
          // For each media type, add a super header plus a header for Spend and CPM.
          headers.push(
            ...[
              {
                label: "Volume",
                name: `${key}_volume`,
                nonInteractive: true,
                flex: 1,
                renderer: item => formatNumberAsInt(item[`${key}_volume`]),
              },
              {
                label: "ROAS",
                name: `${key}_roas`,
                nonInteractive: true,
                flex: 1,
                renderer: item => formatNumber(item[`${key}_roas`], 2),
              },
              {
                label: "CPX",
                name: `${key}_cpx`,
                nonInteractive: true,
                flex: 1,
                renderer: item => (
                  <div className={showSpend ? "" : "farRightColumn"}>
                    {formatMoney(item[`${key}_cpx`], item[`${key}_cpx`] < 15 ? 2 : 0)}
                  </div>
                ),
              },
            ]
          );
          if (showSpend) {
            headers.push({
              label: "Spend",
              name: `${key}_spend`,
              nonInteractive: true,
              flex: 1,
              renderer: item => (
                <div className="farRightColumn">{formatMoney(item[`${key}_spend`], 0)}</div>
              ),
            });
          }
          superHeaders.push({ span: showSpend ? 4 : 3, data: MEDIA_TYPE_TO_PRETTY_NAME[key] });
        }
      }
    }

    return [headers, superHeaders];
  }, [data, showSpend, groupingKey]);

  const totals = useMemo(() => {
    let totals = processedTableData.reduce((acc, row) => {
      for (let key of R.keys(row)) {
        if (!key.includes("date")) {
          acc[key] = (acc[key] || 0) + row[key];
        }
      }
      return acc;
    }, {}) as Record<string, number>;

    // Calculate average CPX based on spend and volume totals.
    for (let key of R.keys(totals)) {
      if (key.includes("cpx")) {
        const channel = key.split("_")[0];
        totals[key] = totals[`${channel}_spend`] / totals[`${channel}_volume`];
      }
      if (key.includes("roas")) {
        const channel = key.split("_")[0];
        totals[key] = totals[`${channel}_revenue`] / totals[`${channel}_spend`];
      }
    }

    let formattedTotals: Record<string, string> = {};
    // Format numbers based on metric.
    for (let key of R.keys(totals)) {
      if (key.includes("volume")) {
        formattedTotals[key] = METRIC_TO_FORMATTER.volume(totals[key]);
      } else if (key.includes("cpx")) {
        formattedTotals[key] = METRIC_TO_FORMATTER.cpx(totals[key]);
      } else if (key.includes("spend")) {
        formattedTotals[key] = METRIC_TO_FORMATTER.spend(totals[key]);
      } else if (key.includes("roas")) {
        formattedTotals[key] = METRIC_TO_FORMATTER.roas(totals[key]);
      }
    }

    return {
      date: "Totals",
      ...formattedTotals,
    };
  }, [processedTableData]);

  const totalsRenderer = ({ data, style = {}, classes = [] as string[] }) => {
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  const exportToExcel = useCallback(() => {
    let fileName = `${company}_PerformanceBreakdown.xlsx`;

    let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    let worksheet = XLSX.utils.json_to_sheet(processedTableData);

    let sheetName = `${company}`;

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;

    XLSX.writeFile(workbook, fileName);
  }, [company, processedTableData]);

  const downloadPNG = useCallback(async () => {
    const contents = document.querySelector<HTMLElement>(".performanceBreakdown");
    if (!contents) {
      return;
    }
    const canvas = await html2canvas(contents);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${company}_PerformanceBreakdown.png`, "image/png");
  }, [company]);

  return (
    <ChartContainer
      title="Performance Breakdown"
      leftActions={<LinkSelector title={linkMetadata.title} links={linkMetadata.links} />}
      rightActions={
        <>
          <ToggleSwitch
            label="Log Scale"
            checked={logScale}
            onChange={setLogScale}
            disabled={!graphView}
          />
          <ToggleSwitch label="Spend" checked={showSpend} onChange={setShowSpend} />
          {graphView && metricType === "volume" && (
            <Dropdown
              type={DropdownToggleType.FILLED}
              design="secondary"
              size="sm"
              value={chartView}
              options={CHART_VIEW_OPTIONS}
              onChange={option => setChartView(option)}
            />
          )}
          <GraphTableToggleButton
            graphView={graphView}
            setGraphView={setGraphView}
            widgetName="performance_breakdown"
          />
          <DownloadDropdown
            size="sm"
            onClickOptions={[exportToExcel, downloadPNG]}
            disabledMenuOptions={
              graphView ? { XLSX: false, PNG: false } : { XLSX: false, PNG: true }
            }
            disabled={!data}
          />
        </>
      }
    >
      {data ? (
        !graphView ? (
          <div className="performanceBreakdownTable">
            <BPMTable
              data={processedTableData}
              headers={headers}
              superHeaders={superHeaders}
              filterBar={false}
              totals={totals}
              totalsRenderer={totalsRenderer}
            />
          </div>
        ) : (
          <div className="performanceBreakdown">
            <div className="aboveChart">
              <div className="aboveChartLeft">
                <Dropdown
                  type={DropdownToggleType.OUTLINED}
                  design="secondary"
                  size="sm"
                  value={metricType}
                  options={METRIC_DROPDOWN_OPTIONS}
                  onChange={option => {
                    if (option !== "volume") {
                      setChartView("Absolute");
                    }
                    setMetricType(option);
                  }}
                />
                <InfoTooltip>
                  <div>
                    Streaming volumes and performance metrics correspond to full funnel incremental
                    matches from Tinuiti’s device graph. <br /> Last week's linear volumes are
                    subject to change daily, as values are based on pre-log and BVS encoded
                    timestamps prior to receipt of network post-logs. Results should be interpreted
                    directionally.
                  </div>
                </InfoTooltip>
              </div>

              {showSpend && <div className="spendAxisLabel">Spend</div>}
            </div>
            <div className="chartContents">
              <div id="performanceBreakdownChart" className="performanceBreakdownChart">
                <AutoSizer>
                  {({ width, height }) => (
                    <ComposedChart
                      data={processedGraphData}
                      width={width}
                      height={height}
                      margin={CHART_MARGIN_WITH_LEGEND}
                    >
                      <CartesianGrid
                        vertical={false}
                        stroke={CARTESIAN_GRID_STROKE}
                        strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                      />
                      <Tooltip
                        content={({ label, payload }) => {
                          const formattedHeaderLabel = formatDateLabel(label, groupingKey, true);
                          let items = R.sortBy(
                            (item: any) => -Math.abs(item.value),
                            payload?.map(item => ({
                              label: item.name as string,
                              value: item.value as number,
                              color: item.stroke,
                            })) || []
                          );
                          let otherItems;
                          if (showSpend) {
                            otherItems = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              items.filter(item => item.label.includes("Spend"))
                            );
                            items = items.filter(item => !item.label.includes("Spend"));
                          }
                          const subHeaderValue = R.pathOr(
                            undefined,
                            [label, metricType],
                            averageCpxAndRoasByDate
                          );
                          return (
                            <ChartTooltip
                              headerLabel={formattedHeaderLabel}
                              subHeaderLabel={`${metricType === "volume" ? "Total" : "Avg"} ${
                                METRIC_OPTIONS_MAP[metricType].prettyName
                              }`}
                              subHeaderValue={subHeaderValue}
                              subHeaderValueFormatter={
                                chartView === "Relative"
                                  ? RELATIVE_FORMATTER
                                  : METRIC_OPTIONS_MAP[metricType].tooltipFormatter
                              }
                              items={items}
                              itemFormatter={
                                chartView === "Relative"
                                  ? RELATIVE_FORMATTER
                                  : METRIC_OPTIONS_MAP[metricType].tooltipFormatter
                              }
                              itemKeyShape={showSpend ? "line" : "circle"}
                              {...(otherItems
                                ? {
                                    otherSubHeaderLabel: "Total Spend",
                                    otherSubHeaderValueFormatter:
                                      chartView === "Relative"
                                        ? RELATIVE_FORMATTER
                                        : SPEND_FORMATTER,
                                    otherItems: otherItems,
                                    otherItemFormatter:
                                      chartView === "Relative"
                                        ? RELATIVE_FORMATTER
                                        : SPEND_FORMATTER,
                                  }
                                : {})}
                            />
                          );
                        }}
                        isAnimationActive={false}
                      />
                      <XAxis
                        dataKey="date"
                        padding={X_AXIS_PADDING}
                        tick={TICK_STYLE}
                        tickFormatter={date => formatDateLabel(date, groupingKey)}
                      />
                      <YAxis
                        tick={TICK_STYLE}
                        tickLine={false}
                        tickFormatter={number =>
                          chartView === "Relative"
                            ? `${abbreviateNumber(number)}%`
                            : `${metricType === "cpx" ? "$" : ""}${abbreviateNumber(number)}`
                        }
                        {...(logScale
                          ? { scale: "log", domain: [0.01, "auto"], allowDataOverflow: true }
                          : {})}
                      />
                      {showSpend && (
                        <YAxis
                          tick={TICK_STYLE}
                          tickFormatter={number =>
                            chartView === "Relative"
                              ? `${abbreviateNumber(number)}%`
                              : `$${abbreviateNumber(number)}`
                          }
                          orientation="right"
                          yAxisId={1}
                        />
                      )}

                      {showSpend && (
                        <>
                          <Bar
                            dataKey="streaming.spend"
                            name="Streaming Spend"
                            fill="#D3B7FB"
                            stroke="#D3B7FB"
                            yAxisId={1}
                          />
                          <Bar
                            dataKey="audio.spend"
                            name="Audio Spend"
                            fill="#b8f5e4"
                            stroke="#b8f5e4"
                            yAxisId={1}
                          />
                          <Bar
                            dataKey="display.spend"
                            name="Display Spend"
                            fill="#fbe8a9"
                            stroke="#fbe8a9"
                            yAxisId={1}
                          />
                          <Bar
                            dataKey="tv.spend"
                            name="Linear Spend"
                            fill="#b0fdca"
                            stroke="#b0fdca"
                            yAxisId={1}
                          />
                        </>
                      )}
                      {toggledMediaTypes.streaming && (
                        <Line
                          dataKey={`streaming.${metricType}`}
                          name={`Streaming ${METRIC_OPTIONS_MAP[metricType].prettyName}`}
                          type="monotone"
                          strokeWidth={LINE_STROKE_WIDTH}
                          dot={false}
                          isAnimationActive={false}
                          stroke={STREAMING_STROKE_COLOR}
                        />
                      )}
                      {toggledMediaTypes.audio && (
                        <Line
                          dataKey={`audio.${metricType}`}
                          name={`Audio ${METRIC_OPTIONS_MAP[metricType].prettyName}`}
                          type="monotone"
                          strokeWidth={LINE_STROKE_WIDTH}
                          dot={false}
                          isAnimationActive={false}
                          stroke={AUDIO_STROKE_COLOR}
                        />
                      )}
                      {toggledMediaTypes.display && (
                        <Line
                          dataKey={`display.${metricType}`}
                          name={`Display ${METRIC_OPTIONS_MAP[metricType].prettyName}`}
                          type="monotone"
                          strokeWidth={LINE_STROKE_WIDTH}
                          dot={false}
                          isAnimationActive={false}
                          stroke={DISPLAY_STROKE_COLOR}
                        />
                      )}
                      {toggledMediaTypes.tv && (
                        <Line
                          dataKey={`tv.${metricType}`}
                          name={`Linear TV ${METRIC_OPTIONS_MAP[metricType].prettyName}`}
                          type="monotone"
                          strokeWidth={LINE_STROKE_WIDTH}
                          dot={false}
                          isAnimationActive={false}
                          stroke={LINEAR_STROKE_COLOR}
                        />
                      )}
                    </ComposedChart>
                  )}
                </AutoSizer>
              </div>
              <div className="rightOfChart">
                <BreakdownLegend
                  data={data}
                  dimensionType={METRIC_OPTIONS_MAP[metricType].prettyName}
                  mediaTypes={R.keys(R.pickBy(v => v, toggledMediaTypes))}
                />
                <Dropdown
                  type={DropdownToggleType.OUTLINED}
                  design="secondary"
                  size="sm"
                  value={groupingKey}
                  options={dateGroupingOptions}
                  onChange={option => setGroupingKey(option as DateGroupingKey)}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <Skeleton>
          <PathSkeleton
            points={[
              [0, 0.3],
              [0.25, 0.7],
              [0.5, 0.2],
              [0.75, 0.9],
              [1, 0.5],
            ]}
          />
        </Skeleton>
      )}
    </ChartContainer>
  );
};

export default PerformanceBreakdown;
