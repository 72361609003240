import { QuestionCardProps } from "../../Components/QuestionCard/QuestionCard";

export const budgetStrategy = [
  {
    id: "budget_authority",
    questionNumber: "4B1",
    title: "How much authority does Tinuiti have to re-allocate budgets?",
    type: "radio",
    options: ["None", "Some. Tinuiti can adjust budget:", "Complete authority"],
    nestedOptionsConfig: {
      "Some. Tinuiti can adjust budget:": [
        {
          inputType: "checkbox",
          options: ["Between channels", "Between platforms (within a channel)", "Between campaigns (within a platform)", "Between time periods", "Up to reach performance goals", "Down to reach performance goals"]
        }
      ]
    },
  },
  {
    id: "require_pos",
    questionNumber: "4B2",
    title: "Do you require POs on your Tinuiti invoices?",
    type: "radio",
    options: ["Yes", "No"],
    nestedOptionsConfig: {
      Yes: [
        {
          inputType: "textarea",
          label: "In what capacity?",
          placeholder: "Example Text"
        },
        {
          inputType: "textarea",
          label: "How do you need them broken out?",
          placeholder: "Example Text"
        },
        {
          inputType: "textarea",
          label: "When can we expect to receive these?",
          placeholder: "Example Text"
        }
      ]
    }
  },
  {
    id: "billing_team_contact",
    questionNumber: "4B3",
    title: "Does your billing team need to speak with us prior to engagement?",
    type: "radio",
    options: ["Yes", "No"]
  },
  {
    id: "billing_nuances",
    questionNumber: "4B4",
    title: "Are there any billing or invoicing nuances not captured here?",
    type: "radio",
    options: ["Yes", "No"]
  }
] as QuestionCardProps[];

export const budgetDetails = [
  {
    id: "budget_shift",
    questionNumber: "4C1",
    title: "Are you expecting a large shift in budgets the month Tinuiti takes over?",
    type: "radio",
    options: ["Yes", "No", "Unsure"],
  },
  {
    id: "campaign_attention",
    questionNumber: "4C2",
    title: "Is there a specific campaign, effort, or platform that requires special attention as soon as Tinuiti takes over?",
    subtitle: "Ex: time-sensitive, new product launch, large promotion, etc.",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    id: "upcoming_initiatives",
    questionNumber: "4C3",
    title: "Please upload any upcoming initiatives that we need to be aware of prior to engagement.",
    subtitle: "Ex: promotional, product, or media plans",
    type: "upload",
    s3Bucket: "bpm-client-info",
    s3Path: "upcoming-initiatives"
  }
] as QuestionCardProps[];

export const budgets = [
  {
    id: "fiscal_calendars",
    questionNumber: "4A1",
    title: "Please attach your current fiscal calendars.",
    type: "upload",
    s3Bucket: "bpm-client-info",
    s3Path: "fiscal-calendars"
  },
  {
    id: "current_budgets",
    questionNumber: "4A2",
    title: "Please attach your current budgets.",
    type: "upload",
    s3Bucket: "bpm-client-info",
    s3Path: "current-budgets"
  },
] as QuestionCardProps[];
