import * as R from "ramda";
import { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggleType,
  FullPageSpinner,
  Page,
  Input,
  DatePicker,
  Button,
  ButtonType,
  Spinner,
  MenuItem,
} from "../Components";
import { useCompanyInfo } from "../redux/company";
import { Form } from "react-bootstrap";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import Plan from "./Plan";
import "./YouTubePlanning.scss";
import "../Components/FormCheck.scss";
import { YoutubePlanningLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import { calculateCPM, calculateCPX } from "../CrossChannel/crossChannelUtils";
import { formatMoney, formatNumber } from "../utils/format-utils";

export interface YouTubePlanTableData {
  id: number;
  format: string;
  targetingType: string[];
  segments: Record<string, string[]>;
  budget: string;
  budgetShare: string;
  reach: string;
  avgFreq: string;
  costPerReach: string;
  imps: string;
  views: string;
  cpv: string;
}

export interface YouTubePlanData {
  goal: string;
  queryString: string;
  dates: Record<string, string>;
  inputBudget: number;
  suggestedBudget: number;
  onTargetReach: number;
  averageFreq: number;
  cpm: number;
  youTubePopulation: number;
  spendVsReach: { spend: number; val: number }[];
  spendVsViews: { spend: number; val: number }[];
  tableData: YouTubePlanTableData[];
}

const GOAL_OPTIONS = [
  "Efficient Reach (Awareness)",
  "Efficient Completions (Awareness)",
  "Maximize Views (Considerations)",
  // "Online Conversions (Action)", TODO: Add back when Google offers Demand Gen
];

interface GoogleAdsAccount {
  account_id: string;
  account_name: string;
  mcc_id: string;
}

const YouTubePlanning = (): JSX.Element => {
  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const [goal, setGoal] = useState<string>();
  const [googleAdsAccountOptions, setGoogleAdsAccountOptions] = useState<MenuItem<string>[]>();
  const [googleAdsAccountData, setGoogleAdsAccountData] = useState({});
  const [googleAdsAccount, setGoogleAdsAccount] = useState<string>();
  const [queryString, setQueryString] = useState<string>();
  const [selectedAttribute, setSelectedAttribute] = useState<MenuItem<string>>();
  const [budget, setBudget] = useState<number>();
  const [dates, setDates] = useState({ start: "", end: "" });
  const [generating, setGenerating] = useState<boolean>(false);
  const [recommendationData, setRecommendationData] = useState<YouTubePlanData>();
  const [showRecommendation, setShowRecommendation] = useState<boolean>(false);

  const getTableDataPoint = useCallback(
    (forecast, suggestedBudget, id, format, top5InMarkets, top5Affinities) => {
      const { costMicros } = forecast;
      const cost = costMicros / 1000000;
      const {
        onTargetReach: onTargetReachString,
        onTargetImpressions: onTargetImpressionsString,
        views: viewsString,
      } = forecast.plannedProductForecast;
      const onTargetReach = parseInt(onTargetReachString);
      const onTargetImpressions = parseInt(onTargetImpressionsString);
      const views = parseInt(viewsString);

      return {
        id,
        format,
        targetingType: ["In-Market Audiences", "Affinity Audiences"],
        segments: { inMarkets: top5InMarkets, affinities: top5Affinities },
        budget: formatMoney(cost, 0),
        budgetShare: `${Math.round((suggestedBudget ? cost / suggestedBudget : 0) * 100)}%`,
        reach: formatNumber(onTargetReach),
        avgFreq: formatNumber(onTargetReach ? onTargetImpressions / onTargetReach : 0),
        costPerReach: formatMoney(calculateCPX(cost, onTargetReach)),
        imps: formatNumber(onTargetImpressions),
        cpm: formatMoney(calculateCPM(cost, onTargetImpressions)),
        views: formatNumber(views),
        cpv: formatMoney(calculateCPX(cost, views)),
      };
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (!googleAdsAccountOptions) {
        try {
          let res = await YoutubePlanningLambdaFetch("/getGoogleAdsAccounts", {
            method: "GET",
            params: {
              company: cid,
            },
          });
          let googleAdsAccounts: Record<string, GoogleAdsAccount> = await awaitJSON(res);

          const googleAdsAccountOptions = Object.values(googleAdsAccounts)
            .sort((a, b) => parseInt(a.account_id) - parseInt(b.account_id))
            .map(account => {
              const { account_id, account_name } = account;
              return {
                label: `${account_id}${account_name ? ` (${account_name})` : ""}`,
                value: account.account_id,
              };
            });

          setGoogleAdsAccountOptions(googleAdsAccountOptions);
          setGoogleAdsAccountData(googleAdsAccounts);
        } catch (e) {
          const error = e as Error;
          setGoogleAdsAccountOptions([]);
          setError({ message: error.message, reportError: error });
        }
      }
    })();
  }, [cid, googleAdsAccountOptions, setError]);

  const fetchGoogleAttributes = useCallback(
    async input => {
      const googleAdsAccountInfo = googleAdsAccount ? googleAdsAccountData[googleAdsAccount] : {};

      let attributes = [];
      try {
        let res = await YoutubePlanningLambdaFetch("/getAttributes", {
          method: "GET",
          params: {
            company: cid,
            googleAdsAccount: googleAdsAccountInfo.account_id,
            mccId: googleAdsAccountInfo.mcc_id,
            queryString: input,
          },
        });

        attributes = await awaitJSON(res);

        return attributes.map((attribute: { name: string; id: string }) => {
          return { label: attribute.name, value: attribute.id };
        });
      } catch (e) {
        return [];
      }
    },
    [cid, googleAdsAccount, googleAdsAccountData]
  );

  const generateRecommendation = useCallback(
    async (editedBudget?, editedBudgetShare?) => {
      setGenerating(true);

      const googleAdsAccountInfo = googleAdsAccount ? googleAdsAccountData[googleAdsAccount] : {};

      try {
        let res = await YoutubePlanningLambdaFetch("/createPlan", {
          method: "POST",
          body: {
            googleAdsAccount: googleAdsAccountInfo.account_id,
            mccId: googleAdsAccountInfo.mcc_id,
            attribute: selectedAttribute,
            goal,
            budget: editedBudget || budget,
            startDate: dates.start,
            endDate: dates.end,
            budgetSplit: editedBudgetShare || null,
          },
        });
        let plan = await awaitJSON(res);

        const spendVsReach = plan.reachCurve.reachForecasts.map(row => {
          return { spend: row.costMicros / 1000000, val: row.forecast.onTargetReach };
        });

        const spendVsViews = plan.reachCurve.reachForecasts.map(row => {
          return { spend: row.costMicros / 1000000, val: row.forecast.views };
        });

        const suggestedBudget =
          R.pathOr(0, ["closestDataPointToBudget", "costMicros"], plan) / 1000000;
        const onTargetReach = parseInt(
          R.pathOr(0, ["closestDataPointToBudget", "forecast", "onTargetReach"], plan) || ""
        );
        const onTargetImpressions = parseInt(
          R.pathOr(0, ["closestDataPointToBudget", "forecast", "onTargetImpressions"], plan) || ""
        );

        let tableData: YouTubePlanTableData[] = [];
        let rowId = 1;
        const productSpecificForecasts = R.pathOr(
          [],
          ["closestDataPointToBudget", "plannedProductReachForecasts"],
          plan
        );

        // Split into Video Reach Campaign and Bumpers
        if (productSpecificForecasts.length === 2) {
          // Video Reach Campaign
          const videoReachForecast = productSpecificForecasts[0];
          const videoReachdataPoint = getTableDataPoint(
            videoReachForecast,
            suggestedBudget,
            rowId,
            "Video Reach Campaign",
            plan.top5InMarkets,
            plan.top5Affinities
          );
          tableData.push(videoReachdataPoint);
          rowId++;

          // Bumpers
          const bumperForecast = productSpecificForecasts[1];
          const bumperDataPoint = getTableDataPoint(
            bumperForecast,
            suggestedBudget,
            rowId,
            "Bumpers",
            plan.top5InMarkets,
            plan.top5Affinities
          );
          tableData.push(bumperDataPoint);
          rowId++;
        } else {
          const forecast = productSpecificForecasts[0];

          const dataPoint = getTableDataPoint(
            forecast,
            suggestedBudget,
            rowId,
            goal === "Maximize Views (Considerations)"
              ? "Video View Campaign"
              : "Demand Gen Campaign",
            plan.top5InMarkets,
            plan.top5Affinities
          );
          tableData.push(dataPoint);
          rowId++;
        }

        const planData = {
          goal: goal || "",
          inputBudget: editedBudget || budget || 0,
          queryString: queryString || "",
          dates: dates || {},
          suggestedBudget,
          onTargetReach,
          averageFreq: onTargetReach ? onTargetImpressions / onTargetReach : 0,
          cpm: calculateCPM(suggestedBudget, onTargetImpressions),
          youTubePopulation: parseInt(
            R.pathOr(0, ["onTargetAudienceMetrics", "youtubeAudienceSize"], plan) || ""
          ),
          spendVsReach,
          spendVsViews,
          tableData,
        };

        setRecommendationData(planData);
        setGenerating(false);
        setShowRecommendation(true);
      } catch (e) {
        const error = e as Error;
        setError({ message: error.message, reportError: error });
        setGenerating(false);
      }
    },
    [
      googleAdsAccount,
      googleAdsAccountData,
      selectedAttribute,
      goal,
      budget,
      dates,
      queryString,
      getTableDataPoint,
      setError,
    ]
  );

  return (
    <Page
      app2Redesign
      title="YouTube Planning"
      pageType="YouTube Planning"
      actions={<div className="youTubePlanningActions"></div>}
    >
      {cid && googleAdsAccountOptions ? (
        <div className="youTubePlanningBody">
          {showRecommendation ? (
            <Plan
              planData={recommendationData}
              onClose={() => setShowRecommendation(false)}
              onRegenerate={generateRecommendation}
            />
          ) : (
            <div className="planningForm">
              <div className="planningFormHeader">Build Recommendation</div>
              <div className="planningFormBody">
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Goal</div>
                  <div className="goalRadioChoice">
                    {GOAL_OPTIONS.map(goalName => {
                      return (
                        <Form.Check
                          key={goalName}
                          className="goalRadioOption"
                          type="radio"
                          label={goalName}
                          checked={goal === goalName}
                          id={goalName}
                          onChange={() => setGoal(goalName)}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Google Ads Account</div>
                  <Dropdown
                    className="googleAdsAccountDropdown"
                    type={DropdownToggleType.OUTLINED}
                    design="primary"
                    value={googleAdsAccount}
                    placeholder="Select Google Ads Account"
                    options={googleAdsAccountOptions || []}
                    onChange={setGoogleAdsAccount}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Query</div>
                  <Input
                    type="text"
                    className="queryInput"
                    placeholder="Search for a category or company name..."
                    value={queryString || ""}
                    onInputChange={setQueryString}
                    onDropdownChange={setSelectedAttribute}
                    optionFetcher={fetchGoogleAttributes}
                    searchable={true}
                    disabled={!googleAdsAccount}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Budget</div>
                  <Input
                    type="number"
                    className="budgetInput"
                    placeholder="Input Budget"
                    value={budget || ""}
                    onChange={e => setBudget(e.target.valueAsNumber)}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Date Range</div>
                  <DatePicker
                    range={dates}
                    isOutsideRange={date => date < TODAY}
                    onChange={({ start, end }) => {
                      setDates({ start, end });
                    }}
                  />
                </div>
              </div>
              <div className="planningFormFooter">
                <Button
                  className="generateButton"
                  type={ButtonType.FILLED}
                  disabled={
                    !goal ||
                    !googleAdsAccount ||
                    !budget ||
                    !dates.start ||
                    !dates.end ||
                    !queryString
                  }
                  onClick={() => generateRecommendation()}
                >
                  {generating ? <Spinner /> : "Generate Recommendation"}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default YouTubePlanning;
