import React, { useState, useMemo, useEffect, useCallback } from "react";
import * as R from "ramda";
import * as uuid from "uuid";
import Select from "react-select";
import { Form, InputGroup, Modal, Tooltip as ExplainerTooltip } from "react-bootstrap";
import {
  MdAdd,
  MdCancel,
  MdDeleteForever,
  MdFileDownload,
  MdSave,
  MdSystemUpdateAlt,
  MdWarning,
} from "react-icons/md";
import { useSetError } from "../redux/modals";
import { useStateFunction } from "../utils/hooks/useData";
import {
  BPMButton,
  BPMTable,
  OldFilterBar,
  FullPageSpinner,
  OverlayTrigger,
  Page,
  Spinner,
  SuggestionInput,
  BPMToggleButton,
  ToggleCheckboxes,
  CellRenderer,
} from "../Components";
import {
  AdminLambdaFetch,
  awaitJSON,
  GetS3SignedUrl,
  MiscLambdaFetch,
  S3PutPng,
  SheetsLambdaFetch,
  SlidesLambdaFetch,
  StreamingPerformanceLambdaFetch,
  StreamingV2LambdaFetch,
} from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import { ReactComponent as InfoIcon } from "../HomePage/info.svg";
import { useCompanyInfo } from "../redux/company";
import { convertSVGStringToPNGURI, downloadJSONToCSV } from "../utils/download-utils";
import * as Dfns from "date-fns/fp";
import "./StreamingPlansBuilder.scss";
import { DATE_FORMAT } from "../utils/data";
import { checkGoogleAccessTokens, SLIDE_ASSET_S3_BUCKET } from "../Slides/Slides";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import ReactDOM from "react-dom";
import { AppendixBarChart } from "./AppendixBarChart";
import { Provider } from "react-redux";
import { reduxStore } from "../redux";
import { S3PromiseFunction } from "../Slides/slidesTypes";

type Network = string;
type Description = string;
type Notes = string;

interface StreamingNetwork {
  name: string;
  shortCode: string;
  dsp: string[];
}

interface FormData {
  network: Network;
  spend: number;
  description: Description;
  idString: string;
  cpm: number;
  notes: Notes;
  newRow?: boolean;
  deletedRow?: boolean;
  feeExclusive?: boolean;
  weeklySpendMultiplier?: number;
  clearance?: number;
  totalSpend?: number;
  dsp?: string;
  shortCode?: string;
}

interface TableRow {
  network: string;
  description: string;
  mediaSpend: number;
  adServing: number;
  bpmFee: number;
  cpm: number;
  ecpm: number;
  idString: string;
  notes: Notes;
  totalSpend: number;
  impressions: number;
  spend: number;
  newRow?: boolean;
  percentageSpend: number;
  feeExclusive?: boolean;
  weeklySpendMultiplier?: number;
  clearance?: number;
  shortCode?: string;
  dsp?: string;
  beeswaxFee: number;
  tremorFee: number;
}

interface FinalRow {
  cpm: string;
  ecpm: string;
  impressions: string;
  mediaSpend: string;
  adServing: string;
  bpmFee: string;
  totalSpend: string;
  beeswaxFee: string;
  tremorFee: string;
}

interface DropdownOptions {
  label: string;
  value: string;
}

type SelectedPlan = Record<string, FormData>;
type AllPlans = Record<string, SelectedPlan>;

const DEFAULT_AGENCY_FEE = 0.1;
const AD_SERVING_FEE = 0.25;
const BEESWAX_FEE = 0.3; // Extra CPM
const TREMROR_FEE = 0.1; // 10% of CPM
const FILTER_OPTIONS = [
  {
    name: "network",
    label: "Network",
  },
  {
    name: "description",
    label: "Description",
  },
  {
    name: "notes",
    label: "Notes",
  },
];

const StreamingPlansBuilder = (): JSX.Element => {
  const isExistingClient = Boolean(useLocation().company);
  const companyInfo = useCompanyInfo();
  const [company, setCompany] = useState(useLocation().company);
  const [companyOptions, setCompanyOptions] = useState<string[]>([]);
  const setError = useSetError();

  const [networkMap, setNetworkMap] = useState<Record<string, StreamingNetwork>>();
  const [cpmMap, setCpmMap] = useState<Record<string, number>>({});

  const [existingPlans, setExistingPlans] = useState<AllPlans>({});
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>({});
  const [newChanges, setNewChanges] = useState<SelectedPlan>({});
  const [formData, setFormData] = useState<Partial<FormData>>({});
  const [summaryTableData, setSummaryTableData] = useState<TableRow[]>([]);

  const [planDropdownOptions, setPlanDropdownOptions] = useState<DropdownOptions[]>([]);
  const [currentDropdownName, setCurrentDropdownName] = useState<string>();
  const [modalActive, setModalActive] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string>();
  const [totalSpendCombined, setTotalSpendCombined] = useState(0);
  const [filter, setFilter] = useStateFunction<(TableRow) => boolean>(() => true);
  const [googleSheetsData, setGoogleSheetsData] = useState<Record<string, any> | undefined>(
    undefined
  );

  const [feePercentage, setFeePercentage] = useState<number>(
    companyInfo && companyInfo.commission ? companyInfo.commission : DEFAULT_AGENCY_FEE
  );

  const commissionFee = useMemo(() => feePercentage * 100, [feePercentage]);
  const [adServingFee] = useState(AD_SERVING_FEE);

  const combinedData = useMemo(() => ({ ...selectedPlan, ...newChanges }), [
    selectedPlan,
    newChanges,
  ]);

  const [pacingClearance, setPacingClearance] = useState<number | null>();

  const [clearanceMap, setClearanceMap] = useState({}); // TODO: Load this with real data
  useEffect(() => {
    const end = Dfns.subDays(1, Dfns.startOfISOWeek(new Date()));
    const start = Dfns.subDays(13, end);
    const dates = { start: Dfns.format(DATE_FORMAT, start), end: Dfns.format(DATE_FORMAT, end) };
    const groupbynetwork = 1;
    // Only fetch if both dates are set.
    if (start && end && networkMap) {
      (async () => {
        let res = await AdminLambdaFetch("/pacing", {
          params: {
            ...dates,
            groupbynetwork,
          },
        });
        let pacingInfo = await awaitJSON(res);
        let clearanceMap = {};
        if (pacingInfo && pacingInfo.length) {
          for (let row of pacingInfo) {
            let network = networkMap[row.network].name;
            if (network && row.platform && row.platform.includes("")) {
              clearanceMap[`${network}_${row.description}`] = row;
            }
          }
        }
        setClearanceMap(clearanceMap);
      })();
    }
  }, [networkMap]);

  // Fetch saved plans
  const fetchPlans = useCallback(async () => {
    if (isExistingClient) {
      try {
        let res = await MiscLambdaFetch("/get_streaming_plan", {
          params: { company },
        });
        let data = await awaitJSON(res);
        let dropdownOptions: DropdownOptions[] = [];
        let planData = {};

        if (data.length) {
          for (let plan of data) {
            dropdownOptions.push({ label: plan.plan_name, value: `${company}_${plan.plan_name}` });
            planData[`${company}_${plan.plan_name}`] = plan.plan_data;
          }
          setCompanyOptions([company]);
          setPlanDropdownOptions(dropdownOptions);
          setExistingPlans(planData);
          setSelectedPlan(planData[dropdownOptions[0].label]);
          setCurrentDropdownName(dropdownOptions[0].label);
        }
      } catch (e) {
        let error = e as Error;
        setError({ message: error.message, reportError: error });
      }
    } else {
      try {
        let res = await MiscLambdaFetch("/get_streaming_plan_proposals", {});
        let data = await awaitJSON(res);
        let dropdownOptions: DropdownOptions[] = [];
        let planData = {};
        let companyOptions = {};
        if (data.length) {
          for (let plan of data) {
            dropdownOptions.push({
              label: plan.plan_name,
              value: `${plan.company}_${plan.plan_name}`,
            });
            planData[`${plan.company}_${plan.plan_name}`] = plan.plan_data;
            companyOptions[plan.company] = true;
          }
          setCompanyOptions(R.keys(companyOptions).sort());
          setPlanDropdownOptions(dropdownOptions);
          setExistingPlans(planData);
        }
      } catch (e) {
        let error = e as Error;
        setError({ message: error.message, reportError: error });
      }
    }
  }, [company, isExistingClient, setError]);

  useEffect(() => {
    if (
      !R.isEmpty(planDropdownOptions) &&
      R.isEmpty(selectedPlan) &&
      !R.isEmpty(existingPlans) &&
      company
    ) {
      let selectedPlan = existingPlans[planDropdownOptions[0].value];
      if (selectedPlan) {
        setSelectedPlan(selectedPlan);
        setCurrentDropdownName(planDropdownOptions[0].label);
      }
    }
  }, [company, existingPlans, planDropdownOptions, selectedPlan]);

  useEffect(() => {
    if (R.isEmpty(existingPlans)) {
      fetchPlans();
    }
  }, [company, existingPlans, fetchPlans, setError]);

  // Network Dropdown Options
  useEffect(() => {
    if (!networkMap) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/old_streaming_networks");
          let data = await awaitJSON(res);
          let cpmMap = {};
          for (let networkObj of data) {
            cpmMap[networkObj.name] = networkObj.cpm ?? 10;
          }
          setCpmMap(cpmMap);
          let newNetworkMap = {};
          for (let row of data.sort()) {
            if (row.name && row.shortCode) {
              newNetworkMap[row.shortCode] = { ...row, freqCount: 0, freqSum: 0 };
            }
          }

          const sampleCompanies = ["instacart", "warbyparker", "acorns", "allbirds", "thumbtack"];
          for (const company of sampleCompanies) {
            const branch = "v2";
            const build = "latest";
            const isGraph = false;
            let params: S.GetPageDataParams = {
              id: 0,
              company,
              branch,
              build,
              isGraph,
            };
            let strPerfRes = await StreamingPerformanceLambdaFetch<S.GetPageDataParams>("/", {
              params,
            });
            let { data: strPerfData } = await awaitJSON<S.GetPageDataResponse>(strPerfRes);
            for (let { dimensions, fetches } of strPerfData) {
              const { Network } = dimensions;
              const shortCode = Network ? Network.slice(3).split("ID")[0] : "";
              if (newNetworkMap[shortCode]) {
                for (const key of R.keys(fetches)) {
                  if (fetches[key].avgFreq) {
                    newNetworkMap[shortCode].freqCount += 1;
                    newNetworkMap[shortCode].freqSum += fetches[key].avgFreq;
                  }
                }
              }
            }
          }
          setNetworkMap(newNetworkMap);
        } catch (e) {
          let error = e as Error;
          setError({ message: error.message, reportError: error });
        }
      })();
    }
  }, [networkMap, setError, company]);

  useEffect(() => {
    if (!googleSheetsData) {
      (async () => {
        try {
          const params = {
            google: true,
            file_id: "1Cch0FS1SC5u5dZn9S-g_Meref5pDnL7NQsF44gxYf-I",
            tab_name: "Streaming Network Demographics 2022 Ref",
          };
          let res = await SheetsLambdaFetch("/json", {
            params,
          });
          let data = await awaitJSON(res);
          let newGoogleMap = {};
          for (let network of data.json) {
            if (network.Network) {
              newGoogleMap[network.Network] = network;
            }
          }
          setGoogleSheetsData(newGoogleMap);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [googleSheetsData]);

  // Add Row
  const addRow = () => {
    const id = uuid.v4();

    setNewChanges(current => {
      if (formData.network) {
        return {
          ...current,
          [id]: {
            spend: formData.spend || 0,
            network: formData.network,
            description: formData.description || "",
            idString: id,
            cpm: formData.cpm || 10,
            notes: formData.notes || "",
            newRow: true,
            feeExclusive: formData.feeExclusive || false,
            weeklySpendMultiplier: formData.weeklySpendMultiplier || 1,
            clearance: formData.clearance,
            totalSpend: formData.totalSpend,
            dsp: formData.dsp,
            shortCode: formData.shortCode,
          },
        };
      }
      return current;
    });
  };

  // Delete Row
  const deleteRow = (id: string) => {
    let deleteObj = { ...selectedPlan[id], deletedRow: true };
    setNewChanges(current => {
      return { ...current, [id]: deleteObj };
    });
  };

  // Update Row
  const updateRow = () => {
    setNewChanges(current => {
      return {
        ...current,
        [selectedRowId || ""]: {
          spend: formData.spend || 0,
          network: formData.network || "",
          description: formData.description || "",
          idString: selectedRowId || "",
          cpm: formData.cpm || 10,
          notes: formData.notes || "",
          feeExclusive: formData.feeExclusive || false,
          weeklySpendMultiplier: formData.weeklySpendMultiplier || 1,
          clearance: formData.clearance,
          totalSpend: formData.totalSpend,
          dsp: formData.dsp,
          shortCode: formData.shortCode,
        },
      };
    });
    setSelectedRowId(undefined);
    setFormData({});
  };

  // Save Plan
  const savePlan = useCallback(
    async (newName?) => {
      if (company) {
        try {
          setSaving(true);
          let combinedDataStripNewKey = {};
          for (let key of R.keys(combinedData)) {
            if (!combinedData[key].deletedRow) {
              combinedDataStripNewKey[key] = R.omit(["newRow"], combinedData[key]);
            }
          }
          if (isExistingClient) {
            await MiscLambdaFetch("/add_streaming_plan", {
              method: "POST",
              body: {
                company,
                plan: combinedDataStripNewKey,
                planName: newName ? newName : currentDropdownName,
              },
            });
          } else {
            await MiscLambdaFetch("/add_streaming_plan_proposal", {
              method: "POST",
              body: {
                company,
                plan: combinedDataStripNewKey,
                planName: newName ? newName : currentDropdownName,
              },
            });
          }
          setSaving(false);
          setModalActive(false);
          setNetworkMap(undefined);
          setFormData({});
          setNewChanges({});
          fetchPlans();
        } catch (e) {
          setSaving(false);

          let error = e as Error;
          setError({ message: error.message, reportError: error });
        }
      } else {
        const error = new Error("A company is required to save a new proposal.");
        setError({ message: error.message, reportError: error });
      }
    },
    [isExistingClient, company, fetchPlans, combinedData, currentDropdownName, setError]
  );

  // Save New Plan (Modal)
  const SaveNewPlanModal = () => {
    const [name, setName] = useState<string>();
    return (
      <Modal show onHide={() => setModalActive(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save current changes as a new plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>New Plan Name:</Form.Label>
            <Form.Control
              onChange={e => {
                let currentValue = e.target.value;
                setName(currentValue);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <BPMButton
            variant="primary"
            disabled={R.isNil(name) || name === ""}
            onClick={() => {
              setModalActive(false);
              savePlan(name);
            }}
          >
            Save
          </BPMButton>
        </Modal.Footer>
      </Modal>
    );
  };

  // Create Data for BPMTable
  useEffect(() => {
    if (!R.isEmpty(cpmMap)) {
      let summaryArray: TableRow[] = [];
      for (let id of R.keys(combinedData)) {
        if (!combinedData[id].deletedRow) {
          const {
            network,
            spend,
            description,
            idString,
            notes,
            newRow,
            cpm,
            feeExclusive,
            weeklySpendMultiplier,
            clearance,
            dsp,
            shortCode,
          } = combinedData[id];
          const beeswaxCPM = dsp === "Beeswax" ? BEESWAX_FEE : 0;
          const tremorCPM = dsp === "Tremor" ? cpm / 10 : 0;
          const bpmFeePercentage = commissionFee / 100;
          const effectiveCPM = feeExclusive
            ? cpm
            : cpm + adServingFee + bpmFeePercentage * cpm + beeswaxCPM + tremorCPM;
          const impressionsEstimate =
            ((Math.round(spend / 100) * 100) / (feeExclusive ? cpm : effectiveCPM)) * 1000;
          const mediaSpend = Math.round((impressionsEstimate * cpm) / 1000 / 100) * 100;
          const impressions = Math.round((mediaSpend / cpm) * 1000);
          const beeswaxFee = (impressions * beeswaxCPM) / 1000;
          const tremorFee = (impressions * tremorCPM) / 1000;
          const adServing = (impressions * adServingFee) / 1000;
          const bpmFee = mediaSpend * bpmFeePercentage;
          const totalSpend = mediaSpend + adServing + bpmFee + beeswaxFee + tremorFee;
          const percentageSpend = Math.floor(
            ((R.defaultTo(0, totalSpend) *
              (!isExistingClient && weeklySpendMultiplier ? weeklySpendMultiplier : 1)) /
              totalSpendCombined) *
              100
          );
          const ecpm = (totalSpend / impressions) * 1000;

          summaryArray.push({
            adServing,
            beeswaxFee,
            bpmFee,
            clearance,
            cpm,
            description,
            dsp,
            ecpm,
            feeExclusive,
            idString,
            impressions,
            mediaSpend,
            network,
            newRow,
            notes,
            percentageSpend,
            shortCode,
            spend,
            totalSpend: R.defaultTo(0, totalSpend),
            tremorFee,
            weeklySpendMultiplier,
          });
        }
      }
      setSummaryTableData(summaryArray);
    }
  }, [
    adServingFee,
    combinedData,
    commissionFee,
    company,
    cpmMap,
    isExistingClient,
    totalSpendCombined,
  ]);

  // Sum of spend of each row in combinedData
  useEffect(() => {
    setTotalSpendCombined(() => {
      let output = 0;
      for (let id of R.keys(combinedData)) {
        if (!combinedData[id].deletedRow) {
          let { totalSpend, weeklySpendMultiplier } = combinedData[id];
          output += R.defaultTo(0, totalSpend) * R.defaultTo(1, weeklySpendMultiplier);
        }
      }
      return output;
    });
  }, [combinedData, company, isExistingClient]);

  // Table Totals Row
  const finalRow: Partial<FinalRow> | undefined = useMemo(() => {
    if (!summaryTableData.length) {
      return;
    }

    let mediaSpendTotal = 0;
    let adServingTotal = 0;
    let bpmFeeTotal = 0;
    let totalImpressions = 0;
    let totalCPM = 0;
    let totalECPM = 0;
    let arrayLength = 0;
    let beeswaxFeeTotal = 0;
    let tremorFeeTotal = 0;

    for (let network of summaryTableData) {
      mediaSpendTotal += network.mediaSpend * R.defaultTo(1, network.weeklySpendMultiplier);
      adServingTotal += network.adServing * R.defaultTo(1, network.weeklySpendMultiplier);
      bpmFeeTotal += network.bpmFee * R.defaultTo(1, network.weeklySpendMultiplier);
      totalImpressions += network.impressions * R.defaultTo(1, network.weeklySpendMultiplier);
      totalCPM += network.cpm;
      totalECPM += network.ecpm;
      beeswaxFeeTotal += network.beeswaxFee * R.defaultTo(1, network.weeklySpendMultiplier);
      tremorFeeTotal += network.tremorFee * R.defaultTo(1, network.weeklySpendMultiplier);
      arrayLength += 1;
    }

    let averageCPM = totalCPM / arrayLength;
    let averageECPM = totalECPM / arrayLength;

    let finalRow: Partial<FinalRow> = {
      cpm: `$${averageCPM.toFixed(2)}`,
      ecpm: `$${averageECPM.toFixed(2)}`,
      impressions: `${Math.round(totalImpressions).toLocaleString()}`,
      mediaSpend: `$${Math.round(mediaSpendTotal).toLocaleString()}`,
      adServing: `$${Math.round(adServingTotal).toLocaleString()}`,
      bpmFee: `$${Math.round(bpmFeeTotal).toLocaleString()}`,
      totalSpend: `$${(
        Math.round(mediaSpendTotal) +
        Math.round(adServingTotal) +
        Math.round(bpmFeeTotal) +
        Math.round(beeswaxFeeTotal) +
        Math.round(tremorFeeTotal)
      ).toLocaleString()}`,
      beeswaxFee: `$${Math.round(beeswaxFeeTotal).toLocaleString()}`,
      tremorFee: `$${Math.round(tremorFeeTotal).toLocaleString()}`,
    };
    return finalRow;
  }, [summaryTableData]);

  const totalsRenderer: CellRenderer<Element | number | string | undefined> = ({
    data,
    style = {},
    classes = [],
  }) => {
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  // Download CSV
  const downloadCSV = () => {
    let mediaSpendTotal = 0;
    let adServingTotal = 0;
    let bpmFeeTotal = 0;
    let totalSpendTotal = 0;
    let totalImpressions = 0;
    let totalCPM = 0;
    let totalECPM = 0;
    let beeswaxFeeTotal = 0;
    let tremorFeeTotal = 0;
    let arrayLength = 0;

    let csvSummary = summaryTableData.map(networkObj => {
      const {
        network,
        description,
        clearance,
        cpm,
        ecpm,
        impressions,
        feeExclusive,
        weeklySpendMultiplier,
        mediaSpend,
        adServing,
        bpmFee,
        beeswaxFee,
        tremorFee,
        totalSpend,
        percentageSpend,
        notes,
      } = networkObj;

      mediaSpendTotal += mediaSpend * R.defaultTo(1, weeklySpendMultiplier);
      adServingTotal += adServing * R.defaultTo(1, weeklySpendMultiplier);
      bpmFeeTotal += bpmFee * R.defaultTo(1, weeklySpendMultiplier);
      totalImpressions += impressions * R.defaultTo(1, weeklySpendMultiplier);
      totalCPM += cpm;
      totalECPM += ecpm;
      beeswaxFeeTotal += beeswaxFee * R.defaultTo(1, weeklySpendMultiplier);
      tremorFeeTotal += tremorFee * R.defaultTo(1, weeklySpendMultiplier);
      arrayLength++;

      if (!isExistingClient) {
        return {
          Network: network,
          Description: description,
          Clearance: clearance ? `${clearance}%` : "",
          CPM: `$${Math.round(cpm).toLocaleString()}`,
          eCPM: `$${Math.round(ecpm).toLocaleString()}`,
          Impressions: `${Math.round(impressions).toLocaleString()}`,
          "Fee Type": feeExclusive ? "Exclusive" : "Inclusive",
          "Weekly Spend Multiplier": `${weeklySpendMultiplier}`,
          "Bookable Media Spend": `$${Math.round(mediaSpend).toLocaleString()}`,
          "Ad Serving Fee": `$${Math.round(adServing).toLocaleString()}`,
          "Agency Fee": `$${Math.round(bpmFee).toLocaleString()}`,
          "Beeswax Fee": `$${Math.round(beeswaxFee).toLocaleString()}`,
          "Tremor Fee": `$${Math.round(tremorFee).toLocaleString()}`,
          "Total Spend": `$${Math.round(totalSpend).toLocaleString()}`,
          "Percentage Spend": `${Math.round(percentageSpend).toLocaleString()}%`,
          Notes: notes,
        };
      }

      return {
        Network: network,
        Description: description,
        Notes: notes,
        CPM: `$${Math.round(cpm).toLocaleString()}`,
        Impressions: `${Math.round(impressions).toLocaleString()}`,
        "Media Spend": `$${Math.round(mediaSpend).toLocaleString()}`,
        "Ad Serving Fee": `$${Math.round(adServing).toLocaleString()}`,
        "Agency Fee": `$${Math.round(bpmFee).toLocaleString()}`,
        "Total Spend": `$${Math.round(totalSpend).toLocaleString()}`,
      };
    });

    let averageCPM = totalCPM / arrayLength;
    let averageECPM = totalECPM / arrayLength;

    if (!isExistingClient) {
      csvSummary.push({
        Network: "",
        Description: "",
        Clearance: "",
        "Fee Type": "",
        "Weekly Spend Multiplier": "",
        CPM: `$${averageCPM.toFixed(2)}`,
        eCPM: `$${averageECPM.toFixed(2)}`,
        Impressions: `${Math.round(totalImpressions).toLocaleString()}`,
        "Bookable Media Spend": `$${Math.round(mediaSpendTotal).toLocaleString()}`,
        "Ad Serving Fee": `$${Math.round(adServingTotal).toLocaleString()}`,
        "Agency Fee": `$${Math.round(bpmFeeTotal).toLocaleString()}`,
        "Beeswax Fee": `$${Math.round(beeswaxFeeTotal).toLocaleString()}`,
        "Tremor Fee": `$${Math.round(tremorFeeTotal).toLocaleString()}`,
        "Total Spend": `$${(
          Math.round(mediaSpendTotal) +
          Math.round(adServingTotal) +
          Math.round(bpmFeeTotal) +
          Math.round(beeswaxFeeTotal) +
          Math.round(tremorFeeTotal)
        ).toLocaleString()}`,
        "Percentage Spend": "",
        Notes: "",
      });
    } else {
      csvSummary.push({
        Network: "",
        Description: "",
        Notes: "",
        CPM: `$${averageCPM.toFixed(2)}`,
        Impressions: `${Math.round(totalImpressions).toLocaleString()}`,
        "Media Spend": `$${Math.round(mediaSpendTotal).toLocaleString()}`,
        "Ad Serving Fee": `$${Math.round(adServingTotal).toLocaleString()}`,
        "Agency Fee": `$${Math.round(bpmFeeTotal).toLocaleString()}`,
        "Total Spend": `$${Math.round(totalSpendTotal).toLocaleString()}`,
      });
    }

    downloadJSONToCSV(csvSummary, `${company}_${currentDropdownName}`);
  };

  const filteredLines = useMemo(
    () =>
      R.filter(item => {
        return filter(item);
      }, summaryTableData),
    [summaryTableData, filter]
  );

  const updateWeeklyMultiplier = (data: FormData, newValue: number) => {
    setNewChanges(current => {
      return {
        ...current,
        [data.idString]: {
          ...data,
          weeklySpendMultiplier: newValue > 0 ? newValue : 1,
        },
      };
    });
  };

  useEffect(() => {
    let currentKey = `${formData.network}_${formData.description ? formData.description : ""}`;
    if (clearanceMap[currentKey]) {
      const bookedSpend = clearanceMap[currentKey].booked.adjusted.spend;
      const clearedSpend = clearanceMap[currentKey].cleared.adjusted.spend;
      // If we haven't booked anything, make clearance null so we can allow the user to enter their own
      const pacingClearance = bookedSpend
        ? Math.round(bookedSpend > 0 && clearedSpend ? (100 * clearedSpend) / bookedSpend : 0)
        : null;

      setPacingClearance(pacingClearance);
      if (!formData.clearance && !R.isNil(pacingClearance)) {
        setFormData(current => {
          return { ...current, clearance: pacingClearance };
        });
      }
    }
  }, [clearanceMap, formData.clearance, formData.description, formData.network]);

  const [resultID, setResultID] = useState<string | undefined>(undefined);
  const [generating, setGenerating] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string | undefined>(undefined);
  const [googleSlidesLink, setGoogleSlidesLink] = useState<string | undefined>(undefined);
  const [altDownloadLink, setAltDownloadLink] = useState<string | undefined>(undefined);

  const addS3Image: S3PromiseFunction = pngString =>
    new Promise((resolve, reject) => {
      const id = uuid.v4();
      const key = `${id}.png`;
      let s3Queue: Promise<void>[] = [];
      s3Queue.push(
        new Promise(queueResolve => {
          S3PutPng(SLIDE_ASSET_S3_BUCKET, key, pngString)
            .then(() => GetS3SignedUrl(`${SLIDE_ASSET_S3_BUCKET}/${key}`))
            .then(url => {
              resolve(url);
              queueResolve();
            })
            .catch(reject);
        })
      );
    });

  const generateChartURL = useCallback(
    async (chartData: any[], yKey: string, xKey: string, isSpend?: boolean): Promise<string> => {
      const divElement = document.createElement("div");
      let { pngURI }: { pngURI: string } = await new Promise(resolve => {
        const load = () => {
          ReactDOM.render(
            <Provider store={reduxStore}>
              <AppendixBarChart
                chartData={chartData}
                yKey={yKey}
                xKey={xKey}
                isSpend={isSpend}
                onLoad={({ elem }) => {
                  if (elem) {
                    // let boundingBox = elem.getBoundingClientRect();
                    let pngURI = convertSVGStringToPNGURI(elem.outerHTML);
                    resolve({
                      pngURI,
                    });
                  }
                }}
              />
            </Provider>,
            divElement
          );
        };
        load();
      });
      const chartURL = await addS3Image(pngURI);
      return chartURL;
    },
    []
  );

  const generateSlides = useCallback(async () => {
    const definedNetworkMap: Record<string, any> = R.defaultTo({}, networkMap);
    let googleAccessToken = await checkGoogleAccessTokens(setError);
    if (googleAccessToken) {
      setGenerating(true);
      setResultID("");
      setDownloadLink("");
      setAltDownloadLink("");
      setGoogleSlidesLink("");
      let weeks = 1;
      let networkData: any[] = [];
      let totalSpend = 0;
      let totalAdServingFees = 0;
      let totalAgencyFees = 0;
      let totalMediaSpend = 0;
      let totalProgrammaticFees = 0;

      const sortedGoogleData: any = [];
      const sortedLines = R.sort(
        (a, b) => (a.percentageSpend > b.percentageSpend ? 1 : -1),
        filteredLines
      );

      for (let row of sortedLines) {
        if (
          row.impressions &&
          row.network &&
          row.spend &&
          row.percentageSpend &&
          row.weeklySpendMultiplier
        ) {
          const shortCode = R.find(
            key => definedNetworkMap[key].name === row.network,
            R.keys(definedNetworkMap)
          );
          const impressions = Math.round(row.impressions / 100) * 100;
          const spend = Math.round(row.totalSpend / 100) * 100;
          const networkRow = definedNetworkMap[R.defaultTo("", shortCode)];
          const avgFreq = networkRow.avgFreq ? networkRow.avgFreq : Math.random() * 2.5;
          const newRow = {
            // BDNetworkOverview
            description: row.description,
            estimatedImpressions: impressions * row.weeklySpendMultiplier,
            estimatedSpend: spend * row.weeklySpendMultiplier,
            network: row.network,
            shortCode,

            // BDEstimatedImpressionsAndReach,
            weeklyImpressions: Math.round(row.impressions),
            averageWeeklyFrequency: avgFreq,
            weeklyReach: Math.round(avgFreq ? row.impressions / avgFreq : 0),

            // BDTestAndSpendBreakdown
            spend: spend * row.weeklySpendMultiplier,
            impressions: impressions * row.weeklySpendMultiplier,
          };
          networkData.push(newRow);
          const newWeeks = R.defaultTo(0, row.weeklySpendMultiplier);
          weeks = newWeeks > weeks ? newWeeks : weeks;
          totalSpend += Math.round(spend * row.weeklySpendMultiplier);
        }
        totalAdServingFees += Math.round(
          R.defaultTo(0, row.adServing) * R.defaultTo(1, row.weeklySpendMultiplier)
        );
        totalAgencyFees += Math.round(
          R.defaultTo(0, row.bpmFee) * R.defaultTo(1, row.weeklySpendMultiplier)
        );
        totalMediaSpend += Math.round(
          R.defaultTo(0, row.mediaSpend) * R.defaultTo(1, row.weeklySpendMultiplier)
        );
        totalProgrammaticFees += Math.round(
          (R.defaultTo(0, row.beeswaxFee) + R.defaultTo(0, row.tremorFee)) *
            R.defaultTo(1, row.weeklySpendMultiplier)
        );
        const googleDataMap = googleSheetsData;
        if (googleDataMap) {
          const networkData = googleDataMap[row.network];
          if (networkData) {
            if (networkData["M/F"] && networkData["Median Age"] && networkData["Median HHI"]) {
              networkData.percentageSpend = row.percentageSpend;
              sortedGoogleData.push(networkData);
            }
          }
        }
      }
      const medianIndex = Math.floor(sortedGoogleData.length / 2);
      const genderSkew = Math.round(
        R.sum(
          R.map(
            elem => (parseInt(elem["M/F"].split("/")[1]) * elem.percentageSpend) / 100,
            sortedGoogleData
          )
        )
      ); // Using Female Total to calculate M/F Split
      const medianAge = sortedGoogleData[medianIndex]["Median Age"];
      const medianHHI = sortedGoogleData[medianIndex]["Median HHI"];
      const medianHHIRange = sortedGoogleData[medianIndex]["HHI Range"];
      const targetImpressionGroup = `A${
        sortedGoogleData ? sortedGoogleData.slice(-1)[0]["Age Range"] : ""
      }`;

      const ageChartURL = await Promise.resolve(
        generateChartURL(
          R.uniq(
            R.map(elem => {
              const plotData = {
                age: parseInt(elem["Median Age"]),
                network: elem.Network,
              };
              return plotData;
            }, sortedGoogleData)
          ),
          "network",
          "age"
        )
      );
      const HHIChartURL = await Promise.resolve(
        generateChartURL(
          R.uniq(
            R.map(elem => {
              const plotData = {
                hhi: parseFloat(elem["Median HHI"].slice(1, -1)) * 1000, // Data is in thousands
                network: elem.Network,
              };
              return plotData;
            }, sortedGoogleData)
          ),
          "network",
          "hhi",
          true
        )
      );
      const genderChartURL = await Promise.resolve(
        generateChartURL(
          R.uniq(
            R.map(elem => {
              const plotData = {
                genderSkew: parseInt(elem["M/F"].split("/")[1]),
                network: elem.Network,
              };
              return plotData;
            }, sortedGoogleData)
          ),
          "network",
          "genderSkew"
        )
      );

      const BDNetworkOverview = {
        type: "BDNetworkOverview",
        eCPM: R.defaultTo({ cpm: 0 }, finalRow).cpm,
        networkData,
        newClientLogoURL: "",
        weeks,
      };
      const BDEstimatedImpressionsAndReach = {
        type: "BDEstimatedImpressionsAndReach",
        networkData,
        newClientLogoURL: "",
      };
      const BDRoadmapToSteadyStateCampaign = {
        type: "BDRoadmapToSteadyStateCampaign",
        launchDate: Dfns.format(DATE_FORMAT, new Date()),
        launchWeeklySpend: Math.round(totalSpend / weeks),
        postTestWeeklySpendGoal: totalSpend,
        numberOfWeeks: weeks,
        testConclusionDate: Dfns.format(DATE_FORMAT, Dfns.addWeeks(weeks, new Date())),
      };
      const BDStreamingTestDimensions = {
        type: "BDStreamingTestDimensions",
        estimatedCampaignSpend: totalSpend,
        numberOfWeeks: weeks,
        targetImpressionGroup,
      };
      const BDTestAndSpendBreakdown = {
        type: "BDTestAndSpendBreakdown",
        agencyFee: feePercentage,
        networkData,
        newClientLogoURL: "",
        totalAdServingFees,
        totalAgencyFees,
        totalMediaSpend,
        totalProgrammaticFees,
        weeks,
      };
      const BDGenderSkewMetrics = {
        type: "BDGenderSkewMetrics",
        chartURL: genderChartURL,
        genderSkew,
      };
      const BDAgeMetrics = {
        type: "BDAgeMetrics",
        chartURL: ageChartURL,
        medianAge,
        targetImpressionGroup,
      };
      const BDHHIMetrics = {
        type: "BDHHIMetrics",
        chartURL: HHIChartURL,
        medianHHI,
        medianHHIRange,
        totalSpend,
      };
      let slides = [
        BDEstimatedImpressionsAndReach,
        BDNetworkOverview,
        BDRoadmapToSteadyStateCampaign,
        BDStreamingTestDimensions,
        BDTestAndSpendBreakdown,
        BDGenderSkewMetrics,
        BDAgeMetrics,
        BDHHIMetrics,
      ];

      try {
        let res = await SlidesLambdaFetch("/slidesBD", {
          method: "POST",
          body: {
            company: "tinuiti",
            newClientName: company,
            date: Dfns.format(DATE_FORMAT, new Date()),
            access_token: googleAccessToken,
            slides,
          },
        });
        const { id, pptx, pptx_alt, url } = await awaitJSON(res);
        setResultID(id);
        setDownloadLink(pptx);
        setAltDownloadLink(pptx_alt);
        setGoogleSlidesLink(url);
        setGenerating(false);
      } catch (e) {
        setGenerating(false);
        let error = e as Error;
        setError({
          message: error.message,
          reportError: error,
        });
      }
    }
  }, [
    company,
    feePercentage,
    filteredLines,
    finalRow,
    generateChartURL,
    googleSheetsData,
    networkMap,
    setError,
  ]);

  let generateText = "";
  if (generating) {
    generateText = "Generating...";
  } else if (resultID) {
    generateText = "Regenerate Slides!";
  } else {
    generateText = "Generate Slides!";
  }

  return (
    <Page
      title="Streaming Plans Builder"
      pageType="Streaming Plans Builder"
      actions={
        <div className="streamingPlansBuilderActions">
          {!isExistingClient && (
            <SuggestionInput
              label={"Company"}
              value={company}
              placeholder="Select Company..."
              options={R.map(company => {
                return { label: company, value: company };
              }, companyOptions)}
              onChange={company => {
                setFormData({});
                setNewChanges({});
                setSelectedPlan({});
                setSummaryTableData([]);
                setCompany(company);
              }}
              nonEmpty={undefined}
              className="companyInput"
            />
          )}
          {!isExistingClient && (
            <InputGroup className="feeInput">
              <InputGroup.Prepend>
                <InputGroup.Text>Fee %</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                value={Math.round(feePercentage * 100)}
                type="number"
                onChange={e => {
                  let newFee = parseInt(e.target.value) / 100;
                  if (newFee >= 1) {
                    newFee = 1;
                  } else if (newFee <= 0) {
                    newFee = 0;
                  }
                  setFeePercentage(newFee);
                }}
              />
            </InputGroup>
          )}
          {!R.isEmpty(newChanges) && (
            <BPMButton
              variant="outline-danger"
              onClick={() => {
                setNewChanges({});
                setFormData({});
              }}
            >
              <MdDeleteForever />
            </BPMButton>
          )}

          {!R.isEmpty(newChanges) && !R.isEmpty(selectedPlan) && (
            <BPMButton variant="outline-success" onClick={() => savePlan()}>
              {saving ? <Spinner /> : <MdSave />}
            </BPMButton>
          )}

          <BPMButton variant="outline-primary" onClick={() => setModalActive(true)}>
            <MdAdd />
          </BPMButton>

          {!R.isEmpty(existingPlans) && company && (
            <div className="savedPlansDropdown">
              <Select
                value={{ value: `${company}_${currentDropdownName}`, label: currentDropdownName }}
                options={R.filter(option => {
                  return option.value.includes(company);
                }, planDropdownOptions as DropdownOptions[])}
                onChange={e => {
                  setSelectedPlan(existingPlans[e.value]);
                  setCurrentDropdownName(e.label);
                }}
              />
            </div>
          )}
          {!R.isEmpty(existingPlans) && (
            <BPMButton variant="outline-primary" onClick={() => downloadCSV()}>
              <MdFileDownload />
            </BPMButton>
          )}
          {!isExistingClient && company && selectedPlan && (
            <BPMButton
              onClick={generateSlides}
              disabled={generating}
              className="generateSlidesButton"
            >
              {generateText}
            </BPMButton>
          )}
          {!isExistingClient && company && selectedPlan && resultID && (
            <>
              <BPMButton
                as="a"
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className="generateSlidesButton"
              >
                Download
              </BPMButton>
              <BPMButton
                as="a"
                href={googleSlidesLink}
                target="_blank"
                rel="noopener noreferrer"
                className="generateSlidesButton"
              >
                Open with Google Slides
              </BPMButton>
              <BPMButton
                as="a"
                href={altDownloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className="generateSlidesButton"
              >
                Permissions issues? Try this.
              </BPMButton>
            </>
          )}
        </div>
      }
    >
      {networkMap ? (
        <div className="streamingPlansBuilderPage">
          <div className="planBuilderInputs">
            <Form.Group className="networkFormGroup">
              <Form.Label>Network</Form.Label>
              <Select
                value={{ label: formData.network, value: formData.shortCode }}
                options={R.map(
                  (network: StreamingNetwork) => {
                    return { label: network.name, value: network.shortCode };
                  },
                  R.filter(
                    network =>
                      !R.isNil(network.name) &&
                      network.name !== "" &&
                      !R.isNil(network.shortCode) &&
                      network.shortCode !== "",
                    R.values(networkMap)
                  )
                )}
                onChange={selection => {
                  let network = selection.label;
                  let shortCode = selection.value;
                  setFormData(current => {
                    current.dsp = undefined;
                    return {
                      ...current,
                      network,
                      cpm: cpmMap[network],
                      clearance: undefined,
                      shortCode,
                    };
                  });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                value={formData.description || ""}
                onChange={e => {
                  let currentValue = e.target.value;
                  setFormData(current => {
                    return { ...current, description: currentValue, clearance: undefined };
                  });
                }}
              />
            </Form.Group>
            {!isExistingClient &&
              formData.network &&
              formData.shortCode &&
              !R.isEmpty(networkMap[formData.shortCode].dsp) && (
                <Form.Group>
                  <Form.Label>DSP</Form.Label>
                  <ToggleCheckboxes
                    block
                    selectedOptions={formData.dsp ? [formData.dsp] : []}
                    options={R.map(
                      (dsp: string) => {
                        return { label: dsp, key: dsp };
                      },
                      R.filter(
                        dsp => dsp === "Beeswax" || dsp === "Tremor",
                        networkMap[formData.shortCode].dsp
                      )
                    )}
                    onChange={dsp =>
                      setFormData(current => {
                        const newDsp = !R.isEmpty(dsp) ? dsp[dsp.length - 1] : undefined;
                        return { ...current, dsp: newDsp };
                      })
                    }
                  />
                </Form.Group>
              )}
            {!isExistingClient && (
              <Form.Group className="clearanceFormGroup">
                <Form.Label>Clearance</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    value={R.defaultTo(0, R.defaultTo(pacingClearance, formData.clearance))}
                    type="number"
                    disabled={!R.isNil(pacingClearance) && pacingClearance === formData.clearance} // if they are equal we likely didn't set it
                    onChange={e => {
                      const clearance = parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0;
                      setFormData(current => {
                        return { ...current, clearance };
                      });
                    }}
                  />
                </InputGroup>
              </Form.Group>
            )}
            {!isExistingClient && (
              <Form.Group className="feeFormGroup">
                <Form.Label>Fee Type</Form.Label>
                <BPMToggleButton
                  className="feeToggle"
                  options={[
                    {
                      key: "Inclusive",
                    },
                    {
                      key: "Exclusive",
                    },
                  ]}
                  selectedOption={formData.feeExclusive ? "Exclusive" : "Inclusive"}
                  onChange={key =>
                    setFormData(current => {
                      return {
                        ...current,
                        feeExclusive: key === "Exclusive",
                        spend: 0,
                        totalSpend: 0,
                      };
                    })
                  }
                  // size="sm"
                />
              </Form.Group>
            )}
            <Form.Group className="spendFormGroup">
              <div className="formHeaderWithInfo">
                {formData.feeExclusive && <Form.Label>Bookable Spend</Form.Label>}
                {!formData.feeExclusive && <Form.Label>Total Spend</Form.Label>}
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                  overlay={
                    <ExplainerTooltip id={"spendInfoIcon"}>
                      {"Bookable Spend will be rounded to the nearest hundred dollars"}
                    </ExplainerTooltip>
                  }
                >
                  <InfoIcon className="infoIcon" />
                </OverlayTrigger>
              </div>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  value={formData?.spend || ""}
                  type="number"
                  step={"100"}
                  onChange={e => {
                    const spendInput = parseFloat(e.target.value);
                    const spend = spendInput > 0 ? Math.round(spendInput / 100) * 100 : 0;
                    const { cpm, feeExclusive, dsp } = formData;
                    const bpmFeePercentage = commissionFee / 100;
                    let totalSpend = 0;
                    if (spend && cpm) {
                      const beeswaxCPM = dsp === "Beeswax" ? BEESWAX_FEE : 0;
                      const tremorCPM = dsp === "Tremor" ? spend + cpm / 10 : 0;
                      let effectiveCPM = feeExclusive
                        ? cpm
                        : cpm + adServingFee + bpmFeePercentage * cpm + beeswaxCPM + tremorCPM;
                      if (feeExclusive) {
                        let impressions = (spend / effectiveCPM) * 1000;
                        let mediaSpend = spend;
                        let beeswaxFee = (impressions * beeswaxCPM) / 1000;
                        let tremorFee = (impressions * tremorCPM) / 1000;
                        let adServing = (impressions * adServingFee) / 1000;
                        let bpmFee = mediaSpend * bpmFeePercentage;
                        totalSpend = spend + adServing + bpmFee + beeswaxFee + tremorFee;
                      } else {
                        totalSpend = spend;
                      }
                    }
                    setFormData(current => {
                      return { ...current, spend: spendInput, totalSpend };
                    });
                  }}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="cpmFormGroup">
              <Form.Label>CPM</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  value={
                    formData.cpm ? formData.cpm : formData.network ? cpmMap[formData.network] : ""
                  }
                  type="number"
                  onChange={e => {
                    const cpm = parseFloat(e.target.value) > 0 ? parseFloat(e.target.value) : 0;
                    setFormData(current => {
                      return { ...current, cpm };
                    });
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="impFormGroup">
              <div className="formHeaderWithInfo">
                <Form.Label>Impressions</Form.Label>
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                  overlay={
                    <ExplainerTooltip id={"spendInfoIcon"}>
                      {"Based on rounded media spend"}
                    </ExplainerTooltip>
                  }
                >
                  <InfoIcon className="infoIcon" />
                </OverlayTrigger>
              </div>
              <InputGroup>
                <Form.Control
                  value={Math.round(
                    formData.cpm
                      ? ((Math.round((formData?.spend || 0) / 100) * 100) /
                          (formData.cpm +
                            (formData.feeExclusive
                              ? 0
                              : (formData.dsp === "Beeswax" ? BEESWAX_FEE : 0) +
                                (formData.dsp === "Tremor" ? formData.cpm / 10 : 0) +
                                commissionFee / 100 +
                                adServingFee))) *
                          1000
                      : 0
                  )}
                  type="number"
                  disabled
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                value={formData.notes || ""}
                onChange={e => {
                  let currentValue = e.target.value;
                  setFormData(current => {
                    return { ...current, notes: currentValue };
                  });
                }}
              />
            </Form.Group>
            {!isExistingClient && (
              <Form.Group>
                <div className="formHeaderWithInfo">
                  <Form.Label>Total Spend</Form.Label>
                  <OverlayTrigger
                    placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                    overlay={
                      <ExplainerTooltip id={"spendInfoIcon"}>
                        {`Based on rounded media spend of: ${
                          Math.round((formData.spend || 0) / 100) * 100
                        }`}
                      </ExplainerTooltip>
                    }
                  >
                    <InfoIcon className="infoIcon" />
                  </OverlayTrigger>
                </div>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    value={Math.round(R.defaultTo(0, formData.totalSpend))}
                    type="number"
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            )}

            <Form.Group>
              {selectedRowId ? (
                <div className="editRowButtons">
                  <BPMButton
                    variant="outline-danger"
                    onClick={() => {
                      setSelectedRowId(undefined);
                      setFormData({});
                    }}
                  >
                    <MdCancel />
                  </BPMButton>
                  <BPMButton variant="outline-success" onClick={() => updateRow()}>
                    <MdSystemUpdateAlt />
                  </BPMButton>
                </div>
              ) : (
                <BPMButton onClick={addRow} disabled={!formData.network || !formData.spend}>
                  <MdAdd />
                </BPMButton>
              )}
            </Form.Group>
          </div>

          <div className="filterBar">
            <OldFilterBar
              options={FILTER_OPTIONS}
              lines={summaryTableData}
              onFilter={newFilter => setFilter(newFilter)}
            />
          </div>

          <BPMTable<TableRow | FinalRow>
            data={filteredLines}
            filterBar={false}
            rowHeight={45}
            headerHeight={50}
            totals={finalRow}
            totalsRenderer={totalsRenderer}
            headers={[
              {
                label: "Network",
                name: "network",
                width: 175,
                nonInteractive: true,
                flex: 1,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };
                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.network}
                    </div>
                  );
                },
              },
              {
                label: "Description",
                name: "description",
                flex: 1,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.description}
                    </div>
                  );
                },
              },
              {
                label: "Clearance",
                name: "clearance",
                width: 80,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    dsp: data.dsp,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }

                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.clearance && data.clearance !== 0 ? (
                        data.clearance < 50 ? (
                          <div className="warning">
                            <MdWarning />
                            <>{`${data.clearance}%`}</>
                          </div>
                        ) : (
                          <>{`${data.clearance}%`}</>
                        )
                      ) : (
                        <Form.Control
                          value={data.clearance}
                          type="number"
                          onChange={e => {
                            let currentValue = parseInt(e.target.value);
                            data.clearance = currentValue;
                            copiedData.clearance = currentValue;
                            setFormData(copiedData);
                          }}
                          size="sm"
                        />
                      )}
                    </div>
                  );
                },
              },
              {
                label: "CPM",
                name: "cpm",
                width: 50,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {`$${data.cpm.toLocaleString()}`}
                    </div>
                  );
                },
              },
              {
                label: "eCPM",
                name: "ecpm",
                width: 50,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {`$${data.ecpm.toLocaleString()}`}
                    </div>
                  );
                },
              },
              {
                label: "Impressions",
                name: "impressions",
                width: 100,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {`${Math.round(data.impressions).toLocaleString()}`}
                    </div>
                  );
                },
              },
              {
                label: "Fee Type",
                name: "feetype",
                width: 100,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.feeExclusive ? "Exclusive" : "Inclusive"}
                    </div>
                  );
                },
              },
              {
                label: "# Weeks",
                name: "weeklyspendmultiplier",
                width: 60,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      <Form.Control
                        value={copiedData.weeklySpendMultiplier || 1}
                        type="number"
                        onChange={e => {
                          let currentValue = parseInt(e.target.value);
                          updateWeeklyMultiplier(copiedData, currentValue);
                        }}
                        size="sm"
                      />
                    </div>
                  );
                },
              },
              {
                label: "Bookable Media Spend",
                name: "mediaSpend",
                width: 150,
                nonInteractive: true,
                flex: 1,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {`$${Math.round(data.mediaSpend).toLocaleString()}`}
                    </div>
                  );
                },
              },
              {
                label: "Ad Serving Fee",
                name: "adServing",
                width: 80,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.adServing === 0
                        ? "-"
                        : `$${Math.round(data.adServing).toLocaleString()}`}
                    </div>
                  );
                },
              },
              {
                label: "Agency Fee",
                name: "bpmFee",
                width: 80,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.bpmFee === 0 ? "-" : `$${Math.round(data.bpmFee).toLocaleString()}`}
                    </div>
                  );
                },
              },
              {
                label: "Beeswax Fee",
                name: "beeswaxFee",
                width: 80,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.dsp === "Beeswax"
                        ? `$${Math.round(
                            (data.mediaSpend / data.cpm) * BEESWAX_FEE
                          ).toLocaleString()}`
                        : "-"}
                    </div>
                  );
                },
              },
              {
                label: "Tremor Fee",
                name: "tremorFee",
                width: 80,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.dsp === "Tremor"
                        ? `$${Math.round(
                            (data.mediaSpend / data.cpm) * (TREMROR_FEE * data.cpm)
                          ).toLocaleString()}`
                        : "-"}
                    </div>
                  );
                },
              },
              {
                label: "Total Spend",
                name: "totalSpend",
                width: 100,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {`$${(
                        Math.round(
                          (data.totalSpend * (!isExistingClient ? data.weeklySpendMultiplier : 1)) /
                            100
                        ) * 100
                      ).toLocaleString()}`}
                    </div>
                  );
                },
              },
              {
                label: "Percentage Spend",
                name: "percentageSpend",
                width: 100,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {`${data.percentageSpend}%`}
                    </div>
                  );
                },
              },
              {
                label: "Notes",
                name: "notes",
                flex: 1,
                nonInteractive: true,
                renderer: data => {
                  let copiedData: FormData = {
                    network: data.network,
                    spend: data.spend,
                    description: data.description,
                    idString: data.idString,
                    cpm: data.cpm,
                    notes: data.notes,
                    feeExclusive: data.feeExclusive,
                    weeklySpendMultiplier: data.weeklySpendMultiplier,
                    clearance: data.clearance,
                    totalSpend: data.totalSpend,
                    shortCode: data.shortCode,
                    dsp: data.dsp,
                  };

                  let classes: string[] = ["dataCell"];
                  if (copiedData.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  if (data.newRow) {
                    classes.push("newRow");
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={() => {
                        setFormData(copiedData);
                        setSelectedRowId(copiedData.idString);
                      }}
                    >
                      {data.notes}
                    </div>
                  );
                },
              },
              {
                label: "",
                width: 75,
                nonInteractive: true,
                flex: 1,
                renderer: data => {
                  let classes: string[] = ["dataCell"];
                  if (data.idString === selectedRowId) {
                    classes.push("selectedRow");
                  }
                  return (
                    <div className={classes.join(" ")}>
                      <BPMButton
                        variant="danger"
                        size="sm"
                        onClick={() => deleteRow(data.idString)}
                      >
                        <MdDeleteForever />
                      </BPMButton>
                    </div>
                  );
                },
              },
            ].filter(
              element =>
                (company &&
                  element.name !== "feetype" &&
                  element.name !== "weeklyspendmultiplier" &&
                  element.name !== "clearance") ||
                (!isExistingClient && element.name !== "notes")
            )}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}
      {modalActive && <SaveNewPlanModal />}
    </Page>
  );
};

export default StreamingPlansBuilder;
