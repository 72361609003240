import React, { useState, useMemo, useEffect } from "react";
import * as R from "ramda";
import cn from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSetError } from "../redux/modals";
import { Spinner } from "./Spinner";
import "./CbsaMap.scss";

// 2023 US Census delineation files have comparable codes between MSAs and CBSAs
// https://www.census.gov/geographies/reference-files/time-series/demo/metro-micro/delineation-files.html
export const CBSA_TO_MSA_MAP = {
  "Anniston-Oxford, AL": "Anniston-Oxford-Jacksonville, AL",
  "Austin-Round Rock-San Marcos, TX": "Austin-Round Rock, TX",
  "Bakersfield-Delano, CA": "Bakersfield, CA",
  "Bend, OR": "Bend-Redmond, OR",
  "Birmingham, AL": "Birmingham-Hoover, AL",
  "Bremerton-Silverdale-Port Orchard, WA": "Bremerton-Silverdale, WA",
  "Bridgeport-Stamford-Danbury, CT": "Bridgeport-Stamford-Norwalk, CT",
  "Brunswick-St. Simons, GA": "Brunswick, GA",
  "Buffalo-Cheektowaga, NY": "Buffalo-Cheektowaga-Niagara Falls, NY",
  "Chambersburg, PA": "Chambersburg-Waynesboro, PA",
  "Chicago-Naperville-Elgin, IL-IN": "Chicago-Naperville-Elgin, IL-IN-WI",
  "Dayton-Kettering-Beavercreek, OH": "Dayton-Kettering, OH",
  "Denver-Aurora-Centennial, CO": "Denver-Aurora-Lakewood, CO",
  "Elizabethtown, KY": "Elizabethtown-Fort Knox, KY",
  "Eugene-Springfield, OR": "Eugene, OR",
  "Evansville, IN": "Evansville, IN-KY",
  "Fairbanks-College, AK": "Fairbanks, AK",
  "Fayetteville-Springdale-Rogers, AR": "Fayetteville-Springdale-Rogers, AR-MO",
  "Fort Collins-Loveland, CO": "Fort Collins, CO",
  "Grand Rapids-Wyoming-Kentwood, MI": "Grand Rapids-Wyoming, MI",
  "Greenville-Anderson-Greer, SC": "Greenville-Anderson-Mauldin, SC",
  "Gulfport-Biloxi, MS": "Gulfport-Biloxi-Pascagoula, MS",
  "Hilton Head Island-Bluffton-Port Royal, SC": "Hilton Head Island-Bluffton-Beaufort, SC",
  "Houma-Bayou Cane-Thibodaux, LA": "Houma-Thibodaux, LA",
  "Houston-Pasadena-The Woodlands, TX": "Houston-The Woodlands-Sugar Land, TX",
  "Indianapolis-Carmel-Greenwood, IN": "Indianapolis-Carmel-Anderson, IN",
  "Joplin, MO-KS": "Joplin, MO",
  "Kahului-Wailuku, HI": "Kahului-Wailuku-Lahaina, HI",
  "Kingsport-Bristol, TN-VA": "Kingsport-Bristol-Bristol, TN-VA",
  "Las Vegas-Henderson-North Las Vegas, NV": "Las Vegas-Henderson-Paradise, NV",
  "Longview-Kelso, WA": "Longview, WA",
  "Mankato, MN": "Mankato-North Mankato, MN",
  "Milwaukee-Waukesha, WI": "Milwaukee-Waukesha-West Allis, WI",
  "Muskegon-Norton Shores, MI": "Muskegon, MI",
  "Myrtle Beach-Conway-North Myrtle Beach, SC": "Myrtle Beach-Conway-North Myrtle Beach, SC-NC",
  "Naples-Marco Island, FL": "Naples-Immokalee-Marco Island, FL",
  "New Haven, CT": "New Haven-Milford, CT",
  "New York-Newark-Jersey City, NY-NJ": "New York-Newark-Jersey City, NY-NJ-PA",
  "Niles, MI": "Niles-Benton Harbor, MI",
  "North Port-Bradenton-Sarasota, FL": "North Port-Sarasota-Bradenton, FL",
  "Norwich-New London-Willimantic, CT": "Norwich-New London, CT",
  "Ogden, UT": "Ogden-Clearfield, UT",
  "Olympia-Lacey-Tumwater, WA": "Olympia-Tumwater, WA",
  "Omaha, NE-IA": "Omaha-Council Bluffs, NE-IA",
  "Panama City-Panama City Beach, FL": "Panama City, FL",
  "Phoenix-Mesa-Chandler, AZ": "Phoenix-Mesa-Scottsdale, AZ",
  "Provo-Orem-Lehi, UT": "Provo-Orem, UT",
  "Racine-Mount Pleasant, WI": "Racine, WI",
  "Raleigh-Cary, NC": "Raleigh, NC",
  "Sacramento-Roseville-Folsom, CA": "Sacramento--Roseville--Arden-Arcade, CA",
  "Salisbury, MD": "Salisbury, MD-DE",
  "Salt Lake City-Murray, UT": "Salt Lake City, UT",
  "San Diego-Chula Vista-Carlsbad, CA": "San Diego-Carlsbad, CA",
  "San Francisco-Oakland-Fremont, CA": "San Francisco-Oakland-Hayward, CA",
  "San Luis Obispo-Paso Robles, CA": "San Luis Obispo-Paso Robles-Arroyo Grande, CA",
  "Santa Rosa-Petaluma, CA": "Santa Rosa, CA",
  "Scranton--Wilkes-Barre, PA": "Scranton--Wilkes-Barre--Hazleton, PA",
  "Sebastian-Vero Beach-West Vero Corridor, FL": "Sebastian-Vero Beach, FL",
  "Sioux Falls, SD-MN": "Sioux Falls, SD",
  "Staunton-Stuarts Draft, VA": "Staunton-Waynesboro, VA",
  "Trenton-Princeton, NJ": "Trenton, NJ",
  "Vallejo, CA": "Vallejo-Fairfield, CA",
  "Vineland, NJ": "Vineland-Bridgeton, NJ",
  "Virginia Beach-Chesapeake-Norfolk, VA-NC": "Virginia Beach-Norfolk-Newport News, VA-NC",
  "Visalia, CA": "Visalia-Porterville, CA",
  "Wenatchee-East Wenatchee, WA": "Wenatchee, WA",
  "Worcester, MA": "Worcester, MA-CT",
  "Youngstown-Warren, OH": "Youngstown-Warren-Boardman, OH-PA",
};

export const NONCONTINENTAL_CBSAS = {
  ALASKA: ["Anchorage, AK", "Fairbanks-College, AK"],
  HAWAII: ["Kahului-Wailuku, HI", "Urban Honolulu, HI"],
};

type CBSAName = string;
type CBSAPath = string;
type CBSAPathMap = Record<CBSAName, CBSAPath>;

interface CbsaMapProps {
  renderColor?: (cbsa: string) => string;
  renderTooltip?: (cbsa: CBSAName) => React.ReactNode;
  selection?: CBSAName;
  onPathLoad?: (loaded: boolean) => void;
}

export const CbsaMap = React.forwardRef<SVGSVGElement | null, CbsaMapProps>(
  ({ renderColor, renderTooltip = R.identity, onPathLoad }, ref) => {
    const setError = useSetError();
    const [cbsaPaths, setCbsaPaths] = useState<CBSAPathMap>();
    const [stateOverlayPaths, setStateOverlayPaths] = useState<string>();
    const [pathsLoading, setPathsLoading] = useState(false);

    useEffect(() => {
      if (!cbsaPaths && !pathsLoading) {
        (async () => {
          setPathsLoading(true);

          try {
            // Data originally pulled from US Census at: https://www.census.gov/cgi-bin/geo/shapefiles/index.php?year=2023&layergroup=Core+Based+Statistical+Areas
            // And then processed with guidance from: https://medium.com/@mbostock/command-line-cartography-part-1-897aa8f8ca2c
            // And filtered out micropolitans which do not have corresponding data from TransUnion
            const cbsaData = await fetch("https://cdn.blisspointmedia.com/geo/CbsaPaths.json");
            const cbsaJSON = await cbsaData.json();
            setCbsaPaths(cbsaJSON);

            // Data originally pulled from US Census at: https://www.census.gov/cgi-bin/geo/shapefiles/index.php?year=2023&layergroup=States+%28and+equivalent%29
            // Then removed additional US territories which do not include MSAs using https://mapshaper.org/
            const stateOverlayData = await fetch(
              "https://cdn.blisspointmedia.com/geo/StatePaths.json"
            );
            const stateOverlayJSON = await stateOverlayData.json();
            setStateOverlayPaths(stateOverlayJSON);
          } catch (e: any) {
            setError({
              message: `Failed to get cbsa path information: ${e.message}`,
              reportError: e,
            });
          }

          setPathsLoading(false);
          if (onPathLoad) {
            onPathLoad(true);
          }
        })();
      }
    }, [pathsLoading, cbsaPaths, setError, onPathLoad]);

    const cbsaMap = useMemo(
      () =>
        cbsaPaths && renderColor
          ? R.keys(cbsaPaths).map(cbsa => {
              return (
                <OverlayTrigger
                  key={cbsa}
                  placement="bottom"
                  overlay={<Tooltip id="cbsa-tooltip">{renderTooltip(cbsa)}</Tooltip>}
                >
                  <path
                    className={cn(
                      "cbsaPath",
                      { alaska: NONCONTINENTAL_CBSAS.ALASKA.includes(cbsa) },
                      { hawaii: NONCONTINENTAL_CBSAS.HAWAII.includes(cbsa) }
                    )}
                    d={cbsaPaths[cbsa]}
                    fill={renderColor(cbsa)}
                  />
                </OverlayTrigger>
              );
            })
          : null,
      [cbsaPaths, renderColor, renderTooltip]
    );

    const stateOverlayMap = useMemo(
      () =>
        stateOverlayPaths
          ? R.keys(stateOverlayPaths).map(state => (
              <path
                className={cn(
                  "statePath",
                  { alaska: state === "Alaska" },
                  { hawaii: state === "Hawaii" }
                )}
                key={state}
                d={stateOverlayPaths[state]}
                fill="transparent"
              />
            ))
          : null,
      [stateOverlayPaths]
    );

    return (
      <div className="cbsaMap">
        {pathsLoading || !cbsaMap ? (
          <Spinner />
        ) : (
          <svg ref={ref} viewBox="350 85 460 270">
            {stateOverlayMap}
            {cbsaMap}
          </svg>
        )}
      </div>
    );
  }
);
