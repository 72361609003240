import React, { useContext, useEffect, useState, useCallback } from "react";
import { FormContext, metaCampaignFormDefault } from "../MetaBuyingContext";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { RouteComponentProps } from "@reach/router";
import {
  selectionsToArray,
  filterSegmentationIds,
  generateNameFields,
  setNameField as setNameFieldUtil,
} from "../MetaBuyingUtils";
import { MetaBuyingTabs } from "../MetaBuyingTabs";
import * as R from "ramda";

const REQUIRED_FIELDS_CAMPAIGN = {
  objective: "Campaign Objective",
  budget_type: "Budget Type",
};

const BUDGET_TYPES_CAMPAIGN = [
  {
    label: "Ad Set Budget",
    value: "AD_SET_BUDGET",
  },
  {
    label: "Campaign Daily Budget",
    value: "CAMPAIGN_DAILY_BUDGET",
  },
  {
    label: "Campaign Lifetime Budget",
    value: "CAMPAIGN_LIFETIME_BUDGET",
  },
];

const BID_STRATEGIES = [
  {
    label: "Lowest Cost w/out Cap",
    value: "LOWEST_COST_WITHOUT_CAP",
  },
  {
    label: "Lowest Cost w/ Bid Cap",
    value: "LOWEST_COST_WITH_BID_CAP",
  },
  {
    label: "Cost Cap",
    value: "COST_CAP",
  },
  {
    label: "Lowest Cost w/ Min. ROAS",
    value: "LOWEST_COST_WITH_MIN_ROAS",
  },
];

const OBJECTIVE_TYPES = [
  {
    label: "Outcome Awareness",
    value: "OUTCOME_AWARENESS",
  },
  {
    label: "Outcome Sales",
    value: "OUTCOME_SALES",
  },
  {
    label: "Outcome Traffic",
    value: "OUTCOME_TRAFFIC",
  },
];

const SPECIAL_AD_CATEGORIES = [
  {
    label: "None",
    value: "NONE",
  },
  {
    label: "Employment",
    value: "EMPLOYMENT",
  },
  {
    label: "Housing",
    value: "HOUSING",
  },
  {
    label: "Credit",
    value: "CREDIT",
  },
  {
    label: "Issues/Elections/Politics",
    value: "ISSUES_ELECTIONS_POLITICS",
  },
  {
    label: "Online Gambling & Gaming",
    value: "ONLINE_GAMBLING_AND_GAMING",
  },
  {
    label: "Financial Products/Services",
    value: "FINANCIAL_PRODUCTS_SERVICES",
  },
];

const OPTIONS_NOTE_CARD_HEADER = "Campaign Options to Populate in Meta";
const OPTIONS_NOTE_CARD_TEXT =
  "A list of the objectives that people will not be able to set campaigns for in our tool";
const OPTIONS_NOTE_CARD_OPTIONS = ["App Promotion", "Engagements", "Leads"];

interface CampaignPayload {
  client: string;
  business_name: string;
  ad_account_id: string;
  name: string;
  objective: string;
  special_ad_categories: string[];
  lifetime_budget?: number;
  daily_budget?: number;
  spend_cap?: number;
  bid_strategy?: string;
}

interface MetaBuyingCampaignProps {
  client: string;
  namingConvention: number[];
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  validateForm: (
    form: Record<string, any>,
    validator: () => Record<string, any>,
    type: string,
    navigate: (args: boolean | string) => void,
    navigateArgs?: boolean | string
  ) => void;
  creationTabs: {
    Campaign: boolean;
    "Ad Set": boolean;
    Ad: boolean;
  };
  granularity: string;
  dimensions: Record<string, any>;
  allNameFields: Record<string, any>;
  setAllNameFields: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const { CLIENT, AGENCY, SEGMENTATION_ID, SEGMENT_SELECTION } = {
  CLIENT: "CLIENT",
  AGENCY: "AGENCY",
  SEGMENTATION_ID: "SEGMENTATION_ID",
  SEGMENT_SELECTION: "SEGMENT_SELECTION",
};

export const MetaBuyingCampaign = ({
  selectedAdAccount,
  client,
  namingConvention,
  tabIndex,
  setTabIndex,
  showReviewModal,
  validateForm,
  creationTabs,
  granularity,
  dimensions,
  allNameFields,
  setAllNameFields,
}: MetaBuyingCampaignProps & RouteComponentProps): JSX.Element => {
  const { metaCampaignForm, setMetaCampaignForm } = useContext(FormContext);

  const agency = selectedAdAccount.business_manager;

  const segmentationDimensionId = R.keys(
    R.filter(dimension => dimension.type === SEGMENTATION_ID, dimensions)
  )[0];

  const [nameFields, setNameFields] = useState<Record<string, any>>(
    generateNameFields(namingConvention, allNameFields, dimensions, client, agency)
  );

  // const setNameField = (id, value) => setNameFieldUtil(id, value, nameFields, setNameFields);
  const setNameField = useCallback(
    (id, value) => {
      setNameFieldUtil(id, value, nameFields, setNameFields);
    },
    [nameFields, setNameFields]
  );

  const creationTabArray = selectionsToArray(creationTabs);

  const updateFormField = (key: string, value: string) =>
    setMetaCampaignForm((current: any): typeof metaCampaignFormDefault => {
      return { ...current, [key]: value };
    });

  useEffect(() => {
    const filteredSegIds = filterSegmentationIds(nameFields, dimensions, segmentationDimensionId);
    const selectedSegId = nameFields[segmentationDimensionId];
    if (!R.includes(selectedSegId, filteredSegIds) && !R.isEmpty(selectedSegId)) {
      setNameField(segmentationDimensionId, "");
    }
  }, [nameFields, segmentationDimensionId, dimensions, setNameField]);

  const mapLabelsByValues: Record<string, any> = R.pipe(
    //@ts-ignore
    R.indexBy(R.prop("value")),
    //@ts-ignore
    R.map(R.prop("label"))
  );

  const validateCampaignForm = () => {
    let payloadCampaign: CampaignPayload = {
      client,
      business_name: "",
      ad_account_id: "",
      name: "",
      objective: "",
      special_ad_categories: [],
    };

    namingConvention.forEach(dimensionId => {
      if (R.isEmpty(nameFields[dimensionId])) {
        throw new Error(`Missing required field '${dimensions[dimensionId].name}'`);
      }
    });

    Object.entries(REQUIRED_FIELDS_CAMPAIGN).forEach(([field, label]) => {
      if (R.isEmpty(metaCampaignForm[field])) {
        throw new Error(`Missing required field '${label}'`);
      }
    });

    if (metaCampaignForm.budget_type === "CAMPAIGN_LIFETIME_BUDGET") {
      payloadCampaign.lifetime_budget = metaCampaignForm.budget;
      payloadCampaign.bid_strategy = metaCampaignForm.bid_strategy;
      if (R.isEmpty(metaCampaignForm.bid_strategy)) {
        throw new Error("Missing required field 'bid_strategy'");
      }
    } else if (metaCampaignForm.budget_type === "CAMPAIGN_DAILY_BUDGET") {
      payloadCampaign.daily_budget = metaCampaignForm.budget;
      payloadCampaign.bid_strategy = metaCampaignForm.bid_strategy;
      if (R.isEmpty(metaCampaignForm.bid_strategy)) {
        throw new Error("Missing required field 'bid_strategy'");
      }
    }

    let newAllNameFields = R.clone(allNameFields);
    R.keys(nameFields).forEach(dimensionId => {
      let field = R.clone(allNameFields[dimensionId]);
      field.value = nameFields[dimensionId];
      newAllNameFields[dimensionId] = field;
    });
    setAllNameFields(newAllNameFields);

    return payloadCampaign;
  };

  const renderNameFields = (): JSX.Element[] =>
    namingConvention.map(dimensionId => {
      const dimension = dimensions[dimensionId];
      let dropdownOptions =
        dimension.type === SEGMENTATION_ID
          ? filterSegmentationIds(nameFields, dimensions, segmentationDimensionId)
          : dimension.options;
      let field;
      switch (dimension.type) {
        case AGENCY:
        case CLIENT:
          field = <Form.Control value={dimension.type === CLIENT ? client : agency} disabled />;
          break;
        case SEGMENTATION_ID:
        case SEGMENT_SELECTION:
          field = (
            <DropdownButton
              title={nameFields[dimensionId]}
              onSelect={value => setNameField(dimensionId, value)}
              disabled={dropdownOptions.length === 0}
            >
              {dropdownOptions.map(option => (
                <Dropdown.Item key={option} eventKey={option}>
                  {option}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          );
          break;
        default:
          field = (
            <Form.Control
              value={nameFields[dimensionId]}
              onChange={e => setNameField(dimensionId, e.target.value)}
            />
          );
          break;
      }
      return (
        <Form.Group key={dimensionId}>
          <Form.Label>{dimension.name}</Form.Label>
          {field}
        </Form.Group>
      );
    });

  return (
    <>
      <MetaBuyingTabs
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        creationTabs={creationTabs}
        validate={index =>
          validateForm(metaCampaignForm, validateCampaignForm, "campaign", () => setTabIndex(index))
        }
      />
      <div className="metaBuyingContainer">
        <div className="metaBuyingCard">
          <div className="metaBuyingHeader">
            <h2>Campaign</h2>
            <div className="metaBuyingNameWrapper">
              Name:
              <MetaBuyingNamePreview
                dimensions={dimensions}
                nameFields={nameFields}
                namingConvention={namingConvention}
              />
            </div>
          </div>

          <div className="metaBuyingFormBody">
            <div className="metaBuyingFormSection">
              <h3>Campaign Name Construction</h3>
              {renderNameFields()}
            </div>
            <div className="metaBuyingFormSection">
              <h3>Campaign Options</h3>
              <Form.Group>
                <Form.Label>Campaign Objective</Form.Label>
                <DropdownButton
                  title={metaCampaignForm.objective}
                  onSelect={value => updateFormField("objective", value || "")}
                >
                  {OBJECTIVE_TYPES.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Special Ad Categories</Form.Label>
                <DropdownButton
                  title={
                    <div className="title">
                      {metaCampaignForm.special_ad_categories.map(category => (
                        <div key={category}>
                          {
                            //@ts-ignore
                            mapLabelsByValues(SPECIAL_AD_CATEGORIES)[category]
                            //@ts-ignore
                          }
                        </div>
                      ))}
                    </div>
                  }
                  onSelect={value => {
                    let newMetaCampaignForm = R.clone(metaCampaignForm);
                    if (R.includes(value, metaCampaignForm.special_ad_categories)) {
                      newMetaCampaignForm.special_ad_categories = R.remove(
                        R.findIndex(elem => elem === value, metaCampaignForm.special_ad_categories),
                        1,
                        metaCampaignForm.special_ad_categories
                      );
                    } else {
                      newMetaCampaignForm.special_ad_categories.push(value);
                    }
                    setMetaCampaignForm(newMetaCampaignForm);
                  }}
                >
                  {SPECIAL_AD_CATEGORIES.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      <div
                        className={
                          R.includes(value, metaCampaignForm.special_ad_categories)
                            ? "selected"
                            : ""
                        }
                      >
                        {label}
                      </div>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
            </div>
            <div className="metaBuyingFormSection">
              <h3>Budget</h3>
              <Form.Group>
                <Form.Label>Budget Type</Form.Label>
                {BUDGET_TYPES_CAMPAIGN.map(({ label, value }) => (
                  <Form.Check
                    key={label}
                    type="radio"
                    label={label}
                    checked={metaCampaignForm.budget_type === value}
                    id={value}
                    onChange={() => updateFormField("budget_type", value)}
                  />
                ))}
              </Form.Group>
              {(metaCampaignForm.budget_type === "CAMPAIGN_DAILY_BUDGET" ||
                metaCampaignForm.budget_type === "CAMPAIGN_LIFETIME_BUDGET") && (
                <>
                  <Form.Group>
                    <Form.Label>Bid Strategy</Form.Label>
                    <DropdownButton
                      //@ts-ignore
                      title={mapLabelsByValues(BID_STRATEGIES)[metaCampaignForm.bid_strategy] || ""}
                      //@ts-ignore
                      onSelect={value => updateFormField("bid_strategy", value || "")}
                    >
                      {BID_STRATEGIES.map(({ label, value }) => (
                        <Dropdown.Item key={value} eventKey={value}>
                          {label}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Budget</Form.Label>
                    <Form.Control
                      value={metaCampaignForm.budget}
                      onChange={e => updateFormField("budget", e.target.value)}
                      type="number"
                    />
                  </Form.Group>
                </>
              )}
            </div>
            <MetaBuyingFooterControls
              nextTabName={creationTabArray[tabIndex + 1]}
              handleReviewClick={() =>
                validateForm(metaCampaignForm, validateCampaignForm, "campaign", showReviewModal)
              }
              handleNextClick={
                creationTabArray[tabIndex + 1]
                  ? () =>
                      validateForm(metaCampaignForm, validateCampaignForm, "campaign", () =>
                        setTabIndex(tabIndex + 1)
                      )
                  : undefined
              }
            />
          </div>
        </div>

        <MetaBuyingOptionsNoteCard
          header={OPTIONS_NOTE_CARD_HEADER}
          text={OPTIONS_NOTE_CARD_TEXT}
          options={OPTIONS_NOTE_CARD_OPTIONS}
        />
      </div>
    </>
  );
};

export default MetaBuyingCampaign;
