import React, { useState, useEffect, useMemo, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { useSelector } from "react-redux";

import { useNetworkMap } from "../redux/networks";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import * as UserRedux from "../redux/user";
import { useCompanyInfo } from "../redux/company";

import { useExperimentFlag } from "../utils/experiments/experiment-utils";

import {
  StreamingV2LambdaFetch,
  StreamingUtilsLambdaFetch,
  JavaLambdaFetch,
  awaitJSON,
  pollS3,
} from "../utils/fetch-utils";

import { useLocalStorageState } from "../utils/hooks/useData";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import useLocation from "../utils/hooks/useLocation";

import {
  Img,
  Spinner,
  Skeleton,
  FilterBarSkeleton,
  RectSkeleton,
  ListGroupSkeleton,
  FILTER_BAR_SKELETON_HEIGHT,
} from "../Components";
import { Toast } from "react-bootstrap";

import { toStatus, makeStrBuyingLocalStorageKey } from "./StreamingBuying";

import FlightList from "./FlightList";
import OrderControls from "./OrderControls";
import FlightForm from "./FlightForm";
import SendOrderView from "./SendOrderView";

import "./OrderView.scss";

export const OrderViewContext = React.createContext({});

const DATE_FORMAT = "yyyy-MM-dd";
const PRETTY_DATE_FORMAT = "M/d/yyyy";
const TODAY = Dfns.format(DATE_FORMAT, new Date());

export const toPrettyDate = R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT));

export const toPrettySpend = spend =>
  (spend || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
export const toPrettyImpressions = impressions => (impressions || 0).toLocaleString("en-US");

export const subFlightsToPropertyArray = R.pipe(
  R.defaultTo([]),
  R.filter(el => !el.ending && !el.zeroLength && (!el.endDate || el.endDate >= TODAY)),
  R.pluck("shortCode"),
  R.uniq,
  R.sortBy(R.identity)
);

export const subFlightsToPropertyList = R.pipe(subFlightsToPropertyArray, R.join(", "));

const OrderViewSkeleton = React.memo(() => {
  const TOP_HEIGHT = 50;
  const GUTTER = 8;
  const LIST_LINE_HEIGHT = 40;
  const LIST_LINE_SPACING = 5;
  const LIST_BORDER_RADIUS = 5;
  const BOTTOM_BUTTON_HEIGHT = 30;

  const groupY = TOP_HEIGHT + GUTTER * 2 + FILTER_BAR_SKELETON_HEIGHT;
  const adjustBorder = R.subtract(R.__, 2);
  return (
    <Skeleton>
      <RectSkeleton height={TOP_HEIGHT} width={adjustBorder} />
      <FilterBarSkeleton y={TOP_HEIGHT + GUTTER} margin={GUTTER} />
      <ListGroupSkeleton
        y={groupY}
        verticalPadding={GUTTER}
        horizontalPadding={GUTTER}
        lineHeight={LIST_LINE_HEIGHT - LIST_LINE_SPACING}
        lineSpacing={LIST_LINE_SPACING}
        borderRadius={LIST_BORDER_RADIUS}
        height={height => height - groupY - GUTTER - BOTTOM_BUTTON_HEIGHT}
      />
      <RectSkeleton
        y={height => height - BOTTOM_BUTTON_HEIGHT - 2}
        height={BOTTOM_BUTTON_HEIGHT}
        width={adjustBorder}
      />
    </Skeleton>
  );
});

const useOrder = (orderID, network, navigate) => {
  const [order, setOrder] = useState();
  const setError = useSetError();
  const { company } = useLocation();

  const isNew = useMemo(() => orderID === "new", [orderID]);

  const networkMap = useNetworkMap();

  const clearOrder = useCallback(() => setOrder(), []);

  // When the order ID changes, that means the URL changed, which means we need to refetch stuff
  useEffect(() => {
    setOrder();
  }, [orderID]);

  useEffect(() => {
    if (order) {
      return;
    }
    if (isNew) {
      if (networkMap) {
        setOrder({
          network,
          company,
          status: 0,
          flights: [],
        });
      }
    } else {
      (async () => {
        let order;
        try {
          let res = await StreamingV2LambdaFetch("/order", {
            params: {
              id: orderID,
            },
          });
          order = await awaitJSON(res);
          setOrder(order);
        } catch (e) {
          setError({
            message: `Failed to fetch order. Error: ${e.message}`,
            reportError: e,
          });
          if ((e.status || 0) === 400) {
            navigate("../");
          }
          return;
        }

        if (order.company !== company) {
          await setError({
            message: `Order #${orderID} does not exist for company ${company}.`,
          });
          navigate("../");
        } else if (!network) {
          navigate(`../${order.network}/${orderID}`, { replace: true });
        } else if (order.network !== network) {
          await setError({
            message: `Order #${orderID} does not exist for network ${network}.`,
          });
          navigate("../");
        }
      })();
    }
  }, [orderID, isNew, order, setOrder, setError, networkMap, company, network, navigate]);

  return [order, clearOrder, isNew];
};

const OrderHeader = React.memo(({ order, networkInfo }) => {
  const { id, status, revision } = order;
  const { shortCode, name } = networkInfo;
  return (
    <div className="orderHeader">
      <div className="orderInfo">
        <div className="logo">
          <Img alt={shortCode} src={`https://cdn.blisspointmedia.com/networks/${shortCode}.png`} />
        </div>
        <div className="text">
          {name} <span className="subText">({shortCode})</span>
        </div>
        <div className="text order">
          {id ? (
            <>
              Order #{id} <span className="subText">(Revision {revision})</span>{" "}
            </>
          ) : (
            "New Order"
          )}
        </div>
        <div className="text status">
          Status: {toStatus(status)} <span className="subText">({status})</span>
        </div>
      </div>
    </div>
  );
});

const DEFAULT_LOCAL_STORAGE_CLIPBOARD = {
  copiedFlight: {},
};

export const useLocalStorageClipboard = (company, network) => {
  const [clipboardChanges, setClipboardChanges] = useLocalStorageState(
    makeStrBuyingLocalStorageKey(company, network, null),
    DEFAULT_LOCAL_STORAGE_CLIPBOARD
  );

  const { copiedFlight } = clipboardChanges;

  const setCopiedFlight = useCallback(
    newCopiedFlight => {
      setClipboardChanges(({ copiedFlight }) => ({
        copiedFlight:
          newCopiedFlight instanceof Function ? newCopiedFlight(copiedFlight) : newCopiedFlight,
      }));
    },
    [setClipboardChanges]
  );

  return { copiedFlight, setCopiedFlight };
};

const DEFAULT_LOCAL_STORAGE_ORDER = {
  flightChangeMap: {},
};

const useLocalStorageOrder = (order, network, company) => {
  const networkMap = useNetworkMap();

  const [orderChanges, setOrderChanges] = useLocalStorageState(
    makeStrBuyingLocalStorageKey(company, network, R.prop("id", order) || "new"),
    DEFAULT_LOCAL_STORAGE_ORDER
  );

  const { flightChangeMap, pdfText, revision } = orderChanges;

  // We save their local changes in local storage. However, if someone elsewhere has modified this
  // order, we don't want to apply their changes onto the new order, since they may no longer be
  // valid. So we, separately, save the order revision number in local storage. If we have one set,
  // then if it doesn't equal what the version is from the API, then the last time we were on this
  // page we were looking at an older version and should, thus, toss our local changes. All we have
  // to do is reset the flight change map which is persisted in local storage by its own hook.
  useEffect(() => {
    if (!order.loading) {
      let orderRevision = R.prop("revision", order);
      if (R.isNil(revision)) {
        setOrderChanges(changes => ({ ...changes, revision: orderRevision || 0 }));
      } else if (revision && orderRevision && orderRevision !== revision) {
        setOrderChanges({ ...DEFAULT_LOCAL_STORAGE_ORDER, revision: orderRevision || 0 });
      }
    }
  }, [order, revision, setOrderChanges]);

  const setFlightChange = useCallback(
    (flightID, newObj) => {
      setOrderChanges(({ flightChangeMap, ...rest }) => {
        let ourNewObj =
          newObj instanceof Function ? newObj(flightChangeMap[flightID] || {}) : newObj;
        let newMap = { ...flightChangeMap };
        if (!ourNewObj || R.isEmpty(ourNewObj)) {
          delete newMap[flightID];
        } else {
          newMap[flightID] = ourNewObj;
        }
        return {
          ...rest,
          flightChangeMap: newMap,
        };
      });
    },
    [setOrderChanges]
  );

  const clearFlightChanges = useCallback(() => setOrderChanges(R.omit(["flightChangeMap"])), [
    setOrderChanges,
  ]);

  const defaultPDFText = useMemo(
    () => R.path([network, "defaultIOPdfText"], networkMap) || "Max Frequency 1X per show/view.",
    [network, networkMap]
  );

  const resolvedPDFText = useMemo(() => {
    if (!R.isNil(pdfText)) {
      return pdfText;
    }
    let ourPDFText = R.prop("pdfText", order);
    if (R.isNil(ourPDFText)) {
      ourPDFText = defaultPDFText;
    }

    return ourPDFText;
  }, [pdfText, order, defaultPDFText]);

  const setPDFText = useCallback(
    newPDFText => {
      setOrderChanges(({ pdfText, ...rest }) => ({
        ...rest,
        pdfText: newPDFText instanceof Function ? newPDFText(pdfText) : newPDFText,
      }));
    },
    [setOrderChanges]
  );

  const clearPDFText = useCallback(() => setOrderChanges(R.omit(["pdfText"])), [setOrderChanges]);
  const clearOrderChanges = useCallback(() => setOrderChanges(DEFAULT_LOCAL_STORAGE_ORDER), [
    setOrderChanges,
  ]);

  const hasChanges = useMemo(() => Boolean(pdfText) || !R.isEmpty(flightChangeMap), [
    pdfText,
    flightChangeMap,
  ]);

  const setPDFTextToDefault = useCallback(() => setPDFText(defaultPDFText), [
    setPDFText,
    defaultPDFText,
  ]);

  return {
    flightChangeMap,
    setFlightChange,
    clearFlightChanges,
    pdfText: resolvedPDFText,
    setPDFText,
    clearPDFText,
    clearOrderChanges,
    hasChanges,
    setPDFTextToDefault,
  };
};

const OrderView = ({ orderID, network, navigate }) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const { company } = useLocation();
  const companyInfo = useCompanyInfo();

  const getIsMounted = useIsMounted();

  const [order, clearOrder, isNewOrder] = useOrder(orderID, network, navigate);
  const networkMap = useNetworkMap();

  const {
    flightChangeMap,
    setFlightChange,
    pdfText,
    setPDFText,
    clearPDFText,
    clearOrderChanges,
    hasChanges,
    setPDFTextToDefault,
  } = useLocalStorageOrder(order || { id: orderID, loading: true }, network, company);

  const { copiedFlight, setCopiedFlight } = useLocalStorageClipboard(company, network);

  const [selectedFlight, setSelectedFlightRaw] = useState();

  const [saving, setSaving] = useState(false);

  const [hasPendingFormChanges, setHasPendingFormChanges] = useState(false);

  const setSelectedFlight = useCallback(
    async newFlight => {
      if (selectedFlight && hasPendingFormChanges) {
        try {
          await setAreYouSure({
            title: "Pending Changes",
            message:
              "You have unsaved changes on your currently-open flight. This will discard them. Are you sure you want continue?",
            okayText: "Continue",
            cancelText: "Never Mind",
          });
        } catch (e) {
          return;
        }
      }
      setSelectedFlightRaw(newFlight);
    },
    [selectedFlight, setAreYouSure, hasPendingFormChanges]
  );

  const closeFlightForm = useCallback(() => {
    setHasPendingFormChanges(false);
    setSelectedFlightRaw();
  }, []);

  const saveFlightChange = useCallback(
    (id, changes, close = true) => {
      setFlightChange(id, changes);
      if (close) {
        closeFlightForm();
      }
    },
    [setFlightChange, closeFlightForm]
  );

  const refetchOrder = useCallback(() => {
    clearOrder();
    clearOrderChanges();
  }, [clearOrder, clearOrderChanges]);

  const [networkBuyingOptions, setNetworkBuyingOptions] = useState();
  useEffect(() => {
    if (company && network) {
      (async () => {
        try {
          const res = await StreamingV2LambdaFetch("/network_buying_info", {
            params: {
              company,
              network,
            },
          });
          const buyingInfo = await awaitJSON(res);
          if (!getIsMounted()) {
            return;
          }
          setNetworkBuyingOptions(buyingInfo);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [company, network, order, setError, getIsMounted]);

  const beeswaxIntegrationFlipDate = useExperimentFlag("beeswaxIntegrationFlipDate");

  const [isGhostBidding, setIsGhostBidding] = useState(false);

  useEffect(() => {
    setIsGhostBidding(order?.isGhostBidding);
  }, [order?.isGhostBidding]);

  const sendFlightsToBeeswax = useCallback(async () => {
    try {
      let minStart, maxEnd;
      let formattedFlights = {};
      for (let flight of order.flights) {
        if (flight.dsp === "Beeswax" && flight.created >= beeswaxIntegrationFlipDate) {
          let changes = flightChangeMap[flight.id] || {};
          let start = changes.startDate || flight.startDate;
          if (!minStart || start < minStart) {
            minStart = start;
          }
          let end = changes.endDate || flight.endDate;
          if (!maxEnd || end > maxEnd) {
            maxEnd = end;
          }

          const formattedFlight = {
            flightId: flight.id,
            startDate: flight.startDate,
            endDate: flight.endDate,
            spend: flight.spend,
            cpm: flight.cpm,
          };

          const hasSubflights = flight.subFlights?.length;

          // Don't allow flights with multiple subflights to be sent through to Beeswax.
          // Instead, we will have a RON subproperty to use.
          if (hasSubflights && flight.subFlights?.length > 1) {
            throw Error(
              "There should be at most one subflight in order to send this through to the DSP.  Please use the RON subnetwork or split into multiple flights if you need to run on multiple subproperties."
            );
          }

          const year = flight.startDate.slice(0, 4);
          const beeswaxLineItemName = flight.useDescription
            ? `${company} ${
                hasSubflights ? flight.subFlights[0].derivedNetwork : flight.derivedNetwork
              } Streaming RTB ${flight.description}`.trim()
            : `${company} ${
                hasSubflights ? flight.subFlights[0].derivedNetwork : flight.derivedNetwork
              } Streaming RTB ${flight.content} ${flight.additional}`.trim();
          if (formattedFlights[year]) {
            if (formattedFlights[year][beeswaxLineItemName]) {
              formattedFlights[year][beeswaxLineItemName].push(formattedFlight);
            } else {
              formattedFlights[year][beeswaxLineItemName] = [formattedFlight];
            }
          } else {
            formattedFlights[year] = {};
            formattedFlights[year][beeswaxLineItemName] = [formattedFlight];
          }
        }
      }

      if (Object.keys(formattedFlights).length) {
        await StreamingUtilsLambdaFetch("/sendFlightsToBeeswax", {
          method: "POST",
          body: {
            company,
            agency: companyInfo.agency,
            network,
            orderId: order.id,
            orderStartDate: minStart,
            flights: formattedFlights,
            isGhostBidding,
          },
        });
      }
    } catch (e) {
      throw Error(e.message);
    }
  }, [
    companyInfo.agency,
    company,
    flightChangeMap,
    network,
    order,
    beeswaxIntegrationFlipDate,
    isGhostBidding,
  ]);

  const onSave = useCallback(
    async (commit = false) => {
      if (!saving) {
        // TODO: it would be cool to do an "areYouSure" that has the entire diff
        setSaving(true);
        try {
          const newFlights = R.pipe(R.values, R.filter(R.prop("isNew")))(flightChangeMap);
          const oldFlights = R.pipe(
            R.prop("flights"),
            R.map(flight => {
              let changes = flightChangeMap[flight.id];
              if (changes) {
                return { ...flight, ...changes, changed: true };
              }
              return flight;
            })
          )(order);
          let hasRTB = false;
          let hasNonRTB = false;
          let payload = {
            commit,
            pdfText: pdfText.replaceAll("\t", " "),
            flights: [...newFlights, ...oldFlights].map(flight => {
              let newFlight = {
                ...flight,
              };
              if (flight.isNew) {
                delete newFlight.id;
              }
              if (flight.dsp === false) {
                delete newFlight.dsp;
              }
              if (flight.canceled === false) {
                delete newFlight.canceled;
              }
              // NOTE: we set overrides to false to signify deletion, as we do with dsp and
              // canceled. However, unlike those two, overrides are in a separate table. The API
              // looks for a false value and, if found, deletes those rows.
              if (flight.platform.includes("RTB")) {
                hasRTB = true;
              } else {
                hasNonRTB = true;
              }
              return newFlight;
            }),
            isGhostBidding,
          };
          if (hasRTB && hasNonRTB) {
            setError({
              title: "Mixed RTB and Non-RTB",
              message:
                "You have a combination of RTB and Non-RTB flights. An IO can only have one or the other.",
            });
            setSaving(false);
            return;
          }
          if (!payload.flights.length) {
            setError({
              title: "No Flights",
              message: "You can't make an order with no flights.",
            });
            setSaving(false);
            return;
          }
          let totalSpend = 0;
          for (let flight of payload.flights) {
            totalSpend += flight.spend;
          }
          if ((!hasRTB && totalSpend < networkBuyingOptions.ioMinimum) || 0) {
            try {
              await setAreYouSure({
                title: "Under IO Minimum",
                message: `Your spend (${toPrettySpend(
                  totalSpend
                )}) is less than the IO minimum (${toPrettySpend(
                  networkBuyingOptions.ioMinimum
                )}). Are you sure you want to save?`,
                okayText: "Yes, save anyway",
                cancelText: "Never mind",
              });
            } catch (e) {
              setSaving(false);
              return;
            }
          }

          // filter for new podcast names
          let newShowTitles = newFlights
            .filter(flight => flight.isNewShowTitle)
            .map(flight => {
              return flight.showTitle;
            });
          if (newShowTitles?.length > 0) {
            // add new podcast names to streaming_podcasts table
            try {
              await StreamingV2LambdaFetch("/insertPodcastByNetwork", {
                method: "POST",
                body: {
                  network: network,
                  newShowTitles: newShowTitles,
                },
              });
            } catch (e) {
              setError({
                message: e.message,
                reportError: e,
              });
            }
          }
          if (isNewOrder) {
            payload = {
              ...order,
              ...payload,
            };
            let res = await StreamingV2LambdaFetch("/orders", {
              method: "PUT",
              body: payload,
            });
            let result = await awaitJSON(res);
            await navigate(`../${result}`, { replace: true });
            refetchOrder();
          } else {
            payload.id = order.id;
            await StreamingV2LambdaFetch("/orders", {
              method: "POST",
              body: payload,
            });
            refetchOrder();
          }
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
        setSaving(false);
      }
    },
    [
      setSaving,
      saving,
      order,
      flightChangeMap,
      pdfText,
      setError,
      isNewOrder,
      navigate,
      refetchOrder,
      networkBuyingOptions,
      setAreYouSure,
      network,
      isGhostBidding,
    ]
  );

  const isMultiProperty = useMemo(
    () => Boolean(R.pipe(R.path([network, "children"]), R.defaultTo([]), R.length)(networkMap)),
    [networkMap, network]
  );

  const fullName = useSelector(UserRedux.fullNameSelector);
  const email = useSelector(UserRedux.emailSelector);

  const generatePDF = useCallback(
    async (download = true) => {
      if (download) {
        setSaving(true);
      }
      let filename = `order${orderID}_${Dfns.format("yyyy-MM-dd_HH-mm-ss", new Date())}.pdf`;
      try {
        await JavaLambdaFetch("/streaming_order", {
          method: "PUT",
          body: {
            order_id: orderID,
            fullName,
            email,
            filename,
            v2: true,
          },
        });
        // TODO: figure out if the PDF gen failed and report why
        await pollS3({
          bucket: "bpm-streaming-orders",
          mimeType: "application/pdf",
          filename,
          autoDownload: download,
        });
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
      if (getIsMounted() && download) {
        setSaving(false);
      }
      return filename;
    },
    [fullName, email, setError, orderID, getIsMounted]
  );

  const [showTitleOptions, setShowTitleOptions] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        let res = await StreamingV2LambdaFetch("/getPodcastsByNetwork", { params: { network } });
        let parsedRes = await awaitJSON(res);
        let showTitleOptions = parsedRes.map(showTitle => ({
          value: showTitle,
          label: showTitle,
        }));

        setShowTitleOptions(showTitleOptions);
      } catch (e) {
        setError(`Failed to fetch podcast options for ${network}: ${e.message}`);
      }
    })();
  }, [network, setError]);

  const [showSendView, setShowSendView] = useState(false);

  const [controlsExpanded, setControlsExpanded] = useState(false);

  const networkInfo = networkMap[network] || {};

  const [sameTab, setSameTab] = useState(true);

  const [showCopiedToast, setShowCopiedToast] = useState(false);

  return (
    <div className="streamingBuyingOrderView">
      {networkInfo && <div className="stripe" style={{ backgroundColor: networkInfo.color }} />}
      {order && networkMap ? (
        <OrderViewContext.Provider
          value={{
            company,
            order,
            orderID,
            flightChangeMap,
            setFlightChange,
            setSelectedFlight,
            selectedFlight,
            saving,
            onSave,
            sendFlightsToBeeswax,
            networkBuyingOptions,
            derivedMapKeys: R.prop("derivedMapKeys", order) || {},
            pdfText,
            network,
            networkInfo,
            isMultiProperty,
            isNewOrder,
            setHasPendingFormChanges,
            clearOrderChanges,
            setPDFText,
            clearPDFText,
            saveFlightChange,
            closeFlightForm,
            generatePDF,
            setSaving,
            hasChanges,
            setPDFTextToDefault,
            refetchOrder,
            setShowSendView,
            copiedFlight,
            setCopiedFlight,
            sameTab,
            setSameTab,
            setShowCopiedToast,
            showTitleOptions,
          }}
        >
          <OrderHeader order={order} networkInfo={networkInfo} />
          {showSendView ? (
            <SendOrderView />
          ) : (
            <div className="panes">
              <div className="leftPane">
                <FlightList />
                <OrderControls
                  expanded={controlsExpanded}
                  setExpanded={setControlsExpanded}
                  isGhostBidding={isGhostBidding}
                  setIsGhostBidding={setIsGhostBidding}
                />
                <Toast
                  className="toast"
                  onClose={() => setShowCopiedToast(false)}
                  show={showCopiedToast}
                  delay={1000}
                  autohide
                >
                  <Toast.Body>Copied Flight!</Toast.Body>
                </Toast>
              </div>
              {selectedFlight && (
                <div className="rightPane">
                  <FlightForm
                    // NOTE: if the selected flight changes, I want to force a re-mount
                    key={JSON.stringify(selectedFlight)}
                    selectedFlight={selectedFlight}
                  />
                </div>
              )}
            </div>
          )}
        </OrderViewContext.Provider>
      ) : (
        <OrderViewSkeleton />
      )}
      {saving && (
        <div className="savingOverlay">
          <Spinner size={75} />
        </div>
      )}
    </div>
  );
};

export default OrderView;
