import { MiscLambdaFetch, awaitJSON } from "../utils/fetch-utils";

export interface AmcInstanceMappingParams {
  company: string;
  amcInstanceId: string;
}

export const createAmcInstanceIdMapping = async (
  clientInfoParams: AmcInstanceMappingParams
): Promise<{ statusCode: number; body: string }> => {
  let res = await MiscLambdaFetch("/createAmcInstanceIdMapping", {
    method: "POST",
    body: JSON.stringify(clientInfoParams),
  });
  return await awaitJSON(res);
};

export const getAmcInstanceIdMapping = async (
  company: string
): Promise<{ statusCode: number; body: AmcInstanceMappingParams[] }> => {
  const res = await MiscLambdaFetch(`/getAmcInstanceIdMapping?company=${company}`, {
    method: "GET",
  });
  return await awaitJSON(res);
};

export interface SkaiMapping {
  company: string;
  profileId: string;
  profileName: string;
  comments?: string;
  notes?: string;
}

export const getSkaiIdMappings = async (
  company: string
): Promise<SkaiMapping[]> => {
  const res = await MiscLambdaFetch("/getSkaiIdMappings", {
    method: "GET",
    params: { company },
  });
  return await awaitJSON(res);
};

export const createSkaiIdMapping = async (
  skaiParams: SkaiMapping
): Promise<{ statusCode: number; body: string }> => {
  const res = await MiscLambdaFetch("/createSkaiIdMapping", {
    method: "POST",
    body: skaiParams
  });
  return await awaitJSON(res);
};
