import { ApprovalStages } from "@blisspointmedia/bpm-types/dist/CreativeApprovals";
import { Button, Collapse } from "react-bootstrap";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { StreamingCreativesContext, APPROVALS_KEY } from "./StreamingCreatives";
import { useCreativeMap } from "../redux/creative";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import { useStateFunction } from "../utils/hooks/useData";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useContext, useCallback, useMemo, useState, useEffect } from "react";
import useLocation from "../utils/hooks/useLocation";
import {
  awaitJSON,
  ExtremeReachLambdaFetch,
  MiscLambdaFetch,
  pollS3,
  StreamingLambdaFetch,
} from "../utils/fetch-utils";
import {
  Card,
  Spinner,
  PieChart,
  Img,
  OldFilterBar,
  ToggleNav,
  ToggleNavButton,
} from "../Components";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

const CURRENT_DATE_LABEL = "Current";
const DATE_FORMAT = "yyyy-MM-dd";
const HEADER_DATE_FORMAT = "MMMM d, yyyy";

const CDN_NETWORK_LINK = "https://cdn.blisspointmedia.com/networks/";

const TODAY = Dfns.format(DATE_FORMAT, new Date());

const DIMENSIONS = {
  DATE: "Date",
  NETWORK: "Network",
  NETWORK_GROUP: "Network Group",
  ROTATION: "Creative Rotation",
  ISCI: "ISCI",
  CREATIVE: "Creative Name",
};

const GROUPING_OPTIONS = {
  DATE: "Date",
  NETWORK: "Network",
  CREATIVE: "Creative",
};

const PRESETS = {
  [GROUPING_OPTIONS.DATE]: [DIMENSIONS.DATE, DIMENSIONS.ROTATION, DIMENSIONS.NETWORK],
  [GROUPING_OPTIONS.NETWORK]: [
    DIMENSIONS.NETWORK_GROUP,
    DIMENSIONS.NETWORK,
    DIMENSIONS.DATE,
    DIMENSIONS.ROTATION,
  ],
  [GROUPING_OPTIONS.CREATIVE]: [
    DIMENSIONS.CREATIVE,
    DIMENSIONS.DATE,
    DIMENSIONS.ROTATION,
    DIMENSIONS.NETWORK,
  ],
};

const SORTERS = {
  [DIMENSIONS.DATE]: R.sort(
    R.comparator((a, b) => {
      if (a === CURRENT_DATE_LABEL) {
        return true;
      }
      if (b === CURRENT_DATE_LABEL) {
        return false;
      }
      return a < b;
    })
  ),
};

const FILTER_BAR_OPTIONS = [
  { label: "Date", name: "date" },
  { label: "Network", name: "network_label" },
  { label: "Network Group", name: "network_group" },
  { label: "ISCI", name: "isci" },
  { label: "Creative Name", name: "creative" },
  { label: "Percentage", name: "pct" },
  { label: "Placement Name", name: "placementName" },
];

const toFormattedHeaderDate = R.pipe(Dfns.parseISO, Dfns.format(HEADER_DATE_FORMAT));
const toFormattedFilterDate = R.pipe(
  Dfns.parse(new Date(), DATE_FORMAT),
  Dfns.format(HEADER_DATE_FORMAT)
);

const makeRotationKey = R.pipe(
  R.sortBy(R.prop("isci")),
  R.map(({ isci, pct }) => `${isci}_${pct}`),
  R.join("|")
);

const groupData = (values, types, groupers) => {
  if (!types.length) {
    return R.pipe(R.pluck("placementID"), R.uniq)(values);
  }
  let newTypes = [...types];
  let type = newTypes.shift();
  let groups = groupers[type](values);
  return R.map(group => groupData(group, newTypes, groupers), groups);
};

const TypeRendererContext = React.createContext();

const CollapsingItem = ({ children, newTypes, group, defaultExpanded = false }) => {
  const typeRenderers = useContext(TypeRendererContext);

  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className="collapsingGroup">
      <div className="collapsingHeader" onClick={() => setExpanded(!expanded)}>
        {children}
        {expanded ? <MdExpandLess /> : <MdExpandMore />}
      </div>
      <Collapse in={expanded} mountOnEnter unmountOnExit>
        <div className={newTypes.length ? "collapsingList" : "finalRows"}>
          {newTypes.length ? (
            <CollapsingGroup types={newTypes} group={group} />
          ) : (
            <div>{R.map(typeRenderers.PLACEMENT, group)}</div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

const CollapsingGroup = ({ types, group = {} }) => {
  const typeRenderers = useContext(TypeRendererContext);

  let newTypes = [...types];
  let type = newTypes.shift();

  let numItems = R.pipe(R.keys, R.length)(group);

  return (
    <>
      {R.pipe(
        R.keys,
        SORTERS[type] || R.sortBy(R.identity),
        R.map(key => (
          <CollapsingItem
            key={key}
            newTypes={newTypes}
            group={group[key]}
            defaultExpanded={numItems <= 1}
          >
            {typeRenderers[type](key)}
          </CollapsingItem>
        ))
      )(group)}
    </>
  );
};

const RotationHeader = ({ rotation }) => {
  const { company } = useLocation();
  const { creativeMap, colorMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });
  const [expanded, setExpanded] = useState(false);

  const units = useMemo(
    () =>
      R.pipe(
        R.split("|"),
        R.map(unit => {
          let [name, value] = unit.split("_");
          return {
            name,
            value: parseInt(value),
            color: colorMap[name] || "white",
            creativeName: creativeMap[name].name,
          };
        })
      )(rotation),
    [rotation, creativeMap, colorMap]
  );

  const displayItems = useMemo(() => {
    let dispUnits = R.sortWith(
      [R.descend(R.prop("value")), R.ascend(R.prop("creativeName")), R.ascend(R.prop("name"))],
      units
    );
    if (!expanded) {
      dispUnits = dispUnits.slice(0, 4);
    }
    return dispUnits;
  }, [units, expanded]);
  return (
    <div className="rotationHeader">
      <PieChart data={units} size={50} />
      <div className={`legendItems${expanded ? "" : " collapsed"}`}>
        {displayItems.map(({ name, value, color, creativeName }) => (
          <div key={name}>
            <div className="swatch" style={{ backgroundColor: color }} />
            <div>{creativeName}</div>
            {expanded && <small>({name})</small>}
            <div>{value}%</div>
          </div>
        ))}
        <div
          className="expandButton"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          {expanded ? "Less" : "More..."}
        </div>
      </div>
    </div>
  );
};

const Generate = () => {
  const { company } = useLocation();
  const { creativeMap, colorMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const creativeToImage = useMemo(
    () =>
      R.pipe(
        R.values,
        R.reduce(
          (agg, elem) => ({
            ...agg,
            [elem.name]: elem.file,
          }),
          {}
        )
      )(creativeMap),
    [creativeMap]
  );

  const { tab, goToTab, shopData, approvalStageData, updateApprovalStage } = useContext(
    StreamingCreativesContext
  );

  const { placements, placementMap } = shopData;

  const [generating, setGenerating] = useState(false);

  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure();

  const [filter, setFilter] = useStateFunction(() => true);

  const generateXR = useCallback(async () => {
    try {
      setGenerating(true);
      await ExtremeReachLambdaFetch("/update_er_rotation", {
        method: "post",
        body: {
          company,
        },
      });
      setError({
        title: "Success",
        variant: "success",
        message: "Extreme Reach updated!",
      });
      await updateApprovalStage(company, "PRE_REVIEW", [], "", "");
    } catch (e) {
      setError({
        message: `Failed to update Extreme Reach. Error: ${e.message}`,
        reportError: e,
      });
    }
    setGenerating(false);
  }, [company, updateApprovalStage, setError]);

  const generateFlashtalking = useCallback(async () => {
    try {
      setGenerating(true);
      const result = await MiscLambdaFetch("/kickOffLambda", {
        method: "POST",
        body: {
          fileType: "txt",
          lambdaArgs: {
            company,
            placementMap: null,
          },
          lambdaName: "flashtalking-sendCreativeRotationsToFlashtalking",
        },
      });
      const uuid = await awaitJSON(result);
      const content = await pollS3({
        autoDownload: false,
        bucket: "bpm-cache",
        filename: `${uuid}.txt`,
        mimeType: "text/plain",
        period: 3000,
      });
      const textContent = await content.text();
      const { body } = JSON.parse(textContent);
      const returnedMap = JSON.parse(body);
      const returnedPlacements = [];
      const errors = [];
      for (const key of R.keys(returnedMap)) {
        const creative = returnedMap[key];
        if (R.isNil(creative.error)) {
          for (const placementID of creative.placementIDs) {
            returnedPlacements.push(placementID);
          }
        } else {
          errors.push(creative.error);
        }
      }
      setError({
        title: "Success",
        variant: errors.length ? "danger" : "success",
        message: `Flashtalking updated! Placements Updated (${R.uniq(returnedPlacements).join(
          ", "
        )})${errors.length ? `\n | Errors (${errors.join(", ")})` : ""}`,
      });
      if (errors.length === 0) {
        await updateApprovalStage(company, "PRE_REVIEW", [], "", "");
      }
    } catch (e) {
      setError({
        message: `Failed to update Flashtalking. Error: ${e.message}`,
        reportError: e,
      });
    }
    setGenerating(false);
  }, [company, updateApprovalStage, setError]);

  const [groupableData, placementDatesToRotations] = useMemo(() => {
    const newData = [];
    const placementDatesToRotations = {};
    for (let { id, creatives } of placements) {
      let creativesByDate = {};
      for (let { isci, pct, start_date } of creatives) {
        let date = start_date;
        if (start_date <= TODAY) {
          date = CURRENT_DATE_LABEL;
        }
        let byDateAgg = creativesByDate[date] || [];
        byDateAgg.push({ isci, pct });
        creativesByDate[date] = byDateAgg;
        newData.push({
          placementID: id,
          isci,
          pct,
          date,
        });
      }
      for (let date of R.keys(creativesByDate)) {
        let rotationKey = makeRotationKey(creativesByDate[date]);
        placementDatesToRotations[`${date}_${id}`] = rotationKey;
      }
    }
    return [newData, placementDatesToRotations];
  }, [placements]);

  const groupers = useMemo(
    () => ({
      [DIMENSIONS.DATE]: R.groupBy(R.prop("date")),
      [DIMENSIONS.NETWORK]: R.groupBy(({ placementID }) =>
        R.path([placementID, "network_label"], placementMap)
      ),
      [DIMENSIONS.NETWORK_GROUP]: R.groupBy(
        ({ placementID }) => placementMap[placementID].network_group
      ),
      [DIMENSIONS.ROTATION]: R.groupBy(
        ({ date, placementID }) => placementDatesToRotations[`${date}_${placementID}`]
      ),
      [DIMENSIONS.ISCI]: R.groupBy(R.prop("isci")),
      [DIMENSIONS.CREATIVE]: R.groupBy(({ isci }) => creativeMap[isci].name),
    }),
    [placementMap, creativeMap, placementDatesToRotations]
  );

  const addLineMetadata = useCallback(
    ({ date, isci, pct, placementID }) => {
      const { network, network_group, name, network_label } = placementMap[placementID];
      return {
        date: date === CURRENT_DATE_LABEL ? CURRENT_DATE_LABEL : toFormattedFilterDate(date),
        network,
        network_label,
        network_group,
        isci,
        creative: creativeMap[isci].name,
        pct,
        placementName: name,
      };
    },
    [placementMap, creativeMap]
  );

  const [selectedGrouping, setSelectedGrouping] = useState("DATE");
  const currentGroupingOrder = PRESETS[GROUPING_OPTIONS[selectedGrouping]];
  const groupedData = useMemo(
    () =>
      groupData(
        R.filter(R.pipe(addLineMetadata, filter), groupableData),
        currentGroupingOrder,
        groupers
      ),
    [groupableData, currentGroupingOrder, groupers, filter, addLineMetadata]
  );

  const typeRenderers = useMemo(
    () => ({
      [DIMENSIONS.DATE]: date => (
        <div className="dateHeader">
          {date === CURRENT_DATE_LABEL ? date : toFormattedHeaderDate(date)}
        </div>
      ),
      [DIMENSIONS.NETWORK]: network => (
        <div className="networkHeader">
          <Img src={`${CDN_NETWORK_LINK}${encodeURIComponent(network.split(" ")[0])}.png`} />
          <div>{network}</div>
        </div>
      ),
      [DIMENSIONS.NETWORK_GROUP]: network => (
        <div className="networkHeader">
          <Img src={`${CDN_NETWORK_LINK}${encodeURIComponent(network.toUpperCase())}.png`} />
          <div>{network}</div>
        </div>
      ),
      [DIMENSIONS.ROTATION]: rotation => <RotationHeader rotation={rotation} />,
      [DIMENSIONS.ISCI]: isci => (
        <div className="isciHeader">
          <div className="swatch" style={{ backgroundColor: colorMap[isci] || "white" }}></div>
          {isci}
        </div>
      ),
      [DIMENSIONS.CREATIVE]: creative => (
        <div className="creativeHeader">
          <Img src={getCreativeThumbnail(company, creativeToImage[creative])} />
          <div>{creative}</div>
        </div>
      ),
      PLACEMENT: id => (
        <div key={id} className="placementRow">
          {id} | {placementMap[id].name} | {placementMap[id].vendor}
        </div>
      ),
    }),
    [colorMap, company, creativeToImage, placementMap]
  );

  const filterBarLines = useMemo(() => R.map(addLineMetadata)(groupableData), [
    groupableData,
    addLineMetadata,
  ]);

  // Default to true so the button is not enabled before the fetch finishes.
  const [hasPendingCreatives, setHasPendingCreatives] = useState(true);

  useEffect(() => {
    (async () => {
      if (company) {
        try {
          let res = await StreamingLambdaFetch("/getPendingCreatives", {
            params: {
              company,
            },
          });
          const pendingCreatives = await awaitJSON(res);
          setHasPendingCreatives(Boolean(Object.keys(pendingCreatives).length));
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      }
    })();
  }, [company, setError, tab]);

  return (
    <div className="streamingCreativesCardContainer">
      <Card className="streamingCreativesCard">
        <div className="generate">
          <div className="generateAction">
            <ToggleNav
              size="lg"
              className="generateViewToggles"
              activeKey={selectedGrouping}
              onChange={setSelectedGrouping}
            >
              {R.pipe(
                R.keys,
                R.map(key => (
                  <ToggleNavButton key={key} value={key}>
                    {GROUPING_OPTIONS[key]}
                  </ToggleNavButton>
                ))
              )(GROUPING_OPTIONS)}
            </ToggleNav>
          </div>
          <div className="generateAction">
            <OldFilterBar
              options={FILTER_BAR_OPTIONS}
              lines={filterBarLines}
              onFilter={setFilter}
            />
          </div>
          <div className="generateContent">
            <TypeRendererContext.Provider value={typeRenderers}>
              <CollapsingGroup group={groupedData} types={currentGroupingOrder} />
            </TypeRendererContext.Provider>
          </div>
          <div className="buttonsContainer">
            <Button
              variant="outline-dark"
              size="lg"
              onClick={() => {
                goToTab(APPROVALS_KEY);
              }}
            >
              Back
            </Button>
            <Button
              disabled={
                generating ||
                (approvalStageData?.stage !== ApprovalStages.APPROVED && hasPendingCreatives)
              }
              variant="primary"
              size="lg"
              onClick={() =>
                setAreYouSure({
                  title: "You're sending an update to Extreme Reach",
                  message:
                    "You are about to upload these changes directly to Extreme Reach. Are you sure you want to continue?",
                  variant: "warning",
                  cancelText: "Never mind",
                  okayText: "Yes",
                  onOkay: () => generateXR(),
                })
              }
            >
              {generating ? <Spinner size={19} color="white" /> : "Upload to Extreme Reach"}
            </Button>
            <Button
              disabled={
                generating ||
                (approvalStageData?.stage !== ApprovalStages.APPROVED && hasPendingCreatives)
              }
              variant="primary"
              size="lg"
              onClick={() =>
                setAreYouSure({
                  title: "You're sending an update to Flashtalking",
                  message:
                    "You are about to upload these changes directly to Flashtalking. Are you sure you want to continue?",
                  variant: "warning",
                  cancelText: "Never mind",
                  okayText: "Yes",
                  onOkay: () => generateFlashtalking(),
                })
              }
            >
              {generating ? <Spinner size={19} color="white" /> : "Upload to Flashtalking"}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Generate;
