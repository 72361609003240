import "./FetchFilterBar.scss";
import { areFilterStatesEqual } from "../MetricsTable/metricsTableUtils";
import { Button, ButtonType, OverlayTrigger, TextToggleButton } from "../../Components";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import { FiltersLambdaFetch, awaitJSON } from "../../utils/fetch-utils";
import { Form, Modal, Tooltip } from "react-bootstrap";
import { StateSetter } from "../../utils/types";
import { useSetAreYouSure, useSetError } from "../../redux/modals";
import * as R from "ramda";
import * as uuid from "uuid";
import FetchFilterBarChip from "./FetchFilterBarChip";
import FilterPaneAdvanced from "./FilterPaneAdvanced";
import FilterPaneBasic from "./FilterPaneBasic";
import PresetDropdown from "../PresetDropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RenameModal from "../RenameModal";
import {
  Category,
  FilterPaneAdvancedState,
  FilterPaneBasicState,
  FilterPaneState,
} from "@blisspointmedia/bpm-types/dist/FilterPane";
import {
  filterTokenizer,
  tokensToWhereClause,
} from "@blisspointmedia/bpm-types/dist/advancedFilterParser";
import {
  DeleteFilterPresetParams,
  GetFilterPresetsParams,
  SaveFilterPresetBody,
} from "@blisspointmedia/bpm-types/dist/FilterPresets";
import {
  MdCancel,
  MdCircle,
  MdEdit,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdOutlineCircle,
  MdSave,
} from "react-icons/md";

export const sortCategories = (categories: Category[], state: FilterPaneState): Category[] =>
  R.sort((cat1, cat2) => {
    let itemCount1 = 0;
    let itemCount2 = 0;
    const notMap = state && state.basic && state.basic.notMap ? state.basic.notMap : {};
    const selectedMap =
      state && state.basic && state.basic.selectedMap ? state.basic.selectedMap : {};
    for (const notKey of R.keys(R.defaultTo({}, notMap[cat1.key]))) {
      if (notMap[cat1.key][notKey]) {
        itemCount1++;
      }
    }
    for (const selectedKey of R.keys(R.defaultTo({}, selectedMap[cat1.key]))) {
      if (selectedMap[cat1.key][selectedKey]) {
        itemCount1++;
      }
    }
    for (const notKey of R.keys(R.defaultTo({}, notMap[cat2.key]))) {
      if (notMap[cat2.key][notKey]) {
        itemCount2++;
      }
    }
    for (const selectedKey of R.keys(R.defaultTo({}, selectedMap[cat2.key]))) {
      if (selectedMap[cat2.key][selectedKey]) {
        itemCount2++;
      }
    }
    return itemCount1 > itemCount2 ? -1 : 1;
  }, categories);

export const DEFAULT_FILTER_PANE_STATE = {
  isAdvanced: false,
  basic: {
    notMap: {},
    selectedMap: {},
  },
  advanced: "",
};

export const useFilterPaneState = (
  init?: FilterPaneState
): [FilterPaneState, StateSetter<FilterPaneState>, () => void] => {
  const [state, setState] = useState<FilterPaneState>({ ...DEFAULT_FILTER_PANE_STATE, ...init });
  const resetState = useCallback(() => setState(DEFAULT_FILTER_PANE_STATE), []);
  return [state, setState, resetState];
};

export const hasFilter = (state: FilterPaneState): boolean => {
  if (state.isAdvanced) {
    return Boolean(state.advanced);
  }
  for (const category of R.keys(state.basic.selectedMap)) {
    for (const option of R.keys(state.basic.selectedMap[category])) {
      if (state.basic.selectedMap[category][option]) {
        return true;
      }
    }
  }
  return false;
};

export interface FetchFilterBarProps {
  categories: Category[];
  className?: string;
  company?: string;
  disabled?: boolean;
  filterID?: number;
  filters: FilterPreset[] | undefined;
  platform?: string;
  readonly?: boolean;
  refreshData?: () => void;
  setFilterID: StateSetter<number | undefined>;
  setFilters: StateSetter<FilterPreset[] | undefined>;
  setFilterSource: StateSetter<
    "presetFilterID" | "currentFilterID" | "presetState" | "currentState"
  >;
  setState: StateSetter<FilterPaneState>;
  state: FilterPaneState;
}

export const FetchFilterBar: React.FC<FetchFilterBarProps> = React.memo(
  ({
    categories = [],
    className,
    company,
    disabled,
    filterID,
    filters,
    platform,
    readonly,
    refreshData = () => {},
    setFilterID, // Sets the filter ID at the top level
    setFilters,
    setFilterSource,
    setState, // Updates the state at the top level
    state,
  }) => {
    const [advanced, setAdvanced] = useState<FilterPaneAdvancedState>(state.advanced);
    const [basic, setBasic] = useState<FilterPaneBasicState>(state.basic);
    const [compileError, setCompileError] = useState<string>("");
    const [expanded, setExpanded] = useState<boolean>(false);
    const [fetchingFilters, setFetchingFilters] = useState<boolean>(false);
    const [initialOpenSection, setInitialOpenSection] = useState<string>("" as string);
    const [isAdvanced, setIsAdvanced] = useState<boolean>(state.isAdvanced);
    const [newFilterName, setNewFilterName] = useState<string | undefined>();
    const [renameFilterOnSave, setRenameFilterOnSave] = useState<boolean>(false);
    const [saveAsNew, setSaveAsNew] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<FilterPreset | undefined>();
    const [showCreateFilterModal, setShowCreateFilterModal] = useState<boolean>(false);
    const [showEditFilterModal, setShowEditFilterModal] = useState<boolean>(false);
    const [showRenameFilterModal, setShowRenameFilterModal] = useState<boolean>(false);
    const [showSaveAsNewFilterModal, setShowSaveAsNewFilterModal] = useState<boolean>(false);
    const [showSaveFilterModal, setShowSaveFilterModal] = useState<boolean>(false);
    const [previousModal, setPreviousModal] = useState<string>("");
    const setAreYouSure = useSetAreYouSure();
    const setError = useSetError();

    const filteredCategories = R.filter(
      category => R.toLower(category.key) !== "disabled",
      R.defaultTo([], categories) as Category[]
    );
    const filtersMap = useMemo(() => {
      const filterMap: Record<string, FilterPreset> = {};
      if (filters) {
        for (const filter of filters) {
          filterMap[filter.id] = filter;
        }
      }
      return filterMap;
    }, [filters]);

    // These are internal variables to track changes to the filter bar. When we want to propagate
    // these to the top level we will call the save function or the set state function.
    const loadState = useCallback(state => {
      setIsAdvanced(state.isAdvanced);
      setBasic(state.basic);
      setAdvanced(state.advanced);
    }, []);

    const reset = useCallback(() => {
      loadState(state);
    }, [loadState, state]);

    // Take existing internal state and get current tokens
    const refreshFilterState = useCallback(
      filterState => {
        if (isAdvanced) {
          const tokens = filterTokenizer(advanced);
          try {
            tokensToWhereClause(tokens);
          } catch (e) {
            const error = e as Error;
            setCompileError(error.message);
            setError(error);
          }
        }
        const refreshedFilterState = { ...filterState };
        if (filteredCategories && !R.isEmpty(filteredCategories)) {
          // Transform the filter option into a map to better access data;
          const filterMap = {};
          for (const elem of filteredCategories) {
            const elemMap = {};
            for (const entry of elem.options) {
              if (entry) {
                // Some of these entries are strings are others are label, value pairs so check
                // before assigning
                if (entry[`${"label"}`]) {
                  elemMap[entry[`${"label"}`]] = true;
                }
                if (entry[`${"value"}`]) {
                  elemMap[entry[`${"value"}`]] = true;
                }
                if (R.isNil(entry[`${"label"}`]) && R.isNil(entry[`${"value"}`])) {
                  elemMap[entry as string] = true;
                }
              }
            }
            filterMap[elem.key.toLowerCase()] = elemMap;
          }
          if (refreshedFilterState.basic) {
            for (const map of R.keys(refreshedFilterState.basic)) {
              for (const category of R.keys(refreshedFilterState.basic[map])) {
                for (const key of R.keys(refreshedFilterState.basic[map][category])) {
                  if (!filterMap[(category as string).toLowerCase()][key]) {
                    delete refreshedFilterState.basic[map][category][key];
                  }
                }
              }
            }
          }
          if (refreshedFilterState.advanced) {
            for (const map of R.keys(refreshedFilterState.advanced)) {
              for (const category of R.keys(refreshedFilterState.advanced[map])) {
                for (const key of R.keys(refreshedFilterState.advanced[map][category])) {
                  if (!filterMap[(category as string).toLowerCase()][key]) {
                    delete refreshedFilterState.advanced[map][category][key];
                  }
                }
              }
            }
          }
        }
        return refreshedFilterState;
      },
      [advanced, filteredCategories, isAdvanced, setError]
    );

    const clear = useCallback(() => {
      setBasic({
        notMap: {},
        selectedMap: {},
      });
      setAdvanced("");
    }, []);

    const closeModals = useCallback(
      (revertToPreviousSelectedFilter = true) => {
        setNewFilterName(undefined);
        setPreviousModal("");
        if (revertToPreviousSelectedFilter) {
          setSelectedFilter(filtersMap[`${filterID}`]);
        }
        setShowCreateFilterModal(false);
        setShowEditFilterModal(false);
        setShowRenameFilterModal(false);
        setShowSaveAsNewFilterModal(false);
        setShowSaveFilterModal(false);
      },
      [filterID, filtersMap]
    );

    const getFilters = useCallback(() => {
      (async () => {
        try {
          setFetchingFilters(true);
          if (company && platform) {
            const params = {
              company,
              platform,
            };
            const res = await FiltersLambdaFetch<GetFilterPresetsParams>("/getFilters", {
              params,
            });
            const parsedFilterPresets = await awaitJSON(res);
            const filters: FilterPreset[] = parsedFilterPresets.length ? parsedFilterPresets : [];
            setFilters(filters);
            if (filterID) {
              for (const filter of filters) {
                if (filterID === filter.id) {
                  setSelectedFilter(filter);
                  if (filter.filter_state) {
                    setState(filter.filter_state);
                    setAdvanced(filter.filter_state.advanced);
                    setBasic(filter.filter_state.basic);
                    setIsAdvanced(filter.isAdvanced);
                  }
                }
              }
            }
          }
        } catch (e) {
          const error = e as Error;
          setError({
            reportError: error,
            message: `Failed to fetch filter presets. Error: ${error.message}`,
          });
        } finally {
          setFetchingFilters(false);
        }
      })();
    }, [company, filterID, platform, setError, setFilters, setState]);

    const saveFilter = useCallback(
      ({
        filter,
        refreshView,
        saveOnly,
      }: {
        filter: FilterPreset;
        refreshView?: boolean;
        saveOnly?: boolean;
      }) => {
        (async () => {
          // Update latest state we have setup in the filter bar
          const newFilterState = refreshFilterState(filter.filter_state);
          // Build the payload
          let filterToBeSaved: Partial<FilterPreset> = {
            company,
            filter_state: newFilterState,
            name: filter.name,
            isAdvanced: isAdvanced,
            platform,
          };
          if (!R.isNil(filter.id)) {
            filterToBeSaved.id = filter.id;
          }
          if (
            R.isNil(filterToBeSaved.name) ||
            filterToBeSaved.name.trim().length < 1 ||
            filterToBeSaved.name.trim().toLowerCase() === "none"
          ) {
            throw new Error("Must provide a valid filter name");
          }
          try {
            const body = {
              filter: filterToBeSaved as FilterPreset,
            };
            const res = await FiltersLambdaFetch<SaveFilterPresetBody>("/saveFilter", {
              method: "POST",
              body,
            });
            setFetchingFilters(true);
            const savedFilter: FilterPreset = await awaitJSON(res);
            getFilters();
            if (refreshView && !saveOnly) {
              refreshData();
            }
            if (!saveOnly) {
              setFilterID(savedFilter.id);
              setSelectedFilter(savedFilter);
              setState(newFilterState);
              setNewFilterName(savedFilter.name);
            }
          } catch (e) {
            const reportError = e as Error;
            setCompileError(reportError.message);
            setError({
              reportError,
              message: `Failed to save filter preset. Error: ${reportError.message}`,
            });
          } finally {
            closeModals();
          }
        })();
      },
      [
        closeModals,
        company,
        getFilters,
        isAdvanced,
        platform,
        refreshData,
        refreshFilterState,
        setError,
        setFilterID,
        setState,
      ]
    );

    const deleteFilter = useCallback(
      (id: number) => {
        setAreYouSure({
          title: "Delete Filter Preset",
          message: "You are about to delete this filter preset, are you sure?",
          variant: "warning",
          cancelText: "Never mind",
          okayText: "Yes",
          onOkay: async () => {
            if (id) {
              setFetchingFilters(true);
              const params: DeleteFilterPresetParams = {
                id,
              };
              try {
                await FiltersLambdaFetch<DeleteFilterPresetParams>("/deleteFilter", {
                  method: "DELETE",
                  params,
                });
              } catch (e) {
                let error = e as Error;
                setError({
                  reportError: error,
                  message: `Failed to save filter preset. Error: ${error.message}`,
                });
              } finally {
                getFilters();
              }
            }
            if (selectedFilter && selectedFilter.id === id) {
              setSelectedFilter(undefined);
              setFilterID(undefined);
              setState(DEFAULT_FILTER_PANE_STATE);
              clear();
            }
          },
        });
      },
      [clear, getFilters, selectedFilter, setAreYouSure, setError, setFilterID, setState]
    );

    const resolvedClassName = useMemo(() => {
      const classes: string[] = ["fetchFilterBar"];
      if (disabled) {
        classes.push("disabled");
      }
      if (className) {
        classes.push(className);
      }
      if (expanded) {
        classes.push("expanded");
      }
      return classes.join(" ");
    }, [className, disabled, expanded]);

    useEffect(() => {
      reset();
    }, [state, reset]);

    useEffect(() => {
      if (R.isNil(filters)) {
        getFilters();
      }
    }, [filters, getFilters]);

    useEffect(() => {
      if (R.isNil(selectedFilter)) {
        setSaveAsNew(true);
      }
    }, [selectedFilter]);

    const saveDisabled = useMemo(
      () => (state.isAdvanced ? !state.advanced.length : !R.keys(state.basic.selectedMap).length),
      [state]
    );

    const isEdited = useMemo(() => {
      if (selectedFilter && selectedFilter.filter_state) {
        return !areFilterStatesEqual(selectedFilter.filter_state, state);
      }
      return false;
    }, [selectedFilter, state]);

    // React Components
    const presetDropdown = useMemo(
      () => (
        <div className="presetDropdown">
          <PresetDropdown
            addPresetOnClick={() => {
              setSelectedFilter({
                id: -1,
                name: "My New Preset 1",
                filter_state: {
                  advanced: "",
                  basic: {
                    notMap: {},
                    selectedMap: {},
                  },
                  isAdvanced: false,
                },
                company: "",
                filter_tokens: [],
                isAdvanced: false,
                lastmodified: "",
                lastuser: "",
              });
              setNewFilterName("My New Preset 1");
              setShowCreateFilterModal(true);
            }}
            addPresetText={"Create New Preset"}
            allowNoSelections={true}
            applyOnClick={preset => {
              if (preset) {
                setFilterID(preset.id);
                setSelectedFilter(preset);
                setState(preset.filter_state);
                loadState(preset.filter_state);
                setFilterSource("currentFilterID");
              } else {
                setFilterSource("currentState");
                setFilterID(undefined);
                setSelectedFilter(undefined);
                setState(DEFAULT_FILTER_PANE_STATE);
                loadState(DEFAULT_FILTER_PANE_STATE);
                clear();
              }
            }}
            cancelOnClick={() => {}}
            className={`filterPresetPicker ${fetchingFilters ? "loading" : ""}`}
            deleteItemOnClick={presetID => deleteFilter(presetID)}
            duplicateItemOnClick={preset =>
              saveFilter({
                filter: {
                  ...(R.omit(["id"], preset) as FilterPreset),
                  name: `${preset.name} (Copy)`,
                },
                refreshView: false,
                saveOnly: true,
              })
            }
            editItemOnClick={preset => {
              setSelectedFilter(preset);
              loadState(preset.filter_state);
              setShowEditFilterModal(true);
            }}
            hasTicks={true}
            label={
              filtersMap && !R.isNil(filterID) && filtersMap[filterID]
                ? `${filtersMap[filterID].name}${isEdited ? " [edited]" : ""}`
                : "Load Filter Preset"
            }
            presets={R.defaultTo([], filters)}
            renameItemOnClick={presetID => {
              setSelectedFilter(filtersMap[presetID]);
              loadState(filtersMap[presetID].filter_state);
              setShowRenameFilterModal(true);
            }}
            selectedPreset={
              filtersMap && !R.isNil(filterID) && filtersMap[filterID]
                ? filtersMap[filterID]
                : undefined
            }
          />
        </div>
      ),
      [
        clear,
        deleteFilter,
        fetchingFilters,
        filterID,
        filters,
        filtersMap,
        isEdited,
        loadState,
        saveFilter,
        setFilterID,
        setFilterSource,
        setState,
      ]
    );

    const tokenDropdowns = (
      <div className="tokenDropdowns" id="tokenDropdowns">
        {state.isAdvanced ? (
          <FetchFilterBarChip
            advanced={state.advanced}
            expanded={expanded}
            setInitialOpenSection={setInitialOpenSection}
            setShowEditFilterModal={setShowEditFilterModal}
          />
        ) : (
          R.map(
            category => (
              <FetchFilterBarChip
                category={category}
                expanded={expanded}
                filterState={state}
                setInitialOpenSection={setInitialOpenSection}
                setShowEditFilterModal={setShowEditFilterModal}
              />
            ),
            sortCategories(categories, state) as Category[]
          )
        )}
      </div>
    );

    const tokenDropdownsReference = document.getElementById("tokenDropdowns");
    const tokenDropdownsHeight =
      tokenDropdownsReference && tokenDropdownsReference.offsetHeight
        ? R.defaultTo(0, tokenDropdownsReference.offsetHeight)
        : Infinity;

    // Check if we have a lot of items in a category so we can expand bar
    const expandItems = R.uniq(
      R.map(cat => {
        let count = 0;
        if (basic.selectedMap && basic.selectedMap[cat.key]) {
          for (const key of R.keys(basic.selectedMap[cat.key])) {
            if (basic.selectedMap[cat.key][key]) {
              count++;
            }
          }
        }
        return count > 3;
      }, filteredCategories)
    ).includes(true);

    const controls = (
      <div className="controls">
        <div
          className="editFilterContainer"
          onClick={() => {
            setShowEditFilterModal(true);
            if (selectedFilter) {
              setNewFilterName("My New Preset");
            }
          }}
        >
          <div className="editFilterIcon">
            <MdEdit />
          </div>
          <div className="editFilterText">Edit Filters</div>
        </div>
        {(expandItems || isAdvanced || tokenDropdownsHeight > 28) && (
          <OverlayTrigger
            overlay={<Tooltip id={uuid.v4()}>Show all filters</Tooltip>}
            placement="bottom center"
          >
            <div className="expandContainer" onClick={() => setExpanded(!expanded)}>
              {expanded ? <MdOutlineArrowBackIos /> : <MdOutlineArrowForwardIos />}
            </div>
          </OverlayTrigger>
        )}
        <OverlayTrigger overlay={<Tooltip id={uuid.v4()}>Save</Tooltip>} placement="bottom center">
          <div
            className={`saveContainer ${saveDisabled ? "disabled" : ""}`}
            onClick={() => {
              if (selectedFilter) {
                setShowSaveFilterModal(true);
              } else {
                setShowSaveAsNewFilterModal(true);
              }
            }}
          >
            <MdSave />
          </div>
        </OverlayTrigger>
      </div>
    );

    const saveModalOverwriteOption = selectedFilter && (
      <div
        className="saveOptionContainer"
        onClick={() => {
          setSaveAsNew(false);
          setNewFilterName(undefined);
        }}
      >
        <div className="saveOption">
          <div className="saveOptionTick">{saveAsNew ? <MdOutlineCircle /> : <MdCircle />}</div>
          <div className="saveOptionText">{`Save ${selectedFilter.name}`}</div>
        </div>
      </div>
    );

    const saveModalAsNewOption = (
      <div
        className="saveOptionContainer"
        onClick={() => {
          setSaveAsNew(true);
        }}
      >
        <div
          className={`saveOption ${
            showRenameFilterModal || showSaveAsNewFilterModal ? "onlyOption" : ""
          }`}
        >
          <div
            className={`saveOptionTick ${
              showRenameFilterModal || showSaveAsNewFilterModal ? "transparent" : ""
            }`}
          >
            {saveAsNew ? <MdCircle /> : <MdOutlineCircle />}
          </div>
          <div className="saveOptionText">Save as New Preset</div>
        </div>
        <div className="saveInputContainer">
          <Form.Control
            className="saveInput"
            onChange={e => setNewFilterName(e.target.value)}
            placeholder="Enter new preset name"
            value={newFilterName}
          />
        </div>
      </div>
    );

    const saveModal = showSaveFilterModal && (
      <Modal show centered size="lg" onHide={() => closeModals()} className="saveModal">
        <Modal.Header>
          <Modal.Title className="saveHeader">Save Preset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {saveModalOverwriteOption}
          {saveModalAsNewOption}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelButton"
            design="secondary"
            onClick={() => closeModals()}
            size={"lg"}
            type={ButtonType.OUTLINED}
          >
            {showRenameFilterModal ? "Discard" : "Cancel"}
          </Button>
          <Button
            className="applyButton"
            onClick={() => {
              saveFilter({
                filter: {
                  company,
                  filter_state: state,
                  filter_tokens: [],
                  isAdvanced: state.isAdvanced,
                  name: newFilterName,
                } as any,
                refreshView: false,
                saveOnly: true,
              });
            }}
            size={"lg"}
            type={ButtonType.FILLED}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );

    const renameSaveAsNewModalTitle = showSaveAsNewFilterModal
      ? "Save as New Preset"
      : showRenameFilterModal
      ? `Rename ${selectedFilter && selectedFilter.name ? selectedFilter.name : "Preset"}`
      : "Save Preset";

    const renameSaveAsNewModal = (showRenameFilterModal || showSaveAsNewFilterModal) && (
      <RenameModal
        applyOnClick={newName => {
          if (showSaveAsNewFilterModal && previousModal === "editFilters") {
            setFilterSource("presetFilterID");
          }
          saveFilter({
            filter: showSaveAsNewFilterModal
              ? ({
                  company,
                  filter_state: { advanced, basic, isAdvanced },
                  filter_tokens: [],
                  isAdvanced: isAdvanced,
                  name: newName,
                } as any)
              : {
                  ...selectedFilter,
                  name: newName,
                },
            refreshView: showSaveAsNewFilterModal,
            saveOnly: showSaveAsNewFilterModal,
          });
        }}
        applyText={
          showSaveAsNewFilterModal && previousModal === "editFilters" ? "Save and Apply" : "Save"
        }
        cancelText={showRenameFilterModal ? "Discard" : "Cancel"}
        onCancel={() => {
          if (showSaveAsNewFilterModal && previousModal === "editFilters") {
            setShowSaveAsNewFilterModal(false);
            setShowEditFilterModal(true);
          } else {
            closeModals();
          }
        }}
        onHide={() => closeModals()}
        placeholder="Enter new preset name"
        subTitle={
          showRenameFilterModal || showSaveAsNewFilterModal
            ? "New Preset Name"
            : "Save as New Preset"
        }
        title={renameSaveAsNewModalTitle}
      />
    );

    const editFilterModal = (showEditFilterModal || showCreateFilterModal) && (
      <Modal show centered size="lg" onHide={() => closeModals()} className="editModal">
        <Modal.Header>
          <div className="filterTitleContainer">
            {((showEditFilterModal && !R.isNil(selectedFilter)) || showCreateFilterModal) && (
              <div className="modalType">
                {showEditFilterModal ? "Edit Preset" : "Create New Preset"}
              </div>
            )}
            <div className="filterTitle">
              {showEditFilterModal && R.isNil(selectedFilter) ? (
                <Modal.Title>Edit Filters</Modal.Title>
              ) : renameFilterOnSave ? (
                <Form.Control
                  className="saveInput"
                  onChange={e => setNewFilterName(e.target.value)}
                  value={newFilterName}
                />
              ) : (
                <Modal.Title>
                  {selectedFilter &&
                  ((showEditFilterModal && !R.isNil(selectedFilter)) || showCreateFilterModal)
                    ? selectedFilter.name
                    : newFilterName}
                </Modal.Title>
              )}
              {((showEditFilterModal && !R.isNil(selectedFilter)) || showCreateFilterModal) && (
                <div
                  className="editIconContainer"
                  onClick={() => setRenameFilterOnSave(!renameFilterOnSave)}
                >
                  {renameFilterOnSave ? <MdCancel /> : <MdEdit />}
                </div>
              )}
            </div>
          </div>
          <TextToggleButton
            options={["Basic", "Advanced"]}
            selectedOption={isAdvanced ? "Advanced" : "Basic"}
            onChange={option => setIsAdvanced(option === "Advanced")}
          />
        </Modal.Header>
        <Modal.Body>
          {isAdvanced ? (
            <FilterPaneAdvanced
              categories={filteredCategories}
              state={advanced}
              setState={setAdvanced}
              compileError={compileError}
              readonly={readonly}
              setEditing={() => {}}
            />
          ) : (
            <FilterPaneBasic
              categories={filteredCategories}
              state={basic}
              initialOpenSection={
                initialOpenSection
                  ? initialOpenSection
                  : filteredCategories[0]
                  ? filteredCategories[0].key
                  : ""
              }
              setState={setBasic}
              readonly={readonly}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelButton"
            design="secondary"
            onClick={() => closeModals()}
            size={"lg"}
            type={ButtonType.EMPTY}
          >
            Cancel
          </Button>
          <Button
            className="applyButton"
            onClick={() => {
              if (showCreateFilterModal) {
                saveFilter({
                  filter: {
                    company,
                    filter_state: { advanced, basic, isAdvanced },
                    filter_tokens: [],
                    isAdvanced: isAdvanced,
                    name: selectedFilter ? selectedFilter.name : newFilterName,
                  } as any,
                  saveOnly: true,
                });
                closeModals(false);
              } else if (showEditFilterModal && !R.isNil(selectedFilter)) {
                setFilterSource("currentState");
                setState(refreshFilterState({ advanced, basic, isAdvanced }));
                if (selectedFilter && selectedFilter.id) {
                  setFilterID(selectedFilter.id);
                }
                refreshData();
                closeModals();
              } else {
                setPreviousModal("editFilters");
                setShowEditFilterModal(false);
                setShowCreateFilterModal(false);
                setShowSaveAsNewFilterModal(true);
              }
            }}
            size={"lg"}
            type={ButtonType.OUTLINED}
          >
            {showCreateFilterModal
              ? "Save as Preset"
              : showEditFilterModal && !R.isNil(selectedFilter)
              ? "Apply"
              : "Save as New Preset"}
          </Button>
          <Button
            className="saveAndApplyButton"
            onClick={() => {
              if (showCreateFilterModal) {
                setFilterSource("presetFilterID");
                saveFilter({
                  filter: {
                    company,
                    filter_state: { advanced, basic, isAdvanced },
                    filter_tokens: [],
                    isAdvanced: isAdvanced,
                    name: newFilterName,
                  } as any,
                  refreshView: true,
                });
              } else if (showEditFilterModal && !R.isNil(selectedFilter)) {
                setFilterSource("currentFilterID");
                saveFilter({
                  filter: {
                    ...selectedFilter,
                    filter_state: { advanced, basic, isAdvanced },
                  },
                  refreshView: true,
                });
              } else {
                setFilterSource("currentState");
                const refreshedFilterState = refreshFilterState({ advanced, basic, isAdvanced });
                loadState(refreshedFilterState);
                setState(refreshedFilterState);
                setFilterID(undefined);
                refreshData();
              }
              closeModals();
            }}
            size={"lg"}
            type={ButtonType.FILLED}
          >
            {showCreateFilterModal || (showEditFilterModal && !R.isNil(selectedFilter))
              ? "Save and Apply"
              : "Apply"}
          </Button>
        </Modal.Footer>
      </Modal>
    );

    return (
      <div className={resolvedClassName}>
        {renameSaveAsNewModal}
        {saveModal}
        {editFilterModal}
        {presetDropdown}
        {tokenDropdowns}
        {controls}
      </div>
    );
  }
);
