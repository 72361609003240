import React from "react";
import * as R from "ramda";

interface MetaBuyingNamePreviewProps {
  dimensions: Record<string, any>;
  nameFields: Record<string, any>;
  namingConvention: number[];
  inheritedFields?: string[];
}
const { CLIENT, AGENCY } = {
  CLIENT: "CLIENT",
  AGENCY: "AGENCY",
};

export const MetaBuyingNamePreview = ({
  dimensions,
  nameFields,
  namingConvention,
  inheritedFields,
}: MetaBuyingNamePreviewProps): JSX.Element => (
  <span className="metaBuyingNamePreview">
    {R.keys(nameFields).map((dimensionId, index) =>
      R.isEmpty(nameFields[dimensionId]) ? (
        <span key={index} className="placeholder">
          {dimensions[dimensionId].name.replace(" ", "")}
          {index < namingConvention.length - 1 ? "_" : ""}
        </span>
      ) : (
        <span
          key={index}
          className={
            ((inheritedFields && !R.includes(dimensionId, inheritedFields)) ||
              R.isNil(inheritedFields)) &&
            dimensions[dimensionId].type !== CLIENT &&
            dimensions[dimensionId].type !== AGENCY
              ? "constructed"
              : ""
          }
        >
          {nameFields[dimensionId]}
          {index < namingConvention.length - 1 ? "_" : ""}
        </span>
      )
    )}
  </span>
);
