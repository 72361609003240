import React, { useContext, useState } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import {
  FilterTableContainer,
  NumberFormatter,
  FullPageSpinner,
  OverlayTrigger,
  Spinner,
  OldDropdown,
  SingleDatePicker,
} from "../Components";
import {
  Button,
  Tooltip,
  Modal,
  Form,
  Row,
  DropdownButton,
  Dropdown,
  Alert,
} from "react-bootstrap";
import {
  MdPlayArrow,
  MdContentCopy,
  MdDelete,
  MdAdd,
  MdAttachMoney,
  MdPeople,
  MdDirectionsCar,
} from "react-icons/md";
import { LinearOptimizationsContext, ROUTES } from "./LinearOptimizations";
import "./LinearOptimizations.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const CUTOFF_DATE = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

const OptimizationHome = ({ navigate }) => {
  const {
    optimizations,
    optimizationKPIs,
    constraintsList,
    copyConstraint,
    newConstraint,
    deleteConstraint,
    runOptimization,
    refreshingOptimizations,
    validWeeks,
  } = useContext(LinearOptimizationsContext);
  const [showCopyConstraint, setShowCopyConstraint] = useState(false);
  const [showNewConstraint, setShowNewConstraint] = useState(false);
  const [showDeleteConstraint, setShowDeleteConstraint] = useState(false);
  const [showRunOptimization, setShowRunOptimization] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState();
  const [fromConstraintId, setFromConstraintId] = useState();
  const [deleteConstraintId, setDeleteConstraintId] = useState();
  const [runOptimizationId, setRunOptimizationId] = useState();
  const [runOptimizationType, setRunOptimizationType] = useState();
  const [runOptimizationWeek, setRunOptimizationWeek] = useState();

  const isConstraintNameValid = name => {
    return name.length > 0 && name !== "." && name !== "..";
  };

  const LogsModal = ({ show, setShowLogsModal }) => {
    if (!show) {
      return null;
    }

    return (
      <Modal size="lg" keyboard={true} show={Boolean(show)} onHide={() => setShowLogsModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Logs for build {show.buildNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnRlx-sns-prod-us-west-2;filter=${show.kpi}_${show.buildNumber}_linear;start=${show.start};end=${show.end}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>IterationConstraints</h2>
          </a>
          <a
            href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-python-optimizer-prod-OptimizeIteration;filter=${show.kpi}_${show.buildNumber}_linear;start=${show.start};end=${show.end}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>PyIPOPT</h2>
          </a>
          <a
            href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnPvt-sns-prod-us-west-2;filter=${show.kpi}_${show.buildNumber}_linear;start=${show.start};end=${show.end}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>IterationPivots</h2>
          </a>
        </Modal.Body>
      </Modal>
    );
  };

  const NewConstraintConfirmation = ({ show, setShowNewConstraint, newConstraint }) => {
    const [toConstraintId, setToConstraintId] = useState("");
    const [optimizationType, setOptimizationType] = useState("Buying");
    const [week, setWeek] = useState();

    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowNewConstraint(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Constraint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={toConstraintId}
              onChange={e => setToConstraintId(e.target.value.replace(/ /g, ""))}
            />
          </Form.Group>
          <Form.Group as={Row} style={{ justifyContent: "space-between", margin: "0 1rem 1rem 0" }}>
            <Form.Group>
              <OldDropdown
                label="Optimization Type"
                value={optimizationType}
                options={["Buying", "Traffic"]}
                onChange={type => setOptimizationType(type)}
              />
            </Form.Group>
            {optimizationType === "Traffic" ? (
              <Form.Group as={Row}>
                <Form.Label style={{ paddingTop: "6px" }}>Allocation Week</Form.Label>
                <SingleDatePicker
                  mondayOnly
                  date={week}
                  placeholder="Select Week"
                  showClearDate
                  isOutsideRange={date => date < CUTOFF_DATE || !validWeeks.includes(date)}
                  onChange={date => setWeek(date)}
                />
              </Form.Group>
            ) : (
              false
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
            overlay={
              (optimizationType === "Traffic" && !week) ||
              !isConstraintNameValid(toConstraintId) ? (
                !isConstraintNameValid(toConstraintId) ? (
                  <Tooltip>Name your constraint to continue</Tooltip>
                ) : (
                  <Tooltip>Select an allocation week to continue</Tooltip>
                )
              ) : (
                <></>
              )
            }
          >
            <div style={{ position: "relative" }}>
              {(optimizationType === "Traffic" && !week) ||
              !isConstraintNameValid(toConstraintId) ? (
                <div
                  style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
                ></div>
              ) : (
                false
              )}
              <Button
                variant="success"
                disabled={
                  (optimizationType === "Traffic" && !week) ||
                  !isConstraintNameValid(toConstraintId)
                }
                onClick={async () => {
                  setShowNewConstraint(false);
                  await newConstraint({
                    to: toConstraintId,
                    optimizationType: optimizationType.toLowerCase(),
                    week,
                  });
                }}
              >
                Create
              </Button>
            </div>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
    );
  };

  const CopyConstraintConfirmation = ({
    show,
    setShowCopyConstraint,
    copyConstraint,
    fromConstraintId,
    fromWeek,
    fromOptimizationType,
  }) => {
    const [toConstraintId, setToConstraintId] = useState("");
    const optimizationType = fromOptimizationType
      ? fromOptimizationType[0].toUpperCase() + fromOptimizationType.slice(1)
      : "";
    const [week, setWeek] = useState(fromWeek);
    // if non experiment parse the name so the old system can use the new API
    const parseConstraintNameIfExperiment = parseType => {
      const isWeek = parseType === "week";
      return () => {
        return isWeek ? week : optimizationType.toLowerCase();
      };
    };
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowCopyConstraint(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Constraint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control type="text" value={fromConstraintId} readOnly />
          </Form.Group>
          <Form.Group>
            <Form.Label>To</Form.Label>
            <Form.Control
              type="text"
              value={toConstraintId}
              onChange={e => setToConstraintId(e.target.value.replace(/ /g, ""))}
            />
          </Form.Group>
          <Form.Group as={Row} style={{ justifyContent: "space-between", margin: "0 1rem 1rem 0" }}>
            <Form.Group>
              <Form.Label style={{ paddingTop: "6px" }}>
                Optimization Type: {optimizationType}
              </Form.Label>
            </Form.Group>
            {optimizationType === "Traffic" ? (
              <Form.Group as={Row}>
                <Form.Label style={{ paddingTop: "6px" }}>Allocation Week</Form.Label>
                <SingleDatePicker
                  mondayOnly
                  date={week}
                  placeholder="Select Week"
                  showClearDate
                  isOutsideRange={date => date < CUTOFF_DATE}
                  onChange={date => setWeek(date)}
                />
              </Form.Group>
            ) : (
              false
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
            overlay={
              (optimizationType === "Traffic" && !week) ||
              !isConstraintNameValid(toConstraintId) ? (
                !isConstraintNameValid(toConstraintId) ? (
                  <Tooltip>Name your constraint to continue</Tooltip>
                ) : (
                  <Tooltip>Select an allocation week to continue</Tooltip>
                )
              ) : (
                <></>
              )
            }
          >
            <div style={{ position: "relative" }}>
              {(optimizationType === "Traffic" && !week) ||
              !isConstraintNameValid(toConstraintId) ? (
                <div
                  style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
                ></div>
              ) : (
                false
              )}
              <Button
                variant="success"
                disabled={
                  (optimizationType === "Traffic" && !week) ||
                  !isConstraintNameValid(toConstraintId)
                }
                onClick={async () => {
                  setShowCopyConstraint(false);
                  await copyConstraint({
                    from: fromConstraintId,
                    to: toConstraintId,
                    optimizationType: parseConstraintNameIfExperiment("type")(toConstraintId),
                    week: parseConstraintNameIfExperiment("week")(toConstraintId),
                  });
                }}
              >
                Copy
              </Button>
            </div>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteConstraintConfirmation = ({
    show,
    setShowDeleteConstraint,
    deleteConstraint,
    deleteConstraintId,
  }) => {
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowDeleteConstraint(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Constraint {deleteConstraintId}?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={async () => {
              setShowDeleteConstraint(false);
              await deleteConstraint({
                deleteConstraintId,
              });
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RunOptimizationConfirmation = ({
    show,
    setShowRunOptimization,
    runOptimization,
    name,
    type,
    week,
  }) => {
    const [branch, setBranch] = useState("v4");
    const [kpi, setOptimizationKPI] = useState("");
    const [kpiError, setShowKpiError] = useState(false);
    const prettyType = type ? type[0].toUpperCase() + type.slice(1) : "";
    // if non experiment parse the name so the old system can use the new API
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowRunOptimization(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Run Constraint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={name} readOnly />
          </Form.Group>
          <Form.Group className="optimizationKPI">
            <Form.Label>Optimization KPI</Form.Label>
            <DropdownButton className="kpiDropdown" title={kpi}>
              {optimizationKPIs &&
                optimizationKPIs.map(kpi => (
                  <Dropdown.Item key={kpi} eventKey={kpi} onSelect={kpi => setOptimizationKPI(kpi)}>
                    {kpi}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          </Form.Group>
          <Form.Group>
            <Form.Label>Branch</Form.Label>
            <Form.Control
              type="text"
              defaultValue={branch}
              onChange={e => setBranch(e.currentTarget.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Optimization Type</Form.Label>
            <Form.Control type="text" value={prettyType} readOnly />
          </Form.Group>
          {type === "traffic" ? (
            <Form.Group>
              <Form.Label>Allocation Week</Form.Label>
              <Form.Control type="text" value={week} readOnly />
            </Form.Group>
          ) : (
            false
          )}
          {kpiError && (
            <Form.Group>
              <Alert variant="warning">Must Select KPI!</Alert>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={async () => {
              if (!kpi || kpi === "") {
                setShowKpiError(true);
              } else {
                setShowRunOptimization(false);
                await runOptimization({
                  name,
                  kpi,
                  branch,
                  optimizationType: type,
                  week,
                });
              }
            }}
          >
            Run
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const optimizationHeaders = [
    {
      label: "Number",
      name: "build_number",
      flex: true,
      renderer: row => {
        if (row.status === "SUCCESS") {
          return (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={<Tooltip>Open Run {row.build_number}</Tooltip>}
            >
              <Button
                style={{ minWidth: 140, fontWeight: 500 }}
                variant="link"
                onClick={() => navigate(`${ROUTES.RUNS.uri}/${row.company_id}_${row.build_number}`)}
              >
                {row.build_number}
              </Button>
            </OverlayTrigger>
          );
        } else {
          return (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={<Tooltip>Debug Run {row.build_number}</Tooltip>}
            >
              <Button
                style={{ minWidth: 140, fontWeight: 400, color: "black" }}
                variant="link"
                onClick={() => {
                  setShowLogsModal({
                    kpi: row.company_id,
                    buildNumber: row.build_number,
                    start: row.start,
                    end: row.end,
                  });
                }}
              >
                {row.build_number}
              </Button>
            </OverlayTrigger>
          );
        }
      },
    },
    {
      label: "KPI",
      name: "company_id",
      flex: 2,
      minFlexWidth: 275,
    },
    {
      label: "Status",
      name: "status",
      width: 180,
      renderer: row => {
        const statusText = (
          <div>
            {row.status} (
            <NumberFormatter
              value={Number.isFinite(row.progress) ? row.progress : 1}
              type={"%"}
              decimals={0}
            />
            )
          </div>
        );
        return statusText;
      },
    },
    {
      label: "Date (Eastern)",
      name: "date",
      minFlexWidth: 200,
      flex: 1,
      renderer: R.pipe(R.prop("date"), Dfns.parseISO, Dfns.format("yyyy-MM-dd hh:mma")),
    },
    {
      label: "Constraint Name",
      name: "constraint_name",
      flex: 2,
      minFlexWidth: 150,
    },
    {
      label: "OF",
      name: "objective_value",
      renderer: row => <NumberFormatter value={row.objective_value} type={""} decimals={0} />,
    },
    {
      label: "Cost",
      name: "cost",
      renderer: row => <NumberFormatter value={row.cost} type={"$"} decimals={0} />,
    },
    {
      label: "Budget",
      name: "budget",
      renderer: row => <NumberFormatter value={row.budget} type={"$"} decimals={0} />,
    },
    {
      label: "Branch",
      name: "branch",
      flex: 3,
      minFlexWidth: 150,
    },
  ];

  const constraintHeaders = [
    {
      label: "Name",
      name: "name",
      width: 180,
      renderer: row => (
        <Button variant="link" onClick={() => navigate(`${ROUTES.CONSTRAINTS.uri}/${row.name}`)}>
          {row.name}
        </Button>
      ),
    },
    {
      label: "Last Modified (Eastern)",
      name: "lastmodified",
      flex: 1,
      minFlexWidth: 200,
      renderer: R.pipe(R.prop("lastmodified"), Dfns.parseISO, Dfns.format("yyyy-MM-dd hh:mma")),
    },
    {
      label: "",
      name: "actions",
      width: 200,
      renderer: data => (
        <div className="constraintActions">
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Run optimization</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                setRunOptimizationId(data.name);
                setRunOptimizationType(data.optimizationType);
                setRunOptimizationWeek(data.week);
                setShowRunOptimization(true);
              }}
            >
              <MdPlayArrow />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Copy optimization</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                setFromConstraintId(data.name);
                setRunOptimizationType(data.optimizationType);
                setRunOptimizationWeek(data.week);
                setShowCopyConstraint(true);
              }}
            >
              <MdContentCopy />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Delete optimization</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                setDeleteConstraintId(data.name);
                setShowDeleteConstraint(true);
              }}
            >
              <MdDelete />
            </Button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  constraintHeaders.unshift({
    label: "",
    nonInteractive: true,
    name: "optimizationType",
    width: 30,
    renderer: row => (
      <OverlayTrigger
        placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
        overlay={
          <Tooltip>
            {row.optimizationType[0].toUpperCase() + row.optimizationType.slice(1)} optimization
          </Tooltip>
        }
      >
        {row.optimizationType === "buying" ? (
          <MdAttachMoney style={{ height: "1.3rem", width: "1.3rem", fill: "#7e57c2" }} />
        ) : row.optimizationType === "traffic" ? (
          <MdDirectionsCar style={{ height: "1.3rem", width: "1.3rem", fill: "#7e57c2" }} />
        ) : (
          <MdPeople style={{ height: "1.3rem", width: "1.3rem", fill: "#7e57c2" }} />
        )}
      </OverlayTrigger>
    ),
  });

  if (!optimizations || !constraintsList) {
    return <FullPageSpinner />;
  }

  // Experimental new constraint button
  const newConstraintButton = (
    <div className="constraintTableHeader">
      <span>Constraints</span>
      <OverlayTrigger
        placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
        overlay={<Tooltip>New Constraint</Tooltip>}
      >
        <Button
          variant="outline-primary"
          onClick={() => {
            setShowNewConstraint(true);
          }}
        >
          <MdAdd />
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <div className="listOptimizations">
      <div className="listOptimizationsBody optimizations">
        <FilterTableContainer
          headerHeight={25}
          rowHeight={25}
          data={optimizations}
          defaultTokens={{
            advanced: ["Status", "is not like", "FAILURE"],
          }}
          defaultAdvancedFilter
          headers={optimizationHeaders}
          title={
            <div className="optimizationTableHeader">
              <span>Optimizations</span>
              {refreshingOptimizations && <Spinner size={50} />}
            </div>
          }
        />
      </div>
      <div className="listOptimizationsBody constraints">
        <FilterTableContainer
          headerHeight={25}
          rowHeight={25}
          data={constraintsList}
          headers={constraintHeaders}
          title={newConstraintButton}
        />
        <CopyConstraintConfirmation
          show={showCopyConstraint}
          setShowCopyConstraint={setShowCopyConstraint}
          copyConstraint={copyConstraint}
          fromConstraintId={fromConstraintId}
          fromWeek={runOptimizationWeek}
          fromOptimizationType={runOptimizationType}
        />
        <NewConstraintConfirmation
          show={showNewConstraint}
          setShowNewConstraint={setShowNewConstraint}
          newConstraint={newConstraint}
        />
        <DeleteConstraintConfirmation
          show={showDeleteConstraint}
          setShowDeleteConstraint={setShowDeleteConstraint}
          deleteConstraint={deleteConstraint}
          deleteConstraintId={deleteConstraintId}
        />
        <RunOptimizationConfirmation
          show={showRunOptimization}
          setShowRunOptimization={setShowRunOptimization}
          name={runOptimizationId}
          type={runOptimizationType}
          week={runOptimizationWeek}
          runOptimization={runOptimization}
        />
        <LogsModal show={showLogsModal} setShowLogsModal={setShowLogsModal} />
      </div>
    </div>
  );
};

export default OptimizationHome;
