import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DatePicker, Page, Dropdown, DropdownToggleType } from "../Components";
import "./BrandHealthMetrics.scss";
import { formatDate } from "./BrandMetricsContent";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { MetricsLambdaFetch, MiscLambdaFetch, awaitJSON, pollS3 } from "../utils/fetch-utils";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import {
  AdvertiserInfo,
  BrandHealthEntry,
  BrandHealthInfo,
  BrandHealthInfoParams,
  BrandHealthGqvQueryInfo,
} from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";
import { DateRange } from "../utils/types";
import { useMap } from "../utils/hooks/useData";
import * as Dfns from "date-fns/fp";
import { formatNumber } from "../utils/format-utils";
import { MdRefresh } from "react-icons/md";
import * as R from "ramda";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps, Router } from "@reach/router";
import YouGovBrandHealth from "./YouGovBrandHealth";
import GoogleBrandHealth from "./GoogleBrandHealth";
import { fetchAndParseData } from "../BrandEquity/BrandEquityUtils";

const enum TabKey {
  YOU_GOV = "you-gov",
  GOOGLE = "google",
}

const NAVS = [
  { label: "YouGov", key: TabKey.YOU_GOV },
  { label: "Google", key: TabKey.GOOGLE },
];

const BrandHealthMetrics: React.FC = ({ navigate }: RouteComponentProps) => {
  const { cid } = useCompanyInfo();
  const shouldEnableBrandHealthGoogle = useExperimentFlag("enableBrandHealthGoogle");
  const shouldEnableBrandHealthMetrics = useExperimentFlag("enableBrandHealthMetrics");
  const enabledNavs = useMemo(() => {
    if (shouldEnableBrandHealthMetrics && shouldEnableBrandHealthGoogle) {
      return NAVS;
    } else {
      return [];
    }
  }, [shouldEnableBrandHealthGoogle, shouldEnableBrandHealthMetrics]);

  const setError = useSetError();
  const [dateRange, setDateRange] = useState<DateRange>();
  const [dateRangeGoogle, setDateRangeGoogle] = useState<DateRange>();
  const [movingAvgDays, setMovingAvgDays] = useState<string>("14");
  const [movingAvgDaysGqv, setMovingAvgDaysGqv] = useState<string>("14");
  const [ageMap, setAgeMap] = useMap<string, boolean>({
    "Age-All": true,
    "18-34": false,
    "35-49": false,
    "50+": false,
  });
  const ageGroup = useMemo(() => {
    if (ageMap["Age-All"]) {
      return "18-34,35-49,50+";
    }
    return Object.keys(ageMap)
      .filter(age => ageMap[age])
      .join(",");
  }, [ageMap]);
  const [genderMap, setGenderMap] = useMap<string, boolean>({
    "Gender-All": true,
    Male: false,
    Female: false,
  });
  const genderGroup = useMemo(() => {
    if (genderMap["Gender-All"]) {
      return "Male,Female";
    }
    return Object.keys(genderMap)
      .filter(gender => genderMap[gender])
      .join(",");
  }, [genderMap]);
  const [regionMap, setRegionMap] = useMap<string, boolean>({
    "Region-All": true,
    Northeast: false,
    South: false,
    Midwest: false,
    West: false,
  });
  const regionGroup = useMemo(() => {
    if (regionMap["Region-All"]) {
      return "Northeast,South,Midwest,West";
    }
    return Object.keys(regionMap)
      .filter(region => regionMap[region])
      .join(",");
  }, [regionMap]);
  const [incomeMap, setIncomeMap] = useMap<string, boolean>({
    All: true,
    "Under 40K": false,
    "40K - 80K": false,
    "80K - 120K": false,
    "Over 120K": false,
  });
  const incomeGroup = useMemo(() => {
    return Object.keys(incomeMap)
      .filter(income => incomeMap[income])
      .join(",");
  }, [incomeMap]);
  const [dateInterval, setDateInterval] = useState<string>("day");

  const movingAvgOptions = useMemo(() => {
    return [
      { value: "1", label: "None" },
      { value: "7", label: "7 days" },
      { value: "14", label: "14 days" },
      { value: "30", label: "30 days" },
      { value: "60", label: "60 days" },
    ];
  }, []);

  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: boolean | undefined }>({
    "Age-All": true,
    "18-34": false,
    "35-49": false,
    "50+": false,
    "Gender-All": true,
    Male: false,
    Female: false,
    "Region-All": true,
    Northeast: false,
    South: false,
    Midwest: false,
    West: false,
    All: true,
    "Under 40K": false,
    "40K - 80K": false,
    "80K - 120K": false,
    "Over 120K": false,
  });

  const [focalAdvertiser, setFocalAdvertiser] = useState<string>("");
  const [nonFocalAdvertisers, setNonFocalAdvertisers] = useState<string[]>([]);
  const [data, setData] = useState<BrandHealthEntry[]>([]);
  const [
    gqvQueryDataWithGeoNamesCodesAndPopulation,
    setGqvQueryDataWithGeoNamesCodesAndPopulation,
  ] = useState<BrandHealthGqvQueryInfo[]>([]);
  const [maxDate, setMaxDate] = useState<string>("");
  const [minDate, setMinDate] = useState<string>("");
  const [fetchingData, setFetchingData] = useState<boolean>(true);

  const [uniqueLabels, setUniqueLabels] = useState<string[]>([]);
  const [uniqueLabelsToSearchTerms, setUniqueLabelsToSearchTerms] = useMap<string, string[]>();
  const uniqueLabelsToSearchTermsMap = new Map<string, string[]>(
    Object.entries(uniqueLabelsToSearchTerms).filter(
      (entry): entry is [string, string[]] => entry[1] !== undefined
    )
  );

  useEffect(() => {
    if (cid && !focalAdvertiser && shouldEnableBrandHealthMetrics) {
      (async () => {
        try {
          let res = await MetricsLambdaFetch("/getAdvertiserInfo", {
            params: {
              cid,
            },
          });
          const advertiserInfo = await awaitJSON<AdvertiserInfo>(res);
          const { focalAdvertiser, nonFocalAdvertisers, minDate, maxDate } = advertiserInfo;
          setFocalAdvertiser(focalAdvertiser);
          setNonFocalAdvertisers(nonFocalAdvertisers);
          setMinDate(minDate);
          setMaxDate(maxDate);
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [cid, focalAdvertiser, setError, shouldEnableBrandHealthMetrics]);

  useEffect(() => {
    if (!shouldEnableBrandHealthMetrics) {
      return;
    }
    setData([]);
    setFetchingData(true);
  }, [movingAvgDays, dateInterval, shouldEnableBrandHealthMetrics]);

  useEffect(() => {
    if (!shouldEnableBrandHealthGoogle) {
      return;
    }

    const fetchAllCsvData = async () => {
      try {
        const [timeSeriesData, geoNamesData, queryData, keyData] = await Promise.all([
          fetchAndParseData(
            `tinuiti-brandhealth-results/gqv/${cid}/mmm_query_data_${cid}.csv`,
            parsedData => parsedData,
            "timeSeries"
          ),
          fetchAndParseData(
            "tinuiti-brandhealth-results/gqv/geonames.csv",
            parsedData => parsedData,
            "GeoNames"
          ),
          fetchAndParseData(
            `tinuiti-brandhealth-results/gqv/${cid}/metadata_query_${cid}.csv`,
            parsedData => parsedData,
            "Query"
          ),
          fetchAndParseData(
            `tinuiti-brandhealth-results/gqv/${cid}/metadata_key_${cid}.csv`,
            parsedData => parsedData,
            "Keys"
          ),
        ]);

        const keyMap = new Map<string, string>(
          keyData.map((item: any) => [item["Group Label"], item.Key])
        );

        const queryMap = new Map<string, string[]>();
        queryData.forEach((item: any) => {
          const groupLabel = item["Group Label"];
          const query = item.Query;
          if (!queryMap.has(groupLabel)) {
            queryMap.set(groupLabel, []);
          }
          queryMap.get(groupLabel)!.push(query);
        });

        keyMap.forEach((keyVal, groupLabel) => {
          const searchTerms = queryMap.get(groupLabel) || [];
          setUniqueLabelsToSearchTerms(keyVal, searchTerms);
        });

        const foundUniqueLabels = Array.from(keyMap.values());
        setUniqueLabels(foundUniqueLabels);

        const dmaResponse = await MetricsLambdaFetch("/getDmaCodeAndPopulation", { params: {} });
        const dmaCodesAndPopulation = await awaitJSON(dmaResponse);

        const dmaCodesToPopulationMap = new Map<number, any>();
        dmaCodesAndPopulation.forEach((dmaCode: any) => {
          dmaCodesToPopulationMap.set(Number(dmaCode.dma_code), dmaCode);
        });

        const geoNamesMap = new Map<string, any>();
        geoNamesData.forEach((geo: any) => {
          geoNamesMap.set(geo.GeoName, geo);
        });

        const merged = timeSeriesData.reduce(
          (acc: { data: any[]; minDate: string | null; maxDate: string | null }, item: any) => {
            const geoData = geoNamesMap.get(item.GeoName);
            const dmaCode = geoData?.dma_code;
            const popData = dmaCode ? dmaCodesToPopulationMap.get(dmaCode) : null;

            const newQueryLabel = keyMap.get(item.QueryLabel);
            const mappedQueries = queryMap.get(item.QueryLabel);

            const currentDate = new Date(item.ReportDate);
            if (!acc.minDate || currentDate < new Date(acc.minDate)) {
              acc.minDate = currentDate.toISOString().slice(0, 10);
            }
            if (!acc.maxDate || currentDate > new Date(acc.maxDate)) {
              acc.maxDate = currentDate.toISOString().slice(0, 10);
            }

            acc.data.push({
              ...item,
              QueryLabel: newQueryLabel,
              SearchTerms: mappedQueries,
              ...(geoData || {}),
              ...(popData || {}),
            });

            return acc;
          },
          { data: [], minDate: null, maxDate: null }
        );

        setGqvQueryDataWithGeoNamesCodesAndPopulation(merged.data);
        setDateRangeGoogle({
          start: merged.minDate,
          end: merged.maxDate,
        });
      } catch (e: any) {
        setError({
          message: e.message,
          reportError: e,
        });
      }
    };

    fetchAllCsvData();
  }, [cid, setError, setUniqueLabelsToSearchTerms, shouldEnableBrandHealthGoogle]);

  useEffect(() => {
    if (
      !shouldEnableBrandHealthMetrics ||
      !focalAdvertiser ||
      !maxDate ||
      !minDate ||
      !fetchingData
    ) {
      return;
    }
    (async () => {
      try {
        setFetchingData(false);
        setAppliedFilters({ ...ageMap, ...genderMap, ...regionMap, ...incomeMap });

        const params: BrandHealthInfoParams = {
          focalAdvertiser,
          nonFocalAdvertisers: nonFocalAdvertisers.join(","),
          minDate,
          maxDate,
          rollingAvgDaysMinusOne: Number.parseInt(movingAvgDays) - 1,
          ageGroup,
          genderGroup,
          regionGroup,
          incomeGroup,
          dateInterval,
        };

        const result = await MiscLambdaFetch("/kickOffLambda", {
          method: "POST",
          body: {
            fileType: "txt",
            lambdaArgs: params,
            lambdaName: "metrics-getBrandHealthInfo",
          },
        });
        const uuid = await awaitJSON(result);

        const content = await pollS3({
          autoDownload: false,
          bucket: "bpm-cache",
          filename: `${uuid}.txt`,
          mimeType: "text/plain",
        });
        const textContent = await content.text();
        let { data } = JSON.parse(textContent) as BrandHealthInfo;

        if (dateInterval !== "day") {
          data = data.sort((a, b) => {
            // Compare 'metric' fields
            const metricComparison = a.metric.localeCompare(b.metric);

            // If 'metric' fields are equal, compare 'date' fields
            if (metricComparison === 0) {
              return a.date.localeCompare(b.date);
            } else {
              return metricComparison;
            }
          });
        }

        setData(
          data.map(entry => {
            let updatedEntry: BrandHealthEntry = {
              metric: entry.metric,
              date: entry.date,
              focal_ma: formatNumber(parseFloat(entry.focal_ma as string), 1),
              focal_sample_size: formatNumber(parseFloat(entry.focal_sample_size), 0),
              non_focal_ma: formatNumber(parseFloat(entry.non_focal_ma as string), 1),
              non_focal_sample_size: formatNumber(parseFloat(entry.non_focal_sample_size), 0),
            };
            return updatedEntry;
          })
        );
      } catch (e) {
        setError({
          message: e.message,
          reportError: e,
        });
      }
    })();
  }, [
    ageGroup,
    cid,
    data,
    focalAdvertiser,
    genderGroup,
    incomeGroup,
    regionGroup,
    movingAvgDays,
    setError,
    fetchingData,
    ageMap,
    genderMap,
    regionMap,
    incomeMap,
    dateInterval,
    maxDate,
    nonFocalAdvertisers,
    minDate,
    shouldEnableBrandHealthMetrics,
  ]);

  const [updatingDateRange, setUpdatingDateRange] = useState<boolean>(true);

  useEffect(() => {
    setUpdatingDateRange(true);
  }, [dateInterval]);

  useEffect(() => {
    // If statement covers initial load of the page.
    if (!dateRange && minDate && maxDate && updatingDateRange) {
      setUpdatingDateRange(false);

      let maxDateObject = Dfns.parseISO(maxDate);
      let tempStart = Dfns.format(
        "yyyy-MM-dd",
        maxDateObject.setDate(maxDateObject.getDate() - 180)
      );

      setDateRange({
        start: tempStart < minDate ? minDate : tempStart,
        end: maxDate,
      });
    } else if (dateRange && dateInterval === "week" && updatingDateRange) {
      setUpdatingDateRange(false);

      let previousMondayStart = Dfns.parseISO(dateRange.start);
      previousMondayStart.setDate(
        previousMondayStart.getDate() - ((previousMondayStart.getDay() + 6) % 7)
      );
      // Check if the start date is before the min date. If so, set to following week.
      if (previousMondayStart < Dfns.parseISO(minDate)) {
        previousMondayStart = Dfns.parseISO(minDate);
        previousMondayStart.setDate(
          previousMondayStart.getDate() + (8 - previousMondayStart.getDay())
        );
      }

      let previousMondayEnd = Dfns.parseISO(dateRange.end);
      previousMondayEnd.setDate(
        previousMondayEnd.getDate() - ((previousMondayEnd.getDay() + 6) % 7)
      );
      // Check if the end date is before the min date. If so, set to following week.
      if (previousMondayEnd < Dfns.parseISO(minDate)) {
        previousMondayEnd = Dfns.parseISO(minDate);
        previousMondayEnd.setDate(previousMondayEnd.getDate() + (8 - previousMondayEnd.getDay()));
      }

      setDateRange({
        start: Dfns.format("yyyy-MM-dd", previousMondayStart),
        end: Dfns.format("yyyy-MM-dd", previousMondayEnd),
      });
    } else if (dateRange && dateInterval === "month" && updatingDateRange) {
      setUpdatingDateRange(false);

      let firstOfMonthStart = Dfns.parseISO(dateRange.start);
      firstOfMonthStart.setDate(1);
      // Check if the start date is before the min date. If so, set to following month.
      if (firstOfMonthStart < Dfns.parseISO(minDate)) {
        firstOfMonthStart.setMonth(firstOfMonthStart.getMonth() + 1);
      }

      let firstOfMonthEnd = Dfns.parseISO(dateRange.end);
      firstOfMonthEnd.setDate(1);
      // Check if the end date is before the min date. If so, set to following month.
      if (firstOfMonthEnd < Dfns.parseISO(minDate)) {
        firstOfMonthEnd.setMonth(firstOfMonthEnd.getMonth() + 1);
      }

      setDateRange({
        start: Dfns.format("yyyy-MM-dd", firstOfMonthStart),
        end: Dfns.format("yyyy-MM-dd", firstOfMonthEnd),
      });
    } else if (dateRange && dateInterval === "quarter" && updatingDateRange) {
      setUpdatingDateRange(false);

      let quarterStart = Dfns.parseISO(dateRange.start);
      const quarterStartMonth = quarterStart.getMonth();
      if (quarterStartMonth < 3) {
        quarterStart.setMonth(0);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(3);
        }
      } else if (quarterStartMonth < 6) {
        quarterStart.setMonth(3);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(6);
        }
      } else if (quarterStartMonth < 9) {
        quarterStart.setMonth(6);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(9);
        }
      } else {
        quarterStart.setMonth(9);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(0);
          quarterStart.setFullYear(quarterStart.getFullYear() + 1);
        }
      }

      let quarterEnd = Dfns.parseISO(dateRange.end);
      const quarterEndMonth = quarterEnd.getMonth();
      if (quarterEndMonth < 3) {
        quarterEnd.setMonth(0);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(3);
        }
      } else if (quarterEndMonth < 6) {
        quarterEnd.setMonth(3);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(6);
        }
      } else if (quarterEndMonth < 9) {
        quarterEnd.setMonth(6);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(9);
        }
      } else {
        quarterEnd.setMonth(9);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(0);
          quarterEnd.setFullYear(quarterEnd.getFullYear() + 1);
        }
      }

      setDateRange({
        start: Dfns.format("yyyy-MM-dd", quarterStart),
        end: Dfns.format("yyyy-MM-dd", quarterEnd),
      });
    } else if (dateRange && dateInterval === "day" && updatingDateRange) {
      setUpdatingDateRange(false);
    }
  }, [dateInterval, dateRange, maxDate, minDate, updatingDateRange]);

  const calendarSelectionDropdownOptions = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "quarter", label: "Quarter" },
  ];

  const isDayBlocked = useCallback(
    date => {
      let checkForDateInterval = false;
      if (dateInterval === "week") {
        checkForDateInterval = !R.pipe(Dfns.parseISO, Dfns.isMonday)(date);
      } else if (dateInterval === "month") {
        checkForDateInterval = !R.pipe(Dfns.parseISO, Dfns.isFirstDayOfMonth)(date);
      } else if (dateInterval === "quarter") {
        checkForDateInterval = !(
          date.substring(5) === "01-01" ||
          date.substring(5) === "04-01" ||
          date.substring(5) === "07-01" ||
          date.substring(5) === "10-01"
        );
      }

      return date < minDate || date > maxDate || checkForDateInterval;
    },
    [dateInterval, maxDate, minDate]
  );

  const inLoadingState = useMemo(() => {
    return data.length === 0;
  }, [data.length]);

  const defaultTabKey = useMemo(() => {
    if (shouldEnableBrandHealthMetrics) {
      return TabKey.YOU_GOV;
    }
    if (shouldEnableBrandHealthGoogle) {
      return TabKey.GOOGLE;
    }
    return TabKey.YOU_GOV;
  }, [shouldEnableBrandHealthMetrics, shouldEnableBrandHealthGoogle]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "brand-health-metrics",
    defaultKey: defaultTabKey,
  });

  return (
    <Page
      app2Redesign
      title="Brand Health Metrics"
      pageType="Brand Health Metrics"
      navs={enabledNavs}
      selectedNav={tab}
      onNav={goToTab}
      actions={
        tab === TabKey.YOU_GOV && shouldEnableBrandHealthMetrics
          ? maxDate && (
              <div className="BHMActions">
                <div className="lastUpdated">
                  <div className="lastUpdatedIcon">
                    <MdRefresh />
                  </div>
                  <div className="lastUpdatedText">{`Data last updated: ${formatDate(
                    maxDate
                  )}`}</div>
                </div>
                <Dropdown
                  type={DropdownToggleType.FILLED}
                  design="primary"
                  disabled={inLoadingState}
                  value={dateInterval}
                  label="Date Interval"
                  options={calendarSelectionDropdownOptions}
                  onChange={setDateInterval}
                ></Dropdown>
                <Dropdown
                  type={DropdownToggleType.FILLED}
                  design="primary"
                  disabled={inLoadingState}
                  label="Moving Average"
                  value={movingAvgDays}
                  options={movingAvgOptions}
                  onChange={setMovingAvgDays}
                ></Dropdown>
                <DatePicker
                  range={dateRange}
                  onChange={setDateRange}
                  isDayBlocked={date => isDayBlocked(date)}
                  maxDate={maxDate}
                ></DatePicker>
              </div>
            )
          : tab === TabKey.GOOGLE &&
            shouldEnableBrandHealthGoogle &&
            dateRangeGoogle && (
              <div className="BHMActions">
                <div className="lastUpdated">
                  <div className="lastUpdatedIcon">
                    <MdRefresh />
                  </div>
                  <div className="lastUpdatedText">{`Data last updated: ${formatDate(
                    dateRangeGoogle.end
                  )}`}</div>
                </div>
                <Dropdown
                  type={DropdownToggleType.FILLED}
                  design="primary"
                  label="Moving Average"
                  value={movingAvgDaysGqv}
                  options={movingAvgOptions}
                  onChange={setMovingAvgDaysGqv}
                ></Dropdown>
                <DatePicker
                  range={dateRangeGoogle}
                  onChange={setDateRangeGoogle}
                  removeHeaderText={true}
                ></DatePicker>
              </div>
            )
      }
    >
      <Router className="fullPageRouter BradHealthMetricsPage">
        {shouldEnableBrandHealthMetrics && (
          <YouGovBrandHealth
            path={"/"}
            focalAdvertiser={focalAdvertiser}
            nonFocalAdvertisers={nonFocalAdvertisers}
            data={data}
            dateRange={dateRange}
            ageMap={ageMap}
            setAgeMap={setAgeMap}
            genderMap={genderMap}
            setGenderMap={setGenderMap}
            regionMap={regionMap}
            setRegionMap={setRegionMap}
            incomeMap={incomeMap}
            setIncomeMap={setIncomeMap}
            setFetchingData={setFetchingData}
            setData={setData}
            appliedFilters={appliedFilters}
            inLoadingState={inLoadingState}
          ></YouGovBrandHealth>
        )}
        {shouldEnableBrandHealthGoogle && (
          <GoogleBrandHealth
            path={TabKey.GOOGLE}
            gqvQueryDataWithGeoNamesCodesAndPopulation={gqvQueryDataWithGeoNamesCodesAndPopulation}
            dateRange={dateRangeGoogle}
            uniqueLabels={uniqueLabels}
            uniqueLabelsToSearchTerms={uniqueLabelsToSearchTermsMap}
            movingAvgDaysGqv={movingAvgDaysGqv}
          ></GoogleBrandHealth>
        )}
      </Router>
    </Page>
  );
};

export default BrandHealthMetrics;
