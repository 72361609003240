import "./ExtremeReachReport.scss";
import { awaitJSON, MiscLambdaFetch, pollS3 } from "../utils/fetch-utils";
import { FullPageSpinner, PieChart } from "../Components";
import { useCreativeMap } from "../redux/creative";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import useLocation from "../utils/hooks/useLocation";

const pieComponent = (data: {}[]) => (
  <div style={{ height: `${74}px`, width: `${74}px` }}>
    {/* This is because we're calling a .jsx component from a .tsx one.  Ignore the error, it works fine! */}
    {/*// @ts-ignore */}
    <PieChart data={data} size={75} />
  </div>
);

interface MismatchCreativeCheckCreative {
  end: string;
  ISCI: string;
  isPending?: boolean; // Only on BPM rows
  start: string;
  weight: number;
}

interface MismatchedRotation {
  BPM: Record<string, MismatchCreativeCheckCreative>;
  FT: Record<string, MismatchCreativeCheckCreative>;
  name: string;
}

export const FlashtalkingReport: React.FC = () => {
  const { company } = useLocation();
  const { colorMap } = useCreativeMap({ company, mediaTypes: ["audio", "display", "streaming"] });
  const setError = useSetError();
  const [reportInfo, setReportInfo] = useState<Record<string, MismatchedRotation>>();

  useEffect(() => {
    if (company && !reportInfo) {
      (async () => {
        const result = await MiscLambdaFetch("/kickOffLambda", {
          method: "POST",
          body: {
            fileType: "txt",
            lambdaArgs: { lambdaArgs: { company } },
            lambdaName: "flashtalking-checkFTCreativeRotations",
          },
        });
        const uuid = await awaitJSON(result);
        const content = await pollS3({
          autoDownload: false,
          bucket: "bpm-cache",
          filename: `${uuid}.txt`,
          mimeType: "text/plain",
          period: 3000,
        });
        const textContent = await content.text();
        const mistmatchedRotations = JSON.parse(textContent) as Record<string, MismatchedRotation>;
        setReportInfo(mistmatchedRotations);
      })();
    }
  }, [company, reportInfo, setError]);

  return (
    <div className="extremeReachReport">
      {reportInfo ? (
        <>
          <div className="reportHeaderRow">
            <h2>
              Flashtalking vs BPM Sync Report: <strong>{/* {campaign} ({id}) */}</strong>
            </h2>
          </div>
          <div className="reportBody">
            <div className="xrBPMHeaders compareBox">
              <h2>Flashtalking</h2>
              <h2>Bliss Point</h2>
            </div>
            <div className="reportContent">
              {!R.isNil(reportInfo) && R.isEmpty(reportInfo) ? (
                <div>Creative rotations are synchronized</div>
              ) : (
                <>
                  {R.map(placementID => {
                    const mismatchedPlacement = reportInfo[placementID];
                    const bpmRotation = mismatchedPlacement.BPM;
                    const ftRotation = mismatchedPlacement.FT;
                    const placementContainer = (
                      <h1 className="placementName">
                        Placement ID: {placementID} ({mismatchedPlacement.name})
                      </h1>
                    );
                    const compareContainer = (
                      <div className="compareBox">
                        {[bpmRotation, ftRotation].map((rotationMap, i) => {
                          const rotation = R.sortBy(R.prop("weight"), R.values(rotationMap));
                          return (
                            <div key={i} className="rotationBox" style={{ borderWidth: "0px" }}>
                              {rotation.length ? (
                                <h3 className="rotationHeader">
                                  {pieComponent(
                                    rotation.map(({ ISCI, weight }) => ({
                                      name: ISCI,
                                      value: weight,
                                      color: colorMap[ISCI],
                                    }))
                                  )}
                                  <div className="isciHeader">
                                    {rotation.map(({ ISCI, weight, start, end, isPending }) => (
                                      <div key={`${ISCI}_${weight}_${start}_${end}`}>
                                        <div
                                          className="swatch"
                                          style={{
                                            backgroundColor: colorMap[ISCI],
                                          }}
                                        />
                                        <div>{ISCI}</div>
                                        <div>{weight}%</div>
                                        <div>
                                          {start.replace(/_/g, "/")} -{" "}
                                          {end && end !== "null"
                                            ? end.replace(/_/g, "/")
                                            : "Ongoing"}
                                        </div>
                                        {isPending && <div>PENDING</div>}
                                      </div>
                                    ))}
                                  </div>
                                </h3>
                              ) : (
                                <h4>No Rotation</h4>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );

                    return (
                      <div key={`${placementID}`} className="mismatchedPlacement">
                        {placementContainer}
                        {compareContainer}
                      </div>
                    );
                  }, R.keys(reportInfo))}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
};

export default FlashtalkingReport;
