import React, { useMemo } from "react";
import {
  Button,
  ButtonType,
  Dropdown,
  DropdownToggleType,
  ButtonFrameworkVariant,
  FilterBarTokens,
} from "../../../Components";
import * as R from "ramda";
import "./MetaTableWidget.scss";
import { AdRow, MetaBuyingTableRow } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { MdDelete } from "react-icons/md";
import { MBAlertDialogType, WHITE_META_LOGO } from "../../MetaBuyingUtils";

interface MetaTableWidgetProps {
  isInternal: boolean;
  title: string;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  setSelectedAdAccount: React.Dispatch<
    React.SetStateAction<{
      account_id: string;
      account_name: string;
      business_manager: string;
    }>
  >;
  adAccountOptions: {
    account_id: string;
    account_name: string;
    business_manager: string;
  }[];
  selectedLevel: string;
  setSelectedLevel: React.Dispatch<React.SetStateAction<string>>;
  filterBar: JSX.Element;
  setFilter: (func: (line: any) => boolean) => void;
  tableComponent: JSX.Element;
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  openAlertDialog?: (type: MBAlertDialogType, row?: MetaBuyingTableRow) => void;
  setTokens: React.Dispatch<React.SetStateAction<FilterBarTokens>>;
}

export const MetaTableWidget = ({
  isInternal,
  title,
  selectedAdAccount,
  setSelectedAdAccount,
  adAccountOptions,
  selectedLevel,
  setSelectedLevel,
  filterBar,
  setFilter,
  tableComponent,
  selectedRows,
  openAlertDialog,
  setTokens,
}: MetaTableWidgetProps): JSX.Element => {
  const numSelectedCampaigns = useMemo(() => Object.keys(selectedRows.campaign).length, [
    selectedRows,
  ]);
  const numSelectedAdSets = useMemo(() => Object.keys(selectedRows.adset).length, [selectedRows]);
  const numSelectedAds = useMemo(() => Object.keys(selectedRows.ad).length, [selectedRows]);

  const campaignsButtonText: string = useMemo(() => {
    return numSelectedCampaigns > 0 ? `Campaigns (${numSelectedCampaigns} selected)` : "Campaigns";
  }, [numSelectedCampaigns]);
  const adSetsButtonText: string = useMemo(() => {
    if (numSelectedCampaigns > 0 && numSelectedAdSets === 0) {
      return `Ad Sets for ${numSelectedCampaigns} Campaign${numSelectedCampaigns > 1 ? "s" : ""}`;
    } else if (numSelectedAdSets > 0) {
      return `Ad Sets (${numSelectedAdSets} selected)`;
    } else {
      return "Ad Sets";
    }
  }, [numSelectedAdSets, numSelectedCampaigns]);
  const adsButtonText: string = useMemo(() => {
    if (numSelectedCampaigns > 0 && numSelectedAdSets === 0 && numSelectedAds === 0) {
      return `Ads for ${numSelectedCampaigns} Campaign${numSelectedCampaigns > 1 ? "s" : ""}`;
    } else if (numSelectedAdSets > 0 && numSelectedAds === 0) {
      return `Ads for ${numSelectedAdSets} Ad Set${numSelectedAdSets > 1 ? "s" : ""}`;
    } else if (numSelectedAds > 0) {
      return `Ads (${numSelectedAds} selected)`;
    } else {
      return "Ads";
    }
  }, [numSelectedAdSets, numSelectedAds, numSelectedCampaigns]);

  const disableFooterButtons = useMemo(() => {
    if (selectedLevel === "campaign" && numSelectedCampaigns === 0) {
      return true;
    } else if (selectedLevel === "adset" && numSelectedAdSets === 0) {
      return true;
    } else if (selectedLevel === "ad" && numSelectedAds === 0) {
      return true;
    } else {
      return false;
    }
  }, [numSelectedAdSets, numSelectedAds, numSelectedCampaigns, selectedLevel]);

  const invalidAdToPublish = useMemo(() => {
    if (selectedLevel === "ad") {
      return (Object.values(selectedRows.ad) as AdRow[]).some(
        ad => ad.approval_stage !== "APPROVED"
      );
    }
    return false;
  }, [selectedLevel, selectedRows]);

  return (
    <div className="metaTableWidget">
      <div className={`mtwHeader${isInternal ? " internal" : " external"}`}>
        <div className="mtwHeaderTop">
          <div className="mtwHeaderTopLeft">
            <div className="mtwHeaderTitle">{title}</div>
            <div className="mtwHeaderDivider"></div>
            <div className="mtwHeaderAccount">
              <Dropdown
                type={DropdownToggleType.WIDGET_TITLE}
                value={selectedAdAccount.account_name}
                options={adAccountOptions.map(({ account_id, account_name }) => ({
                  label: `${account_name} (${account_id})`,
                  value: account_id,
                }))}
                onChange={item =>
                  setSelectedAdAccount(R.indexBy(R.prop("account_id"), adAccountOptions)[item])
                }
              ></Dropdown>
            </div>
          </div>
          <div className="mtwHeaderTopRight">{filterBar}</div>
        </div>
        {isInternal && (
          <div className="mtwHeaderBot">
            <Button
              className={`mtwCampaignsButton${
                selectedLevel === "campaign" ? " selected" : " unselected"
              }`}
              type={ButtonType.EMPTY}
              onClick={() => {
                setTokens({ basic: [], advanced: [] });
                setFilter(() => true);
                setSelectedLevel("campaign");
              }}
            >
              {campaignsButtonText}
            </Button>
            <Button
              className={`mtwAdSetsButton${
                selectedLevel === "adset" ? " selected" : " unselected"
              }`}
              type={ButtonType.EMPTY}
              onClick={() => {
                setTokens({ basic: [], advanced: [] });
                setFilter(() => true);
                setSelectedLevel("adset");
              }}
            >
              {adSetsButtonText}
            </Button>
            <Button
              className={`mtwAdsButton${selectedLevel === "ad" ? " selected" : " unselected"}`}
              type={ButtonType.EMPTY}
              onClick={() => {
                setTokens({ basic: [], advanced: [] });
                setFilter(() => true);
                setSelectedLevel("ad");
              }}
            >
              {adsButtonText}
            </Button>
          </div>
        )}
      </div>
      <div className={`mtwBody${title === "Drafts" && isInternal ? " withFooter" : ""}`}>
        {tableComponent}
      </div>
      {openAlertDialog && (
        <div className="mtwFooter">
          <div className="deleteButton">
            <Button
              variant={ButtonFrameworkVariant.ICON_ONLY}
              type={ButtonType.EMPTY}
              icon={<MdDelete />}
              onClick={() => {
                openAlertDialog(MBAlertDialogType.DELETE);
              }}
              disabled={disableFooterButtons}
            />
          </div>
          <div className="publishButton">
            <Button
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={
                <img src={WHITE_META_LOGO} alt="icon" style={{ width: "18px", height: "18px" }} />
              }
              size="sm"
              type={ButtonType.FILLED}
              onClick={() => {
                openAlertDialog(MBAlertDialogType.PUBLISH_AS_PAUSED);
              }}
              disabled={disableFooterButtons || invalidAdToPublish}
            >
              Publish As Paused
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetaTableWidget;
