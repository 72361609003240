import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import * as UserRedux from "../../redux/user";
import * as Dfns from "date-fns/fp";

import { MdClose } from "react-icons/md";

import { navigate, RouteComponentProps } from "@reach/router";
import { useMap } from "../../utils/hooks/useData";

import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";

import useLocation from "../../utils/hooks/useLocation";
import { awaitJSON, LinearPerformanceLambdaFetch } from "../../utils/fetch-utils";

import {
  encodePrettyUrl,
  getBaseURL,
  getGlobalBrand,
  getPresetOptions,
  PerformanceContext,
  exportCSV,
  getGroupOptions,
  ALL_GROUP_NAME,
  STANDARD_GROUP_NAME,
} from "../performanceUtils";
import {
  makeFetchKey,
  RowWithTabularData,
  performanceRowToTableRow,
  constructTotalsMap,
  DIMENSION_COLUMN_METADATA_MAP,
  COLUMN_METADATA_MAP,
} from "./linearPerformanceUtils";
import { useSetError } from "../../redux/modals";
import {
  BPMButton,
  BPMDateRange,
  OldDropdown,
  FilterPane,
  FullPageSpinner,
  KpiPicker,
  Page,
  SuggestionInput,
  useFilterPaneState,
} from "../../Components";
import { useCompanyInfo } from "../../redux/company";
import { DateRange } from "../../utils/types";
import { useSelector } from "react-redux";
import { useCreativeMap } from "../../redux/creative";
import { mergeNumberObjects } from "../../utils/data";
import { OverviewMetrics, OverviewData } from "../OverviewMetrics";
import PerformanceGrid from "../PerformanceGrid";
import OptionsDropdown from "../Config/OptionsDropdown";
import ConfigToggle from "../Config/ConfigToggle";
import DateConfig from "../Config/DateConfig";
import OverviewMetricsConfig from "../Config/OverviewMetricsConfig";
import PerformanceGridConfig from "../Config/PerformanceGridConfig";
import { Form } from "react-bootstrap";
import {
  computeResolvedDate,
  MONDAY_OF_LAST_WEEK,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import PerformanceGridSkeleton from "../PerformanceGridSkeleton";
import { ReactComponent as Save } from "../icons/Save.svg";

import "../Performance.scss";
import { overrideDateRange } from "../../utils/test-account-utils";

const DATE_FORMAT = "yyyy-MM-dd";

interface LinearPerformanceProps extends RouteComponentProps {
  urlPresetName: string;
}

const LinearPerformance: React.FC<LinearPerformanceProps> = ({ urlPresetName, location }) => {
  const { company } = useLocation();
  const setError = useSetError();
  const companyInfo = useCompanyInfo();
  const isInternal = useSelector(UserRedux.isInternalSelector);

  const [presets, setPresets] = useState<P.PresetIDGroups[]>();

  const [groupName, setGroupName] = useState<string>("");
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [selectGroupOptions, setSelectGroupOptions] = useState<string[]>([]);
  const [presetName, setPresetNameRaw] = useState<string>("");
  const [newName, setNewName] = useState("");

  const baseUrl = useMemo(() => {
    return getBaseURL(urlPresetName, location);
  }, [urlPresetName, location]);

  const setPresetName = useCallback(
    (name: string) => {
      setPresetNameRaw(name);
      navigate(`${location?.origin}${baseUrl}/${encodePrettyUrl(name)}`);
    },
    [location, baseUrl]
  );

  const applyGroupSelection = useCallback((groupName: string) => {
    setGroupName(groupName);
  }, []);

  const applyPresetSelection = useCallback(
    (name: string) => {
      localStorage.removeItem(`${company}_linearPerfDateRange`); // Remove cached dates and use default dates of selected preset.
      setPresetName(name);
    },
    [company, setPresetName]
  );

  const [presetMap, setPresetMap] = useMap<string, L.Preset | undefined>();
  const [editMode, setEditModeRaw] = useState<true | false | "new" | "quick">(false);

  const [kpi, setKpi] = useState<string>(companyInfo.initial_kpi || "");
  const [audience, setAudience] = useState<L.Audience>();
  const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();

  const [saving, setSaving] = useState(false);

  const pickerSetKpi = useCallback(
    (kpi: string) => {
      if (editMode) {
        setPresetChanges(preset => ({ ...preset, globalKpi: kpi }));
      } else {
        setKpi(kpi);
      }
    },
    [editMode]
  );

  const pickerSetAudience = useCallback(
    (audience: L.Audience) => {
      if (editMode) {
        setPresetChanges(preset => ({ ...preset, globalAudience: audience }));
      } else {
        setAudience(audience);
      }
    },
    [editMode]
  );

  const [presetChanges, setPresetChanges] = useState<P.PresetChanges<L.Preset>>({
    columns: [],
    performanceDimensionColumns: [],
    headers: [],
    adminOnly: false,
    shouldUseUnequivalizedImpressions: true,
  });
  const [filterTerm, setFilterTerm] = useState("");

  const presetID = useMemo(() => {
    if (presets) {
      return R.find((preset: P.PresetIDGroups) => preset.name === presetName, presets);
    }
  }, [presets, presetName]);

  const preset = presetMap[`${presetID?.id}`];

  const defaultDateState = useMemo(
    () => ({
      start:
        presetChanges.defaultDateState?.start ||
        preset?.defaultDateState?.start ||
        L.DEFAULT_DATE_RANGE_STATE.start,
      end:
        presetChanges.defaultDateState?.end ||
        preset?.defaultDateState?.end ||
        L.DEFAULT_DATE_RANGE_STATE.end,
    }),
    [presetChanges, preset]
  );

  const startingDateRange = useMemo(() => {
    const localDateRangeString = localStorage.getItem(`${company}_linearPerfDateRange`);
    const localDateRange = localDateRangeString ? JSON.parse(localDateRangeString) : null;

    return {
      start: localDateRange?.start || computeResolvedDate(defaultDateState.start),
      end: localDateRange?.end || computeResolvedDate(defaultDateState.end),
    };
  }, [company, defaultDateState]);

  useEffect(() => {
    if (preset) {
      setKpi(preset?.globalKpi || companyInfo.initial_kpi);
      setAudience(preset?.globalAudience || L.AUDIENCES[0]);
      setDates(startingDateRange);
    }
  }, [preset, companyInfo, startingDateRange]);

  const [resolvedKpi, resolvedAudience] = useMemo(() => {
    let defaultKpi = companyInfo.initial_kpi;
    let defaultAudience: L.Audience = L.AUDIENCES[0];

    if (preset && !editMode) {
      if (preset.globalKpi) {
        defaultKpi = preset.globalKpi;
      }
      if (preset.globalAudience) {
        defaultAudience = preset.globalAudience;
      }
    }

    let resolvedKpi = kpi || defaultKpi;
    let resolvedAudience = audience || defaultAudience;

    if (editMode) {
      resolvedKpi = presetChanges.globalKpi || defaultKpi;
      resolvedAudience = presetChanges.globalAudience || defaultAudience;
    }

    return [resolvedKpi, resolvedAudience];
  }, [preset, companyInfo, kpi, audience, editMode, presetChanges]);

  const globalBrand = getGlobalBrand(companyInfo, kpi);

  const { creativeMap } = useCreativeMap({
    company: globalBrand || company,
    mediaTypes: ["linear"],
  });

  const groupOptions = useMemo(() => {
    let allGroups = getGroupOptions(presets);
    allGroups.push(ALL_GROUP_NAME);
    return allGroups;
  }, [presets]);

  const createViewGroupOptions = useMemo(() => {
    let viewGroupOptions = groupOptions.filter(
      groupName => groupName !== STANDARD_GROUP_NAME && groupName !== ALL_GROUP_NAME
    );
    return viewGroupOptions;
  }, [groupOptions]);

  const presetOptions = useMemo(() => {
    const allPresetsOptions = getPresetOptions(presets, groupName);
    return allPresetsOptions;
  }, [groupName, presets]);

  const allPresetsNames = useMemo(() => {
    return presets ? presets.map(preset => preset.name) : [];
  }, [presets]);

  const setEditMode = useCallback(
    (val: typeof editMode) => {
      if (preset) {
        if (presetID && presetID.groups.includes(STANDARD_GROUP_NAME)) {
          setSelectGroupOptions([STANDARD_GROUP_NAME]);
          setNewGroupName(STANDARD_GROUP_NAME);
        } else {
          let selectOptions = groupOptions.filter(
            groupName => groupName !== STANDARD_GROUP_NAME && groupName !== ALL_GROUP_NAME
          );
          setSelectGroupOptions(selectOptions);
          let currentGroupName = presetID && presetID.groups.length > 0 ? presetID.groups[0] : "";
          setNewGroupName(currentGroupName);
        }
        setEditModeRaw(val);
      }
      if (!val) {
        setNewName("");
        setNewGroupName("");
        setSelectGroupOptions([]);
        // TODO: what else needs to be reset if they hit cancel?
      }
    },
    [preset, presetID, groupOptions]
  );

  // Fetch the Preset IDs for a given company. The preset name is set to "Network" by default.
  // See @blisspointmedia/bpm-types/LinearPerformance for DEFAULT_PRESETS.
  useEffect(() => {
    if (company && !presets) {
      (async () => {
        try {
          let res = await LinearPerformanceLambdaFetch<L.GetPresetsParams>("/getPresets", {
            params: {
              company,
            },
          });
          let presets = await awaitJSON<P.PresetIDGroups[]>(res);
          setPresets(presets);
          let urlPreset = R.find(preset => encodePrettyUrl(preset.name) === urlPresetName, presets);
          if (urlPreset) {
            setPresetName(urlPreset.name);
            if (urlPreset.groups.length > 0) {
              setGroupName(urlPreset.groups[0]);
            } else {
              setGroupName(ALL_GROUP_NAME); // Miscellaneous group
            }
          } else {
            setPresetName("Network");
            setGroupName(STANDARD_GROUP_NAME); // Default group
          }
        } catch (e) {
          let error = e as Error;
          setError({
            reportError: error,
            message: `Failed to fetch presets. Error: ${error.message}`,
          });
        }
      })();
    }
  }, [company, setError, urlPresetName, presets, setPresetName]);

  // Given a Preset ID and Company, fetch all of the metadata for that preset.
  // See @blisspointmedia/bpm-types/Performance for Preset interface.
  useEffect(() => {
    if (!preset && presetID) {
      (async () => {
        try {
          let res = await LinearPerformanceLambdaFetch<L.GetPresetParams>("/getPreset", {
            params: {
              id: presetID.id,
              company,
            },
          });
          let row = await awaitJSON<L.PresetRow>(res);
          setPresetMap(`${presetID.id}`, row.preset);
          setKpi(row.preset.globalKpi || companyInfo.initial_kpi);
          setAudience(row.preset.globalAudience || L.AUDIENCES[0]);
        } catch (e) {
          let error = e as Error;
          setError({
            reportError: error,
            message: error.message,
          });
        }
      })();
    }
  }, [preset, presetID, setError, company, setPresetMap, companyInfo]);

  const [dates, setDates] = useState<DateRange>();
  const [otherDates, setOtherDates] = useState<DateRange>();

  const onDatePickerChange = useCallback(
    (dates: DateRange) => {
      localStorage.setItem(`${company}_linearPerfDateRange`, JSON.stringify(dates));
      setDates(dates);
    },
    [company]
  );

  const resetDefaultDates = useCallback(() => {
    localStorage.removeItem(`${company}_linearPerfDateRange`);
    setDates({
      start: computeResolvedDate(defaultDateState.start),
      end: computeResolvedDate(defaultDateState.end),
    });
  }, [company, defaultDateState]);

  const fetchKey = useMemo(() => {
    if (preset && dates && presetID && kpi && audience) {
      return makeFetchKey(presetID?.id, dates, kpi, audience);
    }
    return "";
  }, [preset, dates, presetID, kpi, audience]);

  const otherDatesFetchKey = useMemo(() => {
    if (preset && otherDates && presetID && kpi && audience) {
      return makeFetchKey(presetID?.id, otherDates, kpi, audience);
    }
    return "";
  }, [preset, otherDates, presetID, kpi, audience]);

  const [kpiMetaData, setKpiMetaData] = useState<P.GetKpiMetaDataResponse>();
  const [kpiPickerMap, setKpiPickerMap] = useState<P.GetKpiPickerMapResponse>();
  const [maxCpxValue, setMaxCpxValue] = useState<number>();

  useEffect(() => {
    if (company && !kpiMetaData) {
      (async () => {
        try {
          const res = await LinearPerformanceLambdaFetch<P.GetKpiMetaDataParams>(
            "/getKpiMetaData",
            {
              params: {
                company,
              },
            }
          );
          const kpiMap = await awaitJSON<P.GetKpiMetaDataResponse>(res);
          setKpiMetaData(kpiMap);
        } catch (e) {
          let error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
      })();
    }
  }, [kpiMetaData, company, setError]);

  useEffect(() => {
    if (company) {
      (async () => {
        try {
          const res = await LinearPerformanceLambdaFetch<P.GetKpiMetaDataParams>(
            "/getKpiPickerMap",
            {
              params: {
                company,
              },
            }
          );
          const kpiMap = await awaitJSON<P.GetKpiPickerMapResponse>(res);
          // Filter for only KPIs that are in the kpiMetaData (the ones we care about for linear).
          const filteredKpiMap = kpiMetaData
            ? Object.fromEntries(
                Object.entries(kpiMap).filter(([key, value]) => key in kpiMetaData)
              )
            : {};
          if (filteredKpiMap) {
            for (let key of R.keys(filteredKpiMap)) {
              if (filteredKpiMap[key].linearHidden) {
                delete filteredKpiMap[key];
              }
            }
          }
          setKpiPickerMap(filteredKpiMap);
        } catch (e) {
          let error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
      })();
    }
  }, [kpiMetaData, company, setError]);

  useEffect(() => {
    if (kpiMetaData && kpi) {
      setMaxCpxValue(kpiMetaData[kpi]?.maxCpxValue);
    }
  }, [kpiMetaData, kpi, setMaxCpxValue]);

  const [dataStatusMap, setDataStatusMap] = useMap<string, string | undefined>();
  const [dataMap, setDataMap] = useMap<string, L.GetPageDataResponse | undefined>();
  const data = dataMap[fetchKey];
  const otherData = dataMap[otherDatesFetchKey];

  const fetchData = useCallback(
    (key, dates) => {
      (async () => {
        if (dates && dates.end && R.pipe(Dfns.parseISO, Dfns.isMonday)(dates.end)) {
          const sundayEnd = R.pipe(
            Dfns.parseISO,
            Dfns.addDays(6),
            Dfns.format(DATE_FORMAT)
          )(dates.end);

          dates.end = sundayEnd;
        }
        try {
          const dateRangeToUse = overrideDateRange(company, dates);
          let pageDataParams: L.GetPageDataParams = {
            ...(dateRangeToUse || {}),
            id: R.defaultTo({ id: 0 }, presetID).id,
            company: globalBrand || company,
            kpi,
            audience,
            shouldUseUnequivalizedImpressions: preset?.shouldUseUnequivalizedImpressions,
          };
          let pageDataRes = await LinearPerformanceLambdaFetch<L.GetPageDataParams>("/", {
            params: pageDataParams,
          });
          let pageData = await awaitJSON<L.GetPageDataResponse>(pageDataRes);
          setDataMap(key, pageData);
        } catch (e) {
          let error = e as Error;
          setError({
            reportError: error,
            message: error.message,
          });
        }
      })();
    },
    [audience, company, globalBrand, kpi, preset, presetID, setDataMap, setError]
  );

  useEffect(() => {
    if (dataStatusMap[fetchKey] === "readyToFetch") {
      if (R.isNil(dataMap[fetchKey])) {
        fetchData(fetchKey, dates);
      }
      setDataStatusMap(fetchKey, "fetched");
    }
    if (fetchKey !== otherDatesFetchKey && dataStatusMap[otherDatesFetchKey] === "readyToFetch") {
      if (R.isNil(dataMap[otherDatesFetchKey])) {
        fetchData(otherDatesFetchKey, otherDates);
      }
      setDataStatusMap(otherDatesFetchKey, "fetched");
    }
  }, [
    dataStatusMap,
    fetchData,
    fetchKey,
    dates,
    setDataStatusMap,
    otherDates,
    otherDatesFetchKey,
    dataMap,
  ]);

  useEffect(() => {
    if (fetchKey && R.isNil(dataStatusMap[fetchKey]) && !data) {
      setDataStatusMap(fetchKey, "readyToFetch");
    }
    if (
      fetchKey !== otherDatesFetchKey &&
      otherDates &&
      otherDatesFetchKey &&
      R.isNil(dataStatusMap[otherDatesFetchKey]) &&
      !otherData
    ) {
      setDataStatusMap(otherDatesFetchKey, "readyToFetch");
    }
  }, [
    company,
    data,
    dataStatusMap,
    dates,
    editMode,
    fetchKey,
    otherData,
    otherDates,
    otherDatesFetchKey,
    presetID,
    setDataMap,
    setDataStatusMap,
    setError,
  ]);

  const [tabularData, otherTabularData] = useMemo(() => {
    if (!(data && preset && kpiMetaData)) {
      return [undefined, undefined];
    }
    let datas = [data];
    if (otherData) {
      datas.push(otherData);
    }
    return datas.map(data => {
      let totalsMap = constructTotalsMap(
        preset.columns,
        data.data,
        kpi,
        R.defaultTo(L.AUDIENCES[0], audience)
      );
      let rows: RowWithTabularData[] = [];
      for (let row of data.data) {
        rows.push({
          ...row,
          tabularRow: performanceRowToTableRow(
            row,
            preset.columns,
            totalsMap,
            kpi,
            kpiMetaData,
            R.defaultTo(L.AUDIENCES[0], audience),
            isInternal
          ),
        });
      }
      return rows;
    });
  }, [data, preset, isInternal, kpi, kpiMetaData, audience, otherData]);

  const otherDataRowMap = useMemo<Record<string, RowWithTabularData | undefined>>(() => {
    if (!otherTabularData) {
      return {};
    }
    let map: Record<string, RowWithTabularData | undefined> = {};
    for (let row of otherTabularData) {
      map[JSON.stringify(row.dimensions)] = row;
    }
    return map;
  }, [otherTabularData]);

  const filteredRows = useMemo(() => {
    if (!(tabularData && filterTerm && preset && creativeMap)) {
      return tabularData;
    }

    let lcFilterTerm = filterTerm.toLowerCase();
    let rows: RowWithTabularData[] = [];
    for (let row of tabularData) {
      for (let dimCol of preset.performanceDimensionColumns) {
        let rowContainsFilterTerm: (row: RowWithTabularData) => boolean;

        // In the data we are referring to creatives by their ISCI, so we need to get the name from
        // the creative map so that you can filter on creative name in the filter bar.
        if (dimCol.dimension === "Creative") {
          rowContainsFilterTerm = row =>
            creativeMap[row.dimensions.Creative || ""]?.name.toLowerCase().includes(lcFilterTerm);
        } else {
          rowContainsFilterTerm = row =>
            `${row.dimensions[dimCol.dimension] || ""}`.toLowerCase().includes(lcFilterTerm);
        }

        if (rowContainsFilterTerm(row)) {
          rows.push(row);
          break;
        }
      }
    }

    return rows;
  }, [filterTerm, tabularData, creativeMap, preset]);

  const [filterState, setFilterState, resetFilterState] = useFilterPaneState();
  const [filterID, setFilterID] = useState<number | undefined>();

  useEffect(() => {
    if (preset) {
      if (preset.filterID) {
        resetFilterState();
        setFilterID(preset.filterID);
      } else {
        setFilterID(undefined);
        if (preset.filterState) {
          setFilterState(preset.filterState);
        }
      }
    } else {
      resetFilterState();
    }
  }, [preset, resetFilterState, setFilterID, setFilterState]);

  const overviewData = useMemo(() => {
    if (!(filteredRows && preset && maxCpxValue)) {
      return;
    }

    let overviewFetchKey = L.getFetchKey({ kpi, audience: R.defaultTo(L.AUDIENCES[0], audience) });

    let aggregatedData = filteredRows.reduce((agg, row) => {
      let data = row.fetches[overviewFetchKey];
      if (!data) {
        return agg;
      }
      return mergeNumberObjects(agg, data);
    }, {} as L.ColumnDataRow);

    let imps = aggregatedData.impressions || 0;

    let overviewData: OverviewData = {
      imps,
      spend: aggregatedData.spend || 0,
      ecpm: L.calculateECPM(aggregatedData),
      lastUpdated: data?.lastModified || "Unknown",
      directConversions: imps ? (1000 * (aggregatedData.volume || 0)) / imps : 0,
      fractionalConversions: imps ? (1000 * (aggregatedData.volumeAll || 0)) / imps : 0,
      avgDirectRevenue: data?.revenue?.averageRevenue || -1,
      totalDirectRevenue: data?.revenue?.totalRevenue || -1,

      linear: {
        spots: aggregatedData.spots || 0,
        directCostPers: L.calculateCPV(aggregatedData, maxCpxValue),
        fractionalCostPers: L.calculateCPV(aggregatedData, maxCpxValue, true),
      },
    };

    return overviewData;
  }, [filteredRows, preset, data, kpi, maxCpxValue, audience]);

  useEffect(() => {
    if (R.isNil(filterData)) {
      (async () => {
        try {
          const res = await LinearPerformanceLambdaFetch<L.GetFilterOptionsParams>(
            "/getFilterOptions",
            {
              params: {
                company: globalBrand || company,
              },
            }
          );
          const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
          setFilterData(options);
        } catch (e) {
          let error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
      })();
    }
  }, [company, filterData, setError, globalBrand, dates]);

  const savePresetToDBAndReload = useCallback(
    async (body: L.SavePresetParams) => {
      try {
        const res = await LinearPerformanceLambdaFetch<L.SavePresetParams>("/savePreset", {
          method: "POST",
          body,
        });
        let { name } = await awaitJSON<P.SavePresetResponse>(res);
        navigate && navigate(`${location?.origin}${baseUrl}/${encodePrettyUrl(name)}`);
        window.location.reload();
      } catch (e) {
        let error = e as Error;
        setError({
          title: "Couldn't save view",
          message: error.message,
          reportError: error,
        });
        setSaving(false);
      }
    },
    [baseUrl, location, setError]
  );

  const saveView = useCallback(async () => {
    if (preset && presetID) {
      if (editMode !== "quick" && newName === "") {
        setError({
          title: "Blank Name",
          message: "Your view must have a name.",
        });
        return;
      }
      if (
        (newGroupName === STANDARD_GROUP_NAME) !==
        presetID.groups.includes(STANDARD_GROUP_NAME)
      ) {
        setError({
          title: "Protected Group",
          message: "Your view cannot change the default group.",
        });
        return;
      }
      setSaving(true);
      const newPreset: L.Preset = {
        ...preset,
        ...presetChanges,
        filterState,
        filterID: editMode === "new" ? undefined : filterID,
      };
      let body: L.SavePresetParams = {
        company: globalBrand || company,
        companyToUseInDB: company,
        id: editMode === "new" ? "new" : presetID.id,
        preset: newPreset,
      };

      if (editMode === "quick") {
        body.temporary = true;
      } else {
        body.name = newName;
        body.groups = newGroupName ? [newGroupName] : [];
      }
      await savePresetToDBAndReload(body);
    }
  }, [
    company,
    editMode,
    filterID,
    filterState,
    globalBrand,
    newGroupName,
    newName,
    preset,
    presetChanges,
    presetID,
    savePresetToDBAndReload,
    setError,
  ]);

  const saveFilter = useCallback<typeof setFilterState>(
    async newFilterState => {
      if (editMode) {
        setFilterState(newFilterState);
      } else if (preset) {
        setSaving(true);
        let body: L.SavePresetParams = {
          company: globalBrand || company,
          companyToUseInDB: company,
          id: "new",
          temporary: true,
          preset: {
            ...preset,
            filterState:
              typeof newFilterState === "function" ? newFilterState(filterState) : newFilterState,
          },
        };
        if (filterID) {
          body.preset.filterID = filterID;
        } else if (body.preset.filterID) {
          delete body.preset.filterID;
        }
        await savePresetToDBAndReload(body);
      }
    },
    [
      company,
      editMode,
      filterID,
      filterState,
      globalBrand,
      preset,
      savePresetToDBAndReload,
      setFilterState,
    ]
  );

  const exportLinearCSV = useCallback(() => {
    exportCSV({
      preset,
      tabularData,
      kpiMetaData,
      creativeMap,
      isInternal,
      kpi,
      DIMENSION_COLUMN_METADATA_MAP,
      COLUMN_METADATA_MAP,
      company: globalBrand || company,
      presetID,
      isLinear: true,
    });
  }, [
    isInternal,
    tabularData,
    preset,
    kpiMetaData,
    creativeMap,
    company,
    presetID,
    kpi,
    globalBrand,
  ]);

  const setAdminOnly = useCallback(
    newVal => setPresetChanges(current => ({ ...current, adminOnly: newVal })),
    []
  );

  const setShouldUseUnequivalizedImpressions = useCallback(
    newVal =>
      setPresetChanges(current => ({ ...current, shouldUseUnequivalizedImpressions: newVal })),
    []
  );

  return (
    <PerformanceContext.Provider
      value={{
        kpiMetaData: kpiMetaData || {},
        globalKpi: kpi,
        globalBrand: globalBrand,
        globalAudience: audience,
        prefix: "tv",
        // We don't actually use the below context values for Linear, we just initialize them
        // to avoid annoying typescript errors down the road because we have a shared context
        // for both streaming and linear.
        impsBetween: false,
        globalLag: "7d",
      }}
    >
      <Page
        minWidth="500px"
        title={
          <div className="performanceTitle">
            <div>Linear Performance</div>
            {presets &&
              editMode !== "quick" &&
              (editMode ? (
                <div className="viewControls">
                  <SuggestionInput
                    isDisabled={newGroupName === STANDARD_GROUP_NAME}
                    placeholder="Select Group..."
                    value={newGroupName}
                    options={selectGroupOptions.map(groupOption => ({
                      value: groupOption,
                      label: groupOption,
                    }))}
                    formatCreateLabel={value => `New group: ${value}`}
                    nonEmpty
                    className="groupInput"
                    classNamePrefix="groupInput"
                    maxMenuHeight={250}
                    onChange={option => {
                      if (option !== STANDARD_GROUP_NAME && option !== ALL_GROUP_NAME) {
                        setNewGroupName(option);
                      }
                    }}
                  />
                  <Form.Control
                    size="sm"
                    className="viewNameInput"
                    value={newName}
                    disabled={newGroupName === STANDARD_GROUP_NAME}
                    placeholder="New view name"
                    onChange={e => setNewName(e.currentTarget.value)}
                  />
                </div>
              ) : (
                <div className="multipleDropdowns">
                  <OldDropdown
                    searchable
                    label="Group"
                    size="sm"
                    className="presetPicker"
                    placeholder="Select Group..."
                    value={groupName}
                    options={groupOptions}
                    onChange={applyGroupSelection}
                  />
                  <OldDropdown
                    searchable
                    label="View"
                    size="sm"
                    className="presetPicker"
                    placeholder="Select View..."
                    value={presetName}
                    options={presetOptions}
                    onChange={applyPresetSelection}
                  />
                </div>
              ))}

            {preset &&
              (editMode ? (
                <div className="editControls">
                  <BPMButton size="sm" icon={<Save />} disabled={saving} onClick={saveView}>
                    {editMode === "quick" ? "Go" : "Save"}
                  </BPMButton>
                  <BPMButton
                    variant="outline-primary"
                    size="sm"
                    icon={<MdClose />}
                    disabled={saving}
                    onClick={() => {
                      // TODO: are you sure?
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </BPMButton>
                </div>
              ) : (
                <OptionsDropdown
                  location={location}
                  isInternal={isInternal}
                  preset={preset}
                  presetID={presetID}
                  baseUrl={baseUrl}
                  presetName={presetName}
                  presetOptions={allPresetsNames}
                  groupOptions={createViewGroupOptions}
                  datePickerDates={dates || startingDateRange}
                  isLinear
                  setNewName={setNewName}
                  setPresetChanges={setPresetChanges}
                  setEditMode={setEditMode}
                  setSaving={setSaving}
                  setError={setError}
                  hasExportCSVData={Boolean(tabularData && kpiMetaData && creativeMap)}
                  exportCSV={exportLinearCSV}
                />
              ))}

            {filterData && preset && (
              <FilterPane
                categories={filterData}
                company={company}
                filterID={filterID}
                highlightWhenFiltered
                platform={"linear"}
                save={saveFilter}
                setFilterID={setFilterID}
                setState={setFilterState}
                state={filterState}
              />
            )}
          </div>
        }
        pageType="Linear Performance"
        actions={
          !editMode && (
            <div className="performanceActions">
              {localStorage.getItem(`${company}_linearPerfDateRange`) && (
                <BPMButton size="sm" onClick={resetDefaultDates}>
                  Reset Default Dates
                </BPMButton>
              )}
              <BPMDateRange
                range={dates}
                onChange={onDatePickerChange}
                isDayBlocked={date =>
                  date > MONDAY_OF_LAST_WEEK || !R.pipe(Dfns.parseISO, Dfns.isMonday)(date)
                }
                fullWeeksOnly
              />
            </div>
          )
        }
      >
        <div className="performance">
          {editMode ? (
            <div className="miscControlContainer">
              <div
                className="kpiPickerConfigContainer"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="label">Global KPI and Audience:</div>
                <KpiPicker
                  kpi={resolvedKpi}
                  onChange={pickerSetKpi}
                  kpiMap={kpiPickerMap}
                  disableTypeSelector
                />
                <OldDropdown
                  size="sm"
                  options={L.AUDIENCES}
                  value={resolvedAudience}
                  onChange={pickerSetAudience}
                  label="Audience"
                />
                <BPMButton
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    setPresetChanges(R.omit(["globalKpi", "globalLag"]));
                    setKpi(preset?.globalKpi || companyInfo.initial_kpi);
                    setAudience(preset?.globalAudience || L.AUDIENCES[0]);
                  }}
                >
                  Reset to Default
                </BPMButton>
              </div>
              <div className="editControls">
                <ConfigToggle
                  toggledProperty={Boolean(presetChanges.adminOnly)}
                  setToggledProperty={setAdminOnly}
                  toggleText={"Admin Only"}
                  tooltipText={"Clients will not be able to see this preset listed."}
                />
                <ConfigToggle
                  toggledProperty={Boolean(presetChanges.shouldUseUnequivalizedImpressions)}
                  setToggledProperty={setShouldUseUnequivalizedImpressions}
                  toggleText={"Use Unequivalized Impressions"}
                  tooltipText={
                    "Impressions will be unequivalized (not weighted by creative length).  This will also impact Target Rating Points and eCPM."
                  }
                />
              </div>
              <DateConfig defaultDateState={defaultDateState} setPresetChanges={setPresetChanges} />
              <OverviewMetricsConfig
                isLinear
                presetChanges={presetChanges}
                setPresetChanges={setPresetChanges}
              />
            </div>
          ) : dates ? (
            <OverviewMetrics
              preset={preset}
              data={overviewData}
              start={dates.start}
              end={dates.end}
              filterTokens={preset?.filterTokens}
              presetID={presetID?.id}
              isLinear
              maxCpxValue={maxCpxValue}
            >
              <div
                className="overviewPickers"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {preset && kpiMetaData && (
                  <div
                    className="kpiPickerContainer"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <KpiPicker
                      kpi={resolvedKpi}
                      onChange={pickerSetKpi}
                      kpiMap={kpiPickerMap}
                      bold
                      normalTitle
                      disableTypeSelector
                    />
                    <OldDropdown
                      size="sm"
                      bold
                      normalTitle
                      options={L.AUDIENCES}
                      value={R.defaultTo(L.AUDIENCES[0], audience)}
                      onChange={pickerSetAudience}
                      label="Audience"
                    />
                  </div>
                )}
              </div>
            </OverviewMetrics>
          ) : undefined}
          {editMode ? (
            preset && companyInfo.initial_kpi ? (
              <PerformanceGridConfig
                preset={preset}
                presetChanges={presetChanges}
                setPresetChanges={setPresetChanges}
                isLinear
                isInternal={isInternal}
              />
            ) : (
              <PerformanceGridSkeleton />
            )
          ) : preset && filteredRows && creativeMap ? (
            <PerformanceGrid
              preset={preset}
              data={filteredRows}
              filterTerm={filterTerm}
              setFilterTerm={setFilterTerm}
              otherDataRowMap={otherDataRowMap}
              otherDates={otherDates}
              setOtherDates={setOtherDates}
              creativeMap={creativeMap}
              isLinear={true}
            />
          ) : (
            <PerformanceGridSkeleton />
          )}
        </div>
        {saving && <FullPageSpinner transparent fixed />}
      </Page>
    </PerformanceContext.Provider>
  );
};

export default LinearPerformance;
