import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Page, FullPageSpinner } from "../Components";
import { RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import MetaBuyingCreate from "./MetaBuyingCreate/MetaBuyingCreate";
import MetaBuyingUpload from "./MetaBuyingUpload";
import MetaBuyingDrafts from "./DraftsAndPublished/MetaBuyingDrafts";
import MetaBuyingPublished from "./DraftsAndPublished/MetaBuyingPublished";
import "./MetaBuying.scss";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import { MetaBuyingProvider } from "./MetaBuyingContext";
import { awaitJSON, MetaLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import { MetaBuyingTableData } from "@blisspointmedia/bpm-types/dist/MetaBuying";

export const enum PageTab {
  CREATE = "create",
  UPLOAD = "upload",
  DRAFTS = "drafts",
  PUBLISHED = "published",
}

const NAVS = [
  { label: "Create", key: PageTab.CREATE },
  { label: "Upload", key: PageTab.UPLOAD },
  { label: "Drafts", key: PageTab.DRAFTS },
  { label: "Published", key: PageTab.PUBLISHED },
];

const MetaBuying = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "social/meta-buying",
    defaultKey: PageTab.CREATE,
  });
  // const { cid } = useCompanyInfo();
  const cid = "culligan";
  const setError = useSetError();

  const isInternal = useSelector(UserRedux.isInternalSelector);

  // TODO: Add lambda for fetching ad accounts from the database
  const [adAccountOptions, setAdAccountOptions] = useState<
    {
      account_id: string;
      account_name: string;
      business_manager: string;
    }[]
  >([
    {
      account_id: "1504500373017084",
      account_name: "Bonafide",
      business_manager: "Ampush",
    },
    {
      account_id: "323885995724895",
      account_name: "Tinuiti - Sandbox",
      business_manager: "Tinuiti",
    },
  ]);
  const [selectedAdAccount, setSelectedAdAccount] = useState<{
    account_id: string;
    account_name: string;
    business_manager: string;
  }>({
    account_id: "323885995724895",
    account_name: "Tinuiti - Sandbox",
    business_manager: "Tinuiti",
  });
  const [namingConventions, setNamingConventions] = useState<{
    campaign: number[];
    adset: number[];
    ad: number[];
  }>({
    campaign: [],
    adset: [],
    ad: [],
  });
  const [dimensions, setDimensions] = useState<Record<string, any>>({});
  const [granularity, setGranularity] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        let res = await MetaLambdaFetch("/getClientInfo", { params: { company: cid } });
        let { data } = await awaitJSON(res);
        if (data.granularity) {
          setGranularity(data.granularity);
        }
        if (data.namingConventions) {
          setNamingConventions(data.namingConventions);
        }
        if (data.accounts) {
          setAdAccountOptions(data.accounts);
        }
        if (data.dimensions) {
          setDimensions(data.dimensions);
        }
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [cid, setError]);

  const [metaBuyingDataMap, setMetaBuyingDataMap] = useState<MetaBuyingTableData>();
  useEffect(() => {
    if (loading && selectedAdAccount && adAccountOptions && !metaBuyingDataMap) {
      (async () => {
        try {
          let res = await MetaLambdaFetch("/getMetaBuyingTableData", {
            params: {
              accountIDs: adAccountOptions.map(account => account.account_id).join(","),
            },
          });
          const obj = await awaitJSON<MetaBuyingTableData>(res);
          setMetaBuyingDataMap(obj);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [
    loading,
    metaBuyingDataMap,
    setMetaBuyingDataMap,
    selectedAdAccount,
    adAccountOptions,
    setError,
  ]);

  /*
   * Used to trigger refetch of data when certain actions are performed
   * in the drafts section.
   */
  const refreshData = useCallback(
    (tab: PageTab) => {
      setMetaBuyingDataMap(undefined);
      setLoading(true);
      goToTab(tab);
    },
    [setMetaBuyingDataMap, setLoading, goToTab]
  );

  // TODO: Add more states that need to be fetched before loading is set to false
  useEffect(() => {
    if (metaBuyingDataMap) {
      setLoading(false);
    }
  }, [metaBuyingDataMap]);

  const { campaignDrafts, publishedCampaigns } = useMemo(() => {
    if (metaBuyingDataMap) {
      const campaigns = metaBuyingDataMap[selectedAdAccount.account_id]?.campaign;

      return {
        campaignDrafts: campaigns.filter(campaign => campaign.meta_id === null),
        publishedCampaigns: campaigns.filter(campaign => campaign.meta_id !== null),
      };
    } else {
      return { campaignDrafts: [], publishedCampaigns: [] };
    }
  }, [metaBuyingDataMap, selectedAdAccount]);

  const { adSetDrafts, publishedAdSets } = useMemo(() => {
    if (metaBuyingDataMap) {
      const adSets = metaBuyingDataMap[selectedAdAccount.account_id]?.adset;

      return {
        adSetDrafts: adSets.filter(adSet => adSet.meta_id === null),
        publishedAdSets: adSets.filter(adSet => adSet.meta_id !== null),
      };
    } else {
      return { adSetDrafts: [], publishedAdSets: [] };
    }
  }, [metaBuyingDataMap, selectedAdAccount]);

  const { adDrafts, publishedAds } = useMemo(() => {
    if (metaBuyingDataMap) {
      const ads = metaBuyingDataMap[selectedAdAccount.account_id]?.ad;

      return {
        adDrafts: ads.filter(ad => ad.meta_id === null),
        publishedAds: ads.filter(ad => ad.meta_id !== null),
      };
    } else {
      return { adDrafts: [], publishedAds: [] };
    }
  }, [metaBuyingDataMap, selectedAdAccount]);

  return (
    <MetaBuyingProvider>
      <Page
        app2Redesign
        title="Meta Buying"
        pageType="Meta Buying"
        navs={NAVS}
        selectedNav={tab}
        onNav={goToTab}
      >
        <div className="metaBuyingPage">
          {loading ? (
            <FullPageSpinner />
          ) : (
            <Router className="fullPageRouter">
              <MetaBuyingCreate
                default
                path={PageTab.CREATE}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                namingConventions={namingConventions}
                client={cid}
                granularity={granularity}
                dimensions={dimensions}
              />
              <MetaBuyingUpload
                path={PageTab.UPLOAD}
                selectedAdAccount={selectedAdAccount}
                refreshData={refreshData}
              />
              <MetaBuyingDrafts
                path={PageTab.DRAFTS}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                campaignRows={campaignDrafts}
                adSetRows={adSetDrafts}
                adRows={adDrafts}
                refreshData={refreshData}
                goToTab={goToTab}
              />
              <MetaBuyingPublished
                path={PageTab.PUBLISHED}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                campaignRows={publishedCampaigns}
                adSetRows={publishedAdSets}
                adRows={publishedAds}
              />
            </Router>
          )}
        </div>
      </Page>
    </MetaBuyingProvider>
  );
});

export default MetaBuying;
