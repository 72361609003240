import "./Login.scss";
import { Alert, AlertProps, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { awaitJSON, UsersLambdaFetch } from "../utils/fetch-utils";
import { formatMessage } from "../Components/Page";
import { Spinner } from "../Components";
import { useSetAreYouSure, useModalInfo } from "../redux/modals";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  clearRefreshToken,
  getGoogleRefreshToken,
  loginRedirect,
  refreshGoogleOAuthToken,
  signInAWS,
  signInChangePasswordAWS,
  signInGoogleOAuth,
} from "../utils/auth";

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_TINUITI_GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
const LOGO_SCALE = 2 / 5;
const LOGO_HEIGHT = 555;
const LOGO_WIDTH = 2108;

const Login: React.FC = () => {
  const [changeCognitoPassword, setChangeCognitoPassword] = useState(false);
  const [cognitoConfirmPassword, setConfirmPassword] = useState("");
  const [cognitoNewPassword, setCognitoNewPassword] = useState("");
  const [cognitoPassword, setCognitoPassword] = useState("");
  const [cognitoUsername, setCognitoUsername] = useState("");
  const [cognitoVerificationCode, setCognitoVerificationCode] = useState("");
  const [googleCodeClient, setGoogleCodeClient] = useState<any>();
  const [googleObject, setGoogleObject] = useState<any>();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingInGoogle, setIsLoggingInGoogle] = useState(false);
  const [loginError, setLoginError] = useState<string>();
  const [resetCognitoPasswordFlow, setResetCognitoPasswordFlow] = useState(false);
  const [showCognitoPassword, setShowCognitoPassword] = useState(false);

  const { areYouSure } = useModalInfo();
  const setAreYouSure = useSetAreYouSure(true);

  const googleFailure = useCallback(error => {
    setIsLoggingInGoogle(false);
    setLoginError(`Unable to login via Google: ${error}`);
  }, []);

  const googleOAuthLogin = useCallback(
    async response => {
      setIsLoggingInGoogle(true);
      setLoginError("");
      try {
        await signInGoogleOAuth(response);
      } catch (e) {
        const error = e as Error;
        setIsLoggingInGoogle(false);
        googleFailure(error.message);
      }
    },
    [googleFailure]
  );

  useEffect(() => {
    let googleObj;
    if (!googleObject) {
      try {
        googleObj = window[`${"google"}`];
      } catch (e) {
        const error = e as Error;
        if (error.message === "google is not defined" || e instanceof ReferenceError) {
          console.error(e);
        } else {
          const error = e as Error;
          throw new Error(error.message);
        }
      } finally {
        setGoogleObject(googleObj);
      }
    }
  }, [googleObject, setGoogleObject]);

  useEffect(() => {
    if (googleObject) {
      try {
        setGoogleCodeClient(
          googleObject.accounts.oauth2.initCodeClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: "profile email openid",
            ux_mode: "popup",
            redirect_uri: "postmessage",
            callback: async response => {
              const code_receiver_uri = "https://oauth2.googleapis.com/token";
              const xhr = new XMLHttpRequest();
              xhr.open("POST", code_receiver_uri, true);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
              // Set custom header for CRSF
              xhr.setRequestHeader("X-Requested-With", "XmlHttpRequest");
              xhr.onload = () => {
                googleOAuthLogin(JSON.parse(xhr.responseText));
              };
              const postBody = `code=${response.code}&redirect_uri=postmessage&scope=${response.scope}&client_id=${GOOGLE_CLIENT_ID}&client_secret=${GOOGLE_CLIENT_SECRET}&grant_type=authorization_code`;
              await xhr.send(postBody);
            },
          })
        );
      } catch (e) {
        const error = e as Error;
        if (error.message === "google is not defined") {
          console.error(error);
        } else {
          throw new Error(error.message);
        }
      }
    }
  }, [googleOAuthLogin, googleObject]);

  const resetPassword = useCallback(async () => {
    await setAreYouSure({
      title: "Confirm Forgot Password",
      message: "You are about to reset your password, are you sure?",
      variant: "warning",
      cancelText: "Never mind",
      okayText: "Yes",
      onOkay: async () => {
        if (cognitoUsername.indexOf("@") > 0) {
          setResetCognitoPasswordFlow(true);
          let res = await UsersLambdaFetch("/forgotPassword", {
            method: "POST",
            body: { username: cognitoUsername },
          });
          await awaitJSON(res);
        } else {
          setLoginError("Invalid email address");
        }
      },
    });
  }, [cognitoUsername, setAreYouSure]);

  const cognitoLogin = useCallback(async () => {
    setIsLoggingIn(true);
    setLoginError("");
    try {
      // True if the user has signed in with a temporary password
      if (changeCognitoPassword) {
        await signInChangePasswordAWS(cognitoUsername, cognitoPassword, cognitoNewPassword);
        loginRedirect();
      } else if (resetCognitoPasswordFlow) {
        let res = await UsersLambdaFetch("/confirmForgotPassword", {
          method: "POST",
          body: {
            username: cognitoUsername,
            confirmationCode: cognitoVerificationCode,
            password: cognitoNewPassword,
          },
        });
        await awaitJSON(res);
        setResetCognitoPasswordFlow(false);
        setIsLoggingIn(false);
        loginRedirect();
      } else {
        // Sign in normally
        const user = await signInAWS(cognitoUsername, cognitoPassword);
        // Check if the signed in user requires a new password
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setChangeCognitoPassword(true);
          setIsLoggingIn(false);
        } else {
          loginRedirect();
        }
      }
    } catch (e) {
      const error = e as Error;
      setLoginError(error.message);
      setIsLoggingIn(false);
    }
  }, [
    cognitoUsername,
    cognitoPassword,
    cognitoNewPassword,
    changeCognitoPassword,
    resetCognitoPasswordFlow,
    cognitoVerificationCode,
  ]);

  // Checks that the user's new password is valid according to our Cognito policy
  const validPassword = useMemo(() => {
    // Password needs to be 8+ characters long and have uppercase & lowecase letters, a special character, and number
    let passwordRegExp = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
    return changeCognitoPassword
      ? cognitoNewPassword === cognitoConfirmPassword && passwordRegExp.test(cognitoNewPassword)
      : true;
  }, [cognitoConfirmPassword, cognitoNewPassword, changeCognitoPassword]);

  const areYouSureModal = areYouSure ? (
    <Modal show={true} onHide={areYouSure.onCancel} centered={Boolean(areYouSure.centered)}>
      <Alert variant={areYouSure.variant as AlertProps["variant"]} className="BPMModalAlert">
        <Alert.Heading>{areYouSure.title}</Alert.Heading>
        {formatMessage(areYouSure.message)}
        <hr />
        <div className="modalFooter">
          <Button
            variant={`outline-${areYouSure.variant}` as AlertProps["variant"]}
            onClick={areYouSure.onCancel}
          >
            {areYouSure.cancelText}
          </Button>
          <Button variant={areYouSure.variant} onClick={areYouSure.onOkay}>
            {areYouSure.okayText}
          </Button>
        </div>
      </Alert>
    </Modal>
  ) : (
    <div />
  );

  return (
    <div className="bpmLogin">
      <img
        className="agencyLogo"
        alt={"Bliss Point by Tinuiti"}
        src={"https://cdn.blisspointmedia.com/logos/login_logo.svg"}
        height={LOGO_HEIGHT * LOGO_SCALE}
        width={LOGO_WIDTH * LOGO_SCALE}
      />
      <div className="bpmLoginInner">
        {areYouSureModal}
        <Form className="loginForm">
          {resetCognitoPasswordFlow && (
            <Alert variant="info">
              Please check your email for a verification code from automation@tinuiti.com and enter
              it in the "Verification Code" field along with a new password. Your new password must
              be at least 8 characters long and contain an upperase letter, lowercase letter,
              number, and special character.
            </Alert>
          )}
          <InputGroup size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text className="loginPrepend">Email</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Email"
              value={cognitoUsername}
              onChange={e => setCognitoUsername(e.target.value)}
              readOnly={resetCognitoPasswordFlow || changeCognitoPassword}
            />
          </InputGroup>
          {!changeCognitoPassword && !resetCognitoPasswordFlow && (
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="loginPrepend">Password</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type={showCognitoPassword ? "text" : "password"}
                placeholder="Password"
                value={cognitoPassword}
                onChange={e => setCognitoPassword(e.target.value)}
              />
            </InputGroup>
          )}
          {(changeCognitoPassword || resetCognitoPasswordFlow) && (
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="loginPrepend">New Password</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type={showCognitoPassword ? "text" : "password"}
                placeholder="New Password"
                value={cognitoNewPassword}
                onChange={e => setCognitoNewPassword(e.target.value)}
              />
            </InputGroup>
          )}
          {changeCognitoPassword && (
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="loginPrepend">Confirm Password</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type={showCognitoPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={cognitoConfirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </InputGroup>
          )}
          {(changeCognitoPassword || resetCognitoPasswordFlow) && (
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="showCognitoPassword">Show password</InputGroup.Text>
              </InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Show password"
                size="sm"
                onChange={e => setShowCognitoPassword(e.target.checked)}
                checked={showCognitoPassword}
              />
            </InputGroup>
          )}
          {resetCognitoPasswordFlow && (
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="loginPrepend">Verification Code</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Code"
                value={cognitoVerificationCode}
                onChange={e => setCognitoVerificationCode(e.target.value)}
              />
            </InputGroup>
          )}
          {loginError && <Alert variant="danger">{loginError}</Alert>}
          {(changeCognitoPassword || resetCognitoPasswordFlow) &&
            cognitoNewPassword.length > 0 &&
            !validPassword && (
              <Alert variant="danger">
                Your new password must be at least 8 characters long and contain an uppercase
                letter, lowercase letter, number, and special character.
              </Alert>
            )}
          <Button
            block
            className="loginButton"
            disabled={isLoggingIn || !validPassword || isLoggingInGoogle}
            size="lg"
            onClick={cognitoLogin}
            type="submit"
          >
            {changeCognitoPassword || resetCognitoPasswordFlow ? (
              isLoggingIn ? (
                <Spinner color="white" size={34} />
              ) : (
                "Change Password & Log In"
              )
            ) : isLoggingIn ? (
              <Spinner color="white" size={34} />
            ) : (
              "Log In"
            )}
          </Button>
          {!resetCognitoPasswordFlow && !changeCognitoPassword && (
            <Button className="forgotPassword" variant="link" onClick={resetPassword}>
              Forgot Password?
            </Button>
          )}
          {googleCodeClient && (
            <Button
              block
              className="signInWithGoogleButton"
              disabled={isLoggingIn || !validPassword || isLoggingInGoogle}
              size="lg"
              onClick={() =>
                getGoogleRefreshToken()
                  ? (async () => {
                      try {
                        const refreshResp = (await refreshGoogleOAuthToken(true)) as any;
                        setTimeout(() => {
                          if (!refreshResp) {
                            clearRefreshToken();
                            googleCodeClient.requestCode();
                          }
                        }, 3000);
                      } catch (e) {
                        clearRefreshToken();
                        console.error(
                          `Issue with auto google oauth refresh:${e}. Defaulting to new login.`
                        );
                        googleCodeClient.requestCode();
                      }
                    })()
                  : googleCodeClient.requestCode()
              }
            >
              {isLoggingInGoogle ? (
                <Spinner color="#4285f4" size={34} />
              ) : (
                <div className="signInWithGoogleContainer">
                  <img
                    width="28px"
                    alt=""
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                  />
                  Sign In With Google
                </div>
              )}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Login;
