import React, { useContext, useCallback, useMemo, useState } from "react";

import * as R from "ramda";

import { Form, Modal, Button, Tooltip } from "react-bootstrap";

import { MdHelp } from "react-icons/md";

import { useCreativeMap } from "../redux/creative";

import useLocation from "../utils/hooks/useLocation";

import { PieSetter, OverlayTrigger } from "../Components";

import { StaticCard } from "./Cards";

import { StreamingCreativesContext, WORKSPACE_KEY, NON_LIVE_COLOR } from "./StreamingCreatives";

import { NonCartContext } from "./NonCart";
import ViewPlacementMetadata from "./ViewPlacementMetadata";

const ViewPlacement = () => {
  const {
    shopData,
    date,
    workspaceOptions,
    setWorkspaceMap,
    selectedMap,
    goToTab,
    setSelectedMap,
  } = useContext(StreamingCreativesContext);

  const { viewPlacementId, setViewPlacementId, filteredPlacements } = useContext(NonCartContext);

  const { company } = useLocation();
  const { creativeMap, colorMap, isciLiveOnDate } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const [autoSelectSelf, setAutoSelectSelf] = useState(true);
  const [selectSameCheck, setSelectSameCheck] = useState(false);

  const loadRotation = useCallback(() => {
    let { creatives, rotationKey, id } = R.path(["placementMap", viewPlacementId], shopData);

    let localCreativeMap = {};

    for (let { isci, pct, start_date, end_date } of creatives) {
      if (start_date <= date && (!end_date || end_date >= date)) {
        localCreativeMap[isci] = pct;
      }
    }
    let newWorkspace = {};
    for (let { isci } of workspaceOptions) {
      newWorkspace[isci] = localCreativeMap[isci] || 0;
    }

    setWorkspaceMap(newWorkspace);

    if (selectSameCheck || autoSelectSelf) {
      let newSelectedMap = { ...selectedMap };
      if (autoSelectSelf) {
        newSelectedMap[id] = true;
      }
      if (selectSameCheck) {
        for (let placement of filteredPlacements) {
          if (rotationKey === shopData.placementMap[placement].rotationKey) {
            newSelectedMap[placement] = true;
          }
        }
      }
      setSelectedMap(newSelectedMap);
    }

    setViewPlacementId();
    goToTab(WORKSPACE_KEY);
  }, [
    shopData,
    date,
    workspaceOptions,
    autoSelectSelf,
    selectSameCheck,
    selectedMap,
    setWorkspaceMap,
    setSelectedMap,
    goToTab,
    setViewPlacementId,
    filteredPlacements,
    viewPlacementId,
  ]);

  const placement = useMemo(
    () => R.path(["placementMap", viewPlacementId], shopData),
    [shopData, viewPlacementId]
  );

  const [data, placementLevelClickthroughUrl, shouldShowCreativeMetadata] = useMemo(() => {
    let data = [];
    let notLive = [];
    let placementClickthroughUrl;
    let shouldShowCreativeMetadata = true;
    if (placement) {
      // If all creatives are using the same clickthrough URL, display it as a placement level clickthrough.
      // If they differ at all, break out the display by ISCI.
      const creativeLevelClickthroughs = placement.creatives.map(
        creative => creative.creative_clickthrough_url || placement.placement_clickthrough_url
      );
      const allUseSameClickthrough = R.uniq(creativeLevelClickthroughs).length === 1;

      // Determine what to display as the placement-level clickthrough if
      // all ISCIs have the same clickthrough.
      if (allUseSameClickthrough) {
        // Use the value from the creatives if it's different from what's set at
        // the placement level.
        if (
          creativeLevelClickthroughs.length &&
          creativeLevelClickthroughs[0] &&
          creativeLevelClickthroughs[0] !== placement.placement_clickthrough_url
        ) {
          placementClickthroughUrl = creativeLevelClickthroughs[0];
        } else {
          placementClickthroughUrl = placement.placement_clickthrough_url || "None";
        }
      }

      // Don't render the creative metadata section if we're showing a placement-level
      // clickthrough URL and there are no audio companions to display.
      const hasAudioCompanions = Boolean(placement.creatives.filter(creative => creative.audio_companion)
        .length);
      if (allUseSameClickthrough && !hasAudioCompanions) {
        shouldShowCreativeMetadata = false;
      }

      for (let {
        isci,
        pct,
        start_date,
        end_date,
        creative_clickthrough_url,
        audio_companion,
      } of placement.creatives) {
        if (start_date > date || (end_date && end_date < date)) {
          continue;
        }
        let creativeData = creativeMap[isci] || {};
        let item = {
          ...creativeData,
          creative: creativeData.name || isci,
          name: isci,
          key: isci,
          value: pct,
          creativeClickthroughUrl: allUseSameClickthrough
            ? null
            : creative_clickthrough_url || placement.placement_clickthrough_url || "None",
          audioCompanion: audio_companion,
        };

        if (isciLiveOnDate({ isci, date })) {
          data.push({
            ...item,
            color: colorMap[isci] || "white",
            isLive: true,
          });
        } else {
          notLive.push({
            ...item,
            color: NON_LIVE_COLOR,
            isLive: false,
          });
        }
      }
    }
    return [
      [...R.sortBy(R.prop("isci"), data), ...R.sortBy(R.prop("isci"), notLive)],
      placementClickthroughUrl,
      shouldShowCreativeMetadata,
    ];
  }, [placement, date, creativeMap, colorMap, isciLiveOnDate]);

  return (
    <Modal show dialogClassName="viewPlacementModal" onHide={() => setViewPlacementId()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {placement.network} {placement.platform}{" "}
          <span className="tagName">"{placement.name}"</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="rotationBody">
          {data.length ? (
            <PieSetter percentage data={data} renderItem={elem => <StaticCard {...elem} />} />
          ) : (
            <div className="noRotation">No rotation</div>
          )}
        </div>
        <div className="metadata">
          <ViewPlacementMetadata
            placementLevelClickthroughUrl={placementLevelClickthroughUrl}
            trackingPixels={placement.tracking_pixels}
            creatives={data}
            shouldShowCreativeMetadata={shouldShowCreativeMetadata}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <div className="viewPlacementFooter">
          <div className="loadRotation">
            <Button variant="primary" onClick={() => loadRotation(selectSameCheck, autoSelectSelf)}>
              Load Into Workspace
            </Button>
            <div
              className="checkAll"
              onClick={() => {
                setAutoSelectSelf(!autoSelectSelf);
              }}
            >
              <Form.Check custom inline type="checkbox">
                <Form.Check.Input
                  checked={autoSelectSelf}
                  onChange={() => {
                    // Handled by the parent div
                  }}
                />
                <Form.Check.Label>
                  <span>Select this tag as well.&nbsp;</span>
                  <OverlayTrigger
                    placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                    overlay={
                      <Tooltip id="autoSelectSelf">
                        When this is checked and you load this rotation into the workspace, it will
                        also add this tag to your workspace as if you hit the + in the menu.
                      </Tooltip>
                    }
                  >
                    <MdHelp />
                  </OverlayTrigger>
                </Form.Check.Label>
              </Form.Check>
            </div>
            <div
              className="checkAll"
              onClick={() => {
                setSelectSameCheck(!selectSameCheck);
              }}
            >
              <Form.Check custom inline type="checkbox">
                <Form.Check.Input
                  checked={selectSameCheck}
                  onChange={() => {
                    // Handled by the parent div
                  }}
                />
                <Form.Check.Label>
                  <span>Select all filtered tags with the same rotation.&nbsp;</span>
                  <OverlayTrigger
                    placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                    overlay={
                      <Tooltip id="checkAllTags">
                        If you check this box before you load this rotation into the workspace, it
                        will automatically select every tag with the same rotation. However, it will
                        only select tags you currently have in view. In other words, if you're
                        currently filtering for a certain network, it will only select tags for that
                        network. For instance, if you're filtering for "Network is like NBC" and
                        this is a 15s tag, it will select all NBC tags that have the same 15s
                        rotation, but not tags for 30s or from other networks.
                      </Tooltip>
                    }
                  >
                    <MdHelp />
                  </OverlayTrigger>
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <Button onClick={() => setViewPlacementId()}>Done</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewPlacement;
