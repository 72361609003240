import {
  awaitJSON,
  DagLambdaFetch,
  MiscLambdaFetch,
  pollS3,
  SingleChannelLambdaFetch,
  StreamingPerformanceLambdaFetch,
} from "../../utils/fetch-utils";
import { DateRange, typedReactMemo } from "../../utils/types";
import { DimensionMap } from "../MetricsTable/metricsTableUtils";
import { fetchDailyDelivery } from "../../StreamingDelivery/StreamingDelivery";
import { FullPageSpinner } from "../../Components";
import { getGlobalBrand } from "../../Performance/performanceUtils";
import { RouteComponentProps } from "@reach/router";
import { useCompanyInfo } from "../../redux/company";
import { useCreativeMap } from "../../redux/creative";
import { useDerivedNetworkMap } from "../../redux/networks";
import { useSetError } from "../../redux/modals";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import SingleChannelPage from "../SingleChannelPage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useLocation from "../../utils/hooks/useLocation";
import {
  columnMetaDataMap,
  getDimensionCell as STR_GET_DIMENSION_CELL,
  GLOSSARY_GRAPH,
  GLOSSARY_IP,
  dimensionColumnMetaDataMap,
} from "./streamingPerformanceUtils";
import {
  DeletePresetParams as DeletePagePresetParams,
  GetPresetsParams as GetPagePresetsParams,
  MetricsPagePreset,
  SavePresetParams as SavePagePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsPage";
import {
  DeletePresetParams as DeleteTablePresetParams,
  DimensionColumn,
  GetPresetParams as GetTablePresetParams,
  MetricsTablePreset,
  SavePresetParams as SaveTablePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";

interface StreamingSingleChannelProps extends RouteComponentProps {
  isGraph: boolean;
  prefix: S.Prefix;
  urlSettings: string;
}

const StreamingSingleChannel = typedReactMemo<React.FC<StreamingSingleChannelProps>>(
  ({ isGraph = false, location, prefix, urlSettings }) => {
    const [dates, setDates] = useState<DateRange>();
    const [fetchingPagePresets, setFetchingPagePresets] = useState(false);
    const [fetchingTablePresets, setFetchingTablePresets] = useState(false);
    const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();
    const [kpiMetaData, setKpiMetaData] = useState<P.GetKpiMetaDataResponse>();
    const [pagePresets, setPagePresets] = useState<MetricsPagePreset[]>();
    const [percentAOIPlaceholder, setPercentAOIPlaceholder] = useState();
    const [tablePresets, setTablePresets] = useState<MetricsTablePreset[]>();
    const { company } = useLocation();
    const companyInfo = useCompanyInfo();
    const derivedNetworkMap = useDerivedNetworkMap(company);
    const globalBrand = getGlobalBrand(companyInfo, companyInfo.streaming_performance_default_kpi);
    const setError = useSetError();

    const { creativeMap } = useCreativeMap({
      company: globalBrand || company,
      mediaTypes: ["streaming", "audio", "display"],
    });

    const creativeNameMap = useMemo(() => {
      let res = {};
      if (creativeMap) {
        for (const key of R.keys(creativeMap)) {
          const { name } = creativeMap[key];
          if (R.isNil(res[name]) || !res[name].file) {
            res[name] = creativeMap[key];
          }
        }
      }
      return res;
    }, [creativeMap]);

    const getPagePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingPagePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_page_presets",
            {
              params: {
                company,
                mediatype: prefix,
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setPagePresets(parsedPresets as MetricsPagePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch page presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingPagePresets(false);
        }
      })();
    }, [company, prefix, setError]);

    const getTablePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingTablePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_table_presets",
            {
              params: {
                company,
                mediatype: prefix,
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setTablePresets(parsedPresets as MetricsTablePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch table presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingTablePresets(false);
        }
      })();
    }, [company, prefix, setError]);

    const getTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            const res = await SingleChannelLambdaFetch<GetTablePresetParams>(
              "/metrics_table_preset",
              {
                params: { ...params, isGraph },
              }
            );
            return await awaitJSON<MetricsTablePreset>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to fetch table preset. Error: ${error.message}`,
              reportError: error,
            });
          }
        })();
      },
      [isGraph, setError]
    );

    const savePagePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<SavePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "POST",
                body: { ...body, isGraph },
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
            return presetResponse;
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save page preset. Error: ${error.message}`,
              reportError: error,
            });
            presetResponse = { message: `Failed to save page preset. Error: ${error.message}` };
          } finally {
            getPagePresets();
          }
        })();
      },
      [getPagePresets, isGraph, setError]
    );

    const saveTablePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<SaveTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "POST",
                body: { ...body, isGraph },
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
            return presetResponse;
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
          }
        })();
      },
      [getTablePresets, isGraph, setError]
    );

    const deletePagePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<DeletePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "DELETE",
                params: { ...params, isGraph },
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete page preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getPagePresets();
          }
        })();
      },
      [getPagePresets, isGraph, setError]
    );

    const deleteTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<DeleteTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "DELETE",
                params: { ...params, isGraph },
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
          }
        })();
      },
      [getTablePresets, isGraph, setError]
    );

    const getDeliveryData = useCallback(params => {
      return (async () => {
        return await fetchDailyDelivery(params);
      })();
    }, []);

    const getPerformanceData = useCallback(
      params => {
        return (async () => {
          const result = await MiscLambdaFetch("/kickOffLambda", {
            method: "POST",
            body: {
              fileType: "txt",
              lambdaArgs: {
                ...params,
                isGraph,
              },
              lambdaName: "streamingPerformance-getPageData",
            },
          });
          const uuid = await awaitJSON(result);
          const content = await pollS3({
            autoDownload: false,
            bucket: "bpm-cache",
            filename: `${uuid}.txt`,
            mimeType: "text/plain",
            period: 3000,
          });
          const textContent = await content.text();
          return JSON.parse(textContent) as S.GetPageDataResponse;
        })();
      },
      [isGraph]
    );

    const getDimensionCell = useCallback(
      (dimensionData: DimensionMap, dimensionHeader: DimensionColumn) =>
        STR_GET_DIMENSION_CELL(
          dimensionData,
          dimensionHeader,
          { ...creativeNameMap, ...creativeMap },
          derivedNetworkMap
        ),
      [creativeMap, creativeNameMap, derivedNetworkMap]
    );

    useEffect(() => {
      if (company && !pagePresets) {
        getPagePresets();
      }
    }, [company, getPagePresets, isGraph, pagePresets, prefix, setError, urlSettings]);

    useEffect(() => {
      if (company && !tablePresets) {
        getTablePresets();
      }
    }, [
      company,
      getTablePresets,
      isGraph,
      pagePresets,
      prefix,
      setError,
      tablePresets,
      urlSettings,
    ]);

    useEffect(() => {
      if (company && !filterData) {
        (async () => {
          try {
            const res = await StreamingPerformanceLambdaFetch<S.GetFilterOptionsParams>(
              "/filter_options",
              {
                params: {
                  company: globalBrand || company,
                  prefix,
                },
              }
            );
            const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
            setFilterData(options);
          } catch (e) {
            const error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, filterData, globalBrand, prefix, setError]);

    useEffect(() => {
      if (company && !kpiMetaData) {
        (async () => {
          try {
            const res = await StreamingPerformanceLambdaFetch<P.GetKpiMetaDataParams>("/kpis", {
              params: {
                company,
              },
            });
            const kpiMap = await awaitJSON<P.GetKpiMetaDataResponse>(res);
            setKpiMetaData(kpiMap);
          } catch (e) {
            const error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, kpiMetaData, setError]);

    useEffect(() => {
      if (company && dates) {
        (async () => {
          try {
            const res = await DagLambdaFetch("/get_percent_aoi_placeholder", {
              params: {
                company,
                platform: prefix,
                start: dates.start,
                end: dates.end,
              },
            });
            const placeholder = await awaitJSON(res);
            setPercentAOIPlaceholder(placeholder);
          } catch (e) {
            const error = e as Error;
            setError({
              reportError: error,
              message: `Failed to fetch % Incremental placeholder value. Error: ${error.message}`,
            });
          }
        })();
      }
    }, [company, dates, prefix, setError]);

    return creativeMap && derivedNetworkMap && pagePresets && tablePresets ? (
      <SingleChannelPage
        columnMetaDataMap={columnMetaDataMap}
        dataFetchKey={S.getFetchKey}
        dates={dates as any}
        deletePagePreset={deletePagePreset}
        deleteTablePreset={deleteTablePreset}
        deliveryDimensionMap={{
          "Network Group": "network_group",
          Creative: "creative",
          DeviceOS: "deviceos",
          Length: "length",
          Network: "network",
        }}
        dimensionColumnMetaDataMap={dimensionColumnMetaDataMap}
        fetchingPagePresets={fetchingPagePresets}
        fetchingTablePresets={fetchingTablePresets}
        filterData={R.defaultTo([], filterData)}
        getDeliveryData={getDeliveryData}
        getDimensionCell={getDimensionCell}
        getPerformanceData={getPerformanceData}
        getTablePreset={getTablePreset}
        glossary={isGraph ? GLOSSARY_GRAPH : GLOSSARY_IP}
        isGraph={isGraph}
        kpiMetaData={R.defaultTo({}, kpiMetaData)}
        location={location}
        pagePresets={R.defaultTo([], pagePresets)}
        percentAOIPlaceholder={percentAOIPlaceholder}
        prefix={prefix}
        savePagePreset={savePagePreset}
        saveTablePreset={saveTablePreset}
        setDates={setDates as any}
        setPagePresets={setPagePresets}
        setTablePresets={setTablePresets}
        sparkChartMetricsMap={{
          cpm: "cpm",
          cpx: "cpxDirect",
          impressions: "imps",
          impsConversionRate: "impsConversionRateDirect",
          revenue: "revenueDirect",
          roas: "roasDirect",
          spend: "spend",
          volume: "volumeDirect",
        }}
        sparkChartMetricPrettyNameMap={{
          cpm: "CPM",
          cpx: "CPX",
          impressions: "Impressions",
          impsConversionRate: "Imps Conversion Rate",
          revenue: "KPI Revenue",
          roas: "ROAS",
          spend: "Spend",
          volume: "KPI Volume",
        }}
        tablePresets={R.defaultTo([], tablePresets)}
        urlSettings={urlSettings}
      />
    ) : (
      <FullPageSpinner />
    );
  }
);

export default StreamingSingleChannel;
