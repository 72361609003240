import * as R from "ramda";
import { differenceInDays, eachDayOfInterval, startOfYesterday } from "date-fns";
import { MetricsLambdaFetch, MiscLambdaFetch, awaitJSON, pollS3 } from "../../utils/fetch-utils";
import { SetError } from "../../redux/modals";
import {
  MediaEntry,
  MediaTable,
  GetMMMOfflineInputsResponse,
  OfflineInputTabs,
  AddMediaInputArgs,
  DeleteMMMOfflineInputArgs,
  UpdateMediaInputsArgs,
  UpdateMMMOfflineInputsArgs,
  AddMMMOfflineInputArgs,
  UpdateMMMOfflineInputsResponse,
  OfflineInputsTable,
  OfflineInputsEntry,
  UpdateInputsArgs,
  OutcomeDataEntry,
  UpdateOutcomeDataArgs,
} from "@blisspointmedia/bpm-types/dist/OfflineInputs";
import { convertDateToUTC } from "../../utils/format-utils";

export interface QueuedRequest {
  type: "CREATE" | "DELETE";
  body: AddMMMOfflineInputArgs | DeleteMMMOfflineInputArgs;
}

const buildBlankDates = (startDate: Date, endDate?: Date): Date[] => {
  const startDateUTC = convertDateToUTC(startDate);
  const endDateUTC = endDate ? convertDateToUTC(endDate) : startOfYesterday();

  if (!(startDateUTC <= startOfYesterday())) {
    return [];
  }

  const blanks = eachDayOfInterval({
    start: startDateUTC,
    end: endDateUTC,
  });
  blanks.reverse();

  return blanks;
};

export const buildBlankMediaEntries = (startDate: Date, endDate?: Date): MediaEntry[] => {
  const blanks = buildBlankDates(startDate, endDate);

  return [
    ...blanks.map((date, i) => ({
      date: date.toISOString(),
      impressions: undefined,
      otherUnits: undefined,
      redemptions: undefined,
      spend: undefined,
    })),
  ];
};

export const buildBlankOutcomeDataEntries = (
  startDate: Date,
  endDate?: Date
): OutcomeDataEntry[] => {
  const blanks = buildBlankDates(startDate, endDate);

  return [
    ...blanks.map((date, i) => ({
      date: date.toISOString(),
      volume: undefined,
    })),
  ];
};

export interface EntryEditsMap {
  [date: string]: OfflineInputsEntry;
}

export interface TableEditsMap {
  [tableId: string]: EntryEditsMap;
}

export interface NewTableEntriesMap {
  [tableId: string]: OfflineInputsEntry[];
}

export const fetchTables = async (
  company: string,
  setTablesByTab: React.Dispatch<
    React.SetStateAction<{ [tabKey in OfflineInputTabs]: OfflineInputsTable[] }>
  >,
  setError: SetError,
  setUpdatedByTab: React.Dispatch<
    React.SetStateAction<
      {
        [tabKey in OfflineInputTabs]: { by?: string; at?: string };
      }
    >
  >
): Promise<void> => {
  const testParams = {
    company,
  };

  try {
    let res = await MetricsLambdaFetch("/getMMMOfflineInputs", {
      params: testParams,
    });
    const resJSON = await awaitJSON<GetMMMOfflineInputsResponse>(res);
    const updates = Object.assign(
      {},
      ...Object.values(OfflineInputTabs).map(tab => ({
        [tab]: { by: resJSON[tab]?.updatedBy, at: resJSON[tab]?.updatedAt },
      }))
    );
    const tables = Object.assign(
      {},
      ...Object.values(OfflineInputTabs).map(tab => ({
        [tab]:
          tab === OfflineInputTabs.PAID_MEDIA_INPUTS ||
          tab === OfflineInputTabs.NON_PAID_MEDIA_INPUTS ||
          tab === OfflineInputTabs.OUTCOME_DATA
            ? resJSON[tab]?.tables
            : [],
      }))
    );

    setTablesByTab(tables);
    setUpdatedByTab(updates);
  } catch (e) {
    const reportError = e as Error;
    setError({
      message: reportError.message,
      reportError,
    });
  }
};

export const prepDeleteTableReq = (
  tableId: string,
  company: string,
  updatedBy: string,
  inputType: keyof typeof OfflineInputTabs,
  tables: OfflineInputsTable[],
  setTables: React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
  deletedTables: OfflineInputsTable[],
  setDeletedTables: React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
  queuedRequests: QueuedRequest[],
  setQueuedRequests: React.Dispatch<React.SetStateAction<QueuedRequest[]>>,
  newTableEntries: NewTableEntriesMap,
  setNewTableEntries: React.Dispatch<React.SetStateAction<NewTableEntriesMap>>,
  tableEdits: TableEditsMap,
  setTableEdits: React.Dispatch<React.SetStateAction<TableEditsMap>>
): void => {
  const foundCreateIndex = queuedRequests.findIndex(req => {
    try {
      if (req.type === "CREATE") {
        const createBody = req.body as AddMMMOfflineInputArgs;
        const createArgs = createBody.args as AddMediaInputArgs;
        return createArgs.tableId === tableId;
      }
    } catch (e) {}
    return false;
  });

  // If the table is brand new and has not been sent to the server yet, then only modify the local state
  if (foundCreateIndex >= 0) {
    queuedRequests.splice(foundCreateIndex, 1);
    setQueuedRequests([...queuedRequests]);
  }

  const tableIndex = tables.findIndex(table => table.tableId === tableId);

  // Save the deleted table to be restored after discard
  if (foundCreateIndex === -1) {
    const deletedTable = tables[tableIndex];
    deletedTables.push(deletedTable);
    setDeletedTables([...deletedTables]);
  }

  // Modify local state as if delete were completed
  tables.splice(tableIndex, 1);
  setTables([...tables]);
  delete newTableEntries[tableId];
  setNewTableEntries({ ...newTableEntries });
  delete tableEdits[tableId];
  setTableEdits({ ...tableEdits });

  if (foundCreateIndex === -1) {
    setQueuedRequests([
      ...queuedRequests,
      {
        type: "DELETE",
        body: { tableId, company, updatedBy, inputType },
      },
    ]);
  }
};

export const deleteTable = async (
  body: DeleteMMMOfflineInputArgs,
  setError: SetError
): Promise<void> => {
  try {
    let res = await MetricsLambdaFetch("/deleteMMMOfflineInput", {
      method: "POST",
      body,
    });
    await awaitJSON<MediaTable[]>(res);
  } catch (e) {
    const reportError = e as Error;
    setError({
      message: reportError.message,
      reportError,
    });
  }
};

export const prepCreateTableReq = (input: {
  company: string;
  updatedBy: string;
  inputType: keyof typeof OfflineInputTabs;
  startDate: Date;
  endDate?: Date;
  newTable: OfflineInputsTable;
  queuedRequests: QueuedRequest[];
  setQueuedRequests: React.Dispatch<React.SetStateAction<QueuedRequest[]>>;
  tables: OfflineInputsTable[];
  setTables: React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>;
  tablesInEditMode: string[];
  setTablesInEditMode: React.Dispatch<React.SetStateAction<string[]>>;
  newTableEntries: NewTableEntriesMap;
  setNewTableEntries: React.Dispatch<React.SetStateAction<NewTableEntriesMap>>;
  tableEdits: TableEditsMap;
  setTableEdits: React.Dispatch<React.SetStateAction<TableEditsMap>>;
}): void => {
  const {
    company,
    updatedBy,
    inputType,
    startDate,
    endDate,
    newTable,
    queuedRequests,
    setQueuedRequests,
    tables,
    setTables,
    tablesInEditMode,
    setTablesInEditMode,
    newTableEntries,
    setNewTableEntries,
    tableEdits,
    setTableEdits,
  } = input;

  switch (inputType) {
    case OfflineInputTabs.PAID_MEDIA_INPUTS:
    case OfflineInputTabs.NON_PAID_MEDIA_INPUTS:
      newTableEntries[newTable.tableId] = buildBlankMediaEntries(startDate);
      break;
    case OfflineInputTabs.OUTCOME_DATA:
      newTableEntries[newTable.tableId] = buildBlankOutcomeDataEntries(startDate, endDate);
  }
  setNewTableEntries({ ...newTableEntries });

  tables.unshift(newTable);
  setTables([...tables]);
  tablesInEditMode.push(newTable.tableId);
  setTablesInEditMode([...tablesInEditMode]);
  tableEdits[newTable.tableId] = {};
  setTableEdits({ ...tableEdits });

  const args: any = { ...newTable, company, updatedBy };
  delete args.entries;
  const body: AddMMMOfflineInputArgs = { inputType, args };

  setQueuedRequests([
    ...queuedRequests,
    {
      type: "CREATE",
      body,
    },
  ]);
};

export const createTable = async (
  body: AddMMMOfflineInputArgs,
  setError: SetError
): Promise<void> => {
  try {
    await MetricsLambdaFetch("/addMMMOfflineInput", {
      method: "POST",
      body,
    });
  } catch (e) {
    const reportError = e as Error;
    setError({
      message: reportError.message,
      reportError,
    });
  }
};

const validateTableEdits = (
  tableEdits: TableEditsMap,
  newTableEntries: NewTableEntriesMap
): { tableId: string; errorDates: string[] }[] => {
  let validationErrors: { tableId: string; errorDates: string[] }[] = [];
  const combinedKeys = new Set([...Object.keys(tableEdits), ...Object.keys(newTableEntries)]);

  combinedKeys.forEach(tableId => {
    const entriesEdits: EntryEditsMap = tableEdits[tableId] ?? [];
    const newEntries: MediaEntry[] = newTableEntries[tableId] ?? [];
    let validationErrorsByDate: EntryEditsMap = {};

    // Find new dates with missing data
    newEntries.forEach(newEntry => {
      const editAtDate = entriesEdits[newEntry.date];

      if (!editAtDate) {
        validationErrorsByDate[newEntry.date] = newEntry;
      }
    });

    const dates = Object.keys(validationErrorsByDate).sort();
    const consecutiveErroringDates = R.groupWith((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);

      return differenceInDays(dateA, dateB) === -1;
    }, dates);

    consecutiveErroringDates.forEach(consecutives => {
      validationErrors.push({ tableId, errorDates: consecutives });
    });
  });

  return validationErrors;
};

const updateTables = async (
  company: string,
  updatedBy: string,
  inputType: keyof typeof OfflineInputTabs,
  tableEdits: TableEditsMap,
  setError: SetError,
  onSuccess?: (updatedTables: UpdateMMMOfflineInputsResponse) => void
): Promise<void> => {
  let args: UpdateInputsArgs = {
    company,
    updatedBy,
    tables: [],
  };
  switch (inputType) {
    case OfflineInputTabs.PAID_MEDIA_INPUTS:
    case OfflineInputTabs.NON_PAID_MEDIA_INPUTS: {
      const mediaArgs: UpdateMediaInputsArgs = {
        tables: Object.keys(tableEdits).map(key => {
          return {
            tableId: key,
            entries: Object.values(tableEdits[key]).map(entry => {
              const mediaEntry = entry as MediaEntry;
              return {
                date: mediaEntry.date,
                impressions: mediaEntry.impressions ?? 0,
                otherUnit: mediaEntry.otherUnit ?? 0,
                redemptions: mediaEntry.redemptions ?? 0,
                spend: mediaEntry.spend ?? 0,
              };
            }),
          };
        }),
        company,
        updatedBy,
      };
      args = mediaArgs;
      break;
    }
    case OfflineInputTabs.OUTCOME_DATA: {
      const outcomeDataArgs: UpdateOutcomeDataArgs = {
        tables: Object.keys(tableEdits).map(key => {
          return {
            tableId: key,
            entries: Object.values(tableEdits[key]).map(entry => {
              const outcomeDataEntry = entry as OutcomeDataEntry;
              return {
                date: outcomeDataEntry.date,
                volume: outcomeDataEntry.volume ?? 0,
              };
            }),
          };
        }),
        company,
        updatedBy,
      };
      args = outcomeDataArgs;
      break;
    }
    default:
      break;
  }

  const lambdaArgs: UpdateMMMOfflineInputsArgs = {
    inputType,
    args,
  };

  try {
    if (args.tables.length > 0) {
      const result = await MiscLambdaFetch("/kickOffLambda", {
        method: "POST",
        body: {
          fileType: "txt",
          lambdaArgs,
          lambdaName: "metrics-updateMMMOfflineInputs",
        },
      });
      const uuid = await awaitJSON(result);
      const content = await pollS3({
        autoDownload: false,
        bucket: "bpm-cache",
        filename: `${uuid}.txt`,
        mimeType: "text/plain",
      });
      const textContent = await content.text();
      const lambdaResult = JSON.parse(textContent) as UpdateMMMOfflineInputsResponse;

      if (lambdaResult.cometErrorMessage) {
        setError({ message: lambdaResult.cometErrorMessage });
      } else if (onSuccess) {
        onSuccess(lambdaResult);
      }
    } else if (onSuccess) {
      onSuccess({});
    }
  } catch (e) {
    const reportError = e as Error;
    setError({
      message: reportError.message,
      reportError,
    });
  }
};

export const validateAndUpdateTables = async (
  company: string,
  updatedBy: string,
  inputType: keyof typeof OfflineInputTabs,
  tableEdits: TableEditsMap,
  newTableEntries: NewTableEntriesMap,
  setValidationErrors: React.Dispatch<
    React.SetStateAction<{ tableId: string; errorDates: string[] }[]>
  >,
  setError: SetError,
  onSuccess: (updatedTables: UpdateMMMOfflineInputsResponse) => void,
  queuedRequests: QueuedRequest[],
  setQueuedRequests: React.Dispatch<React.SetStateAction<QueuedRequest[]>>
): Promise<void> => {
  const invalidDates = validateTableEdits(tableEdits, newTableEntries);

  if (invalidDates.length > 0) {
    setValidationErrors(invalidDates);
  } else {
    for (const req of queuedRequests) {
      switch (req.type) {
        case "DELETE":
          await deleteTable(req.body as DeleteMMMOfflineInputArgs, setError);
          break;
        case "CREATE":
          await createTable(req.body as AddMMMOfflineInputArgs, setError);
          break;
      }
    }

    setQueuedRequests([]);

    await updateTables(company, updatedBy, inputType, tableEdits, setError, onSuccess);
  }
};
