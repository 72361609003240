import { DataType } from './Enums';

export class DataTypeConverter {
  private static isSpecial(valueAsString: string): boolean {
    // Prior to ShowDataTablePresModel, special values come to us as
    // '%null%', '%all%', '%wildcard%', '%missing%'...
    // While this function will never return true for ShowDataTablePresModel conversion,
    // it is as cheap as passing around a version and checking that.
    // (See DataValueFormatter.cpp)

    if (valueAsString.length > 2 && valueAsString[0] === '%' && valueAsString[valueAsString.length - 1] === '%') {
      return true;
    }
    return false;
  }

  private static specialValueIndicator = '%null%';

  // tslint:disable-next-line:no-any
  private static parseBoolean(booleanString: string): boolean | string {
    // boolean values come to us as 'true'|'false'
    const normalizedBoolean: string = booleanString.toLowerCase();
    return normalizedBoolean !== 'true' && normalizedBoolean !== 'false' ? this.specialValueIndicator : normalizedBoolean === 'true';
  }

  // tslint:disable-next-line:no-any
  private static parseNumber(valueAsString: string): number | string {
    const value = Number(valueAsString);
    return isNaN(value) ? this.specialValueIndicator : value;
  }

  private static parseDate(dateAsString: string): Date | null {
    // Note that without a time, the date is assumed to be UTC
    // Input of 2020-03-25 is printed as: Mar 24 2020 17:00:00 GMT-0700
    // This matches style used in existing convertFilterValuePresModel
    const value = new Date(dateAsString);
    return Number.isNaN(value.getTime()) ? null : value;
  }

  private static parseDateTime(dateAsString: string): Date | null {
    // With a time, the date is assumed to be UTC.
    // Our date format is always: yyyy-mm-dd hh:mm:ss
    // Unfortunately, IE11 can't parse that format so we add a T between the day and time.
    // We also add a Z so that it is UTC. (Without the Z, some browsers assume local, and others UTC)
    const formattedDateString: string = dateAsString.replace(' ', 'T').concat('Z');
    const value = new Date(formattedDateString);
    return Number.isNaN(value.getTime()) ? null : value;
  }

  // It would be nice to merge this and the method FilterConverter::convertFilterValuePresModel in api-platform-js
  public static convertValueAsStringToValue(valueAsString: string, type: DataType): boolean | number | string | undefined {
    // This is both DataDictionary and ShowDataTablePresModel compatible.
    // In the DataDictionary, valueAsString can be '%null%' or '%missing%', while in
    // ShowDataTablePresModel, we can get 'null' or 'missing', or '%null%' for string special values.
    // For either of these cases, we return specialValueIndicator (or the original special string).
    // To maintain backwards compatibilty, this converter:
    // 1. returns a string for any special values
    // 2. returns a string for any date

    // Parameter min/max values can be undefined, so result is also undefined
    if (valueAsString === undefined || valueAsString === null) {
      return undefined;
    }

    if (this.isSpecial(valueAsString)) {
      return valueAsString;
    }

    switch (type) {
      case DataType.Bool:
        return this.parseBoolean(valueAsString);

      case DataType.Int:
      case DataType.Float:
        // Return special value '%null%' for any failure in parsing.
        // We need to do that because ShowDataTablePresModel will give us strings like 'null'
        // for special values.
        return this.parseNumber(valueAsString);

      case DataType.Date:
        // For ShowDataTablePresModel, special values will be "null" which will fail to parse.
        // In that case, we return '%null%'.
        return this.parseDate(valueAsString) === null ? this.specialValueIndicator : valueAsString;

      case DataType.DateTime:
        // For ShowDataTablePresModel, special values will be "null" which will fail to parse.
        // In that case, we return '%null%'
        return this.parseDateTime(valueAsString) === null ? this.specialValueIndicator : valueAsString;

      case DataType.Spatial:
      case DataType.String:
      default:
        return valueAsString;
    }
  }

  // tslint:disable-next-line:no-any
  public static convertValueToNativeValue(value: any, type: DataType): boolean | number | Date | string | null {
    // This converts from our 1.2 API that returns strings for specials, and dates as strings.
    // It takes the special case of '%null%' and returns a null.
    // For dates, it returns a Date object rather than a string.
    // convertValueToNativeValue should be used to build a DataValue.nativeValue field.

    // Parameter min/max can be undefined. If that is the case, nativeValue is treated like a special
    if (value === undefined || value === null) {
      return null;
    }

    switch (type) {
      case DataType.Bool:
        return typeof value === 'string' ? null : value;

      case DataType.Int:
      case DataType.Float:
        return typeof value === 'string' ? null : value;

      case DataType.Date:
        // Convert the string to Date object (or null if parsing fails ... we assume special)
        return this.parseDate(value);

      case DataType.DateTime:
        // Convert the string to Date object (or null if parsing fails ... we assume special)
        return this.parseDateTime(value);

      case DataType.String:
        return this.isSpecial(value) ? null : value;

      case DataType.Spatial:
      default:
        return value;
    }
  }

  // tslint:disable-next-line:no-any
  public static convertStringValueToNativeValue(value: string, type: DataType): boolean | number | Date | string | null {
    // This handles the conversion from string directly to native value for all types
    return this.convertValueToNativeValue(this.convertValueAsStringToValue(value, type), type);
  }
}
