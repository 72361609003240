import { PulseLayout } from './Enums';
import { WebComponentLocalSettings } from './WebComponentInterfaces';

/**
 * The names of the string constants you can use as HTML attributes for the <tableau-pulse> web component.
 * @ignore
 */
export enum PulseAttributes {
  DisableExploreFilter = 'disable-explore-filter',
  Layout = 'layout',
}

export interface PulseSettings extends WebComponentLocalSettings {
  /**
   * Indicates whether the explore filter is hidden or visible.
   * ```
   * <tableau-pulse id="tableauPulse" disable-explore-filter>
   * ```
   */
  disableExploreFilter?: boolean;

  /**
   * Specifies the desired custom layout of the Pulse metric.
   * ```
   * <tableau-pulse id="tableauPulse" layout="card">
   * ```
   */
  layout?: PulseLayout;

  /**
   * The token used for authorization
   *
   * ```
   * <tableau-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-authoring-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-pulse id="tableauPulse" token="some-token-containing-clientId" />
   * ```
   */
  token?: string;

  /**
   * The value of the 'loading' attribute of the embedded iframe.
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#loading
   *
   * ```
   * <tableau-viz id="tableauViz" iframe-attr-loading="lazy" />
   * <tableau-authoring-viz id="tableauViz" iframe-attr-loading="lazy" />
   * <tableau-pulse id="tableauPulse" iframe-attr-loading="lazy" />
   * ```
   */
  iframeAttributeLoading?: string;

  /**
   * The value of the 'style' attribute of the embedded iframe.
   *
   * ```
   * <tableau-viz id="tableauViz" iframe-attr-style="border: 1px solid red" />
   * <tableau-authoring-viz id="tableauViz" iframe-attr-style="border: 1px solid red" />
   * <tableau-pulse id="tableauPulse" iframe-attr-style="border: 1px solid red" />
   * ```
   */
  iframeAttributeStyle?: string;

  /**
   * The value of the 'class' attribute of the embedded iframe providing access to any
   * custom selectors defined in the <iframe-style> child tag.
   *
   * ```
   * <tableau-pulse id="tableauViz" iframe-attr-class="red-border">
   *   <iframe-style>
   *     .red-border {
   *       border: 1px solid red;
   *     }
   *   </iframe-style>
   * </tableau-pulse>
   * ```
   */
  iframeAttributeClass?: string;
}

/**
 * The names of the string constants you can use as child tags for TableauPulse custom element.
 */
export enum PulseChildElements {
  /**
   * Custom styles applicable to the embedded iframe element itself (not inside it).
   */
  IframeStyle = 'iframe-style',
}

/**
 * The interface for the top-level Pulse object.
 **/
export interface Pulse extends PulseSettings {}
