import "./KpiVolume.scss";
import { KpiData } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import { useState, useMemo, useCallback, useContext } from "react";
import { CrossChannelContext } from "../CrossChannel";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { MdArrowForwardIos } from "react-icons/md";
import AutoSizer from "react-virtualized-auto-sizer";
import { XAxis, YAxis, Tooltip, CartesianGrid, Area, Line, ComposedChart } from "recharts";
import {
  Dropdown,
  DownloadDropdown,
  DropdownToggleType,
  GraphTableToggleButton,
  BPMTable,
  Header,
} from "../../Components";
import ChartContainer from "../../Components/ChartContainer";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
  DATE_GROUPING_OPTIONS,
  DateGroupingKey,
  INACTIVE_COLOR,
} from "../../TVADCrossChannel/homePageConstants";
import { abbreviateNumber } from "../../utils/data";
import { Brand30, Brand50 } from "../../utils/colors";
import {
  formatDateLabel,
  getGroupingValue,
  metricFormatter,
  PERCENTAGE_FORMATTER,
  VOLUME_FORMATTER,
} from "../../TVADCrossChannel/homePageUtils";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import { StateSetter } from "../../utils/types";
import ChartTooltipV2 from "../../Components/Charts/ChartTooltip/ChartTooltipV2";
import { Mixpanel, MxE } from "../../utils/mixpanelWrapper";

const X_AXIS_DATE_FORMAT = "M/dd/yy";

enum TrafficSource {
  Paid = "Paid",
  Organic = "Organic",
}

const LABELS = [TrafficSource.Paid, TrafficSource.Organic];
const AREA_COLORS = { [TrafficSource.Paid]: Brand50, [TrafficSource.Organic]: Brand30 };

interface TotalsByDate {
  [date: string]: {
    Paid: number;
    Organic: number;
  };
}

interface DataItem {
  date: string;
  Paid: number;
  Organic: number;
}

enum KpiView {
  VOLUME = "volume",
  CONVERSION_RATE = "conversionRate",
}

const METRIC_OPTIONS = [
  { label: "KPI Volume", value: KpiView.VOLUME },
  { label: "KPI Conversion Rate", value: KpiView.CONVERSION_RATE },
] as const;

interface KpiVolumeProps {
  data: KpiData[];
  showMarketingInputs: boolean;
  setShowMarketingInputs: StateSetter<boolean>;
  defaultFunnel?: string;
  footer?: string | React.ReactNode;
}

const KpiVolume: React.FC<KpiVolumeProps> = ({
  data,
  showMarketingInputs,
  setShowMarketingInputs,
  footer,
}) => {
  const { kpi, dates, crossChannelKPIs } = useContext(CrossChannelContext);

  const [dateGrouping, setDateGrouping] = useState(DATE_GROUPING_OPTIONS[0].value);
  const [viewMode, setViewMode] = useState<KpiView>(KpiView.VOLUME);
  const [graphView, setGraphView] = useState(true);
  const [focusedKpi, setFocusedKpi] = useState("");

  const [comparisonKpi, setComparisonKpi] = useState(crossChannelKPIs[0] || ""); // For when viewing conversion rates

  // Total counts by KPI
  const totalByKpi = useMemo(() => {
    let totalByKpi: Record<string, number> = {};
    for (let item of data) {
      const { kpi, count } = item;
      totalByKpi[kpi] = R.pathOr(0, [kpi], totalByKpi) + count;
    }
    return totalByKpi;
  }, [data]);

  // Dropdown options for comparison KPI
  const kpiComparisonOptions = useMemo(
    () =>
      crossChannelKPIs
        // Filter out KPIs with no counts
        .filter(kpi => totalByKpi[kpi] || totalByKpi[kpi] > 0)
        .map(kpi => ({
          label: kpi,
          value: kpi,
        })),
    [crossChannelKPIs, totalByKpi]
  );

  const kpiLegendHeader = useMemo(() => {
    if (viewMode === KpiView.CONVERSION_RATE) {
      let leftKpiLabel = "";
      let rightKpiLabel = "";

      if ((totalByKpi[kpi] || 0) > (totalByKpi[comparisonKpi] || 0)) {
        leftKpiLabel = kpi;
        rightKpiLabel = comparisonKpi;
      } else {
        leftKpiLabel = comparisonKpi;
        rightKpiLabel = kpi;
      }

      return `${leftKpiLabel} \u2192 ${rightKpiLabel}`;
    } else {
      return kpi;
    }
  }, [comparisonKpi, kpi, totalByKpi, viewMode]);

  const rows = useMemo(
    () =>
      getDataByDateGrouping({
        data,
        selectedKpi: kpi,
        comparisonKpi,
        dateGrouping,
        viewMode,
        focusedKpi,
      }),
    [data, kpi, comparisonKpi, dateGrouping, viewMode, focusedKpi]
  );

  const fileNameIdentifiers = useMemo(() => {
    const date =
      DATE_GROUPING_OPTIONS.find(item => item.value === dateGrouping)?.label || dateGrouping;
    const prettyNameMetric =
      METRIC_OPTIONS.find(item => item.value === viewMode)?.label || viewMode;
    return ["CrossChannel", date, prettyNameMetric, kpi || "", `${dates.start}–${dates.end}`];
  }, [dateGrouping, viewMode, kpi, dates]);

  const excelDownload = useCallback(() => {
    exportToExcel(data, `${fileNameIdentifiers.join("_")}`);
  }, [data, fileNameIdentifiers]);

  const pngDownload = useCallback(async () => {
    const label = `KPI: ${kpi}`;
    await downloadPNG(".kpiVolumeContainer", fileNameIdentifiers, label, [
      ".cl-download",
      ".icon-toggle-button",
      ".showInputsToggle",
    ]);
  }, [kpi, fileNameIdentifiers]);

  const headers = useMemo(() => {
    let headers: Header[] = [
      {
        label: "Date",
        name: "date",
        width: 60,
        nonInteractive: true,
        renderer: (item: DataItem) => formatDateLabel(item.date, "", true) || "-",
      },
    ];

    LABELS.forEach(kpi => {
      headers.push({
        label: kpi,
        name: kpi,
        flex: 1,
        renderer: (item: DataItem) =>
          metricFormatter(viewMode === KpiView.VOLUME ? "volume" : "percentage")(item[kpi] || 0),
      });
    });

    return headers;
  }, [viewMode]);

  const totals = useMemo(() => {
    let totals = {
      date: "",
      [TrafficSource.Paid]: 0,
      [TrafficSource.Organic]: 0,
    };

    LABELS.forEach(kpi => {
      let value;
      if (viewMode === KpiView.VOLUME) {
        value = R.sum(rows.map(row => row[kpi])) || 0;
      } else if (viewMode === KpiView.CONVERSION_RATE) {
        value = R.mean(rows.map(row => row[kpi])) || 0;
      }
      totals[kpi] = value;
    });

    return totals;
  }, [rows, viewMode]);

  const totalsRenderer = useCallback(
    ({ data, style = {}, classes = [] as string[] }) => {
      return (
        <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
          {data ? metricFormatter(viewMode === KpiView.VOLUME ? "volume" : "percentage")(data) : ""}
        </div>
      );
    },
    [viewMode]
  );

  const handleKpiChange = useCallback(
    kpi => setFocusedKpi(current => (current === kpi ? "" : kpi)),
    []
  );

  return (
    <div className="kpiVolumeContainer">
      <ChartContainer
        enableHoverDesign
        title={
          <>
            <Dropdown
              type={DropdownToggleType.WIDGET_TITLE}
              value={dateGrouping}
              options={DATE_GROUPING_OPTIONS}
              onChange={option => {
                Mixpanel.track(MxE.DATE_GROUPING_CHANGE, {
                  widget: "kpi_volume",
                  option_selected: option,
                });
                setDateGrouping(option as DateGroupingKey);
              }}
            />
            <Dropdown
              type={DropdownToggleType.WIDGET_TITLE}
              value={viewMode}
              options={METRIC_OPTIONS}
              onChange={viewMode => {
                Mixpanel.track(MxE.METRIC_OPTION_CHANGE, {
                  widget: "kpi_volume",
                  option_selected: viewMode,
                });
                setViewMode(viewMode);
              }}
            />
          </>
        }
        rightActions={
          <>
            {viewMode === KpiView.CONVERSION_RATE && (
              <Dropdown
                size="sm"
                type={DropdownToggleType.FILLED}
                design="secondary"
                label="CVR To/From"
                value={comparisonKpi}
                options={kpiComparisonOptions}
                onChange={option => setComparisonKpi(option)}
              />
            )}
            <GraphTableToggleButton
              graphView={graphView}
              setGraphView={setGraphView}
              widgetName="kpi_volume"
            />
            <DownloadDropdown
              size="sm"
              onClickOptions={[excelDownload, pngDownload]}
              disabledMenuOptions={
                graphView ? { XLSX: false, PNG: false } : { XLSX: false, PNG: true }
              }
              disabled={!data}
            />
          </>
        }
        footerContent={footer}
      >
        {graphView ? (
          <div className="kpiVolumeGraphContents">
            {R.isEmpty(rows) ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "26px",
                }}
              >
                No data for the selected KPI
              </div>
            ) : (
              <div id="kpiVolumeGraph" className="kpiVolumeGraph">
                <AutoSizer>
                  {({ width, height }) => (
                    <ComposedChart
                      width={width}
                      height={height}
                      data={rows}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                      <CartesianGrid
                        vertical={false}
                        stroke={CARTESIAN_GRID_STROKE}
                        strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                      />
                      {LABELS.map((kpi, i) => {
                        if (viewMode === KpiView.CONVERSION_RATE) {
                          return (
                            <Line
                              strokeWidth={3}
                              dataKey={kpi}
                              dot={false}
                              isAnimationActive={false}
                              key={kpi}
                              stroke={AREA_COLORS[kpi]}
                              type="monotone"
                            />
                          );
                        } else {
                          return (
                            <Area
                              type="monotone"
                              dataKey={kpi}
                              stackId="1"
                              key={i}
                              activeDot={false}
                              fillOpacity={1}
                              strokeWidth={0}
                              fill={AREA_COLORS[kpi]}
                              isAnimationActive={false}
                              stroke={AREA_COLORS[kpi]}
                            />
                          );
                        }
                      })}
                      <XAxis
                        dataKey="date"
                        tick={TICK_STYLE}
                        tickFormatter={date => {
                          const parsed = Dfns.parseISO(date);
                          const formatted = Dfns.format(X_AXIS_DATE_FORMAT, parsed);
                          return formatted;
                        }}
                        interval="equidistantPreserveStart"
                      />
                      <YAxis
                        tickLine={false}
                        tick={TICK_STYLE}
                        axisLine={false}
                        tickFormatter={number =>
                          viewMode === KpiView.VOLUME
                            ? `${abbreviateNumber(number)}`
                            : `${Math.round(number * 100).toFixed(0)}%`
                        }
                        domain={["auto", "auto"]}
                      />
                      <Tooltip
                        content={({ label, payload }) => {
                          const valueFormatter =
                            viewMode === KpiView.VOLUME ? VOLUME_FORMATTER : PERCENTAGE_FORMATTER;
                          const formattedHeaderLabel = formatDateLabel(label, dateGrouping, true);
                          let items = R.sortBy(
                            item => -Math.abs(parseInt(item.value)),
                            payload?.map(item => {
                              let { name, value, stroke } = item;
                              name = R.defaultTo("", name);
                              value = R.defaultTo(0, value as number);
                              stroke = R.defaultTo("", stroke);
                              return {
                                label: name,
                                value: valueFormatter(value),
                                color: stroke,
                              };
                            }) || []
                          );

                          const totalsFunction = viewMode === KpiView.VOLUME ? R.sum : R.mean;
                          const totals = valueFormatter(
                            totalsFunction(payload?.map(item => item.value as number) || [])
                          );

                          const footerLabel = viewMode === KpiView.VOLUME ? "Total:" : "Avg:";

                          return (
                            <ChartTooltipV2
                              headers={[formattedHeaderLabel]}
                              items={items}
                              footerLabels={focusedKpi === "" ? [footerLabel] : undefined}
                              footerValues={focusedKpi === "" ? [totals] : undefined}
                              itemKeyShape="square"
                            />
                          );
                        }}
                        isAnimationActive={false}
                      />
                    </ComposedChart>
                  )}
                </AutoSizer>
              </div>
            )}
            <div className="rightOfChart">
              <div className="kpiVolumeLegend">
                <div className="kpiLegendHeader">{kpiLegendHeader}</div>
                {LABELS.map((kpi, i) => {
                  const resolvedColor =
                    focusedKpi === ""
                      ? AREA_COLORS[kpi]
                      : focusedKpi === kpi
                      ? AREA_COLORS[kpi]
                      : INACTIVE_COLOR;
                  return (
                    <div
                      className="legendItem"
                      key={kpi}
                      onClick={() => {
                        handleKpiChange(kpi);
                      }}
                    >
                      <div
                        className="square"
                        style={{
                          backgroundColor: resolvedColor,
                        }}
                      />
                      <div
                        className={`label ${focusedKpi && focusedKpi !== kpi ? "unselected" : ""}`}
                      >
                        {kpi}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="showInputsToggle"
                onClick={() => setShowMarketingInputs(curr => !curr)}
              >
                <span className="text">{showMarketingInputs ? "Hide" : "Show"} inputs</span>
                <span>
                  <MdArrowForwardIos
                    className={`dropdownArrow ${showMarketingInputs ? "up" : "down"}`}
                  />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <BPMTable
            headers={headers}
            data={rows}
            totals={totals}
            totalsRenderer={totalsRenderer}
            filterBar={false}
            headerHeight={30}
            bottomRowHeight={30}
          />
        )}
      </ChartContainer>
    </div>
  );
};

interface GetDataByDateGroupingParams {
  data: KpiData[];
  selectedKpi: string;
  comparisonKpi: string;
  dateGrouping: string;
  viewMode: KpiView;
  focusedKpi: string;
}

const getDataByDateGrouping = ({
  data,
  selectedKpi,
  comparisonKpi,
  dateGrouping,
  viewMode,
  focusedKpi,
}: GetDataByDateGroupingParams): DataItem[] => {
  if (viewMode === KpiView.VOLUME) {
    let totalsByDate: TotalsByDate = {};

    for (let item of data) {
      const { date, kpi, count, isOrganic } = item;

      if (selectedKpi !== kpi) {
        continue;
      }

      if (
        (focusedKpi === TrafficSource.Organic && !isOrganic) ||
        (focusedKpi === TrafficSource.Paid && isOrganic)
      ) {
        continue;
      }

      const dateToUse = getGroupingValue(date, dateGrouping as DateGroupingKey);

      const label = isOrganic ? TrafficSource.Organic : TrafficSource.Paid;

      totalsByDate = R.mergeDeepRight(totalsByDate, {
        [dateToUse]: {
          [label]: R.pathOr(0, [dateToUse, label], totalsByDate) + count,
        },
      });
    }

    const rows = Object.entries(totalsByDate)
      .map(([date, totals]) => ({ date, ...totals }))
      .sort((a, b) => a.date.localeCompare(b.date));

    return rows;
  } else {
    let totalsByDate: TotalsByDate = {};
    for (let item of data) {
      const { date, kpi, count, isOrganic } = item;

      if (
        (focusedKpi === TrafficSource.Organic && !isOrganic) ||
        (focusedKpi === TrafficSource.Paid && isOrganic)
      ) {
        continue;
      }

      const dateToUse = getGroupingValue(date, dateGrouping as DateGroupingKey);

      const label = isOrganic ? TrafficSource.Organic : TrafficSource.Paid;

      totalsByDate = R.mergeDeepRight(totalsByDate, {
        [dateToUse]: {
          [kpi]: {
            [label]: R.pathOr(0, [dateToUse, label], totalsByDate) + count,
          },
        },
      });
    }

    const rows = Object.entries(totalsByDate)
      .map(([date, kpiTotals]) => {
        let conversionRates: { [TrafficSource.Paid]: number; [TrafficSource.Organic]: number } = {
          [TrafficSource.Paid]: 0,
          [TrafficSource.Organic]: 0,
        };

        const paidNumerator = R.pathOr(0, [comparisonKpi, TrafficSource.Paid], kpiTotals);
        const paidDenominator = R.pathOr(0, [selectedKpi, TrafficSource.Paid], kpiTotals);

        const organicNumerator = R.pathOr(0, [comparisonKpi, TrafficSource.Organic], kpiTotals);
        const organicDenominator = R.pathOr(0, [selectedKpi, TrafficSource.Organic], kpiTotals);

        conversionRates.Paid = paidNumerator / paidDenominator;
        conversionRates.Organic = organicNumerator / organicDenominator;

        return { date, ...conversionRates };
      })
      .sort((a, b) => a.date.localeCompare(b.date));

    return rows;
  }
};

export default KpiVolume;
