import "../OptimizationsUtils/Optimizations.scss";
import { awaitJSON, pollS3, StreamingOptimizationsLambdaFetch } from "../utils/fetch-utils";
import { Button, Tooltip, Modal } from "react-bootstrap";
import { CreativeMap, MediaType, useCreativeMap } from "../redux/creative";
import {
  MdDateRange,
  MdOutlineBugReport,
  MdOutlineFormatListNumbered,
  MdSave,
} from "react-icons/md";
import { BreadcrumbTitle, Page, Spinner, OverlayTrigger } from "../Components";
import { navigate, RouteComponentProps, Router } from "@reach/router";
import { RedirectOnDefault } from "../utils/hooks/useNav";
import { typedReactMemo } from "../utils/types";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as uuid from "uuid";
import ConstraintViewNew from "./ConstraintView";
import moment from "moment";
import OptimizationHome from "./OptimizationHome";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import RunView from "./RunView";
import useLocation from "../utils/hooks/useLocation";

export interface StreamingPlacementAndDerivedNetwork {
  placement: string;
  placement_code: string;
  derived_id: string;
  campaign_id: number;
  media: string;
  format: string;
  publisher: string;
}

export interface Optimization {
  branch: string;
  budget: string;
  build_number: string;
  company_id: string; //kpi
  constraint_name: string;
  cost: string;
  date: string;
  end: string;
  log_url: string;
  objective_value: number;
  progress: number;
  start: string;
  status: string;
}

export interface RunResult {
  Cost: string;
  "Number of Impressions": string;
  "Placement Code": string;
  "Placement ID": string;
}

export interface RunOptimization {
  runId: string;
  optimization: Optimization;
}

interface Constraint {
  creative: string;
  creatives: string[];
  id: number;
  impressions: number;
  isError?: string;
  length: number;
  minPct: number;
  minValue: number;
  network: string;
  spend: number;
  type: string;
}

export interface GlobalConstraints {
  minExtrapolation: number;
  networkMaxSpendPct: number;
  unseenMaxSpendPct: number;
  maxExtrapolationRatio: number;
}

export interface Constraints {
  avoid: Constraint[];
  budget: Constraint[];
  creativesAllowed: string[];
  end: string;
  global: GlobalConstraints;
  lastmodified: string;
  lastuser: string;
  maximum: Constraint[];
  networkCreativesDisallowed: any[];
  start: string;
  total: number;
  version: number;
}

export interface ConstraintData {
  company: string;
  constraints: Constraints;
  id: number;
  lastmodified: string;
  lastuser: string;
  name: string;
  optimization_type_id: number;
  optimizationType: string;
  streamingType: string;
  start: string;
  end: string;
}
export interface ConstraintListElement {
  lastmodified: string;
  lastuser: string;
  name: string;
  optimizationType: string;
  start: string;
  end: string;
}

export interface Network {
  name: string;
}

export interface StreamingOptimizationsContextType {
  baseURL?: string;
  company?: string;
  constraintData?: ConstraintData;
  constraintsList?: ConstraintListElement[];
  copyConstraint?: any;
  creativeMap?: CreativeMap;
  deleteConstraint?: any;
  downloadMediaPlan?: any;
  newConstraint?: any;
  noSpacesCreativeMap?: Record<string, string>;
  optimizations?: Optimization[];
  origin?: string;
  refreshingOptimizations?: boolean;
  runId?: string;
  runOptimization?: any;
  isciLiveOnDate?: any;
  runResult?: RunResult[];
  RUNS_BUYABLE_KEY?: string;
  RUNS_CONSTRAINTS_KEY?: string;
  RUNS_SUMMARY_KEY?: string;
  placements?: StreamingPlacementAndDerivedNetwork[];
  selectedView?: string;
  setConstraintData?: any;
  setDirtyConstraint?: any;
  setInvalidConstraint?: any;
  streamingType?: string;
}

const PRETTY_DATE_FORMAT = "yyyy-MM-dd hh:mma";
const RUNS_SUMMARY_KEY = "RUNS_SUMMARY_KEY";
const RUNS_BUYABLE_KEY = "RUNS_BUYABLE_KEY";
const RUNS_CONSTRAINTS_KEY = "RUNS_CONSTRAINTS_KEY";

export const StreamingOptimizationsContext = React.createContext<StreamingOptimizationsContextType>(
  { RUNS_BUYABLE_KEY, RUNS_CONSTRAINTS_KEY, RUNS_SUMMARY_KEY }
);

export const ROUTES = {
  CONSTRAINTS: {
    key: "constraints",
    label: "Constraints",
    uri: "constraints",
    depth: 1,
  },
  OPTIMIZATIONS: {
    key: "optimizations",
    uri: "",
  },
  RUNS: {
    key: "runs",
    label: "Runs",
    uri: "runs",
    depth: 1,
  },
};

const OPTIMIZATION_TYPE_MAP = {
  1: "buying",
  2: "traffic",
};

export default typedReactMemo<React.FC<RouteComponentProps>>(({ location }) => {
  const setError = useSetError();
  const { company } = useLocation();
  const streamingType = location?.pathname.split("/")[2] || "streaming";
  const streamingTitle = `${
    streamingType.charAt(0).toUpperCase() + streamingType.slice(1)
  } Optimizations`;
  const [optimizations, setOptimizations] = useState<Optimization[]>();
  const [singleRunOptimization, setSingleRunOptimization] = useState<RunOptimization>();
  const [constraintsList, setConstraintsList] = useState<ConstraintListElement[]>();
  const [constraintData, setConstraintData] = useState<ConstraintData>();
  const [runResult, setRunResult] = useState<RunResult[]>();
  const [runId, setRunId] = useState<string>();
  const [refreshingOptimizations, setRefreshingOptimizations] = useState(true);
  const [selectedView, setSelectedView] = useState(RUNS_SUMMARY_KEY);
  const [showLogsModal, setShowLogsModal] = useState(false);

  const baseURL = `${company}/${streamingType}/optimizations`;
  const origin = location ? location.origin : "";
  const path: string[] = useMemo(() => {
    const pathname = location && location.pathname ? location.pathname.split("/") : [];
    return R.filter(elem => !R.isNil(elem) && elem.length > 0, pathname);
  }, [location]);
  const currentPathNode = useMemo(() => {
    let node =
      path.length > 1 && ROUTES[path[path.length - 2].toUpperCase()]
        ? ROUTES[path[path.length - 2].toUpperCase()]
        : ROUTES.OPTIMIZATIONS;
    if (R.prop("key", node) !== ROUTES.OPTIMIZATIONS.key) {
      node.id = path[path.length - 1];
      node.resolvedPath = `${path[path.length - 2]}/${path[path.length - 1]}`;
    }
    return node;
  }, [path]);
  const isConstraintsPage = useMemo(
    () => R.prop("key", currentPathNode) === ROUTES.CONSTRAINTS.key,
    [currentPathNode]
  );
  const isRunsPage = useMemo(() => R.prop("key", currentPathNode) === ROUTES.RUNS.key, [
    currentPathNode,
  ]);

  const { creativeMap, isciLiveOnDate } = useCreativeMap({
    company,
    mediaTypes: [streamingType as MediaType],
  });

  const noSpacesCreativeMap = useMemo(() => {
    if (creativeMap) {
      return R.fromPairs(
        R.map(e => [`ad${e.modelEntry};${e.name.replace(/ /g, "")}`, e.name], R.values(creativeMap))
      );
    }
  }, [creativeMap]);

  const listUntilJobsFinish = useCallback(async () => {
    const optimizationsResponse = await StreamingOptimizationsLambdaFetch("/optimizations", {
      params: { company, streamingType },
    });
    const optimizationsResult = await awaitJSON<Optimization[]>(optimizationsResponse);
    setOptimizations(optimizationsResult);

    const stillRunning = R.filter(
      row => row.status.indexOf("SUCC") < 0 && row.status.indexOf("FAIL") < 0,
      optimizationsResult
    ).length;

    if (stillRunning > 0) {
      setTimeout(async () => {
        await listUntilJobsFinish();
      }, 30000);
    } else {
      setRefreshingOptimizations(false);
    }
  }, [company, streamingType, setOptimizations]);

  const getSingleRunOptimization = useCallback(async () => {
    if (runId) {
      try {
        const optimizationsResponse = await StreamingOptimizationsLambdaFetch("/optimizations", {
          params: {
            company,
            runId,
            singleRunMetadata: true,
            streamingType,
          },
        });
        const optimizationsResult = await awaitJSON(optimizationsResponse);
        if (optimizationsResult && !R.isEmpty(optimizationsResult) && runId) {
          setSingleRunOptimization({
            runId,
            optimization: optimizationsResult[0],
          });
        }
      } catch (e) {
        const reportError = e as Error;
        setError({ message: `Error fetching optimization ${runId}`, reportError });
      }
    }
  }, [runId, company, streamingType, setError]);

  useEffect(() => {
    if (company) {
      (async () => {
        try {
          const [constraintsResponse] = await Promise.all([
            StreamingOptimizationsLambdaFetch("/list_constraints", {
              params: { company, streamingType },
            }),
            listUntilJobsFinish(),
          ]);
          const constraintsResult = await awaitJSON(constraintsResponse);
          setConstraintsList(constraintsResult);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to fetch optimizations for company "${company}". ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [company, streamingType, setError, listUntilJobsFinish]);

  useEffect(() => {
    if (company && isConstraintsPage) {
      (async () => {
        const constraintId = currentPathNode.id;
        if (constraintId) {
          try {
            const constraintsResponse = await StreamingOptimizationsLambdaFetch("/constraints", {
              params: { company, name: constraintId, streamingType },
            });
            const constraintsResult = await awaitJSON(constraintsResponse);
            constraintsResult.constraints.budget = constraintsResult.constraints.budget.map(
              (elem, index) => ({ ...elem, index: index })
            );
            constraintsResult.constraints.avoid = constraintsResult.constraints.avoid.map(
              (elem, index) => ({ ...elem, index: index })
            );
            constraintsResult.constraints.creativesAllowed = constraintsResult.constraints.creativesAllowed.map(
              (elem, index) => ({
                ...elem,
                index: index,
              })
            );
            constraintsResult.constraints.networkCreativesDisallowed = constraintsResult.constraints.networkCreativesDisallowed.map(
              (elem, index) => ({
                ...elem,
                index: index,
              })
            );
            constraintsResult.streamingType = streamingType;

            const validatedConstraintsResponse = await StreamingOptimizationsLambdaFetch(
              "/validate_constraints",
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: constraintsResult,
              }
            );
            const validatedConstraints = await awaitJSON<Constraints>(validatedConstraintsResponse);
            setConstraintData({
              ...constraintsResult,
              constraints: validatedConstraints,
              optimizationType:
                OPTIMIZATION_TYPE_MAP[constraintsResult.optimization_type_id] ?? "buying",
            });
          } catch (e) {
            const reportError = e as Error;
            setError({
              message: `Failed to fetch constraint ${constraintId} for company "${company}". ${reportError.message}`,
              reportError,
            });
          }
        } else {
          navigate(`${origin}/${baseURL}`);
        }
      })();
    }
  }, [company, currentPathNode, streamingType, setError, isConstraintsPage, origin, baseURL]);

  useEffect(() => {
    if (company && isRunsPage) {
      (async () => {
        const runId = currentPathNode.id;
        if (runId) {
          try {
            const finalPivotDataResponse = await StreamingOptimizationsLambdaFetch(
              "/optimizations",
              {
                params: { company, runId, type: "csv", streamingType },
              }
            );
            let finalPivot = await awaitJSON(finalPivotDataResponse);
            setRunId(runId);
            setRunResult(finalPivot);
          } catch (e) {
            let reportError = e as Error;
            setError({
              message: `Failed to load data for ${runId}. Error: ${reportError.message}`,
              // This is a frequently failing lambda because competitors often have missing data. For now, just not
              // reporting the failure
              reportError,
            });
          }
        } else {
          navigate(`${origin}/${baseURL}`);
        }
      })();
    }
  }, [company, currentPathNode, streamingType, setError, isRunsPage, origin, baseURL]);

  const downloadMediaPlan = useCallback(async () => {
    let runId = currentPathNode.id;

    const xlsxData = await StreamingOptimizationsLambdaFetch("/optimizations", {
      params: { company, runId, type: "xlsx", streamingType },
    });
    const xlsxFilename = await awaitJSON(xlsxData);

    await pollS3({
      bucket: "bpm-output",
      mimeType: "application/vnd.ms-excel",
      filename: xlsxFilename.filename,
      overloadFilename: `MediaPlan ${company} ${runId}.xlsx`,
    });
  }, [company, streamingType, currentPathNode]);

  const lastMod = useMemo(
    () =>
      constraintData ? (
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={
            <Tooltip id={uuid.v4()}>
              {Dfns.format(PRETTY_DATE_FORMAT)(Dfns.parseISO(constraintData.lastmodified))}
            </Tooltip>
          }
        >
          <span className="lastModified">
            {`Last modified ${
              constraintData.lastuser ? `by ${constraintData.lastuser.split("@")[0]}` : ""
            } ${moment(constraintData.lastmodified).fromNow()}`}
          </span>
        </OverlayTrigger>
      ) : (
        ""
      ),
    [constraintData]
  );

  const [savingConstraint, setSavingConstraint] = useState(false);
  const [dirtyConstraint, setDirtyConstraint] = useState(false);
  const [invalidConstraint, setInvalidConstraint] = useState(false);
  const [placements, setPlacements] = useState<StreamingPlacementAndDerivedNetwork[]>([]);
  const [placementsDownloaded, setPlacementsDownloaded] = useState(false);
  useEffect(() => {
    if (company && R.isEmpty(placements) && !placementsDownloaded) {
      (async () => {
        try {
          const getPlacementsAndDerivedNetworksResponse = await StreamingOptimizationsLambdaFetch(
            "/getPlacementsAndDerivedNetworks",
            {
              params: { company, mediaType: streamingType },
            }
          );
          const placements: StreamingPlacementAndDerivedNetwork[] = await awaitJSON(
            getPlacementsAndDerivedNetworksResponse
          );
          setPlacements(placements);
          setPlacementsDownloaded(true);
        } catch (e) {
          let reportError = e as Error;
          setError({
            message: `Failed to recieve placement data for ${company}. Error: ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [company, placements, streamingType, placementsDownloaded, setError]);

  const saveConstraint = useCallback(async () => {
    setSavingConstraint(true);
    try {
      if (constraintData) {
        constraintData.streamingType = streamingType;
        const validatedConstraintsResponse = await StreamingOptimizationsLambdaFetch(
          "/validate_constraints",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: constraintData,
          }
        );
        const validatedConstraints = await awaitJSON<Constraints>(validatedConstraintsResponse);
        const newConstraintData = {
          ...constraintData,
          constraints: validatedConstraints,
          optimizationType: OPTIMIZATION_TYPE_MAP[constraintData.optimization_type_id] ?? "buying",
        };
        const savedConstraintResponse = await StreamingOptimizationsLambdaFetch("/constraints", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: newConstraintData,
        });
        const savedConstraints = await awaitJSON<ConstraintData>(savedConstraintResponse);
        setConstraintData({
          ...savedConstraints,
          optimizationType:
            OPTIMIZATION_TYPE_MAP[savedConstraints.optimization_type_id] ?? "buying",
        });
        setDirtyConstraint(false);
      }
    } catch (e) {
      let reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
    setSavingConstraint(false);
  }, [constraintData, streamingType, setError]);

  const copyConstraint = useCallback(
    async ({ from, to: name, optimizationType, start, end }) => {
      const constraintsResponse = await StreamingOptimizationsLambdaFetch("/constraints", {
        params: { company, name: from, streamingType },
      });
      const constraintsResult = await awaitJSON(constraintsResponse);
      // Add an optimization type if it has been set
      const body = {
        ...constraintsResult,
        name,
        start,
        end,
        optimizationType,
        streamingType,
      };
      await StreamingOptimizationsLambdaFetch("/constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const constraintsListResponse = await StreamingOptimizationsLambdaFetch("/list_constraints", {
        params: { company, streamingType },
      });
      const constraintsListResult = await awaitJSON(constraintsListResponse);
      setConstraintsList(constraintsListResult);
    },
    [company, streamingType]
  );

  const newConstraint = useCallback(
    async ({ to, optimizationType, start, end }) => {
      const body: Omit<ConstraintData, "id"> = {
        company,
        // Basic constraints included in `sample` optimizations
        constraints: {
          avoid: [],
          budget: [],
          creativesAllowed: [],
          end: "",
          global: {
            minExtrapolation: 6,
            unseenMaxSpendPct: 0.25,
            networkMaxSpendPct: 0.2,
            maxExtrapolationRatio: 1.2,
          },
          lastmodified: new Date(Date.now()).toISOString(),
          lastuser: "",
          maximum: [],
          networkCreativesDisallowed: [],
          start,
          total: 100000,
          version: 4,
        },
        end,
        lastmodified: new Date(Date.now()).toISOString(),
        lastuser: "",
        name: to,
        optimization_type_id: 0,
        optimizationType,
        streamingType,
        start,
      };

      await StreamingOptimizationsLambdaFetch("/constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      const constraintsListResponse = await StreamingOptimizationsLambdaFetch("/list_constraints", {
        params: { company, streamingType },
      });
      const constraintsListResult = await awaitJSON(constraintsListResponse);
      setConstraintsList(constraintsListResult);
    },
    [company, streamingType]
  );

  const deleteConstraint = useCallback(
    async ({ deleteConstraintId }) => {
      await StreamingOptimizationsLambdaFetch("/delete_constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: { company, name: deleteConstraintId, streamingType },
      });

      const constraintsListResponse = await StreamingOptimizationsLambdaFetch("/list_constraints", {
        params: { company, streamingType },
      });
      const constraintsListResult = await awaitJSON(constraintsListResponse);
      setConstraintsList(constraintsListResult);
    },
    [company, streamingType]
  );

  const runOptimization = useCallback(
    async ({ name, branch, optimizationType, start, end }) => {
      await StreamingOptimizationsLambdaFetch("/run_batch_optimizer", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: { name, branch, company, optimizationType, start, end, streamingType },
      });

      setRefreshingOptimizations(true);
      setTimeout(async () => {
        await listUntilJobsFinish();
      }, 5000);
    },
    [company, streamingType, listUntilJobsFinish]
  );

  let actions = useMemo(() => {
    if (isConstraintsPage && constraintData) {
      return (
        <div className={`streamingConstraintActions${savingConstraint ? " saving" : ""}`}>
          <div className="nameLabel">
            <span style={{ color: "#7e57c2" }}>
              {constraintData.optimizationType === "traffic" ? (
                <span
                  style={{
                    border: "solid 1px #7e57c2",
                    borderRadius: "5px",
                    padding: "3px 6px",
                    margin: "0 10px 2px 0",
                    fontSize: "1rem",
                  }}
                >
                  <MdDateRange style={{ margin: "0 4px 2px 0" }} />
                  {constraintData.start}
                  {constraintData.end}
                </span>
              ) : (
                false
              )}
              {constraintData.optimizationType[0].toUpperCase() +
                constraintData.optimizationType.slice(1)}{" "}
              optimization:{" "}
            </span>
            <span>{constraintData.name}</span>
          </div>
          <div>{lastMod}</div>
          <Button
            variant="primary"
            onClick={saveConstraint}
            disabled={!dirtyConstraint || invalidConstraint}
            className="saveButton"
          >
            {savingConstraint ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      );
    } else if (isRunsPage) {
      return (
        <Button
          variant="outline-primary"
          onClick={() => setShowLogsModal(true)}
          className="saveButton"
        >
          Show AWS Logs
        </Button>
      );
    }
  }, [
    isConstraintsPage,
    constraintData,
    lastMod,
    saveConstraint,
    dirtyConstraint,
    savingConstraint,
    invalidConstraint,
    isRunsPage,
  ]);

  const navs = useMemo(() => {
    if (!isRunsPage) {
      return;
    }

    let navs = [
      {
        key: RUNS_SUMMARY_KEY,
        label: "Summary",
      },
      {
        key: RUNS_CONSTRAINTS_KEY,
        label: "Constraint Debugger",
      },
    ];

    return navs;
  }, [isRunsPage]);

  const navRenderer = label => {
    if (label === "Summary") {
      return (
        <span className="navItem">
          <MdOutlineFormatListNumbered style={{ height: "20px", width: "20px" }} /> {label}
        </span>
      );
    } else if (label === "Constraint Debugger") {
      return (
        <span className="navItem">
          <MdOutlineBugReport style={{ height: "20px", width: "20px" }} /> {label}
        </span>
      );
    } else {
      return label;
    }
  };

  const LogsModal = useCallback(
    ({ show, setShowLogsModal }) => {
      if (!show) {
        return null;
      }

      // check if exists and not stale
      const isFetchingSingleRunOptimization = !(
        singleRunOptimization?.optimization && singleRunOptimization?.runId === runId
      );

      let singleRunMetadata;
      if (isFetchingSingleRunOptimization) {
        // display spinner and make API request
        getSingleRunOptimization();
      } else if (singleRunOptimization) {
        singleRunMetadata = singleRunOptimization.optimization;
      }
      return (
        <Modal size="lg" keyboard={true} show={Boolean(show)} onHide={() => setShowLogsModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Logs for build {runId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isFetchingSingleRunOptimization || R.isNil(singleRunMetadata) ? (
              <Spinner size={50} />
            ) : singleRunMetadata.log_url.indexOf("bpm-output") >= 0 ? (
              <>
                <a
                  href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnRlx-sns-prod-us-west-2;filter=${singleRunMetadata.company_id}_${singleRunMetadata.build_number}_${streamingType};start=${singleRunMetadata.start};end=${singleRunMetadata.end}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>IterationConstraints</h2>
                </a>
                <a
                  href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-python-optimizer-prod-OptimizeIteration;filter=${singleRunMetadata.company_id}_${singleRunMetadata.build_number}_${streamingType};start=${singleRunMetadata.start};end=${singleRunMetadata.end}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>PyIPOPT</h2>
                </a>
                <a
                  href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnPvt-sns-prod-us-west-2;filter=${singleRunMetadata.company_id}_${singleRunMetadata.build_number}_${streamingType};start=${singleRunMetadata.start};end=${singleRunMetadata.end}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>IterationPivots</h2>
                </a>
              </>
            ) : (
              <a href={singleRunMetadata.log_url} target="_blank" rel="noopener noreferrer">
                <h2>External Link</h2>
              </a>
            )}
          </Modal.Body>
        </Modal>
      );
    },
    [getSingleRunOptimization, runId, singleRunOptimization, streamingType]
  );

  return (
    <StreamingOptimizationsContext.Provider
      value={{
        baseURL,
        company,
        constraintData,
        constraintsList,
        copyConstraint,
        creativeMap,
        deleteConstraint,
        downloadMediaPlan,
        isciLiveOnDate,
        newConstraint,
        noSpacesCreativeMap,
        optimizations,
        origin,
        placements,
        refreshingOptimizations,
        runId,
        runOptimization,
        runResult,
        RUNS_BUYABLE_KEY,
        RUNS_CONSTRAINTS_KEY,
        RUNS_SUMMARY_KEY,
        selectedView,
        setConstraintData,
        setDirtyConstraint,
        setInvalidConstraint,
        streamingType,
      }}
    >
      <Page
        title={
          <BreadcrumbTitle
            /// @ts-ignore
            path={[currentPathNode]}
            title={streamingTitle}
            navigate={() => {
              navigate(`${origin}/${baseURL}`);
            }}
          />
        }
        pageType={streamingTitle}
        navs={navs}
        navRenderer={navRenderer}
        selectedNav={selectedView}
        onNav={setSelectedView}
        minHeight={"600"}
        actions={actions}
      >
        <div className="optimizationPage">
          <Router>
            <>
              <RunView
                /// @ts-ignore
                path={`${ROUTES.RUNS.uri}/:optimizationId`}
              />
              <ConstraintViewNew
                /// @ts-ignore
                path={`${ROUTES.CONSTRAINTS.uri}/:constraintId`}
              />
            </>
            <OptimizationHome
              /// @ts-ignore
              path="/"
            />
            <RedirectOnDefault
              /// @ts-ignore
              default
              rootNavigate={navigate}
            />
          </Router>
          <LogsModal show={showLogsModal} setShowLogsModal={setShowLogsModal} />
        </div>
      </Page>
    </StreamingOptimizationsContext.Provider>
  );
});
