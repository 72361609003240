import { GraphTableToggleButton } from "../Components";
import ChartContainer from "../Components/ChartContainer";
import { useMemo, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
} from "../Components/Charts/ChartConstants";
import { Brand50, Neutral600 } from "../utils/colors";
import "./SpendVsChart.scss";
import { abbreviateNumber, formatMoney, formatNumber } from "../utils/format-utils";
import ChartTooltipV2 from "../Components/Charts/ChartTooltip/ChartTooltipV2";

interface SpendVsChartProps {
  variableName: string;
  data: { val: number; spend: number }[];
  suggestedBudget?: number;
}

export const SpendVsChart: React.FC<SpendVsChartProps> = ({
  variableName,
  data,
  suggestedBudget,
}) => {
  const [graphView, setGraphView] = useState<boolean>(true);

  const maxVal = useMemo(() => Math.max(...data.map(val => val.val)), [data]);
  const sortedData = useMemo(() => data.sort((a, b) => a.spend - b.spend), [data]);
  const suggestedBudgetYVal = useMemo(
    () => data.filter(point => point.spend === suggestedBudget)[0].val,
    [suggestedBudget, data]
  );

  return (
    <div className="youTubeSpendVsChart">
      <ChartContainer
        enableHoverDesign
        title={`Spend vs ${variableName}`}
        height={240}
        rightActions={
          <GraphTableToggleButton
            graphView={graphView}
            setGraphView={setGraphView}
            widgetName={`youtube_plan_spend_vs_${variableName}`}
          />
        }
      >
        {graphView ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={sortedData}
              margin={{
                top: 0,
                right: 48,
                left: -20,
                bottom: -12,
              }}
            >
              <CartesianGrid
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                vertical={false}
              />
              <XAxis
                dataKey="spend"
                tick={TICK_STYLE}
                tickFormatter={val => `$${abbreviateNumber(val)}`}
                scale="linear"
              />
              <YAxis
                tick={TICK_STYLE}
                tickLine={false}
                tickFormatter={val => abbreviateNumber(val)}
                axisLine={false}
                width={75}
                type="number"
                domain={[0, maxVal]}
              />
              <ReferenceLine
                x={suggestedBudget}
                label={{
                  position: "insideTopLeft",
                  value: "Budget",
                }}
                stroke={Neutral600}
              />
              <ReferenceDot
                x={suggestedBudget}
                y={suggestedBudgetYVal}
                r={6}
                fill={Brand50}
                stroke="none"
              />

              <Tooltip
                content={({ payload }) => {
                  const data = payload?.[0]?.payload || {};
                  const headerLabel = `Spend: ${formatMoney(data.spend)}`;
                  const tooltipItems = [
                    {
                      label: `${variableName}`,
                      value: formatNumber(parseInt(data.val)),

                      color: Brand50,
                    },
                  ];

                  return <ChartTooltipV2 headers={[headerLabel]} items={tooltipItems} />;
                }}
              />
              <Line
                type="monotone"
                dataKey="val"
                dot={false}
                isAnimationActive={false}
                stroke={Brand50}
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div></div>
          // <BPMTable
          //   headers={headers}
          //   data={rows}
          //   totals={totals}
          //   filterBar={false}
          //   headerHeight={30}
          //   bottomRowHeight={30}
          // />
        )}
      </ChartContainer>
    </div>
  );
};

export default SpendVsChart;
