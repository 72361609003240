import "./ChartTooltipV2.scss";
import React from "react";

interface PayloadItem {
  label: string | number | undefined;
  value: string | number | undefined;
  color: string | undefined;
  styles?: Record<string, any>;
}

type KeyShape = "circle" | "square" | "line" | "dottedLine";

type StringArrayOfMaxLength2 = readonly [string?, string?];
type StringOrNumberArrayOfMaxLength2 = readonly [(string | number)?, (string | number)?];

interface ChartTooltipV2Props {
  headers: StringArrayOfMaxLength2;
  footerLabels?: StringArrayOfMaxLength2;
  footerValues?: StringOrNumberArrayOfMaxLength2;
  items: PayloadItem[];
  itemKeyShape?: KeyShape;
  comparisonItems?: PayloadItem[];
  comparisonItemKeyShape?: KeyShape;
}
export const ChartTooltipV2: React.FC<ChartTooltipV2Props> = ({
  headers,
  footerLabels = ["Total:", "Total:"],
  footerValues = [],
  items,
  comparisonItems,
  itemKeyShape = "line",
  comparisonItemKeyShape = "dottedLine",
}) => {
  const [primaryHeader, comparisonHeader] = headers;
  const [primaryFooterLabel, comparisonFooterLabel] = footerLabels;
  const [primaryFooterValue, comparisonFooterValue] = footerValues;

  const hasComparisonPeriod = Boolean(comparisonHeader && comparisonItems);

  return (
    <div className="chartTooltipV2">
      <div className={`tooltipContainer ${hasComparisonPeriod ? "hasComparisonPeriod" : ""}`}>
        <div className="tooltipHeader">
          {hasComparisonPeriod && <div className="headerSuperLabel">Primary Period</div>}
          {primaryHeader}
        </div>
        <div className="itemList">
          {items.map((item, i) => {
            const { label, value, color, styles } = item;
            if (!value) {
              return <></>;
            }
            return (
              <div
                className={`itemRow ${hasComparisonPeriod ? "hasComparisonPeriod" : ""}`}
                key={i}
              >
                <div className="labelContainer">
                  <ColorKey shape={itemKeyShape} color={color} />
                  <div className="label" style={{ ...styles }}>{`${label}: `}</div>
                </div>
                <div className={`value ${hasComparisonPeriod ? "hasComparisonPeriod" : ""}`}>
                  {value}
                </div>
              </div>
            );
          })}
        </div>
        {primaryFooterValue && (
          <div className="footer">
            <div className="footerLabel">{primaryFooterLabel}</div>
            <div className="footerValue">{primaryFooterValue}</div>
          </div>
        )}
      </div>

      {hasComparisonPeriod && (
        <div className="tooltipContainer">
          <div className="tooltipHeader">
            <div className="headerSuperLabel">Comparison Period</div>
            {comparisonHeader}
          </div>
          <div className="itemList">
            {(comparisonItems || []).map((item, i) => {
              const { label, value, color, styles } = item;
              if (!value) {
                return <></>;
              }
              return (
                <div
                  className={`itemRow ${hasComparisonPeriod ? "hasComparisonPeriod" : ""}`}
                  key={i}
                >
                  <div className="labelContainer">
                    <ColorKey shape={comparisonItemKeyShape} color={color} />
                    <div className="label" style={{ ...styles }}>{`${label}: `}</div>
                  </div>
                  <div className="value hasComparisonPeriod">{value}</div>
                </div>
              );
            })}
          </div>
          {comparisonFooterValue && (
            <div className="footer">
              <div className="footerLabel">{comparisonFooterLabel}</div>
              <div className="footerValue">{comparisonFooterValue}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface ColorKeyProps {
  shape: "circle" | "square" | "line" | "dottedLine";
  color: string | undefined;
}

const ColorKey: React.FC<ColorKeyProps> = React.memo(({ shape, color }) => {
  return (
    <>
      {shape === "dottedLine" ? (
        <hr className={`${shape}`} style={{ borderTop: `dotted 3px ${color}` }} />
      ) : (
        <div className={`${shape}`} style={{ backgroundColor: color }} />
      )}
    </>
  );
});

export default ChartTooltipV2;
