import {
  BrandImpactData,
  BusinessImpactData,
  GroupByOptionsBrandEquityMetric,
  RevenueImpactTakeaways,
} from "@blisspointmedia/bpm-types/dist/BrandEquity";

import Papa from "papaparse";
import { S3SignedUrlFetch } from "../utils/fetch-utils";
import { DateGroupingOption } from "../TVADCrossChannel/homePageConstants";
import * as Dfns from "date-fns/fp";

export const createUrlForFetch = (
  branch: string,
  company: string,
  modelRunDate: string,
  s3Path: string,
  fileName: string
): string => {
  const url =
    branch === "main"
      ? `tinuiti-mmm-results/brand_equity/${branch}/${company}/${s3Path}/${modelRunDate}/${fileName}`
      : `tinuiti-mmm-results/brand_equity/${branch}/${company}/${modelRunDate}/${s3Path}/${fileName}`;
  return url;
};

export const fetchAndParseData = async (
  url: string,
  transformFunction: (data: any) => any,
  metricValue: string
): Promise<any> => {
  try {
    const response = await S3SignedUrlFetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch data from URL: ${url} - Status: ${response.status}`);
    }
    const text = await response.text();
    const { data: parsedData } = Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
    });
    return transformFunction(parsedData);
  } catch (error) {
    console.error(`Error processing ${metricValue}:, ${error}`);
    throw new Error(
      `Unable to process data for ${metricValue}. Check if the URL ${url} is accessible.`
    );
  }
};

export const removeDashPFromEndOfMetric = (metric: string): string => {
  const cleanedMetric = metric.endsWith("_p")
    ? metric
        .slice(0, -2)
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : metric
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  return cleanedMetric;
};

export const hasClientFacingOptions = (
  data: Record<string, GroupByOptionsBrandEquityMetric[]>
): boolean => {
  return Object.values(data).some(array => array.some(item => item.client_facing === true));
};

export const hasClientFacingItem = (data: GroupByOptionsBrandEquityMetric[]): boolean => {
  return Array.isArray(data) && data.some(item => item.client_facing === true);
};

export const getMostRecentDateAndKPI = (
  data: Record<
    string,
    { value: string; client_facing: boolean; branch: string; stage_one: boolean }[]
  >
): { date: string; kpi: string } => {
  let defaultDate = "latest";
  let { value } = data.latest[0];

  if (data.hasOwnProperty("latest") && data.latest.some(item => item.client_facing === true)) {
    defaultDate = "latest";
    value = data.latest.find(item => item.client_facing === true)?.value || "";
  } else {
    const dates = Object.keys(data).filter(key => !isNaN(Date.parse(key)));
    const dateList = dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    for (let date of dateList) {
      const clientFacingKPI = data[date].find(item => item.client_facing === true);
      if (clientFacingKPI) {
        defaultDate = date;
        ({ value } = clientFacingKPI);
        break;
      }
    }
  }

  return { date: defaultDate, kpi: value };
};

export const returnModelRunDates = (
  modelOptions: Record<string, GroupByOptionsBrandEquityMetric[]>
): any => {
  const modelOptionsFlat = Object.entries(modelOptions)
    .map(([dateKey, items], index) => {
      const formattedDate =
        dateKey === "latest"
          ? "latest"
          : new Date(dateKey.slice(0, 10)).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            });
      const clientFacing = hasClientFacingItem(items as GroupByOptionsBrandEquityMetric[]);
      const options = Array.from(
        new Map(
          (items as {
            label: string;
            value: string;
            s3Path: string;
            client_facing: boolean;
            branch: string;
            stage_one: boolean;
          }[])
            .map(item => ({
              label: `${clientFacing ? "" : `[Branch: ${item.branch}] `}${formattedDate}`,
              value: dateKey,
              section: clientFacing ? "Client-Facing" : "Admin-Only",
              statusPill: index === 0 ? "Newest" : "Archived",
              className: "archiveOptions",
              clientFacing: clientFacing,
            }))
            .map(option => [option.value, option])
        ).values()
      );
      return options;
    })
    .flat();

  modelOptionsFlat.sort((a, b) => {
    if (a.value === "latest") {
      return -1;
    }
    if (b.value === "latest") {
      return 1;
    }

    const dateA = new Date(a.value);
    const dateB = new Date(b.value);

    return dateB.getTime() - dateA.getTime();
  });

  return modelOptionsFlat;
};

export const isBrandImpactDataComplete = (data: BrandImpactData): boolean => {
  return (
    data.weeklyBrandHealthTimeSeries.length > 0 &&
    Object.keys(data.modelOverview).length > 0 &&
    Object.keys(data.weeklyBrandHealthAreaChartSpend).length > 0 &&
    Object.keys(data.implicationsData).length > 0 &&
    Object.keys(data.paidMediaAreaChart).length > 0 &&
    Object.keys(data.paidMediaBarChart).length > 0 &&
    Object.keys(data.spendAndEffectBarChart).length > 0 &&
    Object.keys(data.nextBestDollarBarChart).length > 0 &&
    Object.keys(data.decayData).length > 0 &&
    Object.keys(data.saturationData).length > 0
  );
};

export const isBusinessImpactDataComplete = (data: BusinessImpactData): boolean => {
  return (
    data.weeklyBrandHealthTimeSeries.length > 0 &&
    Object.values(data.modelOverview).every(
      value => value !== null && value !== undefined && value !== ""
    ) &&
    data.weeklyRevenueTimeSeries.length > 0 &&
    data.totalRevenueDecomposition.length > 0 &&
    data.weeklyRevenueDecomposition.length > 0 &&
    data.revenueImpactTakeaways !== undefined &&
    data.weeklyIncrementalRoas.length > 0 &&
    data.incrementalRoasTakeaways !== undefined &&
    data.incrementalRoasTakeaways !== null &&
    Object.keys(data.saturationData).length > 0 &&
    Object.keys(data.decayData).length > 0
  );
};

export const formatModelOverviewOutcomeVariable = (outcomeVariable: string): string => {
  let formattedOutcomeVariable = "";
  switch (outcomeVariable) {
    case "Gqv":
      formattedOutcomeVariable = "GQV";
      break;
    case "total_revenue":
      formattedOutcomeVariable = "Revenue";
      break;
    default:
      formattedOutcomeVariable = outcomeVariable;
      break;
  }

  return formattedOutcomeVariable;
};

export const transformTakeawaysImpactRevenue = (
  incrementalRowsTakeawaysData: any[]
): RevenueImpactTakeaways => {
  const maxDate = incrementalRowsTakeawaysData.reduce((max, row) => {
    const rowDate = new Date(row.date);
    return rowDate > max ? rowDate : max;
  }, new Date(incrementalRowsTakeawaysData[0].date));

  const twelveMonthsAgo = new Date(maxDate);
  twelveMonthsAgo.setFullYear(twelveMonthsAgo.getFullYear() - 1);

  let brandEquityIncrementalRevenue = 0;
  let mediaDrivenBrandEquityIncrementalRevenue = 0;
  let ttmRevenue = 0;

  incrementalRowsTakeawaysData.forEach(row => {
    const rowDate = new Date(row.date);
    if (rowDate >= twelveMonthsAgo && rowDate <= maxDate) {
      if (row.channelGroup === "Brand Effect") {
        brandEquityIncrementalRevenue += row.value || 0;
        ttmRevenue += row.response || 0;
      } else if (row.channelGroup === "Spend Induced Brand Effect") {
        mediaDrivenBrandEquityIncrementalRevenue += row.value || 0;
      }
    }
  });

  const total = brandEquityIncrementalRevenue + mediaDrivenBrandEquityIncrementalRevenue;
  const brandEquityRevenuePercent =
    ttmRevenue !== 0 ? (brandEquityIncrementalRevenue / ttmRevenue) * 100 : 0;

  const revenueImpactTakeaways: RevenueImpactTakeaways = {
    name: "Revenue Impact Summary",
    MediaDrivenBrandEquity: mediaDrivenBrandEquityIncrementalRevenue,
    BrandEquityRevenue: brandEquityIncrementalRevenue,
    Total: total,
    BrandEquityRevenuePercent: brandEquityRevenuePercent,
  };

  return revenueImpactTakeaways;
};

const getStartOfWeek = (date: Date): Date => {
  const day = date.getUTCDay() || 7;
  if (day !== 1) {
    date.setUTCDate(date.getUTCDate() - day + 1);
  }
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
};

const getStartOfMonth = (date: Date): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
};

const getStartOfQuarter = (date: Date): Date => {
  const month = date.getUTCMonth();
  const quarterStartMonth = month - (month % 3);
  return new Date(Date.UTC(date.getUTCFullYear(), quarterStartMonth, 1));
};

const getStartOfYear = (date: Date): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
};

export const groupChartDataByDate = (
  formattedGqvLineChartData: any[],
  groupingOption: string
): any[] => {
  const groups: { [groupKey: string]: any } = {};

  formattedGqvLineChartData.forEach((item: any) => {
    const date = new Date(item.date);
    let groupKey: string;
    let groupDate: Date;

    switch (groupingOption) {
      case "Day":
        groupDate = new Date(
          Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
        );
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Week":
        groupDate = getStartOfWeek(new Date(date));
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Month":
        groupDate = getStartOfMonth(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Quarter":
        groupDate = getStartOfQuarter(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Year":
        groupDate = getStartOfYear(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      default:
        throw new Error("Invalid grouping option");
    }

    if (!groups[groupKey]) {
      groups[groupKey] = { date: groupKey };
    }

    for (const [key, value] of Object.entries(item)) {
      if (key !== "date" && typeof value === "number") {
        groups[groupKey][key] = ((groups[groupKey][key] as number) || 0) + value;
      }
    }
  });

  return Object.values(groups);
};

export const DATE_GROUPING_OPTIONS_BRAND_EQUITY: DateGroupingOption[] = [
  { value: "Week", label: "Weekly" },
  { value: "Month", label: "Monthly" },
  { value: "Quarter", label: "Quarterly" },
];

export const formatTitleDatesToStartOfDayMddy = (day: string): string => {
  return `${Dfns.format("M/dd/yy", new Date(`${day}T00:00:00`))}`;
};
