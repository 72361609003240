import { Pulse, PulseAttributes, PulseLayout, PulseSettings } from '@tableau/api-external-contract-js';
import { createPulseUrl } from '../Models/EmbeddingPulseUrl';
import { getSiteIdForPulse } from '../Models/EmbeddingUrlBuilder';
import { WebComponentManager } from '../WebComponentManager';
import { attributeToEnumKey } from './TableauVizBase';
import { TableauAuthResponse, TableauWebComponent } from './TableauWebComponent';

/**
 * Represents the entry point for the `<tableau-pulse>` custom HTML element.
 * This class is specifically focused on transferring information between the HTML and
 * the Tableau Pulse, so it should have as little logic as possible.
 */
export class TableauPulse extends TableauWebComponent implements Pulse {
  public constructor() {
    super();
  }

  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the ask-data webcomponent
    return [...super.observedAttributes, ...Object.values(PulseAttributes)];
  }

  protected async updateRenderingIfInitialized(src?: string): Promise<void> {
    if (!this._initialized) {
      return;
    }

    WebComponentManager.unregisterWebComponent(this._embeddingIdCounter);
    return this.updateRendering(src);
  }

  protected async updateRendering(src?: string): Promise<void> {
    try {
      this._initialized = true;
      if (!src) {
        console.debug(`A src needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      if (!this.token) {
        console.debug(`A token needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      const authResponse = await this.auth(getSiteIdForPulse(src));
      if (authResponse === TableauAuthResponse.Failure) {
        console.debug('Authentication failed.');
        return;
      }
      // Nothing to render if the user hasn't provided a src
      if (!this.src) {
        console.debug(`A src needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      if (!this.iframe) {
        console.debug('No iframe available to update the src.');
        return;
      }
      const customParams = this.readCustomParamsFromChildren();
      this._embeddingIdCounter = WebComponentManager.registerWebComponent(this);
      const pulseUrl = createPulseUrl(this.src, this.constructOptions(), customParams).toString();
      this.iframe.src = pulseUrl;
      this.raiseIframeSrcUpdatedNotification();
      return;
    } catch (e) {
      console.warn(e);
    }
  }

  private constructOptions(): PulseSettings {
    const options: PulseSettings = {
      token: this.token,
    };
    if (this.disableExploreFilter) {
      options.disableExploreFilter = this.disableExploreFilter;
    }
    if (this.layout && this.layout !== PulseLayout.Default) {
      // An empty value implies a default Pulse layout. Don't pass "default" value.
      options.layout = this.layout;
    }
    return options;
  }

  public get disableExploreFilter(): boolean {
    return this.hasAttribute(PulseAttributes.DisableExploreFilter);
  }

  public set disableExploreFilter(v: boolean) {
    if (v) {
      this.setAttribute(PulseAttributes.DisableExploreFilter, '');
    } else {
      this.removeAttribute(PulseAttributes.DisableExploreFilter);
    }
  }

  public get layout(): PulseLayout {
    const layoutKey = attributeToEnumKey(this.getAttribute(PulseAttributes.Layout));
    const layout = PulseLayout[layoutKey];
    if (!layout) {
      return PulseLayout.Default;
    }

    return layout;
  }

  public set layout(v: PulseLayout) {
    if (v) {
      this.setAttribute(PulseAttributes.Layout, v);
    } else {
      this.removeAttribute(PulseAttributes.Layout);
    }
  }
}
