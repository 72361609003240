import "./ModelInsights.scss";
import { useCallback, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import MoreInfoTooltip from "../../MMM/MoreInfo";
import { DownloadDropdown } from "../../Components/DownloadDropdown";
import * as R from "ramda";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import { useDynamicYAxisWidth } from "../../Components/Charts/ChartUtils";
import { colorMap as colors } from "../../utils/colors";
import { numberFormatter, capitalizeWords, currencyFormatter } from "../../MMM/MMMUtils";
import { CartesianGrid, Line, LineChart as LC, Tooltip, YAxis, XAxis } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
} from "../../Components/Charts/ChartConstants";
import LineChart from "../../Components/Charts/LineChart";
import { decayDataFormatterUpdated, saturationDataFormatter, weekFormat } from "./BrandImpactUtils";
import { formatModelOverviewOutcomeVariable } from "../BrandEquityUtils";
import { SaturationDataUnformatted } from "@blisspointmedia/bpm-types/dist/BrandEquity";

const MEDIAN_DOT_SIZE = 7;
const GRAY_COLOR = "#94a0b8";

interface ModelInsightsProps {
  company: string;
  groupByMetric: string;
  groupByLevel: string;
  saturationDataUnformatted: SaturationDataUnformatted[];
  decayDataUnformmatted: DecayDataUnformatted[];
}

interface DecayDataUnformatted {
  client: string;
  kpi: string;
  refresh_date: string;
  period: number;
  name: string;
  channel: string;
  platform: string;
  tactic: string;
  brand_nonbrand: string;
  incrementality_test: boolean;
  spend_carryover: number;
  max_lag: number;
}

const ModelInsights: React.FC<ModelInsightsProps> = ({
  company,
  groupByMetric,
  groupByLevel,
  saturationDataUnformatted,
  decayDataUnformmatted,
}) => {
  const saturationDataNew = useMemo(() => {
    if (saturationDataUnformatted.length !== 0) {
      let finalSaturationData;
      finalSaturationData = saturationDataFormatter(saturationDataUnformatted, groupByLevel);
      return finalSaturationData;
    }
    return [];
  }, [groupByLevel, saturationDataUnformatted]);

  const decayDataNew = useMemo(() => {
    if (decayDataUnformmatted.length !== 0) {
      let finalDecayData;
      finalDecayData = (decayDataFormatterUpdated(
        decayDataUnformmatted,
        "spend_carryover",
        groupByLevel
      ) as unknown) as {
        newD: any[];
        lines: any[];
      };
      return finalDecayData;
    }
    return [];
  }, [decayDataUnformmatted, groupByLevel]);

  const [activeLine, setActiveLine] = useState(null);
  const [hoveredLegend, setHoveredLegend] = useState(null);

  const { xAxisHeight, yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    xAxisHeightModifier: x => x + 5,
    yAxisWidthModifier: x => x + 3,
  });

  const colorMap = useMemo(() => {
    if (!R.isEmpty(saturationDataNew)) {
      return colors(saturationDataNew.newD);
    }
  }, [saturationDataNew]);

  const handleLegendClick = useCallback(
    line => {
      activeLine === line.name ? setActiveLine(null) : setActiveLine(line.name);
    },
    [activeLine]
  );

  const handleLegendEnter = useCallback(
    line => {
      hoveredLegend === line.name ? setHoveredLegend(null) : setHoveredLegend(line.name);
    },
    [hoveredLegend]
  );

  const CustomizedDot = props => {
    const { cx, cy, stroke, payload, saturationData } = props;
    if (
      saturationData.medians[payload.name].dataPoint !== payload.spend ||
      saturationData.medians[payload.name].median === 0
    ) {
      return null;
    }

    return (
      <svg>
        <polygon
          points={`${cx},${cy - MEDIAN_DOT_SIZE} ${cx + MEDIAN_DOT_SIZE},${cy} ${cx},${
            cy + MEDIAN_DOT_SIZE
          } ${cx - MEDIAN_DOT_SIZE},${cy}`}
          fill={stroke}
        />
      </svg>
    );
  };

  const tooltip = (
    <Tooltip
      content={({ active, label, payload }) => {
        if (active && payload && payload.length) {
          let items = R.sortBy(
            (item: any) => -Math.abs(item.value),
            payload?.map(item => ({
              label: item.name as string,
              value: item.value as number,
              color: item.stroke,
            })) || []
          );

          return (
            <div className="satChartTooltip">
              <div className="itemList">
                {items.map((item, i) => {
                  const { label, value, color } = item;
                  if (!value && value !== 0) {
                    return <></>;
                  }

                  return (
                    <div className="itemRow" key={i}>
                      <div className="itemWrapper">
                        <div></div>
                        <div style={{ display: "flex", alignItems: "center", padding: "0 12px" }}>
                          <div className="line" style={{ backgroundColor: color }} />
                          <div className="name">{`${label}: `}</div>
                        </div>
                      </div>
                      <div className="itemWrapper">
                        <div className="satTooltipHeaderItem">Median Spend:</div>
                        <div className="value">
                          {currencyFormatter.format(
                            saturationDataNew.medians[payload[0].payload.name].median,
                            0
                          )}
                        </div>
                      </div>
                      <div className="itemWrapper">
                        <div className="satTooltipHeaderItem">Spend:</div>
                        <div className="value">
                          {currencyFormatter.format(payload[0].payload.spend, 0)}
                        </div>
                      </div>
                      <div className="itemWrapper">
                        <div className="satTooltipHeaderItem">Positive Response:</div>
                        <div className="value">
                          {currencyFormatter.format(payload[0].payload.response, 1)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        return null;
      }}
      isAnimationActive={false}
    />
  );

  const excelDownloadSat = useCallback(() => {
    exportToExcel(saturationDataUnformatted, `${company}_saturation`);
  }, [saturationDataUnformatted, company]);

  const pngDownloadSat = useCallback(async () => {
    await downloadPNG(".section4 .left .chartContainer", `${company}_saturation`);
  }, [company]);

  const excelDownloadDecay = useCallback(() => {
    exportToExcel(decayDataUnformmatted, `${company}_decay`);
  }, [decayDataUnformmatted, company]);

  const pngDownloadDecay = useCallback(async () => {
    await downloadPNG(".section4 .right .chartContainer", `${company}_decay`);
  }, [company]);

  return (
    <WidgetContainer
      collapsible
      header={`Model Insights for ${formatModelOverviewOutcomeVariable(groupByMetric)}`}
      subHeader="The model estimation process yields business insights related to channel saturation and the lagged nature of consumer response."
    >
      {
        <div className="section4">
          <div className="left">
            <ChartContainer
              enableHoverDesign
              title={`Saturation Curves (${formatModelOverviewOutcomeVariable(groupByMetric)})`}
              rightActions={
                <>
                  <MoreInfoTooltip size="reg">
                    Most media tactics will exhibit some form of diminishing returns, due to
                    additional investment reaching less qualified audiences and/or additional user
                    frequency having diminished impact. The model training process estimates the
                    shape of these saturation curves on a channel by channel basis. Examining these
                    curves yields insight into the scalability of each channel and the optimality of
                    incremental investments (see above section).
                  </MoreInfoTooltip>
                  <DownloadDropdown size="sm" onClickOptions={[excelDownloadSat, pngDownloadSat]} />
                </>
              }
            >
              {!R.isEmpty(saturationDataNew) && (
                <>
                  <div className="satWrapper">
                    <div className="satChartTitle">
                      {formatModelOverviewOutcomeVariable(groupByMetric)}
                    </div>
                    <AutoSizer>
                      {({ width, height }) => (
                        <LC width={width} height={height} ref={setChartRef}>
                          <CartesianGrid
                            stroke={CARTESIAN_GRID_STROKE}
                            strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                            vertical={false}
                          />
                          <XAxis
                            dataKey="spend"
                            tick={TICK_STYLE}
                            height={xAxisHeight}
                            tickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                            type="number"
                          />
                          <YAxis
                            dataKey="response"
                            tick={TICK_STYLE}
                            width={yAxisWidth}
                            tickFormatter={val => val.toFixed(3)}
                          />
                          {activeLine && tooltip}

                          {saturationDataNew.newD.map(line => {
                            const isActiveLine =
                              activeLine === null ? true : activeLine === line.name;
                            const isHoveredLegend =
                              hoveredLegend === null ? true : hoveredLegend === line.name;
                            const activeColor = isActiveLine ? colorMap[line.name] : GRAY_COLOR;
                            const hoverColor = isHoveredLegend ? colorMap[line.name] : GRAY_COLOR;

                            if (!isActiveLine && activeLine !== null) {
                              return null;
                            }
                            return (
                              <Line
                                data={line.data}
                                dataKey="response"
                                dot={<CustomizedDot saturationData={saturationDataNew} />}
                                isAnimationActive={false}
                                key={line.name}
                                name={line.name}
                                stroke={activeLine ? activeColor : hoverColor}
                                strokeWidth={3}
                              />
                            );
                          })}
                        </LC>
                      )}
                    </AutoSizer>
                  </div>
                  <div className="rightOfChart">
                    <div className="legend">
                      {saturationDataNew.newD.map(item => {
                        const isActiveLine = activeLine === null ? true : activeLine === item.name;
                        const isHoveredLegend =
                          hoveredLegend === null ? true : hoveredLegend === item.name;
                        const activeColor = isActiveLine ? colorMap[item.name] : GRAY_COLOR;
                        const hoverColor = isHoveredLegend ? colorMap[item.name] : GRAY_COLOR;
                        return (
                          <div
                            className="legendItem"
                            key={item.name || item.dataKey}
                            onClick={() => handleLegendClick(item)}
                            onMouseEnter={() => handleLegendEnter(item)}
                            onMouseLeave={() => setHoveredLegend(null)}
                          >
                            <div
                              className="line"
                              style={{
                                backgroundColor: `${activeLine ? activeColor : hoverColor}`,
                              }}
                            />
                            <div className="legendValue">
                              <div
                                className={`${isActiveLine ? "activeLegend" : "inactiveLegend"}`}
                              >
                                {item.name.replace(", ", " - ")}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="medianLegendLabel">
                      <svg width="15" height="15" viewBox="0 0 15 15">
                        <polygon points="7.5,0 15,7.5 7.5,15 0,7.5" fill="#1F003F" />
                      </svg>
                      <div className="medianText">
                        Median <br /> spend
                      </div>
                    </div>
                    <div className="satChartXAxisTitle">Spend</div>
                  </div>
                </>
              )}
            </ChartContainer>
          </div>
          <div className="right">
            <ChartContainer
              enableHoverDesign
              rightActions={
                <>
                  <MoreInfoTooltip size="reg">
                    Few consumers, upon being exposed to an advertisement, drop everything and
                    respond immediately; there is almost always some lag between exposure and
                    response. The model training process estimates the extent of this response lag
                    on a channel by channel basis using a technique known as Adstock. Typically
                    (though not always), we see upper-funnel tactics having longer response lags and
                    lower-funnel tactics converting more quickly. Examining these curves can help us
                    understand channel properties and set appropriate expectations for channel
                    investments.
                  </MoreInfoTooltip>
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadDecay, pngDownloadDecay]}
                  />
                </>
              }
              title={`Effect Decays (${formatModelOverviewOutcomeVariable(groupByMetric)})`}
            >
              <div className="decayWrapper">
                <div className="decayChartTitle">Effect Carryover</div>
                {Object.keys(decayDataNew).length && (
                  <LineChart
                    customTooltipHeader={val => `Week ${weekFormat(val)} Effect Carryover`}
                    data={decayDataNew?.newD}
                    lineDataKeys={decayDataNew?.lines}
                    tooltipLabelFormatter={val => capitalizeWords(val)}
                    tooltipFormatter={val => (val ? `${numberFormatter.format(val, 0)}%` : val)}
                    tooltipShape="line"
                    usePercentageYAxis={true}
                    xAxisDataKey="Index"
                    xAxisTickFormatter={val => `Week ${weekFormat(val)}`}
                    usePrimaryColors={false}
                  />
                )}
              </div>
            </ChartContainer>
          </div>
        </div>
      }
    </WidgetContainer>
  );
};

export default ModelInsights;
