import React from "react";
import { Form } from "react-bootstrap";
import cn from "classnames";

interface QuestionCardTextAreaProps {
  questionNumber: string;
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
  isAdditionalInfo: boolean;
  label?: string;
}

const QuestionCardTextArea: React.FC<QuestionCardTextAreaProps> = ({
  questionNumber,
  value,
  onChange,
  readOnly = false,
  placeholder = "What else should we know?",
  isAdditionalInfo,
  label,
}) => {
  label = label ? label : isAdditionalInfo ? "Additional Info:" : "";
  placeholder = placeholder ? placeholder : "What else should we know?";
  return (
    <Form.Group className={cn("questionCardTextArea", { additionalInfo: isAdditionalInfo })}>
      <Form.Label htmlFor={`${questionNumber}-textarea`}>{label}</Form.Label>
      <Form.Control
        id={`${questionNumber}-textarea`}
        className="textField"
        value={value}
        onChange={e => onChange(e.target.value)}
        as="textarea"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );
};

export default QuestionCardTextArea;
