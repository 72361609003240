import React, { useCallback, useContext, useState, useMemo } from "react";
import * as R from "ramda";
import { MdArrowBackIosNew, MdArrowForwardIos, MdDoubleArrow } from "react-icons/md";
import Select from "react-select";
import { KpiMappingsContext } from "./KpiMapping";
import {
  CombinedCrossChannelKpis,
  HandleKpiEditInput,
  HandleLagEditInput,
  HandleNewRowInput,
  makeEditKey,
} from "./EditKpiMapping";
import {
  KpiEdits,
  SourceMapping,
  AccountInfo,
  KpiMappings,
  Status,
} from "@blisspointmedia/bpm-types/dist/KpiMapping";
import {
  Button,
  ButtonType,
  Dropdown,
  DropdownToggleType,
  OverlayTrigger,
  Tooltip,
} from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { formatNumberAsInt } from "../utils/format-utils";

interface SelectOnChangeParams {
  originalValues: { label: string; value: string }[];
  newValues: { label: string; value: string }[];
  source: string;
  crossChannelKpiId: string | number;
  accountId: string;
  editsKey: string;
  isNewRow?: boolean;
}

interface ApplyKpisToAllAccountsParams {
  valuesToUse: { label: string; value: string }[];
  crossChannelKpiId: string | number;
  crossChannelKpiLabel: string;
  accountIdToCopyFrom: string;
  isNewRow: boolean | undefined;
}

const LAG_OPTIONS = {
  tvad: [
    { label: "1d", value: "1" },
    { label: "3d", value: "3" },
    { label: "7d", value: "7" },
    { label: "14d", value: "14" },
    { label: "30d", value: "30" },
  ],
  reddit: [
    { label: "1d", value: "1" },
    { label: "7d", value: "7" },
    { label: "30d", value: "30" },
  ],
  criteo: [
    { label: "1d", value: "1" },
    { label: "7d", value: "7" },
    { label: "30d", value: "30" },
  ],
};

/**
 * Get the mappings that are currently saved for an account.
 */
const getOriginalValues = ({
  accountId,
  source,
  kpiMappingsKey,
  combinedKpiMappings,
}: {
  accountId: string;
  source: string;
  kpiMappingsKey: string | number;
  combinedKpiMappings: KpiMappings;
}) => {
  const sourceMappings = R.pathOr(
    [] as SourceMapping[],
    [kpiMappingsKey, "mappings", source],
    combinedKpiMappings
  );

  const mappingsForAccount = R.filter(R.propEq("accountId", accountId), sourceMappings);

  return mappingsForAccount.map(mapping => ({ label: mapping.kpi, value: mapping.kpi }));
};

/**
 * Get the existing state of the mappings for an account, with any pending edits applied.
 */
const getValuesToUse = ({
  accountId,
  editsKey,
  editsMap,
  source,
  kpiMappingsKey,
  combinedKpiMappings,
}: {
  accountId: string;
  editsKey: string;
  editsMap: KpiEdits;
  source: string;
  kpiMappingsKey: string | number;
  combinedKpiMappings: KpiMappings;
}) => {
  const originalValues = getOriginalValues({
    accountId,
    source,
    kpiMappingsKey,
    combinedKpiMappings,
  });

  if (R.has(editsKey, editsMap)) {
    const addedValues = R.pathOr([], [editsKey], editsMap)
      .filter((edit: SourceMapping) => edit.status === Status.INSERT)
      .map((edit: SourceMapping) => ({
        label: edit.kpi,
        value: edit.kpi,
      }));

    const deletedValues = R.pathOr([], [editsKey], editsMap)
      .filter((edit: SourceMapping) => edit.status === Status.DELETE)
      .reduce((prev, edit: SourceMapping) => ({ ...prev, [edit.kpi]: true }), {});

    // Filter out deleted values from the original values, and then add the added values
    return [...originalValues.filter(row => !deletedValues[row.value]), ...addedValues];
  } else {
    return originalValues;
  }
};

interface SourceColumnProps {
  combinedKpiMappings: KpiMappings;
  source: string;
  crossChannelKpis: CombinedCrossChannelKpis;
  editsMap: KpiEdits;
  newRows: KpiMappings;
  accounts: AccountInfo[];
  lagEdits: Record<string, string>;
  handleKpiEdit: (input: HandleKpiEditInput) => void;
  handleNewRow: (input: HandleNewRowInput) => void;
  handleLagEdit: (input: HandleLagEditInput) => void;
}
const SourceColumn: React.FC<SourceColumnProps> = React.memo(
  ({
    combinedKpiMappings,
    source,
    crossChannelKpis,
    editsMap,
    newRows,
    accounts,
    lagEdits,
    handleKpiEdit,
    handleNewRow,
    handleLagEdit,
  }) => {
    const {
      sourceIdMap,
      kpiOptions,
      editMode,
      sourceToPrettyName,
      rawSourceKpiCounts,
      lags,
    } = useContext(KpiMappingsContext);

    const [collapsed, setCollapsed] = useState(false);

    const selectOnChange = useCallback(
      ({
        originalValues,
        newValues,
        source,
        crossChannelKpiId,
        accountId,
        editsKey,
        isNewRow,
      }: SelectOnChangeParams) => {
        const oldVals = originalValues.map(val => val.value);
        const newVals = newValues.map(val => val.value);

        // Determine added and removed values
        const added = newVals.filter(val => !oldVals.includes(val));
        const removed = oldVals.filter(val => !newVals.includes(val));

        // Filter out conflicts where items are both added and removed
        const finalAdded = added.filter(kpi => !removed.includes(kpi));
        const finalRemoved = removed.filter(kpi => !added.includes(kpi));

        const addedMappings: SourceMapping[] = finalAdded.map(kpi => ({
          kpi,
          crossChannelKpiId,
          source,
          sourceId: sourceIdMap[source],
          accountId,
          status: Status.INSERT,
        }));

        const removedMappings: SourceMapping[] = finalRemoved.map(kpi => ({
          kpi,
          crossChannelKpiId,
          source,
          sourceId: sourceIdMap[source],
          accountId,
          status: Status.DELETE,
        }));

        const mappings = [...addedMappings, ...removedMappings];

        if (isNewRow) {
          handleNewRow({
            crossChannelKpiId: crossChannelKpiId as string,
            kpiLabel: "",
            sourceLabel: source,
            isCrossChannelKpiLabel: false,
            sourceMappings: mappings,
            accountId,
          });
        } else {
          handleKpiEdit({
            key: editsKey,
            mappings,
          });
        }
      },
      [sourceIdMap, handleKpiEdit, handleNewRow]
    );

    const applyKpisToAllAccounts = useCallback(
      ({
        valuesToUse,
        crossChannelKpiId,
        crossChannelKpiLabel,
        accountIdToCopyFrom,
        isNewRow,
      }: ApplyKpisToAllAccountsParams) => {
        if (isNewRow) {
          // Get all mappings for this source
          const newKpis: SourceMapping[] = R.pathOr(
            [],
            [crossChannelKpiId, "mappings", source],
            newRows
          );

          // Get mappings for the account that we are copying from
          const newKpisForAccount = newKpis.filter(kpi => kpi.accountId === accountIdToCopyFrom);

          // For each account for this source, apply the same KPIs that we are copying from to all of them
          accounts.forEach(account => {
            const { accountId } = account;
            const edits = newKpisForAccount.map((edit: SourceMapping) => ({
              ...edit,
              accountId,
            }));

            handleNewRow({
              crossChannelKpiId: crossChannelKpiId as string,
              kpiLabel: "",
              sourceLabel: source,
              isCrossChannelKpiLabel: false,
              sourceMappings: edits,
              accountId,
            });
          });
        } else {
          // Get all mappings for this source
          const sourceMappings = R.pathOr(
            [] as SourceMapping[],
            [crossChannelKpiLabel, "mappings", source],
            combinedKpiMappings
          );

          // Take the mappings from the account that we're copying from, and apply that to all accounts for this source
          accounts.forEach(account => {
            const { accountId } = account;
            if (accountId === accountIdToCopyFrom) {
              return;
            }

            // Get values for account ID
            const originalValues = R.filter(
              R.propEq("accountId", accountId),
              sourceMappings
            ).map(mapping => ({ label: mapping.kpi, value: mapping.kpi }));

            const editsKey = makeEditKey({
              crossChannelKpiId,
              source,
              accountId,
            });

            selectOnChange({
              originalValues,
              newValues: valuesToUse,
              source,
              crossChannelKpiId,
              accountId,
              editsKey,
            });
          });
        }
      },
      [source, newRows, accounts, handleNewRow, combinedKpiMappings, selectOnChange]
    );

    const getKpiCounts = useCallback(
      (valuesToUse: { label: string; value: string }[], accountId: string) => {
        if (R.isEmpty(valuesToUse)) {
          return 0;
        }

        let total = 0;
        for (let { value } of valuesToUse) {
          const count = R.pathOr(0, [source, accountId, value], rawSourceKpiCounts);
          total += count;
        }

        return total;
      },
      [rawSourceKpiCounts, source]
    );

    const lagToUse = useMemo(() => lagEdits[source] || lags[source] || "Not set", [
      lagEdits,
      lags,
      source,
    ]);

    const makeDropdownOptions = useCallback(
      (kpis: string[]) =>
        kpis.map(kpi => {
          return { label: kpi, value: kpi };
        }),
      []
    );

    return (
      <div className="sourceColumn">
        <div className="columnHeader">
          <div>{sourceToPrettyName[source] || source}</div>
          {LAG_OPTIONS[source] &&
            (editMode ? (
              <div className="lagDropdown">
                <Dropdown
                  type={DropdownToggleType.OUTLINED}
                  label="Lag"
                  value={lagToUse}
                  options={LAG_OPTIONS[source]}
                  onChange={lag => handleLagEdit({ source, lag })}
                />
              </div>
            ) : (
              <div style={{ fontSize: "14px", fontWeight: 400 }}>{`Lag: ${
                lagToUse === "Not set" ? lagToUse : `${lagToUse}d`
              }`}</div>
            ))}
          {accounts.length > 1 && (
            <Button
              size="sm"
              style={{ height: "18px" }}
              type={ButtonType.EMPTY}
              onClick={() => setCollapsed(prev => !prev)}
              icon={collapsed ? <MdArrowForwardIos /> : <MdArrowBackIosNew />}
            />
          )}
        </div>
        <div className="accountIdColumns">
          {collapsed ? (
            <div style={{ minWidth: "200px", marginTop: "32px" }}>
              Expand to see raw KPIs for all {sourceToPrettyName[source] || source} accounts.
            </div>
          ) : (
            accounts.map((accountInfo, i) => {
              const { accountId, accountName } = accountInfo;
              const dropdownOptions = makeDropdownOptions(
                R.pathOr([], [source, accountId], kpiOptions)
              );

              return (
                <div className="accountIdColumn" key={accountId}>
                  <div className="accountIdLabel">
                    <div className="labelPrefix">Account:</div>
                    <div className="labelValue">{accountName || accountId || "N/A"}</div>
                  </div>
                  {crossChannelKpis.map(({ crossChannelKpiLabel, crossChannelKpiId, isNewRow }) => {
                    // If this is existing row, the key is the crossChannelKpiLabel. If it's new row, the key is the crossChannelKpiId (which is a UUID)
                    const kpiMappingsKey = isNewRow ? crossChannelKpiId : crossChannelKpiLabel;

                    const editsKey = makeEditKey({ crossChannelKpiId, source, accountId });

                    const valuesToUse = getValuesToUse({
                      accountId,
                      editsKey,
                      editsMap,
                      source,
                      kpiMappingsKey,
                      combinedKpiMappings,
                    });

                    const originalValues = getOriginalValues({
                      accountId,
                      source,
                      kpiMappingsKey,
                      combinedKpiMappings,
                    });

                    const kpiCounts = getKpiCounts(valuesToUse, accountId);

                    return (
                      <div key={crossChannelKpiId} className="sourceItem">
                        <div className="sourceKpiPicker">
                          {/* Render an extend button if it's the first column, and there's multiple accounts */}
                          {i === 0 && accounts.length > 1 && editMode && (
                            <OverlayTrigger
                              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                              delay={500}
                              overlay={
                                <Tooltip>
                                  Apply selected KPI(s) to all accounts for{" "}
                                  {sourceToPrettyName[source]}
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                style={{ height: "38px", marginRight: "5px" }}
                                varant={ButtonFrameworkVariant.ICON_ONLY}
                                type={ButtonType.OUTLINED}
                                onClick={() =>
                                  applyKpisToAllAccounts({
                                    crossChannelKpiId,
                                    crossChannelKpiLabel,
                                    accountIdToCopyFrom: accountId,
                                    isNewRow,
                                    valuesToUse,
                                  })
                                }
                              >
                                <MdDoubleArrow />
                              </Button>
                            </OverlayTrigger>
                          )}
                          <div className="sourceKpiDropdown">
                            <Select
                              isDisabled={!editMode}
                              className="kpiSelectorDropdown"
                              placeholder="Choose Raw KPI(s)"
                              isClearable
                              isMulti
                              options={dropdownOptions}
                              value={valuesToUse}
                              onChange={(newVals: { label: string; value: string }[]) => {
                                selectOnChange({
                                  originalValues,
                                  newValues: newVals || [],
                                  source,
                                  crossChannelKpiId,
                                  accountId,
                                  editsKey,
                                  isNewRow,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="rawSourceKpiCount">
                          {!R.isEmpty(valuesToUse) && (
                            <>
                              <div>KPI Volume:</div>
                              <div>{formatNumberAsInt(kpiCounts)}</div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  }
);

export default SourceColumn;
