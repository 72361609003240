import React, { useState } from "react";
import { Tab, Tabs, Form, Card } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { BPMFileInput, Button, ButtonType, ButtonFrameworkVariant } from "../index";
import { S3Put } from "../../utils/fetch-utils";
import "./QuestionCard.scss";

interface QuestionCardUploaderProps {
  questionNumber: string;
  value?: string[];
  onChange: (updatedValue: string[]) => void;
  s3Bucket: string;
  s3Path: string;
  readOnly?: boolean;
}

const QuestionCardUploader: React.FC<QuestionCardUploaderProps> = ({
  questionNumber,
  value = [],
  onChange,
  s3Bucket,
  s3Path,
  readOnly = false,
}) => {
  const [uploadLink, setUploadLink] = useState<string>("");
  const [linkList, setLinkList] = useState<string[]>(value || []);

  const handleNewLink = () => {
    if (uploadLink.trim()) {
      const updatedValue = [...linkList, uploadLink];
      setLinkList(updatedValue);
      onChange(updatedValue);
      setUploadLink("");
    }
  };

  const uploadFileToS3 = (file: File) => {
    const fileReader = new FileReader();

    fileReader.onload = async e => {
      if (!e.target || typeof e.target.result !== "string") {
        return;
      }

      const data = e.target.result.split(",")[1]; // Extract base64 content
      const filePath = `${s3Path}/${file.name}`;

      await S3Put(s3Bucket, filePath, data, {
        bufferEncoding: "base64",
        contentType: file.type,
      });

      const updatedValue = [...linkList, filePath];
      setLinkList(updatedValue);
      onChange(updatedValue);
    };

    fileReader.readAsDataURL(file);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      uploadFileToS3(e.target.files[0]);
    }
  };

  return (
    <div className="QuestionCardUploader">
      <Tabs defaultActiveKey="link" id={`tabs-${questionNumber}`}>
        <Tab eventKey="link" title="Link File(s)">
          <Card className="questionCardLinkCard">
            <Form.Group>
              <Form.Label>Link your file below and grant us view access.</Form.Label>
              <Form.Control
                value={uploadLink}
                onChange={e => setUploadLink(e.target.value)}
                placeholder="Link file here"
                as="textarea"
                disabled={readOnly}
              />
            </Form.Group>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdAdd />}
              onClick={handleNewLink}
              disabled={readOnly || !uploadLink.trim()}
            >
              Submit
            </Button>
          </Card>
        </Tab>
        <Tab eventKey="fileUpload" title="Upload File(s)">
          <BPMFileInput
            id={`${questionNumber}-fileUpload`}
            name="questionCardFileUpload"
            onChange={handleFileUpload}
            disabled={readOnly}
          />
        </Tab>
      </Tabs>
      <Card className="uploadedFiles">
        <h5>Linked/Uploaded File(s)</h5>
        {linkList.length === 0 && <p>Link or upload a file above.</p>}
        {linkList.map((file, index) => (
          <p key={index}>{file}</p>
        ))}
      </Card>
    </div>
  );
};

export default QuestionCardUploader;
