import {
  CellData,
  ColumnMetaData,
  DimensionMap,
  GlossaryItem,
  toPretty1000sInteger,
  toPrettyCPX,
  toPrettyNumber,
  toPrettyPercent,
  toPrettySpend,
} from "../MetricsTable/metricsTableUtils";
import { CreativeMap } from "../../redux/creative";
import { DerivedNetworkMap } from "../../redux/networks";
import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as R from "ramda";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";

export const dimensionColumnMetaDataMap: Partial<Record<Y.DimensionColumnType, ColumnMetaData>> = {
  "Account ID": {
    contentType: "text",
    dimensionVarName: "Account ID",
    displayName: "Account ID",
  },
  "Account Name": {
    contentType: "text",
    dimensionVarName: "Account Name",
    displayName: "Account Name",
  },
  "Ad Name": {
    contentType: "text",
    dimensionVarName: "Ad",
    displayName: "Ad Name",
  },
  "Ad Group Name": {
    contentType: "text",
    dimensionVarName: "Ad Group",
    displayName: "Ad Group Name",
  },
  Age: {
    contentType: "text",
    dimensionVarName: "Age",
    displayName: "Age",
  },
  "Campaign Name": {
    contentType: "text",
    dimensionVarName: "Campaign",
    displayName: "Campaign",
  },
  Device: {
    contentType: "text",
    dimensionVarName: "Device",
    displayName: "Device",
  },
  Gender: {
    contentType: "text",
    dimensionVarName: "Gender",
    displayName: "Gender",
  },
} as any;

export const columnMetaDataMap: Partial<Record<Y.ColumnType, ColumnMetaData>> = {
  averagecpm: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.cost
        ? ((agg.cost as number) * 1000.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    displayName: "Avg. CPM",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.cost
        ? ((fetch.cost as number) * 1000.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["impressions", "cost"],
  },
  averagecpv: {
    aggregator: agg =>
      agg.views && (agg.views as number) > 0 && agg.cost
        ? (agg.cost as number) / R.defaultTo(0.0, agg.views as number)
        : "--",
    displayName: "Avg. Cost Per View",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.views && (fetch.views as number) > 0 && fetch.cost
        ? (fetch.cost as number) / R.defaultTo(0.0, fetch.views as number)
        : "--",
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["views", "cost"],
  },
  clicks: {
    displayName: "Clicks",
    formatValue: toPrettyNumber,
  },
  clicksConversionRate: {
    aggregator: agg =>
      agg.clicks && (agg.clicks as number) > 0 && agg.conversions
        ? ((agg.conversions as number) * 100.0) / R.defaultTo(0.0, agg.clicks as number)
        : "--",
    displayName: "Clicks Conversions Rate",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.clicks && (fetch.clicks as number) > 0 && fetch.conversions
        ? ((fetch.conversions as number) * 100.0) / R.defaultTo(0.0, fetch.clicks as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["clicks", "conversions"],
  },
  clickthroughRate: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.clicks
        ? ((agg.clicks as number) * 100.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    displayName: "Clickthrough Rate",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.clicks
        ? ((fetch.clicks as number) * 100.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["impressions", "clicks"],
  },
  conversions: {
    displayName: "Conversions",
    formatValue: toPrettyNumber,
  },
  cost: {
    displayName: "Cost",
    formatValue: toPrettySpend,
  },
  impressions: {
    displayName: "Impressions",
    formatValue: toPretty1000sInteger,
  },
  impsConversionRate: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.conversions
        ? ((agg.conversions as number) * 100.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    displayName: "Imps Conversions Rate",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.conversions
        ? ((fetch.conversions as number) * 100.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "conversions"],
  },
  revenue: {
    displayName: "Revenue",
    formatValue: toPrettySpend,
  },
  roas: {
    aggregator: agg =>
      agg.cost && (agg.cost as number) > 0 && agg.revenue
        ? ((agg.revenue as number) * 100.0) / R.defaultTo(0.0, agg.cost as number)
        : "--",
    displayName: "ROAS",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.cost && (fetch.cost as number) > 0 && fetch.revenue
        ? ((fetch.revenue as number) * 100.0) / R.defaultTo(0.0, fetch.cost as number)
        : "--",
    formatValue: toPrettyNumber,
    minIsBest: true,
    requiredTotalsColumns: ["cost", "revenue"],
  },
  views: {
    displayName: "Views",
    formatValue: toPretty1000sInteger,
  },
  viewsConversionRate: {
    aggregator: agg =>
      agg.views && (agg.views as number) > 0 && agg.conversions
        ? ((agg.conversions as number) * 100.0) / R.defaultTo(0.0, agg.views as number)
        : "--",
    displayName: "Views Conversions Rate",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.views && (fetch.views as number) > 0 && fetch.conversions
        ? ((fetch.conversions as number) * 100.0) / R.defaultTo(0.0, fetch.views as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["views", "conversions"],
  },
  viewthroughRate: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.views
        ? ((agg.views as number) * 100.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    displayName: "Viewthrough Rate",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.views
        ? ((fetch.views as number) * 100.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["impressions", "views"],
  },
  relativeLift: {
    aggregator: agg =>
      agg.weightedRelativeLift && agg.impressions
        ? (agg.weightedRelativeLift as number) / (agg.impressions as number)
        : "--",
    displayName: "Relative Lift",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: [
      "impressions",
      {
        dataVarName: "weightedRelativeLift",
        fetchGetter: fetch => fetch.relativeLift * fetch.impressions,
      },
    ],
  },
  cpic: {
    aggregator: agg =>
      (agg.incrementalConversions as number) > 0 && agg.cost
        ? R.defaultTo(0.0, Number(agg.incrementalConversions as number)) /
          (Number(agg.cost as number))
        : 0.0,
    decimals: 2,
    displayName: "Cost per Incremental Conversion",
    fetchGetter: fetch =>
      (fetch.incrementalConversions as number) > 0 && fetch.cost
        ? R.defaultTo(0.0, Number(fetch.incrementalConversions as number)) /
          (Number(fetch.cost as number))
        : 0.0,
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["incrementalConversions", "cost"],
  },
  incrementalConversions: {
    displayName: "Incremental Conversions",
    formatValue: toPrettyNumber,
  },
  liftProbability: {
    aggregator: agg =>
      agg.weightedLiftProbability && agg.impressions
        ? (agg.weightedLiftProbability as number) / (agg.impressions as number)
        : "--",
    displayName: "Lift Probability",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: [
      "impressions",
      {
        dataVarName: "weightedLiftProbability",
        fetchGetter: fetch => fetch.liftProbability * fetch.impressions,
      },
    ],
  },
  pvalues: {
    displayName: "P-Values",
    formatValue: toPrettyNumber,
  },
};

export const getDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn,
  creativeMap?: CreativeMap,
  derivedNetworkMap?: DerivedNetworkMap
): CellData => {
  const { dimensionVarName } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<Y.Dimension, string>;
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    value: dimensionValue,
    label: dimensionValue,
  };
  if (dimensionVarName === "Campaign") {
    const value =
      dimensionValue.includes("|") &&
      dimensionValue.split("|") &&
      dimensionValue.split("|").length > 1
        ? dimensionValue.split("|")[1]
        : dimensionValue;
    return {
      label: value,
      value,
    };
  } else if (dimensionVarName === "Age") {
    const value = R.defaultTo("", dimensionValue).replace("AGE_RANGE_", "").replace("_", "-");
    return {
      label: value,
      value,
    };
  }
  return defaultCell;
};

export const GLOSSARY: GlossaryItem[] = [
  {
    term: "Impressions (000s)",
    definition:
      "Impressions reveal how many times your ad was exposed to viewers across YouTube inventory including in-stream, in-feed or Shorts",
  },
  {
    term: "Views",
    definition: `This metric is counted when the viewer watches 30 seconds or until the end of the video, whichever comes first. Interactions on the ad can also increment the view count.
  For example, the following count as a view for in-stream ads:
  A viewer watches the entirety of a 20-second video ad.
  A viewer watches 32 seconds of a 40-second video ad.
  A viewer clicks an interactive element of the ad at 18 seconds (which is both a click and a view increment) of a 20-second ad.`,
  },
  {
    term: "Viewthrough rate (VTR)",
    definition: `The viewthrough rate helps you determine the percent of impressions that resulted in a view. It attests to how compelling your ad is. Viewthrough rate helps you answer the question- “Of the people who were prompted with your ad, what percentage were counted as a view?” A low view rate might suggest that the initial portion of your creative wasn’t compelling enough. 20% is considered average across all industries, but it can vary significantly. Also known as view rate. Not all YouTube media will have a view such as non-skippable formats. This includes Bumpers, and since they are a forced view, only impressions will be counted.
  Viewthough rate = views / impressions`,
  },
  {
    term: "Average cost per view (avg. CPV)",
    definition: `This helps you determine on average how much you're paying for a view, and the cost effectiveness of your ads based on views as a main goal of your campaign. CPV is most valuable when it’s used as a means for comparison between ads, ad groups, and campaigns (isolated metrics are not as insightful). You can then determine which strategies are working best based on your budget.
  Average cost per view = cost / views`,
  },
  {
    term: "Average cost-per-thousand impressions (avg. CPM)",
    definition: `CPM helps you determine on average how much you’re paying per 1,000 impressions. Be aware that a good avg. CPM varies by country because there are different minimums that need to be met for CPM bids. CPM will also vary by Google product with Video Action campaigns seeing higher CPMs than Reach Campaigns or Bumpers.
  CPM = cost / ( impressions / 1,000 )
  CPM shows the cost effectiveness of your ads`,
  },
  {
    term: "View-through conversions (VTC)",
    definition: `View-through conversions (“View-through conv.”) are conversions that are recorded when users get served an impression for your ad (but aren’t counted as a view or click), and then convert on your site within the conversion window (the length of the conversion window is determined by when the conversion is created in the account).
  This metric automatically excludes conversions from people who have also interacted with any of your other ads. The last impression of a video ad will get credit for the view-through conversion.
  A view that leads to a conversion is counted in the “Conversions” column and not in the VTC column. VTCs help you bridge the gap between the top and the bottom of your purchase funnel. If you look at just conversions, you might miss capturing the impact your ads had for users that converted based just on an impression.`,
  },
  {
    term: "Clicks and Click-through rate (CTR)",
    definition: `Clicks and Click-through rate (CTR): Clicks, which occur when the viewer clicks an interactive element of your ad, and CTR (Clicks / Impressions) for Video campaigns shouldn’t typically be considered a priority compared to impressions or views (if your goal is awareness) or conversions (if your goal is sales). Low clicks and CTR, is fairly normal in Video campaigns, compared to Search campaigns.
  Clicks and CTR within Video campaigns are typically not metrics that you should have as a priority to determine campaign success. It can be an indicator of how well your ad prompts users to take action upon viewing your ads, but the primary metric with which conversions are given attribution to your Video campaigns is "views" (for example, a click is not required for your campaign to get credit for a conversion, but a view is).`,
  },
  {
    term: "Conversions",
    definition:
      "In Video campaigns, conversions are measured when a viewer is counted as a view for your ad and then takes an action that you’ve defined as valuable to your business, such as an online purchase or a call to your business from a mobile phone.",
  },
  {
    term: "Conversions Rate",
    definition: `Conversion rate (CVR): CVR gives insight into how effective your ad is at getting users to your site and converting after being counted as a view. Like many of the other metrics, a good CVR is very dependent on the advertiser industry.
  CVR = Conversions / Views`,
  },
  {
    term: "Cost",
    definition: "Spend",
  },
  {
    term: "Cost per Incremental Conversion",
    definition: `The spend needed to have the user complete the action that you've defined as valuable to your business, such as an online purchase. Specifically, the number of these actions that were truly incremental, meaning they would not have occurred had the user not viewed the Video ad. 
  Cost per Incremental Conversion (CPiC) = Cost / Incremental Conversions`,
  },
  {
    term: "Incremental Conversions",
    definition:
      "The volume of incremental actions that have been defined as valuable to the business, meaning the action would not have occurred had the user not viewed the Video ad.",
  },
  {
    term: "P-Value",
    definition:
      "The likelihood that we would observe a difference in treatment versus control response rates as large as that observed in the experiment, if there were no true underlying difference between them.",
  },
  {
    term: "ROAS",
    definition: `Return on ad spend. 
  ROAS = Revenue / Cost`,
  },
  {
    term: "Relative Lift",
    definition: `"Relative lift" shows the estimated difference in positive responses between users who saw your ads, versus users who were withheld from seeing your ads. This difference is then divided by the number of positive responses from the group of users who didn't see your ads.
  The result measures how much your ads influenced your audience's positive feelings towards your brand or product compared to the rate by which users who didn't see your ads had positive feelings towards your brand or product.`,
  },
  {
    term: "Revenue",
    definition:
      "Earnings from the purchases attributed to being completed after viewing the Video ads.",
  },
];
