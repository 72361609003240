import "./IconToggleButton.scss";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { ButtonFramework, ButtonFrameworkVariant } from "../ButtonFramework";

interface Option {
  key: string;
  icon: React.ReactElement;
  label: string; // set to empty string to exclude tooltip
}

interface Props {
  options: readonly Option[];
  selectedOption: string;
  onChange: (key: string) => void;
  className?: string;
  size?: "lg" | "sm";
  background?: "light" | "dark";
}

export const IconToggleButton: React.FC<Props> = React.memo(
  ({
    className = "",
    options,
    size = "lg",
    background = "light",
    selectedOption,
    onChange,
  }): JSX.Element => {
    const [clickedKey, setClickedKey] = useState<string>("");

    useEffect(() => {
      const parentContainers = document.getElementsByClassName("chartContainer");

      const handleParentMouseLeave = () => {
        setClickedKey(""); // Reset animateOnClick when chartContainer is hovered out
      };

      for (let i = 0; i < parentContainers.length; i++) {
        parentContainers[i]?.addEventListener("mouseleave", handleParentMouseLeave);
      }

      return () => {
        for (let i = 0; i < parentContainers.length; i++) {
          const parentContainer = parentContainers[i];
          parentContainer?.removeEventListener("mouseleave", handleParentMouseLeave);
        }
      };
    }, []); // Run this effect only once on component mount

    const buttonGroupClassName = useMemo(() => {
      let classes = ["icon-toggle-button", background];

      if (className) {
        classes.push(className);
      }

      return classes.join(" ");
    }, [background, className]);

    return (
      <ButtonGroup toggle className={buttonGroupClassName}>
        {options.map((option, index) => {
          const { key, icon, label } = option;
          let active = selectedOption === key;

          return (
            <ButtonFramework
              className={`icon-toggle-item icon_${index} ${background} ${
                active ? "selected" : "unselected"
              }${clickedKey === key ? " animated" : ""}`}
              key={key}
              variant={ButtonFrameworkVariant.ICON_ONLY}
              size={size}
              icon={icon}
              onClick={() => {
                if (!active) {
                  onChange(key);
                }
                setClickedKey(key);
              }}
              onAnimationEnd={() => {
                setClickedKey("");
              }}
              tooltipText={label}
            />
          );
        })}
      </ButtonGroup>
    );
  }
);
