import { ExecuteParameters, ParameterId, VerbId, VisualId } from '@tableau/api-internal-contract-js';
import { TooltipTextRequestModel } from '../../Models/AccessibilityModels';
import { AccessibilityService } from '../AccessibilityService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class AccessibilityServiceImpl extends ServiceImplBase implements AccessibilityService {
  public get serviceName(): string {
    return ServiceNames.Accessibility;
  }

  public getTooltipTextAsync(visualId: VisualId, tupleId: number): Promise<String> {
    let tooltipTextRequest = new TooltipTextRequestModel(visualId, tupleId);

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getTooltipTextAsync',
      [ParameterId.TooltipTextRequest]: tooltipTextRequest,
    };

    return this.execute(VerbId.GetTooltipText, parameters).then<String>((response) => {
      return response.result as String;
    });
  }

  public leaveMarkNavigationAsync(visualId: VisualId): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'leaveMarkNavigationAsync',
      [ParameterId.VisualId]: visualId,
    };

    return this.execute(VerbId.RaiseLeaveMarkNavNotification, parameters).then<void>((response) => {
      return;
    });
  }
}
