import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

import { AREA_LINE_CHART_COLOR, BAR_CHART_COLOR } from "../../utils/colors";
import { abbreviateNumber } from "../../utils/format-utils";

interface LineAndBarChartProps {
  lineDataKey: string;
  barDataKey: string;
  data: {}[];
  legendLocation?: "top" | "bottom";
  xAxisDataKey: string;
  barYAxisDataKey: string;
  barYAxisLabel: string;
  lineYAxisDataKey: string;
  lineYAxisLabel: string;
  isAnimationActive?: boolean;
  height?: number;
  width?: number;
  barChartColor?: string;
  lineChartColor?: string;
}

export const LineAndBarChart: React.FC<LineAndBarChartProps> = ({
  lineDataKey,
  barDataKey,
  data,
  legendLocation = "top",
  xAxisDataKey,
  barYAxisDataKey,
  barYAxisLabel,
  lineYAxisDataKey,
  lineYAxisLabel,
  isAnimationActive = true,
  height,
  width,
  barChartColor = BAR_CHART_COLOR,
  lineChartColor = AREA_LINE_CHART_COLOR,
}) => {
  const xAxis = <XAxis dataKey={xAxisDataKey} />;
  const barYAxis = (
    <YAxis
      dataKey={barYAxisDataKey}
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "left",
        value: barYAxisLabel,
      }}
      orientation="left"
      tickFormatter={tick => `${abbreviateNumber(tick)}`}
      type="number"
      yAxisId="0"
    />
  );
  const lineYAxis = (
    <YAxis
      dataKey={lineYAxisDataKey}
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "right",
        value: lineYAxisLabel,
      }}
      orientation="right"
      tickFormatter={tick => `${abbreviateNumber(tick)}`}
      type="number"
      yAxisId="1"
    />
  );
  const tooltip = (
    <Tooltip
      formatter={d => `${abbreviateNumber(Number(d))}`}
      isAnimationActive={isAnimationActive}
    />
  );
  const legend = <Legend verticalAlign={legendLocation} />;
  const bar = (
    <Bar
      barSize={30}
      dataKey={barDataKey}
      fill={barChartColor}
      yAxisId="0"
      isAnimationActive={isAnimationActive}
    />
  );
  const line = (
    <Line
      yAxisId="1"
      stroke={lineChartColor}
      dataKey={lineDataKey}
      type="linear"
      isAnimationActive={isAnimationActive}
      strokeWidth={3}
      dot={false}
    />
  );

  if (height && width) {
    return (
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
        height={height}
        width={width}
      >
        {xAxis}
        {barYAxis}
        {lineYAxis}
        {tooltip}
        {legend}
        {bar}
        {line}
      </ComposedChart>
    );
  } else {
    return (
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          height={height}
          width={width}
        >
          {xAxis}
          {barYAxis}
          {lineYAxis}
          {tooltip}
          {legend}
          {bar}
          {line}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
};
