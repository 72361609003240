import { tmrClient } from "./Cache";
import {
  GET_SUMMARY_SLIDE_DATA,
  GET_CAMPAIGN_SLIDE_DATA,
  GET_KEYWORD_SLIDE_DATA,
  GET_PRODUCT_SLIDE_DATA,
  GET_DSP_SLIDE_DATA,
  GET_BIGGEST_MOVER_DATA,
  GET_SEASONALITY_DATA,
  GET_FREE_FORM_DATA,
  GET_CATEGORY_DATA,
} from "./Queries";

export const fetchSummarySlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_SUMMARY_SLIDE_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      dimension: "channel",
      dateDimension: "date",
      retailer: retailer === "null" ? null : retailer,
    },
  });
  return data.tmr;
};

export const fetchCampaignSlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  sort: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_CAMPAIGN_SLIDE_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      retailer: retailer === "null" ? null : retailer,
      sort,
    },
  });
  return data.tmr.campaign;
};

export const fetchKeywordSlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  sort: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_KEYWORD_SLIDE_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      retailer: retailer === "null" ? null : retailer,
      sort,
    },
  });
  return data.tmr.keyword;
};

export const fetchProductSlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  sort: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_PRODUCT_SLIDE_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      retailer: retailer === "null" ? null : retailer,
      sort,
    },
  });
  return data.tmr.product;
};
export const fetchDspSlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_DSP_SLIDE_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
    },
  });
  return data.tmr.dsp;
};

export const fetchBetterTogetherSlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  profileNames: string[]
): Promise<any> => {
  // const { data } = await tmrClient.query({
  //   query: GET_DSP_SLIDE_DATA,
  //   variables: {
  //     companyId: "meyer",
  //     dateRange: { startDate, endDate },
  //     retailer: retailer === "null" ? null : retailer,
  //   },
  // });

  const data = await {
    tmr: {
      betterTogether: {
        dataByWeek: [
          {
            reportWeek: "sep 10 2023",
            searchSpend: 1918.34,
            searchSales: 802.92,
            searchRoas: 7.72,
            dspSpend: 0,
            dspSales: 0,
            dspRoas: null,
            totalAdSpend: 31918.34,
            totalAdSales: 108.92,
            totalAdRoas: 7.72,
            totalAdRevenue: 75,
            orderedRevenue: 0,
            shippedCogs: 0,
          },
          {
            reportWeek: "sep 17 2023",
            searchSpend: 918.34,
            searchSales: 14802.92,
            searchRoas: 7.72,
            dspSpend: 0,
            dspSales: 0,
            dspRoas: null,
            totalAdSpend: 1918.34,
            totalAdSales: 1408.92,
            totalAdRoas: 7.72,
            totalAdRevenue: 30,
            orderedRevenue: 0,
            shippedCogs: 0,
          },
          {
            reportWeek: "sep 24 2023",
            searchSpend: 5918.34,
            searchSales: 9802.92,
            searchRoas: 7.72,
            dspSpend: 0,
            dspSales: 0,
            dspRoas: null,
            totalAdSpend: 143918.34,
            totalAdSales: 12408.92,
            totalAdRoas: 7.72,
            totalAdRevenue: 92,
            orderedRevenue: 0,
            shippedCogs: 0,
          },
        ],
        summary: {
          totalAdSales: 14800,
          totalAdSalesChange: 50.6,
          totalAdSpend: 1920,
          totalAdSpendChange: 64.2,
          totalAdRoas: 7.72,
          totalAdRoasChange: 37.9,
          orderedRevenue: 0,
          orderedRevenueChange: "N/A",
          shippedCogs: 0,
          shippedCogsChange: "N/A",
          totalAdRevenue: 65,
          totalAdRevenueChange: -99.9,
          totalAdImpressions: 1700000,
          totalAdImpressionsChange: -50.1,
          totalAdDpv: 356,
          totalAdDpvChange: 42.8,
          totalAdClicks: 7200,
          totalAdClicksChange: -47.3,
        },
      },
    },
  };
  return data.tmr.betterTogether;
};

export const fetchBiggestMoverSlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  filterField: string,
  dollarMin: number,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_BIGGEST_MOVER_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      retailer: retailer === "null" ? null : retailer,
      filterField,
      dollarMin,
    },
  });
  return data.tmr.biggestMovers;
};

export const fetchSeasonalitySlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_SEASONALITY_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      retailer: retailer === "null" ? null : retailer,
    },
  });
  return data.tmr.seasonality;
};

export const fetchFreeFormSlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  sort: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_FREE_FORM_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      retailer: retailer === "null" ? null : retailer,
    },
  });
  return data.tmr.freeForm;
};

export const fetchCategorySlideData = async (
  companyId: string,
  startDate: string,
  endDate: string,
  retailer: string,
  sort: string,
  profileNames: string[]
): Promise<any> => {
  const { data } = await tmrClient.query({
    query: GET_CATEGORY_DATA,
    variables: {
      companyId,
      profileNames,
      dateRange: { startDate, endDate },
      retailer: retailer === "null" ? null : retailer,
      sort,
    },
  });
  return data.tmr.category;
};
