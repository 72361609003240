import React, { useState, useMemo, useCallback, useEffect } from "react";
import Select from "react-select";
import * as uuid from "uuid";
import * as R from "ramda";
import { awaitJSON, MiscLambdaFetch, pollS3 } from "../utils/fetch-utils";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import { useSetError } from "../redux/modals";

import {
  Page,
  BPMTable,
  BPMButton,
  Spinner,
  SingleDatePicker,
  OverlayTrigger,
} from "../Components";
import { Form, Modal, Tooltip } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";

import "./InternalReports.scss";

interface ReportFieldConfiguration {
  label: string;
  field: string;
  type: string;
}

interface ReportConfiguration {
  type: string;
  title: string;
  description: string;
  reportId?: string;
  parameters: ReportFieldConfiguration[];
}

const DEMO_OPTIONS = [
  { label: "hh", value: "hh" },
  { label: "a18_34", value: "a18_34" },
  { label: "a18_49", value: "a18_49" },
  { label: "a25_54", value: "a25_54" },
  { label: "a35_54", value: "a35_54" },
  { label: "a18plus", value: "a18plus" },
  { label: "a55plus", value: "a55plus" },
  { label: "m18_20", value: "m18_20" },
  { label: "m18_34", value: "m18_34" },
  { label: "m18_49", value: "m18_49" },
  { label: "m21_24", value: "m21_24" },
  { label: "m25_29", value: "m25_29" },
  { label: "m25_54", value: "m25_54" },
  { label: "m30_34", value: "m30_34" },
  { label: "m35_39", value: "m35_39" },
  { label: "m35_54", value: "m35_54" },
  { label: "m40_44", value: "m40_44" },
  { label: "m45_49", value: "m45_49" },
  { label: "m50_54", value: "m50_54" },
  { label: "m55_64", value: "m55_64" },
  { label: "m18plus", value: "m18plus" },
  { label: "m55plus", value: "m55plus" },
  { label: "m65plus", value: "m65plus" },
  { label: "w18_20", value: "w18_20" },
  { label: "w18_34", value: "w18_34" },
  { label: "w18_49", value: "w18_49" },
  { label: "w21_24", value: "w21_24" },
  { label: "w25_29", value: "w25_29" },
  { label: "w25_54", value: "w25_54" },
  { label: "w30_34", value: "w30_34" },
  { label: "w35_39", value: "w35_39" },
  { label: "w35_54", value: "w35_54" },
  { label: "w40_44", value: "w40_44" },
  { label: "w45_49", value: "w45_49" },
  { label: "w50_54", value: "w50_54" },
  { label: "w55_64", value: "w55_64" },
  { label: "w18plus", value: "w18plus" },
  { label: "w55plus", value: "w55plus" },
  { label: "w65plus", value: "w65plus" },
  { label: "a12_17", value: "a12_17" },
  { label: "p2_plus", value: "p2_plus" },
];

const InternalReports = (): JSX.Element => {
  const getIsMounted = useIsMounted();
  const setError = useSetError();

  const [tableData, setTableData] = useState<ReportConfiguration[]>([]);
  const [showConfigureModal, setShowConfigureModal] = useState<ReportConfiguration | null>(null);
  const [report, setReport] = useState({});

  useEffect(() => {
    if (tableData.length === 0) {
      (async () => {
        let res = await MiscLambdaFetch("/list_internal_reports");
        let internalReports = await awaitJSON(res);
        if (getIsMounted()) {
          setTableData(internalReports);
        }
      })();
    }
  }, [tableData, setError, getIsMounted]);

  const headers = [
    {
      label: "Type",
      name: "type",
      flex: 1,
    },
    {
      label: "Title",
      name: "title",
      flex: 1,
    },
    {
      label: "Description",
      name: "description",
      flex: 1,
    },
    {
      label: "Query",
      name: "query",
      width: 100,
      renderer: data => (
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.LEFT.TOP}
          exitOnBackgroundClick
          trigger="click"
          overlay={<Tooltip id="tooltip">{data.query}</Tooltip>}
        >
          <MdInfoOutline />
        </OverlayTrigger>
      ),
    },
    {
      label: "Configure",
      name: "title",
      width: 150,
      renderer: data => {
        return data.reportId ? (
          <Spinner />
        ) : (
          <BPMButton variant="primary" onClick={() => setShowConfigureModal(data)}>
            Run
          </BPMButton>
        );
      },
    },
  ];

  const onSubmit = async () => {
    const reportId = uuid.v4();
    const title = showConfigureModal?.title;

    setTableData(
      R.map(row => {
        if (row.title === title) {
          return { ...row, reportId };
        } else {
          return row;
        }
      }, tableData)
    );
    setShowConfigureModal(null);

    await MiscLambdaFetch("/run_internal_report", {
      method: "post",
      body: { ...report, id: reportId, title },
    });

    await pollS3({
      bucket: "bpm-cache",
      mimeType: "text/csv",
      filename: `${reportId}.csv`,
      autoDownload: true,
      overloadFilename: `${title}.csv`,
    });

    setTableData(
      R.map(row => {
        if (row.title === title) {
          return { ...row, reportId: undefined };
        } else {
          return row;
        }
      }, tableData)
    );
  };

  const configurationComplete = useMemo(() => {
    if (!showConfigureModal) {
      return false;
    }

    return R.all(row => Boolean(report[row.field]), showConfigureModal.parameters);
  }, [report, showConfigureModal]);

  const inputForConfigurationRow = useCallback(
    (row: ReportFieldConfiguration) => {
      if (row.type === "date") {
        return (
          <SingleDatePicker
            date={report[row.field]}
            onChange={date => {
              setReport({ ...report, [row.field]: date });
            }}
          />
        );
      } else if (row.type === "dropdown") {
        return (
          <div className="reportConfigDropdown">
            <Select
              style={{ width: 250 }}
              isSearchable
              value={{ label: report[row.field], value: report[row.field] }}
              options={DEMO_OPTIONS}
              onChange={e => setReport({ ...report, [row.field]: e.value })}
            />
          </div>
        );
      } else {
        return (
          <Form.Control
            value={report[row.field]}
            onChange={e => setReport({ ...report, [row.field]: e.currentTarget.value })}
          />
        );
      }
    },
    [report]
  );

  return (
    <Page title="Internal Reports" pageType="Internal Reports" minHeight={"600"}>
      {Boolean(showConfigureModal) && (
        <Modal
          size="lg"
          show={Boolean(showConfigureModal)}
          onHide={() => {
            setShowConfigureModal(null);
          }}
          className="reportsModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Configuration ({showConfigureModal?.title})</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showConfigureModal?.parameters.map(row => (
              <Form.Group key={row.field} className="reportConfigGroup">
                <Form.Label className="reportConfigLabel">{row.label}</Form.Label>
                {inputForConfigurationRow(row)}
              </Form.Group>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <BPMButton variant="outline-primary" onClick={() => setShowConfigureModal(null)}>
              Cancel
            </BPMButton>
            <BPMButton className="saveButton" disabled={!configurationComplete} onClick={onSubmit}>
              Submit
            </BPMButton>
          </Modal.Footer>
        </Modal>
      )}
      <BPMTable headers={headers} data={tableData} filterBar={true} />
    </Page>
  );
};

export default InternalReports;
