import React, { useCallback, useEffect, useState, useMemo } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Alert, Button, Card, Modal, Tab, Tabs } from "react-bootstrap";
import { useWorkspaceOptions } from "../../StreamingCreatives/StreamingCreatives";
import { StagedTagData } from "./utils";
import { PieSetter, Spinner } from "../../Components";
import { EditableCard } from "../../StreamingCreatives/Cards";
import { useMap } from "../../utils/hooks/useData";
import { StateSetter } from "../../utils/types";
import { MdCheckCircle } from "react-icons/md";

import "./CreativeAllocationPicker.scss";

const TODAY = Dfns.format("yyyy-MM-dd", new Date());

interface CreativeAllocationPickerProps {
  tags: StagedTagData[];
  setTags: StateSetter<StagedTagData[]>;
  onSubmit: any;
  isEditMode: boolean;
}

export const CreativeAllocationPicker = ({
  tags,
  setTags,
  onSubmit,
  isEditMode,
}: CreativeAllocationPickerProps): JSX.Element => {
  const [selectedTag, setSelectedTag] = useState<StagedTagData>(tags[0]);
  const creativeOptions = useWorkspaceOptions(
    TODAY,
    selectedTag.length,
    selectedTag.platform,
    selectedTag.adServer
  );
  const [formFailMessage, setFormFailMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditingRotations, setIsEditingRotations] = useState(isEditMode);
  const [shouldUseSingleCreativeAllocation, setShouldUseSingleCreativeAllocation] = useState(false);

  const existingCreativeAllocations = useMemo(() => {
    let allocationsMap = {};
    tags.forEach(tag => {
      allocationsMap[tag.name] = tag.creativeAllocationMap;
    });
    return allocationsMap;
  }, [tags]);

  const [
    tagsToCreativeAllocations,
    setTagsToCreativeAllocationsValue,
    setTagsToCreativeAllocations,
  ] = useMap<string, Record<string, number>>(isEditMode ? existingCreativeAllocations : {});

  // Removes allocations that are NaN or 0%.
  const tidyCreativeAllocationMap = useCallback(() => {
    Object.keys(tagsToCreativeAllocations).forEach(tag => {
      const creativeAllocationMap = tagsToCreativeAllocations[tag] || {};
      Object.keys(creativeAllocationMap).forEach(creative => {
        if (!creativeAllocationMap[creative]) {
          delete creativeAllocationMap[creative];
        }
      });
    });
  }, [tagsToCreativeAllocations]);

  const isValidAllocation = useCallback(
    tag => {
      const creativeAllocation: Record<string, number> = tagsToCreativeAllocations[tag.name] || {};
      const allocationSum = Object.values(creativeAllocation).reduce((a, b) => {
        return a + (b || 0);
      }, 0);
      return allocationSum === 100;
    },
    [tagsToCreativeAllocations]
  );

  // Note: This function will trigger the below useEffect once setTags completes,
  // which will move the modal to the clickthrough URL form.
  const attemptSubmit = useCallback(() => {
    tidyCreativeAllocationMap();

    // Make sure there's an allocation for every tag and each allocation sums to 100.
    let allAllocationsExist = tags.length === Object.keys(tagsToCreativeAllocations).length;
    let allAllocationsValid = tags.reduce((prev, curr) => prev && isValidAllocation(curr), true);

    if (!allAllocationsExist) {
      setFormFailMessage("You are missing allocations for one or more tags.");
      return;
    } else if (!allAllocationsValid) {
      setFormFailMessage("Creative allocations must sum to 100%");
      return;
    }

    let tagsWithCreativeAllocations = tags.map(tag => {
      return {
        ...tag,
        creativeAllocationMap: tagsToCreativeAllocations[tag.name],
      };
    });
    setTags(tagsWithCreativeAllocations);
    setTagsToCreativeAllocations({});
    setIsEditingRotations(false);
  }, [
    isValidAllocation,
    setTags,
    setTagsToCreativeAllocations,
    tags,
    tagsToCreativeAllocations,
    tidyCreativeAllocationMap,
  ]);

  useEffect(() => {
    // Wait until creativeAllocationMap is set on all tags before submitting.
    const tagsHaveCreativeAllocations = tags.reduce(
      (prev, curr) => prev && Boolean(curr.creativeAllocationMap),
      true
    );
    if (tagsHaveCreativeAllocations && !isSubmitted && !isEditingRotations) {
      onSubmit();
      setIsSubmitted(true);
    }

    // Reset isSubmitted when we get a new tag.  We can tell because it will have
    // no creative allocations set.
    if (!tagsHaveCreativeAllocations) {
      setIsSubmitted(false);
    }
  }, [onSubmit, tags, isSubmitted, isEditingRotations]);

  const makeTabTitle = useCallback(tag => {
    let properties: string[] = [];
    if (tag.device) {
      properties.push(tag.device);
    }
    if (tag.OS) {
      properties.push(tag.OS);
    }
    if (tag.length) {
      properties.push(`${tag.length}s`);
    }
    // Just use the tag name if there are no differentiating properties.
    return properties.length ? properties.join(" - ") : tag.name;
  }, []);

  const setCreativeAllocationValue = useCallback(
    (isci, value, tagName) => {
      let newAlloc = { ...tagsToCreativeAllocations[tagName] };
      newAlloc[isci] = value;

      if (shouldUseSingleCreativeAllocation) {
        // Set the same allocation for all of the tags.
        for (let name of tags.map(tag => tag.name)) {
          setTagsToCreativeAllocationsValue(name, newAlloc);
        }
      } else {
        setTagsToCreativeAllocationsValue(tagName, newAlloc);
      }
    },
    [
      setTagsToCreativeAllocationsValue,
      shouldUseSingleCreativeAllocation,
      tags,
      tagsToCreativeAllocations,
    ]
  );

  const toggleShouldUseSingleCreativeAllocation = useCallback(
    currentTagName => {
      setShouldUseSingleCreativeAllocation(R.not);

      // Set all other tags to have the same allocation of the tag from the open tab.
      let currentTagAlloc = tagsToCreativeAllocations[currentTagName];
      if (currentTagAlloc) {
        for (let name of tags.map(tag => tag.name)) {
          setTagsToCreativeAllocationsValue(name, currentTagAlloc);
        }
      }
    },
    [setTagsToCreativeAllocationsValue, tags, tagsToCreativeAllocations]
  );

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title className="modalHeader">
          <div>{"Set Creative Rotations"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <div className="workspace">
          <Tabs
            defaultActiveKey={tags[0].name}
            onSelect={tagName => {
              const tag = R.find(R.propEq("name", tagName), tags);
              setSelectedTag(tag as StagedTagData);
            }}
          >
            {tags.map(tag => {
              const tabTitle = makeTabTitle(tag);
              return (
                <Tab
                  className="tab"
                  key={tag.name}
                  eventKey={tag.name}
                  title={
                    <div className="tabTitle">
                      {tabTitle}
                      {isValidAllocation(tag) ? (
                        <div className="validIndicator">
                          <MdCheckCircle />
                        </div>
                      ) : null}
                    </div>
                  }
                >
                  {tags.length > 1 && !tags[0]?.splitLength && (
                    <div className="creativeAllocationCheckboxContainer">
                      <input
                        className="creativeAllocationCheckbox"
                        type="checkbox"
                        checked={shouldUseSingleCreativeAllocation}
                        onChange={() => toggleShouldUseSingleCreativeAllocation(tag.name)}
                      />
                      <div className="creativeAllocationCheckboxLabel">
                        Set the same allocation for all tags
                      </div>
                    </div>
                  )}
                  <Card className="workspaceCard">
                    <div className="workspaceCardInner">
                      {creativeOptions ? (
                        <PieSetter
                          pieFlex={0.5}
                          percentage
                          data={R.map(opt => {
                            const creativeAllocation = tagsToCreativeAllocations[tag.name] || {};
                            return {
                              ...opt,
                              key: opt.isci,
                              value: creativeAllocation[opt.isci] || 0,
                            };
                          }, creativeOptions)}
                          renderItem={elem => (
                            <EditableCard
                              {...elem}
                              onChange={(isci, value) =>
                                setCreativeAllocationValue(isci, value, tag.name)
                              }
                            />
                          )}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  </Card>
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {formFailMessage && (
          <Alert variant="danger" className="alert">
            {formFailMessage}
          </Alert>
        )}
        <Button onClick={attemptSubmit}>Done</Button>
      </Modal.Footer>
    </div>
  );
};
