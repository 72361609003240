import "./SourcePicker.scss";
import React, { useState, useMemo, useEffect } from "react";
import { Dropdown, DropdownToggleType } from "../../Components";
import { TabKey } from "../CrossChannel";

enum SourceType {
  PLATFORM = "Platform",
  SOURCE_OF_TRUTH = "Source of Truth",
}

const SOURCE_TYPES = ["Platform", "Source of Truth"];

interface SourcePickerProps {
  applyOnClick: (source) => void;
  label?: string;
  onlyUseSourceTypes: boolean; // If true, only use source types, not source values (for non-Overview tabs you just pick "Platform" or "Source of Truth")
  platforms: string[];
  selectedSourceType: `${SourceType}`;
  selectedSourceValue: string; // Specific SoT values (e.g. "GA4", "TVAD", etc.)
  sourceValueOptions: { label: string; value: string }[];
  selectedTab: string;
}

/*
 * If platform is the selected source type, there won't be a selected value because you don't select a specific platform.
 * If Source of Truth is selected, you will selected a specific value if you're on the Overview tab, but no selected value when on the other tabs.
 *  When in a state where there isn't a selected value, we use the menuRenderOverride to display a string list of options instead of a picker.
 */
const SourcePicker: React.FC<SourcePickerProps> = ({
  applyOnClick,
  label = "Source: ",
  onlyUseSourceTypes,
  platforms,
  selectedSourceType: selectedSourceTypeInput,
  selectedSourceValue: selectedSourceValueInput,
  sourceValueOptions,
  selectedTab,
}) => {
  const [selectedSourceType, setSelectedSourceType] = useState<string | undefined>();
  const [selectedSourceValue, setSelectedSourceValue] = useState<string | undefined>();

  useEffect(() => {
    setSelectedSourceType(selectedSourceTypeInput);
    setSelectedSourceValue(selectedSourceValueInput);
  }, [selectedSourceTypeInput, selectedSourceValueInput]);

  const sourceTypeOptions = useMemo(() => {
    let options: { label: string; value: string; selected: boolean; className: string }[] = [];
    for (let sourceType of SOURCE_TYPES) {
      // If the only source value is platform, disable the Source of Truth picker.
      const isDisabled =
        sourceType === SourceType.SOURCE_OF_TRUTH &&
        sourceValueOptions.length === 1 &&
        sourceValueOptions[0].value === "platform";

      options.push({
        label: sourceType,
        value: sourceType,
        selected: selectedSourceType === sourceType,
        className: isDisabled ? "disabled" : "",
      });
    }
    return options;
  }, [selectedSourceType, sourceValueOptions]);

  const rightMenuOptions = useMemo(() => {
    let options: { label: string; value: string; selected: boolean }[] = [];
    if (selectedSourceType === SourceType.PLATFORM) {
      for (let platform of platforms) {
        options.push({
          label: platform,
          value: platform,
          selected: selectedSourceValue === platform,
        });
      }
    } else {
      for (let sourceOption of sourceValueOptions) {
        if (sourceOption.value !== "platform") {
          options.push({
            ...sourceOption,
            selected: selectedSourceValue === sourceOption.value,
          });
        }
      }
    }

    return options;
  }, [platforms, selectedSourceType, selectedSourceValue, sourceValueOptions]);

  // Whether to render the menu as a string list instead of a picker
  const menuRenderOverride = useMemo(() => {
    if (selectedSourceType === SourceType.PLATFORM || selectedTab !== TabKey.OVERVIEW) {
      // 1 is the index of the menu (the second menu in the dropdown)
      return { "1": "stringList" as const };
    }
  }, [selectedSourceType, selectedTab]);

  return (
    <div className="crossChannelSourcePicker">
      <Dropdown
        type={DropdownToggleType.OUTLINED}
        label={<div>{label}</div>}
        value={""}
        options={[]} // This is a placeholder. The actual options are passed in as menuItems
        menuItems={[sourceTypeOptions, rightMenuOptions]}
        menuRenderOverride={menuRenderOverride}
        multiSelect={[false, false]}
        applyOnClick={([selectedSourceTypeArray, selectedSourceValueArray]) => {
          const selectedSourceType = (selectedSourceTypeArray[0] || {}).value;
          const selectedSourceValue = (selectedSourceValueArray[0] || {}).value;

          if (onlyUseSourceTypes) {
            applyOnClick(selectedSourceType);
            return;
          }

          if (selectedSourceType === SourceType.PLATFORM) {
            applyOnClick("platform");
          } else {
            applyOnClick(selectedSourceValue);
          }
        }}
        cancelOnClick={() => {}}
        onChange={(value: string) => {
          if (SOURCE_TYPES.includes(value)) {
            setSelectedSourceType(value);
            if (value === SourceType.SOURCE_OF_TRUTH) {
              // When switching to Source of Truth, select the first value by default
              setSelectedSourceValue((sourceValueOptions[0] || {}).value);
            }
          } else {
            setSelectedSourceValue(value);
          }
        }}
      />
    </div>
  );
};

export default SourcePicker;
