import "./MediaMix.scss";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import * as R from "ramda";
import { ntbTableHeaders, prospectiveTableHeaders } from "./MediaMixConfig";
import { useCompanyInfo } from "../../../redux/company";
import { BPMTable, FullPageSpinner, Page } from "../../../Components";
import { abbreviateNumber, formatMoneyAsInt } from "../../../utils/format-utils";
import { amcClient } from "../../Cache";
import { GET_MEDIA_MIX } from "../../Queries";
import {
  maxProPurchaseRate,
  maxProPurchased,
  maxProOrderSize,
  maxNTBPurchaseRate,
  maxNTBPurchased,
  maxNTBOrderSize,
  maxReach,
  GET_MAX_MEDIA_MIX,
} from "../../ReactiveVariables";
import AreaAndBarChart from "../../../Components/AreaAndBarChart/AreaAndBarChart";
import {
  formatDate,
  MonthPicker,
  startDateRange,
} from "../../../Components/MonthPicker/MonthPicker";
import { RouteComponentProps } from "@reach/router";
import { AMC_TEST_INSTANCE_ID } from "../../Insights";

/*eslint-disable no-empty-pattern*/
const AMCMediaMix = ({}: RouteComponentProps): JSX.Element => {
  let { cid, amc_instance_id: amcInstanceId } = useCompanyInfo();
  if (cid === "tinuititest") {
    amcInstanceId = AMC_TEST_INSTANCE_ID;
  }
  // Previous month reports not prepared until the 15th of current month
  const monthsBack = new Date().getDate() < 15 ? 2 : 1;
  const [startDateSelection, updateStartDateSelection] = useState(startDateRange(monthsBack + 2));
  const [endDateSelection, updateEndDateSelection] = useState(startDateRange(monthsBack));
  const [dateOptions, updateDateOptions] = useState([{ value: "" }]);
  const [dateRange, updateDateRange] = useState({
    endDate: formatDate(endDateSelection, "end"),
    startDate: formatDate(startDateSelection, "start"),
  });

  useQuery(GET_MAX_MEDIA_MIX, { client: amcClient });

  let { data } = useQuery(GET_MEDIA_MIX, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
    skip: !amcInstanceId,
  });

  useEffect(() => {
    const filterData = (data, tableType, tableOrGraphData, dataType) => {
      return data.amc.mediaMixDataReport[tableType][tableOrGraphData].map(row =>
        row.mediaType !== "Grand Total" ? row[dataType] : null
      );
    };

    if (data) {
      const reach = filterData(data, "prospectiveShoppers", "tableData", "reach");
      const proPuchased = filterData(data, "prospectiveShoppers", "tableData", "purchased");
      const proPurchaseRate = filterData(data, "prospectiveShoppers", "tableData", "purchaseRate");
      const proOrderSize = filterData(data, "prospectiveShoppers", "tableData", "avgOrderSize");
      const NTBPuchased = filterData(data, "newToBrandShoppers", "tableData", "purchased");
      const NTBPurchaseRate = filterData(data, "newToBrandShoppers", "tableData", "purchaseRate");
      const NTBOrderSize = filterData(data, "newToBrandShoppers", "tableData", "avgOrderSize");

      maxReach(Math.max(...reach));
      maxProPurchased(Math.max(...proPuchased));
      maxProPurchaseRate(Math.max(...proPurchaseRate));
      maxProOrderSize(Math.max(...proOrderSize));
      maxNTBPurchased(Math.max(...NTBPuchased));
      maxNTBPurchaseRate(Math.max(...NTBPurchaseRate));
      maxNTBOrderSize(Math.max(...NTBOrderSize));
    }
  }, [data]);

  const formatTotalCells = data => {
    data.avgOrderSize = `${formatMoneyAsInt(data.avgOrderSize)}`;
    data.purchaseRate = `${data.purchaseRate}%`;
  };

  const bottomRenderer = ({ data, style, classes }): JSX.Element => {
    let newData;
    newData = typeof data === "string" ? data : abbreviateNumber(parseFloat(data));

    return (
      <div className={classes.join(" ")} style={style}>
        {newData}
      </div>
    );
  };

  // Cloned to make formatting changes for avgOrderSize and pruchaseRate
  const prospectivePinnedBottomRowData = R.clone(
    data?.amc?.mediaMixDataReport.prospectiveShoppers.tableData.filter(
      row => row.mediaType === "Grand Total"
    )[0]
  );
  if (prospectivePinnedBottomRowData) {
    formatTotalCells(prospectivePinnedBottomRowData);
  }

  const prospectiveTableData = data?.amc?.mediaMixDataReport.prospectiveShoppers.tableData.filter(
    row => row.mediaType !== "Grand Total"
  );

  // Cloned to make formatting changes for avgOrderSize and pruchaseRate
  const ntbPinnedBottomRowData = R.clone(
    data?.amc?.mediaMixDataReport.newToBrandShoppers.tableData.filter(
      row => row.mediaType === "Grand Total"
    )[0]
  );
  if (ntbPinnedBottomRowData) {
    formatTotalCells(ntbPinnedBottomRowData);
  }

  const ntbTableData = data?.amc?.mediaMixDataReport.newToBrandShoppers.tableData.filter(
    row => row.mediaType !== "Grand Total"
  );

  useEffect(() => {
    if (data) {
      const months = data?.amc.mediaMixDataReport.availableFilters.months;
      const options = months?.map(m => {
        return { value: m };
      });

      updateDateOptions(options);
    }
  }, [data]);

  return (
    <Page minHeight="600px" pageType="AMC Media Mix" title="AMC Media Mix">
      <div className="media-mix-page">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <div
            className="section-head"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              Which media mix is most effective in converting{" "}
              <b style={{ fontWeight: 500 }}>prospective shoppers into customers</b>?
            </div>
          </div>
          <MonthPicker
            dateOptions={dateOptions}
            endDateSelection={endDateSelection}
            startDateSelection={startDateSelection}
            updateDateRange={updateDateRange}
            updateEndDateSelection={updateEndDateSelection}
            updateStartDateSelection={updateStartDateSelection}
          />
        </div>
        <div className="basic-card" style={{ marginBottom: "30px" }}>
          <div className="table-wrapper">
            {data ? (
              <BPMTable
                data={prospectiveTableData}
                filterBar={false}
                headers={prospectiveTableHeaders}
                noRowsRenderer={() => <div className="noFailures">No failures to show</div>}
                totals={prospectivePinnedBottomRowData}
                totalsRenderer={bottomRenderer}
              />
            ) : (
              <FullPageSpinner />
            )}
          </div>
          <div className="graph-wrapper">
            <AreaAndBarChart
              areaDataKey="purchased"
              barDataKey="reach"
              data={data?.amc?.mediaMixDataReport.prospectiveShoppers.graphData}
              xAxisDataKey="date"
              barYAxisDataKey="reach"
              barYAxisLabel="Shoppers Reached"
              areaYAxisDataKey="purchased"
              areaYAxisLabel="Shoppers Purchased"
            />
          </div>
        </div>
        <div className="section-head">
          Which media mix is most effective in converting{" "}
          <b style={{ fontWeight: 500 }}>New-to-Brand shoppers into customers</b>?
        </div>

        <div className="basic-card">
          <div className="table-wrapper">
            {data ? (
              <BPMTable
                data={ntbTableData}
                filterBar={false}
                headers={ntbTableHeaders}
                noRowsRenderer={() => <div className="noFailures">No failures to show</div>}
                totals={ntbPinnedBottomRowData}
                totalsRenderer={bottomRenderer}
              />
            ) : (
              <FullPageSpinner />
            )}
          </div>
          <div className="graph-wrapper">
            <AreaAndBarChart
              areaDataKey="purchased"
              barDataKey="reach"
              data={data?.amc?.mediaMixDataReport.newToBrandShoppers.graphData}
              xAxisDataKey="date"
              barYAxisDataKey="reach"
              barYAxisLabel="Shoppers Reached"
              areaYAxisDataKey="purchased"
              areaYAxisLabel="Shoppers Purchased"
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

AMCMediaMix.propTypes = {};

export default AMCMediaMix;
