import React from "react";
import PaidMediaImpact from "./PaidMediaImpact";
import ModelInsights from "./ModelInsights";
import InvestToIncrease from "./InvestToIncrease";
import { RouteComponentProps } from "@reach/router";
import { Img } from "../../Components";
import Overview from "./Overview";
import "./BrandImpact.scss";
import {
  BrandImpactData,
  IncrementalRoasTakeaways,
} from "@blisspointmedia/bpm-types/dist/BrandEquity";
import { formatModelOverviewOutcomeVariable } from "../BrandEquityUtils";

interface BrandImpactProps {
  company: string;
  groupByMetric: string;
  groupByLevel: string;
  groupByMetricOptions: Array<{ label: string; value: string; s3Path: string }>;
  brandImpactData: BrandImpactData;
  incrementalRoasTakeAways: IncrementalRoasTakeaways;
}

const BrandImpact = ({
  company,
  groupByMetric,
  groupByLevel,
  brandImpactData,
  incrementalRoasTakeAways,
}: BrandImpactProps & RouteComponentProps): JSX.Element => {
  const {
    weeklyBrandHealthTimeSeries,
    modelOverview,
    weeklyBrandHealthAreaChartSpend,
    implicationsData,
    paidMediaAreaChart,
    paidMediaBarChart,
    spendAndEffectBarChart,
    nextBestDollarBarChart,
    saturationData,
    decayData,
  } = brandImpactData;

  return (
    <div>
      <div className="brandEquityHeader">
        <div className="brandEquityTextHeader">
          <div className="brandEquityTextHeaderTitle">
            A strong brand drives business outcomes and creates a tailwind for performance marketing
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Performance marketing generates results over days to weeks. Brand marketing operates
            over months to years, making unified assessment of the funnel challenging.
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Bliss Point Brand Health quantifies brand equity's impact on the business for a holistic
            understanding of ROAS.
          </div>
        </div>
        <div className="brandEquityHeaderImageContainer">
          <Img
            src="https://cdn.blisspointmedia.com/assets/img/BusinessImpactDemo/BrandEquity_Infographic.png"
            alt="brand health header"
          />
        </div>
      </div>
      {Object.keys(modelOverview).length > 0 &&
        weeklyBrandHealthTimeSeries.length > 0 &&
        Object.keys(weeklyBrandHealthAreaChartSpend).length > 0 && (
          <Overview
            company={company}
            groupByMetric={groupByMetric}
            groupByLevel={groupByLevel}
            weeklyBrandHealthTimeSeries={weeklyBrandHealthTimeSeries}
            weeklyBrandHealthAreaChartSpend={weeklyBrandHealthAreaChartSpend}
            modelOverviewAll={modelOverview}
            incrementalRoasTakeaways={incrementalRoasTakeAways}
            isGqv={"Gqv" in weeklyBrandHealthTimeSeries[0]}
          />
        )}
      {Object.keys(implicationsData).length > 0 && (
        <PaidMediaImpact
          company={company}
          groupByMetric={formatModelOverviewOutcomeVariable(groupByMetric)}
          paidMediaImpactAreaChart={paidMediaAreaChart[groupByMetric]}
          paidMediaImpactBarChart={paidMediaBarChart}
          implicationsData={implicationsData}
          isGqv={"Gqv" in weeklyBrandHealthTimeSeries[0]}
        />
      )}
      {Object.keys(nextBestDollarBarChart).length > 0 &&
        Object.keys(spendAndEffectBarChart).length > 0 && (
          <InvestToIncrease
            company={company}
            groupByMetric={formatModelOverviewOutcomeVariable(groupByMetric)}
            unGroupedNextBestThousandDollars={nextBestDollarBarChart[groupByMetric]}
            unGroupedSpendAndEffect={spendAndEffectBarChart[groupByMetric]}
            groupByLevel={groupByLevel}
          />
        )}
      {Object.keys(decayData).length > 0 && Object.keys(saturationData).length > 0 && (
        <ModelInsights
          company={company}
          groupByMetric={groupByMetric}
          saturationDataUnformatted={saturationData[groupByMetric]}
          decayDataUnformmatted={decayData[groupByMetric]}
          groupByLevel={groupByLevel}
        />
      )}
    </div>
  );
};

export default BrandImpact;
