import "./CreativeMetadataInputCard.scss";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";
import { Img, Card, OldDropdown } from "../Components";
import React from "react";

interface CreativeProp {
  isci: string;
  name: string;
  length: number;
}

interface CreativeMetadataInputCardProps {
  company: string;
  creative: CreativeProp;
  clickthroughUrl: string;
  onClickthroughUrlInput: (isci: string, value: string) => void;
  audioCompanionOptionsList: { label: string; value: string }[];
  audioCompanion: string;
  onAudioCompanionSelect: (isci: string, value: string) => void;
}

export const CreativeMetadataInputCard = ({
  company,
  creative,
  clickthroughUrl,
  onClickthroughUrlInput,
  audioCompanionOptionsList,
  audioCompanion,
  onAudioCompanionSelect,
}: CreativeMetadataInputCardProps): JSX.Element => {
  return (
    <Card className="creativeDiv">
      <div className="creativeContent">
        <div className="creativeImgBox">
          <Img
            src={getCreativeThumbnail(company, creative.isci)}
            className="creativeImg"
            unloader={<div className="noImg">Thumbnail not found</div>}
          />
        </div>
        <div className="isci">{creative.isci}</div>
        <div className="creativeName">{`${creative.name} (${creative.length}s)`}</div>
      </div>
      <div className="inputContainer">
        <div className="inputBoxLabel">Click-Through URL:</div>
        <input
          className="inputBox"
          value={clickthroughUrl}
          onChange={e => onClickthroughUrlInput(creative.isci, e.target.value)}
        ></input>
        {Boolean(audioCompanionOptionsList.length) && (
          <div className="inputContainer">
            <div className="dropdownLabel">Audio Companion Banner:</div>
            <OldDropdown
              searchable
              className="companionDropdown"
              size="sm"
              placeholder="Select Companion..."
              value={audioCompanion || ""}
              options={audioCompanionOptionsList}
              onChange={newVal => onAudioCompanionSelect(creative.isci, newVal)}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
