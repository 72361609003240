import React, { useContext, useEffect, useMemo, useState } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { MdExpandLess, MdExpandMore } from "react-icons/md";

import {
  PerformancePreset as StreamingPreset,
  CostPerPathRow as StreamingCostPerPathRow,
  CostPerPathLambdaArgs as StreamingCostPerPathLambdaArgs,
  Prefix as StreamingPrefix,
  OverviewConfig as StreamingOverviewConfig,
} from "@blisspointmedia/bpm-types/dist/StreamingPerformance";

import {
  Preset as LinearPreset,
  CostPerPathRow as LinearCostPerPathRow,
  OverviewConfig as LinearOverviewConfig,
} from "@blisspointmedia/bpm-types/dist/LinearPerformance";

import { Preset as YoutubePreset } from "@blisspointmedia/bpm-types/dist/YoutubePerformance";

import { CostPerPathLambdaArgs } from "@blisspointmedia/bpm-types/dist/Performance";

import { RectSkeleton, Skeleton } from "../Components";

import "./OverviewMetrics.scss";

import {
  toPrettyNumber,
  toPrettySpend,
  PerformanceContext,
  ColumnMetaData,
} from "./performanceUtils";

import { getColumnMetadataMap as getStreamingColumnMetadataMap } from "./StreamingPerformance/streamingPerformanceUtils";
import { COLUMN_METADATA_MAP as LINEAR_COLUMN_METADATA_MAP } from "./LinearPerformance/linearPerformanceUtils";
import { COLUMN_METADATA_MAP as YOUTUBE_COLUMN_METADATA_MAP } from "./YouTubePerformance/youtubePerformanceUtils";

import {
  awaitJSON,
  LinearPerformanceLambdaFetch,
  StreamingPerformanceLambdaFetch,
} from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import useLocation from "../utils/hooks/useLocation";
import { ProcessedToken } from "@blisspointmedia/bpm-types/dist/advancedFilterParser";
import { CostPerPathChart as StreamingCostPerPathChart } from "./StreamingPerformance/CostPerPathChart";
import { CostPerPathChart as LinearCostPerPathChart } from "./LinearPerformance/CostPerPathChart";
import { LiftFromTvChart } from "./LinearPerformance/LiftFromTvChart";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import ConversionLatencyHistogram from "./StreamingPerformance/ConversionLatencyHistogram";
import { overrideDateRange } from "../utils/test-account-utils";

const OverviewSkeleton: React.FC = () => (
  <div className="overviewMetrics skeleton">
    <Skeleton redesign>
      <RectSkeleton />
    </Skeleton>
  </div>
);

interface OverviewSectionItemProps {
  label: string;
}
const OverviewSectionItem: React.FC<OverviewSectionItemProps> = ({ label, children }) => (
  <div className="overviewSectionItem">
    <div className="label">{label}</div>
    <div className="value">{children}</div>
  </div>
);

export interface OverviewData {
  imps: number;
  spend: number;
  ecpm: number;
  lastUpdated: string;
  directConversions?: number;
  fractionalConversions?: number;
  avgDirectRevenue?: number;
  totalDirectRevenue?: number;

  linear?: {
    spots: number;
    directCostPers: number;
    fractionalCostPers: number;
  };

  youtube?: {
    conversions: number;
    cpa: number;
    cpm: number;
    revenue: number;
    roas: number;
  };

  streaming?: {
    residentialDelivRate: number;
    residentialRespRate: number;
    avgFractionalRevenue?: number;
    totalFractionalRevenue?: number;
    haloConversions?: number;
    fullFunnelConversions?: number;
    incrementalConversions?: number;
    avgHaloRevenue?: number;
    totalHaloRevenue?: number;
    avgFullFunnelRevenue?: number;
    totalFullFunnelRevenue?: number;
    avgIncrementalRevenue?: number;
    totalIncrementalRevenue?: number;
  };
}

interface OverviewMetricsProps {
  data?: OverviewData;
  end: string;
  filterTokens?: ProcessedToken[];
  isLinear?: boolean;
  isYoutube?: boolean;
  maxCpxValue?: number;
  preset?: StreamingPreset | LinearPreset | YoutubePreset;
  presetID?: number;
  start: string;
  isTransunionGraph?: boolean;
}

export const OverviewMetrics: React.FC<OverviewMetricsProps> = ({
  children,
  data,
  end,
  filterTokens,
  isLinear,
  isYoutube,
  maxCpxValue,
  preset,
  presetID,
  start,
  isTransunionGraph = false,
}) => {
  const setError = useSetError();
  const { company } = useLocation();
  const getIsMounted = useIsMounted();

  const { kpiMetaData, globalKpi: kpi, prefix, globalBrand, branchBuild, isGraph } = useContext(
    PerformanceContext
  );

  const columnMetaDataMap: Record<string, ColumnMetaData | undefined> = useMemo(() => {
    return isYoutube
      ? YOUTUBE_COLUMN_METADATA_MAP
      : isLinear
      ? LINEAR_COLUMN_METADATA_MAP
      : getStreamingColumnMetadataMap(isGraph, company);
  }, [isGraph, isLinear, isYoutube, company]);

  const isStreamingPreset = (preset as StreamingPreset)?.impsBetween !== undefined;

  const build = useMemo(() => {
    if (data?.streaming && branchBuild) {
      let parts = branchBuild.split("/");
      return parts[parts.length - 1];
    }
  }, [branchBuild, data?.streaming]);

  const [expanded, setExpanded] = useState(true);

  const lastUpdated = useMemo(() => {
    if (!data) {
      return "";
    }
    // The date might be "Unknown", or something else that's not parsable
    try {
      return `${R.pipe(
        Dfns.parseISO,
        Dfns.format("M/d/yyyy h:mm:ss a")
      )(`${data.lastUpdated}Z`)} (your time)`;
    } catch (e) {
      return data.lastUpdated;
    }
  }, [data]);

  const [cppDataMap, setCPPDataMap] = useState<
    Record<string, StreamingCostPerPathRow[] | LinearCostPerPathRow[] | undefined>
  >({});

  const cppKey = `${presetID}_${kpi}_${build}_${start}_${end}_${
    (preset as StreamingPreset)?.impsBetween
  }_${globalBrand || ""}`;

  const [liftFromTvDataMap, setLiftFromTvDataMap] = useState({});
  const liftFromTvKey = `${kpi}_${start}_${end}`;

  const dateRangeToUse = overrideDateRange(company, { start, end });
  const startDateToUse = dateRangeToUse?.start || "";
  const endDateToUse = dateRangeToUse?.end || "start";

  useEffect(() => {
    if (data?.streaming && preset && isStreamingPreset && !cppDataMap[cppKey]) {
      (async () => {
        try {
          let res = await StreamingPerformanceLambdaFetch<StreamingCostPerPathLambdaArgs>(
            isGraph ? "/cost_per_path_graph" : "/cost_per_path_ip",
            {
              method: "POST",
              body: {
                company: globalBrand || company,
                kpiId: kpi,
                startDate: startDateToUse,
                endDate: endDateToUse,
                impressionCountSelector: (preset as StreamingPreset).impsBetween
                  ? "Imp's Served Between"
                  : "Response Between",
                prefix: prefix as StreamingPrefix,
                build: build || "latest",
                parsedFilterTokens: filterTokens,
                isTransunionGraph: isTransunionGraph,
              },
            }
          );
          let rows = await awaitJSON<StreamingCostPerPathRow[]>(res);
          if (getIsMounted()) {
            setCPPDataMap(map => ({ ...map, [cppKey]: rows }));
          }
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: reportError.message,
            reportError,
          });
        }
      })();
    }
  }, [
    preset,
    cppDataMap,
    cppKey,
    company,
    start,
    end,
    prefix,
    build,
    filterTokens,
    setError,
    kpi,
    getIsMounted,
    globalBrand,
    isStreamingPreset,
    data?.streaming,
    isGraph,
    startDateToUse,
    endDateToUse,
    isTransunionGraph,
  ]);

  useEffect(() => {
    if (data?.linear && !cppDataMap[cppKey] && maxCpxValue) {
      (async () => {
        try {
          let res = await LinearPerformanceLambdaFetch<CostPerPathLambdaArgs>("/getCostPerPath", {
            method: "GET",
            params: {
              kpiId: kpi,
              startDate: startDateToUse,
              endDate: endDateToUse,
            },
          });
          let rows = await awaitJSON<LinearCostPerPathRow[]>(res);
          // Cap values at the specified max value.
          rows.forEach(row => {
            row.val = R.min(row.val, maxCpxValue);
          });
          if (getIsMounted()) {
            setCPPDataMap(map => ({ ...map, [cppKey]: rows }));
          }
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: reportError.message,
            reportError,
          });
        }
      })();
    }
  }, [
    cppDataMap,
    cppKey,
    start,
    end,
    setError,
    kpi,
    data?.linear,
    getIsMounted,
    maxCpxValue,
    startDateToUse,
    endDateToUse,
  ]);

  useEffect(() => {
    if (data?.linear && !liftFromTvDataMap[liftFromTvKey]) {
      (async () => {
        try {
          const liftFromTvRes = await LinearPerformanceLambdaFetch("/getLiftFromTvChart", {
            method: "GET",
            params: {
              kpi,
              start: startDateToUse,
              end: endDateToUse,
            },
          });
          const liftFromTvRows = await awaitJSON(liftFromTvRes);
          setLiftFromTvDataMap(map => ({ ...map, [liftFromTvKey]: liftFromTvRows }));
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: reportError.message,
            reportError,
          });
        }
      })();
    }
  }, [data?.linear, startDateToUse, endDateToUse, kpi, liftFromTvDataMap, liftFromTvKey, setError]);

  const cppData = cppDataMap[cppKey];
  const liftFromTvData = liftFromTvDataMap[liftFromTvKey];

  const hasSectionMap = useMemo(() => {
    let config: Required<StreamingOverviewConfig & LinearOverviewConfig> = {
      spendInfo: true,
      conversions: true,
      revenue: true,
      roas: true,
      deliveryRates: true,
      cppChart: true,
      conversionChart: true,
      volumePathChart: false,
      hideFractional: false,
      ...(preset?.overviewConfig || {}),
    };

    /*
      On the Linear side, revenue data comes from a separate lambda than the rest of the page data,
      and depending on the KPI we don't always have any data (a file in S3). In LinearPerformance.tsx
      we set the revenue numbers to -1 if they don't exist (to differentiate from an valid 0 values).
      If there's no data, don't display the Revenue or ROAS sections.
    */
    if (data?.avgDirectRevenue === -1) {
      config.revenue = false;
      config.roas = false;
    }

    return config;
  }, [preset, data]);

  return data && kpiMetaData && kpi && kpiMetaData[kpi] ? (
    <div className="overviewMetrics">
      <div className="headerBar" onClick={() => setExpanded(R.not)}>
        <div className="header">
          <div className="title">Overview Metrics</div>
          {children}
        </div>
        <div className="expandIcon">{expanded ? <MdExpandLess /> : <MdExpandMore />}</div>
      </div>

      {expanded && (
        <div className="overviewBody">
          <div className="lastUpdated">
            <strong>Last Updated:</strong> {lastUpdated}
          </div>

          {hasSectionMap.spendInfo && (
            <div className="section orderedDelivery">
              <OverviewSectionItem label="Impressions Served">
                {toPrettyNumber(data.imps)}
              </OverviewSectionItem>
              <OverviewSectionItem label="Spend">{toPrettySpend(data.spend)}</OverviewSectionItem>
              {isYoutube ? (
                <OverviewSectionItem label="CPM">
                  {toPrettySpend(data.youtube && data.youtube.cpm ? data.youtube.cpm : 0, 2)}
                </OverviewSectionItem>
              ) : (
                <OverviewSectionItem label="eCPM">
                  {toPrettySpend(data.ecpm, 2)}
                </OverviewSectionItem>
              )}
              {data.linear && !R.isNil(data.linear.spots) && (
                <OverviewSectionItem label="Spots">
                  {toPrettyNumber(data.linear.spots)}
                </OverviewSectionItem>
              )}
            </div>
          )}
          {hasSectionMap.conversions && (
            <div className="section conversions boxcolumn">
              <div className="sectionHeader">
                {isYoutube ? "Conversions" : "Conversions / Imps"}
              </div>
              <div className="sectionBody">
                {isYoutube ? (
                  <div>
                    <OverviewSectionItem label="Direct">
                      {toPrettyNumber(
                        data.youtube && data.youtube.conversions ? data.youtube.conversions : 0,
                        (data.youtube && data.youtube.conversions ? data.youtube.conversions : 0) >
                          100
                          ? 0
                          : 2
                      )}
                    </OverviewSectionItem>
                    <div className="conversionsPer">per 1K imps.</div>
                  </div>
                ) : (
                  !isGraph && (
                    <div>
                      <OverviewSectionItem label="Direct">
                        {toPrettyNumber(
                          data.directConversions || 0,
                          (data.directConversions || 0) > 100 ? 0 : 2
                        )}
                      </OverviewSectionItem>
                      <div className="conversionsPer">per 1K imps.</div>
                    </div>
                  )
                )}
                {isYoutube && (
                  <div>
                    <OverviewSectionItem label="CPA">
                      {toPrettySpend(
                        data && data.youtube && data.youtube.cpa ? data.youtube.cpa : 0,
                        2
                      )}
                    </OverviewSectionItem>
                  </div>
                )}
                {!isYoutube && !isGraph && !hasSectionMap.hideFractional && (
                  <div>
                    <OverviewSectionItem label="+Fractional">
                      {toPrettyNumber(
                        data.fractionalConversions || 0,
                        (data.fractionalConversions || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                    <div className="conversionsPer">per 1K imps.</div>
                  </div>
                )}
                {!isGraph && data.streaming && !R.isNil(data.streaming.haloConversions) && (
                  <div>
                    <OverviewSectionItem label="+Halo">
                      {toPrettyNumber(
                        data.streaming.haloConversions,
                        data.streaming.haloConversions > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                    <div className="conversionsPer">per 1K imps.</div>
                  </div>
                )}
                {isGraph && data.streaming && (
                  <div>
                    <OverviewSectionItem label="Full Funnel">
                      {toPrettyNumber(
                        data.streaming.fullFunnelConversions || 0,
                        (data.streaming.fullFunnelConversions || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                    <div className="conversionsPer">per 1K imps.</div>
                  </div>
                )}
                {isGraph && data.streaming && (
                  <div>
                    <OverviewSectionItem label="Incremental">
                      {toPrettyNumber(
                        data.streaming.incrementalConversions || 0,
                        (data.streaming.incrementalConversions || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                    <div className="conversionsPer">per 1K imps.</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {hasSectionMap.revenue && (
            <div className="section boxcolumn">
              <div className="sectionHeader">Revenue</div>
              <div className="sectionBody">
                {isYoutube ? (
                  <div>
                    <OverviewSectionItem label="Direct">
                      {toPrettySpend(
                        data.youtube?.revenue || 0,
                        (data.youtube?.revenue || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                  </div>
                ) : (
                  !isGraph && (
                    <div>
                      <OverviewSectionItem label="Avg. Direct">
                        {toPrettySpend(
                          data.avgDirectRevenue || 0,
                          (data.avgDirectRevenue || 0) > 100 ? 0 : 2
                        )}
                      </OverviewSectionItem>
                      <OverviewSectionItem label="Total Direct">
                        {toPrettySpend(
                          data.totalDirectRevenue || 0,
                          (data.totalDirectRevenue || 0) > 100 ? 0 : 2
                        )}
                      </OverviewSectionItem>
                    </div>
                  )
                )}
                {!isGraph &&
                  data.streaming &&
                  (!R.isNil(data.streaming.avgHaloRevenue) ||
                    !R.isNil(data.streaming.totalHaloRevenue)) && (
                    <div>
                      {!R.isNil(data.streaming.avgHaloRevenue) && (
                        <OverviewSectionItem label="Avg. +Halo">
                          {toPrettySpend(
                            data.streaming.avgHaloRevenue,
                            columnMetaDataMap?.avgRevenueHalo?.decimals ||
                              (data.streaming.avgHaloRevenue > 100 ? 0 : 2)
                          )}
                        </OverviewSectionItem>
                      )}
                      {!R.isNil(data.streaming.totalHaloRevenue) && (
                        <OverviewSectionItem label="Total +Halo">
                          {toPrettySpend(
                            data.streaming.totalHaloRevenue,
                            columnMetaDataMap?.revenueHalo?.decimals ||
                              (data.streaming.totalHaloRevenue > 100 ? 0 : 2)
                          )}
                        </OverviewSectionItem>
                      )}
                    </div>
                  )}
                {!isGraph && data.streaming && !hasSectionMap.hideFractional && (
                  <div>
                    <OverviewSectionItem label="Avg. +Frac">
                      {toPrettySpend(
                        data.streaming.avgFractionalRevenue || 0,
                        columnMetaDataMap?.avgRevenueFractional?.decimals ||
                          ((data.streaming.avgFractionalRevenue || 0) > 100 ? 0 : 2)
                      )}
                    </OverviewSectionItem>
                    <OverviewSectionItem label="Total +Frac">
                      {toPrettySpend(
                        data.streaming.totalFractionalRevenue || 0,
                        columnMetaDataMap?.revenueHalo?.decimals ||
                          ((data.streaming.totalFractionalRevenue || 0) > 100 ? 0 : 2)
                      )}
                    </OverviewSectionItem>
                  </div>
                )}
                {isGraph && data.streaming && (
                  <div>
                    <OverviewSectionItem label="Avg. Full Funnel">
                      {toPrettySpend(
                        data.streaming.avgFullFunnelRevenue || 0,
                        (data.streaming.avgFullFunnelRevenue || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                    <OverviewSectionItem label="Total Full Funnel">
                      {toPrettySpend(
                        data.streaming.totalFullFunnelRevenue || 0,
                        (data.streaming.totalFullFunnelRevenue || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                  </div>
                )}
                {isGraph && data.streaming && (
                  <div>
                    <OverviewSectionItem label="Avg. Incremental">
                      {toPrettySpend(
                        data.streaming.avgIncrementalRevenue || 0,
                        (data.streaming.avgIncrementalRevenue || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                    <OverviewSectionItem label="Total Incremental">
                      {toPrettySpend(
                        data.streaming.totalIncrementalRevenue || 0,
                        (data.streaming.totalIncrementalRevenue || 0) > 100 ? 0 : 2
                      )}
                    </OverviewSectionItem>
                  </div>
                )}
              </div>
            </div>
          )}

          {!data.streaming && hasSectionMap.roas && (
            <div className="section boxcolumn">
              <div className="sectionHeader">ROAS</div>
              <div className="sectionBody">
                <div>
                  {isYoutube ? (
                    <OverviewSectionItem label="Direct">
                      {company === "credible"
                        ? toPrettyNumber(
                            data.youtube && data.youtube.revenue
                              ? (data.spend - data.youtube.revenue) / data.youtube.revenue
                              : 0,
                            2
                          )
                        : data.youtube && data.youtube.revenue
                        ? toPrettyNumber((data.youtube.revenue || 0) / data.spend, 2)
                        : 0}
                    </OverviewSectionItem>
                  ) : (
                    <OverviewSectionItem label="Direct">
                      {company === "credible"
                        ? toPrettyNumber(
                            data.totalDirectRevenue
                              ? (data.spend - data.totalDirectRevenue) / data.totalDirectRevenue
                              : 0,
                            2
                          )
                        : toPrettyNumber((data.totalDirectRevenue || 0) / data.spend, 2)}
                    </OverviewSectionItem>
                  )}
                </div>
              </div>
            </div>
          )}

          {data.streaming && hasSectionMap.deliveryRates && (
            <div className="section boxcolumn">
              <div className="sectionHeader">{isGraph ? "Matchable Rates" : "Delivery"}</div>
              <div className="sectionBody">
                <div>
                  <OverviewSectionItem label={isGraph ? "Delivery Rate" : "Res. Del. Rate"}>
                    {toPrettyNumber(data.streaming.residentialDelivRate * 100, 1)}%
                  </OverviewSectionItem>
                </div>
                <div>
                  <OverviewSectionItem label={isGraph ? "Response Rate" : "Res. Resp. Rate"}>
                    {toPrettyNumber(data.streaming.residentialRespRate * 100, 1)}%
                  </OverviewSectionItem>
                </div>
              </div>
            </div>
          )}
          <div className="overviewChartContainer">
            {data?.streaming && hasSectionMap.cppChart && preset && (
              <StreamingCostPerPathChart
                data={cppData as StreamingCostPerPathRow[]}
                label={`${isGraph ? "i" : ""}${kpiMetaData[kpi].cpxName || "CPX"} Path`}
                decimals={R.isNil(kpiMetaData[kpi].decimals) ? 2 : kpiMetaData[kpi].decimals}
              />
            )}
            {data?.streaming && hasSectionMap.volumePathChart && preset && (
              <StreamingCostPerPathChart
                volume
                data={cppData as StreamingCostPerPathRow[]}
                label="Volume Path"
              />
            )}
            {data?.linear && hasSectionMap.cppChart && (
              <LinearCostPerPathChart
                data={cppData as LinearCostPerPathRow[]}
                label={`${kpiMetaData[kpi].cpxName || "CPX"} Path`}
                decimals={R.isNil(kpiMetaData[kpi].decimals) ? 2 : kpiMetaData[kpi].decimals}
              />
            )}
            {data?.linear && <LiftFromTvChart data={liftFromTvData} />}
            {isStreamingPreset && hasSectionMap.conversionChart && (
              <ConversionLatencyHistogram
                presetID={presetID}
                kpi={kpi}
                start={start}
                end={end}
                preset={preset as StreamingPreset}
                prefix={prefix as StreamingPrefix}
              />
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <OverviewSkeleton />
  );
};
