import "./IncrementalRoas.scss";
import React, { useCallback, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import ComposedChart from "../../Components/Charts/ComposedChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import { DownloadDropdown, Dropdown, DropdownToggleType } from "../../Components";
import { currFormat, percFormat } from "../BrandImpact/BrandImpactUtils";
import { MdArrowForward } from "react-icons/md";
import {
  WeeklyIncrementalRoas,
  IncrementalRoasTakeaways,
} from "@blisspointmedia/bpm-types/dist/BrandEquity";
import {
  DATE_GROUPING_OPTIONS_BRAND_EQUITY,
  formatTitleDatesToStartOfDayMddy,
  groupChartDataByDate,
} from "../BrandEquityUtils";

interface IncrementalRoasProps {
  company: string;
  weeklyIncrementalRoas: WeeklyIncrementalRoas[];
  incrementalRoasTakeaways: IncrementalRoasTakeaways;
}

export const IncrementalRoas: React.FC<IncrementalRoasProps> = ({
  weeklyIncrementalRoas,
  incrementalRoasTakeaways,
}) => {
  const [
    weeklyIncrementalRoasChartDateGrouping,
    setWeeklyIncrementalRoasChartDateGrouping,
  ] = useState("Week");

  const { res: weeklyIncrementalRoasByDate } = useMemo(() => {
    if (weeklyIncrementalRoas.length > 0) {
      const groupByDateGqvData = groupChartDataByDate(
        weeklyIncrementalRoas,
        weeklyIncrementalRoasChartDateGrouping
      );
      return { res: groupByDateGqvData };
    }
    return { res: [] };
  }, [weeklyIncrementalRoasChartDateGrouping, weeklyIncrementalRoas]);

  const excelDownloadWeeklyIncrementalRoas = useCallback(() => {
    exportToExcel(weeklyIncrementalRoas, "weekly_incremental_roas");
  }, [weeklyIncrementalRoas]);

  const pngDownloadWeeklyIncrementalRoas = useCallback(async () => {
    await downloadPNG(".left .leftLeft", "weekly_incremental_roas");
  }, []);

  return (
    <WidgetContainer
      collapsible
      header="What is our incremental ROAS from media-driven brand equity?"
      subHeader="Brand media investments' contribution to the bottom line."
    >
      <div className="left">
        <div className="leftLeft">
          {!R.isEmpty(weeklyIncrementalRoas) && (
            <ChartContainer
              enableHoverDesign
              rightActions={
                <div className="incrementalRoasRightActions">
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[
                      excelDownloadWeeklyIncrementalRoas,
                      pngDownloadWeeklyIncrementalRoas,
                    ]}
                  />
                </div>
              }
              title={
                <>
                  <Dropdown
                    type={DropdownToggleType.WIDGET_TITLE}
                    value={weeklyIncrementalRoasChartDateGrouping}
                    options={DATE_GROUPING_OPTIONS_BRAND_EQUITY}
                    onChange={option => setWeeklyIncrementalRoasChartDateGrouping(option)}
                  />
                  <div>
                    Incremental ROAS
                    <span style={{ color: "#1F003F", fontWeight: 500, fontSize: "1rem" }}>
                      {" (Revenue From Media-Driven Brand Equity / Spend)"}
                    </span>
                  </div>
                </>
              }
              titleAfterDashText={`${formatTitleDatesToStartOfDayMddy(
                weeklyIncrementalRoas[0].date
              )} – ${formatTitleDatesToStartOfDayMddy(
                weeklyIncrementalRoas[weeklyIncrementalRoas.length - 1].date
              )}`}
              beforeTooltipText="Incremental ROAS"
              rightSideText="Revenue"
            >
              <ComposedChart
                data={weeklyIncrementalRoasByDate}
                xAxisDataKey="date"
                xAxisTickFormatter={val => formatTitleDatesToStartOfDayMddy(val)}
                dateGrouping="Week"
                areaYAxisTickFormatter={value => `${currFormat(value, 0)}`}
                yAxisWidth={150}
                colorOverrides={{
                  "Revenue From Media-Driven Brand Equity": "#6B2DEF",
                  IncrementalRoas: "#79DFF5",
                }}
                areas={[
                  {
                    name: "Incremental ROAS",
                    dataKey: "IncrementalRoas",
                    line: true,
                  },
                ]}
                tooltipFormatter={val => {
                  if (!val) {
                    return val;
                  } else if (val <= 100) {
                    return `${percFormat(val, 2)}`;
                  } else {
                    return `${currFormat(val, 2)}`;
                  }
                }}
                reverseToolTipItems={true}
                reverseLegend={true}
                areaYAxisOrientation="right"
              ></ComposedChart>
            </ChartContainer>
          )}
        </div>
        <div className="leftRight">
          <ChartContainer title="Takeaways" titleAfterDashText="TTM" enableHoverDesign>
            <div className="parentIncrementalTakeaways">
              <div className="topIncremental">
                <div className="topTextBox">
                  <div></div>
                  <div className="topText">Spend </div>
                  <div className="topText">
                    {`$${(incrementalRoasTakeaways.paidMediaSpend / 1000000).toLocaleString(
                      "en-US",
                      {
                        maximumFractionDigits: 2,
                      }
                    )}M`}
                  </div>
                </div>
                <div className="iconHolder">
                  <MdArrowForward className="icon"></MdArrowForward>
                </div>
                <div className="topTextBox">
                  <div></div>
                  <div className="topText">Brand</div>
                  <div className="topText">Equity</div>
                </div>
                <div className="iconHolder">
                  <MdArrowForward className="icon"></MdArrowForward>
                </div>
                <div className="topTextBox">
                  <div>Incremental</div>
                  <div className="topText">Revenue</div>
                  <div className="topText">{`~$${(
                    incrementalRoasTakeaways.spendInducedBrandRevenue / 1000000
                  ).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}M`}</div>
                </div>
              </div>
              <div className="middleIncremental">
                <div className="middleText">{`Incremental ROAS = ${incrementalRoasTakeaways.iRoasTtm.toLocaleString(
                  "en-US",
                  {
                    maximumFractionDigits: 2,
                  }
                )}`}</div>
              </div>
              <div className="bottomIncremental">
                <div className="bottomText">
                  {`We estimate paid media-driven brand equity led to an incremental ROAS of ${incrementalRoasTakeaways.iRoasTtm.toLocaleString(
                    "en-US",
                    {
                      maximumFractionDigits: 2,
                    }
                  )} over  the trailing twelve months.`}
                </div>
              </div>
            </div>
          </ChartContainer>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default IncrementalRoas;
