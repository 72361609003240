import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import { Card, InfoTooltip, Spinner } from "../../Components";
import { Button, Form, InputGroup, Modal, ListGroup } from "react-bootstrap";
import useLocation from "../../utils/hooks/useLocation";
import { CommerceToolProps } from "../CommerceTool";
import { getSkaiIdMappings, createSkaiIdMapping, SkaiMapping } from "../CommerceToolUtils";
import "../CommerceTool.scss";

const SkaiIdMapping: React.FC<CommerceToolProps> = ({ utils }) => {
  const { company } = useLocation();
  const { setError, setModalMessage, setShowModalConfirmation } = utils;

  const [loading, setLoading] = useState<boolean>(false);
  const [validProfileName, setValidProfileName] = useState<boolean>(true);
  const [validProfileId, setValidProfileId] = useState<boolean>(true);

  const [profileId, setProfileId] = useState<string>("");
  const [profileName, setProfileName] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const [mappings, setMappings] = useState<SkaiMapping[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (R.isEmpty(mappings)) {
        const skaiMappings = await getSkaiIdMappings(company);
        setMappings(skaiMappings);
      }
    };
    fetchData();
  }, [company, mappings]);

  const handleSelectMapping = (selectedMapping: SkaiMapping) => {
    setProfileId(selectedMapping.profileId);
    setProfileName(selectedMapping.profileName);
    setComments(selectedMapping.comments || "");
    setNotes(selectedMapping.notes || "");
    setShowModal(false);
  };

  const createMapping = useCallback(async () => {
    const profileNameEmpty = R.isEmpty(profileName);
    const profileIdEmpty = R.isEmpty(profileId);

    setValidProfileName(!profileNameEmpty);
    setValidProfileId(!profileIdEmpty);

    if (profileNameEmpty || profileIdEmpty) {
      return;
    }

    setLoading(true);

    try {
      const mapping = R.pickBy(R.complement(R.isEmpty), {
        company,
        profileId,
        profileName,
        comments,
        notes,
      }) as SkaiMapping;

      await createSkaiIdMapping(mapping);
      setModalMessage("Skai Mapping Created Successfully");
      setShowModalConfirmation(true);
    } catch (e) {
      setError({
        message: (e as Error).message,
        reportError: e as { message: string },
      });
    } finally {
      setLoading(false);
    }
  }, [comments, company, notes, profileId, profileName, setError, setModalMessage, setShowModalConfirmation]);

  const filteredMappings = mappings.filter((map) =>
    map.profileName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Card className="commerceToolCard">
      <div className="commerceToolCardTitle">
        Add Skai ID Mapping
        <InfoTooltip size="reg">
          Please input the Skai Profile ID and Profile Name for the company you are mapping. This ID can be found in the
          Skai UI. To modify an existing mapping, select the mapping from the list and modify the fields.
        </InfoTooltip>
      </div>

      {/* Company ID */}
      <InputGroup size="sm" className="commerceToolElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company ID</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={company} disabled />
      </InputGroup>

      {/* Skai Profile Name Input with Select Button */}
      <InputGroup size="sm" className="commerceToolElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Skai Profile Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          placeholder="Skai Profile Name"
          value={profileName || ""}
          onChange={(e) => setProfileName(e.target.value)}
          isInvalid={!validProfileName}
        />
        <InputGroup.Append>
          <Button className="linearcommerceToolElem" onClick={() => setShowModal(true)}>
            Select Existing Mapping
          </Button>
        </InputGroup.Append>
        <Form.Control.Feedback type="invalid">Profile Name is required.</Form.Control.Feedback>
      </InputGroup>

      {/* Profile ID */}
      <InputGroup size="sm" className="commerceToolElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Skai Profile ID</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={profileId || ""}
          placeholder="Skai Profile ID"
          onChange={(e) => setProfileId(e.target.value)}
          isInvalid={!validProfileId}
        />
        <Form.Control.Feedback type="invalid">Profile ID is required.</Form.Control.Feedback>
      </InputGroup>

      {/* Comments */}
      <InputGroup size="sm" className="commerceToolElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Comments</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={comments || ""}
          placeholder="Comments"
          onChange={(e) => setComments(e.target.value)}
        />
      </InputGroup>

      {/* Notes */}
      <InputGroup size="sm" className="commerceToolElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Notes</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={notes || ""}
          placeholder="Notes"
          onChange={(e) => setNotes(e.target.value)}
        />
      </InputGroup>

      {/* Submit Button */}
      <Button className="linearcommerceToolElem" onClick={createMapping}>
        {loading ? <Spinner color="white" /> : "Save"}
      </Button>

      {/* Modal for Selecting Existing Mapping */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Existing Skai Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Search by Profile Name"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <ListGroup className="skaiMappingList">
            {filteredMappings.map((map, index) => (
              <ListGroup.Item key={index} action onClick={() => handleSelectMapping(map)}>
                Profile ID: {map.profileId}, Name: {map.profileName}
              </ListGroup.Item>
            ))}
            {filteredMappings.length === 0 && <div className="text-center text-muted">No results found</div>}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default SkaiIdMapping;
