import React, { useState, useEffect } from "react";
import { Response, Selection } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../utils/hooks/useLocation";
import QuestionCard, {
  QuestionCardProps,
  NestedValues,
} from "../Components/QuestionCard/QuestionCard";
import "./ClientInfo.scss";

interface QuestionOnlyFormProps {
  responses: Response[];
  questions: QuestionCardProps[];
  onChange: (updatedResponses: Response[]) => void;
}

const QuestionOnlyForm: React.FC<QuestionOnlyFormProps> = ({ responses, questions, onChange }) => {
  const [currentResponses, setCurrentResponses] = useState<any[]>(responses || []);
  const { company } = useLocation();

  useEffect(() => {
    setCurrentResponses(responses);
  }, [responses]);

  const handleChange = (updatedResponse: {
    questionNumber: string;
    values: { parentValue: any; nestedValue: NestedValues; textAreaValue: string };
  }) => {
    const { questionNumber, values } = updatedResponse;
    const { parentValue, nestedValue, textAreaValue } = values;

    const parentValuesArray = Array.isArray(parentValue) ? parentValue : [parentValue];

    const updatedSelections: Selection[] = parentValuesArray.map(parentLabel => {
      const nested = nestedValue?.[parentLabel] || {};

      const children = Object.entries(nested).map(([nestedLabel, nestedValue]) => ({
        label: nestedLabel || parentLabel,
        openText: typeof nestedValue === "string" ? nestedValue : undefined,
      }));

      return {
        label: parentLabel,
        ...(children.length > 0 && { children }),
      };
    });

    const cleanedResponse: Response = {
      questionNumber,
      selections: updatedSelections,
      additionalInfo: textAreaValue || "",
    };

    setCurrentResponses(prevResponses => {
      const index = prevResponses.findIndex(r => r.questionNumber === questionNumber);

      if (index !== -1) {
        const updated = [...prevResponses];
        updated[index] = cleanedResponse;
        return updated;
      }

      return [...prevResponses, cleanedResponse];
    });

    const updatedResponses = currentResponses.some(
      response => response.questionNumber === questionNumber
    )
      ? currentResponses.map(response =>
          response.questionNumber === questionNumber ? cleanedResponse : response
        )
      : [...currentResponses, cleanedResponse];

    onChange(updatedResponses);
  };

  const nestedValues = (question: QuestionCardProps, selections: Selection[]) => {
    const nestedValues: NestedValues = {};
    selections?.forEach(s => {
      const parentLabel = s.label;
      const nestedSelections = s.children;
      if (nestedSelections) {
        nestedValues[parentLabel] = nestedSelections.reduce((acc, nested) => {
          acc[nested.label] = nested.openText || nested.label;
          return acc;
        }, {});
      }
    });
    return nestedValues;
  };

  const sanitizedQuestion = (question: QuestionCardProps): QuestionCardProps => {
    const response = currentResponses.find(r => r.questionNumber === question.questionNumber);
    const selections = response?.selections || [];

    return {
      ...question,
      textValue: response?.additionalInfo || "",
      nestedValues: nestedValues(question, selections),
      value:
        question.type === "checkbox"
          ? selections.map((r: any) => r.label) || []
          : question.type === "radio"
          ? selections[0]?.label || ""
          : question.type === "upload"
          ? selections.map((r: any) => r.label) || []
          : question.value,
      s3Bucket: question.type === "upload" ? "bpm-client-info" : undefined,
      s3Path: question.type === "upload" ? `test/${company}/${question.s3Path}` : undefined,
    };
  };

  return (
    <div className="questionRows">
      {questions.map(q => {
        const question = sanitizedQuestion(q);
        return (
          <QuestionCard
            {...question}
            onChange={newValue =>
              handleChange({
                questionNumber: question.questionNumber,
                values: newValue,
              })
            }
            key={question.questionNumber}
          />
        );
      })}
    </div>
  );
};

export default QuestionOnlyForm;
