import React, { useMemo } from "react";

import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "../Components";

import "./ViewPlacementMetadata.scss";

const STANDARD_TRACKING_PIXEL_MATCHERS = [
  "https://match.prod.bidr.io/cookie-sync/blisspoint?buyer_user_id={{er_session}}",
  "https://0sqwpj551b.execute-api.us-west-2.amazonaws.com/prod/pixel",
];

interface Creative {
  isci: string;
  creative: string;
  length: string;
  creativeClickthroughUrl: string | null;
  audioCompanion: string | null;
}

interface ViewPlacementMetadataProps {
  placementLevelClickthroughUrl: string;
  trackingPixels: string[];
  creatives: Creative[];
  shouldShowCreativeMetadata: boolean;
}

const ViewPlacementMetadata: React.FC<ViewPlacementMetadataProps> = ({
  placementLevelClickthroughUrl,
  trackingPixels,
  creatives,
  shouldShowCreativeMetadata,
}) => {
  // No need to show people the tracking pixels we always add.  Find the non-standard ones
  // to show them which pixels they may have manually added to a placement.
  const nonStandardTrackingPixels = useMemo(() => {
    const nonStandardPixels = trackingPixels?.filter(
      pixel => !STANDARD_TRACKING_PIXEL_MATCHERS.some(matcher => pixel.includes(matcher))
    );
    return nonStandardPixels || [];
  }, [trackingPixels]);

  return (
    <div className="viewPlacementMetadata">
      {(placementLevelClickthroughUrl || Boolean(nonStandardTrackingPixels.length)) && (
        <div className="placementMetadata">
          <div className="metadataHeader">Placement Metadata</div>
          {placementLevelClickthroughUrl && (
            <div className="placementMetadataContainer">
              <div className="placementMetadataLabel">Placement-Level Clickthrough URL</div>
              {placementLevelClickthroughUrl === "None" ? (
                <div>None</div>
              ) : (
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.TOP.LEFT}
                  overlay={
                    <Tooltip className="urlTooltip" id="placementClickthroughTooltip">
                      {placementLevelClickthroughUrl}
                    </Tooltip>
                  }
                >
                  <a href={placementLevelClickthroughUrl} className="urlText">
                    {placementLevelClickthroughUrl}
                  </a>
                </OverlayTrigger>
              )}
            </div>
          )}
          {Boolean(nonStandardTrackingPixels.length) && (
            <div className="placementMetadataContainer">
              <div className="placementMetadataLabel">Non-Standard Tracking Pixels</div>
              {nonStandardTrackingPixels.map((pixel, i) => (
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.TOP.LEFT}
                  overlay={
                    <Tooltip className="urlTooltip" id="trackingPixelTooltip">
                      {pixel}
                    </Tooltip>
                  }
                >
                  <div className="urlText">{`${i + 1}) ${pixel}`}</div>
                </OverlayTrigger>
              ))}
            </div>
          )}
        </div>
      )}

      {shouldShowCreativeMetadata && (
        <div className="rotationMetadata">
          <div className="metadataHeader">Creative Metadata</div>
          {creatives.map(creative => (
            <div>
              {(creative.creativeClickthroughUrl || creative.audioCompanion) && (
                <div key={creative.isci} className="rotationMetadataOuterContainer">
                  <div className="isciLabel">
                    <div className="isci"> {creative.isci}</div>
                    <div className="creativeName">{`(${creative.creative} - ${creative.length}s)`}</div>
                  </div>

                  <div className="rotationMetadataContent">
                    {creative.creativeClickthroughUrl && (
                      <div className="rotationMetadataInnerContainer">
                        <div className="rotationMetadataLabel">Creative-Level Clickthrough URL</div>
                        {creative.creativeClickthroughUrl === "None" ? (
                          <div>None</div>
                        ) : (
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.TOP.LEFT}
                            overlay={
                              <Tooltip className="urlTooltip" id="creativeClickthroughTooltip">
                                {creative.creativeClickthroughUrl}
                              </Tooltip>
                            }
                          >
                            <a href={creative.creativeClickthroughUrl} className="urlText">
                              {creative.creativeClickthroughUrl}
                            </a>
                          </OverlayTrigger>
                        )}
                      </div>
                    )}
                    {creative.audioCompanion && (
                      <div className="rotationMetadataInnerContainer">
                        <div className="rotationMetadataLabel">Audio Companion</div>
                        <div>{creative.audioCompanion || "None"}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ViewPlacementMetadata;
