import { DateRange } from "../utils/types";
import {
  BrandHealthGqvQueryInfo,
  BrandHealthTableInfo,
  BrandHealthGqvTimeSeries,
  BrandHealthGqvTimeSeriesObject,
} from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";

export const formatGqvQueryDataForTimeSeries = (
  filteredGqvDataByRegionAndDateRange: BrandHealthGqvQueryInfo[]
): BrandHealthGqvTimeSeriesObject => {
  const sumsByDate: { [date: string]: { [label: string]: number } } = {};

  const uniqueLabelsSet = new Set<string>();

  for (let i = 0; i < filteredGqvDataByRegionAndDateRange.length; i++) {
    const row = filteredGqvDataByRegionAndDateRange[i];
    const date = row.ReportDate;
    const label = row.QueryLabel;
    const volume = Number(row.IndexedQueryVolume);

    uniqueLabelsSet.add(label);

    if (!sumsByDate[date]) {
      sumsByDate[date] = {};
    }
    if (!sumsByDate[date][label]) {
      sumsByDate[date][label] = 0;
    }
    sumsByDate[date][label] += volume;
  }

  const uniqueLabels = Array.from(uniqueLabelsSet).sort();

  const allDates = Object.keys(sumsByDate).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  const result: BrandHealthGqvTimeSeries[] = [];

  for (let i = 0; i < allDates.length; i++) {
    const date = allDates[i];
    const dateSums = sumsByDate[date];
    const obj: any = {
      ReportDate: date,
    };

    for (let j = 0; j < uniqueLabels.length; j++) {
      const label = uniqueLabels[j];
      obj[label] = dateSums[label] || 0;
    }

    result.push(obj);
  }
  return { res: result, uniqueLabels: uniqueLabels };
};

export const filterGqvQueryDataByRegionAndDateRange = (
  gqvQueryDataWithGeoNamesCodesAndPopulation: BrandHealthGqvQueryInfo[],
  filters: Partial<Record<string, boolean>>,
  dateRange: DateRange
): BrandHealthGqvQueryInfo[] => {
  const filteredData = gqvQueryDataWithGeoNamesCodesAndPopulation.filter(item => {
    const reportDateStr = item.ReportDate;
    return reportDateStr >= dateRange.start && reportDateStr <= dateRange.end;
  });

  const regions = Object.keys(filters).filter(region => filters[region]);
  const filteredTimeSeriesData = filteredData.filter(row => regions.includes(row.Region));
  return regions.includes("Region-All") ? filteredData : filteredTimeSeriesData;
};

const capitalizeDmaFullName = (input: string): string => {
  return input
    .split(", ")
    .map((segment, index) => {
      if (index === 0) {
        return segment
          .split(" ")
          .map(word =>
            word
              .split("-")
              .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
              .join("-")
          )
          .join(" ");
      } else {
        return segment.toUpperCase();
      }
    })
    .join(", ");
};

export const groupGqvQueryDataByDmaCode = (
  data: BrandHealthGqvQueryInfo[]
): BrandHealthTableInfo[] => {
  const aggregation: { [key: string]: BrandHealthTableInfo } = {};

  data.forEach(item => {
    const { dma_code } = item;
    if (!dma_code) {
      return;
    }

    if (!aggregation[dma_code]) {
      aggregation[dma_code] = {
        dma_full_name: capitalizeDmaFullName(item.dma_full_name),
        dma_code: dma_code,
        "Absolute Index": 0,
        Population: parseFloat(item.population),
      };
    }

    const volume =
      typeof item.IndexedQueryVolume === "number"
        ? item.IndexedQueryVolume
        : parseFloat(item.IndexedQueryVolume);

    aggregation[dma_code]["Absolute Index"] += volume;
  });

  return Object.values(aggregation);
};

export const groupGqvQueryDataByDmaCodeAndQueryLabel = (data: BrandHealthGqvQueryInfo[]): any[] => {
  const aggregation: { [key: string]: { [key: string]: any } } = {};

  data.forEach(item => {
    const { dma_code, QueryLabel, IndexedQueryVolume, dma_full_name, population } = item;

    if (!dma_code || !QueryLabel) {
      return;
    }

    if (!aggregation[dma_code]) {
      aggregation[dma_code] = {
        dma_full_name: capitalizeDmaFullName(dma_full_name),
        dma_code: dma_code,
        Population: parseFloat(population),
      };
    }

    if (!aggregation[dma_code][QueryLabel]) {
      aggregation[dma_code][QueryLabel] = 0;
    }

    const volume =
      typeof IndexedQueryVolume === "number" ? IndexedQueryVolume : parseFloat(IndexedQueryVolume);

    aggregation[dma_code][QueryLabel] += volume;
  });

  return Object.values(aggregation).map(entry => {
    const { dma_full_name, dma_code, Population, ...queryLabelData } = entry;

    return {
      "DMA Full Name": dma_full_name,
      "DMA Code": dma_code,
      Population,
      ...queryLabelData,
    };
  });
};

export const returnTitleForGqvChart = (typeOfChart: string): string => {
  let title = "";
  switch (typeOfChart) {
    case "showGraph":
      title = "Indexed Google Query Volume";
      break;
    case "showTable":
      title = "Total Google Query Volume";
      break;
    case "showMap":
      title = "Indexed Google Query Volume by DMA";
      break;
    default:
      title = "Indexed Google Query Volume";
      break;
  }

  return title;
};

export const filterTableDataByLabel = (
  filteredGqvDataByRegionAndDateRange: BrandHealthGqvQueryInfo[],
  label: string
): BrandHealthGqvQueryInfo[] => {
  const filteredGqvDataByLabel = filteredGqvDataByRegionAndDateRange.filter(
    row => row.QueryLabel === label
  );
  return filteredGqvDataByLabel;
};

const getStartOfWeek = (date: Date): Date => {
  const day = date.getUTCDay() || 7;
  if (day !== 1) {
    date.setUTCDate(date.getUTCDate() - day + 1);
  }
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
};

const getStartOfMonth = (date: Date): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
};

const getStartOfQuarter = (date: Date): Date => {
  const month = date.getUTCMonth();
  const quarterStartMonth = month - (month % 3);
  return new Date(Date.UTC(date.getUTCFullYear(), quarterStartMonth, 1));
};

const getStartOfYear = (date: Date): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
};

export const groupFormattedGqvLineChartDataByDate = (
  formattedGqvLineChartData: BrandHealthGqvTimeSeries[],
  groupingOption: string
): BrandHealthGqvTimeSeries[] => {
  const groups: { [groupKey: string]: BrandHealthGqvTimeSeries } = {};

  formattedGqvLineChartData.forEach((item: BrandHealthGqvTimeSeries) => {
    const date = new Date(item.ReportDate);
    let groupKey: string;
    let groupDate: Date;

    switch (groupingOption) {
      case "Day":
        groupDate = new Date(
          Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
        );
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Week":
        groupDate = getStartOfWeek(new Date(date));
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Month":
        groupDate = getStartOfMonth(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Quarter":
        groupDate = getStartOfQuarter(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Year":
        groupDate = getStartOfYear(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      default:
        throw new Error("Invalid grouping option");
    }

    if (!groups[groupKey]) {
      groups[groupKey] = { ReportDate: groupKey };
    }

    for (const [key, value] of Object.entries(item)) {
      if (key !== "ReportDate" && typeof value === "number") {
        groups[groupKey][key] = ((groups[groupKey][key] as number) || 0) + value;
      }
    }
  });

  return Object.values(groups);
};

export const formatAndRoundNumber = (num: number | null): string => {
  if (num == null) {
    return "";
  }

  if (num === 0) {
    return "0";
  }

  if (Math.abs(num) < 0.5) {
    return "<1";
  }

  const roundedValue = Math.round(num);

  return roundedValue.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const calculateMovingAverage = (
  groupByDateGqvData: BrandHealthGqvTimeSeries[],
  uniqueLabels: string[],
  movingAvgDaysGqv: string
): BrandHealthGqvTimeSeries[] => {
  if (movingAvgDaysGqv === "1") {
    return groupByDateGqvData;
  }

  const movingAvg = parseInt(movingAvgDaysGqv, 10);
  const sortedData = [...groupByDateGqvData].sort(
    (a, b) => new Date(a.ReportDate).getTime() - new Date(b.ReportDate).getTime()
  );

  return sortedData.map((entry, index) => {
    const newEntry = { ...entry };

    uniqueLabels.forEach(label => {
      const start = Math.max(0, index - movingAvg + 1);
      const values = sortedData
        .slice(start, index + 1)
        .map(item => (typeof item[label] === "number" ? (item[label] as number) : 0));

      const movingAverage =
        values.length > 0 ? values.reduce((sum, value) => sum + value, 0) / values.length : 0;

      newEntry[label] = movingAverage;
    });

    return newEntry;
  });
};

export const getMedian = (gqvMapData: number[]): number => {
  if (gqvMapData.length === 0) {
    return 0;
  }
  const sortedGqvMapData = gqvMapData.sort((a, b) => a - b);
  const midIndexOfGqvData = Math.floor(sortedGqvMapData.length / 2);
  return midIndexOfGqvData % 2 !== 0
    ? sortedGqvMapData[midIndexOfGqvData]
    : (sortedGqvMapData[midIndexOfGqvData - 1] + sortedGqvMapData[midIndexOfGqvData]) / 2;
};

export const getStandardDeviation = (gqvMapData: number[]): number => {
  if (gqvMapData.length === 0) {
    return 0;
  }
  const mean = gqvMapData.reduce((sum, val) => sum + val, 0) / gqvMapData.length;
  const variance = gqvMapData.reduce((acc, val) => acc + (val - mean) ** 2, 0) / gqvMapData.length;
  return Math.sqrt(variance);
};

export const getThresholdBinsWithStdDev = (gqvMapData: number[]): number[] => {
  const minIndex = Math.min(...gqvMapData);
  const maxIndex = Math.max(...gqvMapData);
  const median = getMedian(gqvMapData);
  const stdDev = getStandardDeviation(gqvMapData);
  const adjustedCeiling = median + 2.5 * stdDev;
  const adjustedMaxIndex = Math.min(maxIndex, adjustedCeiling);
  const binSize = (adjustedMaxIndex - minIndex) / 5;

  const thresholds: number[] = [];
  for (let i = 1; i < 5; i++) {
    if (i === 4) {
      thresholds.push(minIndex + i * binSize);
      thresholds.push(minIndex + i * binSize);
    } else {
      thresholds.push(minIndex + i * binSize);
    }
  }
  return thresholds;
};
