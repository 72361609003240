import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

import { AREA_LINE_CHART_COLOR, BAR_CHART_COLOR } from "../../utils/colors";
import { abbreviateNumber, capitalizeFirstLetter } from "../../utils/format-utils";

interface AreaAndBarChartProps {
  areaDataKey: string;
  barDataKey: string;
  data: {}[];
  legendLocation?: "top" | "bottom";
  xAxisDataKey: string;
  barYAxisDataKey: string;
  barYAxisLabel: string;
  areaYAxisDataKey: string;
  areaYAxisLabel: string;
  isAnimationActive?: boolean;
  height?: number;
  width?: number;
  barChartColor?: string;
  areaChartLineColor?: string;
  areaChartFillColor?: string;
}

const AreaAndBarChart: React.FC<AreaAndBarChartProps> = ({
  areaDataKey,
  barDataKey,
  data,
  legendLocation = "top",
  xAxisDataKey,
  barYAxisDataKey,
  barYAxisLabel,
  areaYAxisDataKey,
  areaYAxisLabel,
  isAnimationActive = true,
  height,
  width,
  barChartColor = BAR_CHART_COLOR,
  areaChartLineColor = AREA_LINE_CHART_COLOR,
  areaChartFillColor = AREA_LINE_CHART_COLOR,
}) => {
  const xAxis = <XAxis dataKey={xAxisDataKey} />;
  const barYAxis = (
    <YAxis
      dataKey={barYAxisDataKey}
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "left",
        value: barYAxisLabel,
      }}
      orientation="left"
      tickFormatter={tick => `${abbreviateNumber(tick)}`}
      type="number"
      yAxisId="0"
    />
  );
  const areaYAxis = (
    <YAxis
      dataKey={areaYAxisDataKey}
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "right",
        value: areaYAxisLabel,
      }}
      orientation="right"
      tickFormatter={tick => `${abbreviateNumber(tick)}`}
      type="number"
      yAxisId="1"
    />
  );
  const tooltip = (
    <Tooltip
      formatter={d => `${abbreviateNumber(Number(d))}`}
      isAnimationActive={isAnimationActive}
    />
  );
  const legend = (
    <Legend formatter={item => capitalizeFirstLetter(item)} verticalAlign={legendLocation} />
  );
  const bar = (
    <Bar
      barSize={20}
      dataKey={barDataKey}
      fill={barChartColor}
      yAxisId="0"
      isAnimationActive={isAnimationActive}
    />
  );
  const area = (
    <Area
      yAxisId="1"
      stroke={areaChartLineColor}
      dataKey={areaDataKey}
      fill={areaChartFillColor}
      type="monotone"
      isAnimationActive={isAnimationActive}
    />
  );

  if (height && width) {
    return (
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
        height={height}
        width={width}
      >
        {xAxis}
        {barYAxis}
        {areaYAxis}
        {tooltip}
        {legend}
        {bar}
        {area}
      </ComposedChart>
    );
  } else {
    return (
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          height={height}
          width={width}
        >
          {xAxis}
          {barYAxis}
          {areaYAxis}
          {tooltip}
          {legend}
          {bar}
          {area}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
};

export default AreaAndBarChart;
