import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import {
  INC_CDN,
  IncPlaybookSectionBox,
  navigateToSection,
  PAGE_PREFIX,
} from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Amazon";

export const setupHeaders = [
  {
    flex: 2,
    label: "Considerations",
    name: "Considerations",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return (
        //Reuse the channel class from the Playbook.scss
        <div className="channelNames">
          <div className="channelName single">
            <div className="label">{row.Considerations}</div>
          </div>
        </div>
      );
    },
  },
  {
    flex: 5,
    label: "Details",
    name: "Details",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return <div className="bulletedList">{row.Details}</div>;
    },
  },
];

export const testHeaders = [
  {
    flex: 1,
    label: "Element",
    name: "Element",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return (
        //Reuse the channel class from the Playbook.scss
        <div className="channelNames">
          <div className="channelName single">
            <div className="label">{row.Element}</div>
          </div>
        </div>
      );
    },
  },
  {
    flex: 5,
    label: "Details",
    name: "Details",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return <div className="bulletedList">{row.Details}</div>;
    },
  },
];

const Amazon = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              As the leading online marketplace, Amazon offers a diverse range of products to
              millions of customers. Amazon can target a highly engaged audience actively searching
              for products. Advertising options allow clients to promote their products, increase
              visibility, and drive sales directly on the Amazon platform.
            </div>
            <br />
            <div className="basicText">
              Amazon’s incrementality measurement solution is a managed service across the following
              products:
            </div>
            <br />
            <ol className="basicList">
              <li>
                <strong>Display</strong>
              </li>
              <li>
                <strong>Video/STV</strong>
              </li>
              <li>
                <strong>Audio</strong>
              </li>
            </ol>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Setup Options & Considerations",
                "Signal Enrichment",
                "Test Outputs",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Amazon Incrementality Measurement (AIM), currently have two incrementality testing
              options.
            </div>
            <br />
            {playbookTable(
              [
                {
                  Method: "AIM - User Based Testing",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                    { img: "Icon_ServiceModel_ManagedService.png", label: "Managed Service" },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        User-based testing option in which treatment users are served ads and
                        control users are withheld from client ads consistent with a{" "}
                        <a href={`${PAGE_PREFIX}/Incrementality101#ghost-bidding`}>ghost bidding</a>{" "}
                        methodology.
                      </li>
                      <li>
                        AIM currently only covers campaigns running on Amazon DSP and measures at
                        the order level across any device or channel across most inventory types.
                        This includes Standard Display (Mobile, Web, Desktop), STV, OLV
                        (non-guaranteed), Fire TV, IMDB TV, O&O, Native ads and Twitch.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Since the ad is not actually served to users in the control group, there are
                        no additional ad-serving costs for the advertiser.
                      </li>
                      <li> Can run across most inventory types and across devices.</li>
                      <li>
                        Managed service reduces the lift on internal teams to set up analysis.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Limited control over measurement and custom analysis due to reliance on
                        Amazon for handling measurement and data.
                      </li>
                      <li>
                        Do not have a way for identifying ghost wins and thus are using an{" "}
                        <a href="https://www.remerge.io/findings/blog-post/incrementality-tests-101-intent-to-treat-psa-ghost-ads-and-ghost-bids">
                          intent-to-treat (ITT)
                        </a>{" "}
                        approach and so may underestimate the effect of the treatment.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "AIM - Geo-lift Measurement",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                    { img: "Icon_ServiceModel_ManagedService.png", label: "Managed Service" },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        Geo-lift measurement uses geographic randomization based on DMA or Zip.
                      </li>
                      <li>
                        Measures impact without individual user tracking, suitable for
                        non-recognized users.
                      </li>
                      <li>Must be an Amazon DSP campaign, and must be biddable.</li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Allows for broader observational data beyond individual user behaviors.
                      </li>
                      <li>
                        Potential for running nested experiments with user-based RCT during beta.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Requires campaign to be national in North American, without geo-targeting.
                      </li>
                      <li>
                        Only measures Amazon Ad Tag events, not CAPI, MMP, or ASIN (on Amazon).
                      </li>
                      <li>Segment level reports not provided, only standard AIM reports.</li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              450
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Considerations: "Setup Steps",
                  Details: (
                    <ul className="basicList">
                      <li>
                        This is an in-platform testing option, so setup would be entirely owned by
                        the Amazon team. The set-up process begins by completing the{" "}
                        <a href="https://docs.google.com/document/d/1EFsi9uBLCE3jpz7ysHqcirv4jyn2UsPG/edit?usp=drive_link&ouid=100858070515602603720&rtpof=true&sd=true">
                          AIM intake form
                        </a>
                        . For measuring campaigns that sell products on Amazon, where we are linking
                        into amazon.com include the list of{" "}
                        <a href="https://docs.google.com/spreadsheets/d/19L0uUY3vnjbgxvUx6pEQICAWuiluTqVy/edit?usp=sharing&ouid=100858070515602603720&rtpof=true&sd=true">
                          specific ASINs
                        </a>
                        . Then send an email request with the completed form(s) to our Amazon
                        contact: Lucas Nurni (<a href="nurlucas@amazon.com">nurlucas@amazon.com</a>
                        ).
                      </li>
                    </ul>
                  ),
                },
                {
                  Considerations: "Timeline",
                  Details: (
                    <ul className="basicList">
                      <li>
                        It is recommended that requests to setup a test are received 2 weeks prior
                        to the start date to ensure the campaign launches on time.
                      </li>
                    </ul>
                  ),
                },
                {
                  Considerations: "Randomizations",
                  Details: (
                    <ul className="basicList">
                      <li>
                        Amazon user randomized at the Amazon ID level to treatment and control
                        groups through a hash-based randomization mechanism. Users in the treatment
                        group are shown ads from the target campaign when the target ad wins the ad
                        auction.
                      </li>
                      <li>
                        For users in the control group, when the target ad wins the ad auction, the
                        user is shown (or “flipped to”) the ad that they would have seen had the
                        target campaign not been running.
                      </li>
                      <li>
                        The hash function and randomization will be different for each experiment,
                        and experiments can be setup to include multiple campaigns or one campaign.
                        This flexibility allows for a consistent control/treatment group or unique
                        control/treatment groups for each campaign.
                      </li>
                    </ul>
                  ),
                },
                {
                  Considerations: "Sample Size Recommendation",
                  Details: (
                    <ul className="basicList">
                      <li>
                        For User Based Testing, Amazon has a general recommendation of $50-60k
                        budget and tests typically run 2-4 weeks long in order to reach statistical
                        significance. For Geo Lift measurement, Amazon recommends a minimum of $150K
                        in combined campaign budget to reach stat sig.
                        <ul>
                          <li>
                            Note: this is not a minimum spend level, these factors can vary by
                            client and there are ways in which test costs can be brought down
                            further, for example, by measuring on upper funnel KPIs. There is no
                            strict minimum spend level and will depend on a variety of factors.
                          </li>
                        </ul>
                      </li>
                      <li>
                        They do offer power calculations as part of setup process to provide
                        specific spend and test length recommendations for each test.
                      </li>
                      <li>
                        It is recommended that advertisers who would like to ensure statistical
                        significant results and are willing to compromise on the opportunity cost to
                        increase the control audience to 50%.
                      </li>
                      <li>
                        50%-50% split roughly doubles the power of an experiment and is the most
                        statistically precise split as there is no scaling needed to compare the two
                        groups.
                      </li>
                    </ul>
                  ),
                },
              ],
              setupHeaders,
              index => (index === 0 || index === 1 ? 100 : index === 2 ? 200 : 300)
            )}
          </div>
        }
        id={"setup-options-&-considerations"}
        pageTitle={PAGE_TITLE}
        title={"Setup Options & Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              In the context of Amazon Attribution, signal enrichment refers to the process of
              enhancing the depth and accuracy of data collected from user interactions with
              advertisements and their subsequent actions. Amazon offers the{" "}
              <a href="https://advertising.amazon.com/API/docs/en-us/guides/amazon-attribution/how-to">
                Amazon Attribution API
              </a>
              , which provides a structured approach to access and leverage various resources
              associated with advertisers' profiles. This API empowers advertisers to gather
              valuable insights from user actions, enabling more effective retargeting, attribution,
              and measurement strategies.
            </div>
            <br />
            <div className="basicText">
              Accurate implementation and proper utilization of attribution tags are crucial for
              effective measurement and reporting.
            </div>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Timeline",
                  Details: (
                    <ul className="basicList">
                      At test completion, Amazon will deliver a report and a list of incremental
                      performance for both targeted and un-targeted audiences. The target for
                      reporting on the test is 1 week after the end of the conversion window.
                    </ul>
                  ),
                },
                {
                  Element: "Conversion Window",
                  Details: (
                    <ul className="basicList">
                      The default conversion window is 14 days but you may select a shorter
                      conversion window of 7 days. In the future, custom conversion window will be
                      available for advertisers to select any number of days from the campaign end
                      date.
                    </ul>
                  ),
                },
                {
                  Element: "Granularity of Results",
                  Details: (
                    <ul className="basicList">
                      The product currently measures at the order level across any device or
                      channel. These include desktop, mobile, mobile web across all inventory types,
                      browsers, etc. Although Amazon can measure these sources, they don't yet have
                      the ability to provide lift measurements segmented by device or channel
                      dimensions. To work around this, some clients have created unique orders with
                      specific devices or channels allowing them to produce incremental lift values
                      by source.
                    </ul>
                  ),
                },
                {
                  Element: "Output Format",
                  Details: <ul className="basicList">In-app</ul>,
                },
              ],
              testHeaders,
              index => (index === 2 ? 200 : 100)
            )}
            <br />
            <Img src={`${INC_CDN}/Amazon_SegmentReport.png`} />
            <br />
            <iframe
              src="https://drive.google.com/file/d/1d76WLeihLso_sa8N-ai7sg8I-4L3WTrm/preview?authuser=0"
              allowFullScreen
              title="Example Results"
            ></iframe>
          </div>
        }
        id={"test-outputs"}
        pageTitle={PAGE_TITLE}
        title={"Test Outputs"}
      />
    </div>
  );
});

export default Amazon;
