import { useState, useMemo, useEffect } from "react";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import useLocation from "../utils/hooks/useLocation";
import { DateRangePicker, KpiPicker, Page } from "../Components";
import { useCompanyInfo } from "../redux/company";
import { MEDIA_TYPE_TO_PRETTY_NAME } from "./homePageConstants";
import { awaitJSON, ToolsLambdaFetch } from "../utils/fetch-utils";
import { getBrandFromKpi, getDateGroupingOptions } from "./homePageUtils";
import ChannelChip from "./ChannelChip";
import SpendBreakdown from "./SpendBreakdown";
import PerformanceBreakdown from "./PerformanceBreakdown";
import BudgetSnapshot from "./BudgetSnapshot";
import PerformanceSnapshot from "./PerformanceSnapshot";
import MarketingPortfolio from "./MarketingPortfolio";
import CreativeSnapshot from "./CreativeSnapshot";
import FunnelDynamics from "./FunnelDynamics";
import "./TVADCrossChannel.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const DATE_PICKER_CUTOFF = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());

const defaultDates = () => {
  const start = R.pipe(Dfns.subMonths(3), Dfns.format(DATE_FORMAT))(new Date());
  const end = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());
  return { start, end };
};

const TVADCrossChannel: React.FC = () => {
  const { company } = useLocation();
  const companyInfo = useCompanyInfo();
  const [dates, setDates] = useState(defaultDates);
  const [kpi, setKpi] = useState(companyInfo.initial_kpi);
  const [lags, setLags] = useState({
    streaming: companyInfo.streaming_performance_default_lag || "7d",
    audio: companyInfo.streaming_performance_default_lag || "7d",
    display: companyInfo.streaming_performance_default_lag || "7d",
  });
  const [toggledMediaTypes, setToggledMediaTypes] = useState<Record<string, boolean>>({});
  const [liveMediaTypes, setLiveMediaTypes] = useState<string[]>([]);
  const [dataMap, setDataMap] = useState({});

  const dataKey = `${dates.start}_${dates.end}_${kpi}_${lags.streaming}_${lags.audio}_${lags.display}`;

  const enabledMediaTypes = useMemo(
    () =>
      (companyInfo.media_types || []).reduce(
        (acc, mediaType) => ({ ...acc, [mediaType]: true }),
        {}
      ),
    [companyInfo?.media_types]
  );

  const dateGroupingOptions = useMemo(() => getDateGroupingOptions(dates.start, dates.end), [
    dates,
  ]);

  const resolvedCompany = useMemo(() => getBrandFromKpi(kpi) || company, [company, kpi]);

  useEffect(() => {
    (async () => {
      if (!R.isEmpty(enabledMediaTypes)) {
        const res = await ToolsLambdaFetch("/getHomePage", {
          params: {
            company: resolvedCompany,
            start: dates.start,
            end: dates.end,
            kpi,
            mediaTypes: R.keys(enabledMediaTypes).join(","),
            streamingLag: lags.streaming,
            audioLag: lags.audio,
            displayLag: lags.display,
          },
        });
        const data = await awaitJSON(res);
        setDataMap(current => ({ ...current, [dataKey]: data }));
        setLiveMediaTypes(data.liveChannels || []);
      }
    })();
  }, [resolvedCompany, dates, enabledMediaTypes, dataKey, kpi, setLiveMediaTypes, lags]);

  useEffect(() => {
    setToggledMediaTypes(enabledMediaTypes);
  }, [enabledMediaTypes]);

  const data = dataMap[dataKey] || {};

  return (
    <Page
      title={
        <div className="tvadCrossChannelTitle">
          <div>TVAD Cross-Channel</div>
          {R.keys(MEDIA_TYPE_TO_PRETTY_NAME)
            .filter(mediaType => enabledMediaTypes[mediaType])
            .map(mediaType => (
              <ChannelChip
                key={mediaType}
                mediaType={mediaType}
                isSelected={toggledMediaTypes?.[mediaType]}
                lag={lags[mediaType]}
                setLags={setLags}
                setToggledMediaTypes={setToggledMediaTypes}
                isLive={liveMediaTypes.includes(mediaType)}
              />
            ))}
        </div>
      }
      pageType="Cross-Channel"
      actions={
        <>
          <KpiPicker onChange={setKpi} kpi={kpi} disableTypeSelector />
          <DateRangePicker
            startDate={dates.start}
            endDate={dates.end}
            startDateId="startDate"
            endDateId="endDate"
            isOutsideRange={date => date > DATE_PICKER_CUTOFF}
            onChange={({ startDate, endDate }) =>
              setDates({
                start: startDate,
                end: endDate,
              })
            }
          />
        </>
      }
    >
      <div className="tvadCrossChannelContainer">
        <div className="unevenRowContainer">
          <BudgetSnapshot
            data={data.budgetSnapshot}
            company={resolvedCompany}
            toggledMediaTypes={toggledMediaTypes}
          />
          <SpendBreakdown
            data={data.spendBreakdown}
            company={resolvedCompany}
            toggledMediaTypes={toggledMediaTypes}
            dateGroupingOptions={dateGroupingOptions}
          />
        </div>
        <div className="unevenRowContainer">
          <PerformanceSnapshot
            data={data.performanceSnapshot}
            company={resolvedCompany}
            toggledMediaTypes={toggledMediaTypes}
          />
          <PerformanceBreakdown
            data={data.performanceBreakdown}
            company={resolvedCompany}
            toggledMediaTypes={toggledMediaTypes}
            dateGroupingOptions={dateGroupingOptions}
          />
        </div>
        <div className="unevenRowContainer">
          <CreativeSnapshot
            company={resolvedCompany}
            start={dates.start}
            end={dates.end}
            kpi={kpi}
          />
          <MarketingPortfolio
            company={resolvedCompany}
            start={dates.start}
            end={dates.end}
            kpi={kpi}
          />
        </div>
        <div className="unevenRowContainer">
          <FunnelDynamics company={resolvedCompany} start={dates.start} end={dates.end} />
        </div>
      </div>
    </Page>
  );
};

export default TVADCrossChannel;
