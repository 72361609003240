// Experiment for enabling Brand Health Google tab.
const brandHealthGoogleExternalCompanies: string[] = [
  "away",
  "culligan",
  "leafguard",
  "learningresources",
  "nobull",
  "saxx",
];

const brandHealthGoogleInternalCompanies: string[] = ["tinuititest"];

export const shouldEnableBrandHealthGoogleTab = (company: string, userRole: number): boolean => {
  if (brandHealthGoogleExternalCompanies.includes(company) && userRole <= 10) {
    return true;
  } else if (brandHealthGoogleInternalCompanies.includes(company) && userRole <= 2) {
    return true;
  } else {
    return false; // page does not exist
  }
};
