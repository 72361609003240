import "./IncrementalityNavBar.scss";
import { FilterBar, Img } from "../Components";
import { FLAT_INC_PLAYBOOK_PAGES, INC_CDN, INC_PLAYBOOK_PAGES } from "./IncrementalityPlaybook";
import { typedReactMemo } from "../utils/types";
import { useCallback, useMemo, useState } from "react";
import * as R from "ramda";

// Pages we don't want in the nav here
const HIDDEN_PAGES = ["GhostBidding"];

interface IncrementalityNavBarProps {
  activePage: string;
  setActivePageURL: (page: string) => void;
}

const IncrementalityNavBar = typedReactMemo<React.FC<IncrementalityNavBarProps>>(
  ({ activePage, setActivePageURL }) => {
    const [filteredRoutes, setFilteredRoutes] = useState<string[]>();
    const [selectedPage, setSelectedPage] = useState<string>();

    const [activeNavBarItem, activeSubNavBarItem] = useMemo(() => {
      for (const section of R.keys(INC_PLAYBOOK_PAGES)) {
        for (const page of R.keys(INC_PLAYBOOK_PAGES[section])) {
          const pageContent = INC_PLAYBOOK_PAGES[section][page];
          if (page === activePage) {
            return [page, undefined];
          } else if (pageContent.subPages && R.keys(pageContent.subPages).includes(activePage)) {
            setSelectedPage(page);
            return [page, activePage];
          }
        }
      }
      return [undefined, undefined];
    }, [activePage]);

    const createSubNavItems = useCallback(
      subPages => (
        <>
          {R.map(
            subPage => (
              <>
                <div
                  className={`subItem ${activeSubNavBarItem === subPage ? "active" : ""}`}
                  onClick={() => setActivePageURL(subPage.replace(/ /g, ""))}
                >
                  <div className="label">{subPage}</div>
                </div>
              </>
            ),
            R.keys(subPages).filter(subpage => !HIDDEN_PAGES.includes(subpage))
          )}
        </>
      ),
      [activeSubNavBarItem, setActivePageURL]
    );

    const createNavBarItem = useCallback(
      (section, page: string) => {
        const pageContent = INC_PLAYBOOK_PAGES[section][page];
        return (
          <>
            <div
              className={`item ${selectedPage === page ? "selected" : ""} ${
                activeNavBarItem === page ? "active" : ""
              }`}
              onClick={() => {
                if (selectedPage === page) {
                  setSelectedPage(undefined);
                  setActivePageURL(page.replace(/ /g, ""));
                } else if (pageContent.subPages) {
                  setSelectedPage(page);
                } else {
                  setActivePageURL(page.replace(/ /g, ""));
                }
              }}
            >
              <div className="icon">
                {pageContent.icon ? pageContent.icon : <Img src={`${INC_CDN}/Icon_${page}.png`} />}
              </div>
              <div className="label"> {page}</div>
            </div>
            {selectedPage === page &&
              pageContent.subPages &&
              createSubNavItems(pageContent.subPages)}
          </>
        );
      },
      [activeNavBarItem, createSubNavItems, selectedPage, setActivePageURL]
    );

    const filteredResults =
      !R.isNil(filteredRoutes) &&
      (R.isEmpty(filteredRoutes) ? (
        <div className="subItem">
          <div className="label">No search results</div>
        </div>
      ) : (
        createSubNavItems(R.pick(filteredRoutes, FLAT_INC_PLAYBOOK_PAGES))
      ));

    return (
      <div className="incPlaybookNavBar">
        <FilterBar
          className="searchBar"
          onFilter={filter => {
            const filteredRoutes = R.filter(
              key => filter({ [key]: key }),
              R.keys(FLAT_INC_PLAYBOOK_PAGES)
            );
            if (filteredRoutes.length === R.keys(FLAT_INC_PLAYBOOK_PAGES).length) {
              setFilteredRoutes(undefined);
            } else {
              setFilteredRoutes(filteredRoutes);
            }
          }}
          options={R.keys(FLAT_INC_PLAYBOOK_PAGES)}
          placeholder="Search Playbook"
          variant="Dropdown"
        />
        <div className="dividerLineContainer"></div>
        {R.isNil(filteredRoutes)
          ? R.map(
              section => (
                <>
                  {section !== R.keys(INC_PLAYBOOK_PAGES)[0] && (
                    <div className="dividerLineContainer">
                      <div className="dividerLine" />
                    </div>
                  )}
                  {section !== R.keys(INC_PLAYBOOK_PAGES)[0] && (
                    <div className="sectionTitle">{section}</div>
                  )}
                  {R.map(
                    page => createNavBarItem(section, page),
                    R.keys(INC_PLAYBOOK_PAGES[section]).filter(page => !HIDDEN_PAGES.includes(page))
                  )}
                </>
              ),
              R.keys(INC_PLAYBOOK_PAGES)
            )
          : filteredResults}
      </div>
    );
  }
);

export default IncrementalityNavBar;
