import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { seriesColors } from "../../utils/colors";
import { abbreviateNumber } from "../../utils/format-utils";
interface LineChartProps {
  data: {}[];
  height?: number;
  isAnimationActive?: boolean;
  leftYAxisDataKey?: string;
  leftYAxisLabel?: string;
  legendLocation?: "top" | "bottom";
  lineChartColors?: string[];
  lineDataKeys: { dataKey: string; yAxisId: 0 | 1 }[]; // yAxisId: 0 = right, 1 = left
  percentageMinTickGap?: number;
  percentDomain?: [number, number];
  rightYAxisDataKey?: string;
  rightYAxisLabel?: string;
  dateXAxis?: boolean;
  usePercentageYAxis?: boolean; // default to right side; use rightYAxisDataKey and rightYAxisLabel
  width?: number;
  xAxisDataKey?: string;
  refLineKeys?: { x: number | string; label: number | string; stroke: number | string }[];
}
export const LineChart: React.FC<LineChartProps> = ({
  data,
  height,
  isAnimationActive = true,
  leftYAxisDataKey,
  leftYAxisLabel,
  legendLocation = "top",
  lineChartColors = seriesColors,
  lineDataKeys,
  percentageMinTickGap = 20,
  percentDomain = [0, 100],
  rightYAxisDataKey,
  rightYAxisLabel,
  usePercentageYAxis = false,
  width,
  xAxisDataKey,
  refLineKeys,
}) => {
  const dateXAxis = <XAxis dataKey={xAxisDataKey} />;
  const rightYAxis = (
    <YAxis
      dataKey={rightYAxisDataKey}
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "right",
        value: rightYAxisLabel,
      }}
      orientation="right"
      tickFormatter={tick => `${abbreviateNumber(tick)}`}
      type="number"
      yAxisId="0"
    />
  );
  const leftYAxis = (
    <YAxis
      dataKey={leftYAxisDataKey}
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "left",
        value: leftYAxisLabel,
      }}
      orientation="left"
      tickFormatter={tick => `${abbreviateNumber(tick)}`}
      type="number"
      yAxisId="1"
    />
  );
  const percentageYAxis = (
    <YAxis
      dataKey={rightYAxisDataKey}
      domain={percentDomain}
      label={{
        angle: -90,
        position: "right",
        value: rightYAxisLabel,
      }}
      minTickGap={percentageMinTickGap}
      orientation="right"
      type="number"
      yAxisId="0"
      unit="%"
    />
  );
  const tooltip = (
    <Tooltip
      formatter={d => `${abbreviateNumber(Number(d))}`}
      isAnimationActive={isAnimationActive}
    />
  );
  const legend = <Legend verticalAlign={legendLocation} />;

  const customReferenceLine = refLineKeys => {
    const refLines = refLineKeys.map((refDataKeyInfo, index: number) => (
      <ReferenceLine
        x={refDataKeyInfo.x}
        label={refDataKeyInfo.label}
        stroke={refDataKeyInfo.stroke}
        key={index}
      />
    ));
    return refLines;
  };

  const buildLines = lineDataKeys => {
    const lines = lineDataKeys.map((dataKeyInfo, index: number) => (
      <Line
        yAxisId={dataKeyInfo.yAxisId}
        stroke={lineChartColors[index]}
        dataKey={dataKeyInfo.dataKey}
        type="linear"
        isAnimationActive={isAnimationActive}
        strokeWidth={3}
        dot={false}
        key={index}
      />
    ));
    return lines;
  };

  const useRightYAxis = rightYAxisDataKey && !usePercentageYAxis;
  if (height && width) {
    return (
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
        height={height}
        width={width}
      >
        {dateXAxis}
        {useRightYAxis && rightYAxis}
        {usePercentageYAxis && percentageYAxis}
        {leftYAxisDataKey && leftYAxis}
        {tooltip}
        {legend}
        {buildLines(lineDataKeys)}
        {refLineKeys && customReferenceLine(refLineKeys)}
      </ComposedChart>
    );
  } else {
    return (
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          height={height}
          width={width}
        >
          {dateXAxis}
          {useRightYAxis && rightYAxis}
          {usePercentageYAxis && percentageYAxis}
          {leftYAxisDataKey && leftYAxis}
          {tooltip}
          {legend}
          {buildLines(lineDataKeys)}
          {refLineKeys && customReferenceLine(refLineKeys)}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
};
