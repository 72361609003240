import "./MarketingInputs.scss";
import React, { useState, useMemo, useCallback, useContext } from "react";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import { XAxis, YAxis, Tooltip, CartesianGrid, Area, AreaChart } from "recharts";
import {
  Skeleton,
  PathSkeleton,
  BPMTable,
  DropdownToggleType,
  Dropdown,
  GraphTableToggleButton,
  DownloadDropdown,
  ToggleSwitch,
  InfoTooltip,
} from "../../Components";
import { abbreviateNumber } from "../../utils/data";
import ChartContainer from "../../Components/ChartContainer";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
  DateGroupingKey,
  DATE_GROUPING_OPTIONS,
} from "../../TVADCrossChannel/homePageConstants";
import { getSeriesColor } from "../../utils/colors";
import { formatNumberAsInt } from "../../utils/format-utils";
import {
  formatDateLabel,
  getGroupingValue,
  VOLUME_FORMATTER,
} from "../../TVADCrossChannel/homePageUtils";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import { CrossChannelContext } from "../CrossChannel";
import MarketingInputsLegend from "./MarketingInputsLegend";
import ChartTooltip from "../../TVADCrossChannel/ChartTooltip";

const X_AXIS_DOMAIN = ["dataMin", "dataMax"];

interface MarketingInputsProps {
  data: any[];
  channelClassification: Record<string, string>;
}

interface GradientRange {
  start: number;
  end: number;
  active: boolean;
}

interface Channel {
  channel: string;
  color: string;
}

const groupDataByDate = (data: any, dateGrouping: DateGroupingKey, channels: Channel[]): any => {
  if (!data) {
    return [];
  }
  // By default, the data is on the day level, so don't do anything to it.
  if (dateGrouping === "Day") {
    return data;
  }

  const channelNames = channels.map(channel => channel.channel);

  let totalsByDate: Record<string, Record<string, any>> = {};
  for (const item of data) {
    const dateToUse = getGroupingValue(item.date, dateGrouping);

    for (let channel of channelNames) {
      totalsByDate = R.mergeDeepRight(totalsByDate, {
        [dateToUse]: {
          [channel]: R.pathOr(0, [dateToUse, channel], totalsByDate) + (item[channel] || 0),
        },
      });
    }
  }
  return Object.entries(totalsByDate).map(([date, totals]) => ({
    date,
    ...totals,
  })) as any;
};

const processGraphData = (
  data: any,
  logScale: boolean,
  dateGrouping: DateGroupingKey,
  channels: Channel[]
) => {
  if (!data) {
    return [];
  }

  // Group data by selected date interval (Day, Week, Month, etc.)
  const totalsByDate = groupDataByDate(data, dateGrouping, channels);

  if (logScale) {
    return totalsByDate.map((item: any) => {
      const { date, ...rest } = item;
      const logValues = R.keys(rest).reduce((prev, curr) => {
        let logVal = Math.log10(rest[curr] || 0);
        if (!isFinite(logVal) || logVal < 0) {
          logVal = 0;
        }
        return { ...prev, [curr]: logVal };
      }, {});
      return { date, ...logValues };
    });
  }

  return totalsByDate;
};

const processTableData = (
  data: any[],
  dateGrouping: DateGroupingKey,
  channels: Channel[]
): any[] => {
  if (!data) {
    return [];
  }
  const channelNames = channels.map(channel => channel.channel);
  let groupedDataByDate: Record<string, Record<string, number>> = {};
  for (let row of data) {
    const dateToUse = getGroupingValue(row.date, dateGrouping as DateGroupingKey);
    for (let channel of channelNames) {
      const value = row[channel];
      groupedDataByDate = R.mergeDeepRight(groupedDataByDate, {
        [dateToUse]: {
          [channel]: R.pathOr(0, [dateToUse, channel], groupedDataByDate) + (value || 0),
        },
      });
    }
  }

  return Object.entries(groupedDataByDate).map(([date, totals]) => ({ date, ...totals }));
};

const MarketingInputs: React.FC<MarketingInputsProps> = React.memo(
  ({ data, channelClassification }) => {
    const [graphView, setGraphView] = useState(true);
    const [logScale, setLogScale] = useState(false);
    const [dateGrouping, setDateGrouping] = useState(DATE_GROUPING_OPTIONS[0].value);

    const { dates } = useContext(CrossChannelContext);

    const channels: Channel[] = useMemo(() => {
      return (R.keys(channelClassification) || []).sort().map((channel, i) => ({
        channel,
        color: getSeriesColor(i),
      }));
    }, [channelClassification]);

    const processedGraphData = useMemo(
      () => processGraphData(data, logScale, dateGrouping, channels),
      [data, logScale, dateGrouping, channels]
    );

    const processedTableData = useMemo(() => processTableData(data, dateGrouping, channels), [
      data,
      dateGrouping,
      channels,
    ]);

    const gradientRanges = useMemo(() => {
      let marketingPortfolioGradientRanges: GradientRange[] = [];
      if (data && !R.isEmpty(data)) {
        let globalCount = 0;
        let thisCount = 0;
        let active = data[0].Interpolated;
        let arrayLength = data.length;
        for (let date of data) {
          if (date.Interpolated === active) {
            thisCount++;
          } else {
            marketingPortfolioGradientRanges.push({
              start: (globalCount / arrayLength) * 100,
              end: ((globalCount + thisCount) / arrayLength) * 100,
              active,
            });
            active = date.Interpolated;
            globalCount += thisCount;
            thisCount = 1;
          }
        }
        marketingPortfolioGradientRanges.push({
          start: (globalCount / arrayLength) * 100,
          end: ((globalCount + thisCount) / arrayLength) * 100,
          active,
        });
      }
      return marketingPortfolioGradientRanges;
    }, [data]);

    const headers = useMemo(() => {
      if (!processedTableData || R.isEmpty(processedTableData)) {
        return [];
      }
      let headers: any[] = [
        {
          label: "Date",
          name: "date",
          nonInteractive: true,
          renderer: item => formatDateLabel(item.date, dateGrouping),
        },
      ];
      for (let key of R.keys(processedTableData[0])) {
        if (key !== "date" && key !== "Interpolated") {
          // For each media type, add a super header plus a header for Spend and CPM.
          headers.push(
            ...[
              {
                label: key,
                name: key,
                nonInteractive: true,
                flex: 1,
                renderer: item => formatNumberAsInt(item[key]),
              },
            ]
          );
        }
      }

      return headers;
    }, [processedTableData, dateGrouping]);

    const headersRenderer = useCallback(
      ({ data }) => {
        const styles: any = {};
        if (channelClassification[data] === "Impressions") {
          styles.fontStyle = "italic";
        }
        return <div style={styles}>{data}</div>;
      },
      [channelClassification]
    );

    const totals = useMemo(() => {
      if (!processedTableData || R.isEmpty(processedTableData)) {
        return {};
      }
      let totals = processedTableData.reduce((acc, row) => {
        for (let key of R.keys(row)) {
          if (key !== "date") {
            acc[key] = (acc[key] || 0) + row[key];
          }
        }
        return acc;
      }, {});

      return {
        date: "Totals",
        ...totals,
      };
    }, [processedTableData]);

    const totalsRenderer = ({ data, style = {}, classes = [] as string[] }) => {
      return (
        <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
          {formatNumberAsInt(data)}
        </div>
      );
    };

    const fileNameIdentifiers = useMemo(() => {
      return [
        "CrossChannel",
        "MarketingInputs",
        "Spend/Impressions",
        `${dates.start}–${dates.end}`,
      ];
    }, [dates]);

    const excelDownload = useCallback(() => {
      exportToExcel(data, `${fileNameIdentifiers.join("_")}`);
    }, [data, fileNameIdentifiers]);

    const pngDownload = useCallback(async () => {
      await downloadPNG(
        ".marketingInputsOuterContainer",
        fileNameIdentifiers,
        "Spend/Impressions",
        [".cl-download", ".icon-toggle-button"]
      );
    }, [fileNameIdentifiers]);

    return (
      <div className="marketingInputsOuterContainer">
        <ChartContainer
          enableHoverDesign
          title={
            <>
              <Dropdown
                type={DropdownToggleType.WIDGET_TITLE}
                value={dateGrouping}
                options={DATE_GROUPING_OPTIONS}
                onChange={option => setDateGrouping(option as DateGroupingKey)}
              />
              <div>Spend/Impressions</div>
              <InfoTooltip>
                <div>
                  Italicized channels are shown in 100's of impressions. All other channels are
                  shown in spend dollars.
                </div>
              </InfoTooltip>
            </>
          }
          rightActions={
            <>
              <ToggleSwitch label="Log Scale " checked={logScale} onChange={setLogScale} />
              <GraphTableToggleButton
                graphView={graphView}
                setGraphView={setGraphView}
                widgetName="maketing_inputs"
              />
              <DownloadDropdown
                size="sm"
                onClickOptions={[excelDownload, pngDownload]}
                disabledMenuOptions={
                  graphView ? { XLSX: false, PNG: false } : { XLSX: false, PNG: true }
                }
                disabled={!data}
              />
            </>
          }
        >
          {data ? (
            R.isEmpty(data) ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "26px",
                }}
              >
                No marketing inputs to show
              </div>
            ) : graphView ? (
              <div className="marketingInputsContent">
                <div id="marketingInputsChart" className="marketingInputsChart">
                  <AutoSizer>
                    {({ width, height }) => (
                      <AreaChart width={width} height={height} data={processedGraphData}>
                        <CartesianGrid
                          vertical={false}
                          stroke={CARTESIAN_GRID_STROKE}
                          strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                        />
                        <defs>
                          {channels.map((channelInfo, i) => {
                            const { channel, color } = channelInfo;
                            return (
                              <React.Fragment key={channel}>
                                <linearGradient
                                  id={`marketingPortfolioFillGradient_${channel}`}
                                  x1="0%"
                                  x2="100%"
                                  y1="0%"
                                  y2="0%"
                                >
                                  {gradientRanges.map(range => {
                                    const { start, end, active } = range;
                                    let opacity = active ? 0.5 : 1;
                                    return [
                                      <stop
                                        key={`${start}_${end}_start`}
                                        offset={`${start}%`}
                                        stopColor={color}
                                        stopOpacity={opacity}
                                      />,
                                      <stop
                                        key={`${start}_${end}_end`}
                                        offset={`${end}%`}
                                        stopColor={color}
                                        stopOpacity={opacity}
                                      />,
                                    ];
                                  })}
                                </linearGradient>
                              </React.Fragment>
                            );
                          })}
                        </defs>
                        {channels.map((channelInfo, i) => {
                          const { channel, color } = channelInfo;
                          return (
                            <Area
                              type="monotone"
                              dataKey={channel}
                              stackId="1"
                              key={i}
                              activeDot={false}
                              fillOpacity={1}
                              strokeWidth={0}
                              fill={color}
                              isAnimationActive={false}
                              stroke={color}
                            />
                          );
                        })}
                        <XAxis
                          dataKey="date"
                          domain={X_AXIS_DOMAIN}
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={date => formatDateLabel(date, dateGrouping)}
                          interval="equidistantPreserveStart"
                        />
                        <YAxis
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={number => `${abbreviateNumber(number)}`}
                        />
                        <Tooltip
                          content={({ label, payload }) => {
                            const formattedHeaderLabel = formatDateLabel(label, dateGrouping, true);
                            let items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload?.map(item => {
                                // @ts-ignore
                                const { name, value }: { name: string; value: number } = item;
                                return {
                                  label: name,
                                  value: logScale ? (value === 0 ? value : 10 ** value) : value,
                                  color: item.stroke,
                                  styles: {
                                    fontStyle:
                                      channelClassification[item.name as string] === "Impressions"
                                        ? "italic"
                                        : "",
                                  },
                                };
                              }) || []
                            );
                            return (
                              <ChartTooltip
                                headerLabel={formattedHeaderLabel}
                                items={items}
                                itemFormatter={VOLUME_FORMATTER}
                              />
                            );
                          }}
                          isAnimationActive={false}
                        />
                      </AreaChart>
                    )}
                  </AutoSizer>
                </div>
                <div className="rightOfChart">
                  <MarketingInputsLegend
                    channels={channels}
                    channelClassification={channelClassification}
                  />
                </div>
              </div>
            ) : (
              <BPMTable
                data={processedTableData}
                headers={headers}
                headersRenderer={headersRenderer}
                filterBar={false}
                totals={totals}
                totalsRenderer={totalsRenderer}
              />
            )
          ) : (
            <Skeleton>
              <PathSkeleton
                points={[
                  [0, 0.3],
                  [0.25, 0.7],
                  [0.5, 0.2],
                  [0.75, 0.9],
                  [1, 0.5],
                ]}
              />
            </Skeleton>
          )}
        </ChartContainer>
      </div>
    );
  }
);

export default MarketingInputs;
