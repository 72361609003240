import React from "react";
import { amcClient } from "../../Cache";
import { useQuery } from "@apollo/client";
import TableProgressBar from "../../TableProgressBar";
import { GET_MAX_NTB, maxNtbRate, maxNtbRepeatRate } from "../../ReactiveVariables";
import { abbreviateNumber } from "../../../utils/format-utils";

const ProgressBarRenderer = (value: number, maxCompleted: number, type: string): JSX.Element => {
  useQuery(GET_MAX_NTB, { client: amcClient });

  return <TableProgressBar label={`${value}%`} maxCompleted={maxCompleted} value={value} />;
};
interface TableData {
  customersConverted: number;
  ntbCustomers: number;
  ntbPurchases: number;
  ntbRate: number;
  ntbRepeat: number;
  ntbRepeatRate: number;
  purchases: number;
  trackedAsin: string;
}

export const ntbTableHeaders = [
  {
    name: "trackedAsin",
    label: "ASIN",
  },
  {
    name: "customersConverted",
    label: "Customers Converted",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): string => abbreviateNumber(data.customersConverted),
  },
  {
    name: "purchases",
    label: "Purchases",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): string => abbreviateNumber(data.purchases),
  },
  {
    name: "ntbCustomers",
    label: "NTB Customers",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): string => abbreviateNumber(data.ntbCustomers),
  },
  {
    name: "ntbPurchases",
    label: "NTB Purchases",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): string => abbreviateNumber(data.ntbPurchases),
  },
  {
    name: "ntbRate",
    label: "NTB Rate",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.ntbRate, maxNtbRate(), "ntbRate"),
  },
  {
    name: "ntbRepeat",
    label: "NTB Repeat",
    flex: 1,
    minFlexWidth: 141,
  },
  {
    name: "ntbRepeatRate",
    label: "NTB Repeat Rate",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.ntbRepeatRate, maxNtbRepeatRate(), "ntbRepeatRate"),
  },
];
