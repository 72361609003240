import * as Contract from '@tableau/api-external-contract-js';
import { ColumnType, DataType, FieldAggregationType, FieldRoleType } from '@tableau/api-external-contract-js';
import { FieldImpl } from './Impl/FieldImpl';

export class Field implements Contract.Field {
  public constructor(private _fieldImpl: FieldImpl) {}

  public get name(): string {
    return this._fieldImpl.name;
  }

  public get id(): string {
    return this._fieldImpl.id;
  }

  public get description(): string | undefined {
    return this._fieldImpl.description;
  }

  public get dataType(): DataType {
    return this._fieldImpl.dataType;
  }

  public get aggregation(): FieldAggregationType {
    return this._fieldImpl.aggregation;
  }

  public get dataSource(): Contract.DataSource {
    return this._fieldImpl.dataSource;
  }

  public get role(): FieldRoleType {
    return this._fieldImpl.role;
  }

  public get columnType(): ColumnType {
    return this._fieldImpl.columnType;
  }

  public get isCalculatedField(): boolean {
    return this._fieldImpl.isCalculatedField;
  }

  public get isCombinedField(): boolean {
    return this._fieldImpl.isCombinedField;
  }

  public get isGenerated(): boolean {
    return this._fieldImpl.isGenerated;
  }

  public get isGeospatial(): boolean {
    return this._fieldImpl.isGeospatial;
  }

  public get isHidden(): boolean {
    return this._fieldImpl.isHidden;
  }

  public get isPresentOnPublishedDatasource(): boolean {
    return this._fieldImpl.isPresentOnPublishedDatasource;
  }
}
