import { CustomViewModel, ExecuteParameters, ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { CustomViewImpl } from '../../Impl/CustomViewImpl';
import { ServiceNames } from '../ServiceRegistry';
import { VizService } from '../VizService';
import { ServiceImplBase } from './ServiceImplBase';

export class VizServiceImpl extends ServiceImplBase implements VizService {
  public get serviceName(): string {
    return ServiceNames.Viz;
  }

  public createVizImageAsync(inputSpec: object): Promise<string> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'createVizImageAsync',
      [ParameterId.VizAPIInputJson]: JSON.stringify(inputSpec),
    };

    return this.execute(VerbId.VizAPI, parameters).then((response) => {
      const result = response.result as string;
      return result;
    });
  }

  public activateSheetAsync(name: string): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'activateSheetAsync',
      [ParameterId.SwitchToSheetName]: name,
    };

    return this.execute(VerbId.ActivateSheet, parameters).then(() => {
      return;
    });
  }

  public getCustomViewsAsync(): Promise<Array<CustomViewImpl>> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getCustomViewsAsync',
    };

    return this.execute(VerbId.GetCustomViews, parameters).then<Array<CustomViewImpl>>((response) => {
      const customViews = response.result as Array<CustomViewModel>;
      return customViews.map((customView: CustomViewModel) => {
        return new CustomViewImpl(customView, this._registryId);
      });
    });
  }

  public showCustomViewAsync(customViewName?: string | null): Promise<CustomViewImpl | undefined> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'showCustomViewAsync',
      [ParameterId.CustomViewName]: customViewName || '', // a falsey value means "show original view"
    };

    return this.execute(VerbId.ShowCustomView, parameters).then<CustomViewImpl | undefined>((response) => {
      const customView = response.result as CustomViewModel;
      return customView?.luid ? new CustomViewImpl(customView, this._registryId) : undefined;
    });
  }

  public removeCustomViewAsync(customViewName: string): Promise<CustomViewImpl> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'removeCustomViewAsync',
      [ParameterId.CustomViewName]: customViewName,
    };

    return this.execute(VerbId.RemoveCustomView, parameters).then<CustomViewImpl>((response) => {
      const customView = response.result as CustomViewModel;
      return new CustomViewImpl(customView, this._registryId);
    });
  }

  public saveCustomViewAsync(customViewName: string): Promise<CustomViewImpl> {
    // CustomViewIsDefault is a required parameter, but our API has not been updated to allow it to be specified yet.
    // We should add a 'default' parameter to the interface.
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'saveCustomViewAsync',
      [ParameterId.CustomViewName]: customViewName,
      [ParameterId.CustomViewIsDefault]: false,
    };

    return this.execute(VerbId.SaveWorkbookAsCustomView, parameters).then<CustomViewImpl>((response) => {
      const customView = response.result as CustomViewModel;
      return new CustomViewImpl(customView, this._registryId);
    });
  }

  public setActiveCustomViewAsDefaultAsync(): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'setActiveCustomViewAsDefaultAsync',
    };

    return this.execute(VerbId.SetActiveCustomViewAsDefault, parameters).then(() => {
      return;
    });
  }

  public saveAsync(customView: CustomViewImpl): Promise<CustomViewImpl> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'saveAsync',
      [ParameterId.CustomViewLuid]: customView.luid,
      [ParameterId.CustomViewName]: customView.name,
      [ParameterId.CustomViewIsDefault]: customView.isDefault,
      [ParameterId.CustomViewIsPublic]: customView.shared,
    };

    return this.execute(VerbId.UpdateCustomView, parameters).then<CustomViewImpl>((response) => {
      const customView = response.result as CustomViewModel;
      return new CustomViewImpl(customView, this._registryId);
    });
  }

  public getCurrentSrcAsync(): Promise<string> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getCurrentSrcAsync',
    };

    return this.execute(VerbId.GetCurrentSrc, parameters).then((response) => response.result as string);
  }
}
