import { FullPageSpinner, ProtectedRoute, SuspenseRoute } from "./Components";
import { Mixpanel } from "./utils/mixpanelWrapper";
import { Router, navigate, Redirect } from "@reach/router";
import { useCompanyInfo, SetCompany } from "./redux/company";
import { useExperimentFlag } from "./utils/experiments/experiment-utils";
import { useSelector, useDispatch } from "react-redux";
import * as R from "ramda";
import * as UserRedux from "./redux/user";
import AddNewClient from "./AddNewClient";
import BrandEquity from "./BrandEquity";
import BrandHealthMetrics from "./BrandHealthMetrics";
import BudgetIntakeTool from "./BudgetIntakeTool";
import ClientInfoForm from "./Onboarding";
import CommerceSingleChannel from "./SingleChannel/Channels/CommerceSingleChannel";
import CommerceTool from "./Commerce";
import CompetitiveMetrics from "./CompetitiveMetrics";
import CompetitiveSpots from "./CompetitiveSpots";
import CreativeInsights from "./CreativeInsights";
import CreativeMap from "./CreativeMap";
import CreativeView from "./CreativeView";
import CrossChannel from "./CrossChannel";
import CustomerInsights from "./CustomerInsights";
import CustomTableau from "./CustomTableau";
import Dashboard from "./Dashboard";
import Ebay from "./Ebay/Ebay";
import ExpectedBookings from "./ExpectedBookings";
import HomePage from "./HomePage";
import Insights from "./AMC/Insights";
import KpiMapping from "./KpiMapping";
import LinearBuying from "./LinearBuying";
import LinearDelivery from "./LinearDelivery";
import LinearMLDiagnostics from "./LinearMLDiagnostics";
import LinearOptimizations from "./LinearOptimizations";
import LinearOrders from "./LinearOrders";
import LinearPerformance from "./Performance/LinearPerformance";
import LinearPlans from "./LinearPlans";
import LinearSingleChannel from "./SingleChannel/Channels/LinearSingleChannel";
import LinearUpfrontReconciliation from "./LinearUpfrontReconciliation";
import MetaBuying from "./MetaBuying";
import MMM from "./MMM";
import MobiusView from "./MobiusView";
import NotFound from "./NotFound";
import Pacing from "./Pacing";
import Properties from "./Properties";
import React, { useMemo, useEffect } from "react";
import ReferenceView from "./ReferenceView";
import Reporting from "./Reporting";
import SearchShoppingSingleChannel from "./SingleChannel/Channels/SearchShoppingSingleChannel";
import SegmentationMapping from "./SegmentationMapping/SegmentationMapping";
import Slides from "./Slides";
import SocialSingleChannel from "./SingleChannel/Channels/SocialSingleChannel";
import SpikeChart from "./SpikeChart";
import StreamingBuying from "./StreamingBuying";
import StreamingCreatives from "./StreamingCreatives";
import StreamingDelivery from "./StreamingDelivery";
import StreamingExperiments from "./StreamingExperiments";
import StreamingGeoResponse from "./StreamingGeoResponse";
import StreamingGeoTargeting from "./StreamingGeoTargeting";
import StreamingInvoicing from "./StreamingInvoicing";
import StreamingMLDiagnostics from "./StreamingMLDiagnostics";
import StreamingOptimizations from "./StreamingOptimizations";
import StreamingOrderStatus from "./StreamingOrderStatus";
import StreamingPerformance from "./Performance/StreamingPerformance";
import StreamingPlansBuilder from "./StreamingPlansBuilder";
import StreamingReports from "./StreamingReports/StreamingReports";
import StreamingSingleChannel from "./SingleChannel/Channels/StreamingSingleChannel";
import StreamingWizard from "./StreamingWizard";
import TVADCrossChannel from "./TVADCrossChannel";
import useLocation from "./utils/hooks/useLocation";
import WebTrafficOptimization from "./WebTrafficOptimization";
import YouTubeDelivery from "./YouTubeDelivery/YouTubeDelivery";
import YouTubePerformance from "./Performance/YouTubePerformance";
import YouTubePlanning from "./YouTubePlanning";
import YouTubeSingleChannel from "./SingleChannel/Channels/YouTubeSingleChannel";

const CompanyRoutes = ({ company }) => {
  const { location } = useLocation();
  const dispatch = useDispatch();
  const companies = useSelector(UserRedux.companiesSelector);
  const companyInfo = useCompanyInfo();

  const shouldEnableAudioGraphPerformance = useExperimentFlag("enableAudioGraphPerformance");
  const shouldEnableBrandHealthMetrics = useExperimentFlag("enableBrandHealthMetrics");
  const shouldEnableBrandHealthGoogle = useExperimentFlag("enableBrandHealthGoogle");
  const shouldEnableCreativeInsightsExperiment = useExperimentFlag("enableCreativeInsights");
  const shouldEnableCustomerInsightsExperiment = useExperimentFlag("enableCustomerInsights");
  const shouldEnableDisplayGraphPerformance = useExperimentFlag("enableDisplayGraphPerformance");
  const shouldEnableEbayIntegration = useExperimentFlag("enableEbayIntegration");
  const shouldEnableGraphPerformance = useExperimentFlag("enableGraphPerformance");
  const shouldEnableMobiusIntegration = useExperimentFlag("enableMobiusIntegration");
  const shouldEnableMobiusSlidesCompany = useExperimentFlag("enableMobiusSlidesCompany");
  const shouldEnableStreamingGeoTargetingExperiment = useExperimentFlag(
    "enableStreamingGeoTargeting"
  );
  const shouldNotDefaultHomeV2 = useExperimentFlag("notDefaultHomeV2");
  const shouldEnableMetaBuyingExperiment = useExperimentFlag("enableMetaBuying");

  const isValidCompany = useMemo(() => R.any(elem => elem.company === company, companies), [
    companies,
    company,
  ]);

  const role = useSelector(UserRedux.roleSelector);
  const isAdmin = role === 0;

  useEffect(() => {
    // TODO we need to set this to null for non company routes
    Mixpanel.register({ company: company });
  }, [company]);

  useEffect(() => {
    if (company !== company.toLowerCase()) {
      R.pipe(R.prop("pathname"), R.replace(company, company.toLowerCase()), navigate)(location);
      return;
    }
    if (company && isValidCompany && (!companyInfo.cid || company !== companyInfo.cid)) {
      dispatch(SetCompany(company));
    }
  }, [company, isValidCompany, companyInfo, location, dispatch]);

  if (companies && !isValidCompany) {
    return <NotFound invalidCompany={company} />;
  } else if (!companyInfo.cid) {
    return <FullPageSpinner path="*" />;
  } else {
    return (
      <Router className="bpmRouter">
        {/* Home */}
        {shouldNotDefaultHomeV2 ? (
          <>
            <Redirect noThrow from="/" to="home" />
            <HomePage key="home" path="/home" />
          </>
        ) : (
          // TODO: temporary hack to route specific companies to new crosschannel page by default
          <>
            {["cavenders", "tula"].includes(company) ? (
              <Redirect noThrow from="/" to="cross-channel" />
            ) : (
              <Redirect noThrow from="/" to="tvad-cross-channel" />
            )}
            <HomePage key="home" path="/home-archived" />
          </>
        )}
        <ProtectedRoute key="onboarding" path="/client-info/*" component={ClientInfoForm} />
        <TVADCrossChannel key="tvadcrosschannel" path="/tvad-cross-channel" />
        <CrossChannel key="crosschannel" path="/cross-channel/*urlSettings" />
        <CrossChannel key="crosschannel" path="/cross_channel/*urlSettings" />
        <CrossChannel key="crosschannel" path="/crosschannel/*urlSettings" />

        {/* Metrics */}
        <Dashboard key="dashboard" path="/dashboard" />
        <CreativeView key="creative view" path="/creatives" />

        {shouldEnableCreativeInsightsExperiment && (
          <CreativeInsights key="creative insights" path="/creative-insights" />
        )}
        <Redirect noThrow from="/creative_insights" to="../creative-insights" />
        <Redirect noThrow from="/creativeinsights" to="../creative-insights" />

        {shouldEnableCustomerInsightsExperiment && (
          <CustomerInsights key="customer insights" path="/customer-insights/*" />
        )}
        <Redirect noThrow from="/customer_insights" to="../customer-insights" />
        <Redirect noThrow from="/customerinsights" to="../customer-insights" />

        {(shouldEnableBrandHealthMetrics || shouldEnableBrandHealthGoogle) && (
          <BrandHealthMetrics key="brand health metrics" path="brand-health-metrics/*" />
        )}
        <Redirect noThrow from="/brand_health_metrics" to="../brand-health-metrics" />
        <ProtectedRoute
          key="segmentation-mapping"
          path="/segmentation-mapping/*"
          component={SegmentationMapping}
          roleRequired={2}
        />
        <ReferenceView key="reference view" path="/reference" />
        {/* Linear */}
        <LinearPerformance
          key="linear performance"
          path={"/linear/performance/*urlPresetName"}
          prefix="tv"
        />
        <SuspenseRoute
          key="linear delivery"
          path="/linear/delivery"
          component={LinearDelivery}
          prefix="linear"
        />
        <SuspenseRoute key="spike" path="/linear/spike" component={SpikeChart} />
        <ProtectedRoute key="linear buying" path="/linear/buying" component={LinearBuying} />
        <ProtectedRoute key="linear plans" path="/linear/plans" component={LinearPlans} />
        <ProtectedRoute key="linear orders" path="/linear/orders" component={LinearOrders} />
        {/* Radio */}
        <ProtectedRoute
          key="radio buying"
          path="/radio/buying"
          component={LinearBuying}
          channel="radio"
        />
        {/* Streaming */}
        <StreamingPerformance
          key="streaming performance"
          path={"/streaming/performance/*urlPresetName"}
          prefix="streaming"
          isGraph={shouldEnableGraphPerformance}
        />
        <StreamingPerformance
          key="streaming performance"
          path={"/streaming/performance_graph/*urlPresetName"}
          prefix="streaming"
          isGraph={true}
        />
        <StreamingPerformance
          key="streaming performance"
          path={"/streaming/performance_tu/*urlPresetName"}
          prefix="streaming"
          isGraph={true}
          isTransunionGraph={true}
        />
        <StreamingPerformance
          key="streaming performance"
          path={"/streaming/performance_ip/*urlPresetName"}
          prefix="streaming"
        />
        <SuspenseRoute
          key="streaming delivery"
          path="/streaming/delivery"
          component={StreamingDelivery}
          prefix="streaming"
          isGraph={shouldEnableGraphPerformance}
        />
        {/* New Single Channel Routes */}
        <ProtectedRoute
          component={StreamingSingleChannel}
          isGraph={shouldEnableGraphPerformance}
          key="streaming single channel"
          path={"/streaming/*urlSettings"}
          prefix="streaming"
          roleRequired={10}
        />
        <ProtectedRoute
          component={StreamingSingleChannel}
          isGraph={shouldEnableAudioGraphPerformance}
          key="audio single channel"
          path={"/audio/*urlSettings"}
          prefix="audio"
          roleRequired={10}
        />
        <ProtectedRoute
          component={StreamingSingleChannel}
          isGraph={shouldEnableDisplayGraphPerformance}
          key="display single channel"
          path={"/display/*urlSettings"}
          prefix="display"
          roleRequired={10}
        />
        <ProtectedRoute
          component={LinearSingleChannel}
          key="linear single channel"
          path={"/linear/*urlSettings"}
          roleRequired={10}
        />
        <ProtectedRoute
          component={LinearUpfrontReconciliation}
          key="linear upfront reconciliation"
          path={"/linear/upfront/"}
          roleRequired={10}
        />
        <ProtectedRoute
          component={YouTubeSingleChannel}
          key="youtube single channel"
          path={"/youtube/*urlSettings"}
          roleRequired={10}
        />
        <ProtectedRoute
          component={SocialSingleChannel}
          key="social single channel"
          path={"/social/*urlSettings"}
          roleRequired={10}
        />
        <ProtectedRoute
          component={SearchShoppingSingleChannel}
          key="search shopping single channel"
          path={"/search-shopping/*urlSettings"}
          roleRequired={0}
        />
        {/* */}
        {shouldEnableMetaBuyingExperiment && (
          <ProtectedRoute
            key="meta-buying"
            path="/social/meta-buying/*"
            suspense={true}
            component={MetaBuying}
          />
        )}
        <ProtectedRoute
          component={CommerceSingleChannel}
          key="commerce single channel"
          path={"/commerce/*urlSettings"}
          roleRequired={10}
        />
        {/* Youtube */}
        <ProtectedRoute
          key="youtube planning"
          path="/youtube/planning"
          component={YouTubePlanning}
        />
        <SuspenseRoute
          key="youtube performance"
          path="/youtube/performance/*urlPresetName"
          component={YouTubePerformance}
        />
        <SuspenseRoute
          key="youtube delivery"
          path="/youtube/delivery/"
          component={YouTubeDelivery}
        />
        <StreamingGeoResponse
          key="streaming geo response"
          path="/streaming/geo-response"
          prefix="streaming"
        />
        <Redirect noThrow from="/streaming/geo_response" to="../streaming/geo-reponse" />
        <StreamingGeoResponse key="audio geo response" path="/audio/geo-response" prefix="audio" />
        <Redirect noThrow from="/audio/geo_response" to="../audio/geo-reponse" />
        {shouldEnableStreamingGeoTargetingExperiment && (
          <>
            <ProtectedRoute
              key="streaming geo targeting"
              path="/streaming/geo_targeting/*orderInfo"
              component={StreamingGeoTargeting}
              prefix="streaming"
            />
            <ProtectedRoute
              key="streaming geo targeting"
              path="/streaming/geo-targeting/*orderInfo"
              component={StreamingGeoTargeting}
              prefix="streaming"
            />
          </>
        )}
        <ProtectedRoute
          key="streaming creatives"
          path="/streaming/creatives/*"
          component={StreamingCreatives}
        />
        <ProtectedRoute
          suspense
          key="streaming wizard"
          path="/streaming/wizard/*"
          component={StreamingWizard}
        />
        <ProtectedRoute
          key="streaming buying"
          path="/streaming/buying/*"
          component={StreamingBuying}
        />
        <ProtectedRoute
          key="streaming reports"
          path="/streaming/reports/*"
          component={StreamingReports}
        />
        <ProtectedRoute
          key="streaming status"
          path="/streaming/status/*"
          component={StreamingOrderStatus}
        />
        <ProtectedRoute
          key="streaming invoicing"
          path="/streaming/invoicing"
          component={StreamingInvoicing}
        />
        <ProtectedRoute
          key="streamingPlansBuilder"
          path="/streaming/plan-builder"
          component={StreamingPlansBuilder}
        />
        <Redirect noThrow from="/streaming/planbuilder" to="../streaming/plan-builder" />
        <StreamingExperiments key="streamingExperiments" path="/streaming/experiments" />
        {/* Audio */}
        <StreamingPerformance
          key="audio performance"
          path={"/audio/performance/*urlPresetName"}
          prefix="audio"
          isGraph={shouldEnableAudioGraphPerformance}
        />
        <StreamingPerformance
          key="audio performance"
          path={"/audio/performance_graph/*urlPresetName"}
          prefix="audio"
          isGraph={true}
        />
        <StreamingPerformance
          key="audio performance"
          path={"/audio/performance_tu/*urlPresetName"}
          prefix="audio"
          isGraph={true}
          isTransunionGraph={true}
        />
        <StreamingPerformance
          key="audio performance"
          path={"/audio/performance_ip/*urlPresetName"}
          prefix="audio"
        />
        <SuspenseRoute
          key="audio delivery"
          path="/audio/delivery"
          component={StreamingDelivery}
          prefix="audio"
          isGraph={shouldEnableAudioGraphPerformance}
        />
        {/* Display */}
        <StreamingPerformance
          key="display performance"
          path={"/display/performance/*urlPresetName"}
          prefix="display"
          isGraph={shouldEnableDisplayGraphPerformance}
        />
        <StreamingPerformance
          key="display performance"
          path={"/display/performance_graph/*urlPresetName"}
          prefix="display"
          isGraph={true}
        />
        <StreamingPerformance
          key="display performance"
          path={"/display/performance_tu/*urlPresetName"}
          prefix="display"
          isGraph={true}
          isTransunionGraph={true}
        />
        <StreamingPerformance
          key="display performance"
          path={"/display/performance_ip/*urlPresetName"}
          prefix="display"
        />
        <SuspenseRoute
          key="display delivery"
          path="/display/delivery"
          component={StreamingDelivery}
          prefix="display"
          isGraph={shouldEnableDisplayGraphPerformance}
        />
        <ProtectedRoute key="expectedbookings" path="/bookings" component={ExpectedBookings} />
        {shouldEnableEbayIntegration && <ProtectedRoute key="ebay" path="/ebay" component={Ebay} />}
        {/* Mobius */}
        {shouldEnableMobiusIntegration && <MobiusView key="mobius" path="/mobius/*" />}
        {shouldEnableMobiusSlidesCompany && (
          <ProtectedRoute
            suspense
            key="mobiusslides"
            path="/mobius-slides"
            component={Slides}
            roleRequired={2}
          />
        )}
        <Redirect noThrow from="/mobius_slides" to="../mobius-slides" />
        <Insights key="amc insights" path="/amc-insights/*" />
        <CommerceTool key="commerce" path="/commerce-tools" />
        {/* Competitive */}
        <CompetitiveMetrics key="competitive metrics" path="/competitive/metrics" />
        <CompetitiveSpots key="competitive spots" path="/competitive/spots" />

        {/* Channels? */}
        <MMM key="mmm" path="/mmm/*" />
        <ProtectedRoute
          key="custom tableau"
          path="/custom-dashboard/*dashboardName"
          component={CustomTableau}
        />
        <ProtectedRoute
          key="custom tableau"
          path="/customdashboard/*dashboardName"
          component={CustomTableau}
        />

        {/* Admin */}
        <ProtectedRoute key="creativemap" path="/creative-map/*" component={CreativeMap} />
        <ProtectedRoute key="creativemap" path="/creativemap/*" component={CreativeMap} />
        <ProtectedRoute key="kpiMapping" path="/kpi-mapping" component={KpiMapping} />
        <Redirect noThrow from="/powerpoint" to="../slides" />
        <ProtectedRoute suspense key="slides" path="/slides" component={Slides} />
        <ProtectedRoute
          key="linear_optimizations"
          path="/linear/optimizations/*"
          component={LinearOptimizations}
        />
        <ProtectedRoute
          key="streaming_optimizations"
          path="/streaming/optimizations/*"
          component={StreamingOptimizations}
        />
        <ProtectedRoute
          key="audio_optimizations"
          path="/audio/optimizations/*"
          component={StreamingOptimizations}
        />
        <ProtectedRoute key="reporting" path="/reporting" component={Reporting} />
        {/* Properties */}
        <ProtectedRoute key="properties" path="/properties" component={Properties} />
        <ProtectedRoute
          key="diagnostics"
          path="/linear/diagnostics/*"
          component={LinearMLDiagnostics}
        />
        <ProtectedRoute
          key="diagnostics"
          path="/streaming/diagnostics/*"
          component={StreamingMLDiagnostics}
        />
        <ProtectedRoute
          key="budgetintaketool"
          path="budget-intake-tool"
          component={BudgetIntakeTool}
        />
        <Redirect noThrow from="/budgetintaketool" to="../budget-intake-tool" />
        <Redirect noThrow from="/budget_intake_tool" to="../budget-intake-tool" />
        <Redirect noThrow from="/budget-intake" to="../budget-intake-tool" />
        <Redirect noThrow from="/budgetintake" to="../budget-intake-tool" />
        <Redirect noThrow from="/budget_intake" to="../budget-intake-tool" />
        <ProtectedRoute key="pacing" path="pacing" component={Pacing} />
        <ProtectedRoute key="wto" path="/wto" component={WebTrafficOptimization} />
        {isAdmin && (
          <ProtectedRoute
            key="client tools"
            path="/edit-client"
            component={AddNewClient}
            existingClient={true}
          />
        )}
        <Redirect noThrow from="/edit_client" to="../edit-client" />
        <ProtectedRoute key="brandequity" path="brand-equity/*" component={BrandEquity} />
        <Redirect noThrow from="/brand_equity" to="../brand-equity" />
        <Redirect noThrow from="/brandequity" to="../brand-equity" />
        <NotFound key="404" default />
      </Router>
    );
  }
};

export default CompanyRoutes;
