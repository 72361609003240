import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as uuid from "uuid";
import {
  awaitJSON,
  awaitText,
  StreamingPerformanceLambdaFetch,
  StreamingV2LambdaFetch,
} from "../utils/fetch-utils";
import {
  BarGraphSkeleton,
  BPMButton,
  CheckBox,
  CircleSkeleton,
  DateRangePicker,
  DropdownOption,
  OldFilterBar,
  FilterPane,
  ItemListSkeleton,
  OldDropdown as Dropdown,
  OverlayTrigger,
  Page,
  RectSkeleton,
  Skeleton,
  Spinner,
  BPMToggleButton,
  useFilterPaneState,
} from "../Components";
import "./StreamingDelivery.scss";
import { Button, Tooltip } from "react-bootstrap";
import { DateRange, Setter, StateSetter } from "../utils/types";
import { DeviceColorMap, getSeriesColor } from "../utils/colors";
import { download, convertSVGStringToPNGURI } from "../utils/download-utils";
import { FilterPaneState } from "@blisspointmedia/bpm-types/dist/FilterPane";
import { formatNumberAsInt, formatMoney, formatMoneyAsInt } from "../utils/format-utils";
import { getGlobalBrand } from "../Performance/performanceUtils";
import { MdInfoOutline, MdExpandMore, MdExpandLess, MdImage, MdDescription } from "react-icons/md";
import { ModalError, useSetError } from "../redux/modals";
import { ReactComponent as ECPMIcon } from "./eCPM.svg";
import { ReactComponent as ImpressionsIcon } from "./impressions.svg";
import { ReactComponent as SpendIcon } from "./spend.svg";
import { TEST_COMPANIES, TEST_CREATIVES } from "@blisspointmedia/bpm-types/dist/TestCompanies";
import { useCompanyInfo } from "../redux/company";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import DeliveryChart from "./DeliveryChart";
import Legend from "./Legend";
import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from "react";
import useLocation from "../utils/hooks/useLocation";

const DEFAULT_DAILY_SETTING = true;
const DEFAULT_SPEND_SETTING = false;
export interface DateExtrema {
  min: string;
  max: string;
}

export interface CreativeKeyInfo {
  isci: string;
  creative: string;
  length: string;
}
export interface NetworkKeyInfo {
  network: string;
  description: string;
}
interface DeliveryNetworkKeyInfo {
  network: string;
}

export type KeyData = boolean | CreativeKeyInfo | NetworkKeyInfo | DeliveryNetworkKeyInfo;

export type ActiveKeyMap = Record<string, boolean>;
export type ActiveKeyMapMap = Record<string, ActiveKeyMap | undefined>;

export type SetActiveKeyMap = Setter<string | Record<string, boolean>>;
export interface ColorMap {
  (key: string): string;
}

export type OtherMap = Record<DeliveryDate, Record<DeliveryKey, boolean>>;
interface StreamingDeliveryFetchProps {
  company: string;
  dates: Partial<DateRange>;
  dimension: string;
  filterID: number | undefined;
  filterState: FilterPaneState;
  prefix: S.Prefix;
  vod: boolean;
}

interface StreamingDeliveryFetchSetters {
  setDates: Setter<DateRange>;
  setDimension: Setter<string>;
  setFilterID: StateSetter<number | undefined>;
  setFilterState: StateSetter<FilterPaneState>;
  setVOD: Setter<boolean>;
}

interface StreamingDeliveryUISettings {
  daily: boolean;
  scaleAbsolute: boolean;
  spendMetric: boolean;
}

interface StreamingDeliveryUISetters {
  setDaily: Setter<boolean>;
  setScaleAbsolute: Setter<boolean>;
  setSpendMetric: Setter<boolean>;
}

interface StreamingDeliveryUIState {
  activeKeyMap: Record<string, boolean>;
  brands?: { cid: string; name: string }[];
  chartLoaded: boolean;
  dateExtrema?: DateExtrema;
  expanded: boolean;
  hoverKey?: string;
  keyInfo: Record<string, KeyData | undefined>;
  setActiveKeyMap: SetActiveKeyMap;
  setBrand: Setter<string>;
  setExpanded: Setter<boolean>;
  setHoverKey: StateSetter<string | undefined>;
}

type StreamingDeliveryContextType = StreamingDeliveryFetchProps &
  StreamingDeliveryUISettings &
  StreamingDeliveryUIState &
  StreamingDeliveryFetchSetters &
  StreamingDeliveryUISetters & { isGraph: boolean };

export const StreamingDeliveryContext = React.createContext<StreamingDeliveryContextType>({
  activeKeyMap: {},
  brands: [],
  chartLoaded: false,
  company: "",
  daily: DEFAULT_DAILY_SETTING,
  dateExtrema: {
    min: "",
    max: "",
  },
  dates: {
    start: "",
    end: "",
  },
  dimension: "network_group",
  expanded: false,
  filterID: undefined,
  filterState: { advanced: "", basic: { notMap: {}, selectedMap: {} }, isAdvanced: false },
  hoverKey: "",
  isGraph: false,
  keyInfo: {},
  prefix: "streaming",
  scaleAbsolute: false,
  setActiveKeyMap: () => {},
  setBrand: () => {},
  setDaily: () => {},
  setDates: () => {},
  setDimension: () => {},
  setExpanded: () => {},
  setFilterID: () => {},
  setFilterState: () => {},
  setHoverKey: () => {},
  setScaleAbsolute: () => {},
  setSpendMetric: () => {},
  setVOD: () => {},
  spendMetric: DEFAULT_SPEND_SETTING,
  vod: false,
});

const DATE_FORMAT = "yyyy-MM-dd";

export const DIMENSIONS: Record<string, string> = {
  network_group: "Network Group",
  network: "Network",
  creative: "Creative",
  length: "Length",
  size: "Size",
  deviceos: "Device + OS",
};

export const useColorMap = (colorSource: Record<string, string | undefined>): ColorMap => {
  const seriesIRef = useRef(0);
  const colorMapRef = useRef<Record<string, string>>({});
  return useCallback<ColorMap>(
    (key: string) => {
      if (colorMapRef.current[key]) {
        return colorMapRef.current[key];
      }
      let definedColor = colorSource[key];
      if (definedColor) {
        colorMapRef.current[key] = definedColor;
        return definedColor;
      }
      let newColor = getSeriesColor(seriesIRef.current++);

      colorMapRef.current[key] = newColor;

      return newColor;
    },
    [colorSource]
  );
};

export const fetchStreamingDeliveryDateExtrema = async (
  company: string,
  platform: string
): Promise<DateExtrema> => {
  let res = await StreamingV2LambdaFetch("/delivery_dates", {
    params: { company, platform },
  });
  return (await awaitJSON(res)) as DateExtrema;
};
export const computeDefaultDateRange = (
  dateRange: DateExtrema,
  endOnSunday?: boolean
): DateRange => {
  let end = endOnSunday ? R.pipe(Dfns.startOfISOWeek, Dfns.subDays(1))(new Date()) : new Date();
  end = Dfns.parseISO(R.min(Dfns.format(DATE_FORMAT, end), dateRange.max));
  const yesterday = Dfns.format(DATE_FORMAT, Dfns.subDays(1, new Date()));
  let start = R.max(
    R.pipe(Dfns.subWeeks(6), Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(end),
    dateRange.min
  );

  return {
    start,
    end: R.max(start, R.min(yesterday, R.min(Dfns.format(DATE_FORMAT, end), dateRange.max))),
  };
};

export const useStreamingDeliveryDateExtrema = (
  company: string,
  platform: string
): [DateExtrema | undefined, DateRange | undefined] => {
  const setError = useSetError();
  const [dateRange, setDateRange] = useState<DateExtrema | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!dateRange && !loading && company && setError) {
      setLoading(true);
      (async () => {
        try {
          let dates = await fetchStreamingDeliveryDateExtrema(company, platform);
          setDateRange(dates);
        } catch (e) {
          let error = e as Error;
          setError({
            message: `Could not get delivery date range. Error: ${error.message}`,
            reportError: error,
          });
        }
      })();
    }
  }, [company, dateRange, setError, loading, platform]);

  const defaultDateRange = useMemo<DateRange | undefined>(() => {
    if (dateRange) {
      return computeDefaultDateRange(dateRange);
    }
  }, [dateRange]);
  return [dateRange, defaultDateRange];
};

interface DailyDeliveryDatum {
  cost: number;
  ratedCost: number;
  count: number;
}
export type DeliveryKey = string;
type DeliveryDate = string;
export type DailyDeliveryDataMap = Record<DeliveryDate, Record<DeliveryKey, DailyDeliveryDatum>>;
export interface DailyDeliveryData {
  keys: Record<DeliveryKey, KeyData>;
  daily: DailyDeliveryDataMap;
  weekly: DailyDeliveryDataMap;
}

export const fetchDailyDelivery: (
  props: StreamingDeliveryFetchProps
) => Promise<DailyDeliveryData> = async ({
  company,
  dates,
  dimension,
  filterID,
  filterState,
  prefix,
  vod,
}) => {
  let newData: DailyDeliveryData = {
    keys: {},
    daily: {},
    weekly: {},
  };
  if (!(dates.start && dates.end)) {
    return newData;
  }
  // TODO: pull daily vs weekly separately to make initial load (that's only weekly)
  // faster
  let res = await StreamingV2LambdaFetch("/delivery_v2", {
    params: {
      ...(dates as DateRange),
      company,
      dimension,
      filter_id: filterID,
      filter_state: filterState ? JSON.stringify(filterState) : undefined,
      include_vod: vod ? 1 : 0,
      streaming_type: prefix,
    },
  });
  let result = await awaitJSON(res);

  for (let line of result) {
    let count = parseFloat(line.count);

    let key = "";
    let keyData: KeyData = true;
    switch (dimension) {
      case "deviceos":
        key = `${line.device}_${line.os}`;
        break;
      case "creative":
        // If on a test company, randomly use a fake creative so that real client creative names/thumbnails aren't displayed.
        if (TEST_COMPANIES.includes(company)) {
          const random = Math.floor(Math.random() * TEST_CREATIVES.length);
          const { name, isci } = TEST_CREATIVES[random];
          key = `${isci}_${name}_${line.length}`;
          keyData = {
            isci: isci,
            creative: name,
            length: line.length,
          };
        } else {
          key = `${line.isci}_${line.creative}_${line.length}`;
          keyData = {
            isci: line.isci,
            creative: line.creative,
            length: line.length,
          };
        }
        break;
      case "network":
        key =
          line.network === line.description ? line.network : `${line.network} ${line.description}`;
        keyData = {
          network: line.network,
          description: line.description,
        };
        break;
      default:
        key = line[dimension];
        break;
    }
    newData.keys[key] = keyData;
    let { cost, date } = line;
    const ourData = { cost, count };
    let dataObj = {
      [key]: ourData,
    };
    // Need a custom function because R.sum takes an array :eye-roll:
    newData = R.mergeDeepWith((a: number, b: number) => a + b, newData, {
      daily: {
        [date]: dataObj,
      },
    }) as DailyDeliveryData;
  }

  let allDays = Dfns.eachDayOfInterval({
    start: Dfns.parseISO(dates.start),
    end: Dfns.parseISO(dates.end),
  });
  for (let day of allDays) {
    let formatted = Dfns.format(DATE_FORMAT, day);
    let val = newData.daily[formatted];
    if (!val) {
      newData.daily[formatted] = {};
    }
  }

  // Do the weekly grouping in a separate loop. The date function is expensive, so instead
  // of doing it for every line, we can do it with the lines already grouped by date.
  for (let date of R.keys(newData.daily)) {
    let week = R.pipe(Dfns.parseISO, Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(date);
    let dataObj = newData.daily[date];
    newData = R.mergeDeepWith((a, b) => a + b, newData, {
      weekly: {
        [week]: dataObj,
      },
    }) as DailyDeliveryData;
  }
  return newData;
};

export const useStreamingDelivery = ({
  company,
  dates,
  dimension,
  vod,
  prefix,
  setError,
  filterState,
  filterID,
}: StreamingDeliveryFetchProps & {
  setError: (error: ModalError) => Promise<void>;
}): DailyDeliveryData | undefined => {
  const [data, setData] = useState<Record<string, DailyDeliveryData | undefined>>({});
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});
  let filterStateHash = 0;
  if (filterState) {
    const filterStateString = JSON.stringify(filterState);
    for (let i = 0; i < filterStateString.length; i++) {
      const ch = filterStateString.charCodeAt(i);
      filterStateHash = (filterStateHash << 5) - filterStateHash + ch;
      filterStateHash = filterStateHash & filterStateHash;
    }
  }
  const currentKey = `${company}_${dates.start}_${dates.end}_${dimension}_${vod}_${filterID}_${filterStateHash}`;

  useEffect(() => {
    try {
      if (company && dates.start && dates.end && dimension) {
        if (data[currentKey] || loadingMap[currentKey]) {
          return;
        }
        setLoadingMap(map => ({ ...map, [currentKey]: true }));
        (async () => {
          try {
            let newData = await fetchDailyDelivery({
              dates,
              company,
              dimension,
              vod,
              prefix,
              filterState,
              filterID,
            });
            setData(data => ({
              ...data,
              [currentKey]: newData,
            }));
          } catch (e) {
            let error = e as Error;
            console.error(error);
            setError({
              message: `Couldn't fetch streaming delivery data. Error: '${error.message}'`,
            });
          }
        })();
      }
    } catch (e) {
      let error = e as Error;
      console.error(error);
      setError(error);
    }
  }, [
    company,
    currentKey,
    data,
    dates,
    dimension,
    filterID,
    filterState,
    loadingMap,
    prefix,
    setError,
    vod,
  ]);

  return data[currentKey];
};

export interface TotalSummary {
  cost: number;
  count: number;
  ecpm: number;
}

export interface Totals extends TotalSummary {
  totals: Record<string, TotalSummary>;
}

export const computeTotals = ({
  data,
  activeKeyMap,
  daily,
}: {
  data: DailyDeliveryData;
  activeKeyMap: ActiveKeyMap;
  daily: boolean;
}): Totals => {
  let ourData = data[daily ? "daily" : "weekly"];

  let totals: Record<string, TotalSummary> = {};
  let totalCost = 0;
  let totalCount = 0;

  for (let date of R.keys(ourData)) {
    let group = ourData[date];
    let cost = 0;
    let count = 0;
    for (let key of R.keys(group)) {
      if (activeKeyMap && !activeKeyMap[key]) {
        continue;
      }
      let keyData = group[key];
      cost += keyData.cost;
      count += keyData.count;
    }
    totalCost += cost;
    totalCount += count;
    totals[date] = {
      cost,
      count,
      ecpm: count ? (1000 * cost) / count : 0,
    };
  }
  return {
    cost: totalCost,
    count: totalCount,
    ecpm: totalCount ? (1000 * totalCost) / totalCount : 0,
    totals,
  };
};

export const useBrands = (): [
  string,
  { name: string; cid: string }[] | undefined,
  (company: string) => void
] => {
  const companyInfo = useCompanyInfo();
  const [company, setCompany] = useState(() => {
    if (companyInfo.sub_companies?.length) {
      return companyInfo.sub_companies[0].cid;
    }
    return companyInfo.cid;
  });
  return [company, companyInfo?.sub_companies, setCompany];
};

const StreamingDeliveryActions = () => {
  const {
    brands,
    company,
    dateExtrema,
    dates,
    dimension,
    expanded,
    filterID,
    filterState,
    prefix,
    setBrand,
    setDates,
    setDimension,
    setExpanded,
    setFilterID,
    setFilterState,
  } = useContext(StreamingDeliveryContext);

  const currentBrand = useMemo(() => {
    if (!brands) {
      return null;
    }
    return R.find(({ cid }) => cid === company, brands);
  }, [brands, company]);

  const setError = useSetError();
  const companyInfo = useCompanyInfo();
  const globalBrand = getGlobalBrand(companyInfo, companyInfo.streaming_performance_default_kpi);
  const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();

  useEffect(() => {
    if (company && !filterData) {
      (async () => {
        try {
          const res = await StreamingPerformanceLambdaFetch<S.GetFilterOptionsParams>(
            "/filter_options",
            {
              params: {
                company: globalBrand || company,
                prefix,
              },
            }
          );
          const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
          setFilterData(options);
        } catch (e) {
          let error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
      })();
    }
  }, [company, filterData, setError, prefix, globalBrand]);

  return (
    <div className="streamingDeliveryActions">
      <div>
        {currentBrand && (
          <div className="labeledControl">
            <Dropdown
              size="sm"
              label="Brand"
              value={currentBrand.cid}
              options={(brands || []).map(({ cid, name }) => ({ value: cid, label: name }))}
              onChange={cid => setBrand(cid)}
            />
          </div>
        )}
        <div className="labeledControl">
          <Dropdown
            size="sm"
            label="Dimension"
            value={dimension}
            options={R.pipe<Record<string, string>, string[], DropdownOption[]>(
              R.keys,
              R.map(value => ({ value, label: DIMENSIONS[value] }))
            )(DIMENSIONS)}
            onChange={val => {
              setDimension(val);
            }}
          />
        </div>
        {filterData && (
          <FilterPane
            categories={filterData}
            company={company}
            filterID={filterID}
            highlightWhenFiltered
            platform={prefix}
            save={setFilterState}
            setFilterID={setFilterID}
            setState={() => {}}
            state={filterState}
          />
        )}
        {dates.start && dates.end && dateExtrema && (
          <DateRangePicker
            startDate={dates.start}
            endDate={dates.end}
            startDateId="strDelivStart"
            endDateId="strDelivEnd"
            isOutsideRange={date => date < dateExtrema.min || date > dateExtrema.max}
            onChange={({ startDate, endDate }) => setDates({ start: startDate, end: endDate })}
          />
        )}

        <Button
          variant={expanded ? "primary" : "outline-primary"}
          onClick={() => setExpanded(!expanded)}
          size="sm"
        >
          {expanded ? <MdExpandLess /> : <MdExpandMore />}
        </Button>
      </div>
    </div>
  );
};

const StreamingDeliverySubActions = ({ chartRef }) => {
  const {
    chartLoaded,
    company,
    daily,
    dates,
    dimension,
    expanded,
    filterID,
    filterState,
    keyInfo,
    prefix,
    scaleAbsolute,
    setActiveKeyMap,
    setDaily,
    setScaleAbsolute,
    setSpendMetric,
    setVOD,
    spendMetric,
    vod,
  } = useContext(StreamingDeliveryContext);
  const id = useMemo(() => uuid.v4(), []);

  const setError = useSetError();

  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const downloadCSV = useCallback(() => {
    setDownloadingCSV(true);
    (async () => {
      try {
        let urlResp = await StreamingV2LambdaFetch("/delivery_csv_v2", {
          params: {
            ...(dates as DateRange),
            company,
            filter_id: filterID,
            filter_state: JSON.stringify(filterState),
            media_type: prefix,
          },
        });
        let urlRes = await awaitJSON<{ url: string }>(urlResp);
        let resp = await fetch(urlRes.url);
        let result = await awaitText(resp);
        download(result, `Streaming Delivery ${dates.start} to ${dates.end}.csv`, "text/csv");
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Couldn't fetch CSV. Error: ${error.message}`,
          reportError: error,
        });
      }

      setDownloadingCSV(false);
    })();
  }, [company, dates, filterID, filterState, prefix, setError]);

  const downloadPNG = useCallback(() => {
    if (chartLoaded && chartRef.current) {
      let svg = chartRef.current.container.getElementsByTagName("svg")[0];
      let boundingBox = svg.getBoundingClientRect();
      let svgString = convertSVGStringToPNGURI(
        svg.outerHTML,
        boundingBox.width * 2,
        boundingBox.height * 2
      );
      download(svgString, "daily_delivery.png", "image/png");
    }
  }, [chartRef, chartLoaded]);

  const hasKeyInfo = useMemo(() => {
    if (!keyInfo) {
      return false;
    }
    let keys = R.keys(keyInfo);
    let firstElem = keys[0];
    return typeof keyInfo[firstElem] === "object";
  }, [keyInfo]);
  const filterOptions = useMemo(() => {
    if (hasKeyInfo) {
      return R.pipe(R.values, R.nth(0), R.keys)(keyInfo);
    }
    return [
      {
        label: DIMENSIONS[dimension],
        name: dimension,
      },
    ];
  }, [keyInfo, dimension, hasKeyInfo]);

  const filterLines = useMemo(() => {
    if (hasKeyInfo) {
      return R.values(keyInfo);
    }
    return R.pipe(
      R.keys,
      R.map(key => ({ [dimension]: key }))
    )(keyInfo);
  }, [hasKeyInfo, keyInfo, dimension]);

  const onFilter = useCallback(
    filter => {
      let newMap = {};
      let keys = R.keys(keyInfo);
      for (let key of keys) {
        newMap[key] = filter(hasKeyInfo ? keyInfo[key] : { [dimension]: key });
      }
      setActiveKeyMap(newMap);
    },
    [keyInfo, setActiveKeyMap, dimension, hasKeyInfo]
  );
  return (
    <div
      className="subActions"
      style={{
        display: expanded ? "flex" : "none",
      }}
    >
      <div className="filterBar">
        <OldFilterBar
          options={filterOptions}
          lines={filterLines as Record<string, string | number | boolean>[]}
          onFilter={onFilter}
        />
      </div>
      <div className="labeledControl">
        <span className="label">Download</span>
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={<Tooltip id={`${id}_csv_tooltip`}>CSV</Tooltip>}
        >
          <BPMButton
            className="saveButton"
            disabled={downloadingCSV}
            variant="outline-primary"
            onClick={downloadCSV}
          >
            {downloadingCSV ? <Spinner color="white" size={16} /> : <MdDescription />}
          </BPMButton>
        </OverlayTrigger>
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={<Tooltip id={`${id}_png_tooltip`}>Image</Tooltip>}
        >
          <BPMButton disabled={!chartLoaded} variant="outline-primary" onClick={downloadPNG}>
            <MdImage />
          </BPMButton>
        </OverlayTrigger>
      </div>

      <div className="labeledControl">
        <span className="label">VOD</span>
        <CheckBox checked={vod} onCheck={setVOD} size="md" />
      </div>
      <BPMToggleButton
        options={[
          {
            key: "Daily",
          },
          {
            key: "Weekly",
          },
        ]}
        selectedOption={daily ? "Daily" : "Weekly"}
        onChange={key => setDaily(key === "Daily")}
      />
      <BPMToggleButton
        options={[
          {
            key: "Spend",
          },
          {
            key: "Impressions",
          },
        ]}
        selectedOption={spendMetric ? "Spend" : "Impressions"}
        onChange={key => setSpendMetric(key === "Spend")}
      />
      <BPMToggleButton
        options={[
          {
            key: "Absolute",
          },
          {
            key: "Relative",
          },
        ]}
        selectedOption={scaleAbsolute ? "Absolute" : "Relative"}
        onChange={key => setScaleAbsolute(key === "Absolute")}
      />
    </div>
  );
};

interface InfoCardProps {
  title: string;
  info: string;
  data: number | undefined;
  formatter: (datum: number) => string;
  icon: JSX.Element;
  onLeft?: boolean;
  notAvailable?: boolean;
}

export const InfoCard = React.memo(
  ({ title, info, data, formatter, icon, onLeft, notAvailable }: InfoCardProps) => (
    <div className="box infoCard">
      {R.isNil(data) ? (
        <div className="skeletonBox">
          <Skeleton>
            <CircleSkeleton />
            <RectSkeleton x={70} height={15} width={width => width - 70} rx={6} ry={6} />
            <RectSkeleton
              x={70}
              height={25}
              width={width => width - 70}
              y={height => height - 25}
              rx={6}
              ry={6}
            />
          </Skeleton>
        </div>
      ) : (
        <div>
          <div className="iconContainer">{icon}</div>
          <div className="info">
            <div className="title">
              {title}
              <span className="infoIcon">
                <OverlayTrigger
                  placement={
                    onLeft
                      ? OverlayTrigger.PLACEMENTS.LEFT.TOP
                      : OverlayTrigger.PLACEMENTS.RIGHT.TOP
                  }
                  overlay={<Tooltip id={`tooltip_${title}`}>{info}</Tooltip>}
                >
                  <MdInfoOutline />
                </OverlayTrigger>
              </span>
            </div>
            <div className="value">{notAvailable ? "Not available" : formatter(data)}</div>
          </div>
        </div>
      )}
    </div>
  )
);

type StreamingDeliverySettingsHook = StreamingDeliveryUISettings & { vod: boolean };

type StreamingDeliverySettingsHookSetters = StreamingDeliveryUISetters & {
  setVOD: (vod: boolean) => void;
};

const useStreamingDeliverySettings = (): StreamingDeliverySettingsHook &
  StreamingDeliverySettingsHookSetters => {
  const { company } = useLocation();

  const localStorageKey = `BPMStreamingDeliverySettings${company}`;

  const [settings, setSettings] = useState<StreamingDeliverySettingsHook>(() => ({
    vod: false,
    daily: DEFAULT_DAILY_SETTING,
    spendMetric: DEFAULT_SPEND_SETTING,
    scaleAbsolute: true,
    ...(JSON.parse(window.localStorage.getItem(localStorageKey) || "{}") || {}),
  }));

  const setSettingsSetters = useMemo<StreamingDeliverySettingsHookSetters>(() => {
    if (company) {
      let setSetting = (key: string) => (val: boolean) => {
        setSettings(settings => {
          let newSettings = {
            ...settings,
            [key]: val,
          };
          window.localStorage.setItem(localStorageKey, JSON.stringify(newSettings));
          return newSettings;
        });
      };
      return {
        setVOD: setSetting("vod"),
        setDaily: setSetting("daily"),
        setSpendMetric: setSetting("spendMetric"),
        setScaleAbsolute: setSetting("scaleAbsolute"),
      };
    }
    return {
      setVOD: () => {},
      setDaily: () => {},
      setSpendMetric: () => {},
      setScaleAbsolute: () => {},
    };
  }, [localStorageKey, company]);
  return {
    ...settings,
    ...setSettingsSetters,
  };
};

const useActiveKeyMap = ({
  data,
  dimension,
}: {
  data: DailyDeliveryData | undefined;
  dimension: string;
}): [ActiveKeyMap, (key: string | Record<string, boolean>) => void] => {
  const [activeKeyMapMap, setActiveKeyMapMap] = useState<ActiveKeyMapMap>({});

  const setActiveKeyMap = useCallback(
    (arg: string | Record<string, boolean>) => {
      if (!data) {
        return;
      }
      if (typeof arg !== "string") {
        setActiveKeyMapMap(mapMap => ({
          ...mapMap,
          [dimension]: {
            ...mapMap[dimension],
            ...arg,
          },
        }));
      } else if (arg === "all" || arg === "none") {
        const newSettings = R.pipe(
          R.keys,

          R.reduce((map, subKey) => {
            map[subKey] = arg === "all";
            return map;
          }, {})
        )(data.keys);

        setActiveKeyMapMap({
          ...activeKeyMapMap,
          [dimension]: newSettings,
        });
      } else {
        let val = R.path([dimension, arg], activeKeyMapMap);
        if (R.isNil(val)) {
          val = false;
        } else {
          val = !val;
        }
        setActiveKeyMapMap(R.assocPath([dimension, arg], val));
      }
    },
    [data, dimension, setActiveKeyMapMap, activeKeyMapMap]
  );

  const activeKeyMap = useMemo(() => {
    let existingMap = activeKeyMapMap[dimension] || {};
    let keys = R.keys(data?.keys);
    return R.reduce<string, ActiveKeyMap>(
      (map, key) => {
        if (R.isNil(existingMap[key])) {
          map[key] = true;
        } else {
          map[key] = existingMap[key];
        }
        return map;
      },
      {},
      keys
    );
  }, [dimension, activeKeyMapMap, data]);

  return [activeKeyMap, setActiveKeyMap];
};

const OTHER_THRESHOLD = 10;

export const useOtherMap = (
  data: DailyDeliveryData | undefined,
  activeKeyMap: ActiveKeyMap,
  daily = DEFAULT_DAILY_SETTING,
  spendMetric = DEFAULT_SPEND_SETTING
): OtherMap =>
  useMemo(() => {
    if (!data) {
      return {};
    }
    const ourData = data[daily ? "daily" : "weekly"];
    let map = {};
    for (let date of R.keys(ourData)) {
      let itemMap = ourData[date];
      let items: { key: string; value: number }[] = [];
      for (let key of R.keys(itemMap)) {
        if (activeKeyMap[key]) {
          let item = itemMap[key];
          items.push({
            key,
            value: item[spendMetric ? "cost" : "count"],
          });
        }
      }
      items = R.sort(R.descend<{ value: number }>(R.prop("value")), items);
      let mapItem: Record<DeliveryKey, boolean> = {};
      for (let i = 0; i < items.length; ++i) {
        mapItem[items[i].key] = i >= OTHER_THRESHOLD;
      }
      map[date] = mapItem;
    }
    return map;
  }, [data, activeKeyMap, daily, spendMetric]);

const StreamingDelivery: React.FC<{
  isGraph: boolean;
  prefix: S.Prefix;
}> = ({ isGraph, prefix }) => {
  const [company, brands, setBrand] = useBrands();
  const [dateExtrema, defaultDateRange] = useStreamingDeliveryDateExtrema(company, prefix);
  const [dates, setDates] = useState<Partial<DateRange>>({});
  const [filterID, setFilterID] = useState<number | undefined>();
  const [filterState, setFilterState] = useFilterPaneState();
  const chartRef = useRef();
  const defaultColorMap = useColorMap({});
  const deviceColorMap = useColorMap(DeviceColorMap);
  const networkColorMap = useColorMap({});
  const networkGroupColorMap = useColorMap({});

  useEffect(() => {
    if (defaultDateRange) {
      setDates(defaultDateRange);
    }
  }, [defaultDateRange]);

  const {
    vod,
    daily,
    spendMetric,
    scaleAbsolute,
    setVOD,
    setDaily,
    setSpendMetric,
    setScaleAbsolute,
  } = useStreamingDeliverySettings();

  const [dimension, setDimension] = useState(R.keys(DIMENSIONS)[0]);

  const setError = useSetError();
  const data = useStreamingDelivery({
    dates,
    dimension,
    vod,
    prefix,
    company,
    setError,
    filterState,
    filterID,
  });

  const [activeKeyMap, setActiveKeyMap] = useActiveKeyMap({ data, dimension });

  const otherMap = useOtherMap(data, activeKeyMap, daily, spendMetric);

  const { cost, ecpm, count, totals } = useMemo<Partial<Totals>>(() => {
    if (!(data && activeKeyMap)) {
      return {};
    }
    return computeTotals({
      data,
      activeKeyMap,
      daily,
    });
  }, [data, activeKeyMap, daily]);

  let colorMap: ColorMap;

  switch (dimension) {
    case "network_group":
      colorMap = networkGroupColorMap;
      break;
    case "network":
      colorMap = networkColorMap;
      break;
    case "deviceos":
      colorMap = deviceColorMap;
      break;
    default:
      colorMap = defaultColorMap;
      break;
  }

  let title = "Streaming Delivery";
  if (prefix === "audio") {
    title = "Audio Delivery";
  } else if (prefix === "olv") {
    title = "Online Video Delivery";
  } else if (prefix === "display") {
    title = "Display Delivery";
  }

  const chartLoaded = Boolean(data && totals);
  const [expanded, setExpanded] = useState(false);
  const [hoverKey, setHoverKey] = useState<string>();

  // Kind of a hack to make creative filter-bar-able. Would be nice to change backend to put this
  // metadata in data.keys.
  const filterKeyInfo = useMemo(() => {
    let ourKeyInfo = data?.keys || {};
    if (dimension === "creative") {
      let keys = R.keys(ourKeyInfo);
      let newOurKeyInfo = {};
      for (let key of keys) {
        const [isci, creative, length] = key.split("_");
        newOurKeyInfo[key] = { isci, creative, length };
      }
      ourKeyInfo = newOurKeyInfo;
    }
    return ourKeyInfo;
  }, [data, dimension]);

  let context = {
    activeKeyMap,
    brands,
    chartLoaded,
    company,
    daily,
    dateExtrema,
    dates,
    dimension,
    expanded,
    filterID,
    filterState,
    hoverKey,
    isGraph,
    keyInfo: filterKeyInfo,
    prefix,
    scaleAbsolute,
    setActiveKeyMap,
    setBrand,
    setDaily,
    setDates,
    setDimension,
    setExpanded,
    setFilterID,
    setFilterState,
    setHoverKey,
    setScaleAbsolute,
    setSpendMetric,
    setVOD,
    spendMetric,
    vod,
  };

  return (
    <StreamingDeliveryContext.Provider value={context}>
      <Page minHeight="700px" title={title} pageType={title} actions={<StreamingDeliveryActions />}>
        <div className="streamingDelivery">
          <StreamingDeliverySubActions chartRef={chartRef} />
          <div className="summaries">
            <InfoCard
              title="Impressions"
              icon={<ImpressionsIcon />}
              data={count}
              formatter={formatNumberAsInt}
              info="The total number of impressions served between the selected start and end dates (inclusive)."
            />
            <InfoCard
              title="eCPM"
              icon={<ECPMIcon />}
              data={ecpm}
              formatter={formatMoney}
              info="The effective CPM: the total cost of the media delivered divided by the total number of impressions served (in thousands) between the selected start and end dates. Any over-delivery of impressions (which reduces eCPM) will be reflected here."
            />
            <InfoCard
              title="Spend"
              icon={<SpendIcon />}
              data={cost}
              formatter={formatMoneyAsInt}
              info="The total cost of media delivered between the selected start and end dates, inclusive of media cost and ad serving fees."
            />
          </div>
          <div className="box chartBox">
            <div className="chart">
              {chartLoaded && data && totals ? (
                <DeliveryChart
                  ref={chartRef}
                  data={data[daily ? "daily" : "weekly"]}
                  totals={totals}
                  activeKeyMap={activeKeyMap}
                  absolute={scaleAbsolute}
                  isSpend={spendMetric}
                  colorMap={colorMap}
                  daily={daily}
                  dimension={dimension}
                  otherMap={otherMap}
                />
              ) : (
                <Skeleton>
                  <BarGraphSkeleton
                    barWidth={daily ? 14 : 55}
                    gutter={daily ? 10 : 50}
                    verticalPadding={15}
                    horizontalPadding={50}
                  />
                </Skeleton>
              )}
            </div>
            <div className="legend">
              {chartLoaded && activeKeyMap && colorMap ? (
                <Legend
                  activeKeyMap={activeKeyMap}
                  setActiveKeyMap={setActiveKeyMap}
                  colorMap={colorMap}
                  dimension={dimension}
                  keyInfo={data?.keys}
                />
              ) : (
                <div className="loadingLegend">
                  <Skeleton>
                    <ItemListSkeleton size={50} gutter={20} verticalPadding={0} />
                    <ItemListSkeleton
                      size={15}
                      gutter={55}
                      verticalPadding={55}
                      horizontalPadding={-20}
                    />
                    <ItemListSkeleton size={50} gutter={20} verticalPadding={90} />
                    <ItemListSkeleton
                      size={15}
                      gutter={55}
                      verticalPadding={145}
                      horizontalPadding={-20}
                    />
                  </Skeleton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Page>
    </StreamingDeliveryContext.Provider>
  );
};

export default StreamingDelivery;
