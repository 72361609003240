import React, { useEffect, useMemo, useState } from "react";
import { CampaignRow, AdSetRow, AdRow } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { Modal } from "react-bootstrap";
import { Button, ButtonType, Spinner } from "../../../Components";
import "./ReviewModal.scss";
import { ButtonFrameworkVariant } from "../../../Components/ButtonFramework";
import { MdCheck, MdClose, MdContentCopy, MdDelete, MdEdit } from "react-icons/md";
import { awaitJSON, MetaLambdaFetch } from "../../../utils/fetch-utils";
import { WHITE_META_LOGO } from "../../MetaBuyingUtils";

export enum ReviewModalVariant {
  DRAFT = "draft",
  PUBLISHED = "published",
  EDITING = "editing",
}

export enum ReviewModalTab {
  NONE = "None",
  DETAILS = "Details",
  AD_PREVIEW = "Ad Preview",
}

const CampaignView = ({ campaign }: { campaign: CampaignRow }): JSX.Element => {
  return (
    <div className="innerBody">
      <div className="headerRow">
        <div className="level campaign">Campaign</div>
        <div className="name">{campaign.campaign_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Campaign Objective</div>
        <div className="value">{campaign.objective}</div>
      </div>
      {campaign.special_ad_categories && (
        <div className="fieldRow">
          <div className="field">Special Ad Categories</div>
          <div className="value">{campaign.special_ad_categories?.toLocaleString() || "None"}</div>
        </div>
      )}
      {campaign.special_ad_category_country && (
        <div className="fieldRow">
          <div className="field">Special Ad Categories Country</div>
          <div className="value">{campaign.special_ad_category_country}</div>
        </div>
      )}
      {campaign.bid_strategy && (
        <div className="fieldRow">
          <div className="field">Bid Strategy</div>
          <div className="value">{campaign.bid_strategy}</div>
        </div>
      )}
      {(campaign.lifetime_budget || campaign.daily_budget) && (
        <div className="fieldRow">
          <div className="field">Budget</div>
          <div className="value">{campaign.daily_budget || campaign.lifetime_budget}</div>
        </div>
      )}
      {campaign.spend_cap && (
        <div className="fieldRow">
          <div className="field">Spend Cap</div>
          <div className="value">{campaign.spend_cap}</div>
        </div>
      )}
      <div className="fieldRow">
        <div className="field">Last Modified By</div>
        <div className="value">{campaign.lastuser}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Created On</div>
        <div className="value">{campaign.created}</div>
      </div>
    </div>
  );
};

const AdSetView = ({ adset }: { adset: AdSetRow }): JSX.Element => {
  return (
    <div className="innerBody">
      <div className="headerRow">
        <div className="level adset">Ad Set</div>
        <div className="name">{adset.adset_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Campaign</div>
        <div className="value">{adset.campaign_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Pixel</div>
        <div className="value">{adset.pixel_id}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Conversion Event</div>
        <div className="value">
          {adset.custom_event_type || adset.custom_conversion_id || "None"}
        </div>
      </div>
      <div className="fieldRow">
        <div className="field">Optimization Goal</div>
        <div className="value">{adset.optimization_goal || "None"}</div>
      </div>
      {adset.bid_strategy && (
        <div className="fieldRow">
          <div className="field">Bid Strategy</div>
          <div className="value">{adset.bid_strategy}</div>
        </div>
      )}
      {adset.bid_amount && (
        <div className="fieldRow">
          <div className="field">Bid Amount</div>
          <div className="value">{adset.bid_amount}</div>
        </div>
      )}
      {adset.daily_budget && (
        <div className="fieldRow">
          <div className="field">Daily Budget</div>
          <div className="value">{adset.daily_budget}</div>
        </div>
      )}
      {adset.lifetime_budget && (
        <div className="fieldRow">
          <div className="field">Lifetime Budget</div>
          <div className="value">{adset.lifetime_budget}</div>
        </div>
      )}
      {adset.daily_spend_cap && (
        <div className="fieldRow">
          <div className="field">Daily Spend Cap</div>
          <div className="value">{adset.daily_spend_cap}</div>
        </div>
      )}
      {adset.lifetime_spend_cap && (
        <div className="fieldRow">
          <div className="field">Lifetime Spend Cap</div>
          <div className="value">{adset.lifetime_spend_cap}</div>
        </div>
      )}
      {adset.age_min && (
        <div className="fieldRow">
          <div className="field">Age Min</div>
          <div className="value">{adset.age_min}</div>
        </div>
      )}
      {adset.age_max && (
        <div className="fieldRow">
          <div className="field">Age Max</div>
          <div className="value">{adset.age_max}</div>
        </div>
      )}
      {adset.genders && (
        <div className="fieldRow">
          <div className="field">Genders</div>
          <div className="value">{adset.genders}</div>
        </div>
      )}
      {adset.click_attribution_window && (
        <div className="fieldRow">
          <div className="field">Click Attribution Window</div>
          <div className="value">{adset.click_attribution_window}</div>
        </div>
      )}
      {adset.view_attribution_window && (
        <div className="fieldRow">
          <div className="field">View Attribution Window</div>
          <div className="value">{adset.view_attribution_window}</div>
        </div>
      )}
      {adset.publisher_platforms && (
        <div className="fieldRow">
          <div className="field">Publisher Platforms</div>
          <div className="value">{adset.publisher_platforms.join(", ")}</div>
        </div>
      )}
      {adset.facebook_positions && (
        <div className="fieldRow">
          <div className="field">Facebook Positions</div>
          <div className="value">{adset.facebook_positions.join(", ")}</div>
        </div>
      )}
      {adset.instagram_positions && (
        <div className="fieldRow">
          <div className="field">Instagram Positions</div>
          <div className="value">{adset.instagram_positions.join(", ")}</div>
        </div>
      )}
      {adset.messenger_positions && (
        <div className="fieldRow">
          <div className="field">Messenger Positions</div>
          <div className="value">{adset.messenger_positions.join(", ")}</div>
        </div>
      )}
      {adset.audience_network_positions && (
        <div className="fieldRow">
          <div className="field">Audience Network Positions</div>
          <div className="value">{adset.audience_network_positions.join(", ")}</div>
        </div>
      )}
      {adset.custom_audiences && (
        <div className="fieldRow">
          <div className="field">Custom Audiences</div>
          <div className="value">{adset.custom_audiences.join(", ")}</div>
        </div>
      )}
      {adset.excluded_custom_audiences && (
        <div className="fieldRow">
          <div className="field">Excluded Custom Audiences</div>
          <div className="value">{adset.excluded_custom_audiences.join(", ")}</div>
        </div>
      )}
      {adset.start_time && (
        <div className="fieldRow">
          <div className="field">Start Time</div>
          <div className="value">{adset.start_time}</div>
        </div>
      )}
      {adset.end_time && (
        <div className="fieldRow">
          <div className="field">End Time</div>
          <div className="value">{adset.end_time}</div>
        </div>
      )}
      <div className="fieldRow">
        <div className="field">Last Modified By</div>
        <div className="value">{adset.lastuser}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Created On</div>
        <div className="value">{adset.created}</div>
      </div>
    </div>
  );
};

const AdView = ({ ad }: { ad: AdRow }): JSX.Element => {
  return (
    <div className="innerBody">
      <div className="headerRow">
        <div className="level ad">Ad</div>
        <div className="name">{ad.ad_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Ad Set</div>
        <div className="value">{ad.adset_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Campaign</div>
        <div className="value">{ad.campaign_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Facebook Page</div>
        <div className="value">{ad.page_id}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Instagram account</div>
        <div className="value">{ad.instagram_actor_id || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Creative Format</div>
        <div className="value">{ad.format}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Link</div>
        <div className="value">{ad.link}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Primary Text</div>
        <div className="value">{ad.message}</div>
      </div>
      <div className="fieldRow">
        <div className="field">{ad.format === "IMAGE" ? "Name" : "Title"}</div>
        <div className="value">{ad.headline}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Description</div>
        <div className="value">{ad.description}</div>
      </div>
      <div className="fieldRow">
        <div className="field">CTA</div>
        <div className="value">{ad.call_to_action}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Last Modified By</div>
        <div className="value">{ad.lastuser}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Created On</div>
        <div className="value">{ad.created}</div>
      </div>
    </div>
  );
};

export interface ReviewModalProps {
  variant: ReviewModalVariant;
  accountID: string;
  businessManager: string;
  isInternal?: boolean;
  campaign?: CampaignRow;
  adset?: AdSetRow;
  ad?: AdRow;
  escapable?: boolean;
  selectedTab?: ReviewModalTab;
  setSelectedTab?: React.Dispatch<React.SetStateAction<ReviewModalTab>>;
  onHide: () => void;
  publishAsPaused?: () => void;
  edit?: () => void;
  approve?: () => void;
  disapprove?: () => void;
  remove?: () => void;
}

export const ReviewModal = ({
  variant,
  accountID,
  businessManager,
  isInternal = true,
  campaign,
  adset,
  ad,
  escapable = true,
  selectedTab = ReviewModalTab.NONE,
  setSelectedTab = () => {},
  onHide = () => {},
  publishAsPaused = () => {},
  edit = () => {},
  approve = () => {},
  disapprove = () => {},
  remove = () => {},
}: ReviewModalProps): JSX.Element => {
  const isDraft = variant === ReviewModalVariant.DRAFT;
  const isPublished = variant === ReviewModalVariant.PUBLISHED;

  const modalTitle = useMemo(() => {
    let title = "";
    if ((isDraft || isPublished) && campaign) {
      title = `Review Campaign${isDraft ? " Draft" : ""}`;
    } else if ((isDraft || isPublished) && adset) {
      title = `Review Ad Set${isDraft ? " Draft" : ""}`;
    } else if ((isDraft || isPublished) && ad) {
      title = `Review Ad${isDraft ? " Draft" : ""}`;
    } else {
      title = "Review Draft";
    }

    return title;
  }, [ad, adset, campaign, isDraft, isPublished]);

  // Keep track of loading state for ad preview
  const [isLoading, setIsLoading] = useState(true);

  const { idTitle, idValue } = useMemo(() => {
    if (isInternal && campaign && !adset && !ad && isDraft) {
      return { idTitle: `Internal Campaign ID: ${campaign.id}`, idValue: campaign.id };
    } else if (campaign && !adset && !ad && isPublished) {
      return { idTitle: `Meta Campaign ID: ${campaign.id}`, idValue: campaign.id };
    } else if (isInternal && !campaign && adset && !ad && isDraft) {
      return { idTitle: `Internal Ad Set ID: ${adset.id}`, idValue: adset.id };
    } else if (!campaign && adset && !ad && isPublished) {
      return { idTitle: `Meta Ad Set ID: ${adset.id}`, idValue: adset.id };
    } else if (isInternal && !campaign && !adset && ad && isDraft) {
      return { idTitle: `Internal Ad ID: ${ad.id}`, idValue: ad.id };
    } else if (!campaign && !adset && ad && isPublished) {
      return { idTitle: `Meta Ad ID: ${ad.id}`, idValue: ad.id };
    } else {
      return { idTitle: "", idValue: "" };
    }
  }, [ad, adset, campaign, isInternal, isDraft, isPublished]);

  const [iFrameFields, setIFrameFields] = useState<{
    src: string;
    width: string;
    height: string;
  }>();
  useEffect(() => {
    (async () => {
      if (!iFrameFields && ad && selectedTab === ReviewModalTab.AD_PREVIEW) {
        const res = await MetaLambdaFetch("/getAdPreview", {
          method: "POST",
          body: {
            accountID,
            businessManager,
            ad,
          },
        });

        const parsedRes = await awaitJSON(res);
        setIFrameFields({
          src: parsedRes.src,
          width: parsedRes.width,
          height: parsedRes.height,
        });
      }
    })();
  }, [iFrameFields, setIFrameFields, ad, selectedTab, accountID, businessManager]);

  return (
    <Modal
      className="reviewModal"
      show={true}
      onHide={onHide}
      centered
      backdrop={escapable ? true : "static"}
      keyboard={escapable}
    >
      <Modal.Header closeButton className="reviewModalHeader">
        {((isInternal && isDraft) || isPublished) && (
          <div className="titleInfo">
            <div className="modalTitle">{modalTitle}</div>
            <div className="modalID">
              <div className="idDisplay">{idTitle}</div>
              <Button
                icon={<MdContentCopy />}
                variant={ButtonFrameworkVariant.ICON_ONLY}
                type={ButtonType.EMPTY}
                size="sm"
                onClick={() => {
                  navigator.clipboard.writeText(idValue);
                }}
              />
            </div>
          </div>
        )}
        {!isInternal && isDraft && <div className="modalTitle">{modalTitle}</div>}
        {
          // Only show tabs if there is an ad
          ad && (
            <div className="titleButtons">
              <Button
                className={`titleButton${selectedTab === "Details" ? " selected" : " unselected"}`}
                type={ButtonType.EMPTY}
                onClick={() => {
                  setSelectedTab(ReviewModalTab.DETAILS);
                }}
              >
                Details
              </Button>
              <Button
                className={`titleButton${
                  selectedTab === "Ad Preview" ? " selected" : " unselected"
                }`}
                type={ButtonType.EMPTY}
                onClick={() => {
                  setIsLoading(true);
                  setSelectedTab(ReviewModalTab.AD_PREVIEW);
                }}
              >
                Ad Preview
              </Button>
            </div>
          )
        }
      </Modal.Header>
      <Modal.Body className="reviewModalBody">
        {campaign && selectedTab !== ReviewModalTab.AD_PREVIEW && (
          <CampaignView campaign={campaign} />
        )}
        {adset && selectedTab !== ReviewModalTab.AD_PREVIEW && <AdSetView adset={adset} />}
        {ad && selectedTab !== ReviewModalTab.AD_PREVIEW && <AdView ad={ad} />}
        {ad && selectedTab === ReviewModalTab.AD_PREVIEW && (
          <div className="adPreview">
            {isLoading && !iFrameFields && <Spinner />}
            <iframe
              title="test"
              onLoad={() => setIsLoading(false)}
              src={iFrameFields?.src}
              width={iFrameFields?.width}
              height={iFrameFields?.height}
              scrolling="no"
              style={{
                border: "none",
                display: isLoading ? "none" : "block",
              }}
            ></iframe>
          </div>
        )}
      </Modal.Body>
      {isPublished && (
        <Modal.Footer className="reviewModalFooter warning">
          Details may not be correct here if edits have been made in Ads Manager
        </Modal.Footer>
      )}
      {!isInternal && isDraft && (
        <Modal.Footer className="reviewModalFooter">
          <div className="mainActions">
            <Button
              className="disapproveButton"
              type={ButtonType.OUTLINED}
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={<MdClose />}
              onClick={disapprove}
              enableAnimation={false}
              disabled={ad && ad.approval_stage !== "IN_REVIEW"}
            >
              Disapprove
            </Button>
            <Button
              className="approveButton"
              type={ButtonType.OUTLINED}
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={<MdCheck />}
              onClick={approve}
              enableAnimation={false}
              disabled={ad && ad.approval_stage !== "IN_REVIEW"}
            >
              Approve
            </Button>
          </div>
        </Modal.Footer>
      )}
      {isInternal && isDraft && (
        <Modal.Footer className="reviewModalFooter">
          <div className="deleteAction">
            <Button
              variant={ButtonFrameworkVariant.ICON_ONLY}
              type={ButtonType.EMPTY}
              icon={<MdDelete />}
              onClick={remove}
            />
          </div>
          <div className="mainActions">
            <Button
              className="editButton"
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdEdit />}
              type={ButtonType.OUTLINED}
              onClick={edit}
            >
              Edit
            </Button>
            <Button
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={
                <img src={WHITE_META_LOGO} alt="icon" style={{ width: "22px", height: "22px" }} />
              }
              type={ButtonType.FILLED}
              onClick={publishAsPaused}
              disabled={ad && ad.approval_stage !== "APPROVED"}
            >
              Publish As Paused
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ReviewModal;
