import * as Contract from '@tableau/api-external-contract-js';
import {
  ApiMenuType,
  ContextMenuOptions,
  DateRangeType,
  ErrorCodes,
  FilterNullOption,
  IncludeDataValuesOption,
  PeriodType,
  SelectionUpdateType,
  SharedErrorCodes,
  TooltipContext,
} from '@tableau/api-external-contract-js';
import { DataSchema, DataSource as DataSourceInfo, VisualId, WorksheetDataSourceInfo } from '@tableau/api-internal-contract-js';
import { DataSource } from '../DataSource';
import { LogicalTable } from '../LogicalTable';
import { AccessibilityService } from '../Services/AccessibilityService';
import { AnnotationService } from '../Services/AnnotationService';
import { DataSourceService } from '../Services/DataSourceService';
import { ExternalContextMenuService } from '../Services/ExternalContextMenuService';
import { FilterService } from '../Services/FilterService';
import { GetDataService, GetDataType } from '../Services/GetDataService';
import { SelectionService } from '../Services/SelectionService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { VisualModelService } from '../Services/VisualModelService';
import { TableauError } from '../TableauError';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { DashboardImpl } from './DashboardImpl';
import { DataSourceImpl } from './DataSourceImpl';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';
import { StoryPointImpl } from './StoryPointImpl';

export class WorksheetImpl extends SheetImpl {
  public constructor(
    sheetInfoImpl: SheetInfoImpl,
    _registryId: number,
    private _visualId: VisualId,
    private _parentDashboardImpl: DashboardImpl | null,
    private _parentStoryPointImpl: StoryPointImpl | null,
  ) {
    super(sheetInfoImpl, _registryId);
  }

  public get parentDashboard(): DashboardImpl | null {
    return this._parentDashboardImpl;
  }

  public get parentStoryPoint(): StoryPointImpl | null {
    return this._parentStoryPointImpl;
  }

  public get visualId(): VisualId {
    return this._visualId;
  }

  public getMaxPageRowLimit(): number {
    return 10000;
  }

  public applyFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    ErrorHelpers.verifyEnumValue<Contract.FilterUpdateType>(updateType, Contract.FilterUpdateType, 'Contract.FilterUpdateType');
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.applyFilterAsync(this.visualId, fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    ErrorHelpers.verifyParameter(fieldName, 'fieldName');
    ErrorHelpers.verifyParameter(filterOptions, 'filterOptions');
    if (filterOptions.nullOption) {
      ErrorHelpers.verifyEnumValue<FilterNullOption>(filterOptions.nullOption, FilterNullOption, 'FilterNullOption');
    } else {
      ErrorHelpers.verifyRangeParamType(filterOptions.min, filterOptions.max);
    }
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.applyRangeFilterAsync(this.visualId, fieldName, filterOptions);
  }

  public applyHierarchicalFilterAsync(
    fieldName: string,
    values: Array<string> | Contract.HierarchicalLevels,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    ErrorHelpers.verifyParameter(fieldName, 'fieldName');
    ErrorHelpers.verifyParameter(values, 'values');
    ErrorHelpers.verifyEnumValue<Contract.FilterUpdateType>(updateType, Contract.FilterUpdateType, 'Contract.FilterUpdateType');
    if (!Array.isArray(values) && !values.levels) {
      throw new TableauError(
        ErrorCodes.InvalidParameter,
        'values parameter for applyHierarchicalFilterAsync must be an array or contain a levels key',
      );
    }
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.applyHierarchicalFilterAsync(this.visualId, fieldName, values, updateType, options);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    ErrorHelpers.verifyParameter(fieldName, 'fieldName');
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.clearFilterAsync(this.visualId, fieldName);
  }

  public applyRelativeDateFilterAsync(fieldName: string, options: Contract.RelativeDateFilterOptions): Promise<string> {
    ErrorHelpers.verifyStringParameter(fieldName, 'fieldName');
    ErrorHelpers.verifyParameter(options, 'options');
    ErrorHelpers.verifyEnumValue<PeriodType>(options.periodType, PeriodType, 'PeriodType');
    ErrorHelpers.verifyEnumValue<DateRangeType>(options.rangeType, DateRangeType, 'DateRangeType');
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.applyRelativeDateFilterAsync(this.visualId, fieldName, options);
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(ServiceNames.DataSourceService);
    return service.getDataSourcesAsync(this.visualId).then<Array<Contract.DataSource>>((result) => {
      const dataSchema: DataSchema = result;
      const worksheetDataSourceInfo: WorksheetDataSourceInfo = dataSchema.worksheetDataSchemaMap[this.name];

      const dataSources: Array<Contract.DataSource> = [];

      // First, add the primary datasource.  By convention, it comes first in the returned array.
      const primaryId: string = worksheetDataSourceInfo.primaryDataSource;
      dataSources.push(this.createDataSourceFromInfo(dataSchema.dataSources[primaryId]));

      // Then, loop through any secondary data sources and add them.
      for (const secondaryId of worksheetDataSourceInfo.referencedDataSourceList) {
        if (secondaryId !== primaryId) {
          dataSources.push(this.createDataSourceFromInfo(dataSchema.dataSources[secondaryId]));
        }
      }

      return dataSources;
    });
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.getFiltersAsync(this.visualId);
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.getSelectedMarksAsync(this.visualId);
  }

  public getHighlightedMarksAsync(): Promise<Contract.MarksCollection> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.getHighlightedMarksAsync(this.visualId);
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};

    return service.getUnderlyingDataAsync(
      this.visualId,
      GetDataType.Summary,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      true,
      options.columnsToIncludeById || [],
      options.maxRows || 0,
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues,
    );
  }

  public getSummaryDataReaderAsync(pageRowCount: number, options: Contract.GetSummaryDataOptions): Promise<Contract.DataTableReader> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};

    return service.getSummaryDataReaderAsync(
      this.visualId,
      pageRowCount || this.getMaxPageRowLimit(),
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      true, // includeAllColumns (can be overridden by columnsToIncludeById)
      options.columnsToIncludeById || [],
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues,
    );
  }

  public getVisualSpecificationAsync(): Promise<Contract.VisualSpecification> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<VisualModelService>(ServiceNames.VisualModel);

    return service.getVisualSpecificationAsync(this.visualId);
  }

  public getSummaryColumnsInfoAsync(): Promise<Array<Contract.Column>> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.getSummaryColumnsInfoAsync(this.visualId);
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};
    return service.getUnderlyingDataAsync(
      this.visualId,
      GetDataType.Underlying,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      !!options.includeAllColumns,
      options.columnsToIncludeById || [],
      options.maxRows || 0,
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues,
    );
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(ServiceNames.DataSourceService);
    return service.getUnderlyingTablesAsync(this.visualId).then<Array<Contract.LogicalTable>>((logicalTableInfos) => {
      return logicalTableInfos.map((logicalTableInfo) => new LogicalTable(logicalTableInfo));
    });
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options?: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};
    return service.getUnderlyingTableDataAsync(
      this.visualId,
      logicalTableId,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      !!options.includeAllColumns,
      options.columnsToIncludeById || [],
      options.maxRows || 0,
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues,
    );
  }

  public getUnderlyingTableDataReaderAsync(
    logicalTableId: string,
    pageRowCount?: number,
    options?: Contract.GetUnderlyingDataOptions,
  ): Promise<Contract.DataTableReader> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    options = options || {};
    return service.getUnderlyingTableDataReaderAsync(
      this.visualId,
      logicalTableId,
      pageRowCount || this.getMaxPageRowLimit(),
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      !!options.includeAllColumns,
      options.columnsToIncludeById || [],
      options.includeDataValuesOption || IncludeDataValuesOption.AllValues,
    );
  }

  public clearSelectedMarksAsync(): Promise<void> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.clearSelectedMarksAsync(this.visualId);
  }

  public selectMarksByValueAsync(selections: Array<Contract.SelectionCriteria>, selectionUpdateType: SelectionUpdateType): Promise<void> {
    ErrorHelpers.verifyParameter(selections, 'fieldName');
    ErrorHelpers.verifyEnumValue<SelectionUpdateType>(selectionUpdateType, SelectionUpdateType, 'SelectionUpdateType');
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.selectMarksByValueAsync(this.visualId, selections, selectionUpdateType);
  }

  public selectMarksByIdAsync(selections: Array<Contract.MarkInfo>, selectionUpdateType: SelectionUpdateType): Promise<void> {
    ErrorHelpers.verifyParameter(selections, 'fieldName');
    ErrorHelpers.verifyEnumValue<SelectionUpdateType>(selectionUpdateType, SelectionUpdateType, 'SelectionUpdateType');
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.selectMarksByIdAsync(this.visualId, selections, selectionUpdateType);
  }

  public annotateMarkAsync(mark: Contract.MarkInfo, annotationText: string): Promise<void> {
    ErrorHelpers.verifyParameter(mark, 'mark');
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<AnnotationService>(ServiceNames.Annotation);
    return service.annotateMarkAsync(this.visualId, mark, annotationText);
  }

  public getAnnotationsAsync(): Promise<Array<Contract.Annotation>> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<AnnotationService>(ServiceNames.Annotation);
    return service.getAnnotationsAsync(this.visualId);
  }

  public removeAnnotationAsync(annotation: Contract.Annotation) {
    ErrorHelpers.verifyParameter(annotation, 'annotation');
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<AnnotationService>(ServiceNames.Annotation);
    return service.removeAnnotationAsync(this.visualId, annotation);
  }

  public appendContextMenuAsync(targetMenu: ApiMenuType, config: ContextMenuOptions): Promise<string> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<ExternalContextMenuService>(ServiceNames.ExternalContextMenu);
    return service.appendContextMenuAsync(this.visualId.worksheet, targetMenu, config);
  }

  public removeContextMenuAsync(targetMenu: ApiMenuType, menuItemId: string): Promise<void> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<ExternalContextMenuService>(ServiceNames.ExternalContextMenu);
    return service.removeContextMenuAsync(this.visualId.worksheet, targetMenu, menuItemId);
  }

  public executeContextMenuAsync(targetMenu: ApiMenuType, menuItemId: string): Promise<void> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<ExternalContextMenuService>(ServiceNames.ExternalContextMenu);
    return service.executeContextMenuAsync(this.visualId.worksheet, targetMenu, menuItemId);
  }

  public renameContextMenuAsync(targetMenu: ApiMenuType, menuHeader: string, menuDescription: string): Promise<void> {
    this.verifyActiveSheet();

    const service = ApiServiceRegistry.get(this._registryId).getService<ExternalContextMenuService>(ServiceNames.ExternalContextMenu);
    return service.renameContextMenuAsync(this.visualId.worksheet, targetMenu, menuHeader, menuDescription);
  }

  public hoverTupleAsync(hoveredTuple?: number, tooltip?: TooltipContext | null, allowHoverActions?: boolean): Promise<void> {
    if (this.isInsideDashboardExtension()) {
      return Promise.reject(
        new TableauError(Contract.SharedErrorCodes.ImplementationError, `hoverTupleAsync is not supported in dashboard extensions`),
      );
    }

    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.hoverTupleAsync(this.visualId, hoveredTuple, tooltip, allowHoverActions);
  }

  public selectTuplesAsync(selectedTuples: Array<number>, selectOption: Contract.SelectOptions, tooltip?: TooltipContext): Promise<void> {
    if (this.isInsideDashboardExtension()) {
      return Promise.reject(
        new TableauError(Contract.SharedErrorCodes.ImplementationError, `selectTuplesAsync is not supported in dashboard extensions`),
      );
    }

    const service = ApiServiceRegistry.get(this._registryId).getService<SelectionService>(ServiceNames.Selection);
    return service.selectTuplesAsync(this.visualId, selectedTuples, selectOption, tooltip);
  }

  public getTooltipTextAsync(tupleId: number): Promise<String> {
    if (this.isInsideDashboardExtension()) {
      return Promise.reject(
        new TableauError(Contract.SharedErrorCodes.ImplementationError, `getTooltipTextAsync is not supported in dashboard extensions`),
      );
    }
    const service = ApiServiceRegistry.get(this._registryId).getService<AccessibilityService>(ServiceNames.Accessibility);
    return service.getTooltipTextAsync(this.visualId, tupleId);
  }

  public leaveMarkNavigationAsync(): Promise<void> {
    if (this.isInsideDashboardExtension()) {
      return Promise.reject(
        new TableauError(
          Contract.SharedErrorCodes.ImplementationError,
          `leaveMarkNavigationAsync is not supported in dashboard extensions`,
        ),
      );
    }
    const service = ApiServiceRegistry.get(this._registryId).getService<AccessibilityService>(ServiceNames.Accessibility);
    return service.leaveMarkNavigationAsync(this.visualId);
  }

  private createDataSourceFromInfo(dataSourceInfo: DataSourceInfo): Contract.DataSource {
    const dataSourceImpl = new DataSourceImpl(dataSourceInfo, this._registryId);
    const dataSource = new DataSource(dataSourceImpl);
    dataSourceImpl.initializeWithPublicInterfaces(dataSource);
    return dataSource;
  }

  private verifyActiveSheet() {
    const isRootAndActiveWorksheet = this.active;
    const isInsideActiveDashboard = this.isInsideActiveDashboard();
    const isInsideActiveStoryPoint = this.isInsideActiveStoryPoint();

    if (!isRootAndActiveWorksheet && !isInsideActiveDashboard && !isInsideActiveStoryPoint) {
      throw new TableauError(SharedErrorCodes.NotActiveSheet, 'Operation not allowed on non-active sheet');
    }
  }

  private isInsideActiveStoryPoint() {
    return this._parentStoryPointImpl && this._parentStoryPointImpl.active;
  }

  private isInsideActiveDashboard() {
    return this._parentDashboardImpl && this._parentDashboardImpl.active;
  }

  private isInsideDashboardExtension() {
    return this._parentDashboardImpl !== null;
  }
}
