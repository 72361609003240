// Experiment for creative insights page.
const creativeInsightsExternalCompanies: string[] = [
  "homechef",
  "casper",
  "acorns",
  "allbirds",
  "hers",
  "ruggable",
  "bonobos",
  "away",
  "everlywell",
  "prizepicks",
  "pendulum",
  "petmeds",
  "tally",
  "bollandbranch",
  "blenderseyewear",
  "huckberry",
  "mejuri",
  "rebag",
  "butcherbox",
  "cashapp",
  "misfitsmarket",
  "theathletic",
  "ethos",
  "thumbtack",
  "doordash",
  "mirror",
  "oura",
  "warbyparker"
];

const creativeInsightsInternalCompanies: string[] = [
  "upstart",
  "experian",
  "everyplate",
  "credible",
  "society6",
  "tula",
  "ergatta",
  "ollie",
  "fundrise",
  "movado",
  "tonal",
  "hims",
  "udemy",
  "ourplace",
  "zola",
  "lovevery",
  "masterclass",
  "talkspace",
  "tractive",
  "paireyewear",
  "instacart",
  "repipespecialists",
  "tinuititest",
  "textnow",
  "prose"
];

export const shouldEnableCreativeInsights = (company: string, userRole: number): boolean => {
  if (creativeInsightsExternalCompanies.includes(company) && userRole <= 10) {
    return true;
  } else if (creativeInsightsInternalCompanies.includes(company) && userRole <= 2) {
    return true;
  } else {
    return false; // page does not exist
  }
};
