import { QuestionCardProps } from "../../Components/QuestionCard/QuestionCard";
import { FormLayoutProps } from "../OnboardingFreeForm";

export const primaryContacts = {
  columns: [
    {
      title: "primary",
      type: "header",
      width: 3,
    },
    {
      title: "First Name",
      type: "text",
      width: 2,
      required: true,
      textPlaceholder: "Add First Name",
      enabler: true,
    },
    {
      title: "Last Name",
      type: "text",
      width: 2,
      required: true,
      textPlaceholder: "Add Last Name",
      enabler: true,
    },
    {
      title: "Title",
      type: "text",
      width: 2,
      required: true,
      textPlaceholder: "Add Title",
      enabler: true,
    },
    {
      title: "Email",
      type: "email",
      width: 2,
      required: true,
      textPlaceholder: "Add Email",
      enabler: true,
    },
    {
      title: "Add Contact",
      type: "button",
      width: 1,
      enabler: true,
    },
  ],
  rows: [
    {
      id: "1",
      questionNumber: "3A1",
      title: "Internal Data Stack",
      subtitle: "reporting tools, data sources, data structure",
    },
    {
      id: "2",
      questionNumber: "3A2",
      title: "Web Analytics",
      subtitle: "UA/GA4, Adobe, UTM structures, etc.",
    },
    {
      id: "3",
      questionNumber: "3A3",
      title: "Management of non-Tinuiti managed/ non-paid channels",
    },
    {
      id: "4",
      questionNumber: "3A4",
      title: "Reporting and Measurement",
    },
  ],
} as FormLayoutProps;

export const calendar = [
  {
    id: "reporting_year",
    questionNumber: "3B1",
    title: "What is your company's reporting year?",
    type: "radio",
    options: [
      "Traditional Calendar Year (Jan - Dec)",
      "Fiscal (standard business)",
      "13 Period",
      "4 - 4 - 5",
      "4 - 5 - 4",
      "4 - 5 - 5",
    ],
  },
  {
    id: "week_definition",
    questionNumber: "3B2",
    title: "What is your company's definition of a week?",
    type: "radio",
    options: ["Sunday - Saturday", "Monday - Sunday", "Saturday - Friday"],
  },
] as QuestionCardProps[];

export const metrics = [
  {
    id: "kpi_list",
    questionNumber: "3C1",
    title: "List the KPIs your company uses to measure business outcomes:",
    subtitle: "For example: Order volume, Revenue, etc.",
    type: "textarea",
    placeholder: "List KPIs here",
    renderAdditionalInfo: false,
  },
] as QuestionCardProps[];
