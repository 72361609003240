import React, { useState, useContext, useEffect } from "react";
import { Modal, Col, Form, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { HiOutlinePlus } from "react-icons/hi";
import { MdDelete, MdArrowCircleRight } from "react-icons/md";
import { Button, ButtonType, DateRangePicker, SingleDatePicker } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { formValidator } from "./WtoFormValidator";
import {
  updateParamFunnelStep,
  updateParamTestName,
  updateParamVersionNumber,
  DAYS,
  getParamFunnelStep,
  getParamTestName,
  getParamVersionNumber,
} from "./WtoUtils";
import {
  FormContext,
  defaultRule,
  defaultTimeframe,
  defaultCustomRetention,
  defaultDowRow,
} from "./WtoFormContext";
import WtoValidationErrors from "./WtoValidationErrors";
import moment from "moment-timezone";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import "./WtoRulesFormModal.scss";

export interface WtoRulesFormModalProps {
  show: boolean;
  closeModal: () => void;
  readOnly: boolean;
  setBreadcrumbPath: React.Dispatch<React.SetStateAction<string[]>>;
  ruleIndex: number | null;
  showRuleCards: boolean[];
  setShowRuleCards: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export const WtoRulesFormModal: React.FC<WtoRulesFormModalProps> = ({
  show,
  closeModal,
  readOnly,
  setBreadcrumbPath,
  ruleIndex,
  showRuleCards,
  setShowRuleCards,
}) => {
  const { wtoFormObj, setWtoFormObj, newRule, setNewRule } = useContext(FormContext);
  const MIN_YEAR = new Date().getFullYear();
  const MAX_YEAR = new Date().getFullYear() + 100;
  const timezone = moment.tz.guess(true);
  const TIMESTAMP_FORMAT = "yyyy-MM-dd HH:mm:ss.SSS";
  const DATE_FORMAT = "yyyy-MM-dd";
  const timestamp = R.pipe(Dfns.format(TIMESTAMP_FORMAT))(new Date());
  const today = R.pipe(Dfns.format(DATE_FORMAT))(new Date());

  const CONDITION_OPTIONS = ["UTM Parameter", "Device", "Cookie", "Browser", "OS"];
  const CONDITION_RULE = ["Contains", "Equals", "Excludes"];
  const UTM_OPERATION = ["Add", "Replace", "Delete", "Override"];
  const COOKIE_OPERATION = ["Create", "Replace", "Delete"];
  const CONDITION_RULE_SINGLE = ["Equals", "Excludes"];
  const TIMEZONE_OPTIONS = [
    "Central / Chicago",
    "Eastern / New York",
    "Mountain / Denver",
    "Pacific / Los Angeles",
    "UTC",
  ];
  const TIMEZONE_MAP = {
    "Central / Chicago": "America/Chicago",
    "Eastern / New York": "America/New_York",
    "Mountain / Denver": "America/Denver",
    "Pacific / Los Angeles": "America/Los_Angeles",
    UTC: "UTC",
  };

  const CONDITION_VALUES = {
    Device: ["Mobile", "Tablet", "Desktop"],
    Browser: ["Chrome", "Safari", "Firefox", "Opera", "Microsoft Edge"],
    OS: ["iOS", "MacOS", "Android", "Windows", "Linux"],
  };

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    let invalidId = R.find(R.propEq("variant_id", ""), wtoFormObj.variants);
    let invalidUrl = R.find(R.propEq("variant_url", ""), wtoFormObj.variants);
    if (!R.isNil(invalidId) || !R.isNil(invalidUrl)) {
      setBreadcrumbPath(["Route Details"]);
    }
  }, [newRule, wtoFormObj, setBreadcrumbPath]);

  const editCondition = (event, property, index) => {
    const newRuleFormObj = R.clone(newRule);
    let newCondition = newRuleFormObj.conditions[index];
    let valueTokens = [];
    let value = "";
    switch (property) {
      case "trigger":
        newCondition.trigger = event;
        newCondition.rule = "";
        newCondition.value = "";
        break;
      case "rule":
        newCondition[property] = event;
        break;
      case "key":
        valueTokens = newCondition.value.split("=");
        value = valueTokens.length > 1 ? valueTokens[1] : "";
        newCondition.value = `${event.target.value}=${value}`;
        break;
      case "value":
        if (typeof event === "string") {
          newCondition.value = event;
        } else {
          valueTokens = newCondition.value.split("=");
          newCondition.value = `${valueTokens[0]}=${event.target.value}`;
        }
        break;
    }
    if (newCondition.value === "=") {
      newCondition.value = "";
    }
    newRuleFormObj.conditions[index] = newCondition;
    setNewRule(newRuleFormObj);
  };

  const onSelectChange = (event: any, keyName?: string, index?: number, index2?: number) => {
    const newRuleObj = R.clone(newRule);
    switch (keyName) {
      case "timezone":
        newRuleObj.timezone = event;
        break;
      case "conditions_rule":
        if (index || index === 0) {
          if (newRuleObj.conditions[index].length > 0) {
            newRuleObj.conditions[index][1] = event;
          }
        }
        break;
      case "conditions_dropdown":
        if (index || index === 0) {
          if (newRuleObj.conditions[index].length > 0) {
            newRuleObj.conditions[index][2] = event;
          }
        }
        break;
      case "additional_utm":
        if (index || index === 0) {
          let utmOperations: { utm_variant_id: string; utm_variant_url: string }[] = [];
          if (event.variant_id.toLocaleLowerCase() === "all") {
            utmOperations = event.variant_url.map(
              (utmOp: { variant_id: string; variant_url: string | string[] }) => ({
                utm_variant_id: utmOp.variant_id,
                utm_variant_url: utmOp.variant_url,
              })
            );
          } else {
            utmOperations.push({
              utm_variant_id: event.variant_id,
              utm_variant_url: event.variant_url,
            });
          }
          newRuleObj[keyName][index].additional_utm_url = utmOperations;
        }
        break;
      case "additional_utm_operation":
        if ((index || index === 0) && !R.isNil(index2)) {
          newRuleObj.additional_utm[index2].additional_utm_operation[index] = {
            operation: "",
            utm_key: "",
            utm_value: "",
          };
          if (event === "Replace") {
            newRuleObj.additional_utm[index2].additional_utm_operation[index].replace_key = "";
            newRuleObj.additional_utm[index2].additional_utm_operation[index].replace_value = "";
          } else {
            delete newRuleObj.additional_utm[index2].additional_utm_operation[index].replace_key;
            delete newRuleObj.additional_utm[index2].additional_utm_operation[index].replace_value;
          }
          newRuleObj.additional_utm[index2].additional_utm_operation[index].operation = event;
        }
        break;
      case "variant_id":
        if (index || index === 0) {
          newRuleObj.variant_splits[index][keyName] = event.variant_id;
        }
        newRuleObj.cookie_operation_url = [];
        break;
      case "cookie_operation_url":
        newRuleObj[keyName][0].cookie_variant_id = event.variant_id;
        break;
      case "cookie_operation":
        if (index || index === 0) {
          newRuleObj.cookie_operation[index] = {
            cookie_key: "",
            cookie_value: "",
            operation: "",
          };
          if (event === "Replace") {
            newRuleObj.cookie_operation[index].cki_replace_key = "";
            newRuleObj.cookie_operation[index].cki_replace_value = "";
          } else {
            delete newRuleObj.cookie_operation[index].cki_replace_key;
            delete newRuleObj.cookie_operation[index].cki_replace_value;
          }
          newRuleObj.cookie_operation[index].operation = event;
        }
        break;
      default:
        newRuleObj[event.target.name] = event.target.value;
    }
    setNewRule(newRuleObj);
  };

  const onInputChange = (e: any, valueType?, idx?) => {
    const newRuleObj = R.clone(newRule);
    if (valueType === "rule_name") {
      newRuleObj.rule_name = e.target.value;
    } else {
      switch (e.target.dataset.type) {
        case "utm_operation":
          newRuleObj.additional_utm[e.target.dataset.utmindex].additional_utm_operation[idx][
            valueType
          ] = e.target.value;
          break;
        case "split": {
          let splitValue = Number(e.target.value);
          if (splitValue > 100) {
            splitValue = 100;
          } else if (splitValue < 0) {
            splitValue = 0;
          }
          newRuleObj.variant_splits[idx][valueType] = splitValue;
          break;
        }
        case "cookie_operation":
          newRuleObj.cookie_operation[idx][valueType] = e.target.value;
          break;
        default:
          newRuleObj[e.target.name] = e.target.value;
      }
    }
    setNewRule(newRuleObj);
  };

  const updateRuleField = (key, value) =>
    setNewRule((current: any) => {
      return { ...current, [key]: value };
    });

  const updateTimeframeId = value => {
    const newRuleFormObj = R.clone(newRule);
    newRuleFormObj.timeframe.utm_test_id = value;
    setNewRule(newRuleFormObj);
  };

  const addCondition = () => {
    const newRuleFormObj = R.clone(newRule);
    newRuleFormObj.conditions = [
      ...newRule.conditions,
      {
        operator: newRuleFormObj.conditions.length === 0 ? "WHEN" : "AND",
        trigger: "",
        rule: "",
        value: "",
      },
    ];
    setNewRule(newRuleFormObj);
  };

  const onDeleteOperationClick = (e: any, ruleType, optrnIdx?: number) => {
    const newRuleFormObj = R.clone(newRule);
    const index = Number(e.currentTarget.dataset.index);
    if (ruleType === "additional_utm_operation") {
      newRuleFormObj.additional_utm[index][ruleType].splice(optrnIdx, 1);
    } else {
      newRuleFormObj[ruleType].splice(index, 1);
    }
    if (ruleType === "conditions" && newRuleFormObj.conditions.length > 0) {
      newRuleFormObj.conditions[0].operator = "WHEN";
    }
    if (ruleType === "variant_splits") {
      newRuleFormObj.cookie_operation_url = [];
    }
    setNewRule(newRuleFormObj);
  };

  const renderVariantsSplits = (variants, dowIndex?) => {
    let variantIdProperty = R.isNil(dowIndex) ? "variant_id" : "name";

    // disable variant selection if no more variants remain unselected
    let selectedVariantCount = 0;
    wtoFormObj.variants.forEach(variant => {
      if (
        R.isNil(dowIndex)
          ? R.find(R.propEq("variant_id", variant.variant_id), newRule.variant_splits)
          : R.find(R.propEq("name", variant.variant_id), newRule.dow_operation[dowIndex].variants)
      ) {
        selectedVariantCount++;
      }
    });
    return (
      <>
        <div className="variantsInputWrapper">
          {variants.map((split, splitIndex: number) => (
            <div className="variantSplitWrapper" key={splitIndex}>
              <DropdownButton
                className="variantDropdown"
                disabled={readOnly || selectedVariantCount === wtoFormObj.variants.length}
                title={
                  R.isEmpty(split[variantIdProperty]) ? "Variant ID" : split[variantIdProperty]
                }
              >
                {wtoFormObj.variants.map(variant =>
                  // if there is a DOW index, check if variant has been selected in DOW operations
                  // if there is not, check if variant  has been selected in variant splits
                  // only allow variants that have not been selected as options
                  R.isNil(
                    R.isNil(dowIndex)
                      ? R.find(R.propEq("variant_id", variant.variant_id), newRule.variant_splits)
                      : R.find(
                          R.propEq("name", variant.variant_id),
                          newRule.dow_operation[dowIndex].variants
                        )
                  ) ? (
                    <Dropdown.Item
                      key={variant.variant_id}
                      eventKey={variant.variant_id}
                      onSelect={() =>
                        R.isNil(dowIndex)
                          ? onSelectChange(variant, "variant_id", splitIndex)
                          : handleDowVariant("select", dowIndex, splitIndex, variant)
                      }
                      placeholder="Variant ID"
                    >
                      {variant.variant_id}
                    </Dropdown.Item>
                  ) : null
                )}
              </DropdownButton>
              <div className="percentageWrapper">
                <Form.Control
                  value={split.split ? split.split : ""}
                  disabled={wtoFormObj.is_deleted}
                  className="variantPercentage"
                  data-type="split"
                  placeholder="0"
                  type="number"
                  onChange={e =>
                    R.isNil(dowIndex)
                      ? onInputChange(e, "split", splitIndex)
                      : handleDowVariant("allocate", dowIndex, splitIndex, e.target.value)
                  }
                />
                %
              </div>
              {variants.length > 1 && !readOnly && (
                <Button
                  className="deleteButton"
                  type={ButtonType.FILLED}
                  icon={<MdDelete />}
                  data-index={splitIndex}
                  onClick={e => {
                    e.preventDefault();
                    if (R.isNil(dowIndex)) {
                      onDeleteOperationClick(e, "variant_splits");
                    } else {
                      handleDowVariant("delete", dowIndex, splitIndex);
                    }
                  }}
                />
              )}
            </div>
          ))}
        </div>
        <div className="addSplitRow">
          {variants.length < wtoFormObj.variants.length && (
            <Button
              disabled={readOnly}
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<HiOutlinePlus />}
              onClick={e => {
                e.preventDefault();
                if (R.isNil(dowIndex)) {
                  addTrafficSplit();
                } else {
                  addDowTrafficSplit(dowIndex);
                }
              }}
            >
              Add Traffic Split
            </Button>
          )}
          <div className="splitTotal">
            {R.sum(R.flatten(R.pluck("split")(variants)) as number[])}%
          </div>
        </div>
      </>
    );
  };

  const renderConditionLogic = (condition, index: number) => {
    let logicSelections: JSX.Element[] = [];
    switch (condition.trigger) {
      case "UTM Parameter":
      case "Cookie":
        logicSelections.push(
          <div key={logicSelections.length} className="logicSelection conditions">
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={!R.isEmpty(condition.value) ? condition.value.split("=")[0] : ""}
                  disabled={readOnly}
                  data-name={condition.rule}
                  data-type="conditions"
                  placeholder="Key"
                  onChange={e => editCondition(e, "key", index)}
                />
              </InputGroup>
            </Form.Group>

            <DropdownButton
              className="ruleDropdown"
              disabled={readOnly}
              title={
                newRule.conditions[index].rule && !R.isEmpty(newRule.conditions[index].rule)
                  ? newRule.conditions[index].rule
                  : "Select Rule"
              }
            >
              {CONDITION_RULE.map(condition => (
                <Dropdown.Item
                  key={condition}
                  eventKey={condition}
                  placeholder="Select Rule"
                  onSelect={e => editCondition(e, "rule", index)}
                >
                  {condition}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={condition.value ? condition.value.split("=")[1] : ""}
                  disabled={readOnly}
                  data-name={condition.rule}
                  data-type="conditions"
                  placeholder="Value"
                  onChange={e => editCondition(e, "value", index)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        );
        break;
      case "Device":
      case "Browser":
      case "OS":
        logicSelections.push(
          <div key={logicSelections.length} className="logicSelection conditions">
            <DropdownButton
              className="ruleDropdown"
              disabled={readOnly}
              title={
                newRule.conditions[index].rule && newRule.conditions[index].rule.length > 0
                  ? newRule.conditions[index].rule
                  : "Select Rule"
              }
            >
              {CONDITION_RULE_SINGLE.map(condition => (
                <Dropdown.Item
                  key={condition}
                  eventKey={condition}
                  placeholder="Select Rule"
                  onSelect={e => editCondition(e, "rule", index)}
                >
                  {condition}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              className="ruleDropdown"
              disabled={readOnly}
              title={
                newRule.conditions[index].value && newRule.conditions[index].value.length > 0
                  ? newRule.conditions[index].value
                  : `Select ${condition.trigger}`
              }
            >
              {CONDITION_VALUES[condition.trigger].map(condition => (
                <Dropdown.Item
                  key={condition}
                  eventKey={condition}
                  placeholder={`Select ${condition.trigger}`}
                  onSelect={e => editCondition(e, "value", index)}
                >
                  {condition}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        );
        break;
      default:
        break;
    }
    return logicSelections;
  };

  const checkListDuplicate = (listName: string) => {
    newRule.conditions.map((item: { [x: string]: string }) => {
      if (listName === "Browser") {
        item[0] === "Browser" ? (listName = "") : (listName = "Browser");
      }
      if (listName === "OS") {
        item[0] === "OS" ? (listName = "") : (listName = "OS");
      }
      if (listName === "Device") {
        item[0] === "Device" ? (listName = "") : (listName = "Device");
      }
      return listName;
    });
    return listName;
  };

  const renderConditions = () => {
    return (
      <div className="conditionsInputWrapper">
        {newRule.conditions.map((variant, index: number) => (
          <div key={index} className="conditionFieldsWrapper">
            <div className="operatorWrapper">
              <div className="conditionalOperator">{variant.operator}</div>
              <DropdownButton
                className="conditionDropdown"
                disabled={readOnly}
                title={
                  newRule.conditions[index].trigger && newRule.conditions[index].trigger.length > 0
                    ? newRule.conditions[index].trigger
                    : "Select Trigger"
                }
              >
                {CONDITION_OPTIONS.map(condition => (
                  <Dropdown.Item
                    key={condition}
                    eventKey={condition}
                    disabled={
                      condition !== "UTM Parameter" &&
                      condition !== "Cookie" &&
                      condition !== checkListDuplicate("Browser") &&
                      condition !== checkListDuplicate("OS") &&
                      condition !== checkListDuplicate("Device")
                    }
                    onSelect={e => editCondition(e, "trigger", index)}
                    placeholder="Select Trigger"
                  >
                    {condition}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            {renderConditionLogic(variant, index)}
            {!readOnly && (
              <Button
                className="deleteButton"
                data-index={index}
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.ICON_ONLY}
                icon={<MdDelete />}
                onClick={e => {
                  e.preventDefault();
                  onDeleteOperationClick(e, "conditions");
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderUrlParamLogic = (utmOptrnObj, operationIndex, utmIndex: number) => {
    let logicSelections: JSX.Element[] = [];
    switch (utmOptrnObj.operation) {
      case "Replace":
        logicSelections.push(
          <div key={logicSelections.length} className="logicSelection">
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={utmOptrnObj.utm_key || ""}
                  disabled={readOnly}
                  data-utmindex={utmIndex}
                  data-type="utm_operation"
                  placeholder="Key"
                  onChange={e => onInputChange(e, "utm_key", operationIndex)}
                />
                <Form.Control
                  value={utmOptrnObj.utm_value || ""}
                  disabled={readOnly}
                  data-utmindex={utmIndex}
                  data-type="utm_operation"
                  placeholder="Value"
                  onChange={e => onInputChange(e, "utm_value", operationIndex)}
                />
              </InputGroup>
            </Form.Group>
            <div className="iconWrapper">
              <MdArrowCircleRight />
            </div>
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={utmOptrnObj.replace_key || ""}
                  disabled={readOnly}
                  data-utmindex={utmIndex}
                  data-type="utm_operation"
                  placeholder="Key"
                  onChange={e => onInputChange(e, "replace_key", operationIndex)}
                />
                <Form.Control
                  value={utmOptrnObj.replace_value || ""}
                  disabled={readOnly}
                  data-utmindex={utmIndex}
                  data-type="utm_operation"
                  placeholder="Value"
                  onChange={e => onInputChange(e, "replace_value", operationIndex)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        );
        break;
      case "Add":
      case "Delete":
      case "Override":
        logicSelections.push(
          <div key={logicSelections.length} className="logicSelection">
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={utmOptrnObj.utm_key || ""}
                  disabled={readOnly}
                  data-utmindex={utmIndex}
                  data-type="utm_operation"
                  placeholder="Key"
                  onChange={e => onInputChange(e, "utm_key", operationIndex)}
                />
                <Form.Control
                  value={utmOptrnObj.utm_value || ""}
                  disabled={readOnly}
                  data-utmindex={utmIndex}
                  data-type="utm_operation"
                  placeholder="Value"
                  onChange={e => onInputChange(e, "utm_value", operationIndex)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        );
        break;
      default:
        break;
    }
    return logicSelections;
  };

  const renderCookieLogic = (utmOptrnObj, operationIndex) => {
    let logicSelections: JSX.Element[] = [];
    switch (utmOptrnObj.operation) {
      case "Replace":
        logicSelections.push(
          <div key={logicSelections.length} className="logicSelection">
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={utmOptrnObj.cookie_key || ""}
                  disabled={readOnly}
                  data-type="cookie_operation"
                  placeholder="Key"
                  onChange={e => onInputChange(e, "cookie_key", operationIndex)}
                />
                <Form.Control
                  value={utmOptrnObj.cookie_value || ""}
                  disabled={readOnly}
                  data-type="cookie_operation"
                  placeholder="Value"
                  onChange={e => onInputChange(e, "cookie_value", operationIndex)}
                />
              </InputGroup>
            </Form.Group>
            <div className="iconWrapper">
              <MdArrowCircleRight />
            </div>
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={utmOptrnObj.cki_replace_key || ""}
                  disabled={readOnly}
                  data-type="cookie_operation"
                  placeholder="Key"
                  onChange={e => onInputChange(e, "cki_replace_key", operationIndex)}
                />
                <Form.Control
                  value={utmOptrnObj.cki_replace_value || ""}
                  disabled={readOnly}
                  data-type="cookie_operation"
                  placeholder="Value"
                  onChange={e => onInputChange(e, "cki_replace_value", operationIndex)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        );
        break;
      case "Delete":
      case "Create":
        logicSelections.push(
          <div key={logicSelections.length} className="logicSelection">
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={utmOptrnObj.cookie_key || ""}
                  disabled={readOnly}
                  data-type="cookie_operation"
                  placeholder="Key"
                  onChange={e => onInputChange(e, "cookie_key", operationIndex)}
                />
                <Form.Control
                  value={utmOptrnObj.cookie_value || ""}
                  disabled={readOnly}
                  data-type="cookie_operation"
                  placeholder="Value"
                  onChange={e => onInputChange(e, "cookie_value", operationIndex)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        );
        break;
      default:
        break;
    }
    return logicSelections;
  };

  const getCookieOperationUrls = () => {
    let variantList = newRule.variant_splits;
    const cookieOperationUrls = [
      ...R.innerJoin(
        (
          variantOption: { variant_url: string; variant_id: string },
          selectedVariant: { split: number; variant_id: string }
        ) => selectedVariant.variant_id === variantOption.variant_id,
        wtoFormObj.variants,
        R.filter(
          (variant: { split: number; variant_id: string }) => !R.isEmpty(variant.variant_id),
          variantList
        )
      ).map(variant => ({
        cookie_variant_id: variant.variant_id,
        cookie_variant_url: variant.variant_url,
      })),
    ];
    return cookieOperationUrls;
  };

  const renderCookieVariants = () => {
    const cookieOperationUrls = getCookieOperationUrls();
    const variantOptions = [
      {
        cookie_variant_id: "All",
      },
      ...cookieOperationUrls,
    ];
    return (
      <div className="operationSection utmSection">
        <div className="variantWrapper">
          Select Variant for Cookie Operations
          <DropdownButton
            className="conditionDropdown"
            disabled={readOnly}
            title={
              newRule.cookie_operation_url.length === 0 || newRule.cookie_operation_url.length > 1
                ? "All"
                : newRule.cookie_operation_url[0].cookie_variant_id
            }
          >
            {variantOptions.map(variant => (
              <Dropdown.Item
                key={variant.cookie_variant_id}
                eventKey={variant.cookie_variant_id}
                onSelect={e => {
                  const newRuleFormObj = R.clone(newRule);
                  if (variant.cookie_variant_id === "All") {
                    newRuleFormObj.cookie_operation_url = cookieOperationUrls;
                  } else {
                    newRuleFormObj.cookie_operation_url = [
                      R.find(R.propEq("cookie_variant_id", e), cookieOperationUrls),
                    ];
                  }
                  setNewRule(newRuleFormObj);
                }}
                placeholder="Select Variant"
              >
                {variant.cookie_variant_id}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      </div>
    );
  };

  const renderUrlParams = () => {
    let variantList = wtoFormObj.variants;
    variantList = [...variantList, { variant_id: "All", variant_url: wtoFormObj.variants }];
    return (
      <div className="operationSection utmSection">
        {newRule.additional_utm.map((variant, index: number) => {
          const selectedVariants = newRule.additional_utm.map(addUtm =>
            R.isEmpty(addUtm.additional_utm_url)
              ? ""
              : addUtm.additional_utm_url.length === 1
              ? addUtm.additional_utm_url[0].utm_variant_id
              : "All"
          );
          return (
            <div key={index}>
              <hr />
              <div className="variantWrapper">
                Select Variant for UTM Parameter Updates
                <DropdownButton
                  className="conditionDropdown"
                  disabled={readOnly}
                  title={
                    variant.additional_utm_url.length === 0
                      ? "Select Variant"
                      : variant.additional_utm_url.length === 1
                      ? variant.additional_utm_url[0].utm_variant_id
                      : "All"
                  }
                >
                  {variantList.map(variant => {
                    // check if variant has already been selected in additional_utm
                    // only allow a variant as an option if it has not been selected
                    return R.includes(variant.variant_id, selectedVariants) ? null : (
                      <Dropdown.Item
                        key={variant.variant_id}
                        eventKey={variant.variant_id}
                        onSelect={() => onSelectChange(variant, "additional_utm", index)}
                        placeholder="Select Variant"
                      >
                        {variant.variant_id}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                {!readOnly && (
                  <Button
                    data-index={index}
                    type={ButtonType.FILLED}
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    icon={<MdDelete />}
                    onClick={e => {
                      e.preventDefault();
                      onDeleteOperationClick(e, "additional_utm");
                    }}
                  />
                )}
              </div>
              <div className="operationWrapper">
                {variant.additional_utm_url.length > 0 && (
                  <>
                    {variant.additional_utm_operation.map((_, idj: number) => (
                      <div className="eachOperation" key={idj}>
                        <DropdownButton
                          disabled={readOnly}
                          className="conditionDropdown"
                          title={
                            variant.additional_utm_operation[idj].operation
                              ? variant.additional_utm_operation[idj].operation
                              : "Select Operation"
                          }
                        >
                          {UTM_OPERATION.map(utmOperation => (
                            <Dropdown.Item
                              key={utmOperation}
                              eventKey={utmOperation}
                              onSelect={e =>
                                onSelectChange(e, "additional_utm_operation", idj, index)
                              }
                              placeholder="Select Operation"
                            >
                              {utmOperation}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                        {variant.additional_utm_operation[idj].operation &&
                          variant.additional_utm_operation[idj].operation.length > 0 &&
                          renderUrlParamLogic(variant.additional_utm_operation[idj], idj, index)}
                        {!readOnly && (
                          <Button
                            data-index={index}
                            type={ButtonType.FILLED}
                            variant={ButtonFrameworkVariant.ICON_ONLY}
                            icon={<MdDelete />}
                            onClick={e => {
                              e.preventDefault();
                              onDeleteOperationClick(e, "additional_utm_operation", idj);
                            }}
                          />
                        )}
                      </div>
                    ))}
                    <Button
                      disabled={readOnly}
                      className="add-optrn"
                      type={ButtonType.FILLED}
                      variant={ButtonFrameworkVariant.LEADING_ICON}
                      icon={<HiOutlinePlus />}
                      onClick={e => {
                        e.preventDefault();
                        addParameterOperation(index, "additional_utm_operation");
                      }}
                    >
                      Add Operation
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderCookie = () => {
    return (
      <div className="operationSection cookieSection">
        {newRule.cookie_operation.length > 0 && (
          <div className="operationWrapper">
            {newRule.cookie_operation.map((_, idj: number) => (
              <div className="eachOperation" key={idj}>
                <DropdownButton
                  disabled={readOnly}
                  className="conditionDropdown"
                  title={
                    newRule.cookie_operation[idj].operation
                      ? newRule.cookie_operation[idj].operation
                      : "Select Operation"
                  }
                >
                  {COOKIE_OPERATION.map(cookieOperation => (
                    <Dropdown.Item
                      key={cookieOperation}
                      eventKey={cookieOperation}
                      onSelect={e => onSelectChange(e, "cookie_operation", idj)}
                      placeholder="Select Operation"
                    >
                      {cookieOperation}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                {newRule.cookie_operation[idj].operation &&
                  newRule.cookie_operation[idj].operation.length > 0 &&
                  renderCookieLogic(newRule.cookie_operation[idj], idj)}
                {!readOnly && (
                  <Button
                    data-index={idj}
                    type={ButtonType.FILLED}
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    icon={<MdDelete />}
                    onClick={e => {
                      e.preventDefault();
                      onDeleteOperationClick(e, "cookie_operation");
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const addParameterUpdatesRow = (ruleType: string): void => {
    const newRuleFormObj = R.clone(newRule);

    switch (ruleType) {
      case "additional_utm":
        newRuleFormObj.additional_utm = [
          ...newRule.additional_utm,
          {
            additional_utm_operation: [],
            additional_utm_url: [],
          },
        ];
        break;
      case "cookie_operation":
        newRuleFormObj.cookie_operation = [
          ...newRule.cookie_operation,
          {
            cookie_key: "",
            cookie_value: "",
            operation: "",
          },
        ];
        break;
      default:
        break;
    }
    setNewRule(newRuleFormObj);
  };

  const addParameterOperation = (index, typeblock) => {
    const newRuleFormObj = R.clone(newRule);
    newRuleFormObj.additional_utm[index][typeblock] = [
      ...newRuleFormObj.additional_utm[index][typeblock],
      {
        operation: "",
        utm_key: "",
        utm_value: "",
      },
    ];
    setNewRule(newRuleFormObj);
  };

  const validateRule = () => {
    const validationArray = [
      "rule_name",
      "conditions",
      "variant_splits",
      "timeframe_dates",
      "timezone",
      "additional_utm",
      "cookie_operation",
      "cookie_operation_url",
      "dow_operation",
      "customRetention",
    ];
    if (newRule.timeframe && newRule.timeframe.timeframe_utm_test_id_enabled) {
      validationArray.push("timeframe_utm_test_id");
    }
    const errors = formValidator(
      newRule,
      validationArray,
      {
        isBulkPlover: false,
      },
      {
        currentUtmTestId: wtoFormObj.utm_test_id,
        currentRulesArray: wtoFormObj.rules,
        currentRuleIndex: Number(newRule.rule_order),
      }
    );
    let newRuleFormObj = R.clone(newRule);
    let validDows = R.filter(
      (dowOp: Record<string, any>) => !R.isEmpty(dowOp.day),
      newRuleFormObj.dow_operation
    );
    newRuleFormObj.dow_operation = validDows;
    validDows.forEach((dowOperation, index) => {
      if (
        moment(dowOperation.start_time).isAfter(dowOperation.end_time) ||
        moment(dowOperation.start_time).isSame(dowOperation.end_time)
      ) {
        errors.push(
          `Day of the week ${index} start and end times cannot be the same and start time cannot be ahead of end time`
        );
      }
    });

    if (errors.length > 0) {
      setValidationErrors(errors);
    } else {
      let newWtoFormObj = R.clone(wtoFormObj);

      // helper function to determine if a rule has custom retention enabled
      const isNotCustomRetentionRule = (rule: Record<string, any>) =>
        R.isEmpty(rule.customRetention) ||
        (rule.customRetention &&
          !R.isNil(rule.customRetention.customRetentionStatus) &&
          !rule.customRetention.customRetentionStatus);

      // first simple rule
      let simpleFirstIndex = R.findIndex(
        (rule: Record<string, any>) =>
          isNotCustomRetentionRule(rule) &&
          !rule.timeframe_enabled &&
          rule.dow_operation.length === 0,
        wtoFormObj.rules
      );

      // first dow rule
      let dowFirstIndex = R.findIndex(
        (rule: Record<string, any>) =>
          isNotCustomRetentionRule(rule) &&
          !rule.timeframe_enabled &&
          rule.dow_operation.length > 0,
        wtoFormObj.rules
      );

      // last dow rule
      let dowLastIndex = R.findLastIndex(
        (rule: Record<string, any>) =>
          isNotCustomRetentionRule(rule) &&
          !rule.timeframe_enabled &&
          rule.dow_operation.length > 0,
        wtoFormObj.rules
      );

      // first timeframe rule
      let timeframeFirstIndex = R.findIndex(
        (rule: Record<string, any>) =>
          isNotCustomRetentionRule(rule) && rule.timeframe_enabled && rule.timeframe,
        wtoFormObj.rules
      );

      // last timeframe rule
      let timeframeLastIndex = R.findLastIndex(
        (rule: Record<string, any>) =>
          isNotCustomRetentionRule(rule) && rule.timeframe_enabled && rule.timeframe,
        wtoFormObj.rules
      );

      // last custom retention rule
      let customRetentionLastIndex = R.findLastIndex(
        (rule: Record<string, any>) =>
          !R.isEmpty(rule.customRetention) && rule.customRetention.customRetentionStatus,
        wtoFormObj.rules
      );

      // update both the list of rules, and show/hide state of each rule card
      let newRules = R.clone(wtoFormObj.rules);
      let newShowRuleCards = R.clone(showRuleCards);

      // helper function to remove old data for a rule if it has been updated in a new index
      const removeOldRule = newIndex => {
        if (ruleIndex || ruleIndex === 0) {
          newRules = R.remove(newIndex > ruleIndex ? ruleIndex : ruleIndex + 1, 1, newRules);
          newShowRuleCards = R.remove(
            newIndex > ruleIndex ? ruleIndex : ruleIndex + 1,
            1,
            newShowRuleCards
          );
        }
      };

      // if edited rule has custom retention enabled, place at top of list
      if (newRule.customRetention && newRule.customRetention.customRetentionStatus) {
        newRules = R.prepend(newRule, newRules);
        newShowRuleCards = [true, ...showRuleCards];
        removeOldRule(0);

        // if edited rule has timeframe enabled:
        // - if a custom retention rule exists, insert after the last custom retention rule
        // - else if a dow rule exists, insert before first dow rule
        // - else if a simple rule exists, insert before first simple rule
        // - else, insert at the end of the list
      } else if (newRule.timeframe_enabled && newRule.timeframe) {
        let newTimeframeIndex =
          customRetentionLastIndex !== -1
            ? customRetentionLastIndex + 1
            : timeframeFirstIndex !== -1
            ? timeframeFirstIndex
            : dowFirstIndex !== -1
            ? dowFirstIndex
            : simpleFirstIndex !== -1
            ? simpleFirstIndex
            : newRules.length;
        newRules = R.insert(newTimeframeIndex, newRule, newRules);
        newShowRuleCards = R.insert(newTimeframeIndex, true, newShowRuleCards);
        removeOldRule(newTimeframeIndex);

        // if edited rule has day of week operation:
        // - if a timeframe rule exists, insert after the last timeframe rule
        // - else if a simple rule exists, insert before first simple rule
        // - else, insert at the end of the list
      } else if (newRule.dow_operation.length > 0) {
        let newDowIndex =
          timeframeLastIndex !== -1
            ? timeframeLastIndex + 1
            : dowFirstIndex !== -1
            ? dowFirstIndex
            : simpleFirstIndex !== -1
            ? simpleFirstIndex
            : newRules.length;
        newRules = R.insert(newDowIndex, newRule, newRules);
        newShowRuleCards = R.insert(newDowIndex, true, newShowRuleCards);
        removeOldRule(newDowIndex);

        // if edited rule is simple (none of the preceeding cases apply):
        // - if editing existing rule, edit rule at current index
        // - else, insert rule at the end of the list
      } else if (ruleIndex || ruleIndex === 0) {
        let newSimpleIndex = ruleIndex > dowLastIndex ? ruleIndex : newRules.length;
        newRules = R.insert(newSimpleIndex, newRule, newRules);
        removeOldRule(newSimpleIndex);
      } else {
        newRules = R.append(newRule, newRules);
        newShowRuleCards = [...showRuleCards, true];
      }

      // update rule orders
      newRules.forEach((rule, ruleIndex) => {
        rule.rule_order = `${ruleIndex}`;

        // ensure all URLs are added if cookie operation(s) have been added
        // and cookie operation URL option has been left selected as "All" by default
        if (rule.cookie_operation.length > 0 && rule.cookie_operation_url.length === 0) {
          rule.cookie_operation_url = getCookieOperationUrls();
        }
      });

      // save changes in state and close modal
      newWtoFormObj.rules = newRules;
      setShowRuleCards(newShowRuleCards);
      setWtoFormObj(newWtoFormObj);
      closeModal();
    }
  };

  const addTrafficSplit = () => {
    if (newRule.variant_splits.length < wtoFormObj.variants.length) {
      updateRuleField("variant_splits", [...newRule.variant_splits, { split: 0, variant_id: "" }]);
    }
  };

  const toggleCustomRetention = event => {
    const newRuleFormObj = R.clone(newRule);
    if (!event) {
      newRuleFormObj.customRetention = {};
    } else {
      let newCustomRetention = R.clone(defaultCustomRetention);
      newCustomRetention.customRetentionStatus = event;

      newRuleFormObj.customRetention = newCustomRetention;
    }
    setNewRule(newRuleFormObj);
  };

  const getTimestampHour = timestamp => {
    let hour = Number(timestamp.substring(11, 13));
    if (hour === 0 || hour === 12) {
      return "12";
    } else if (hour < 12) {
      return timestamp.substring(11, 13);
    } else {
      return `${hour - 12}`.padStart(2, "0");
    }
  };

  Number(timestamp.substring(11, 13)) < 12
    ? timestamp.substring(11, 13)
    : `${Number(timestamp.substring(11, 13)) - 12}`.padStart(2, "0");
  const getTimestampMinute = timestamp => timestamp.substring(14, 16);
  const getTimestampAmpm = timestamp => (Number(timestamp.substring(11, 13)) < 12 ? "AM" : "PM");

  const handleDateAndTime = (
    data: any,
    timeType: string,
    ruleName: string,
    defaultRule: Record<string, any>,
    propertyName: string,
    formattedPropertyName: string,
    bothProperties?: string[] | null,
    bothFormattedProperties?: string[] | null,
    propertyIndex?: number
  ) => {
    // default values for time initialization
    let userTimezone = R.invertObj(TIMEZONE_MAP)[timezone];
    let updatedTime = "";
    let formattedUpdatedTime = "";
    let date = today;
    let dateTokens = today.split("-");
    let hour = "00";
    let min = "00";
    let tz = R.includes(userTimezone, TIMEZONE_OPTIONS)
      ? R.invertObj(TIMEZONE_MAP)[timezone]
      : "UTC";
    let offset = tz === "UTC" ? 0 : moment(date).tz(timezone).utcOffset() / 60;
    let ampm = "am";

    // check if we need to get property directly or get array object
    let updatedRule = !R.isNil(propertyIndex)
      ? newRule[ruleName][propertyIndex]
      : newRule[ruleName];
    let updatedTimeData = R.clone(defaultRule);

    if (updatedRule) {
      updatedTimeData = R.clone(updatedRule);

      // if property already exists and is a time type, get its values
      if (
        propertyName !== "time_zone" &&
        updatedRule[propertyName] &&
        updatedRule[propertyName].length !== 0
      ) {
        let existingRule = updatedRule[propertyName];
        const currDateTokens = existingRule.split("T");
        const currTimeOfDayTokens = currDateTokens[1].split(":");
        date = currDateTokens[0];
        hour = currTimeOfDayTokens[0];
        min = currTimeOfDayTokens[1];
        tz = updatedRule.time_zone;
        ampm = Number(hour) < 12 ? "am" : "pm";
        offset = Number(currTimeOfDayTokens[2].substring(2));
      }
    }

    // parse time data from parameter based on type
    switch (timeType) {
      case "date":
        date = data;
        dateTokens = data.split("-");
        break;
      case "hour":
        hour = data.padStart(2, "0");
        if (Number(data) === 0 || Number(data) === 12) {
          hour = ampm.toLocaleLowerCase() === "am" ? "00" : "12";
        } else if (ampm.toLocaleLowerCase() === "am" && Number(data) > 12) {
          hour = `${Number(hour) - 12}`.padStart(2, "0");
        } else if (ampm.toLocaleLowerCase() === "pm" && Number(data) < 12) {
          hour = `${Number(hour) + 12}`.padStart(2, "0");
        }
        break;
      case "minute":
        min = data.padStart(2, "0");
        break;
      case "ampm":
        ampm = data.toLocaleLowerCase();
        if (Number(hour) === 0 || Number(hour) === 12) {
          hour = ampm.toLocaleLowerCase() === "am" ? "00" : "12";
        } else if (ampm.toLocaleLowerCase() === "am" && Number(hour) > 12) {
          hour = `${Number(hour) - 12}`.padStart(2, "0");
        } else if (ampm.toLocaleLowerCase() === "pm" && Number(hour) < 12) {
          hour = `${Number(hour) + 12}`.padStart(2, "0");
        }
        break;
      case "time_zone":
        tz = data;
        offset = moment(date).tz(TIMEZONE_MAP[data]).utcOffset() / 60;
        break;
    }

    // if time zone was changed and the object has
    // both start and end timestamps, update both
    if (propertyName === "time_zone" && bothProperties && bothFormattedProperties) {
      let property1 = updatedTimeData[bothProperties[0]];
      let property2 = updatedTimeData[bothProperties[1]];
      let formattedProperty1 = updatedTimeData[bothFormattedProperties[0]];
      let formattedProperty2 = updatedTimeData[bothFormattedProperties[1]];
      let gmtOffset = `${offset < 0 ? "-" : "+"}${Math.abs(offset).toString().padStart(2, "0")}:00`;
      property1 = R.isEmpty(property1)
        ? `${date}T${hour}:${min}:00${gmtOffset}`
        : `${updatedRule[bothProperties[0]].substring(0, 19)}${gmtOffset}`;
      property2 = R.isEmpty(property2)
        ? `${date}T${hour}:${min}:00${gmtOffset}`
        : `${updatedRule[bothProperties[1]].substring(0, 19)}${gmtOffset}`;
      formattedProperty1 = R.isEmpty(formattedProperty1)
        ? `${dateTokens[0]}/${Number(dateTokens[1])}/${Number(dateTokens[2])}, ${
            Number(hour) > 12 ? Number(hour) - 12 : Number(hour)
          }:${min}${ampm}`
        : formattedProperty1;
      formattedProperty2 = R.isEmpty(formattedProperty2)
        ? `${dateTokens[0]}/${Number(dateTokens[1])}/${Number(dateTokens[2])}, ${
            Number(hour) > 12 ? Number(hour) - 12 : Number(hour)
          }:${min}${ampm}`
        : formattedProperty2;
      updatedTimeData[bothProperties[0]] = property1;
      updatedTimeData[bothProperties[1]] = property2;
      updatedTimeData[bothFormattedProperties[0]] = formattedProperty1;
      updatedTimeData[bothFormattedProperties[1]] = formattedProperty2;
      updatedTimeData.time_zone = tz;
    } else {
      updatedTime = `${date}T${hour}:${min}:00${offset < 0 ? "-" : "+"}${Math.abs(offset)
        .toString()
        .padStart(2, "0")}:00`;
      formattedUpdatedTime = `${dateTokens[0]}/${Number(dateTokens[1])}/${Number(dateTokens[2])}, ${
        Number(hour) > 12 ? Number(hour) - 12 : Number(hour)
      }:${min}${ampm}`;

      updatedTimeData[propertyName] = updatedTime;
      updatedTimeData[formattedPropertyName] = formattedUpdatedTime;
      updatedTimeData.time_zone = tz;
    }

    return updatedTimeData;
  };

  const handleCustomRetention = (data: any, type: string) => {
    const newRuleFormObj = R.clone(newRule);
    let updatedRule = handleDateAndTime(
      data,
      type,
      "customRetention",
      defaultCustomRetention,
      "startDate",
      "formattedStartDate"
    );
    newRuleFormObj.customRetention = updatedRule;
    setNewRule(newRuleFormObj);
  };

  const handleTimeframe = (data: any, type: string, isStartTime?: boolean) => {
    const newRuleFormObj = R.clone(newRule);
    if (data.startDate && data.endDate) {
      if (data.startDate !== "Invalid date" && data.endDate !== "Invalid date") {
        let updatedRuleStart = handleDateAndTime(
          data.startDate,
          type,
          "timeframe",
          defaultTimeframe,
          "startDateTime",
          "formattedStartDateTime"
        );
        let updatedRuleEnd = handleDateAndTime(
          data.endDate,
          type,
          "timeframe",
          defaultTimeframe,
          "endDateTime",
          "formattedEndDateTime"
        );
        newRuleFormObj.timeframe = {
          ...newRuleFormObj.timeframe,
          startDateTime: updatedRuleStart.startDateTime,
          formattedStartDateTime: updatedRuleStart.formattedStartDateTime,
          endDateTime: updatedRuleEnd.endDateTime,
          formattedEndDateTime: updatedRuleEnd.formattedEndDateTime,
          time_zone: updatedRuleEnd.time_zone,
        };
      }
      setNewRule(newRuleFormObj);
    } else if (type === "time_zone") {
      let updatedRule = handleDateAndTime(
        data,
        type,
        "timeframe",
        defaultTimeframe,
        "time_zone",
        "time_zone",
        ["endDateTime", "startDateTime"],
        ["formattedEndDateTime", "formattedStartDateTime"]
      );
      newRuleFormObj.timeframe = updatedRule;
      setNewRule(newRuleFormObj);
    } else {
      let updatedRule = handleDateAndTime(
        data,
        type,
        "timeframe",
        defaultTimeframe,
        isStartTime ? "startDateTime" : "endDateTime",
        isStartTime ? "formattedStartDateTime" : "formattedEndDateTime"
      );
      newRuleFormObj.timeframe = updatedRule;
      setNewRule(newRuleFormObj);
    }
  };

  const handleDowTime = (data: any, type: string, index: number, isStartTime?: boolean) => {
    const newRuleFormObj = R.clone(newRule);
    if (type === "time_zone") {
      let updatedRule = handleDateAndTime(
        data,
        type,
        "dow_operation",
        defaultDowRow,
        "time_zone",
        "time_zone",
        ["end_time", "start_time"],
        ["formattedEndTime", "formattedStartTime"],
        index
      );
      newRuleFormObj.dow_operation[index] = updatedRule;
    } else {
      let updatedRule = handleDateAndTime(
        data,
        type,
        "dow_operation",
        defaultDowRow,
        isStartTime ? "start_time" : "end_time",
        isStartTime ? "formattedStartTime" : "formattedEndTime",
        null,
        null,
        index
      );
      newRuleFormObj.dow_operation[index] = updatedRule;
    }
    setNewRule(newRuleFormObj);
  };

  const toggleTimeframe = () => {
    let newRuleFormObj = R.clone(newRule);
    newRuleFormObj.timeframe_enabled = !newRuleFormObj.timeframe_enabled;
    if (newRuleFormObj.timeframe_enabled) {
      newRuleFormObj.timeframe = R.clone(defaultTimeframe);
    } else {
      newRuleFormObj = R.omit(["timeframe"], newRuleFormObj);
    }
    setNewRule(newRuleFormObj);
  };

  const addDayOfWeekSplitRow = () => {
    const newRuleFormObj = R.clone(newRule);
    const newDowOperation = R.clone(defaultDowRow);
    newRuleFormObj.dow_operation.push(newDowOperation);
    setNewRule(newRuleFormObj);
  };

  const addDowTrafficSplit = index => {
    const newRuleFormObj = R.clone(newRule);
    let dowVariants = R.clone(newRuleFormObj.dow_operation[index].variants);
    if (dowVariants.length < wtoFormObj.variants.length) {
      dowVariants.push({ name: "", url: "", split: 0 });
    } else {
      setValidationErrors([
        "There are no more variants for this test to split against; to add more, return to the Naming & Variants step",
      ]);
    }
    newRuleFormObj.dow_operation[index].variants = dowVariants;
    setNewRule(newRuleFormObj);
  };

  const handleDowVariant = (operation, index, rowIndex?, data?) => {
    const newRuleFormObj = R.clone(newRule);
    let dowRows = R.clone(newRuleFormObj.dow_operation);
    let dowVariants = R.clone(newRuleFormObj.dow_operation[index].variants);

    switch (operation) {
      case "select":
        dowVariants[rowIndex].name = data.variant_id;
        dowVariants[rowIndex].url = data.variant_url;
        newRuleFormObj.cookie_operation_url = [];
        break;
      case "allocate":
        dowVariants[rowIndex].split = Number(data);
        break;
      case "delete":
        if (!R.isNil(rowIndex)) {
          dowVariants = R.remove(rowIndex, 1, dowVariants);
        } else {
          dowRows = R.remove(index, 1, dowRows);
        }
        newRuleFormObj.cookie_operation_url = [];
        break;
    }

    if (!R.isNil(rowIndex)) {
      newRuleFormObj.dow_operation[index].variants = dowVariants;
    } else {
      newRuleFormObj.dow_operation = dowRows;
    }
    setNewRule(newRuleFormObj);
  };

  const selectDay = (day, index) => {
    if (!readOnly) {
      const newRuleFormObj = R.clone(newRule);
      let dowSelections = R.clone(newRuleFormObj.dow_operation[index].day);
      let dayIndex = R.findIndex(x => x === day.number, dowSelections);
      if (dayIndex === -1) {
        dowSelections.push(day.number);
      } else {
        dowSelections = R.remove(dayIndex, 1, dowSelections);
      }
      newRuleFormObj.dow_operation[index].day = dowSelections;
      setNewRule(newRuleFormObj);
    }
  };

  const cancelRuleCreation = () => {
    setNewRule(R.clone(defaultRule));
    closeModal();
  };

  const renderTimeSelection = (handler, timestamp?, isStartTime?, index?) => {
    const noSelection = R.isNil(timestamp) || R.isEmpty(timestamp);
    return (
      <div className="timeSelection">
        <DropdownButton
          disabled={readOnly}
          className="timeDropdown hour"
          title={noSelection ? "--" : getTimestampHour(timestamp)}
        >
          {R.range(0, 12).map(hourIndex =>
            R.pipe((hour: string) => (
              <Dropdown.Item
                key={hour}
                eventKey={hour}
                onSelect={time => handler(time, "hour", isStartTime, index)}
              >
                {hour.padStart(2, "0")}
              </Dropdown.Item>
            ))(`${hourIndex === 0 ? 12 : hourIndex}`)
          )}
        </DropdownButton>
        :
        <DropdownButton
          disabled={readOnly}
          className="timeDropdown minute"
          title={noSelection ? "--" : getTimestampMinute(timestamp)}
        >
          {R.range(0, 60).map(minIndex =>
            R.pipe((min: string) => (
              <Dropdown.Item
                key={min}
                eventKey={min}
                onSelect={time => handler(time, "minute", isStartTime, index)}
              >
                {min.padStart(2, "0")}
              </Dropdown.Item>
            ))(minIndex.toString())
          )}
        </DropdownButton>
        <DropdownButton
          disabled={readOnly}
          className="timeDropdown ampm"
          title={noSelection ? "--" : getTimestampAmpm(timestamp)}
        >
          {["AM", "PM"].map(ampm => (
            <Dropdown.Item
              key={ampm}
              eventKey={ampm}
              onSelect={time => handler(time, "ampm", isStartTime, index)}
            >
              {ampm}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    );
  };

  const toggleTimeframeParam = () => {
    const newRuleFormObj = R.clone(newRule);
    newRuleFormObj.timeframe.timeframe_utm_test_id_enabled = !newRuleFormObj.timeframe
      .timeframe_utm_test_id_enabled;
    setNewRule(newRuleFormObj);
  };

  return (
    <Modal show={show} onHide={cancelRuleCreation} className="wtoRulesFormModal">
      <Modal.Header closeButton>
        <Modal.Title>Route Rules</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WtoValidationErrors validationErrors={validationErrors} />
        <Form className="form">
          <Form.Row className="ruleNameWrapper">
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  value={newRule.rule_name}
                  disabled={readOnly}
                  placeholder="Rule Name"
                  onChange={e => onInputChange(e, "rule_name")}
                />
              </InputGroup>
            </Form.Group>
            <Form.Check
              disabled={readOnly}
              type="switch"
              id="timeframe-switch"
              label="Timeframe"
              checked={newRule.timeframe_enabled}
              onChange={toggleTimeframe}
            />
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="ruleEditor">
              <span className="ruleHeader">Traffic Allocation</span>
              {renderVariantsSplits(newRule.variant_splits)}
              <Form.Check
                disabled={readOnly}
                className="retentionCheckbox"
                label="Reset Variant Retention"
                type="checkbox"
                checked={
                  R.isEmpty(newRule.customRetention)
                    ? false
                    : newRule.customRetention.customRetentionStatus || false
                }
                value={
                  R.isEmpty(newRule.customRetention)
                    ? false
                    : newRule.customRetention.customRetentionStatus || false
                }
                onChange={e => toggleCustomRetention(e.target.checked)}
              />
              {newRule.customRetention.customRetentionStatus && (
                <div className="retentionWrapper">
                  Select date of reset
                  <SingleDatePicker
                    disabled={readOnly}
                    minYear={MIN_YEAR}
                    maxYear={MAX_YEAR}
                    date={newRule.customRetention.startDate || null}
                    onChange={date => handleCustomRetention(date, "date")}
                  />
                  {renderTimeSelection(handleCustomRetention, newRule.customRetention.startDate)}
                  <DropdownButton
                    disabled={readOnly}
                    className="conditionDropdown"
                    title={
                      newRule.customRetention.time_zone
                        ? newRule.customRetention.time_zone
                        : "Select Timezone"
                    }
                  >
                    {TIMEZONE_OPTIONS.map(timezone => (
                      <Dropdown.Item
                        key={timezone}
                        eventKey={timezone}
                        onSelect={e => handleCustomRetention(e, "time_zone")}
                        placeholder="Select Timezone"
                      >
                        {timezone}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
              )}
              <p>Note: Checking this will randomize variants for returning users</p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="ruleEditor">
              <span className="ruleHeader">Conditions</span>
              {renderConditions()}
              <Button
                disabled={readOnly}
                className="addRule"
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.LEADING_ICON}
                icon={<HiOutlinePlus />}
                onClick={e => {
                  e.preventDefault();
                  addCondition();
                }}
              >
                Add Condition
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="ruleEditor utmCookieWrapper">
              <span className="ruleHeader">URL Parameter Updates</span>
              {renderUrlParams()}
              {!R.isEmpty(newRule.additional_utm) && <hr />}
              {newRule.additional_utm.length < wtoFormObj.variants.length + 1 && (
                <Button
                  disabled={readOnly}
                  className="addRule"
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  icon={<HiOutlinePlus />}
                  onClick={e => {
                    e.preventDefault();
                    addParameterUpdatesRow("additional_utm");
                  }}
                >
                  Add Row
                </Button>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="ruleEditor utmCookieWrapper">
              <span className="ruleHeader">Cookie Operations</span>
              {renderCookieVariants()}
              {renderCookie()}
              <Button
                disabled={readOnly}
                className="addRule"
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.LEADING_ICON}
                icon={<HiOutlinePlus />}
                onClick={e => {
                  e.preventDefault();
                  addParameterUpdatesRow("cookie_operation");
                }}
              >
                Add Row
              </Button>
            </Form.Group>
          </Form.Row>
          {newRule.timeframe_enabled && (
            <Form.Row>
              <Form.Group as={Col} className="ruleEditor timeframeWrapper">
                <span className="ruleHeader">Time Frame</span>
                <div className="dateSelection">
                  <div className="dateRangeWrapper">
                    Start and End Dates:
                    <DateRangePicker
                      disabled={readOnly}
                      startDate={newRule.timeframe.startDateTime.split("T")[0] || today}
                      endDate={newRule.timeframe.endDateTime.split("T")[0] || today}
                      startDateId="activeOTTStartDate"
                      endDateId="activeOTTEndDate"
                      onChange={e => handleTimeframe(e, "date")}
                    />
                  </div>
                  <div className="timeRangeWrapper">
                    Start Time:{" "}
                    {renderTimeSelection(handleTimeframe, newRule.timeframe.startDateTime, true)}
                    End Time:{" "}
                    {renderTimeSelection(handleTimeframe, newRule.timeframe.endDateTime, false)}
                    <DropdownButton
                      disabled={readOnly}
                      className="conditionDropdown"
                      title={
                        newRule.timeframe.time_zone
                          ? newRule.timeframe.time_zone
                          : "Select Timezone"
                      }
                    >
                      {TIMEZONE_OPTIONS.map(timezone => (
                        <Dropdown.Item
                          key={timezone}
                          eventKey={timezone}
                          onSelect={e => handleTimeframe(e, "time_zone")}
                          placeholder="Select Timezone"
                        >
                          {timezone}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div className="timeframeUtmWrapper">
                    <Form.Check
                      disabled={readOnly}
                      type="switch"
                      id="utm-switch"
                      label="Timeframe Route Tracking URL Parameter"
                      checked={newRule.timeframe.timeframe_utm_test_id_enabled}
                      onChange={toggleTimeframeParam}
                    />
                    {newRule.timeframe.timeframe_utm_test_id_enabled && (
                      <>
                        All web traffic will be tagged with the{" "}
                        <strong>{wtoFormObj.utm_test_id}</strong> parameter outside of the Rule Time
                        Frame. During the time frame specified above, all Web Traffic will be tagged
                        using the following Tracking URL Parameter:
                        <div className="utmFieldContainer">
                          utm_
                          <Form.Control
                            disabled={readOnly}
                            type="text"
                            placeholder="Funnel Step"
                            value={getParamFunnelStep(newRule.timeframe.utm_test_id)}
                            onChange={e =>
                              updateTimeframeId(
                                updateParamFunnelStep(
                                  e.target.value,
                                  newRule.timeframe.utm_test_id
                                    ? newRule.timeframe.utm_test_id
                                    : defaultTimeframe.utm_test_id
                                )
                              )
                            }
                          />
                          _exp=
                          <InputGroup>
                            <Form.Control
                              className="testName"
                              disabled={readOnly}
                              type="text"
                              placeholder="Test Name"
                              value={getParamTestName(newRule.timeframe.utm_test_id)}
                              onChange={e =>
                                updateTimeframeId(
                                  updateParamTestName(
                                    e.target.value,
                                    newRule.timeframe.utm_test_id
                                      ? newRule.timeframe.utm_test_id
                                      : defaultTimeframe.utm_test_id
                                  )
                                )
                              }
                            />
                            <Form.Control
                              className="version"
                              disabled={readOnly}
                              type="number"
                              placeholder="Version"
                              value={getParamVersionNumber(newRule.timeframe.utm_test_id)}
                              onChange={e =>
                                updateTimeframeId(
                                  updateParamVersionNumber(
                                    Number(e.target.value),
                                    newRule.timeframe.utm_test_id
                                      ? newRule.timeframe.utm_test_id
                                      : defaultTimeframe.utm_test_id
                                  )
                                )
                              }
                            />
                          </InputGroup>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col} className="ruleEditor">
              <span className="ruleHeader">Day of the Week Split</span>
              <div className="dowOperations">
                {newRule.dow_operation.map((operation, index) => (
                  <div key={index}>
                    <hr />
                    <div className="dowHeader">Select the day of week</div>
                    <div className="dowControls">
                      <div className="dowSelections">
                        {DAYS.map(day => (
                          <div
                            key={day.number}
                            className={`dowSelection ${
                              R.includes(day.number, operation.day)
                                ? "selected"
                                : R.flatten(R.pluck("day")(newRule.dow_operation)).includes(
                                    day.number
                                  )
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() => selectDay(day, index)}
                          >
                            {day.label}
                          </div>
                        ))}
                      </div>
                      <div className="timeRangeWrapper">
                        Start Time:{" "}
                        {renderTimeSelection(
                          handleDowTime,
                          newRule.dow_operation[index].start_time,
                          index,
                          true
                        )}
                        End Time:{" "}
                        {renderTimeSelection(
                          handleDowTime,
                          newRule.dow_operation[index].end_time,
                          index,
                          false
                        )}
                        <DropdownButton
                          disabled={readOnly}
                          className="conditionDropdown"
                          title={
                            newRule.dow_operation[index].time_zone
                              ? newRule.dow_operation[index].time_zone
                              : "Select Timezone"
                          }
                        >
                          {TIMEZONE_OPTIONS.map(timezone => (
                            <Dropdown.Item
                              key={timezone}
                              eventKey={timezone}
                              onSelect={e => handleDowTime(e, "time_zone", index)}
                              placeholder="Select Timezone"
                            >
                              {timezone}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                        {!readOnly && (
                          <Button
                            className="deleteButton"
                            type={ButtonType.FILLED}
                            icon={<MdDelete />}
                            data-index={index}
                            onClick={e => {
                              e.preventDefault();
                              handleDowVariant("delete", index);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {operation.day.length > 0 && renderVariantsSplits(operation.variants, index)}
                  </div>
                ))}
              </div>
              {!R.isEmpty(newRule.dow_operation) && <hr />}
              <Button
                className="addRule"
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.LEADING_ICON}
                icon={<HiOutlinePlus />}
                disabled={readOnly}
                onClick={e => {
                  e.preventDefault();
                  addDayOfWeekSplitRow();
                }}
              >
                Add Row
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type={ButtonType.FILLED} onClick={cancelRuleCreation}>
          Cancel
        </Button>
        <Button type={ButtonType.FILLED} onClick={() => validateRule()}>
          Save Rule
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WtoRulesFormModal;
