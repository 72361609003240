import "./App.scss";
import {
  ExperimentContext,
  getExperimentsForUser,
  getExperimentsForCompany,
  getExperimentsForCompanyWithUserRole,
} from "./utils/experiments/experiment-utils";
import { Location, Redirect, Router } from "@reach/router";
import { LocationContext } from "./utils/hooks/useLocation";
import { Mixpanel } from "./utils/mixpanelWrapper";
import { Page, ProtectedRoute } from "./Components";
import { roleMap } from "./UserAdmin/UserAdmin";
import { useCompanyInfo, useCompanyInfoFetch } from "./redux/company";
import { useSelector } from "react-redux";
import * as R from "ramda";
import * as UserRedux from "./redux/user";
import ABCD from "./ABCD";
import AccountPlanning from "./AccountPlanning";
import ActiveOTT from "./ActiveOTT";
import AddNewClient from "./AddNewClient";
import Admin from "./Admin";
import AdOps from "./AdOps";
import Amplify from "aws-amplify";
import BasicFaq from "./MMM/BasicFaq";
import BatchFailures from "./BatchFailures";
import Calendar from "./Calendar";
import Commissions from "./Commissions";
import Companies from "./Companies";
import CompanyRoutes from "./CompanyRoutes";
import CompanySpecificFiltering from "./CompanySpecificFiltering/CompanySpecificFiltering";
import Cookies from "js-cookie";
import CreditMemos from "./CreditMemos";
import CroOnboard from "./CroOnboard";
import DemoDataGenerator from "./DemoDataGenerator";
import DagGraphs from "./DagGraphs";
import DagStart from "./DagStart";
import DagStatus from "./DagStatus";
import DataConnectorTool from "./DataConnectorTool";
import DeviceBreakout from "./DeviceBreakout";
import EbayRedirct from "./Ebay/EbayRedirect";
import IncrementalityJobStatus from "./IncrementalityJobStatus";
import IncrementalityPlaybook from "./IncrementalityPlaybook";
import InternalReports from "./InternalReports";
import Invoicing from "./Invoicing";
import KpiSetup from "./KpiSetup";
import KPIStatus from "./KPIStatus";
import LinearBuyingAssignments from "./LinearBuyingAssignments";
import LinearCanonicalNetworks from "./LinearCanonicalNetworks";
import LinearClearanceAssumptions from "./LinearClearanceAssumptions";
import LinearConstraints from "./LinearConstraints";
import LinearCpms from "./LinearCpms";
import LinearPacing from "./LinearPacing";
import LinearRates from "./LinearRates";
import LinearRatesComparisons from "./LinearRatesComparisons";
import Login from "./Login";
import LogsTracker from "./LogsTracker";
import MasterTargeting from "./MasterTargeting";
import MediaUpdateGenerator from "./MediaUpdateGenerator";
import NavBar from "./NavBar";
import OldExpectedBookings from "./ExpectedBookings/OldExpectedBookings";
import OutOfHomeBuys from "./OutOfHomeBuys";
import ProcessLogs from "./ProcessLogs";
import QueryExporter from "./QueryExporter";
import React, { useEffect } from "react";
import ReferenceView from "./ReferenceView";
import SalesAndTrafficContacts from "./SalesAndTrafficContacts";
import SiteMonitoring from "./SiteMonitoring";
import StreamingConstraints from "./StreamingConstraints";
import StreamingDiscrepancies from "./StreamingDiscrepancies";
import StreamingMonitoring from "./StreamingMonitoring";
import StreamingNetworks from "./StreamingNetworks";
import StreamingPacing from "./StreamingPacing";
import StreamingPlansBuilder from "./StreamingPlansBuilder";
import UserAdmin from "./UserAdmin";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "us-west-2:6a89fe3b-186f-4fe9-8d37-e7ab34d17628",
    // REQUIRED - Amazon Cognito Region
    region: "us-west-2",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-west-2_QRVXCFwDq",
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "65hq4tpaon1b81kr7q9upoh1mn",
  },
});

const LocationWrapper = () => (
  <Location>
    {({ location }) => (
      <LocationContext.Provider value={location}>
        <MainApp location={location} />
      </LocationContext.Provider>
    )}
  </Location>
);

const MainApp = ({ location }) => {
  useCompanyInfoFetch();
  let { cid } = useCompanyInfo();
  const userEmail = useSelector(UserRedux.emailSelector);
  let userRole = useSelector(UserRedux.roleSelector);
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const isAdmin = useSelector(UserRedux.isAdminSelector);
  // An experiment should be turned on if EITHER the company or user is opted in.
  const companyExperiments = getExperimentsForCompany(cid);
  const userExperiments = getExperimentsForUser(userEmail);
  // Or if the company is opted in and userRole is appropriate
  const companyWithUserRoleExperiments = getExperimentsForCompanyWithUserRole(cid, userRole);
  // Merge all experiments
  const experiments = R.mergeWith(
    R.or,
    R.mergeWith(R.or, companyExperiments, userExperiments),
    companyWithUserRoleExperiments
  );

  useEffect(() => {
    window?.heap?.identify(userEmail);
    window?.heap?.addUserProperties({
      role: roleMap[userRole],
      isInternal: isInternal,
      isAdmin: isAdmin,
    });
    if (userEmail) {
      Mixpanel.identify(`${userEmail}`);
      Mixpanel.people.set({
        distinct_id: `${userEmail}`,
        $email: userEmail,
        role: userRole,
        isInternal: isInternal,
        isAdmin: isAdmin,
        company: cid,
      });
    }
  }, [userEmail, isInternal, isAdmin, userRole, cid]);

  return (
    <ExperimentContext.Provider value={experiments}>
      {/* TODO: we need to make a wrapper for "NonCompanyRoutes" and give it its own navbar that has no logo etc. For a company it needs some metadata */}
      <NavBar location={location} />
      <div className="main">
        <Router className="bpmRouter">
          <Companies key="companies root" path="/" />
          <Companies key="companies" path="/companies" />
          <ProtectedRoute
            key="dag-status"
            path="/dag-status"
            component={DagStatus}
            roleRequired={3}
          />
          <Redirect noThrow from="/dag_status" to="/dag-status" />
          <ProtectedRoute key="kpi-status" path="/kpi-status" component={KPIStatus} />
          <Redirect noThrow from="/kpi_status" to="/kpi-status" />
          <ProtectedRoute key="reports" path="/reports" component={InternalReports} />
          <ProtectedRoute
            key="site-monitoring"
            path="/site-monitoring"
            component={SiteMonitoring}
            roleRequired={2}
          />
          <Redirect noThrow from="/sitemonitoring" to="/site-monitoring" />
          <Redirect noThrow from="/site_monitoring" to="/site-monitoring" />
          <ProtectedRoute
            key="batch-failures"
            path="/batch-failures"
            component={BatchFailures}
            roleRequired={3}
          />
          <Redirect noThrow from="/batchfailures" to="/batch-failures" />
          <Redirect noThrow from="/batch_failures" to="/batch-failures" />
          <ProtectedRoute
            key="incrementality"
            path="/incrementality-job-status"
            component={IncrementalityJobStatus}
          />
          <Redirect noThrow from="/incrementality_job_status" to="/incrementality-job-status" />
          <ProtectedRoute
            key="dag-graphs"
            path="/dag-graphs"
            component={DagGraphs}
            roleRequired={3}
          />
          <Redirect noThrow from="/dag_graphs" to="/dag-graphs" />
          <ProtectedRoute key="calendar" path="/calendar" component={Calendar} />
          <ProtectedRoute suspense key="process" path="process" component={ProcessLogs} />
          <ProtectedRoute
            key="streamingnetworks"
            path="/streaming/networks/*"
            component={StreamingNetworks}
          />
          <ProtectedRoute
            key="streaming monitoring"
            path="/streaming/monitoring"
            component={StreamingMonitoring}
          />
          <ProtectedRoute key="activeott" path="/activeott/*" component={ActiveOTT} />
          <ProtectedRoute
            key="linearbuyingassignments"
            path="/linear/assignments"
            component={LinearBuyingAssignments}
          />
          <ProtectedRoute
            key="accountplanning"
            path="/account-planning"
            component={AccountPlanning}
          />
          <Redirect noThrow from="/accountplanning" to="/account-planning" />
          <Redirect noThrow from="/account_planning" to="/account-planning" />
          <ProtectedRoute
            key="companyspecificfiltering"
            path="/company-specific-filtering"
            component={CompanySpecificFiltering}
          />
          <Redirect noThrow from="/companyspecificfiltering" to="/company-specific-filtering" />
          <Redirect noThrow from="/company_specific_filtering" to="/company-specific-filtering" />
          <ProtectedRoute
            key="salesandtrafficcontacts"
            path="/linear/contacts"
            component={SalesAndTrafficContacts}
          />
          <ProtectedRoute
            key="oldexpectedbookings"
            path="/bookings"
            component={OldExpectedBookings}
          />
          <ProtectedRoute key="devicebreakout" path="/device-breakout" component={DeviceBreakout} />
          <Redirect noThrow from="/devicebreakout" to="/device-breakout" />
          <ProtectedRoute
            key="linearconstraints"
            path="/linear/constraints"
            component={LinearConstraints}
          />
          <ProtectedRoute
            key="streamingconstraints"
            path="/streaming/constraints"
            component={StreamingConstraints}
          />
          <ProtectedRoute key="admin" path="/admin" component={Admin} />
          <ProtectedRoute key="useradmin" path="/user-admin" component={UserAdmin} />
          <Redirect noThrow from="/useradmin" to="/user-admin" />
          <Redirect noThrow from="/user_admin" to="/user-admin" />
          <ProtectedRoute key="linearcpms" path="/linear/cpms" component={LinearCpms} />
          <ProtectedRoute
            key="linearclearanceassumptions"
            path="/linear/assumptions"
            component={LinearClearanceAssumptions}
          />
          <ProtectedRoute key="commissions" path="/commissions" component={Commissions} />
          {experiments.enableMasterTargeting && (
            <ProtectedRoute
              key="mastertargeting"
              path="/streaming/master-targeting"
              component={MasterTargeting}
            />
          )}
          <Redirect noThrow from="/streaming/mastertargeting" to="/streaming/master-targeting" />
          <Redirect noThrow from="/streaming/master_targeting" to="/streaming/master-targeting" />
          <ProtectedRoute key="linearrates" path="/linear/rates" component={LinearRates} />
          <ProtectedRoute
            key="linearcanonicalnetworks"
            path="/linear/networks"
            component={LinearCanonicalNetworks}
          />
          <ProtectedRoute
            key="out of home buys"
            path="/out-of-home-buys"
            component={OutOfHomeBuys}
          />
          <Redirect noThrow from="/outofhomebuys" to="/out-of-home-buys" />
          <Redirect noThrow from="/out_of_home_buys" to="/out-of-home-buys" />
          <ProtectedRoute
            key="dag start"
            path="/dag-start"
            component={DagStart}
            roleRequired={[3]}
          />
          <Redirect noThrow from="/dagstart" to="/dag-start" />
          <Redirect noThrow from="/dag_start" to="/dag-start" />
          <ProtectedRoute
            key="linearratescomparisons"
            path="/linear/rates/comparisons"
            component={LinearRatesComparisons}
          />
          <ProtectedRoute suspense key="invoices2" path="/invoicing" component={Invoicing} />
          <ProtectedRoute
            key="pacing streaming"
            path="/pacing/streaming/*"
            component={StreamingPacing}
            platform={"Streaming"}
          />
          <ProtectedRoute
            key="pacing display"
            path="/pacing/display/"
            component={StreamingPacing}
            platform={"Display"}
          />
          <ProtectedRoute
            key="streaming discrepancies"
            path="/pacing/discrepancies"
            component={StreamingDiscrepancies}
          />
          <ProtectedRoute key="pacing linear" path="/pacing/linear" component={LinearPacing} />
          <ProtectedRoute key="logs tracker" path="/logs/*" component={LogsTracker} />
          <ProtectedRoute
            key="linear adops"
            path="/adops/linear"
            component={AdOps}
            platform="Linear"
          />
          <ProtectedRoute
            key="streaming adops"
            path="/adops/streaming"
            component={AdOps}
            platform="Streaming"
          />
          <ProtectedRoute key="ebay redirect" path="/ebay" component={EbayRedirct} />
          <ProtectedRoute key="credit memos" path="/credit-memos" component={CreditMemos} />
          <Redirect noThrow from="/creditmemos" to="/credit-memos" />
          <Redirect noThrow from="/credit_memos" to="/credit-memos" />
          <ProtectedRoute
            key="add new client"
            path="/add-new-client"
            component={AddNewClient}
            existingClient={false}
          />
          <Redirect noThrow from="/addnewclient" to="/add-new-client" />
          <Redirect noThrow from="/add_new_client" to="/add-new-client" />
          <ProtectedRoute key="kpi setup" path="/kpi-setup" component={KpiSetup} />
          <Redirect noThrow from="/kpisetup" to="/kpi-setup" />
          <Redirect noThrow from="/kpi_setup" to="/kpi-setup" />
          {experiments.enableMediaUpdateGenerator && (
            <ProtectedRoute
              key="media update generator"
              path="/media-update-generator"
              component={MediaUpdateGenerator}
            />
          )}
          <Redirect noThrow from="/mediaupdategenerator" to="/media-update-generator" />
          <Redirect noThrow from="/media_update_generator" to="/media-update-generator" />
          {experiments.enableStreamingPlanBuilder && (
            <ProtectedRoute
              key="streamingplanbuilder"
              path="/streaming/plan-builder"
              component={StreamingPlansBuilder}
            />
          )}
          <Redirect noThrow from="/streaming/planbuilder" to="/streaming/plan-builder" />
          <ProtectedRoute
            component={ReferenceView}
            key="reference"
            path="/reference"
            roleRequired={10} // External Users
          />
          <ProtectedRoute
            key="query exporter"
            path="/query-exporter"
            component={QueryExporter}
            roleRequired={2}
          />

          <ProtectedRoute
            key="AI-Powered Tagging"
            path="/gemini-tagging"
            component={ABCD}
            roleRequired={10}
          />
          <Redirect noThrow from="/queryexporter" to="/query-exporter" />
          <Redirect noThrow from="/query_exporter" to="/query-exporter" />
          <ProtectedRoute
            key="data connector tool"
            path="/data-connectors"
            component={DataConnectorTool}
            roleRequired={3}
          />
          <Redirect noThrow from="/dataconnectors" to="/data-connectors" />
          <Redirect noThrow from="/data_connectors" to="/data-connectors" />
          <ProtectedRoute
            key="Incrementality Playbook"
            path="/incrementality-playbook/*page"
            component={IncrementalityPlaybook}
          />
          <ProtectedRoute
            key="Cross Channel Demo Data Generator"
            path="/demo-data-generator"
            component={DemoDataGenerator}
          />
          <ProtectedRoute key="croonboard" path="/cro-onboard" component={CroOnboard} />
          <Redirect noThrow from="/croonboard" to="../cro-onboard" />
          <BasicFaq key="mmm faq" path="/mmm/faq" />
          <Redirect noThrow key="pacing root" from="/pacing" to="/pacing/streaming" />
          <Redirect noThrow from="/companies/:company/*" to="/:company" />
          <Redirect noThrow from="/login" to="/" />
          <CompanyRoutes path="/:company/*" />
        </Router>
      </div>
    </ExperimentContext.Provider>
  );
};

const App = () => {
  UserRedux.useVersionCheck();
  const isAuthed = UserRedux.useIsAuthed();
  // State to store isAuthedCount value
  let content;
  // This seems to be what prevents unauthorized users from seeing anything even if they somehow login to the app
  if (R.isNil(isAuthed)) {
    content = <Page onlyModals={true}></Page>;
  } else if (!R.isNil(Cookies.get("access_token")) && !R.isNil(Cookies.get("login_method"))) {
    content = <LocationWrapper />;
  } else {
    content = <Login />;
  }
  return <div className="bpm">{content}</div>;
};

export default App;
