import React, { useState, useEffect, useRef } from "react";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MdOutlineInsertLink } from "react-icons/md";
import { Button, ButtonType } from "../Button/Button";
import QuestionCardTextAreaInput from "./QuestionCardTextArea";
import QuestionCardRadio from "./QuestionCardRadio";
import QuestionCardUploader from "./QuestionCardUploader";
import QuestionCardCheckbox from "./QuestionCardCheckbox";
import "./QuestionCard.scss";

export interface NestedOptionConfig {
  inputType: "textarea" | "checkbox";
  options?: string[];
  label?: string;
  placeholder?: string;
}

export interface NestedValues {
  [parentLabel: string]: { [nestedLabel: string]: string | boolean };
}

export interface QuestionCardProps {
  id: string;
  questionNumber: string;
  title: string;
  subtitle?: string;
  url?: string;
  children?: React.ReactNode;
  type?: "radio" | "checkbox" | "textarea" | "upload" | "custom";
  textLabel?: string;
  options?: string[];
  value?: any;
  textValue?: string;
  nestedValues?: NestedValues;
  placeholder?: string;
  renderAdditionalInfo?: boolean;
  readOnly?: boolean;
  nestedOptionsConfig?: { [option: string]: NestedOptionConfig[] };
  onChange?: (value: any) => void;
  className?: string;
  s3Bucket?: string;
  s3Path?: string;
}

const QuestionCard = ({
  id,
  questionNumber,
  title,
  subtitle,
  url = window.location.href.split("#")[0],
  type,
  textLabel,
  options,
  value,
  textValue,
  nestedValues = {},
  placeholder,
  renderAdditionalInfo = true,
  readOnly = false,
  nestedOptionsConfig = {},
  onChange,
  className,
  children,
  s3Bucket,
  s3Path,
}: QuestionCardProps): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const [parent, setParent] = useState<string | string[]>(value || "");
  const [nested, setNested] = useState<NestedValues>(nestedValues || {});
  const [text, setText] = useState<string>(textValue || "");
  const questionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === `#${questionNumber}`) {
        setFocused(true);
        questionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        setFocused(false);
      }
    };

    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [questionNumber]);

  const handleTextAreaChange = (updatedTextValue: string) => {
    setText(updatedTextValue);
    onChange?.({
      parentValue: parent,
      nestedValue: nested,
      textAreaValue: updatedTextValue,
    });
  };

  const renderTextarea = () => (
    <QuestionCardTextAreaInput
      questionNumber={questionNumber}
      value={text || ""}
      onChange={handleTextAreaChange}
      placeholder={placeholder}
      readOnly={readOnly}
      label={textLabel}
      isAdditionalInfo={renderAdditionalInfo}
    />
  );

  const renderFormElement = () => {
    switch (type) {
      case "radio":
        return (
          <QuestionCardRadio
            questionNumber={questionNumber}
            options={options || []}
            nestedOptionsConfig={nestedOptionsConfig}
            value={value}
            nestedValues={nestedValues}
            readOnly={readOnly}
            onChange={({ parentValue, nestedValues }) => {
              setParent(parentValue);
              setNested(nestedValues);
              onChange?.({ parentValue, nestedValue: nestedValues, textAreaValue: text });
            }}
          />
        );
      case "checkbox":
        return (
          <QuestionCardCheckbox
            questionNumber={questionNumber}
            options={options || []}
            nestedOptionsConfig={nestedOptionsConfig}
            values={Array.isArray(parent) ? parent : [parent]}
            nestedValues={nested}
            readOnly={readOnly}
            onChange={({ parentValues, nestedValues }) => {
              setParent(parentValues);
              setNested(nestedValues);
              onChange?.({
                parentValue: parentValues,
                nestedValue: nestedValues,
                textAreaValue: text,
              });
            }}
          />
        );
      case "textarea":
        return renderTextarea();
      case "upload":
        return (
          <QuestionCardUploader
            questionNumber={questionNumber}
            value={value || []}
            onChange={value => {
              setParent(value);
              onChange?.({ parentValue: value, nestedValue: nested, textAreaValue: text });
            }}
            s3Bucket={s3Bucket || ""}
            s3Path={s3Path || ""}
            readOnly={readOnly}
          />
        );
      case "custom":
        return <Form.Group>{children}</Form.Group>;
      default:
        return null;
    }
  };

  return (
    <div
      id={questionNumber}
      className={`questionCard ${focused ? "focused" : ""} ${className || ""}`}
      ref={questionRef}
    >
      <div className="questionHeader">
        <p className="questionNumber">{questionNumber}</p>
        <OverlayTrigger overlay={<Tooltip id={`${questionNumber}-copyButton`}>Copy</Tooltip>}>
          <Button
            id={`${questionNumber}-copyButton`}
            type={ButtonType.EMPTY}
            className="onboardingButton"
            onClick={() => navigator.clipboard.writeText(`${url}#${questionNumber}`)}
            icon={<MdOutlineInsertLink />}
          />
        </OverlayTrigger>
      </div>
      <div className="cardContent">
        <h2 className="cardTitle">{title}</h2>
        {subtitle && <p className="cardSubtitle">{subtitle}</p>}
        {renderFormElement()}
        {renderAdditionalInfo && renderTextarea()}
      </div>
    </div>
  );
};

export default QuestionCard;
