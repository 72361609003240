import { useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import { DropdownMenu, DropdownMenuProps } from "../DropdownMenu";
import { DropdownOption, MenuItem } from "../Dropdown";
import { Setter, typedReactMemo } from "../../utils/types";
import "./Input.scss";

interface InputProps<T extends string>
  extends Omit<
    DropdownMenuProps<T>,
    "className" | "itemOnClick" | "menuItems" | "size" | "storybook"
  > {
  className?: string;
  searchable?: boolean;
  searchOptions?: readonly DropdownOption<T>[];
  onInputChange?: Setter<T>;
  onDropdownChange?: (item: MenuItem<T>) => void;
  optionFetcher?: (input: string) => Promise<MenuItem<T>[]>;
}

export const Input = typedReactMemo(
  <T extends string>({
    className: providedClassName,
    searchable,
    initialSearchOptions,
    menuItems,
    onInputChange,
    onDropdownChange,
    optionFetcher,
    ...passthrough
  }: InputProps<T>): JSX.Element => {
    const className = useMemo(() => {
      let classes = ["cl-input"];

      if (providedClassName) {
        classes.push(providedClassName);
      }
      return classes.join(" ");
    }, [providedClassName]);

    const [searchOptions, setSearchOptions] = useState<DropdownOption<T>[]>(initialSearchOptions);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [dropdownLoading, setDropdownLoading] = useState<boolean>(false);

    const debouncedOptionFetcher = useMemo(() => {
      const debounced = debounce(async (inputString, resolve, reject) => {
        if (optionFetcher) {
          const options = await optionFetcher(inputString).then(resolve).catch(reject);
          return options;
        }
        return [];
      }, 300);

      return inputString =>
        new Promise((resolve, reject) => {
          debounced(inputString, resolve, reject);
        });
    }, [optionFetcher]);

    // Only called when searchable = true.  Otherwise treat like a standard input box.
    const getSearchOptions = useCallback(
      async e => {
        const inputString = e.target.value;

        if (onInputChange) {
          onInputChange(inputString);
        }

        let newOptions;

        if (inputString === "") {
          newOptions = initialSearchOptions;
        } else if (optionFetcher) {
          // If options should be fetched via an API call, make that call to get the new options.
          setDropdownLoading(true);
          setShowDropdown(true);
          newOptions = await debouncedOptionFetcher(inputString);
        } else {
          // If not, just use the query as a filter on the searchOptions.
          const lcFilterTerm = inputString.toLowerCase();
          newOptions = initialSearchOptions?.filter(option =>
            typeof option === "string"
              ? option.toLowerCase().includes(lcFilterTerm)
              : option.value.toLowerCase().includes(lcFilterTerm) ||
                (option.label ? option.label.toLowerCase().includes(lcFilterTerm) : true)
          );
        }

        setSearchOptions(newOptions);
        setDropdownLoading(false);

        // Open or close dropdown depending on whether there are searchOptions.
        if (newOptions?.length) {
          setShowDropdown(true);
        } else {
          setShowDropdown(false);
        }
      },
      [debouncedOptionFetcher, initialSearchOptions, onInputChange, optionFetcher]
    );

    const items = useMemo(
      () =>
        searchOptions?.map(option => {
          if (typeof option === "string") {
            return { label: option, value: option };
          } else {
            return { ...option, label: option.label || option.value };
          }
        }),
      [searchOptions]
    );

    return (
      <div className="bpmInputContainer">
        <input
          className={className}
          onChange={
            searchable ? getSearchOptions : passthrough.onChange ? passthrough.onChange : undefined
          }
          onFocus={() => {
            if (searchOptions?.length || (!searchOptions?.length && initialSearchOptions?.length)) {
              setShowDropdown(true);
            }
          }}
          {...passthrough}
        />
        {searchable && showDropdown && (
          <DropdownMenu
            className="searchableInputDropdown"
            menuItems={menuItems ? menuItems : items}
            itemOnClick={e => {
              if (onDropdownChange) {
                onDropdownChange(e as MenuItem<T>);
              }
              if (onInputChange) {
                onInputChange(e.label as T);
              }
              setShowDropdown(false);
            }}
            standalone
            loading={dropdownLoading}
          />
        )}
      </div>
    );
  }
);
