import "./BinsLegend.scss";
import React from "react";

import { Brand20, Brand25, Brand40, Brand60, Brand80, DivergentColorMap5 } from "../utils/colors";
import { formatNumber } from "../utils/format-utils";

interface BinsLegendsProps {
  bins: number[];
  actual: boolean;
}

const actualSquareColors = [Brand20, Brand25, Brand40, Brand60, Brand80];
const adjustedSquareColors = DivergentColorMap5;

export const BinsLegends: React.FC<BinsLegendsProps> = ({ bins, actual }) => {
  return (
    <div
      className="legendMap"
      style={bins.length > 0 ? { border: "1px solid #cbd2e1" } : { border: "none" }}
    >
      <div className="legendTitle">Legend:</div>
      {bins.map((item, i) => {
        if (i === 0) {
          return (
            <div className="legendItem" key={i}>
              <div
                className="legendShape"
                style={{
                  backgroundColor: actual ? actualSquareColors[i] : adjustedSquareColors[i],
                }}
              ></div>
              <div className="legendText">{`0-${
                actual ? formatNumber(item, 0) : formatNumber(item, 2)
              }`}</div>
            </div>
          );
        } else if (i === bins.length - 1) {
          return (
            <div className="legendItem" key={i}>
              <div
                className="legendShape"
                style={{
                  backgroundColor: actual ? actualSquareColors[i] : adjustedSquareColors[i],
                }}
              ></div>
              <div className="legendText">{`${
                actual ? formatNumber(item, 0) : formatNumber(item, 2)
              }+`}</div>
            </div>
          );
        } else {
          return (
            <div className="legendItem" key={i}>
              <div
                className="legendShape"
                style={{
                  backgroundColor: actual ? actualSquareColors[i] : adjustedSquareColors[i],
                }}
              ></div>
              <div className="legendText">
                {`${actual ? formatNumber(bins[i - 1], 0) : formatNumber(bins[i - 1], 2)}-${
                  actual ? formatNumber(bins[i], 0) : formatNumber(bins[i], 2)
                }`}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default BinsLegends;
