import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";

import { awaitJSON, StreamingPerformanceLambdaFetch } from "../utils/fetch-utils";

import {
  Page,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  BPMButton,
  Spinner,
  SelectorOption,
} from "../Components";
import "./CompanySpecificFiltering.scss";

import { CompanySpecificFilterRow } from "@blisspointmedia/bpm-types/dist/pgTables/CompanySpecificFilters";
import {
  GetCompanySpecificFilterRowsResponse,
  UpdateCompanySpecificFilterRowParams,
} from "@blisspointmedia/bpm-types/dist/CompanySpecificFilters";
import { MdFilterList, MdSave } from "react-icons/md";
import UpdatedCompanySpecificFilterCard from "./UpdatedCompanySpecificFilterCard";

const WIDTHS = {
  CID: 200,
  BLOCKED_NETWORKS: 750,
  BLOCKED_ISCIS: 500,
  UNVIEWABLE: 0,
};
const MODAL_ROWS = {
  CID: 0,
  BLOCKED_NETWORKS: 1,
  BLOCKED_ISCIS: 2,
  LAST_MODIFIED: 3,
  LAST_USER: 3,
};

const DEFAULT_NEW_ROW = {
  id: (null as unknown) as number,
  cid: "",
  blocked_networks: [],
  blocked_iscis: [],
  lastuser: "",
  lastmodified: "",
};

const CompanySpecificFiltering: React.FC = () => {
  const [tableData, setTableData] = useState<CompanySpecificFilterRow[]>();
  const [updatedRows, setUpdatedRows] = useState<CompanySpecificFilterRow[]>([]);
  const [newRows, setNewRows] = useState<CompanySpecificFilterRow[]>([]);
  const [deletedRows, setDeletedRows] = useState<CompanySpecificFilterRow[]>([]);
  const [originalTableData, setOriginalTableData] = useState<
    Record<string, CompanySpecificFilterRow>
  >();
  const [saving, setSaving] = useState(false);

  const [pageData, setPageData] = useState<GetCompanySpecificFilterRowsResponse>();
  const [networks, setNetworks] = useState<string[]>([]);
  const [iscis, setIscis] = useState<string[]>([]);
  const [cids, setCids] = useState<string[]>([]);

  const [invalidText, setInvalidText] = useState<string>();
  const [showPendingChanges, setShowPendingChanges] = useState(false);
  const [showTableData, setShowTableData] = useState(false);
  const setError = useSetError();

  const getFreshPageData = useCallback(async () => {
    try {
      setShowTableData(false);
      setNewRows([]);
      setDeletedRows([]);
      setUpdatedRows([]);
      let res = await StreamingPerformanceLambdaFetch(
        "/getStreamingPerformanceCompanySpecificFilteringData"
      );
      let jsonResponse = await awaitJSON<GetCompanySpecificFilterRowsResponse>(res);
      setPageData(jsonResponse);
      setTableData(jsonResponse.tableData);
      setNetworks(jsonResponse.networks);
      setIscis(jsonResponse.iscis);
      setCids(jsonResponse.cids);

      let originalTableData: Record<string, CompanySpecificFilterRow> = {};
      for (let row of jsonResponse.tableData) {
        originalTableData[row.id] = row;
      }
      setOriginalTableData(originalTableData);
      setShowTableData(true);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to get Company Specific Filtering Data. ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [setError]);

  useEffect(() => {
    if (!tableData) {
      (async () => {
        await getFreshPageData();
      })();
    }
  }, [setError, getFreshPageData, tableData]);

  useEffect(() => {
    if (tableData && originalTableData) {
      let isUpdated = (row: CompanySpecificFilterRow) => {
        if (originalTableData[row.id]) {
          let originalRow = originalTableData[row.id];
          let arrayEquals = (a, b) => {
            let setA = new Set(a);
            let setB = new Set(b);
            let setEquals = (x, y) => x.size === y.size && [...x].every(value => y.has(value));
            return setEquals(setA, setB);
          };
          return (
            (row.id !== null && row.cid !== originalRow.cid) ||
            !arrayEquals(row.blocked_networks, originalRow.blocked_networks) ||
            !arrayEquals(row.blocked_iscis, originalRow.blocked_iscis)
          );
        }
        return false;
      };
      let updatedRows = R.filter(isUpdated, tableData);

      setUpdatedRows(updatedRows);

      let insertedRows = R.filter(row => R.isNil(row.id), tableData);
      setNewRows(insertedRows);
    }
  }, [originalTableData, tableData]);

  const hasPendingChanges: boolean = useMemo(() => {
    const isChanged = !(R.isEmpty(updatedRows) && R.isEmpty(newRows) && R.isEmpty(deletedRows));

    if (!isChanged) {
      // if there are no pending changes force the pending pane to
      // close
      setShowPendingChanges(false);
    }

    return isChanged;
  }, [deletedRows, newRows, updatedRows]);

  const selectorOptions: Record<string, SelectorOption[]> = useMemo(
    () => ({
      cids: pageData && cids ? R.map(e => ({ label: e, value: e }), cids) : [],
    }),
    [cids, pageData]
  );

  const save = useCallback(async () => {
    setSaving(true);
    const internalBody: UpdateCompanySpecificFilterRowParams = {
      insert: newRows || [],
      update: updatedRows || [],
      delete: deletedRows || [],
    };
    try {
      await StreamingPerformanceLambdaFetch<UpdateCompanySpecificFilterRowParams>(
        "/setStreamingPerformanceCompanySpecificFilteringData",
        {
          method: "POST",
          body: internalBody,
        }
      );

      await getFreshPageData();
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to set Company Specific Filtering Data. \n ERROR: ${reportError.message}`,
        reportError,
      });
    }
    setSaving(false);
  }, [newRows, updatedRows, deletedRows, getFreshPageData, setError]);

  const checkIsValid = (data: CompanySpecificFilterRow) => {
    const { cid } = data;
    if (R.isNil(cid) || !cid.length) {
      setInvalidText("Company field cannot be empty");
      return false;
    }
    for (const row of tableData || []) {
      if (row.id !== data.id && row.cid === cid) {
        setInvalidText(`Company ${cid} already exists!`);
        return false;
      }
    }

    return true;
  };

  const isValidNewNetwork = network => networks.includes(network);
  const isValidNewISCI = network => iscis.includes(network);

  const contactsHeader = [
    {
      label: "Company",
      field: "cid",
      type: "select",
      options: "cids",
      width: WIDTHS.CID,
      modalRow: MODAL_ROWS.CID,
      modalFlex: 1,
    },
    {
      label: "Blocked Networks",
      field: "blocked_networks",
      type: "select",
      isValidNewOption: isValidNewNetwork,
      isMulti: true,
      width: WIDTHS.BLOCKED_NETWORKS,
      modalRow: MODAL_ROWS.BLOCKED_NETWORKS,
      modalFlex: 1,
    },
    {
      label: "Blocked ISCIs",
      field: "blocked_iscis",
      type: "select",
      isValidNewOption: isValidNewISCI,
      isMulti: true,
      width: WIDTHS.BLOCKED_ISCIS,
      modalRow: MODAL_ROWS.BLOCKED_ISCIS,
      modalFlex: 1,
    },
    {
      label: "Last Modified",
      field: "lastmodified",
      type: "text",
      width: WIDTHS.UNVIEWABLE,
      modalRow: MODAL_ROWS.LAST_MODIFIED,
      modalFlex: 1,
      readOnly: true,
      nonInteractive: true,
    },
    {
      label: "Last User",
      field: "lastuser",
      type: "text",
      width: WIDTHS.UNVIEWABLE,
      modalRow: MODAL_ROWS.LAST_USER,
      modalFlex: 1,
      readOnly: true,
      nonInteractive: true,
    },
  ];

  return (
    <Page
      title="Streaming Performance Company Specific Filtering"
      pageType="Streaming Performance Company Specific Filtering"
      minHeight="600px"
      actions={
        <div className="SPCSFActions">
          <div className="actionButtons">
            {hasPendingChanges && (
              <>
                <BPMButton
                  size="sm"
                  variant="outline-primary"
                  icon={<MdFilterList />}
                  onClick={() => {
                    setShowPendingChanges(!showPendingChanges);
                  }}
                >
                  Pending Changes
                </BPMButton>
              </>
            )}
            <BPMButton
              size="sm"
              variant="primary"
              onClick={save}
              icon={saving ? <Spinner color="white" /> : <MdSave />}
              disabled={!hasPendingChanges}
            >
              Save
            </BPMButton>
          </div>
        </div>
      }
    >
      <div className="SPCSFPageContainer">
        {" "}
        {showTableData && tableData ? (
          <ModalEditTable
            className="companySpecificFiltersTable"
            name="Companies"
            headers={contactsHeader}
            tableData={tableData}
            setTableData={newTableData => setTableData(newTableData)}
            selectorOptions={selectorOptions}
            filterBar
            enableDelete={true}
            enableAdd={true}
            deletedRows={deletedRows}
            /// @ts-ignore - Can delete when ModalEditTable is TypeScripted
            setDeletedRows={setDeletedRows}
            /// @ts-ignore
            checkIsValid={checkIsValid}
            invalidText={invalidText}
            rowHeight={45}
            showModalOnAdd={true}
            defaultNewRow={DEFAULT_NEW_ROW}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && (
          <div className="pendingChangesContainer">
            {Boolean(hasPendingChanges) && originalTableData && (
              <div className="changesList">
                {!R.isEmpty(newRows) && (
                  <>
                    <h2>New Filters</h2>
                    {newRows.map(newRow => {
                      return (
                        <UpdatedCompanySpecificFilterCard
                          newRow={newRow}
                          oldRow={DEFAULT_NEW_ROW}
                        />
                      );
                    })}
                  </>
                )}
                {!R.isEmpty(updatedRows) && (
                  <>
                    <h2>Updated Filters:</h2>
                    {updatedRows.map(updatedRow => {
                      return (
                        <UpdatedCompanySpecificFilterCard
                          newRow={updatedRow}
                          oldRow={originalTableData[updatedRow.id]}
                        />
                      );
                    })}
                  </>
                )}
                {!R.isEmpty(deletedRows) && (
                  <>
                    <h2>Deleted Filters:</h2>
                    {deletedRows.map(deletedRow => {
                      return (
                        <UpdatedCompanySpecificFilterCard
                          newRow={deletedRow}
                          oldRow={originalTableData[deletedRow.id]}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export default CompanySpecificFiltering;
