import "./FunnelDynamics.scss";
import { BPMButton, KpiPicker, RelativeDatePicker, BPMToggleButton } from "../Components";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import { dateSort } from "../Slides/slideUtils";
import { Form } from "react-bootstrap";
import { FunnelChartData, FunnelDynamicsChart } from "../Components/FunnelDynamicsChart";
import { GOOGLE_SLIDE_IMAGE_SCALING_FACTOR } from "./slideFormatConstants";
import { KpiInfo } from "@blisspointmedia/bpm-types/dist/Kpis";
import { Provider } from "react-redux";
import { reduxStore } from "../redux";
import { S3SignedUrlFetch, SlidesLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { useCompanyInfo } from "../redux/company";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

const BAR_CHART_HEIGHT = 136.2 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR;
const BAR_CHART_WIDTH = 623.5 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR;
const DEFAULT_COLOR = "#000000";
const includeRevenueChartTypes = ["Include", "Exclude"] as const;
const LINE_CHART_HEIGHT = 106.4 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR;
const LINE_CHART_WIDTH = 567.4 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR;

interface DailyResponseData {
  Timeslot: string;
  responses: number;
}

export const getS3Data = async (kpi: string): Promise<string | null> => {
  if (!kpi) {
    return null;
  }
  const res = await S3SignedUrlFetch(`bpm-ml-data/v4/${kpi}/latest/microattribution_revenue_000`);
  try {
    const data = await res.text();
    return data;
  } catch (e) {
    let error = e as Error;
    if ((R.prop("message", error) || "").includes("NoSuchKey")) {
      throw new Error(`No revenue data found for kpi: ${kpi}`);
    }
    throw e;
  }
};

export const getWeeklyResponses = async (
  kpi: string,
  start: string,
  end: string
): Promise<DailyResponseData[]> => {
  const responseMap: Record<string, DailyResponseData> = {};
  const lambdaRes = await SlidesLambdaFetch("/getDailyResponses", {
    params: {
      kpi,
      start,
      end,
    },
  });
  const res = await awaitJSON(lambdaRes);
  let weekCount = 0;
  let currentDate = new Date(
    R.pipe(Dfns.startOfISOWeek, Dfns.subWeeks(weekCount), Dfns.format(DATE_FORMAT))(new Date(end))
  );
  while (currentDate >= new Date(start)) {
    const dateString = R.pipe(
      Dfns.startOfISOWeek,
      Dfns.subWeeks(weekCount),
      Dfns.format(DATE_FORMAT)
    )(new Date(end));
    currentDate = new Date(dateString);
    if (currentDate >= new Date(start)) {
      responseMap[`${dateString}`] = { Timeslot: dateString, responses: 0 };
    }
    weekCount++;
  }
  for (let day of res) {
    let dateKey = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date(day.Timeslot));
    if (dateKey && responseMap[dateKey]) {
      responseMap[dateKey].responses += parseFloat(day.responses);
    }
  }
  return R.values(responseMap);
};

interface FunnelDynamicsData {
  date: string;
  count: number;
}

export const getFunnelChartData = (
  upperData: DailyResponseData[],
  lowerData: DailyResponseData[]
): FunnelDynamicsData[] => {
  let dataMap: Record<string, FunnelDynamicsData> = {};
  R.map(elem => {
    dataMap[elem.Timeslot] = { date: elem.Timeslot, count: elem.responses };
    return elem;
  }, lowerData);
  R.map(elem => {
    if (dataMap[elem.Timeslot]) {
      dataMap[elem.Timeslot].count /= elem.responses;
    } else {
      dataMap[elem.Timeslot] = { date: elem.Timeslot, count: 0 };
    }
    return elem;
  }, upperData);

  return dateSort(R.values(dataMap));
};

interface SlideFunnelDynamicsChartResult {
  elem: SVGSVGElement | null;
  noData?: boolean;
}

interface SlideFunnelDynamicsChartOnLoad {
  (results: SlideFunnelDynamicsChartResult): void;
}

interface SlideFunnelDynamicsChartProps {
  color: string;
  funnelChartData: FunnelDynamicsData[] | FunnelChartData[];
  type: string;
  onLoad: SlideFunnelDynamicsChartOnLoad;
}

const SlideFunnelDynamicsChart: React.FC<SlideFunnelDynamicsChartProps> = ({
  color,
  onLoad,
  type,
  funnelChartData,
}) => {
  const [clear, setClear] = useState<boolean>(true);
  const [pathLoaded, setPathLoaded] = useState(false);
  const height = type === "kpiLine" ? LINE_CHART_HEIGHT : BAR_CHART_HEIGHT;
  const mapSvgRef = useRef<SVGSVGElement | null>(null);
  const width = type === "kpiLine" ? LINE_CHART_WIDTH : BAR_CHART_WIDTH;

  useLayoutEffect(() => {
    if (clear) {
      mapSvgRef.current = null;
      setClear(false);
    } else if (funnelChartData !== null && pathLoaded) {
      onLoad({
        elem: clear ? null : mapSvgRef.current,
      });
      setClear(true);
    }
  }, [clear, funnelChartData, onLoad, pathLoaded]);

  return (
    <FunnelDynamicsChart
      chartData={clear ? [] : funnelChartData.slice(0, -1)}
      chartType={type}
      color={color}
      height={height}
      onPathLoad={setPathLoaded}
      ref={mapSvgRef}
      width={width}
    />
  );
};

interface FunnelDynamicsSlideData {
  bottomChartURL: string;
  bottomLabelKPI: string;
  companyColor: string;
  end: string;
  middleChartURL: string;
  middleLabelKPI: string;
  start: string;
  topChartURL: string;
  topLabelKPI: string;
}

export interface FunnelDynamicsSlideState extends FunnelDynamicsSlideData {
  dates: RelativeDateRange;
  funnelBottomLabel: string;
  funnelMiddleLabel: string;
  funnelTopLabel: string;
  includeRevenueChart: boolean;
  revenueKPI: string;
}

const getKPIName = (kpi: string, kpis: KpiInfo[]): string => {
  for (let kpiInfo of kpis) {
    if (kpi === kpiInfo.id) {
      return kpiInfo.name;
    }
  }
  return "";
};

class FunnelDynamicsSlide extends SlideType {
  static typeKey = "funnelDynamics";
  static displayKey = "Funnel Dynamics";
  static defaultState: FunnelDynamicsSlideState = {
    bottomChartURL: "",
    bottomLabelKPI: "",
    companyColor: DEFAULT_COLOR,
    dates: {
      start: { pivotDate: "monday", adjustment: 28, adjustmentType: "day" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
    end: "",
    funnelBottomLabel: "",
    funnelMiddleLabel: "",
    funnelTopLabel: "",
    includeRevenueChart: false,
    middleChartURL: "",
    middleLabelKPI: "",
    revenueKPI: "",
    start: "",
    topChartURL: "",
    topLabelKPI: "",
  };

  static SettingsComponent: React.FC<SettingsComponentProps<FunnelDynamicsSlideState>> = React.memo(
    ({ state, setState }) => {
      const {
        bottomLabelKPI,
        dates,
        funnelBottomLabel,
        funnelMiddleLabel,
        funnelTopLabel,
        includeRevenueChart,
        middleLabelKPI,
        revenueKPI,
        topLabelKPI,
      } = state;
      const { color, initial_kpi, kpis } = useCompanyInfo();

      useEffect(() => {
        setState({ companyColor: color });
      }, [color, setState]);

      return (
        <div className="settingsBox">
          <div className="wrappingColumn">
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <RelativeDatePicker
                state={dates.start}
                onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
              />
              <Form.Label>End Date</Form.Label>
              <RelativeDatePicker
                state={dates.end}
                onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
              />
            </Form.Group>
            <div>
              Include Average Weekly Revenue Chart
              <BPMToggleButton
                size="sm"
                block
                bordered
                options={includeRevenueChartTypes}
                selectedOption={
                  includeRevenueChart ? includeRevenueChartTypes[0] : includeRevenueChartTypes[1]
                }
                onChange={includeRevenueChartType =>
                  setState({
                    includeRevenueChart: includeRevenueChartType === "Include",
                    revenueKPI: initial_kpi,
                  })
                }
              />
              <div className="kpiFunnelPicker" hidden={!includeRevenueChart}>
                <KpiPicker
                  kpi={revenueKPI}
                  onChange={kpi => setState({ revenueKPI: kpi })}
                  filter={kpi => kpi.includes("filtered")}
                />
              </div>
            </div>
            <Form.Group>
              <div>Current Funnel:</div>
              <div hidden={Boolean(topLabelKPI)}>No KPI's selected</div>
              <div
                hidden={!topLabelKPI}
              >{`Top Funnel KPI: ${funnelTopLabel} (${topLabelKPI})`}</div>
              <div
                hidden={!middleLabelKPI}
              >{`Middle Funnel KPI: ${funnelMiddleLabel} (${middleLabelKPI})`}</div>
              <div
                hidden={!bottomLabelKPI}
              >{`Bottom Funnel KPI: ${funnelBottomLabel} (${bottomLabelKPI})`}</div>
            </Form.Group>
            <Form.Group className="KPIPickers">
              <Form.Group>
                <div>Top Funnel KPI Label:</div>
                <Form.Control
                  value={funnelTopLabel}
                  onChange={e => {
                    setState({ funnelTopLabel: e.target.value });
                  }}
                />
                <div>Top Funnel KPI Picker</div>
                <div className="kpiFunnelInput">
                  <div className="kpiFunnelPicker">
                    <KpiPicker
                      kpi={topLabelKPI}
                      onChange={kpi =>
                        setState({ topLabelKPI: kpi, funnelTopLabel: getKPIName(kpi, kpis) })
                      }
                      filter={kpi => kpi.includes("filtered")}
                    />
                  </div>
                  <BPMButton
                    className="kpiFunnelClearButton"
                    onClick={() =>
                      setState({
                        bottomLabelKPI: "",
                        funnelBottomLabel: "",
                        funnelMiddleLabel: funnelBottomLabel,
                        funnelTopLabel: funnelMiddleLabel,
                        middleLabelKPI: bottomLabelKPI,
                        topLabelKPI: middleLabelKPI,
                      })
                    }
                  >
                    Clear Top Funnel KPI
                  </BPMButton>
                </div>
              </Form.Group>
              <Form.Group>
                <div hidden={!topLabelKPI}>
                  <div>Middle Funnel KPI Label:</div>
                  <Form.Control
                    value={funnelMiddleLabel}
                    onChange={e => {
                      setState({ funnelMiddleLabel: e.target.value });
                    }}
                  />

                  <div>Middle Funnel KPI Picker</div>
                  <div className="kpiFunnelInput">
                    <div className="kpiFunnelPicker">
                      <KpiPicker
                        kpi={middleLabelKPI}
                        onChange={kpi =>
                          setState({
                            funnelMiddleLabel: getKPIName(kpi, kpis),
                            middleLabelKPI: kpi,
                          })
                        }
                        filter={kpi => kpi.includes("filtered")}
                      />
                    </div>
                    <BPMButton
                      className="kpiFunnelClearButton"
                      onClick={() =>
                        setState({
                          bottomLabelKPI: "",
                          funnelBottomLabel: "",
                          funnelMiddleLabel: funnelBottomLabel,
                          middleLabelKPI: bottomLabelKPI,
                        })
                      }
                    >
                      Clear Middle Funnel KPI
                    </BPMButton>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div hidden={!middleLabelKPI}>
                  <div>Bottom Funnel KPI Label:</div>
                  <Form.Control
                    value={funnelBottomLabel}
                    onChange={e => {
                      setState({ funnelBottomLabel: e.target.value });
                    }}
                  />
                  <div>Bottom Funnel KPI Picker</div>
                  <div className="kpiFunnelInput">
                    <div className="kpiFunnelPicker">
                      <KpiPicker
                        kpi={bottomLabelKPI}
                        onChange={kpi =>
                          setState({
                            bottomLabelKPI: kpi,
                            funnelBottomLabel: getKPIName(kpi, kpis),
                          })
                        }
                        filter={kpi => kpi.includes("filtered")}
                      />
                    </div>
                    <BPMButton
                      className="kpiFunnelClearButton"
                      onClick={() => setState({ bottomLabelKPI: "", funnelBottomLabel: "" })}
                    >
                      Clear Bottom Funnel KPI
                    </BPMButton>
                  </div>
                </div>
              </Form.Group>
            </Form.Group>
          </div>
        </div>
      );
    }
  );

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<FunnelDynamicsSlideData> => {
    const {
      bottomLabelKPI,
      companyColor,
      dates,
      funnelBottomLabel,
      funnelMiddleLabel,
      funnelTopLabel,
      includeRevenueChart,
      middleLabelKPI,
      revenueKPI,
      topLabelKPI,
    } = state as FunnelDynamicsSlideState;
    if (!topLabelKPI || !middleLabelKPI) {
      throw new Error(
        "You must have at least two kpis selected to generate the Funnel Dynamics Slide!"
      );
    }
    const chartDataMap: Record<string, any> = {
      topMiddleLine: undefined,
      middleBottomLine: undefined,
      revenue: undefined,
    };

    const start = computeResolvedDate(dates.start);
    const end = computeResolvedDate(dates.end);
    let topChartURL = "";
    let middleChartURL = "";
    let bottomChartURL = "";

    const [topKPIData, middleKPIData, bottomKPIData, revenueData] = await Promise.all([
      (async () => {
        try {
          return await getWeeklyResponses(topLabelKPI, start, end);
        } catch (e) {
          const error = e as Error;
          throw new Error(`Failed to fetch info for kpi "${topLabelKPI}". ${error.message}`);
        }
      })(),
      (async () => {
        try {
          return await getWeeklyResponses(middleLabelKPI, start, end);
        } catch (e) {
          const error = e as Error;
          throw new Error(`Failed to fetch info for kpi "${middleLabelKPI}". ${error.message}`);
        }
      })(),
      (async () => {
        if (bottomLabelKPI) {
          try {
            return await getWeeklyResponses(bottomLabelKPI, start, end);
          } catch (e) {
            const error = e as Error;
            throw new Error(`Failed to fetch info for kpi "${bottomLabelKPI}". ${error.message}`);
          }
        } else {
          delete chartDataMap.middleBottomLine;
          return null;
        }
      })(),
      (async () => {
        if (includeRevenueChart) {
          const lambdaRes = await SlidesLambdaFetch("/getAverageWeeklyRevenue", {
            params: {
              kpi: revenueKPI,
              start,
              end,
            },
          });
          const res = await awaitJSON(lambdaRes);
          return dateSort(res);
        } else {
          return null;
        }
      })(),
    ]);

    if (topKPIData && middleKPIData) {
      chartDataMap.topMiddleLine = getFunnelChartData(topKPIData, middleKPIData);
    } else {
      delete chartDataMap.topMiddleLine;
    }
    if (bottomKPIData && middleKPIData) {
      chartDataMap.middleBottomLine = getFunnelChartData(middleKPIData, bottomKPIData);
    } else {
      delete chartDataMap.middleBottomLine;
    }
    if (includeRevenueChart) {
      chartDataMap.revenue = revenueData;
    } else {
      delete chartDataMap.revenue;
    }

    for (const key of R.keys(chartDataMap)) {
      if (R.isNil(chartDataMap[key]) || chartDataMap[key].length < 2) {
        const settings = `${
          key === "topMiddleLine"
            ? `Top KPI: ${topLabelKPI} & Middle KPI: ${middleLabelKPI}`
            : key === "middleBottomLine"
            ? `Middle KPI: ${middleLabelKPI} & Bottom KPI: ${bottomLabelKPI}`
            : `Revenue KPI: ${revenueKPI}`
        } & start: ${start} & end: ${end}}`;

        throw new Error(`There is not enough data to generate the Funnel Dynamics Slide. Please check that the date range has sufficient weeks for graphs! Please check the following:
        ${settings}`);
      }
      const type = key === "revenue" ? key : "kpiLine";
      if (type) {
        let sandbox = await claimSandbox();
        let {
          svgString,
        }: Omit<SlideFunnelDynamicsChartResult, "elem"> & {
          svgString: string;
        } = await new Promise((resolve, reject) => {
          try {
            const load = () => {
              ReactDOM.render(
                <Provider store={reduxStore}>
                  <SlideFunnelDynamicsChart
                    color={companyColor}
                    funnelChartData={chartDataMap[key]}
                    type={type}
                    onLoad={({ elem }) => {
                      if (elem) {
                        let boundingBox = elem.getBoundingClientRect();
                        let svgString = convertSVGStringToPNGURI(
                          elem.outerHTML,
                          boundingBox.width,
                          boundingBox.height
                        );
                        if (sandbox.element) {
                          ReactDOM.unmountComponentAtNode(sandbox.element);
                        }
                        resolve({
                          svgString,
                        });
                      }
                    }}
                  />
                </Provider>,
                sandbox.element
              );
            };
            load();
          } catch (e) {
            reject(e);
          }
        });

        releaseSandbox(sandbox);
        if (key === "topMiddleLine") {
          topChartURL = await addS3Image(svgString);
        } else if (key === "middleBottomLine") {
          middleChartURL = await addS3Image(svgString);
        } else if (key === "revenue") {
          bottomChartURL = await addS3Image(svgString);
        }
      }
    }

    return {
      bottomChartURL,
      bottomLabelKPI: funnelBottomLabel,
      companyColor,
      end,
      middleChartURL,
      middleLabelKPI: funnelMiddleLabel,
      start,
      topChartURL,
      topLabelKPI: funnelTopLabel,
    };
  };
}

export default FunnelDynamicsSlide;
