import React, { useState } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { Modal } from "react-bootstrap";
import { BPMButton, BPMDateRange, Spinner } from "../../Components";
import { DateRange } from "../../utils/types";
import { getWeeksFromRange } from "../../utils/date-utils";

type ImportType = "bulk" | "changes" | "changesWithId" | "xml";

interface ImportModalProps {
  importType: ImportType;
  importFunction: (file: any, week: string) => Promise<void>;
  week: string;
  onClose: () => void;
}

const TITLES = {
  bulk: "Bulk Import (from Excel)",
  xml: "Bulk XML Process (from XML)",
  changes: "Import Changes (from Excel)",
  changesWithId: "Import Changes with ID (from Excel)",
};

const DESCRIPTIONS = {
  bulk:
    "Bulk import plan rows. If there are imported rows that duplicate existing ones, they are ignored.",
  xml:
    "Bulk import from XML. Downloads new file with information for buyer to review. Upload via Bulk Upload when done.",
  changes:
    "Import changes to existing plan rows. This only affects changes to count, cost, notes, and creative rotations.",
  changesWithId:
    "Import changes to existing plan rows. This affects all edits except to Network, Avail, Type, and Campaign. This will only work with an exported plan, not from the optimizer.",
};

const ImportModal: React.FC<ImportModalProps> = ({ importType, importFunction, week, onClose }) => {
  const DEFAULT_DATE_RANGE = { start: week, end: week };
  const [dates, setDates] = useState<DateRange>(DEFAULT_DATE_RANGE);
  const [file, setFile] = useState<File | undefined>();
  const [importing, setImporting] = useState(false);

  const importPlan = () => {
    setImporting(true);
    // Create array of weeks to loop over.
    const weeks = getWeeksFromRange(dates);

    // For each week in the range, import the plan.
    const reader = new FileReader();
    reader.onload = async e => {
      const binaryString = e?.target?.result;

      await Promise.all(
        weeks.map(week =>
          (async () => {
            await importFunction(binaryString, week);
          })()
        )
      );

      setImporting(false);
      onClose();
    };
    reader.readAsArrayBuffer(file as Blob);
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{TITLES[importType]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{DESCRIPTIONS[importType]}</div>
        <input
          type="file"
          onChange={e => {
            const uploadedFile = e.target.files;
            const file = uploadedFile?.[0];
            setFile(file);
          }}
        />
        <BPMDateRange
          bordered
          range={dates}
          onChange={dates => setDates(dates)}
          isDayBlocked={date => !R.pipe(Dfns.parseISO, Dfns.isMonday)(date)}
          fullWeeksOnly
        />
      </Modal.Body>
      <Modal.Footer>
        <BPMButton disabled={!file} variant="primary" onClick={importPlan}>
          {importing ? <Spinner /> : "Import"}
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
