import "./ModelOverview.scss";
import { cptbAreaData, dateToMddyy } from "./DataFormatters";
import { currencyFormatter, CONVERSION_FORMATTER_TYPE } from "../MMMUtils";
import { DownloadDropdown } from "../../Components/DownloadDropdown";
import { downloadJSONToCSV, exportToExcel, downloadPNG } from "../../utils/download-utils";
import { Dropdown, DropdownToggleType } from "../../Components/Dropdown";
import { formatNumber, formatMoney } from "../../utils/format-utils";
import { getGroupingValue } from "../../TVADCrossChannel/homePageUtils";
import { isInternalSelector } from "../../redux/user";
import { TEST_COMPANIES } from "@blisspointmedia/bpm-types/dist/TestCompanies";
import { useSelector } from "react-redux";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import ActualVsPredicted from "./ActualVsPredicted";
import AreaChart from "../../Components/Charts/AreaChart";
import ChartContainer from "../../Components/ChartContainer";
import MoreInfo from "../MoreInfo";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import {
  ActualVsPredictedDataInitial,
  ModelOverviewData,
  CptbData,
} from "@blisspointmedia/bpm-types/dist/MMM";

interface ModelOverviewProps {
  company: string;
  cptbDataDirect: CptbData[];
  cptbData: CptbData[];
  disabledPlatform: boolean | undefined;
  groupBy: string;
  kpi: string;
  kpiType: string;
  modelOverviewData: ModelOverviewData[];
  overallRoasCpa: number | undefined;
  actualVsPredictedData: ActualVsPredictedDataInitial[];
  showActualizedData: boolean;
}
export interface ActualVsPredictedData {
  actual_kpi: number;
  date: string;
  predicted_kpi: number;
  train_test: string;
  [key: string]: any;
}

export type DateGroupingKey = "Day" | "Week" | "Month" | "Quarter" | "Year";
export interface DateGroupingOption {
  value: DateGroupingKey;
  label: string;
}
export const DATE_GROUPING_OPTIONS: DateGroupingOption[] = [
  { value: "Week", label: "Weekly" },
  { value: "Month", label: "Monthly" },
  { value: "Quarter", label: "Quarterly" },
];

interface RightData {
  kpi: string;
  mean_spend: number;
  refresh_date: string;
  test_end_date: string;
  test_rsquared: number;
  test_size: number;
  train_start_date: string;
  train_size: number;
  test_mape: number;
  test_mae?: number;
  "test_mae "?: number;
}
interface PrettyNameToDataMap {
  dataKey: string;
  name: string;
  value: string | number;
}

const DATA_KEYS = [
  "kpi",
  "mean_spend",
  "refresh_date",
  "test_end_date",
  "test_rsquared",
  "test_size",
  "train_start_date",
  "train_size",
  "test_mape",
  "test_mae ", // some old files have a space in the key
  "test_mae",
  "test_rsquared",
];

const CHART_HEIGHT = 405;

const ACT_VS_PRED_TITLE = {
  conversion: "KPI Volume",
  revenue: "Revenue",
};

export const ModelOverview: React.FC<ModelOverviewProps> = ({
  company,
  cptbData,
  cptbDataDirect,
  disabledPlatform,
  groupBy,
  kpi,
  kpiType,
  modelOverviewData,
  overallRoasCpa,
  actualVsPredictedData,
  showActualizedData,
}) => {
  const [chartDateGrouping, setChartDateGrouping] = useState("Week");
  const [rightData, setRightData] = useState({} as RightData);
  const isInternal = useSelector(isInternalSelector);
  const [areaChartData, setAreaChartData] = useState(
    {} as { newD: any[]; areas: any[]; groupedAreas: any[] }
  );
  const companyEqualsTest = TEST_COMPANIES.includes(company);

  const modelOverviewDataToExport: [string[], (string | number)[]] = useMemo(() => {
    if (!R.isEmpty(rightData) && kpi && overallRoasCpa) {
      const keyToPrettyName: PrettyNameToDataMap[] = [
        {
          dataKey: "train_start_date",
          name: "First model observation",
          value: dateToMddyy(rightData.train_start_date),
        },
        {
          dataKey: "test_end_date",
          name: "Last model observation",
          value: dateToMddyy(rightData.test_end_date),
        },
        {
          dataKey: "mean_spend",
          name: "Average weekly spend",
          value: currencyFormatter.format(rightData.mean_spend, 0),
        },
        {
          dataKey: "kpi",
          name: "Outcome variable",
          value: kpi,
        },
        {
          dataKey: "refresh_date",
          name: "Last refresh date",
          value: dateToMddyy(rightData.refresh_date),
        },
        {
          dataKey: "N/A",
          name: "Train/Test split",
          value: `${(rightData.train_size * 100).toFixed(0)}/${(rightData.test_size * 100).toFixed(
            0
          )}`,
        },
        {
          dataKey: "test_mape",
          name: "Out-of-sample MAPE",
          value: `${rightData.test_mape.toFixed(1)}%`,
        },
        {
          dataKey: "test_mae",
          name: "Out-of-sample MAE",
          value:
            rightData["test_mae "] !== undefined
              ? kpiType === "revenue"
                ? formatMoney(rightData["test_mae "], 0)
                : formatNumber(rightData["test_mae "], 0)
              : rightData.test_mae !== undefined
              ? kpiType === "revenue"
                ? formatMoney(rightData.test_mae, 0)
                : formatNumber(rightData.test_mae, 0)
              : "",
        },
        {
          dataKey: "overallRoasCpa",
          name:
            kpiType === "revenue" ? "Trailing Twelve Months ROAS" : "Trailing Twelve Months CPA",
          value: overallRoasCpa || "",
        },
      ];

      return keyToPrettyName.reduce(
        (acc, cv) => {
          acc[0].push(cv.name);
          acc[1].push(cv.value);
          return acc;
        },
        [[], []] as [string[], (string | number)[]]
      );
    } else {
      return [[], []];
    }
  }, [rightData, kpi, kpiType, overallRoasCpa]);

  useEffect(() => {
    if (!R.isEmpty(cptbDataDirect)) {
      let data;
      data = R.filter(R.compose(R.not, R.propEq("name", "Covariates")))(cptbDataDirect);
      data = cptbAreaData({
        data,
        data1Key: cptbDataDirect[0].raw_spend !== undefined ? "raw_spend" : "direct_spend",
        groupBy,
        dateGrouping: chartDateGrouping,
        disabledPlatform,
        companyEqualsTest,
      });

      setAreaChartData(data);
    }
  }, [
    chartDateGrouping,
    companyEqualsTest,
    cptbDataDirect,
    disabledPlatform,
    groupBy,
    showActualizedData,
    cptbData,
  ]);

  useEffect(() => {
    if (!R.isEmpty(modelOverviewData)) {
      const rightUsedData = DATA_KEYS.map(key => {
        return modelOverviewData.find(data => {
          return key === data.parameter;
        });
      });

      let rightDataAsObject = {};
      rightUsedData.forEach(data => {
        if (data) {
          rightDataAsObject[data.parameter] = data.value;
        }
      });
      setRightData(rightDataAsObject as any);
    }
  }, [modelOverviewData]);

  const excelDownloadRight = useCallback(() => {
    exportToExcel(modelOverviewDataToExport, `${company}_model_overview`);
  }, [modelOverviewDataToExport, company]);

  const csvDownloadRight = useCallback(() => {
    downloadJSONToCSV(modelOverviewDataToExport, `${company}_model_overview`);
  }, [modelOverviewDataToExport, company]);

  const excelDownloadActVPred = useCallback(() => {
    exportToExcel(actualVsPredictedData, `${company}_actual_vs_predicted`);
  }, [actualVsPredictedData, company]);

  const pngDownloadActVPred = useCallback(async () => {
    await downloadPNG(".actualVsPredictedGraph", `${company}_actual_vs_predicted`);
  }, [company]);

  const excelDownloadAreaGraph = useCallback(() => {
    const XLSX_KEYS = [
      "client",
      "kpi",
      "refresh_date",
      "name",
      "date",
      "raw_spend",
      "direct_spend",
    ];
    const cptbDataDirectToExport = R.map(R.pick(XLSX_KEYS), cptbDataDirect);

    exportToExcel(cptbDataDirectToExport, `${company}_area_spend`);
  }, [cptbDataDirect, company]);

  const pngDownloadAreaGraph = useCallback(async () => {
    await downloadPNG(".modelOverview .areaChart", `${company}_area`);
  }, [company]);

  const getActVPredDataByDateGrouping = (data: any[], dateGrouping: string) => {
    let totalsByDate: Record<string, Record<string, number>> = {};
    for (let item of data) {
      const { date, actual_kpi, predicted_kpi } = item;

      const dateToUse = getGroupingValue(date, dateGrouping as DateGroupingKey);
      totalsByDate = R.mergeDeepRight(totalsByDate, {
        [dateToUse]: {
          actual_kpi: R.pathOr(0, [dateToUse, actual_kpi], totalsByDate) + actual_kpi,
          predicted_kpi: R.pathOr(0, [dateToUse, predicted_kpi], totalsByDate) + predicted_kpi,
          train_test: item.train_test,
        },
      });
    }
    let rows: {
      date: string;
      actual_kpi: number;
      predicted_kpi: number;
      train_test: string;
    }[] = [];
    Object.keys(totalsByDate).forEach(item => {
      rows.push({
        date: item,
        actual_kpi: totalsByDate[item].actual_kpi,
        predicted_kpi: totalsByDate[item].predicted_kpi,
        train_test: String(totalsByDate[item].train_test),
      });
    });

    return rows;
  };

  const actVPredDataByDate = getActVPredDataByDateGrouping(
    actualVsPredictedData,
    chartDateGrouping
  );

  return (
    <WidgetContainer
      collapsible
      header="How well does the model understand our business?"
      subHeader={
        <>
          High out-of-sample prediction accuracy is indicative of reliable causal understanding.
          <MoreInfo rightLabel="More info" size="sm">
            The model is estimated by splitting up the time series data into slices called “train”
            and “test.”. The training data is used to estimate the relationships between inputs
            (media investments, contextual variables) and outputs (the KPI). The test data is used
            to evaluate the performance of the trained model “out of sample,” i.e. on observations
            it has not already encountered.
            <br />
            <br /> Out-of-sample predictive accuracy is a critical concept because it proxies what
            we ultimately care about, namely an understanding of likely KPI outputs given a
            combination of novel media inputs. See Out-of-Sample R<sup>2</sup> at right for a
            measure of this concept.
          </MoreInfo>
        </>
      }
    >
      {!R.isEmpty(rightData) && (
        <div className="modelOverview">
          <div className="left">
            <ChartContainer
              enableHoverDesign
              rightActions={
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[excelDownloadActVPred, pngDownloadActVPred]}
                />
              }
              title={
                <>
                  <Dropdown
                    type={DropdownToggleType.WIDGET_TITLE}
                    value={chartDateGrouping}
                    options={DATE_GROUPING_OPTIONS}
                    onChange={option => setChartDateGrouping(option)}
                  />
                  <div>{`Historical ${ACT_VS_PRED_TITLE[kpiType]}`}</div>
                  <div className="headerText">(Actual vs Predicted)</div>
                </>
              }
            >
              {!R.isEmpty(actVPredDataByDate) && (
                <ActualVsPredicted
                  data={actVPredDataByDate}
                  dateGrouping={chartDateGrouping}
                  legendValue={ACT_VS_PRED_TITLE[kpiType]}
                  syncId={1}
                  tooltipFormatter={val => {
                    return val < 1000
                      ? CONVERSION_FORMATTER_TYPE[kpiType].format(val, 0)
                      : CONVERSION_FORMATTER_TYPE[kpiType].format(val, 1);
                  }}
                  yAxisFormatter={val => CONVERSION_FORMATTER_TYPE[kpiType].format(val, 0)}
                />
              )}
            </ChartContainer>
            <ChartContainer
              enableHoverDesign
              title={
                <>
                  <Dropdown
                    type={DropdownToggleType.WIDGET_TITLE}
                    value={chartDateGrouping}
                    options={DATE_GROUPING_OPTIONS}
                    onChange={option => setChartDateGrouping(option)}
                  />
                  <div>Historical Spend</div>
                </>
              }
              rightActions={
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[excelDownloadAreaGraph, pngDownloadAreaGraph]}
                />
              }
              height={CHART_HEIGHT}
            >
              {!R.isEmpty(areaChartData) && (
                <div className="modelOverviewSpend">
                  <AreaChart
                    areas={areaChartData.areas}
                    data={areaChartData.newD}
                    dateGrouping={chartDateGrouping}
                    groupedAreas={areaChartData.groupedAreas}
                    incrementality={false}
                    syncId={1}
                    tooltipFormatter={val => {
                      return val < 1000
                        ? currencyFormatter.format(val, 0)
                        : currencyFormatter.format(val, 1);
                    }}
                    xAxisDataKey="date"
                    xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}T00:00:00`))}
                    yAxisTickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
          <div className="right">
            <ChartContainer
              enableHoverDesign
              title="Model Overview"
              rightActions={
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[excelDownloadRight, csvDownloadRight]}
                  menuOptions={["XLSX", "CSV"]}
                />
              }
            >
              <div
                className="chartBody"
                style={{ display: "flex", flexDirection: "column", gap: "32px" }}
              >
                <div className="dataSection">
                  <div className="dataSectionTitle">Training Data</div>
                  <div className="dataBody">
                    <div className="dataSectionRow">
                      <div className="label">First model observation:</div>
                      <div className="value">{dateToMddyy(rightData.train_start_date)}</div>
                    </div>
                    <div className="dataSectionRow">
                      <div className="label">Last model observation:</div>
                      <div className="value">{dateToMddyy(rightData.test_end_date)}</div>
                    </div>
                    <div className="dataSectionRow">
                      <div className="label">Average weekly spend:</div>
                      <div className="value">
                        {currencyFormatter.format(rightData.mean_spend, 0)}
                      </div>
                    </div>
                    <div className="dataSectionRow">
                      <div className="label">Outcome variable:</div>
                      <div className="value outcomeVariable">{kpi.replace(/_/g, " ")}</div>
                    </div>
                  </div>
                </div>
                <div className="dataSection">
                  <div className="dataSectionTitle">Recency</div>
                  <div className="dataBody">
                    <div className="dataSectionRow">
                      <div className="label">Last refresh date:</div>
                      <div className="value">{dateToMddyy(rightData.refresh_date)}</div>
                    </div>
                  </div>
                </div>
                <div className="dataSection">
                  <div className="dataSectionTitle">Statistics</div>
                  <div className="dataBody">
                    <div className="dataSectionRow">
                      <div className="label">
                        Train/Test split:{" "}
                        <MoreInfo size="reg">
                          This refers to the fraction of data used to fit the model vs the fraction
                          held out for model validation. Setting aside data for model validation is
                          very important in ensuring that the model generalizes well to new, unseen
                          data.
                        </MoreInfo>
                      </div>
                      <div className="value">{`${(rightData.train_size * 100).toFixed(0)}/${(
                        rightData.test_size * 100
                      ).toFixed(0)}`}</div>
                    </div>
                    {isInternal && !companyEqualsTest && (
                      <div className="dataSectionRow">
                        <div className="label">
                          Out-of-sample R<sup>2</sup>:{" "}
                          <MoreInfo size="reg">
                            The percentage of variation in the unseen test data outcomes that is
                            accounted for by the model's explanatory variables. Formally, this is 1
                            - (residual sum of squares) / (total sum of squares)
                          </MoreInfo>
                        </div>
                        <div className="value">{rightData.test_rsquared.toFixed(2)}</div>
                      </div>
                    )}
                    <div className="dataSectionRow">
                      <div className="label">
                        {"Out-of-sample MAPE: "}
                        <MoreInfo size="reg">
                          Mean absolute percentage error is a measure of the model's prediction
                          accuracy, reflecting the average magnitude of the difference between
                          actual and predicted values in percentage terms. Its definition is
                          avg(abs((predicted - actual)/actual)).
                        </MoreInfo>
                      </div>
                      <div className="value">{`${rightData.test_mape.toFixed(1)}%`}</div>
                    </div>
                    <div className="dataSectionRow">
                      <div className="label">
                        {"Out-of-sample MAE: "}
                        <MoreInfo size="reg">
                          Mean absolute error is a measure of the model's prediction accuracy,
                          reflecting the average magnitude of the difference between actual and
                          predicted values in absolute terms. Its definition is avg(abs(predicted -
                          actual)).
                        </MoreInfo>
                      </div>
                      <div className="value">
                        {rightData["test_mae "] !== undefined
                          ? kpiType === "revenue"
                            ? formatMoney(rightData["test_mae "], 0)
                            : formatNumber(rightData["test_mae "], 0)
                          : rightData.test_mae !== undefined
                          ? kpiType === "revenue"
                            ? formatMoney(rightData.test_mae, 0)
                            : formatNumber(rightData.test_mae, 0)
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dataSection">
                  <div className="dataSectionTitle">Key Results</div>
                  <div className="dataBody">
                    <div className="dataSectionRow">
                      <div className="label">
                        {kpiType === "revenue"
                          ? "Trailing Twelve Months ROAS:"
                          : "Trailing Twelve Months CPA:"}
                      </div>
                      <div className="value">
                        {overallRoasCpa
                          ? kpiType === "conversion"
                            ? formatMoney(overallRoasCpa)
                            : formatNumber(overallRoasCpa, 2)
                          : "--"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ChartContainer>
          </div>
        </div>
      )}
    </WidgetContainer>
  );
};

export default ModelOverview;
