import moment from "moment";
import { VALID_URL_REGEXP } from "./WtoUtils";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const isNumeric = (arg: any) => {
  return !isNaN(arg);
};

export const hasNumber = (myString: string) => /\d/.test(myString);

let keyArr: any;
let message: string;

const hasDuplicates = (array: string | any[]) => {
  const valuesSoFar = Object.create(null);
  const resultObject: { [k: string]: any } = {};

  for (let i = 0; i < array.length; ++i) {
    const value = array[i];
    if (value !== null && value in valuesSoFar) {
      resultObject.duplicateValue = value;
      resultObject.originalIndex = valuesSoFar[value];
      resultObject.duplicateIndex = i;
      resultObject.hasDuplicates = true;
      return resultObject;
    }
    valuesSoFar[value] = i;
  }

  resultObject.hasDuplicates = false;
  return resultObject;
};

const addInvalidClass = (selector: string, selectorType: string) => {
  switch (selectorType) {
    case "class":
      if (!document.getElementsByClassName(selector)[0]?.classList.contains("invalid")) {
        document.getElementsByClassName(selector)[0]?.classList.add("invalid");
      }
      break;
    default:
      // default selector type is id
      if (!document.getElementById(selector)?.classList.contains("invalid")) {
        document.getElementById(selector)?.classList.add("invalid");
      }
  }
};

const removeInvalidClass = (selector: string, selectorType: string) => {
  switch (selectorType) {
    case "class":
      if (document.getElementsByClassName(selector)[0]?.classList.contains("invalid")) {
        document.getElementsByClassName(selector)[0]?.classList.remove("invalid");
      }
      break;
    default:
      // default selector type is id
      if (document.getElementById(selector)?.classList.contains("invalid")) {
        document.getElementById(selector)?.classList.remove("invalid");
      }
  }
};

const areMultipleTimeFrameTestIdsTheSame = (rulesArray: any, currentRuleIndex: any) => {
  if (rulesArray.length <= 1) {
    return { hasDuplicates: false };
  }

  const filteredTimeFrameEnabledRules: any = [];
  const timeFrameTestIds: any = [];

  for (let i = 0; i < rulesArray.length; i++) {
    if (rulesArray[i].timeframe_enabled && rulesArray[i].timeframe.timeframe_utm_test_id_enabled) {
      filteredTimeFrameEnabledRules.push({
        rule_name: rulesArray[i].rule_name,
        utm_test_id: rulesArray[i].timeframe.utm_test_id,
      });
    } else {
      filteredTimeFrameEnabledRules.push(null);
    }
  }

  if (filteredTimeFrameEnabledRules.length > 1) {
    for (let i = 0; i < filteredTimeFrameEnabledRules.length; i++) {
      if (filteredTimeFrameEnabledRules[i]) {
        timeFrameTestIds.push(filteredTimeFrameEnabledRules[i].utm_test_id);
      } else {
        timeFrameTestIds.push(null);
      }
    }

    const dupeResultObject = hasDuplicates(timeFrameTestIds);

    if (dupeResultObject.hasDuplicates) {
      return {
        hasDuplicates: true,
        message: `${dupeResultObject.duplicateValue} is already in use for the following rule: ${
          filteredTimeFrameEnabledRules[dupeResultObject.originalIndex].rule_name
        }`,
      };
    } else {
      return { hasDuplicates: false };
    }
  }

  return { hasDuplicates: false };
};

const getFormFieldIndex = (invalidationArray: string | any[], formFieldName: string) =>
  invalidationArray.indexOf(formFieldName);

const areAnyTimeFrameTestIdsEqualToDefaultTestId = (currentFormObj: {
  utm_test_id: any;
  rules: string | any[];
}) => {
  let result = false;
  const defaultUtmTestId = currentFormObj.utm_test_id;
  for (let i = 0; i < currentFormObj.rules?.length; i++) {
    if (
      currentFormObj.rules[i].timeframe_enabled &&
      currentFormObj.rules[i].timeframe.utm_test_id === defaultUtmTestId
    ) {
      result = true;
      break;
    }
  }
  return result;
};

const isValidUrl = (url: string) => {
  const testQuestionMark = new RegExp(".+\\?"); // checking if having "?" in variant and fallback url
  return (
    Boolean(VALID_URL_REGEXP.test(url)) &&
    !testQuestionMark.test(url) &&
    url.indexOf("https://") !== -1 &&
    (url.match(/\./g) || []).length >= 1
  );
};

const areAdditionalUtmDuplicates = (
  formArray: any,
  operationType: string,
  keyName: string,
  getOperationObject: any
) => {
  let isDuplicate = false;

  if (keyName.includes("key")) {
    // compare add_utm with utm_param
    if (
      formArray.conditions.length !== -1 &&
      getOperationObject.operation !== "Replace" &&
      getOperationObject.operation !== "Delete"
    ) {
      formArray.conditions.map((conditionData: any) => {
        if (
          conditionData.trigger &&
          ((getOperationObject.operation === "Add" && conditionData.trigger === "UTM Parameter") ||
            (getOperationObject.operation === "Create" && conditionData.trigger === "Cookie"))
        ) {
          if (conditionData.value.split("=")[0] === getOperationObject[keyName]) {
            isDuplicate = true;
          }
        }
        return null;
      });
    }

    if (formArray[operationType].length > 0 && operationType === "additional_utm") {
      for (let k = 0; k < formArray[operationType].length; k++) {
        let element = formArray[operationType][k];
        for (let i = 0; i < element.additional_utm_operation.length; i++) {
          for (let j = 0; j < element.additional_utm_operation.length; j++) {
            const keyOne = element.additional_utm_operation[i].operation.includes("Replace")
              ? keyName
              : "utm_key";
            const keyTwo = element.additional_utm_operation[j].operation.includes("Replace")
              ? keyName
              : "utm_key";
            if (i !== j) {
              if (
                element.additional_utm_operation[i][keyOne] ===
                element.additional_utm_operation[j][keyTwo]
              ) {
                return (isDuplicate = true);
              }
            }
          }
        }
      }
    }

    if (formArray[operationType].length > 0 && operationType !== "additional_utm") {
      for (let i = 0; i < formArray[operationType].length; i++) {
        for (let j = 0; j < formArray[operationType].length; j++) {
          const keyOne = formArray[operationType][i].operation.includes("Replace")
            ? keyName
            : operationType === "cookie_operation"
            ? "cookie_key"
            : "utm_key";
          const keyTwo = formArray[operationType][j].operation.includes("Replace")
            ? keyName
            : operationType === "cookie_operation"
            ? "cookie_key"
            : "utm_key";
          if (i !== j) {
            if (formArray[operationType][i][keyOne] === formArray[operationType][j][keyTwo]) {
              return (isDuplicate = true);
            }
          }
        }
      }
    }

    if (
      formArray.timeframe &&
      formArray.timeframe.timeframe_utm_test_id_enabled &&
      operationType !== "cookie_operation"
    ) {
      if (formArray.timeframe.utm_test_id.split("=")[0] === getOperationObject[keyName]) {
        return (isDuplicate = true);
      }
    }
  }
  return isDuplicate;
};

interface formFieldMappingObj {
  [key: string]: any;
}

export const formValidator = (
  formObj: any,
  validationArray: string | any[],
  bulkPlover: any,
  optionalObject?: { [key: string]: any }
): any[] => {
  const formFieldMappingObj: formFieldMappingObj = {
    test_name: bulkPlover.isBulkPlover ? "route_name" : "Route Name",
    utm_test_id: bulkPlover.isBulkPlover
      ? "utm_exp & utm_test & version number"
      : "Route Tracking URL Parameter",
    default_url: bulkPlover.isBulkPlover ? "default_url" : "Fallback Variant URL",
    default_id: bulkPlover.isBulkPlover ? "default_id" : "Fallback Variant ID",
    domain: "Domain",
    plover_url: bulkPlover.isBulkPlover ? "plover_url" : "Plover Name",
    folder_path: bulkPlover.isBulkPlover ? "folder_path" : "Path",
    notes: "Notes",
    variant_id: bulkPlover.isBulkPlover ? "variant name" : "Variant ID",
    variant_url: "Variant URL",
    variant_split: "Variant Split",
    rule_name: "Rule Name",
    trigger: "Trigger",
    rule: '"Select Rule"',
    value: "Value",
    condition: "Condition",
    operator: "Operator",
    additional_utm: "Additional UTMs",
    cookie_operation: "Cookie Operation",
    day: "Day of the Week",
    reset: "Reset Variant Retention",
  };

  interface formValidatorObjType {
    [key: string]: any;
  }

  const splitTotalArray: any = [];

  const formValidatorObj: formValidatorObjType = {
    invalidationArray: [],
  };

  /*eslint-disable no-labels*/
  topLevelLoop: for (let i = 0; i < validationArray.length; i++) {
    switch (validationArray[i]) {
      case "rules-minimum":
        if (
          (formObj.rules.length === 1 && formObj.rules[0].rule_name === "") ||
          formObj.rules.length === 0
        ) {
          formValidatorObj.invalidationArray.push(
            "At least 1 rule is required to create a new route"
          );
        } else {
          const index = getFormFieldIndex(
            formValidatorObj.invalidationArray,
            "At least 1 rule is required to create a new route"
          );

          if (index !== -1) {
            formValidatorObj.invalidationArray.splice(index, 1);
          }
        }
        break;
      case "rules-maximum":
        if (formObj.rules.length === 15) {
          formValidatorObj.invalidationArray.push("Traffic Route cannot have more than 15 Rules");
        } else {
          const index = getFormFieldIndex(
            formValidatorObj.invalidationArray,
            "Traffic Route cannot have more than 15 Rules"
          );

          if (index !== -1) {
            formValidatorObj.invalidationArray.splice(index, 1);
          }
        }
        break;
      case "timeframe_dates":
        if (
          formObj.timeframe_enabled &&
          (!formObj.timeframe.startDateTime || !formObj.timeframe.endDateTime)
        ) {
          formValidatorObj.invalidationArray.push("Time frame requires a start and end date");
          addInvalidClass("ant-picker-range", "class");
        } else if (
          formObj.timeframe_enabled &&
          formObj.timeframe.startDateTime &&
          formObj.timeframe.endDateTime &&
          (moment(formObj.timeframe.startDateTime).isAfter(formObj.timeframe.endDateTime) ||
            moment(formObj.timeframe.startDateTime).isSame(formObj.timeframe.endDateTime))
        ) {
          formValidatorObj.invalidationArray.push(
            "Time Frame start and end times cannot be the same and start time cannot be ahead of end time"
          );
          addInvalidClass("ant-picker-range", "class");
        } else {
          const index = getFormFieldIndex(
            formValidatorObj.invalidationArray,
            "Time frame requires a start and end date"
          );

          if (index !== -1) {
            formValidatorObj.invalidationArray.splice(index, 1);
            removeInvalidClass("ant-picker-range", "class");
          }
        }
        break;
      case "timeframe_utm_test_id":
        if (formObj.timeframe_enabled) {
          const areMultTimeFrameIds = areMultipleTimeFrameTestIdsTheSame(
            optionalObject?.currentRulesArray,
            optionalObject?.currentRuleIndex
          );
          if (
            /^utm_[a-zA-Z-]+_exp=[a-zA-Z-]+[0-9]+$/.test(formObj.timeframe.utm_test_id) &&
            hasNumber(formObj.timeframe.utm_test_id) &&
            optionalObject?.currentUtmTestId !== formObj.timeframe.utm_test_id &&
            !areMultTimeFrameIds.hasDuplicates
          ) {
            const index = getFormFieldIndex(
              formValidatorObj.invalidationArray,
              `${validationArray[i]}`
            );
            if (index !== -1) {
              formValidatorObj.invalidationArray.splice(index, 1);
            }

            removeInvalidClass("timeframe_utm_test_id-keyname", "id");
            removeInvalidClass("timeframe_utm_test_id-plovername", "id");
            removeInvalidClass("timeframe_utm_test_id-versionnumber", "id");
          } else {
            switch (true) {
              case formObj.timeframe.utm_test_id === "":
                message = "Time Frame Route Tracking URL Parameter cannot be left blank";
                formValidatorObj.invalidationArray.push(message);
                addInvalidClass("timeframe_utm_test_id-keyname", "id");
                addInvalidClass("timeframe_utm_test_id-plovername", "id");
                addInvalidClass("timeframe_utm_test_id-versionnumber", "id");
                break;
              case formObj.timeframe.utm_test_id !== "" &&
                formObj.timeframe.utm_test_id.split("utm_")[1].split("_")[0] === "":
              case formObj.timeframe.utm_test_id !== "" &&
                (formObj.timeframe.utm_test_id.split("=")[1] === "" ||
                  isNumeric(formObj.timeframe.utm_test_id.split("=")[1])):
              case !hasNumber(formObj.timeframe.utm_test_id):
                if (formObj.timeframe.utm_test_id.split("utm_")[1].split("_")[0] === "") {
                  message =
                    "Time Frame Route Tracking URL Parameter - Funnel Step cannot be left blank";
                  formValidatorObj.invalidationArray.push(message);

                  if (
                    !document
                      .getElementById("timeframe_utm_test_id-keyname")
                      ?.classList.contains("invalid")
                  ) {
                    addInvalidClass("timeframe_utm_test_id-keyname", "id");
                  }
                } else {
                  removeInvalidClass("timeframe_utm_test_id-keyname", "id");
                }

                if (
                  formObj.timeframe.utm_test_id.split("=")[1] === "" ||
                  isNumeric(formObj.timeframe.utm_test_id.split("=")[1])
                ) {
                  message =
                    "Time Frame Route Tracking URL Parameter - Test Name cannot be left blank";
                  formValidatorObj.invalidationArray.push(message);

                  if (
                    !document
                      .getElementById("timeframe_utm_test_id-plovername")
                      ?.classList.contains("invalid")
                  ) {
                    addInvalidClass("timeframe_utm_test_id-plovername", "id");
                  }
                } else {
                  removeInvalidClass("timeframe_utm_test_id-plovername", "id");
                }

                if (!hasNumber(formObj.timeframe.utm_test_id)) {
                  message =
                    "Time Frame Route Tracking URL Parameter - Version Number cannot be left blank";
                  formValidatorObj.invalidationArray.push(message);

                  if (
                    !document
                      .getElementById("timeframe_utm_test_id-versionnumber")
                      ?.classList.contains("invalid")
                  ) {
                    addInvalidClass("timeframe_utm_test_id-versionnumber", "id");
                  }
                } else {
                  removeInvalidClass("timeframe_utm_test_id-versionnumber", "id");
                }
                break;
              case optionalObject?.currentUtmTestId === formObj.timeframe.utm_test_id:
                message =
                  "Route Tracking URL Parameter cannot have the same value as a Time Frame Route Tracking URL Parameter";
                formValidatorObj.invalidationArray.push(message);

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-keyname")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-keyname", "id");
                }

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-plovername")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-plovername", "id");
                }

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-versionnumber")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-versionnumber", "id");
                }
                break;
              case areMultTimeFrameIds.hasDuplicates:
                message = areMultTimeFrameIds.message ? areMultTimeFrameIds.message : "";
                formValidatorObj.invalidationArray.push(message);

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-keyname")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-keyname", "id");
                }

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-plovername")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-plovername", "id");
                }

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-versionnumber")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-versionnumber", "id");
                }
                break;
              default:
                message = "Time Frame Route Tracking URL Parameter is invalid";
                formValidatorObj.invalidationArray.push(message);

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-keyname")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-keyname", "id");
                }

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-plovername")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-plovername", "id");
                }

                if (
                  !document
                    .getElementById("timeframe_utm_test_id-versionnumber")
                    ?.classList.contains("invalid")
                ) {
                  addInvalidClass("timeframe_utm_test_id-versionnumber", "id");
                }
            }

            if (
              formObj.timeframe.utm_test_id > 0 &&
              formObj.timeframe.utm_test_id.split("utm_")[1].split("_")[0] === ""
            ) {
              if (
                !document
                  .getElementById("timeframe_utm_test_id-keyname")
                  ?.classList.contains("invalid")
              ) {
                addInvalidClass("timeframe_utm_test_id-keyname", "id");
              }
            }
          }
        }
        break;
      case "timezone":
        if (formObj.timeframe_enabled && !formObj.timeframe.time_zone) {
          formValidatorObj.invalidationArray.push("A timezone must be selected");

          if (!document.getElementById("timezone")?.classList.contains("invalid")) {
            addInvalidClass("timezone", "id");
          }
        } else {
          const index = getFormFieldIndex(
            formValidatorObj.invalidationArray,
            "A timezone must be selected"
          );

          if (index !== -1) {
            formValidatorObj.invalidationArray.splice(index, 1);
          }

          removeInvalidClass("timezone", "id");
        }
        break;
      case "customRetention":
        if (
          formObj[validationArray[i]].customRetentionStatus &&
          (formObj[validationArray[i]].startDate === "" ||
            formObj[validationArray[i]].startDate === undefined)
        ) {
          formValidatorObj.invalidationArray.push(
            "Reset Variant Retention start date must be selected"
          );
          addInvalidClass("ant-picker", "class");
        }
        if (
          formObj[validationArray[i]].customRetentionStatus &&
          formObj[validationArray[i]].time_zone === ""
        ) {
          formValidatorObj.invalidationArray.push(
            "Reset Variant Retention timezone must be selected"
          );
          if (!document.getElementById("reset-timezone")?.classList.contains("invalid")) {
            addInvalidClass("reset-timezone", "id");
          }
        }
        if (
          formObj[validationArray[i]].customRetentionStatus &&
          formObj[validationArray[i]].time_zone !== ""
        ) {
          const index = getFormFieldIndex(
            formValidatorObj.invalidationArray,
            "Reset Variant Retention timezone must be selected"
          );

          if (index !== -1) {
            formValidatorObj.invalidationArray.splice(index, 1);
          }

          removeInvalidClass("reset-timezone", "id");
        }
        break;
      default:
        switch (typeof formObj[validationArray[i]]) {
          case "object":
            if (Array.isArray(formObj[validationArray[i]])) {
              for (let j = 0; j < formObj[validationArray[i]].length; j++) {
                keyArr = Object.keys(formObj[validationArray[i]][j]);
                for (let k = 0; k < keyArr.length; k++) {
                  switch (keyArr[k]) {
                    case "variant_url":
                      if (
                        formObj[validationArray[i]][j][keyArr[k]] !== "" &&
                        isValidUrl(formObj[validationArray[i]][j][keyArr[k]])
                      ) {
                        const index = getFormFieldIndex(
                          formValidatorObj.invalidationArray,
                          `${keyArr[k]} ${j + 1}`
                        );
                        if (index !== -1) {
                          formValidatorObj.invalidationArray.splice(index, 1);
                        }
                      } else {
                        // checking for bulk plover feature
                        bulkPlover.isBulkPlover
                          ? (message =
                              formObj[validationArray[i]][j][keyArr[k]] === ""
                                ? `Sheet Row ${bulkPlover.currentRow} - ${
                                    formFieldMappingObj[keyArr[k]]
                                  } ${j + 1} cannot be left blank`
                                : `Sheet Row ${bulkPlover.currentRow} - ${
                                    formFieldMappingObj[keyArr[k]]
                                  } ${
                                    j + 1
                                  } is an invalid URL. Valid URL must start with HTTPS and contain full URL without UTM Parameters.`)
                          : (message =
                              formObj[validationArray[i]][j][keyArr[k]] === ""
                                ? `${formFieldMappingObj[keyArr[k]]} ${j + 1} cannot be left blank`
                                : `${formFieldMappingObj[keyArr[k]]} ${
                                    j + 1
                                  } is an invalid URL. Valid URL must start with HTTPS and contain full URL without UTM Parameters.`);
                        formValidatorObj.invalidationArray.push(message);
                      }
                      break;
                    case "split":
                      splitTotalArray.push(formObj[validationArray[i]][j][keyArr[k]]);
                      if (j === formObj[validationArray[i]].length - 1) {
                        const splitTotal = splitTotalArray.reduce((a, b) => a + b, 0);
                        message = "Split percentage total should be 100";
                        if (splitTotal === 100) {
                          const index = getFormFieldIndex(
                            formValidatorObj.invalidationArray,
                            message
                          );
                          if (index !== -1) {
                            formValidatorObj.invalidationArray.splice(index, 1);
                          }

                          removeInvalidClass(keyArr[k], "id");
                        } else {
                          formValidatorObj.invalidationArray.push(message);

                          if (!document.getElementById(keyArr[k])?.classList.contains("invalid")) {
                            addInvalidClass(keyArr[k], "id");
                          }
                        }
                      }

                      break;
                    case "variants": {
                      const splitDOWTotalArray: any = [];
                      for (let v = 0; v < formObj[validationArray[i]][j][keyArr[k]].length; v++) {
                        const element = formObj[validationArray[i]][j][keyArr[k]][v];
                        if (element.name === "" && element.url === "") {
                          message = `Day of the week ${j + 1} variant ${
                            v + 1
                          } cannot be left blank`;
                          formValidatorObj.invalidationArray.push(message);

                          if (
                            !document
                              .getElementById(`dow_variant_id-${j + 1}-${v + 1}`)
                              ?.classList.contains("invalid")
                          ) {
                            addInvalidClass(`dow_variant_id-${j + 1}-${v + 1}`, "id");
                          }
                        } else {
                          splitDOWTotalArray.push(element.split);
                          if (v === formObj[validationArray[i]][j][keyArr[k]].length - 1) {
                            const splitTotal = splitDOWTotalArray.reduce(
                              (a: any, b: any) => a + b,
                              0
                            );
                            message = `Day of the week ${j + 1}, All splits must total to 100%`;

                            if (splitTotal === 100) {
                              const index = getFormFieldIndex(
                                formValidatorObj.invalidationArray,
                                message
                              );
                              if (index !== -1) {
                                formValidatorObj.invalidationArray.splice(index, 1);
                              }

                              removeInvalidClass(`split-${j + 1}`, "id");
                            } else {
                              formValidatorObj.invalidationArray.push(message);

                              if (
                                !document
                                  .getElementById(`split-${j + 1}`)
                                  ?.classList.contains("invalid")
                              ) {
                                addInvalidClass(`split-${j + 1}`, "id");
                              }
                            }
                          }
                        }
                      }
                      break;
                    }
                    case "start_time":
                    case "end_time":
                      for (let j = 0; j < formObj[validationArray[i]].length; j++) {
                        let element = formObj[validationArray[i]][j];
                        if (keyArr[k] === "start_time" && element.start_time) {
                          if (element.end_time === "" || element.end_time === undefined) {
                            message = `Day of the week ${j + 1} end time is mising`;
                            formValidatorObj.invalidationArray.push(message);
                          }
                        } else if (keyArr[k] === "end_time" && element.end_time) {
                          if (element.start_time === "" || element.start_time === undefined) {
                            message = `Day of the week ${j + 1} start time is mising`;
                            formValidatorObj.invalidationArray.push(message);
                          }
                        }
                      }
                      break;
                    case "day":
                      if (formObj[validationArray[i]][j].day.length > 0) {
                        if (formObj[validationArray[i]][j].variants.length === 0) {
                          message = `Day of the week ${
                            j + 1
                          } please select variants for allocation`;
                          formValidatorObj.invalidationArray.push(message);
                        }
                      } else if (
                        (formObj[validationArray[i]][j].day.length === 0 &&
                          formObj[validationArray[i]][j].variants.length > 0) ||
                        (formObj[validationArray[i]][j].day.length === 0 &&
                          (formObj[validationArray[i]][j].end_time.length > 0 ||
                            formObj[validationArray[i]][j].start_time.length > 0))
                      ) {
                        message = `Day of the week ${j + 1} , please select day`;
                        formValidatorObj.invalidationArray.push(message);
                      }
                      break;
                    default:
                      switch (true) {
                        // Checking validation for Rule Name , variant_splits , varinat ID
                        case formObj[validationArray[i]][j][keyArr[k]] === "" &&
                          keyArr[k] !== "operator" &&
                          validationArray[i] !== "dow_operation" &&
                          validationArray[i] !== "additional_utm" &&
                          validationArray[i] !== "cookie_operation" &&
                          validationArray[i] !== "conditions":
                          message = `${formFieldMappingObj[keyArr[k]]} ${
                            j + 1
                          } cannot be left blank`;

                          if (validationArray[i] === "variant_splits") {
                            message = `${
                              formFieldMappingObj[
                                validationArray[i].substring(0, validationArray[i].length - 1)
                              ]
                            } ${j + 1} - ${formFieldMappingObj[keyArr[k]]} cannot be left blank`;
                          }
                          formValidatorObj.invalidationArray.push(message);

                          if (
                            !document
                              .getElementById(`${keyArr[k]}-${j + 1}`)
                              ?.classList.contains("invalid")
                          ) {
                            addInvalidClass(`${keyArr[k]}-${j + 1}`, "id");
                          }

                          break;
                        // Added case for validation for trigger in conditions
                        case formObj[validationArray[i]][j][keyArr[k]] === "" &&
                          keyArr[k] !== "operator" &&
                          validationArray[i] !== "additional_utm" &&
                          validationArray[i] !== "cookie_operation" &&
                          validationArray[i] !== "variant_splits":
                          // checking if only trigger is left blank
                          if (keyArr[k] === "trigger" && validationArray[i] === "conditions") {
                            message = `${
                              formFieldMappingObj[
                                validationArray[i].substring(0, validationArray[i].length - 1)
                              ]
                            } ${j + 1} - ${formFieldMappingObj[keyArr[k]]} cannot be left blank`;
                            formValidatorObj.invalidationArray.push(message);
                            if (
                              !document
                                .getElementById(`${keyArr[k]}-${j + 1}`)
                                ?.classList.contains("invalid")
                            ) {
                              addInvalidClass(`${keyArr[k]}-${j + 1}`, "id");
                            }
                            break;
                          } else if (
                            formObj[validationArray[i]][j].trigger &&
                            keyArr[k] !== "trigger"
                          ) {
                            // checking key validation for cookies in condition
                            if (
                              validationArray[i] === "conditions" &&
                              keyArr[k] === "value" &&
                              formObj[validationArray[i]][j][keyArr[k]].split("=")[0] === "" &&
                              formObj[validationArray[i]][j].trigger === "Cookie"
                            ) {
                              message = `${
                                formFieldMappingObj[
                                  validationArray[i].substring(0, validationArray[i].length - 1)
                                ]
                              } ${j + 1} - Key cannot be left blank`;
                              formValidatorObj.invalidationArray.push(message);

                              if (
                                !document
                                  .getElementById(`key-${j + 1}`)
                                  ?.classList.contains("invalid")
                              ) {
                                addInvalidClass(`key-${j + 1}`, "id");
                              }
                            }
                            // checking validation for remaining fields in condition
                            message = `${formFieldMappingObj[keyArr[k]]} ${
                              j + 1
                            } cannot be left blank`;
                            if (validationArray[i] === "conditions") {
                              message = `${
                                formFieldMappingObj[
                                  validationArray[i].substring(0, validationArray[i].length - 1)
                                ]
                              } ${j + 1} - ${formFieldMappingObj[keyArr[k]]} cannot be left blank`;
                            }
                            formValidatorObj.invalidationArray.push(message);
                            if (
                              !document
                                .getElementById(`${keyArr[k]}-${j + 1}`)
                                ?.classList.contains("invalid")
                            ) {
                              addInvalidClass(`${keyArr[k]}-${j + 1}`, "id");
                            }
                          }
                          break;
                        case validationArray[i] === "conditions" &&
                          keyArr[k] === "value" &&
                          formObj[validationArray[i]][j][keyArr[k]].split("=")[0] === "":
                        case validationArray[i] === "conditions" &&
                          keyArr[k] === "value" &&
                          formObj[validationArray[i]][j][keyArr[k]].split("=")[1] === "":
                          // UTM Parameter Key
                          if (
                            validationArray[i] === "conditions" &&
                            keyArr[k] === "value" &&
                            formObj[validationArray[i]][j][keyArr[k]].split("=")[0] === ""
                          ) {
                            message = `${
                              formFieldMappingObj[
                                validationArray[i].substring(0, validationArray[i].length - 1)
                              ]
                            } ${j + 1} - Key cannot be left blank`;
                            formValidatorObj.invalidationArray.push(message);

                            if (
                              !document
                                .getElementById(`key-${j + 1}`)
                                ?.classList.contains("invalid")
                            ) {
                              addInvalidClass(`key-${j + 1}`, "id");
                            }
                          }

                          // UTM Parameter Value
                          if (
                            validationArray[i] === "conditions" &&
                            keyArr[k] === "value" &&
                            formObj[validationArray[i]][j][keyArr[k]].split("=")[1] === ""
                          ) {
                            message = `${
                              formFieldMappingObj[
                                validationArray[i].substring(0, validationArray[i].length - 1)
                              ]
                            } ${j + 1} - Value cannot be left blank`;
                            formValidatorObj.invalidationArray.push(message);

                            if (
                              !document
                                .getElementById(`${keyArr[k]}-${j + 1}`)
                                ?.classList.contains("invalid")
                            ) {
                              addInvalidClass(`${keyArr[k]}-${j + 1}`, "id");
                            }
                          }
                          break;
                        case validationArray[i] === "additional_utm":
                          if (formObj[validationArray[i]][j][keyArr[k]].length > 0) {
                            for (
                              let g = 0;
                              g < formObj[validationArray[i]][j][keyArr[k]].length;
                              g++
                            ) {
                              const element = formObj[validationArray[i]][j][keyArr[k]][g];
                              if (keyArr[k] === "additional_utm_operation") {
                                let keyArrUtm = Object.keys(element);
                                for (let h = 0; h < keyArrUtm.length; h++) {
                                  if (
                                    (element.operation === "Add" ||
                                      element.operation === "Override") &&
                                    keyArrUtm[h] !== "operation" &&
                                    element[keyArrUtm[h]] === ""
                                  ) {
                                    message = `${formFieldMappingObj[validationArray[i]]} ${
                                      j + 1
                                    } - Row ${g + 1} - ${
                                      element.operation.length > 0 ? element.operation : ""
                                    } - ${
                                      keyArrUtm[h].indexOf("_") !== -1
                                        ? keyArrUtm[h].split("_")[1]
                                        : keyArrUtm[h]
                                    } cannot be left blank`;
                                    formValidatorObj.invalidationArray.push(message);
                                    if (
                                      !document
                                        .getElementById(`${keyArrUtm[h]}-${j + 1}-${g + 1}`)
                                        ?.classList.contains("invalid")
                                    ) {
                                      addInvalidClass(`${keyArrUtm[h]}-${j + 1}-${g + 1}`, "id");
                                    }
                                  } else if (
                                    element.operation.length === 0 &&
                                    keyArrUtm[h] === "operation" &&
                                    element[keyArrUtm[h]] === ""
                                  ) {
                                    message = `${formFieldMappingObj[validationArray[i]]} ${
                                      j + 1
                                    } - Row ${g + 1} - ${keyArrUtm[h]} cannot be left blank`;
                                    formValidatorObj.invalidationArray.push(message);

                                    if (
                                      !document
                                        .getElementById(`add_utm_${keyArrUtm[h]}-${j + 1}-${g + 1}`)
                                        ?.classList.contains("invalid")
                                    ) {
                                      addInvalidClass(
                                        `add_utm_${keyArrUtm[h]}-${j + 1}-${g + 1}`,
                                        "id"
                                      );
                                    }
                                  } else if (
                                    element.operation.length !== 0 &&
                                    keyArrUtm[h] !== "operation" &&
                                    element[keyArrUtm[h]] === "" &&
                                    (keyArrUtm[h] === "utm_key" || keyArrUtm[h] === "replace_key")
                                  ) {
                                    if (element.operation === "Replace") {
                                      message = `${formFieldMappingObj[validationArray[i]]} ${
                                        j + 1
                                      } - Row ${g + 1} - ${
                                        element.operation.length > 0 ? element.operation : ""
                                      } ${
                                        keyArrUtm[h].indexOf("_") !== -1
                                          ? keyArrUtm[h] === "cki_replace_key"
                                            ? keyArrUtm[h].split("_")[2]
                                            : keyArrUtm[h].split("_")[1]
                                          : keyArrUtm[h]
                                      } ${
                                        keyArrUtm[h] === "replace_key" ||
                                        keyArrUtm[h] === "cki_replace_key"
                                          ? "2"
                                          : "1"
                                      } cannot be left blank`;
                                      formValidatorObj.invalidationArray.push(message);
                                    } else {
                                      message = `${formFieldMappingObj[validationArray[i]]} ${
                                        j + 1
                                      } - Row ${g + 1} - ${
                                        element.operation.length > 0 ? element.operation : ""
                                      }-${g + 1} ${
                                        keyArrUtm[h].indexOf("_") !== -1
                                          ? keyArrUtm[h].split("_")[1]
                                          : keyArrUtm[h]
                                      } cannot be left blank`;
                                      formValidatorObj.invalidationArray.push(message);
                                    }

                                    if (
                                      !document
                                        .getElementById(`${keyArrUtm[h]}-${j + 1}-${g + 1}`)
                                        ?.classList.contains("invalid")
                                    ) {
                                      addInvalidClass(`${keyArrUtm[h]}-${j + 1}-${g + 1}`, "id");
                                    }
                                  } else if (
                                    areAdditionalUtmDuplicates(
                                      formObj,
                                      validationArray[i],
                                      keyArrUtm[h],
                                      formObj[validationArray[i]][j]
                                    )
                                  ) {
                                    message = `1 or more duplicate or conflicting ${
                                      formFieldMappingObj[validationArray[i]]
                                    } keys have been detected`;
                                    formValidatorObj.invalidationArray.push(message);
                                    break topLevelLoop;
                                  }
                                }
                              }
                            }
                          } else if (keyArr[k] === "additional_utm_url") {
                            if (
                              formObj[validationArray[i]][j].additional_utm_operation?.length > 0 &&
                              formObj[validationArray[i]][j].additional_utm_url?.length === 0
                            ) {
                              message = `Please Select Variant for ${
                                formFieldMappingObj[validationArray[i]]
                              } ${j + 1}`;
                              if (
                                !document
                                  .getElementById(`utm_variant_id-${j + 1}`)
                                  ?.classList.contains("invalid")
                              ) {
                                document
                                  .getElementById(`utm_variant_id-${j + 1}`)
                                  ?.classList.add("invalid");
                              }
                              formValidatorObj.invalidationArray.push(message);
                            }
                          }
                          break;
                        // handle additional_UTM and cookie_operation CREATE/ADD operation
                        case validationArray[i] === "cookie_operation" &&
                          (formObj[validationArray[i]][j].operation === "Add" ||
                            formObj[validationArray[i]][j].operation === "Create") &&
                          formObj[validationArray[i]][j][keyArr[k]].length < 1:
                          message = `${formFieldMappingObj[validationArray[i]]} ${j + 1} - ${
                            formObj[validationArray[i]][j].operation.length > 0
                              ? formObj[validationArray[i]][j].operation
                              : ""
                          } ${
                            keyArr[k].indexOf("_") !== -1 ? keyArr[k].split("_")[1] : keyArr[k]
                          } cannot be left blank`;
                          formValidatorObj.invalidationArray.push(message);
                          if (
                            !document
                              .getElementById(`${keyArr[k]}-${j + 1}`)
                              ?.classList.contains("invalid")
                          ) {
                            addInvalidClass(`${keyArr[k]}-${j + 1}`, "id");
                          }
                          break;

                        // Checking validation if only operation field is empty in additional UTM
                        case validationArray[i] === "cookie_operation" &&
                          formObj[validationArray[i]][j][keyArr[k]].length < 1 &&
                          !keyArr[k].includes("value"):
                          if (
                            formObj[validationArray[i]][j].operation === "" &&
                            keyArr[k] !== "utm_key" &&
                            keyArr[k] !== "replace_key" &&
                            keyArr[k] !== "cookie_key" &&
                            keyArr[k] !== "cki_replace_key"
                          ) {
                            message = `${formFieldMappingObj[validationArray[i]]} ${j + 1} - ${
                              formObj[validationArray[i]][j].operation.length > 0
                                ? formObj[validationArray[i]][j].operation
                                : ""
                            } ${
                              keyArr[k].indexOf("_") !== -1 ? keyArr[k].split("_")[1] : keyArr[k]
                            } cannot be left blank`;
                            formValidatorObj.invalidationArray.push(message);
                            let TriggerElementID = "";
                            if (validationArray[i] === "additional_utm") {
                              TriggerElementID = `add_utm_${keyArr[k]}-${j + 1}`;
                            } else {
                              TriggerElementID = `cookie_${keyArr[k]}-${j + 1}`;
                            }
                            if (
                              !document
                                .getElementById(TriggerElementID)
                                ?.classList.contains("invalid")
                            ) {
                              addInvalidClass(TriggerElementID, "id");
                            }
                          } else if (formObj[validationArray[i]][j].operation !== "") {
                            if (formObj[validationArray[i]][j].operation === "Replace") {
                              message = `${formFieldMappingObj[validationArray[i]]} ${j + 1} - ${
                                formObj[validationArray[i]][j].operation.length > 0
                                  ? formObj[validationArray[i]][j].operation
                                  : ""
                              } ${
                                keyArr[k].indexOf("_") !== -1
                                  ? keyArr[k] === "cki_replace_key"
                                    ? keyArr[k].split("_")[2]
                                    : keyArr[k].split("_")[1]
                                  : keyArr[k]
                              } ${
                                keyArr[k] === "replace_key" || keyArr[k] === "cki_replace_key"
                                  ? "2"
                                  : "1"
                              } cannot be left blank`;
                              formValidatorObj.invalidationArray.push(message);
                            } else {
                              message = `${formFieldMappingObj[validationArray[i]]} ${j + 1} - ${
                                formObj[validationArray[i]][j].operation.length > 0
                                  ? formObj[validationArray[i]][j].operation
                                  : ""
                              } ${
                                keyArr[k].indexOf("_") !== -1 ? keyArr[k].split("_")[1] : keyArr[k]
                              } cannot be left blank`;
                              formValidatorObj.invalidationArray.push(message);
                            }

                            if (
                              !document
                                .getElementById(`${keyArr[k]}-${j + 1}`)
                                ?.classList.contains("invalid")
                            ) {
                              addInvalidClass(`${keyArr[k]}-${j + 1}`, "id");
                            }
                          }
                          break;
                        // handle additional_UTM and cookie_operation duplicate keys
                        case (validationArray[i] === "additional_utm" ||
                          validationArray[i] === "cookie_operation") &&
                          keyArr[k].includes("key") &&
                          keyArr[k].length > 0:
                          if (
                            areAdditionalUtmDuplicates(
                              formObj,
                              validationArray[i],
                              keyArr[k],
                              formObj[validationArray[i]][j]
                            )
                          ) {
                            message = `1 or more duplicate or conflicting ${
                              formFieldMappingObj[validationArray[i]]
                            } keys have been detected`;
                            formValidatorObj.invalidationArray.push(message);
                            break topLevelLoop;
                          }
                          break;
                        default:
                          if (
                            getFormFieldIndex(
                              formValidatorObj.invalidationArray,
                              `${keyArr[k]} ${j + 1}`
                            ) !== -1
                          ) {
                            formValidatorObj.invalidationArray.splice(
                              getFormFieldIndex(
                                formValidatorObj.invalidationArray,
                                `${keyArr[k]} ${j + 1}`
                              ),
                              1
                            );
                          }
                          removeInvalidClass(`${keyArr[k]}-${j + 1}`, "id");

                          // below handles removing the invalid class from the key input field for the utm parameter updates trigger when it is valid.
                          if (
                            formObj.conditions &&
                            formObj.additional_utm[j] &&
                            formObj.additional_utm[j].utm_key !== ""
                          ) {
                            removeInvalidClass(`utm_key-${j + 1}`, "id");
                          }

                          // below handles removing the invalid class from the value input field for the utm parameter updates trigger when it is valid.
                          if (
                            formObj.conditions &&
                            formObj.additional_utm[j] &&
                            formObj.additional_utm[j].utm_value !== ""
                          ) {
                            removeInvalidClass(`utm_value-${j + 1}`, "id");
                          }

                          // below handles removing the invalid class from the replace value input field for the utm parameter updates trigger when it is valid.
                          if (
                            formObj.conditions &&
                            formObj.additional_utm[j] &&
                            formObj.additional_utm[j].replace_key !== ""
                          ) {
                            removeInvalidClass(`replace_key-${j + 1}`, "id");
                          }
                      }
                  }
                }
              }
            }
            break;
          default:
            switch (validationArray[i]) {
              case "utm_test_id":
                if (
                  /^utm_[a-zA-Z-]+_exp=[a-zA-Z-]+[0-9]+$/.test(formObj[validationArray[i]]) &&
                  hasNumber(formObj[validationArray[i]]) &&
                  !areAnyTimeFrameTestIdsEqualToDefaultTestId(formObj)
                ) {
                  const index = getFormFieldIndex(
                    formValidatorObj.invalidationArray,
                    `${validationArray[i]}`
                  );
                  if (index !== -1) {
                    formValidatorObj.invalidationArray.splice(index, 1);
                  }
                } else {
                  switch (true) {
                    case formObj[validationArray[i]] === "":
                      // checking for bulk plover feature
                      bulkPlover.isBulkPlover
                        ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                            formFieldMappingObj[validationArray[i]]
                          } cannot be left blank`)
                        : (message = `${
                            formFieldMappingObj[validationArray[i]]
                          } cannot be left blank`);
                      break;
                    case !hasNumber(formObj[validationArray[i]]):
                      message = bulkPlover.isBulkPlover
                        ? `Sheet Row ${bulkPlover.currentRow} - ${
                            formFieldMappingObj[validationArray[i]]
                          } does not have a test iteration number present, please add one`
                        : `${
                            formFieldMappingObj[validationArray[i]]
                          } does not have a test iteration number present, please add one`;
                      break;
                    case areAnyTimeFrameTestIdsEqualToDefaultTestId(formObj):
                      message = bulkPlover.isBulkPlover
                        ? `Sheet Row ${bulkPlover.currentRow} - ${
                            formFieldMappingObj[validationArray[i]]
                          } cannot have the same value as a Timeframe Route Tracking URL Parameter.  1 or more duplicates were detected`
                        : `${
                            formFieldMappingObj[validationArray[i]]
                          } cannot have the same value as a Timeframe Route Tracking URL Parameter.  1 or more duplicates were detected`;
                      break;
                    default:
                      message = bulkPlover.isBulkPlover
                        ? `Sheet Row ${bulkPlover.currentRow} - ${
                            formFieldMappingObj[validationArray[i]]
                          } is invalid`
                        : `${formFieldMappingObj[validationArray[i]]} is invalid`;
                  }

                  formValidatorObj.invalidationArray.push(message);
                }
                break;
              case "default_url":
                if (formObj[validationArray[i]] !== "" && isValidUrl(formObj[validationArray[i]])) {
                  const index = getFormFieldIndex(
                    formValidatorObj.invalidationArray,
                    `${validationArray[i]}`
                  );
                  if (index !== -1) {
                    formValidatorObj.invalidationArray.splice(index, 1);
                  }
                } else {
                  // checking for bulk plover feature
                  bulkPlover.isBulkPlover
                    ? (message =
                        formObj[validationArray[i]] === ""
                          ? `Sheet Row ${bulkPlover.currentRow} - ${
                              formFieldMappingObj[validationArray[i]]
                            } cannot be left blank`
                          : `Sheet Row ${bulkPlover.currentRow} - ${
                              formFieldMappingObj[validationArray[i]]
                            } is an invalid URL`)
                    : (message =
                        formObj[validationArray[i]] === ""
                          ? `${formFieldMappingObj[validationArray[i]]} cannot be left blank`
                          : `${formFieldMappingObj[validationArray[i]]} is an invalid URL`);
                  formValidatorObj.invalidationArray.push(message);
                }
                break;
              case "plover_url":
                // TODO: Swap commented out code with if conditional that is currently in use when ready
                if (
                  formObj[validationArray[i]] !== "" &&
                  (/^[a-zA-Z0-9-]+[a-zA-Z0-9]+$/.test(formObj[validationArray[i]]) ||
                    /^[a-zA-Z0-9-]+(.html)$/.test(formObj[validationArray[i]]))
                ) {
                  const index = getFormFieldIndex(
                    formValidatorObj.invalidationArray,
                    `${validationArray[i]}`
                  );
                  if (index !== -1) {
                    formValidatorObj.invalidationArray.splice(index, 1);
                  }
                } else {
                  // checking for bulk plover feature
                  bulkPlover.isBulkPlover
                    ? (message =
                        formObj[validationArray[i]] === ""
                          ? `Sheet Row ${bulkPlover.currentRow} - ${
                              formFieldMappingObj[validationArray[i]]
                            } cannot be left blank`
                          : `Sheet Row ${bulkPlover.currentRow} - ${
                              formFieldMappingObj[validationArray[i]]
                            } is in an invalid format`)
                    : (message =
                        formObj[validationArray[i]] === ""
                          ? `${formFieldMappingObj[validationArray[i]]} cannot be left blank`
                          : `${formFieldMappingObj[validationArray[i]]} is in an invalid format`);
                  formValidatorObj.invalidationArray.push(message);
                }
                break;
              case "folder_path":
                if (
                  formObj[validationArray[i]] === "/" ||
                  /^(\/)([a-zA-Z0-9]{3,}(\/))+$/.test(formObj[validationArray[i]])
                ) {
                  const index = getFormFieldIndex(
                    formValidatorObj.invalidationArray,
                    `${validationArray[i]}`
                  );
                  if (index !== -1) {
                    formValidatorObj.invalidationArray.splice(index, 1);
                  }
                } else if (formObj[validationArray[i]] === "") {
                  //checking for bulk plover feature
                  bulkPlover.isBulkPlover
                    ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                        formFieldMappingObj[validationArray[i]]
                      } cannot be left blank`)
                    : (message = `${formFieldMappingObj[validationArray[i]]} cannot be left blank`);
                  formValidatorObj.invalidationArray.push(message);
                } else if (
                  !/^[^*|":<>{}[\]\\!?=^%#`()';@&$.,\\+\\-]+$/.test(
                    formObj[validationArray[i]].replace(/^\/\/|\/\/$/g, "?")
                  )
                ) {
                  //validation of folder path if it conatain special character   ? , \ , =, ! , [ ]
                  bulkPlover.isBulkPlover
                    ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                        formFieldMappingObj[validationArray[i]]
                      } is in invalid format`)
                    : (message = `${formFieldMappingObj[validationArray[i]]} is in invalid format`);
                  formValidatorObj.invalidationArray.push(message);
                } else if (
                  !formObj[validationArray[i]].startsWith("/") ||
                  !formObj[validationArray[i]].endsWith("/")
                ) {
                  bulkPlover.isBulkPlover
                    ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                        formFieldMappingObj[validationArray[i]]
                      } should start and end with / character`)
                    : (message = `${
                        formFieldMappingObj[validationArray[i]]
                      } should start and end with / character`);
                  formValidatorObj.invalidationArray.push(message);
                } else if (formObj[validationArray[i]].length) {
                  const splitFolderPathArray = formObj[validationArray[i]]
                    .split("/")
                    .filter((element: any) => element !== "");
                  if (splitFolderPathArray) {
                    for (let index = 0; index < splitFolderPathArray.length; index++) {
                      let element = splitFolderPathArray[index];
                      if (element.length) {
                        if (index === 0 && element.length < 3) {
                          bulkPlover.isBulkPlover
                            ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                                formFieldMappingObj[validationArray[i]]
                              } cannot be shorter than 3 characters`)
                            : (message = `${
                                formFieldMappingObj[validationArray[i]]
                              } cannot be shorter than 3 characters`);
                          formValidatorObj.invalidationArray.push(message);
                        }
                        if (index !== 0 && element.length < 2) {
                          bulkPlover.isBulkPlover
                            ? (message = `Sheet Row ${bulkPlover.currentRow} - Sub path cannot be shorter than 2 characters`)
                            : (message = "Sub path cannot be shorter than 2 characters");
                          formValidatorObj.invalidationArray.push(message);
                        }
                      }
                    }
                  }
                }
                break;
              case "domain":
                if (formObj[validationArray[i]] !== "") {
                  // checking for bulk plover feature
                  if (bulkPlover.isBulkPlover && bulkPlover.partnerDomainArray?.length > 0) {
                    if (
                      bulkPlover.partnerDomainArray?.indexOf(formObj[validationArray[i]]) === -1
                    ) {
                      message = `Sheet Row ${
                        bulkPlover.currentRow
                      } - The domain does not match partner-specific listed domains. These are ${bulkPlover.partnerDomainArray.toString()}`;
                      formValidatorObj.invalidationArray.push(message);
                    }
                  }
                  const index = getFormFieldIndex(
                    formValidatorObj.invalidationArray,
                    `${validationArray[i]}`
                  );
                  if (index !== -1) {
                    formValidatorObj.invalidationArray.splice(index, 1);
                  }
                } else {
                  bulkPlover.isBulkPlover
                    ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                        formFieldMappingObj[validationArray[i]]
                      } cannot be left blank`)
                    : (message = `${formFieldMappingObj[validationArray[i]]} cannot be left blank`);
                  formValidatorObj.invalidationArray.push(message);
                }
                break;
              case "variants":
                for (let j = 0; j < formObj[validationArray[i]].length; j++) {
                  keyArr = Object.keys(formObj[validationArray[i]][j]);
                  for (let k = 0; k < keyArr.length; k++) {
                    switch (keyArr[k]) {
                      case "variant_url":
                        if (
                          formObj[validationArray[i]][j][keyArr[k]] !== "" &&
                          isValidUrl(formObj[validationArray[i]][j][keyArr[k]])
                        ) {
                          const index = getFormFieldIndex(
                            formValidatorObj.invalidationArray,
                            `${keyArr[k]} ${j + 1}`
                          );
                          if (index !== -1) {
                            formValidatorObj.invalidationArray.splice(index, 1);
                          }
                        } else {
                          // checking for bulk plover feature
                          bulkPlover.isBulkPlover
                            ? (message =
                                formObj[validationArray[i]][j][keyArr[k]] === ""
                                  ? `Sheet Row ${bulkPlover.currentRow} - ${
                                      formFieldMappingObj[keyArr[k]]
                                    } ${j + 1} cannot be left blank`
                                  : `Sheet Row ${bulkPlover.currentRow} - ${
                                      formFieldMappingObj[keyArr[k]]
                                    } ${j + 1} is an invalid URL`)
                            : (message =
                                formObj[validationArray[i]][j][keyArr[k]] === ""
                                  ? `${formFieldMappingObj[keyArr[k]]} ${
                                      j + 1
                                    } cannot be left blank`
                                  : `${formFieldMappingObj[keyArr[k]]} ${j + 1} is an invalid URL`);
                          formValidatorObj.invalidationArray.push(message);
                        }
                        break;
                      default:
                        if (
                          formObj[validationArray[i]][j][keyArr[k]] &&
                          /^[a-zA-Z0-9-]+$/.test(formObj[validationArray[i]][j][keyArr[k]])
                        ) {
                          const index = getFormFieldIndex(
                            formValidatorObj.invalidationArray,
                            `${keyArr[k]} ${j + 1}`
                          );
                          if (index !== -1) {
                            formValidatorObj.invalidationArray.splice(index, 1);
                          }
                        } else {
                          if (!/^[a-zA-Z0-9-]+$/.test(formObj[validationArray[i]][j][keyArr[k]])) {
                            bulkPlover.isBulkPlover
                              ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                                  formFieldMappingObj[keyArr[k]]
                                } ${
                                  j + 1
                                } is in an invalid format. Only letters, digits, and dash (-) characters are accepted.'`)
                              : (message = `${formFieldMappingObj[keyArr[k]]} ${
                                  j + 1
                                } is in an invalid format. Only letters, digits, and dash (-) characters are accepted.'`);
                          }

                          if (!formObj[validationArray[i]][j][keyArr[k]]) {
                            bulkPlover.isBulkPlover
                              ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                                  formFieldMappingObj[keyArr[k]]
                                } ${j + 1} cannot be left blank`)
                              : (message = `${formFieldMappingObj[keyArr[k]]} ${
                                  j + 1
                                } cannot be left blank`);
                          }

                          if (
                            validationArray[i] === "conditions" ||
                            validationArray[i] === "variant_splits"
                          ) {
                            // checking for bulk plover feature
                            bulkPlover.isBulkPlover
                              ? (message = `Sheet Row ${bulkPlover.currentRow} - ${
                                  formFieldMappingObj[
                                    validationArray[i].substring(0, validationArray[i].length - 1)
                                  ]
                                } ${j + 1} ${keyArr[k]} cannot be left blank`)
                              : (message = `${
                                  formFieldMappingObj[
                                    validationArray[i].substring(0, validationArray[i].length - 1)
                                  ]
                                } ${j + 1} ${keyArr[k]} cannot be left blank`);
                          }

                          formValidatorObj.invalidationArray.push(message);
                        }
                    }
                  }
                }
                break;
              case "default_id":
                if (
                  formObj[validationArray[i]] &&
                  /^[a-zA-Z0-9-]+$/.test(formObj[validationArray[i]])
                ) {
                  const index = getFormFieldIndex(
                    formValidatorObj.invalidationArray,
                    `${validationArray[i]}`
                  );
                  if (index !== -1) {
                    formValidatorObj.invalidationArray.splice(index, 1);
                  }
                } else {
                  // checking for bulk plover feature
                  bulkPlover.isBulkPlover
                    ? (message = !formObj[validationArray[i]]
                        ? `Sheet Row ${bulkPlover.currentRow} - ${
                            formFieldMappingObj[validationArray[i]]
                          } cannot be left blank`
                        : `Sheet Row ${bulkPlover.currentRow} - ${
                            formFieldMappingObj[validationArray[i]]
                          } is in an invalid format. Only letters, digits, and dash (-) characters are accepted.'`)
                    : (message = !formObj[validationArray[i]]
                        ? `${formFieldMappingObj[validationArray[i]]} cannot be left blank`
                        : `${
                            formFieldMappingObj[validationArray[i]]
                          } is in an invalid format. Only letters, digits, and dash (-) characters are accepted.'`);
                  formValidatorObj.invalidationArray.push(message);
                }
                break;
              default:
                if (formObj[validationArray[i]]) {
                  const index = getFormFieldIndex(
                    formValidatorObj.invalidationArray,
                    `${validationArray[i]}`
                  );
                  if (index !== -1) {
                    formValidatorObj.invalidationArray.splice(index, 1);
                  }

                  removeInvalidClass(validationArray[i], "id");
                } else {
                  formValidatorObj.invalidationArray.push(
                    bulkPlover.isBulkPlover
                      ? `Sheet Row ${bulkPlover.currentRow} - ${
                          formFieldMappingObj[validationArray[i]]
                        } cannot be left blank`
                      : `${formFieldMappingObj[validationArray[i]]} cannot be left blank`
                  );

                  if (!document.getElementById(validationArray[i])?.classList.contains("invalid")) {
                    addInvalidClass(validationArray[i], "id");
                  }
                }
            }
        }
    }
  }

  return formValidatorObj.invalidationArray;
};
