import "./About.scss";
import { IncPlaybookSectionBox, PAGE_PREFIX } from "../IncrementalityPlaybook";
import { playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";

const About = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              This is a codification of our portfolio of methods for evaluating the incremental
              impact of media investments and tactics. This will serve as a playbook, spanning all
              channels, of experimental and quasi-experimental options for testing and measuring
              incrementality. This master playbook will standardize and govern the most appropriate
              methods by channel and/or platform.
            </div>
            <br />
            <br />
            <div className="basicHeader">The Playbook Impact:</div>
            <ul className="basicList">
              <br />
              <li>
                As deterministic signals erode, experimental design allows us to more confidently
                measure and quantify the lift attributable to media.
              </li>
              <li>
                To encourage clients to use incrementality studies to calibrate channel performance
                and media investment allocation, and to deliver this solution with high quality and
                consistency, at low cost.
              </li>
              <li>
                To reduce cost of execution and to drive consistency and repeatability in approach.
              </li>
            </ul>
            <br />
            <br />
            <div className="basicText">
              For more information on this type of measurement refer to{" "}
              <a href={`${PAGE_PREFIX}/Incrementality101`}> Incrementality 101.</a>For any general
              feedback or feature requests, we encourage you to share your thoughts on our dedicated{" "}
              <a href="https://tinuiti.slack.com/archives/C05HHT6JETE">Slack channel.</a>
            </div>
          </div>
        }
        title={"About the Incrementality Playbook"}
      />
      <IncPlaybookSectionBox
        content={<div className="mainTable">{playbookTable()}</div>}
        title={"Incrementality Testing Approaches"}
      />
    </div>
  );
});

export default About;
