import * as Contract from '@tableau/api-external-contract-js';
import {
  CustomViewInfoModel,
  EmbeddingBootstrapInfo,
  EmbeddingSheetInfo,
  NotificationId,
  SheetPath,
  VisualId,
} from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  CustomViewImpl,
  DashboardImpl,
  ErrorHelpers,
  ParameterImpl,
  ParametersService,
  ServiceNames,
  SheetImpl,
  SheetInfoImpl,
  SheetUtils,
  StoryImpl,
  TableauError,
  VizService,
  WorkbookImpl,
  WorksheetImpl,
} from '@tableau/api-shared-js';
import { EmbeddingServiceNames, ToolbarService } from '../Services';

export class EmbeddingWorkbookImpl extends WorkbookImpl {
  private _name: string;
  private _publishedSheetsInfo: Array<SheetInfoImpl> = [];
  private _activeSheetImpl: SheetImpl;
  private _canDownloadWorkbook: boolean;
  private _pendingTabSwitchPromise: { resolve: (response: WorkbookImpl) => void; reject: (error: any) => void };
  private _pendingShowCustomViewPromise:
    | { resolve: (response: CustomViewImpl | undefined) => void; reject: (error: any) => void }
    | undefined;
  private _customViews: Map<string, CustomViewImpl> = new Map<string, CustomViewImpl>();
  private _currentCustomView: CustomViewImpl | undefined;
  public constructor(bootstrapInfo: EmbeddingBootstrapInfo, private _registryId: number) {
    super();
    this._name = bootstrapInfo.workbookName;
    this._canDownloadWorkbook = bootstrapInfo.canDownloadWorkbook;
    this.initializeWorkbook(bootstrapInfo);
  }

  public get activeSheet(): SheetImpl {
    return this._activeSheetImpl;
  }

  public get publishedSheetsInfo(): Array<SheetInfoImpl> {
    return this._publishedSheetsInfo;
  }

  public get name(): string {
    return this._name;
  }

  public get canDownloadWorkbook(): boolean {
    return this._canDownloadWorkbook;
  }

  public get pendingTabSwitchPromise() {
    return this._pendingTabSwitchPromise;
  }

  public get pendingShowCustomViewPromise() {
    return this._pendingShowCustomViewPromise;
  }

  public clearPendingShowCustomViewPromise() {
    this._pendingShowCustomViewPromise = undefined;
  }

  public get activeCustomView() {
    return this._currentCustomView;
  }

  public activateSheetAsync(sheetNameOrIndex: string | number): Promise<WorkbookImpl> {
    ErrorHelpers.verifyParameter(sheetNameOrIndex, 'sheetNameOrIndex');

    let sheetName = this.convertSheetIndexToSheetName(sheetNameOrIndex);
    ErrorHelpers.verifyParameterType(sheetName, 'string', 'sheetNameOrIndex');

    if (!this.validatePublishedSheet(sheetName)) {
      this.verifyDashboardSheets(sheetName);
    }

    // Check to see if the sheet is already active.
    if (this._activeSheetImpl && sheetName === this._activeSheetImpl.name) {
      return new Promise<WorkbookImpl>((resolve, reject) => {
        resolve(this);
      });
    }

    const service = ApiServiceRegistry.get(this._registryId).getService<VizService>(ServiceNames.Viz);
    service.activateSheetAsync(sheetName);

    let promise = new Promise<WorkbookImpl>((resolve, reject) => {
      // `this._pendingTabSwitchPromise` will be resolved when `TabSwitchedEvent` is fired.
      this._pendingTabSwitchPromise = { resolve: resolve, reject: reject };
    });
    return promise;
  }

  public getParametersAsync(): Promise<Array<ParameterImpl>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<ParametersService>(ServiceNames.Parameters);
    return service.getAllParametersAsync();
  }

  public async changeParameterValueAsync(name: string, value: string | number | boolean | Date): Promise<ParameterImpl | undefined> {
    ErrorHelpers.verifyParameter(name, 'parameterName');

    const service = ApiServiceRegistry.get(this._registryId).getService<ParametersService>(ServiceNames.Parameters);
    const parameter = await service.findParameterByNameAsync(name);
    if (parameter) {
      return parameter.changeValueAsync(value).then(() => {
        return parameter;
      });
    } else {
      return undefined;
    }
  }

  public updateExistingActiveSheetReferences(newSheetName: string) {
    if (this._activeSheetImpl) {
      // No need to do anything if we're already on the current sheet.
      if (this._activeSheetImpl.name === newSheetName) {
        return;
      }

      this._activeSheetImpl.active = false;

      this._publishedSheetsInfo.forEach((sheetInfo: SheetInfoImpl, index: number) => {
        if (sheetInfo.name === this._activeSheetImpl.name) {
          sheetInfo.active = false;
        }
      });
    }
  }

  /**
   *
   * This method is responsible for processing custom views from Tableau. It does two things:
   * First, finds out what's the diff between our local cache & the incoming set of custom views depending on the notification
   * Then, updates the local cache
   *
   * @param customViewNotification
   * @param customViewsInfo
   * @returns The updated {@link CustomViewImpl}
   */
  public processCustomViews(customViewNotification: NotificationId, customViewsInfo: CustomViewInfoModel): Array<CustomViewImpl> {
    const currentCustomViewLuid = customViewsInfo.currentView?.luid;
    let updatedCustomViews: Array<CustomViewImpl> = [];

    // For CustomViewsLoaded, set the updatedCustomView to the currently active one
    // For CustomViewRemoved, CustomViewSaved & CustomViewSetDefault find the corresponding one from the cache
    // before updating the cache with the incoming set
    switch (customViewNotification) {
      case NotificationId.CustomViewsLoaded:
      case NotificationId.CustomViewSaved: {
        this.refreshCustomViewCache(customViewsInfo);
        if (currentCustomViewLuid) {
          const customView = this._customViews.get(currentCustomViewLuid);
          if (customView) {
            updatedCustomViews.push(customView);
          }
        }
        break;
      }
      case NotificationId.CustomViewRemoved: {
        let oldCustomViews: Map<string, CustomViewImpl> = new Map(this._customViews);
        this._customViews.clear();
        customViewsInfo.customViewsList.map((customView) => {
          this._customViews.set(customView.luid, new CustomViewImpl(customView, this._registryId));
          oldCustomViews.delete(customView.luid);
        });

        for (let removedCustomView of oldCustomViews.values()) {
          updatedCustomViews.push(removedCustomView);
        }
        break;
      }
      case NotificationId.CustomViewSetDefault: {
        this.refreshCustomViewCache(customViewsInfo);
        const defaultCustomView = customViewsInfo.customViewsList.find((customView) => customView.isDefault);
        if (defaultCustomView) {
          updatedCustomViews.push(new CustomViewImpl(defaultCustomView, this._registryId));
        }
        break;
      }
    }

    // If there's an active custom view, update currentCustomView else clear it
    this._currentCustomView = currentCustomViewLuid ? this._customViews.get(currentCustomViewLuid) : undefined;

    return updatedCustomViews;
  }

  private refreshCustomViewCache(customViewsInfo: CustomViewInfoModel) {
    this._customViews.clear();
    customViewsInfo.customViewsList.map((customView) => {
      this._customViews.set(customView.luid, new CustomViewImpl(customView, this._registryId));
    });
  }

  public revertAllAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this._registryId).getService<ToolbarService>(EmbeddingServiceNames.ToolbarService);
    return service.revertAllAsync();
  }

  public getCustomViewsAsync(): Promise<Array<CustomViewImpl>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<VizService>(ServiceNames.Viz);
    return service.getCustomViewsAsync();
  }

  public showCustomViewAsync(customViewName?: string | null): Promise<CustomViewImpl | undefined> {
    const service = ApiServiceRegistry.get(this._registryId).getService<VizService>(ServiceNames.Viz);

    service.showCustomViewAsync(customViewName);

    let promise = new Promise<CustomViewImpl | undefined>((resolve, reject) => {
      // `this._pendingShowCustomViewPromise` will be resolved when `CustomViewsLoadedEvent` is fired.
      this._pendingShowCustomViewPromise = { resolve: resolve, reject: reject };
    });

    return promise;
  }

  public removeCustomViewAsync(customViewName: string): Promise<CustomViewImpl> {
    const service = ApiServiceRegistry.get(this._registryId).getService<VizService>(ServiceNames.Viz);
    return service.removeCustomViewAsync(customViewName);
  }

  public saveCustomViewAsync(customViewName: string): Promise<CustomViewImpl> {
    const service = ApiServiceRegistry.get(this._registryId).getService<VizService>(ServiceNames.Viz);
    return service.saveCustomViewAsync(customViewName);
  }

  public setActiveCustomViewAsDefaultAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this._registryId).getService<VizService>(ServiceNames.Viz);
    return service.setActiveCustomViewAsDefaultAsync();
  }

  private initializeWorkbook(bootstrapInfo: EmbeddingBootstrapInfo): void {
    bootstrapInfo.publishedSheets.forEach((publishedSheet: EmbeddingSheetInfo, index: number) => {
      const sheetSize: Contract.SheetSize = SheetUtils.getSheetSizeFromSizeConstraints(publishedSheet.sizeConstraint);

      const isActive = publishedSheet.name === bootstrapInfo.currWorksheetName;

      // Published sheets are not hidden
      const isHidden = false;
      const sheetType = SheetUtils.getSheetTypeEnum(publishedSheet.sheetType);

      const sheetInfoImpl: SheetInfoImpl = new SheetInfoImpl(
        publishedSheet.name,
        sheetType,
        sheetSize,
        index,
        isActive,
        isHidden,
        publishedSheet.url,
      );

      this._publishedSheetsInfo.push(sheetInfoImpl);
      if (isActive) {
        switch (sheetInfoImpl.sheetType) {
          case Contract.SheetType.Worksheet: {
            const vizId: VisualId = {
              worksheet: sheetInfoImpl.name,
            };

            this._activeSheetImpl = new WorksheetImpl(sheetInfoImpl, this._registryId, vizId, null, null);
            break;
          }
          case Contract.SheetType.Dashboard: {
            const sheetPath: SheetPath = {
              sheetName: sheetInfoImpl.name,
              isDashboard: true,
            };

            this._activeSheetImpl = new DashboardImpl(sheetInfoImpl, bootstrapInfo.dashboardZones, sheetPath, this._registryId, null);
            break;
          }
          case Contract.SheetType.Story: {
            if (!bootstrapInfo.story) {
              throw new TableauError(Contract.SharedErrorCodes.ServerError, 'Unable to receive story information from Tableau');
            }

            this._activeSheetImpl = new StoryImpl(sheetInfoImpl, bootstrapInfo.story, bootstrapInfo.publishedSheets, this._registryId);
            break;
          }
          default: {
            throw new TableauError(Contract.SharedErrorCodes.ServerError, 'Invalid SheetType');
          }
        }
      }
    });
  }

  private convertSheetIndexToSheetName(sheetNameOrIndex: string | number): string {
    if (typeof sheetNameOrIndex == 'number') {
      const sheetIndex = sheetNameOrIndex;
      if (this.publishedSheetsInfo[sheetIndex]) {
        return this.publishedSheetsInfo[sheetIndex].name;
      } else {
        throw new TableauError(Contract.EmbeddingErrorCodes.IndexOutOfRange, `Index ${sheetIndex} is out of range.`);
      }
    }
    return sheetNameOrIndex;
  }

  private validatePublishedSheet(sheetName: string): boolean {
    const found = this.publishedSheetsInfo.find((sheetInfo) => sheetInfo.name === sheetName);
    return found !== undefined;
  }

  private verifyDashboardSheets(sheetName: string): void {
    if (this._activeSheetImpl.sheetType === Contract.SheetType.Dashboard) {
      let activeSheet = this._activeSheetImpl as DashboardImpl;
      let index = activeSheet.worksheetsImpl.findIndex((worksheetImpl) => {
        return worksheetImpl.name === sheetName;
      });
      if (index !== -1) {
        if (activeSheet.worksheetsImpl[index].hidden) {
          throw new TableauError(Contract.SharedErrorCodes.ServerError, 'Cannot activate hidden sheet');
        }
        return;
      }
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.SheetNotInWorkbook, 'Sheet is not found in Workbook');
  }
}
