import "./TagCreationForm.scss";
import { AdServer, TagData } from "./utils";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { DATE_FORMAT, TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { DatePicker, Dropdown } from "../../Components";
import { DeviceNames, hasDeviceTags, hasOSTags, OSNames } from "../utils";
import { StateSetter } from "../../utils/types";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface TagCreationFormProps {
  tag: TagData;
  setTag: StateSetter<TagData>;
  onSubmit: any;
  flashtalkingCampaigns?: { campaign_id: number; campaign: string; is_managed_service: boolean }[];
}

export interface FormData {
  adServer: AdServer;
  deviceMap?: Record<string, boolean>;
  osMap?: Record<string, boolean>;
  lengthMap?: Record<string, boolean>;
  vendor?: string;
  campaignID?: number;
  startDate?: string;
  endDate?: string;
  placementType?: "" | "Audio" | "Pixel" | "VAST";
  transcodeVendor?: string;
}

// Only allow the DSP's vendor as an option if it's an RTB placement.
const DSP_TO_VENDOR_MAP: Record<string, string> = {
  Amazon: "Amazon.com",
  Beeswax: "Beeswax",
  Yahoo: "Yahoo!",
  Xandr: "Xandr",
  TradeDesk: "The Trade Desk (Ad Serving Only)",
  "Roku OneView": "Roku OneView (formerly DataXu)",
  Amobee: "Amobee",
  Nexxen: "Nexxen",
};

export const FLASHTALKING_DSP_TO_VENDOR_MAP: Record<string, string> = {
  // "Roku OneView": "Roku",
  Amazon: "Amazon",
  // Amobee: "Amobee",
  Nexxen: "Nexxen",
  TradeDesk: "The Trade Desk",
  // Xandr: "Xandr",
  Yahoo: "Yahoo",
  // YouTube: "YouTube", TODO: Add this back when we have certified YouTube
};

export const TagCreationForm = ({
  tag,
  setTag,
  onSubmit,
  flashtalkingCampaigns = [],
}: TagCreationFormProps): JSX.Element => {
  const [deviceMap, setDeviceMap] = useState({
    [DeviceNames.MOBILE]: true,
    [DeviceNames.CTV]: true,
    [DeviceNames.DESKTOP]: true,
  });
  let hasValidDevices = Object.values(deviceMap).includes(true);

  const [osMap, setOsMap] = useState({
    [OSNames.ANDROID]: true,
    [OSNames.IOS]: true,
    [OSNames.DESKTOP]: true,
  });
  let hasValidOSes = Object.values(osMap).includes(true);

  const [lengthMap, setLengthMap] = useState({
    "15": false,
    "30": false,
    "60": false,
  });
  let hasValidLengths = Object.values(lengthMap).includes(true);

  const [adServer, setAdServer] = useState<AdServer>("Extreme Reach");

  const vendorOptions = useMemo(() => {
    if (tag.dsp) {
      if (
        tag.dsp &&
        ((adServer === "Flashtalking" && R.isNil(FLASHTALKING_DSP_TO_VENDOR_MAP[tag.dsp])) ||
          (adServer === "Extreme Reach" && R.isNil(DSP_TO_VENDOR_MAP[tag.dsp])))
      ) {
        return [];
      }
      return adServer === "Flashtalking"
        ? [FLASHTALKING_DSP_TO_VENDOR_MAP[tag.dsp], ...R.defaultTo([], tag.flashtalking_vendors)]
        : [DSP_TO_VENDOR_MAP[tag.dsp]];
    }
    if (adServer === "Flashtalking") {
      return tag.flashtalking_vendors ? [...tag.flashtalking_vendors] : [];
    }
    return R.uniq(R.defaultTo([], tag.vendorOptions));
  }, [adServer, tag]);

  let hasOnlyOneVendor = R.length(vendorOptions || []) === 1;
  const [vendor, setVendor] = useState<string>(hasOnlyOneVendor ? vendorOptions[0] : "");
  const [transcodeVendor, setTranscodeVendor] = useState<string>("");
  const [campaignID, setCampaignID] = useState<number>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [placementType, setPlacementType] = useState<"" | "Audio" | "Pixel" | "VAST">("");

  const placementTypeOptions: any = useMemo(() => {
    const defaultOptions = {
      audio_vast: { label: "Audio VAST", value: "Audio" },
      pixel: { label: "Pixel", value: "Pixel" },
      video_vast: { label: "Video VAST", value: "VAST" },
    };
    if (vendor && adServer === "Flashtalking") {
      for (const vendorOption of vendorOptions) {
        if (vendor === (vendorOption as any).name) {
          const options: { label: string; value: string }[] = [];
          for (const type of (vendorOption as any).placement_types) {
            if (defaultOptions[type]) {
              options.push(defaultOptions[type]);
            }
          }
          return options;
        }
      }
      return R.values(defaultOptions);
    }
    return R.values(defaultOptions);
  }, [adServer, vendor, vendorOptions]);

  useEffect(() => {
    if (vendorOptions && vendorOptions.length === 1) {
      setVendor(
        typeof vendorOptions[0] === "string" ? vendorOptions[0] : (vendorOptions[0] as any).name
      );
    }
  }, [vendorOptions]);

  const campaignMap = useMemo(() => {
    const campaignMap = {};
    for (const campaign of flashtalkingCampaigns) {
      campaignMap[campaign.campaign] = campaign.campaign_id;
    }
    if (flashtalkingCampaigns.length === 1) {
      setCampaignID(flashtalkingCampaigns[0].campaign_id);
    }
    return campaignMap;
  }, [flashtalkingCampaigns]);

  const [formFail, setFormFail] = useState(false);

  const constructFormData = useCallback(() => {
    const formData: FormData = {
      adServer: "Extreme Reach",
    };

    if (hasDeviceTags(tag)) {
      formData.deviceMap = deviceMap;
    }
    if (hasOSTags(tag)) {
      formData.osMap = osMap;
    }
    if (tag.splitLength) {
      formData.lengthMap = lengthMap;
    }
    formData.vendor = vendor;
    formData.adServer = adServer;
    formData.campaignID = campaignID;
    formData.startDate = startDate;
    formData.endDate = endDate;
    formData.placementType = placementType;
    formData.transcodeVendor = transcodeVendor;
    return formData;
  }, [
    tag,
    vendor,
    adServer,
    campaignID,
    startDate,
    endDate,
    placementType,
    deviceMap,
    osMap,
    lengthMap,
    transcodeVendor,
  ]);

  const updateTagWithFormData = useCallback(() => {
    const formData = constructFormData();
    let updatedTag = tag;

    if (formData.deviceMap) {
      updatedTag.deviceMap = formData.deviceMap;
    }
    if (formData.osMap) {
      updatedTag.osMap = formData.osMap;
    }
    if (formData.lengthMap) {
      updatedTag.lengthMap = formData.lengthMap;
    }
    updatedTag.vendor = formData.vendor;
    updatedTag.adServer = formData.adServer;
    updatedTag.campaignID = formData.campaignID;
    updatedTag.startDate = formData.startDate;
    updatedTag.endDate = formData.endDate;
    updatedTag.placementType = formData.placementType;
    updatedTag.transcodeVendor = formData.transcodeVendor;
    setTag(updatedTag);
  }, [constructFormData, setTag, tag]);

  const attemptSubmit = useCallback(() => {
    updateTagWithFormData();
    let isValid =
      hasValidDevices &&
      hasValidOSes &&
      (tag.splitLength ? hasValidLengths : true) &&
      vendor.length &&
      (adServer !== "Flashtalking" || (adServer === "Flashtalking" && campaignID && placementType));
    if (isValid) {
      onSubmit();
      setFormFail(false);
    } else {
      setFormFail(true);
    }
  }, [
    updateTagWithFormData,
    hasValidDevices,
    hasValidOSes,
    tag.splitLength,
    hasValidLengths,
    vendor.length,
    adServer,
    campaignID,
    placementType,
    onSubmit,
  ]);

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title className="modalHeader">
          <div>{`Create Tags for ${tag.derivedID}`}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="controls">
          {hasDeviceTags(tag) && (
            <div className="selectionDiv">
              <div className="selectionTitle">Devices:</div>
              <ButtonGroup>
                {[DeviceNames.MOBILE, DeviceNames.CTV, DeviceNames.DESKTOP].map(device => (
                  <Button
                    key={device}
                    size="sm"
                    variant={`${deviceMap[device] ? "" : "outline-"}${
                      formFail && !hasValidDevices ? "danger" : "primary"
                    }`}
                    onClick={() =>
                      setDeviceMap({
                        ...deviceMap,
                        [device]: !deviceMap[device],
                      })
                    }
                  >
                    {device}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          )}
          {hasOSTags(tag) && (
            <div className="selectionDiv">
              <div className="selectionTitle">OSes:</div>
              <ButtonGroup>
                {[OSNames.ANDROID, OSNames.IOS, OSNames.DESKTOP].map(os => (
                  <Button
                    key={os}
                    size="sm"
                    variant={`${osMap[os] ? "" : "outline-"}${
                      formFail && !hasValidOSes ? "danger" : "primary"
                    }`}
                    onClick={() =>
                      setOsMap({
                        ...osMap,
                        [os]: !osMap[os],
                      })
                    }
                  >
                    {os}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          )}
          {tag.splitLength && (
            <div className="selectionDiv">
              <div className="selectionTitle">Lengths:</div>
              <ButtonGroup>
                {["15", "30", "60"].map(length => (
                  <Button
                    key={length}
                    size="sm"
                    variant={`${lengthMap[length] ? "" : "outline-"}${
                      formFail && !hasValidLengths ? "danger" : "primary"
                    }`}
                    onClick={() =>
                      setLengthMap({
                        ...lengthMap,
                        [length]: !lengthMap[length],
                      })
                    }
                  >
                    {length}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          )}
          <div className="selectionDiv">
            <div className="selectionTitle">Ad Server:</div>
            <Form.Control
              as="select"
              size="sm"
              value={adServer}
              onChange={e => {
                setAdServer(e.target.value as AdServer);
                setVendor("");
              }}
            >
              {["Extreme Reach", "Flashtalking"].map(vendor => (
                <option key={vendor}>{vendor}</option>
              ))}
            </Form.Control>
          </div>
          {campaignMap && adServer === "Flashtalking" && (
            <div className="selectionDiv">
              <div className="selectionTitle">Campaign:</div>
              {flashtalkingCampaigns ? (
                <Dropdown
                  onChange={e => setCampaignID(parseInt(e))}
                  options={R.map(
                    campaign => ({
                      label: `${campaign.campaign} (${campaign.campaign_id})`,
                      value: `${campaign.campaign_id}`,
                    }),
                    R.filter(campaign => !campaign.is_managed_service, flashtalkingCampaigns) as {
                      campaign_id: number;
                      campaign: string;
                    }[]
                  )}
                  size="sm"
                  value={`${campaignID ? campaignID : ""}` as string}
                />
              ) : (
                <a
                  href={`/streaming/networks/${tag.network}`}
                  className={formFail ? "invalid" : ""}
                >
                  Add campaigns for Flashtalking
                </a>
              )}
            </div>
          )}
          {campaignMap && adServer === "Flashtalking" && (
            <div className="selectionDiv">
              <div className="selectionTitle">Start Date and End Date:</div>
              <DatePicker
                range={{
                  start: R.defaultTo(TODAY, startDate),
                  end: R.defaultTo(
                    `${Dfns.format(
                      DATE_FORMAT,
                      new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                    )}`,
                    endDate
                  ),
                }}
                onChange={newDates => {
                  if (newDates.start && newDates.end) {
                    setStartDate(newDates.start);
                    setEndDate(newDates.end);
                  }
                }}
                isOutsideRange={() => false}
              />
            </div>
          )}
          {campaignMap && adServer === "Flashtalking" && (
            <div className="selectionDiv">
              <div className="selectionTitle">Placement Type:</div>
              <Dropdown
                onChange={e => setPlacementType(e as "Audio" | "Pixel" | "VAST")}
                options={placementTypeOptions}
                size="sm"
                value={placementType}
              />
            </div>
          )}
          <div className="selectionDiv">
            <div className="selectionTitle">Vendor:</div>
            {vendorOptions ? (
              <Dropdown
                onChange={e => setVendor(e)}
                options={R.filter(option => !R.isNil(option), vendorOptions).map((vendor: any) => ({
                  label: typeof vendor === "string" ? vendor : vendor.pretty_name,
                  value: typeof vendor === "string" ? vendor : vendor.name,
                }))}
                size="sm"
                value={vendor}
              />
            ) : (
              <a href={`/streaming/networks/${tag.network}`} className={formFail ? "invalid" : ""}>
                Add vendors for {tag.network}
              </a>
            )}
          </div>
          {placementType !== "Pixel" &&
            R.values(FLASHTALKING_DSP_TO_VENDOR_MAP).includes(vendor) &&
            adServer === "Flashtalking" && (
              <div className="selectionDiv">
                <div className="selectionTitle">Transcode Vendor (For Nexxen, other dsp's):</div>
                {tag.flashtalking_vendors ? (
                  <Dropdown
                    onChange={e => setTranscodeVendor(e)}
                    options={tag.flashtalking_vendors.map((vendor: any) => ({
                      label: typeof vendor === "string" ? vendor : vendor.pretty_name,
                      value: typeof vendor === "string" ? vendor : vendor.name,
                    }))}
                    size="sm"
                    value={transcodeVendor}
                  />
                ) : (
                  <a
                    href={`/streaming/networks/${tag.network}`}
                    className={formFail ? "invalid" : ""}
                  >
                    Add vendors for {tag.network}
                  </a>
                )}
              </div>
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={attemptSubmit}>Next</Button>
      </Modal.Footer>
    </div>
  );
};
