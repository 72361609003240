import { useEffect, useState } from "react";
import { Input } from "../Components";
import "./ToplineNumber.scss";

interface ToplineNumberProps {
  label: string;
  value?: number;
  formatter?: (value: number | null) => string;
  editable?: boolean;
  onEdit?: (value: number) => {} | void;
}

export const ToplineNumber: React.FC<ToplineNumberProps> = ({
  label,
  value,
  formatter,
  editable,
  onEdit,
}) => {
  const [editedValue, setEditedValue] = useState<number>(parseFloat((value || 0).toFixed(2)));

  useEffect(() => {
    if (onEdit) {
      onEdit(editedValue);
    }
  }, [editedValue, onEdit]);

  return (
    <div className="youTubePlanToplineNumber">
      <div className="youTubePlanToplineNumberLabel">{label}</div>
      {editable && onEdit ? (
        <Input
          type="number"
          className="valueInput"
          placeholder={`Input ${label}`}
          value={editedValue}
          onChange={e => setEditedValue(e.target.valueAsNumber)}
        />
      ) : (
        <div className="youTubePlanToplineNumberValue">
          {formatter ? formatter(value || null) : value}
        </div>
      )}
    </div>
  );
};

export default ToplineNumber;
