import React from "react";
import { amcClient } from "../../Cache";
import { useQuery } from "@apollo/client";
import TableProgressBar from "../../TableProgressBar";
import {
  GET_MAX_GEO,
  maxDPVRate,
  maxConversionRate,
  maxGeoNtbRepeatRate,
  maxPurchaseRate,
} from "../../ReactiveVariables";
import { abbreviateNumber } from "../../../utils/format-utils";

const ProgressBarRenderer = (value: number, maxCompleted: number, type: string): JSX.Element => {
  useQuery(GET_MAX_GEO, { client: amcClient });

  return <TableProgressBar label={`${value}%`} maxCompleted={maxCompleted} value={value} />;
};
interface TableData {
  state: string;
  impressions: number;
  reach: number;
  dpv: number;
  customersConverted: number;
  orders: number;
  revenue: number;
  ntbCustomers: number;
  dpvRate: number;
  conversionRate: number;
  ntbRepeatRate: number;
  ntbPurchaseRate: number;
}

export const geoTableHeaders = [
  {
    name: "state",
    label: "State",
    width: 135,
  },
  {
    name: "impressions",
    label: "Impressions",
    flex: 1,
    minFlexWidth: 110,
    renderer: (data: TableData): string => abbreviateNumber(data.impressions),
  },
  {
    name: "reach",
    label: "Reach",
    flex: 1,
    minFlexWidth: 110,
    renderer: (data: TableData): string => abbreviateNumber(data.reach),
  },
  {
    name: "dpv",
    label: "DPV",
    flex: 1,

    renderer: (data: TableData): string => abbreviateNumber(data.dpv),
    width: 110,
  },
  {
    name: "customersConverted",
    label: "Customers Converted",
    flex: 1,
    minFlexWidth: 150,
    renderer: (data: TableData): string => abbreviateNumber(data.customersConverted),
  },
  {
    name: "orders",
    label: "Orders",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): string => abbreviateNumber(data.orders),
  },
  {
    name: "revenue",
    label: "Revenue",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): string => abbreviateNumber(data.revenue),
  },
  {
    name: "ntbCustomers",
    label: "NTB Customers",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): string => abbreviateNumber(data.ntbCustomers),
  },
  {
    name: "dpvRate",
    label: "DPVR",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.dpvRate, maxDPVRate(), "dpvRate"),
  },
  {
    name: "conversionRate",
    label: "Conversion Rate",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.conversionRate, maxConversionRate(), "conversionRate"),
  },
  {
    name: "ntbRepeatRate",
    label: "NTB Repeat Rate",
    flex: 1,
    minFlexWidth: 141,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.ntbRepeatRate, maxGeoNtbRepeatRate(), "ntbRepeatRate"),
  },
  {
    name: "ntbPurchaseRate",
    label: "NTB Purchase Rate",
    flex: 1,
    minFlexWidth: 150,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.ntbPurchaseRate, maxPurchaseRate(), "ntbPurchaseRate"),
  },
];
