import "./CustomerInsightsChart.scss";
import { RefObject, useMemo, useRef, useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import cn from "classnames";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import { formatNumber, formatPercent } from "../utils/format-utils";
import ChartContainer from "../Components/ChartContainer";
import { buildColorMap, getChartType } from "./customerInsightsUtils";
import {
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
} from "./customerInsightsConstants";
import { CustomerInsightsLegend } from "./CustomerInsightsGroup";

interface DefaultBarProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
}

const DefaultBar: React.FC<DefaultBarProps> = ({ data, fieldPrettyName }) => {
  const [hoveredValue, setHoveredValue] = useState<string | undefined>();
  const barMax = Math.ceil(Math.max(...data.map(val => val.clientPopulation)));
  const colorMap = useMemo(() => buildColorMap(data), [data]);

  const barFormattedData = useMemo(() => {
    return data.map(({ clientPopulation, clientPopulationFraction, levels }) => ({
      name: levels,
      val1: clientPopulation,
      computedValue: clientPopulationFraction,
    }));
  }, [data]);

  const legendFormattedData = useMemo(() => {
    return barFormattedData.map(({ name, val1, computedValue }) => ({
      name,
      val1: formatNumber(Math.round(val1)),
      computedValue: formatPercent(computedValue, 1),
    }));
  }, [barFormattedData]);

  return (
    <div className="customerInsightsBarChart customerBaseBarChart">
      <ChartContainer title={fieldPrettyName}>
        <ResponsiveContainer width="100%" height={barFormattedData.length * 49}>
          <BarChart data={barFormattedData} layout="vertical">
            <XAxis dataKey="val1" type="number" domain={[0, barMax]} hide />
            <YAxis dataKey="name" type="category" hide />
            <Legend
              content={() => (
                <CustomerInsightsLegend
                  data={legendFormattedData}
                  hoveredValue={hoveredValue}
                  setHoveredValue={setHoveredValue}
                />
              )}
              layout="vertical"
              align="left"
              verticalAlign="top"
            />
            <Bar
              dataKey="val1"
              stackId="a"
              isAnimationActive={false}
              onMouseEnter={bar => {
                setHoveredValue(bar?.name);
              }}
              onMouseLeave={() => {
                setHoveredValue(undefined);
              }}
            >
              {barFormattedData.map(({ name }) => (
                <Cell
                  className={cn("barCell", {
                    hoveredValue: name === hoveredValue,
                    unhoveredValue: Boolean(hoveredValue) && name !== hoveredValue,
                  })}
                  key={name}
                  fill={colorMap[name]}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

interface DefaultPieProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
}

const DefaultPie: React.FC<DefaultPieProps> = ({ data, fieldPrettyName }) => {
  const [hoveredValue, setHoveredValue] = useState<string | undefined>();
  const pieRef: RefObject<HTMLDivElement> = useRef(null);
  const colorMap = useMemo(() => buildColorMap(data), [data]);

  const pieFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulation }) => {
      return {
        name: levels,
        value: Math.round(clientPopulation),
      };
    });
  }, [data]);

  const legendFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulation, clientPopulationFraction }) => {
      return {
        name: levels,
        val1: formatNumber(clientPopulation, 0),
        computedValue: formatPercent(clientPopulationFraction, 1),
        color: colorMap[levels],
      };
    });
  }, [colorMap, data]);

  return (
    <div className="customerInsightsPieChart customerBasePieChart" ref={pieRef}>
      <ChartContainer title={fieldPrettyName}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Legend
              content={() => (
                <CustomerInsightsLegend
                  data={legendFormattedData}
                  hoveredValue={hoveredValue}
                  setHoveredValue={setHoveredValue}
                />
              )}
              layout="vertical"
              align="left"
              verticalAlign="top"
            />
            <Pie
              data={pieFormattedData}
              isAnimationActive={false}
              startAngle={90}
              endAngle={-270}
              cx="50%"
              cy="50%"
              innerRadius="25%"
              dataKey="value"
              onMouseEnter={slice => {
                setHoveredValue(slice?.name);
              }}
              onMouseLeave={() => {
                setHoveredValue(undefined);
              }}
            >
              {pieFormattedData.map(({ name }) => (
                <Cell
                  className={cn("pieCell", {
                    hoveredValue: name === hoveredValue,
                    unhoveredValue: Boolean(hoveredValue) && name !== hoveredValue,
                  })}
                  key={`cell-${name}`}
                  fill={colorMap[name]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

interface CustomerBaseChartProps {
  fieldName: CustomerInsightsFields;
  data: KnowYourCustomerData[];
}

const CustomerBaseChart: React.FC<CustomerBaseChartProps> = ({ fieldName, data }) => {
  const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[fieldName];

  if (getChartType(fieldName) === "bar") {
    return <DefaultBar data={data} fieldPrettyName={fieldPrettyName} />;
  }

  if (getChartType(fieldName) === "pie") {
    return <DefaultPie data={data} fieldPrettyName={fieldPrettyName} />;
  }

  return null;
};

export default CustomerBaseChart;
