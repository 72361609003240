import React, { useState, useCallback } from "react";

import * as R from "ramda";

import { Router } from "@reach/router";

import { MENU_KEY, WORKSPACE_KEY } from "./StreamingCreatives";

import Menu from "./Menu";
import Workspace from "./Workspace";
import ViewPlacement from "./ViewPlacement";
import SideCart from "./SideCart";

export const NonCartContext = React.createContext({});

const NonCart = () => {
  const [viewPlacementId, setViewPlacementId] = useState();

  const [filteredPlacements, setFilteredPlacementsRaw] = useState([]);

  const setFilteredPlacements = useCallback(placements => {
    R.pipe(R.pluck("id"), setFilteredPlacementsRaw)(placements);
  }, []);

  return (
    <NonCartContext.Provider
      value={{
        viewPlacementId,
        setViewPlacementId,
        filteredPlacements,
        setFilteredPlacements,
      }}
    >
      <Router className="nonCart">
        <Menu path={MENU_KEY} />
        <Workspace path={WORKSPACE_KEY} />
        <Menu path="/" />
      </Router>
      <SideCart />
      {Boolean(viewPlacementId) && <ViewPlacement />}
    </NonCartContext.Provider>
  );
};

export default NonCart;
