import "./AMCGraphs.scss";
import React from "react";
import * as Dfns from "date-fns/fp";
import {
  LineChart as LineGraph,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { abbreviateNumber } from "../utils/format-utils";

interface LineChartProps {
  data: {}[];
  dataKey: string;
  lineColor: string;
  tooltipKey: string;
}

const LineChart: React.FC<LineChartProps> = ({ data, dataKey, lineColor, tooltipKey }) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const val = dataKey === "ntbCpa" ? abbreviateNumber(payload[0].value) : payload[0].value;
      return (
        <div className="custom-tooltip">
          <div className="label" style={{ backgroundColor: lineColor }}>{`${tooltipKey}`}</div>
          <div className="description">{`${label}: ${val}`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={230} width="100%">
      <LineGraph
        className="amc-line-chart"
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="date"
          interval={7}
          padding={{ left: 20 }}
          tickFormatter={value => {
            return Dfns.format("MMM dd", new Date(value));
          }}
          tickMargin={5}
        />
        <YAxis />

        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={() => tooltipKey} />
        <Line dataKey={dataKey} dot={false} stroke={lineColor} strokeWidth={3} type="monotone" />
      </LineGraph>
    </ResponsiveContainer>
  );
};

export default LineChart;
