import "./Legend.scss";
import { computeTextColor } from "../utils/colors";
import { Img, OverlayTrigger } from "../Components";
import { MdCheckBox, MdCheckBoxOutlineBlank, MdIndeterminateCheckBox } from "react-icons/md";
import { Tooltip } from "react-bootstrap";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import * as R from "ramda";
import * as uuid from "uuid";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useLocation from "../utils/hooks/useLocation";
import {
  ActiveKeyMap,
  ColorMap,
  CreativeKeyInfo,
  KeyData,
  NetworkKeyInfo,
  SetActiveKeyMap,
  StreamingDeliveryContext,
} from "./StreamingDelivery";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

interface GetLegendInfoProps {
  key: string;
  colorMap: ColorMap;
  info: KeyData;
  dimension: string;
  company?: string;
}

interface LegendInfo {
  color: string;
  logoSrc: string;
  name: string;
  description: string;
  legendClasses: string[];
  legendInnerClasses: string[];
  thumbnailClasses: string[];
}

export const getLegendInfo = ({
  key,
  colorMap,
  info,
  dimension,
  company,
}: GetLegendInfoProps): LegendInfo => {
  const legendClasses = ["legendItem"];
  const legendInnerClasses = ["legendItemInner"];
  const thumbnailClasses = ["legendLabel"];
  let color = colorMap(key);
  let logoSrc = `https://cdn.blisspointmedia.com/networks/${encodeURIComponent(
    key.toUpperCase()
  )}.png`;
  let name = "";
  let description = "";

  switch (dimension) {
    case "creative":
      legendInnerClasses.push("tall");
      thumbnailClasses.push("creativeLabel");
      legendClasses.push("creative");
      {
        const typedInfo = info as CreativeKeyInfo;
        name = `${typedInfo.creative} (${typedInfo.length}s)`;
        description = name;
        if (typedInfo.isci && company) {
          logoSrc = getCreativeThumbnail(company, typedInfo.isci);
        }
      }

      break;
    case "network":
      legendInnerClasses.push("tall");
      color = colorMap(key);
      {
        let typedInfo = info as NetworkKeyInfo;
        ({ description } = typedInfo);
        name = description;
        if (typedInfo.network) {
          logoSrc = `https://cdn.blisspointmedia.com/networks/${encodeURIComponent(
            typedInfo.network.toUpperCase()
          )}.png`;
        }
      }

      break;
    case "network_group":
      description = key;
      break;
    case "daypart":
      thumbnailClasses.push("daypartLabel");
      legendClasses.push("daypart");
      logoSrc = "";
      break;
    case "avail":
      thumbnailClasses.push("availLabel");
      legendClasses.push("avail");
      break;
    default:
      legendInnerClasses.push("tall");
      thumbnailClasses.push("creativeLabel");
      legendClasses.push("creative");
  }
  return {
    color,
    logoSrc,
    name,
    description,
    legendClasses,
    legendInnerClasses,
    thumbnailClasses,
  };
};

interface LegendProps {
  activeKeyMap: ActiveKeyMap;
  setActiveKeyMap: SetActiveKeyMap;
  colorMap: ColorMap;
  dimension: string;
  keyInfo?: Record<string, KeyData>;
}
export default React.memo<LegendProps>(
  ({ activeKeyMap, setActiveKeyMap, colorMap, dimension, keyInfo }) => {
    const getIsMounted = useIsMounted();
    const { company } = useLocation();
    const id = useMemo(() => uuid.v4(), []);
    const { setHoverKey } = useContext(StreamingDeliveryContext);
    const renderToggle = useCallback(() => {
      let allSelected = true;
      let someSelected = false;
      for (let isSelected of R.values(activeKeyMap)) {
        if (isSelected) {
          someSelected = true;
          // If we've already found non-selected items, then no matter what we see next the state
          // will be "partially selected". So we can break the loop
          if (!allSelected) {
            break;
          }
        } else {
          allSelected = false;
        }
      }

      let label = "Select All";
      let icon = <MdCheckBoxOutlineBlank />;
      if (allSelected) {
        label = "Select None";
        icon = <MdCheckBox />;
      } else if (someSelected) {
        icon = <MdIndeterminateCheckBox />;
      }

      return (
        <div
          className="legendToggleAll"
          onClick={() => {
            setActiveKeyMap(allSelected ? "none" : "all");
          }}
        >
          <div className="checkboxIcon">{icon}</div>
          <div className="toggleAllLabel">{label}</div>
        </div>
      );
    }, [activeKeyMap, setActiveKeyMap]);

    // The goal here is to prevent unnecessary rendering. When our mouse enters a legend item, the
    // bars associated with it get brighter, and all other bars get dimmed. On mouse exit, all the
    // bars return to their original colors. We use the "hoverKey" to enforce this: if it's set,
    // make that key bright and the others dim. If it's unset, make everything normal. If we move
    // our mouse from one item to another, the the non-implicated items shouldn't change; they
    // should stay dimmed and not unnecessarily rerender. However, since exit events trigger before
    // enter events, we'll get a situation where the hoverKey gets set to null, everything will
    // return to its original color, then the enter will trigger, and everything will dim again. So
    // we end up double-rendering when, for most of our bars, we don't even need to rerender once.
    // What we really want is for the key to switch to the new one. So this delays the exit event so
    // it happens after the enter event. The enter sets the hover key to its new value. Then we
    // resolve the exit. We check if the key is the same as our key: if it is, then we know no enter
    // occurred changing it (meaning this is a true exit). In that case we set it to null.
    // Otherwise, we know they entered into another item and we just don't do anything. This
    // function has "debounce" in the name but it doesn't actually debounce. The mouse exit even
    // happens before the mouse enter on the new element, but they really happen at the same time
    // (since the legend items butt up against one another). Doing setTimeout with a delay of 0
    // doesn't do anything but add the contained code to the bottom of the event queue, which is
    // really all we want.
    const debouncedUnsetHoverKey = useCallback(
      key => {
        setTimeout(() => {
          setHoverKey(currentKey => {
            if (currentKey !== key) {
              return currentKey;
            }
          });
        }, 0);
      },
      [setHoverKey]
    );

    const [pendingKeyMap, setPendingKeyMap] = useState<Record<string, boolean | undefined>>({});

    const setActiveKey = useCallback(
      (key: string, active: boolean) => {
        setPendingKeyMap(map => ({ ...map, [key]: !active }));
        setTimeout(() => {
          if (getIsMounted()) {
            setActiveKeyMap(key);
          }
        }, 0);
      },
      [setActiveKeyMap, getIsMounted]
    );
    useEffect(() => {
      setPendingKeyMap(existingMap => {
        let newMap = {};
        for (let key of R.keys(existingMap)) {
          // We could have several updates "queued" if the user clicks multiple items before they
          // all resolve. When activeKeyMap changes, I want to check each key in our "pending queue"
          // and see if it matches. If it matches, then we know that activeKeyMap has gotten this
          // particular update, and we can remove it from our queue. Otherwise we leave it. I want
          // to double bang to turn undefined into false for each, but doing a single bang for each
          // will produce the same result if you think about it.
          if (!activeKeyMap[key] !== !existingMap[key]) {
            newMap[key] = existingMap[key];
          }
        }
        return newMap;
      });
    }, [activeKeyMap]);
    return (
      <div
        className={`streamingDeliveryLegend${
          dimension === "creative" ? " streamingDeliveyrCreativeLegend" : ""
        }`}
      >
        {renderToggle()}
        <div className="items">
          {R.pipe(
            R.keys,
            R.sortBy(R.identity),
            R.map(key => {
              const active = R.isNil(pendingKeyMap[key]) ? activeKeyMap[key] : pendingKeyMap[key];
              let {
                color,
                logoSrc,
                name,
                description,
                legendClasses,
                legendInnerClasses,
                thumbnailClasses,
              } = getLegendInfo({
                key,
                colorMap,
                info: (keyInfo || {})[key],
                dimension,
                company,
              });

              if (!active) {
                legendClasses.push("disabled");
              }

              let content = (
                <div className={legendInnerClasses.join(" ")}>
                  <div className={thumbnailClasses.join(" ")}>
                    <Img src={logoSrc} unloader={key} />
                  </div>
                  <div className="legendBox">
                    <div className="circle" style={{ backgroundColor: color }} />
                  </div>
                  {name && (
                    <div className="creativeName" style={{ color: computeTextColor(color) }}>
                      {name}
                    </div>
                  )}
                </div>
              );
              let hoverProps: { onMouseEnter?: () => void; onMouseLeave?: () => void } = {
                onMouseEnter: () => setHoverKey(key),
                onMouseLeave: () => debouncedUnsetHoverKey(key),
              };

              if (description) {
                content = (
                  <OverlayTrigger
                    placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                    overlay={
                      <Tooltip id={`${id}_${key}`} className="streamingDeliveryLegendItemTooltip">
                        {description}
                      </Tooltip>
                    }
                  >
                    <div {...hoverProps}>{content}</div>
                  </OverlayTrigger>
                );
                hoverProps = {};
              }
              return (
                <div
                  key={key}
                  className={legendClasses.join(" ")}
                  onClick={() => {
                    setActiveKey(key, Boolean(active));
                  }}
                  {...hoverProps}
                >
                  {content}
                </div>
              );
            })
          )(activeKeyMap)}
        </div>
      </div>
    );
  }
);
